import { CLEAR_ERRORS } from "../../actions/types";
import {
  ADD_HOTEL_ASSET_FAIL,
  ADD_HOTEL_ASSET_REQ,
  ADD_HOTEL_ASSET_RESET,
  ADD_HOTEL_ASSET_SUC,
  DELETE_HOTEL_ASSET_FAIL,
  DELETE_HOTEL_ASSET_REQ,
  DELETE_HOTEL_ASSET_RESET,
  DELETE_HOTEL_ASSET_SUC,
  UPDATE_HOTEL_ASSET_FAIL,
  UPDATE_HOTEL_ASSET_REQ,
  UPDATE_HOTEL_ASSET_RESET,
  UPDATE_HOTEL_ASSET_SUC,
} from "../../actions/types/asset";

// add hotel assets
export const addHotelAssetReducer = (state = { assets: [] }, action) => {
  switch (action.type) {
    case ADD_HOTEL_ASSET_REQ:
      return {
        ...state,
        loading: true,
        isadded: false,
      };
    case ADD_HOTEL_ASSET_SUC:
      return {
        ...state,
        loading: false,
        assets: action.payload,
        isadded: true,
      };
    case ADD_HOTEL_ASSET_FAIL:
      return {
        ...state,
        loading: false,
        isadded: false,
        error: action.payload,
      };
    case ADD_HOTEL_ASSET_RESET:
      return {
        ...state,
        loading: false,
        isadded: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// update hotel assets
export const updateHotelAssetReducer = (state = { assets: [] }, action) => {
  switch (action.type) {
    case UPDATE_HOTEL_ASSET_REQ:
      return {
        ...state,
        loading: true,
        isupdated: false,
      };
    case UPDATE_HOTEL_ASSET_SUC:
      return {
        ...state,
        loading: false,
        assets: action.payload,
        isupdated: true,
      };

    case UPDATE_HOTEL_ASSET_FAIL:
      return {
        ...state,
        loading: false,
        isupdated: false,
        error: action.payload,
      };
    case UPDATE_HOTEL_ASSET_RESET:
      return {
        ...state,
        loading: false,
        isupdated: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

//delete hotel assets
export const deleteHotelAssetReducer = (state = { assets: [] }, action) => {
  switch (action.type) {
    case DELETE_HOTEL_ASSET_REQ:
      return {
        ...state,
        loading: true,
        isdeleted: false,
      };
    case DELETE_HOTEL_ASSET_SUC:
      return {
        ...state,
        loading: false,
        assets: action.payload,
        isdeleted: true,
      };
    case DELETE_HOTEL_ASSET_FAIL:
      return {
        ...state,
        loading: false,
        isdeleted: false,
        error: action.payload,
      };
    case DELETE_HOTEL_ASSET_RESET:
      return {
        ...state,
        loading: false,
        isdeleted: false,
        error: null,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
