import axios from "axios";
import { TOKEN } from "../../constants";
import {
  UPDATE_VENDOR_FAIL,
  UPDATE_VENDOR_REQUEST,
  UPDATE_VENDOR_SUCCESS,
  DELETE_VENDOR_FAIL,
  DELETE_VENDOR_REQUEST,
  DELETE_VENDOR_SUCCESS,
} from "../types/vendor";

//update vendor
export const updateVendorProfileAction = (formData) => async (dispatch) => {
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/vendor`;
  try {
    dispatch({ type: UPDATE_VENDOR_REQUEST });
    const { data } = await axios.post(url, formData, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: UPDATE_VENDOR_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_VENDOR_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

// delete vendor
export const deleteVendorProfileAction =
  (hotelId, vendorId) => async (dispatch) => {
    if(!hotelId || !vendorId) return;
    const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/vendor`;
    try {
      dispatch({ type: DELETE_VENDOR_REQUEST });
      const { data } = await axios.delete(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem(TOKEN),
        },
        withCredentials: true,
        data: { hotelId, vendorId },
      });
      dispatch({
        type: DELETE_VENDOR_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: DELETE_VENDOR_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };
