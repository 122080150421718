import { useState } from "react";
import { connect, useSelector } from "react-redux";
import { isObjEmpty } from "../../utils";
import { registerEvent } from "../../logs";
import PageLoader from "../../components/loader/PageLoader";
import { useTranslation } from "react-i18next";
import AddButton from "../../components/buttons/AddButton";
import Seperator from "../../components/layouts/Seperator";
import AllBuffet from "./AllBuffet";
import AddBuffet from "./AddBuffet";
import { ROUTES } from "../../constants";
import DeleteButton from "../../components/buttons/DeleteButton";
import EditButton from "../../components/buttons/EditButton";

const BuffetPage = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [kitchen, setKitchen] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const { kitchen: storeKitchen } = useSelector((state) => state.Kitchen);
  //open modal
  const openModal = () => {
    setOpen(true);
    console.log(kitchen, open);
    registerEvent(t("open modal"), t("add Buffet"));
  };

  //close modal
  const closeModal = () => setOpen(false);

  const data = {
    heading: t("Kladee’s Cafe - Buffet"),
    seperator: [
      {
        text: t("134"),
      },
      {
        link: ROUTES.KITCHENS,
        text: t("36"),
      },
      { text: t("1001") },
    ],
  };

  return (
    <>
      {props?.kitchen && props?.kitchen?.loading ? (
        <PageLoader />
      ) : (
        <div className="page-container p-0 mb-4">
          <div className="w-full p-4 pb-0 max-w-full">
            <div className="flex justify-between items-end">
              <Seperator data={data} />
              <div className="flex gap-2">
                <EditButton />
                <DeleteButton />
                <AddButton text={t("1036")} onClick={openModal} />
              </div>
            </div>
            {isObjEmpty(kitchen) && open ? (
              <AddBuffet close={closeModal} hotel={props?.hotel?.hotel} />
            ) : null}
            <AllBuffet setKitchen={setKitchen} hotel={props?.hotel?.hotel} />
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  hotel: state.Hotel,
  kitchen: state.Kitchen,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(BuffetPage);
