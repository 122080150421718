import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import LandingPage from "./pages";
import Dashboard from "./pages/dashboard";
import LoginPage from "./pages/auth/LoginPage";
import CustomersList from "./pages/customers";
import NotFound404 from "./components/NotFound404";
import ReservationGrid from "./pages/reservation";
import Support from "./pages/support/Support";
import FoodMenu from "./pages/food";
import OrderSummary from "./pages/food/OrderSummary";
import AllHotel from "./pages/hotel";
import OnBoardingRoutes from "./middlewares/OnBoardingRoutes";
import SignupPage from "./pages/auth/SignupPage";
import GetStarted from "./pages/onBoarding";
import ForgotPassword from "./pages/user/ForgotPassword";
import ForgotPasswordVerifyOTP from "./pages/user/ForgotPasswordVerifyOTP";
import ResetPassword from "./pages/user/ResetPassword";
import EmployeeLogin from "./pages/employee/EmployeeLogin";
import { authenticateHotelOwner } from "./actions/pms/ownerAuth";
import store from "./store";
import { authenticateEmployee } from "./actions/pms/employeeAuth";
import { THEMES, ROUTES } from "./constants";
import AdminAndEmployeeRoute from "./middlewares";
import AllReviews from "./pages/reviews";
import Profile from "./pages/user/Profile";
import Reports from "./pages/reports";
import ChangePassword from "./pages/user/ChangePassword";
import VerifyEmailOTP from "./pages/user/VerifyEmailOTP";
import RoomTypes from "./pages/room";
import Kitchen from "./pages/kitchen";
import HouseKeeping from "./pages/housekeeping";
import EmployeePage from "./pages/employee";
import AssetsPage from "./pages/assets";
import ExpensePage from "./pages/expense";
import InventoryPage from "./pages/inventory";
import MenuItemPage from "./pages/kitchen-menu";
import VendorPage from "./pages/vendor";
import BookingPage from "./pages/booking";
import Settings from "./pages/settings";
import HotelInventory from "./pages/inventory/HotelInventory";
import CustomerDetails from "./pages/customers/CustomerDetails";
import BookingDetails from "./pages/booking/BookingDetails";
import BuffetPage from "./pages/kitchen/BuffetPage";
import AccessControl from "./pages/access/AccessControl";
import MapIngredients from "./pages/kitchen-menu/MapIngredients";
import CreateBuffetMenu from "./pages/kitchen/CreateBuffetMenu";
import FAndBDetails from "./pages/kitchen-menu/FAndBDetails";
import CheckOutTomarrow from "./pages/housekeeping/CheckOutTomarrow";
import RoomServiceRequest from "./pages/housekeeping/RoomServiceRequest";
import VendorDetails from "./pages/vendor/VendorDetails";
import VendorHistory from "./pages/vendor/VendorHistory";
import CancelBooking from "./pages/booking/CancelBooking";
import IssueReported from "./pages/housekeeping/IssueReported";
import HousekeepingAmenities from "./pages/housekeeping/HousekeepingAmenities";
import AlaCarte from "./pages/kitchen-menu/AlaCarte";
import PurchaseOrder from "./pages/vendor/PurchaseOrder";
import RoasterManagement from "./pages/roaster";
import RoomTariff from "./pages/automation";
import BuffetDetails from "./pages/kitchen/BuffetDetails";
import CheckInventoryDetails from "./pages/kitchen/CheckInventoryDetails";
import KitchenOperations from "./pages/kitchen-operations";
import QrCode from "./pages/qrcode";
import EmployeeDetails from "./pages/employee/EmployeeDetails";
import ExpenseDetails from "./pages/expense/ExpenseDetails";
import LogsChange from "./pages/logs-change";
import HotelCheckIn from "./pages/settings/hotel-check-in";
import Laundry from "./pages/laundry";
import Spa from "./pages/spa/Index";
import Tutorial from "./pages/tutorial";

function App() {
  const [theme, setTheme] = useState(THEMES.GREEN);
  useEffect(() => {
    // check is already authenticated
    store.dispatch(authenticateHotelOwner());
    store.dispatch(authenticateEmployee());
  }, []);

  return (
    <div className={`${theme} bg-[#fafafa] text-[black] duration-75`}>
      <Router>
        <Routes>
          {/* tutorial path */}
          <Route exact path={"tutorial"} element={<Tutorial />} />
          {/* public routes */}
          <Route exact path={ROUTES.LANDING_PAGE} element={<LandingPage />} />
          <Route exact path={ROUTES.LOGIN} element={<LoginPage />} />
          <Route exact path={ROUTES.REGISTER} element={<SignupPage />} />
          <Route
            exact
            path={ROUTES.PASSWORD_FORGOT}
            element={<ForgotPassword />}
          />
          <Route
            exact
            path={ROUTES.VERIFY_OTP}
            element={<ForgotPasswordVerifyOTP />}
          />
          <Route
            exact
            path={ROUTES.PASSWORD_RESET}
            element={<ResetPassword />}
          />
          <Route
            exact
            path={ROUTES.EMPLOYEE_LOGIN}
            element={<EmployeeLogin />}
          />
          <Route
            exact
            path={ROUTES.VERIFY_EMAIL_OTP}
            element={<VerifyEmailOTP />}
          />
          {/* onboarding routes */}
          <Route element={<OnBoardingRoutes />}>
            <Route exact path={ROUTES.ONBOARDING} element={<GetStarted />} />
          </Route>
          {/* admin and employee shared routes */}
          <Route element={<AdminAndEmployeeRoute setTheme={setTheme} />}>
            <Route path={ROUTES.BOOKINGS} element={<BookingPage />} />
            <Route path={ROUTES.BOOKING} element={<BookingDetails />} />
            <Route path={ROUTES.CANCEL_BOOKING} element={<CancelBooking />} />
            <Route path={ROUTES.FOOD_MENU} element={<FoodMenu />} />
            <Route path={ROUTES.ROOMS} element={<RoomTypes />} />
            <Route path={ROUTES.LAUNDRY} element={<Laundry />} />
            <Route path={ROUTES.QR_CODES} element={<QrCode />} />
            <Route
              path={ROUTES.FOOD_ORDER_SUMMARY}
              element={<OrderSummary />}
            />
            <Route path={ROUTES.HOTELS} element={<AllHotel />} />
            <Route path={ROUTES.REPORTS} element={<Reports />} />
            <Route path={ROUTES.ACCESS_CONTROL} element={<AccessControl />} />
            <Route path={ROUTES.CUSTOMERS} element={<CustomersList />} />
            <Route path={ROUTES.CUSTOMER} element={<CustomerDetails />} />
            <Route
              path={ROUTES.RESERVATION_GRID}
              element={<ReservationGrid />}
            />
            <Route path={ROUTES.SUPPORT} element={<Support />} />
            <Route path={ROUTES.EXPENSES} element={<ExpensePage />} />
            <Route path={ROUTES.EXPENSE} element={<ExpenseDetails />} />
            <Route path={ROUTES.EMPLOYEES} element={<EmployeePage />} />
            <Route path={ROUTES.EMPLOYEE} element={<EmployeeDetails />} />
            <Route
              exact
              path={ROUTES.EMPLOYEE_ROASTER}
              element={<RoasterManagement />}
            />
            <Route
              exact
              path={ROUTES.HOTEL_CHECKIN}
              element={<HotelCheckIn />}
            />
            <Route path={ROUTES.REVIEWS} element={<AllReviews />} />
            <Route
              path={`${ROUTES.HOUSE_KEEPING}/:index`}
              element={<HouseKeeping />}
            />
            {/* kitchen */}
            <Route path={ROUTES.KITCHENS} element={<Kitchen />} />
            <Route path={ROUTES.KITCHEN_MENU} element={<MenuItemPage />} />
            <Route
              path={ROUTES.KITCHEN_INVENTORY}
              element={<InventoryPage />}
            />
            <Route path={ROUTES.KITCHEN_BUFFETS} element={<BuffetPage />} />
            <Route path={ROUTES.KITCHEN_BUFFET} element={<BuffetDetails />} />
            <Route
              path={ROUTES.KITCHEN_BUFFET_INVENTORY}
              element={<CheckInventoryDetails />}
            />
            <Route path={ROUTES.ALACARTE} element={<AlaCarte />} />
            {/* Kitchen Operations */}
            <Route
              path={ROUTES.KITCHEN_OPERATIONS}
              element={<KitchenOperations />}
            />
            <Route
              path={ROUTES.ROOM_TARIIF_AUTOMATION}
              element={<RoomTariff />}
            />
            <Route path={ROUTES.MY_PROFILE} element={<Profile />} />
            <Route path={ROUTES.PASSWORD_CHANGE} element={<ChangePassword />} />
            <Route path={ROUTES.ASSETS} element={<AssetsPage />} />
            <Route path={ROUTES.LOGS_CHANGE} element={<LogsChange />} />
            <Route path={ROUTES.VENDORS} element={<VendorPage />} />
            <Route path={ROUTES.INVENTORY} element={<HotelInventory />} />
            <Route
              path={ROUTES.KITCHEN_MENU_INGREDIENTS}
              element={<MapIngredients />}
            />
            <Route path={ROUTES.FAB_ITEMS} element={<FAndBDetails />} />
            <Route
              path={ROUTES.ADD_BUFFET_MENU}
              element={<CreateBuffetMenu />}
            />
            <Route path={ROUTES.CHECKOUT} element={<CheckOutTomarrow />} />
            <Route
              path={ROUTES.ROOM_SERVICE_REQUEST}
              element={<RoomServiceRequest />}
            />
            <Route path={ROUTES.ISSUE_REPORTED} element={<IssueReported />} />
            <Route
              path={ROUTES.HOUSE_KEEPING_AMENITY}
              element={<HousekeepingAmenities />}
            />
            <Route path={ROUTES.PURCHASE_ORDER} element={<PurchaseOrder />} />
            <Route path={ROUTES.VENDOR} element={<VendorDetails />} />
            <Route path={ROUTES.SPA} element={<Spa />} />
            <Route path={ROUTES.VENDOR_HISTORY} element={<VendorHistory />} />
            <Route path={ROUTES.DASHBOARD} element={<Dashboard />} />
            <Route
              path={`${ROUTES.HOTEL_CONFIG}/:index`}
              element={<Settings />}
            />
          </Route>
          {/* 404 not found page */}
          <Route path="*" element={<NotFound404 />} />
        </Routes>
        <ToastContainer style={{ marginTop: "50px" }} />
      </Router>
    </div>
  );
}

export default App;
