//kitchen
export const ADD_KITCHEN_REQUEST = "ADD_KITCHEN_REQUEST";
export const ADD_KITCHEN_SUCCESS = "ADD_KITCHEN_SUCCESS";
export const ADD_KITCHEN_FAIL = "ADD_KITCHEN_FAIL";
export const ADD_KITCHEN_RESET = "ADD_KITCHEN_RESET";

export const GET_ALL_KITCHEN_REQUEST = "GET_ALL_KITCHEN_REQUEST";
export const GET_ALL_KITCHEN_SUCCESS = "GET_ALL_KITCHEN_SUCCESS";
export const GET_ALL_KITCHEN_FAIL = "GET_ALL_KITCHEN_FAIL";

export const GET_KITCHEN_REQUEST = "GET_KITCHEN_REQUEST";
export const GET_KITCHEN_SUCCESS = "GET_KITCHEN_SUCCESS";
export const GET_KITCHEN_FAIL = "GET_KITCHEN_FAIL";

export const UPDATE_KITCHEN_REQUEST = "UPDATE_KITCHEN_REQUEST";
export const UPDATE_KITCHEN_SUCCESS = "UPDATE_KITCHEN_SUCCESS";
export const UPDATE_KITCHEN_FAIL = "UPDATE_KITCHEN_FAIL";
export const UPDATE_KITCHEN_RESET = "UPDATE_KITCHEN_RESET";

export const DELETE_KITCHEN_REQUEST = "DELETE_KITCHEN_REQUEST";
export const DELETE_KITCHEN_SUCCESS = "DELETE_KITCHEN_SUCCESS";
export const DELETE_KITCHEN_FAIL = "DELETE_KITCHEN_FAIL";
export const DELETE_KITCHEN_RESET = "DELETE_KITCHEN_RESET";
