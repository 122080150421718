import React, { useState } from "react";
import Inputfield from "../../../components/layouts";
import Visa from "../../../images/icons/Visa.svg";

const PaymentModes = (props) => {
  const [email, setEmail] = useState("");
  return (
    <div className="mx-auto w-full border border-[#D0D5DD] rounded-[10px] bg-white p-8">
      <h2 className="text-[20px] leading-5 font-medium text-black mb-3">
        Email
      </h2>
      <Inputfield
        // label={"Email"}
        placeholder={"abcdefghi@gmail.com"}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required={true}
      />
      <div className="my-6">
        <h2 className="text-[20px] leading-5 font-medium text-black mb-3">
          Card Information
        </h2>
        <div className="relative shadow-[0_1px_2px_0_rgba(16,24,40,0.05)]  rounded-[10px]">
          <input
            type="text"
            className="rounded-b-none shadow-none"
            placeholder="Enter Your Card Number"
          />
          <img src={Visa} alt="" className="absolute right-6 top-0" />
          <div className="flex w-full">
            <input
              type="text"
              className="w-1/2 rounded-t-none rounded-br-none border-t-0 border-r-0 shadow-none"
              placeholder="CV"
            />
            <input
              type="text"
              className="w-1/2 rounded-t-none rounded-bl-none border-t-0 shadow-none"
              placeholder="Enter Expiry Month/Year"
            />
          </div>
        </div>
      </div>
      <div className="">
        <h2 className="text-[20px] leading-5 font-medium text-black mb-3">
          Name on card
        </h2>
        <input type="text" className="w-3/5" placeholder="Enter Name On Card" />
      </div>

      <div className="mt-6 mb-2">
        <h2 className="text-[20px] leading-5 font-medium text-black mb-3">
          Country or region
        </h2>
        <div className="relative shadow-[0_1px_2px_0_rgba(16,24,40,0.05)]  rounded-[10px]">
          <input
            type="text"
            className="shadow-none rounded-b-none"
            placeholder="Country"
          />
          <input
            type="text"
            className="shadow-none rounded-t-none border-t-0"
            placeholder="Pin Code"
          />
        </div>
      </div>
      <div className="w-full text-[16px] leading-[21.28px] font-normal text-[#BFC0C1]">
        By providing your card information, you allow kunggy to charge your card
        for future payments in accordance with thier terms.
      </div>
      <button
        type="button"
        className="w-full h-12 rounded-lg bg-base-primary hover:bg-base-primary_hover text-white text-[16px] leading-[18.88px] font-bold mt-14"
      >
        Pay
      </button>
    </div>
  );
};

export default PaymentModes;
