export const GET_ALL_SALES_REQ = "GET_ALL_SALES_REQ";
export const GET_ALL_SALES_SUC = "GET_ALL_SALES_SUC";
export const GET_ALL_SALES_FAIL = "GET_ALL_SALES_FAIL";

//get sales by - date, month and year
export const SALES_BY_DATE_REQ = "SALES_BY_DATE_REQ";
export const SALES_BY_DATE_SUC = "SALES_BY_DATE_SUC";
export const SALES_BY_DATE_FAIL = "SALES_BY_DATE_FAIL";

export const SALES_BY_MONTH_REQ = "SALES_BY_MONTH_REQ";
export const SALES_BY_MONTH_SUC = "SALES_BY_MONTH_SUC";
export const SALES_BY_MONTH_FAIL = "SALES_BY_MONTH_FAIL";

export const SALES_BY_YEAR_REQ = "SALES_BY_YEAR_REQ";
export const SALES_BY_YEAR_SUC = "SALES_BY_YEAR_SUC";
export const SALES_BY_YEAR_FAIL = "SALES_BY_YEAR_FAIL";
