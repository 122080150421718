/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import CustomeTable from "../../components/CustomeTable";

const SalaryManagement = () => {
  const [selectedRows, setSelectedRows] = useState([]);

  const tableData = [
    {
      transactionId: "3213210985",
      dateAndTime: "04/12/2023  6:53:09  PM",
      fromAccountTitle: "john Doe",
      bankAccount: "ABC2345686121",
      transactionAmount: "$10000.00",
      totalAmount: "$2000",
      paymentStatus: "Paid",
    },
    {
      transactionId: "3213210985",
      dateAndTime: "04/12/2023  6:53:09  PM",
      fromAccountTitle: "john Doe",
      bankAccount: "ABC2345686121",
      transactionAmount: "$10000.00",
      totalAmount: "$2000",
      paymentStatus: "Paid",
    },
    {
      transactionId: "3213210985",
      dateAndTime: "04/12/2023  6:53:09  PM",
      fromAccountTitle: "john Doe",
      bankAccount: "ABC2345686121",
      transactionAmount: "$10000.00",
      totalAmount: "$2000",
      paymentStatus: "Paid",
    },
    {
      transactionId: "3213210985",
      dateAndTime: "04/12/2023  6:53:09  PM",
      fromAccountTitle: "john Doe",
      bankAccount: "ABC2345686121",
      transactionAmount: "$10000.00",
      totalAmount: "$2000",
      paymentStatus: "Pending",
    },
  ];
  const checkPaymentStatus = ({ value }) => {
    return (
      <>
        <div className="text-[14px] leading-6 font-normal tracking-[-0.6%] mt-2">
          {value === "Paid" && <div className="text-base-primary">{value}</div>}
          {value === "Pending" && <div className="text-[#F04438]">{value}</div>}
        </div>
      </>
    );
  };
  const columnDefs = [
    {
      headerName: "Transaction ID",
      field: "transactionId",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Date And Time",
      field: "dateAndTime",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "From Account Title",
      field: "fromAccountTitle",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Bank Account",
      field: "bankAccount",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Transaction Amount",
      field: "transactionAmount",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Total Amount",
      field: "totalAmount",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Payment Status",
      field: "paymentStatus",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
      cellRenderer: checkPaymentStatus,
    },
  ];

  return (
    <div>
      <CustomeTable
        columnDefs={columnDefs}
        rowData={tableData}
        setSelectedRows={setSelectedRows}
      />
    </div>
  );
};

export default SalaryManagement;
