import { React, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import Inputfield from "../../components/layouts";
import AddSubmitButton from "../../components/buttons/AddSubmitButton";

const AddSpa = ({closeAddSpa}) => {
  const [serviceName, setServiceName] = useState("");
  const [addPrice, setAddPrice] = useState("");
  const [addDescription, setAddDescription] = useState("");
  return (
    <div className="w-full border border-[#D0D5DD] rounded-[10px] bg-white px-6 pt-6 mt-6">
      <div className="flex items-center justify-between">
        <h2 className="text-[30px] leading-[37.8px] text-[#07090D] font-bold">
          Add Spa
        </h2>
        <button className="" onClick={closeAddSpa}>
          <RxCross2
            size={26}
            className="hover:rotate-90 duration-500 hover:text-[#F04438]"
          />
        </button>
      </div>
      <section className="my-6">
        <div className="grid grid-cols-2 items-center gap-6 ">
          <Inputfield
            label={"Service Name"}
            value={serviceName}
            onChange={(e) => setServiceName(e.target.value)}
            placeholder={"Enter Service Name"}
            required={true}
          />
          <Inputfield
            label={"Add Price"}
            value={addPrice}
            onChange={(e) => setAddPrice(e.target.value)}
            placeholder={"Enter Add Price"}
            required={true}
          />
        </div>
        <div className="mt-6">
          <label className="">Add Description</label>
          <textarea
            className="w-full h-[118px] border border-[#D0D5DD] rounded-lg  overflow-y-auto scroll-barguest placeholder:text-base placeholder:text-[#667085] p-4"
            placeholder="Write Here"
            value={addDescription}
            onChange={(e) => setAddDescription(e?.target?.value)}
          ></textarea>
        </div>
        <div className="flex items-center justify-end mt-10">
          <AddSubmitButton text={"Add Spa"} />
        </div>
      </section>
    </div>
  );
};

export default AddSpa;
