import { CLEAR_ERRORS } from "../../actions/types";
import {
  DELETE_ROOM_FAIL,
  DELETE_ROOM_REQUEST,
  DELETE_ROOM_RESET,
  DELETE_ROOM_SUCCESS,
  DELETE_ROOM_NUMBER_FAIL,
  DELETE_ROOM_NUMBER_SUC,
  DELETE_ROOM_NUMBER_RESET,
  DELETE_ROOM_NUMBER_REQ,
} from "../../actions/types/room";

//delete room
export const deleteRoomReducer = (state = { message: "" }, action) => {
  switch (action.type) {
    case DELETE_ROOM_REQUEST:
      return {
        ...state,
        loading: true,
        isdeleted: false,
      };
    case DELETE_ROOM_SUCCESS:
      return {
        ...state,
        loading: false,
        isdeleted: true,
        message: action.payload,
      };
    case DELETE_ROOM_FAIL:
      return {
        ...state,
        loading: false,
        isdeleted: false,
        error: action.payload,
      };
    case DELETE_ROOM_RESET:
      return {
        ...state,
        isdeleted: false,
        loading: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

//delete room number reducer
export const deleteRoomNumberReducer = (state = { rooms: [] }, action) => {
  switch (action.type) {
    case DELETE_ROOM_NUMBER_REQ:
      return {
        ...state,
        loading: true,
        isdeleted: false,
      };
    case DELETE_ROOM_NUMBER_SUC:
      return {
        ...state,
        loading: false,
        isdeleted: true,
        rooms: action.payload,
      };
    case DELETE_ROOM_NUMBER_FAIL:
      return {
        ...state,
        loading: false,
        isdeleted: false,
        error: action.payload,
      };
    case DELETE_ROOM_NUMBER_RESET:
      return {
        ...state,
        isdeleted: false,
        loading: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
