import React from "react";
import { FiArrowRight } from "react-icons/fi";

const FormSubmitButton = ({
  type = "submit",
  loading = false,
  text = "submit",
}) => {
  return (
      <button
        className="relative h-10 w-fit px-3 bg-base-primary hover:bg-base-primary_hover rounded-md flex items-center shadow-[0,1px,2px,0,rgba(16, 24, 40, 0.05)] transition-all duration-[0.3s] ease-[0s]"
        disabled={loading}
        type={type}
      >
        <div
          className={`text-sm font-medium ${
            loading ? "text-base-primary" : "text-white"
          }`}
        >
          {text}
        </div>
        <div className="absolute left-1/2 -translate-x-1/2">
          <div
            className={`${
              loading ? "" : "hidden"
            }  h-5 w-5 border-[2px] rounded-full border-white border-t-[#ffffff23] animate-spin`}
          ></div>
        </div>
        <FiArrowRight
          size={20}
          style={{ marginLeft: "8px", color: "#FFFFFF" }}
        />
      </button>
  );
};

export default FormSubmitButton;
