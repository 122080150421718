import React from "react";

export const DesboardCard = ({
  name,
  icon,
  percantage,
  percantageIcon,
  totalCounts,
  utility,
}) => {
  return (
    // drop-shadow-[0_4.042162px_12.126px_rgba(0, 0, 0, 0.04)]
    <div className="w-full h-[171.21px] rounded-[12.13px] px-5 py-6 bg-[#FFFFFF] shadow-sm">
      <div className="flex items-center justify-between text-base-primary">
        <img src={icon} alt="" />
        <div className={`flex items-center font-semibold ${utility}`}>
          {percantage}
          {percantageIcon}
        </div>
      </div>
      {/* total counts */}
      <div className="w-full md:max-w-[266.26px]  leading-[72.22px] text-[51.28px] text-[#18181B] font-bold">
        {totalCounts}
      </div>
      {/* stutus name */}
      <div className="text-[#71717A] font-medium text-[13.33px] leading-[22.27px] tracking-[1.24px] uppercase">
        {name}
      </div>
    </div>
  );
};
