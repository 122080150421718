import React from "react";
import { FiArrowRight } from "react-icons/fi";
import LoadSpinner from "../loader/LoadSpinner";

const AddSubmitButton = ({
  text,
  onClick = () => {},
  loading,
  width = 144,
  type = "submit",
}) => {
  return (
    <button
      className={`h-10 bg-base-primary transition-all duration-[0.3s] ease-[0s] hover:bg-base-primary_hover rounded-md text-white flex items-center justify-center shadow-[0,1px,2px,0,rgba(16,24,40,0.05)]`}
      disabled={loading}
      type={type}
      onClick={onClick}
      style={{ width }}
    >
      {loading ? (
        <LoadSpinner />
      ) : (
        <>
          <span className="text-sm font-medium mr-2">{text}</span>
          <FiArrowRight size={18} className="font-medium" />
        </>
      )}
    </button>
  );
};

export default AddSubmitButton;
