import {
  ADD_EMPLOYEE_FAIL,
  ADD_EMPLOYEE_REQUEST,
  ADD_EMPLOYEE_RESET,
  ADD_EMPLOYEE_SUCCESS,
  GET_ALL_EMPLOYEE_FAIL,
  GET_ALL_EMPLOYEE_REQUEST,
  GET_ALL_EMPLOYEE_SUCCESS,
  GET_EMPLOYEE_BY_ID_FAIL,
  GET_EMPLOYEE_BY_ID_REQUEST,
  GET_EMPLOYEE_BY_ID_SUCCESS,
} from "../../actions/types/employee";
import { CLEAR_ERRORS } from "../../actions/types";

//add employee reducer
export const addEmployeeReducer = (state = { employee: {} }, action) => {
  switch (action.type) {
    case ADD_EMPLOYEE_REQUEST:
      return {
        ...state,
        loading: true,
        isadded: false,
      };
    case ADD_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: false,
        employee: action.payload,
        isadded: true,
      };
    case ADD_EMPLOYEE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isadded: false,
      };
    case ADD_EMPLOYEE_RESET:
      return {
        ...state,
        loading: false,
        error: action.payload,
        employee: null,
        isadded: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

//hotel get all employee
export const getAllEmployeeReducer = (state = { employees: [] }, action) => {
  switch (action.type) {
    case GET_ALL_EMPLOYEE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: false,
        employees: action.payload,
      };
    case GET_ALL_EMPLOYEE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        employees:[],
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

//hotel owner get one employee by id
export const getEmployeeByIdReducer = (state = { employee: {} }, action) => {
  switch (action.type) {
    case GET_EMPLOYEE_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_EMPLOYEE_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        employee: action.payload,
      };
    case GET_EMPLOYEE_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        employee: null
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null, 
      };
    default:
      return state;
  }
};
