import { CLEAR_ERRORS } from "../../actions/types";
import {
  ADD_ROOM_FAIL,
  ADD_ROOM_REQUEST,
  ADD_ROOM_RESET,
  ADD_ROOM_SUCCESS,
  UPDATE_ROOM_FAIL,
  UPDATE_ROOM_REQUEST,
  UPDATE_ROOM_RESET,
  UPDATE_ROOM_SUCCESS,
} from "../../actions/types/room";

//add room
export const addRoomReducer = (state = { room: {} }, action) => {
  switch (action.type) {
    case ADD_ROOM_REQUEST:
      return {
        ...state,
        loading: true,
        isadded: false,
      };
    case ADD_ROOM_SUCCESS:
      return {
        ...state,
        loading: false,
        room: action.payload,
        isadded: true,
      };
    case ADD_ROOM_FAIL:
      return {
        ...state,
        loading: false,
        room: null,
        isadded: false,
        error: action.payload,
      };
    case ADD_ROOM_RESET:
      return {
        ...state,
        loading: false,
        isadded: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

//update room
export const updateRoomReducer = (state = { room: {} }, action) => {
  switch (action.type) {
    case UPDATE_ROOM_REQUEST:
      return {
        ...state,
        loading: true,
        isupdated: false,
      };
    case UPDATE_ROOM_SUCCESS:
      return {
        ...state,
        loading: false,
        room: action.payload,
        isupdated: true,
      };
    case UPDATE_ROOM_FAIL:
      return {
        ...state,
        loading: false,
        room: null,
        isupdated: false,
        error: action.payload,
      };
    case UPDATE_ROOM_RESET:
      return {
        ...state,
        loading: false,
        isupdated: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
