import React from 'react'
import { RiUpload2Line } from 'react-icons/ri'

const UploadButton = ({ onClick }) => {
    return (
        <button type="button" className="flex items-center justify-center gap-3 h-10 px-4 border border-base-primary hover:bg-base-primary hover:text-white rounded-md bg-white text-sm font-semibold text-base-primary shadow-[0_1px_2px_0_rgba(16, 24, 40, 0.05)] transition-all duration-[0.3s] ease-[0s]"
            onClick={onClick}>
            Upload Data
            <RiUpload2Line size={18}/>
        </button>
    )
}

export default UploadButton