import React, { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import RoomRow from "./RoomRow";

const RoomType = ({ roomType, days, bookings }) => {
    const [open, setOpen] = useState(false);
    const [rooms, setRooms] = useState([]);

    const toggleShow = () => {
        setOpen(!open);

        //filter rooms by room type
        let newData = [];
        Object.keys(bookings)?.map((x) => {
            if (bookings[x]?.roomType === roomType) {
                newData.push(bookings[x]);
            }
        })
        setRooms(newData)
    }

    return (
        <div>
            <div className="flex">
                <button className="h-[60px] w-[177px] min-w-[177px] px-5 flex items-center justify-between border-b border-r bg-[#F6F6F6]" onClick={toggleShow}>
                    <span className="text-[18px] leading-3 font-normal capitalize">{roomType}</span>
                    {open ? <IoIosArrowUp size={24} /> : <IoIosArrowDown size={24} />}
                </button>
                <div
                    className={`bg-white w-12 min-w-12 h-[60px] border-r border-b`}
                ></div>
                {days?.map((date, index) => (
                    <div
                        key={index}
                        className={`bg-white w-[60px] min-w-[60px] h-[60px] border-r border-b flex justify-center items-center relative`}
                    ></div>
                ))}
            </div>
            {
                open ? rooms?.map((room, index) => (
                    <RoomRow key={index} room={room} bookings={bookings} days={days} />
                )) : null
            }
        </div>
    );
};
export default RoomType
