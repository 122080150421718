import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CiFilter } from "react-icons/ci";

const LeftSide = () => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState(false);
  const [isHotelGuestBuffet, setIsHotelGuestBuffet] = useState(false);
  const [isWeddingAndEventBuffet, setIsWeddingAndEventBuffet] = useState(false);
  const [isKitchenStaff, setIsKitchenStaff] = useState(false);
  const [isBuffetGuest, setIsBuffetGuest] = useState(false);

  function handleHotelGuestBuffet() {
    setIsHotelGuestBuffet(!isHotelGuestBuffet);
  }

  function handleWeddingAndEventBuffet() {
    setIsWeddingAndEventBuffet(!isWeddingAndEventBuffet);
  }
  function handleKitchenStaff() {
    setIsKitchenStaff(!isKitchenStaff);
  }

  function handleBuffetGuest() {
    setIsBuffetGuest(!isBuffetGuest);
  }

  return (
    <div className="position static z-10">
      <div className="w-[177px] h-[39px] bg-base-primary flex items-center justify-center border-r border-b border-[#D0D5DD] text-[16px] leading-[20.16px] font-medium text-white tracking-[-0.8px]">
        Buffet Name
      </div>
      <div className="w-[177px] h-[54px] flex items-center overflow-y-auto overflow-hidden pl-6">
        {/* filter */}
        <button
          className="gap-2 w-[117px] h-[34px] border rounded-[5px] bg-white flex items-center px-4"
          onClick={() => setFilter(!filter)}
        >
          <span className="tracking-[-0.6%] text-[14px] leading-[24px] font-normal text-[#344054] capitalize">
            {t("1014")}
          </span>
          <span className="border border-gray-400 rounded-lg p-[2px]">
            <CiFilter size={13} />
          </span>
          <span className="h-[19px] w-[19px] rounded-full flex justify-center items-center text-white text-[11px] leading-5 font-medium bg-base-primary hover:bg-base-primary_hover">
            3
          </span>
        </button>
      </div>
      {/* Hotel Guest Buffet */}
      <div className="">
        {filter ? (
          <div className="pt-4 pb-4 flex flex-col items-center justify-center border-t border-r">
            <button
              className=" w-[141px] text-[14px] leading-[16.94px] text-[#07090D] hover:text-base-primary font-normal capitalize"
              onClick={handleHotelGuestBuffet}
            >
              Hotel Guest Buffet
            </button>
            {isHotelGuestBuffet ? (
              <button
                type="button"
                className="h-[29px] w-[86px] rounded-md border border-[#0000001a] text-[14px] text-base-primary hover:bg-base-primary hover:text-white leading-[16.94px] mt-4"
                onClick={handleBuffetGuest}
              >
                Guest List
              </button>
            ) : null}
          </div>
        ) : null}

        {/* buffet guest list modal */}
        <div className=""></div>

        {/*breakfast buffet menu modal */}
        <div className=""></div>

        {/* wedding/events buffet */}
        {filter ? (
          <div className="pt-4 pb-4 flex flex-col items-center justify-center border-t border-r">
            <button
              className=" w-[141px] text-[14px] leading-[16.94px] text-[#07090D] hover:text-base-primary font-normal capitalize"
              onClick={handleWeddingAndEventBuffet}
            >
              Wedding/Event Buffet
            </button>
            {isWeddingAndEventBuffet ? (
              <button
                type="button"
                className="h-[29px] w-[86px] rounded-md border border-[#0000001a] text-[14px] text-base-primary hover:bg-base-primary hover:text-white leading-[16.94px] mt-4"
                // onClick={handleBreakfastBuffetMenu}
              >
                Details
              </button>
            ) : null}
          </div>
        ) : null}
        {/* kitchen staff */}
        {filter ? (
          <div className="pt-4 pb-4 flex flex-col items-center justify-center border-t border-r">
            {/* Hotel Guest Buffet */}
            <button
              className=" w-[141px] text-[14px] leading-[16.94px] text-[#07090D] hover:text-base-primary font-normal capitalize"
              onClick={handleKitchenStaff}
            >
              Kitchen Staff
            </button>
            {/* {isKitchenStaff ? (
              <button
                type="button"
                className="h-[29px] w-[86px] rounded-md border border-[#0000001a] text-[14px] text-base-primary hover:bg-base-primary hover:text-[#FFFFFF] leading-[16.94px] mt-4"
              >
                Details
              </button>
            ) : null} */}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default LeftSide;
