import React from "react";
import ToolTip from "../ToolTip";

const Inputfield = ({
  label,
  value,
  onChange,
  type = "text",
  max,
  min,
  maxLength,
  minLength,
  message,
  placeholder,
  required = false,
  readOnly = false,
  utility,
  icon,
  customInputField,
}) => {
  return (
    <div className="relative">
      <div className={`flex items-center justify-between ${utility}`}>
        <label className="">{label}</label>
        <ToolTip Message={message} />
      </div>
      <input
        className={`${customInputField}`}
        type={type}
        value={value}
        onChange={onChange}
        max={max}
        min={min}
        maxLength={maxLength}
        minLength={minLength}
        placeholder={placeholder}
        required={required}
        readOnly={readOnly}
      />
      <div className="absolute right-4 top-8">{icon}</div>
    </div>
  );
};

export default Inputfield;
