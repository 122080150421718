export const ADD_UPDATE_ADDRESS_REQUEST = "ADD_UPDATE_ADDRESS_REQUEST";
export const ADD_UPDATE_ADDRESS_SUCCESS = "ADD_UPDATE_ADDRESS_SUCCESS";
export const ADD_UPDATE_ADDRESS_FAIL = "ADD_UPDATE_ADDRESS_FAIL";
export const ADD_UPDATE_ADDRESS_RESET = "ADD_UPDATE_ADDRESS_RESET";

export const ADD_UPDATE_AMENITY_REQUEST = "ADD_UPDATE_AMENITY_REQUEST";
export const ADD_UPDATE_AMENITY_SUCCESS = "ADD_UPDATE_AMENITY_SUCCESS";
export const ADD_UPDATE_AMENITY_FAIL = "ADD_UPDATE_AMENITY_FAIL";
export const ADD_UPDATE_AMENITY_RESET = "ADD_UPDATE_AMENITY_RESET";

export const DELETE_AMENITY_REQUEST = "DELETE_AMENITY_REQUEST";
export const DELETE_AMENITY_SUCCESS = "DELETE_AMENITY_SUCCESS";
export const DELETE_AMENITY_FAIL = "DELETE_AMENITY_FAIL";
export const DELETE_AMENITY_RESET = "DELETE_AMENITY_RESET";

export const ADD_UPDATE_CONTACT_REQUEST = "ADD_UPDATE_CONTACT_REQUEST";
export const ADD_UPDATE_CONTACT_SUCCESS = "ADD_UPDATE_CONTACT_SUCCESS";
export const ADD_UPDATE_CONTACT_FAIL = "ADD_UPDATE_CONTACT_FAIL";
export const ADD_UPDATE_CONTACT_RESET = "ADD_UPDATE_CONTACT_RESET";