import { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { employeeLoginAction } from "../actions/pms/employeeAuth";
import { isAdminAction } from "../actions/pms/isadmin";
import { GET_HOTEL_SUCCESS } from "../actions/types/hotel";
import { 
  TOKEN,
  ISADMIN,
  PROPERTY_ID,
  ONBOARDING_STAGES,
  ROUTES,
} from "../constants";

const AuthenticateUser = (props) => {
  const navigate = useNavigate();
  //check is user is authenticated or not
  const redirect = async () => {
    const token = localStorage.getItem(TOKEN);
    const isadmin = JSON.parse(localStorage.getItem(ISADMIN));
    if (!token) {
      navigate(ROUTES.LOGIN);
    }
    if (isadmin) props.setIsadmin(true);
    else props.setIsadmin(false);
  };
  useEffect(() => {
    redirect();
  }, []);

  useEffect(() => {
    if (
      props?.employee &&
      props?.employee?.employee &&
      props?.employee?.employee?._id
    ) {
      //set hotel data
      const hotel = {
        _id: props?.employee?.employee?.hotelId,
        stage: ONBOARDING_STAGES.DASHBOARD,
      };
      if (hotel) {
        props.getHotel(hotel);
        localStorage.setItem(PROPERTY_ID, hotel?._id);
        props.setIsConfigured(true);
      }
    }
  }, [props?.employee]);

  return null;
};

const mapStateToProps = (state) => ({
  employee: state.EmployeeAuth,
});

const mapDispatchToProps = (dispatch) => ({
  employeeAuth: () => dispatch(employeeLoginAction()),
  getHotel: (data) => dispatch({ type: GET_HOTEL_SUCCESS, payload: data }),
  setIsadmin: (value) => dispatch(isAdminAction(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticateUser);
