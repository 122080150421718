import axios from "axios";
import { TOKEN } from "../../constants";
import {
  ADD_HOTEL_WIFI_FAIL,
  ADD_HOTEL_WIFI_REQ,
  ADD_HOTEL_WIFI_SUC,
  DELETE_HOTEL_WIFI_FAIL,
  DELETE_HOTEL_WIFI_REQ,
  DELETE_HOTEL_WIFI_SUC,
  GET_HOTEL_WIFI_FAIL,
  GET_HOTEL_WIFI_REQ,
  GET_HOTEL_WIFI_SUC,
  UPDATE_HOTEL_WIFI_FAIL,
  UPDATE_HOTEL_WIFI_REQ,
  UPDATE_HOTEL_WIFI_SUC,
} from "../types/hotelWifi";

//GET HOTEL WIFI
export const getHotelWifi = (hotelId) => async (dispatch) => {
  if(!hotelId) return;
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/wifi?hotelId=${hotelId}`;

  try {
    dispatch({ type: GET_HOTEL_WIFI_REQ });
    const { data } = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: GET_HOTEL_WIFI_SUC,
      payload: data?.wifiDetails,
    });
  } catch (error) {
    dispatch({
      type: GET_HOTEL_WIFI_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

//ADD HOTEL WIFI
export const addHotelWifi = (formData) => async (dispatch) => {
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/wifi`;
  try {
    dispatch({ type: ADD_HOTEL_WIFI_REQ });
    const { data } = await axios.post(url, formData, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: ADD_HOTEL_WIFI_SUC,
      payload: data?.wifiDetails,
    });
  } catch (error) {
    dispatch({
      type: ADD_HOTEL_WIFI_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

//UPDATE HOTEL WIFI
export const updateHotelWifi = (formData) => async (dispatch) => {
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/wifi`;
  try {
    dispatch({ type: UPDATE_HOTEL_WIFI_REQ });
    const { data } = await axios.put(url, formData, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: UPDATE_HOTEL_WIFI_SUC,
      payload: data?.wifiDetails,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_HOTEL_WIFI_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

//DELETE HOTEL WIFI
export const deleteHotelWifi = (hotelId, ssid) => async (dispatch) => {
  if(!hotelId || !ssid) return;
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/wifi`;
  try {
    dispatch({ type: DELETE_HOTEL_WIFI_REQ });
    const { data } = await axios.delete(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
      data: {
        hotelId,
        ssid,
      },
    });
    dispatch({
      type: DELETE_HOTEL_WIFI_SUC,
      payload: data?.wifiDetails,
    });
  } catch (error) {
    dispatch({
      type: DELETE_HOTEL_WIFI_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};
