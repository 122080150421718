import React from "react";
import { Link } from "react-router-dom";
import { registerEvent } from "../../logs";

const Logo = ({ url }) => {
  return (
    <>
      <Link to={url}>
        <h1
          className={`text-black text-[32px] w-full relative text-left whitespace-nowrap font-sans font-extrabold`}
          onClick={() => registerEvent("goto landing page", "LOGO", { url })}
        >
          Kunggy
        </h1>
      </Link>
    </>
  );
};

export default Logo;
