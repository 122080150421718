import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { notifySuccess } from "../../components/alert";
import {
  deleteKitchenAction,
  getKitchenByHotelIdAction,
} from "../../actions/kitchen/kitchen";
import DeleteOrNot from "../../components/layouts/DeleteOrNot";
import { ROUTES } from "../../constants";
import BackGroundLoadSpinner from "../../components/loader/BackGroundLoadSpinner";
import { isObjEmpty } from "../../utils";
import { useTranslation } from "react-i18next";
// import DelIcon from "../../images/icons/DelIcon.svg";
// import EditIcon from "../../images/icons/EidIcon.svg";
import { CustomeTable } from "../../components";
import UploadButton from "../../components/buttons/UploadButton";
import DownloadButton from "../../components/buttons/DownloadButton";

const AllBuffet = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [kitchenId, setKitchenId] = useState("");
  const [datas, setDatas] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    if (props?.kitchen && !isObjEmpty(props?.kitchen?.kitchen)) {
      setDatas([
        {
          Kitchen_id: "1214",
          buffetName: props?.kitchen?.kitchen?.buffetName,
          buffetType: props?.kitchen?.kitchen?.buffetType,
          buffetPurpose: props?.kitchen?.kitchen?.buffetPurpose,
          buffetPricePerson: props?.kitchen?.kitchen?.buffetPricePerson,
          _id: props?.kitchen?.kitchen?._id,
        },
      ]);
    }
  }, [props?.kitchen]);

  //set kitchen data and redirect to another page
  // const setKitchenAndRedirect = (link) => {
  //   if (link) {
  //     navigate(link);
  //   }
  // };

  // add update kitchen data
  // const setKitchenData = (id) => {
  //   if (id && props?.kitchen && props?.kitchen?.kitchen) {
  //     props.setKitchen(props?.kitchen?.kitchen);
  //   }
  // };

  //set delete Info
  // const deleteInfo = (id) => {
  //   if (id) {
  //     setKitchenId(id);
  //     setOpen(true);
  //   }
  // };

  //delete the kitchen
  const deleteKitchen = () => {
    if (kitchenId) {
      setKitchenId(kitchenId);
      dispatch(deleteKitchenAction(kitchenId));
      setOpen(false);
    }
  };

  //kitchen added
  useEffect(() => {
    if (props?.add && props?.add?.isadded) {
      notifySuccess(t("1060"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.add]);

  //go for add menu item in buffet
  // const gotoCreateBuffetMenu = (id) => {
  //   if (id) {
  //     localStorage.setItem(ID_NUMBER, id);
  //     navigate(PATH_KITCHEN_BUFFET_MENU);
  //   }
  // };
  // const gotoCreateBuffetDetails = (id) => {
  //   if (id) {
  //     localStorage.setItem(ID_NUMBER, id);
  //     navigate(PATH_KITCHEN_BUFFET_DETAILS);
  //   }
  // };

  //view Buffet Actions buttons
  const ViewBuffetActions = () => {
    const navigate = useNavigate();
    const setKitchenAndRedirect = (link) => {
      if (link) {
        navigate(link);
      }
    };
    return (
      <div className="flex items-center gap-2 mt-[5px]">
        <button
          className="our-button w-fit px-3 h-[32px] rounded font-normal hover:bg-base-primary_hover"
          onClick={() => setKitchenAndRedirect(ROUTES.KITCHEN_BUFFETS)}
        >
          {t("1061")}
        </button>
        <button
          className="our-button w-fit px-3 h-[32px] rounded font-normal hover:bg-base-primary_hover"
          onClick={() => setKitchenAndRedirect(ROUTES.KITCHEN_BUFFET)}
        >
          {t("Details")}
        </button>
      </div>
    );
  };

  const rowData = [
    {
      Kitchen_id: "BF01",
      buffetName: "Royal Buffet",
      buffetType: "Breakfast Buffet",
      buffetPurpose: "Hotel Guest Buffet",
      overWrite: "No",
      buffetPricePerson: "$12",
    },
    {
      Kitchen_id: "BF02",
      buffetName: "Grand Buffet",
      buffetType: "Lunch Buffet",
      buffetPurpose: "Wedding Buffet",
      overWrite: "No",
      buffetPricePerson: "$16",
    },
    {
      Kitchen_id: "BF03",
      buffetName: "Grand Buffet",
      buffetType: "Lunch Buffet",
      buffetPurpose: "Wedding Buffet",
      overWrite: "Yes",
      buffetPricePerson: "$15",
    },
    {
      Kitchen_id: "BF04",
      buffetName: "Grand Buffet",
      buffetType: "Lunch Buffet",
      buffetPurpose: "Wedding Buffet",
      overWrite: "Yes",
      buffetPricePerson: "$22",
    },
    {
      Kitchen_id: "BF05",
      buffetName: "Grand Buffet",
      buffetType: "Lunch Buffet",
      buffetPurpose: "Wedding Buffet",
      overWrite: "Yes",
      buffetPricePerson: "$18",
    },
  ];

  const columnDefs = [
    {
      headerName: "Buffet ID",
      field: "Kitchen_id",
      maxWidth: 120,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Buffet Name",
      field: "buffetName",
      maxWidth: 150,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Buffet Type",
      field: "buffetType",
      maxWidth: 150,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Buffet Purpose",
      field: "buffetPurpose",
      maxWidth: 150,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "OverWrite",
      field: "overWrite",
      maxWidth: 120,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Price/Person",
      field: "buffetPricePerson",
      maxWidth: 120,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Actions",
      field: "_id",
      maxWidth: 270,
      minWidth: 100,
      cellRenderer: ViewBuffetActions,
    },
  ];

  return (
    <>
      <div className="relative w-full mx-auto max-w-7xl mt-6">
      <div className="flex items-center justify-between mb-4">
        <div className="text-[22px] leading-[27.72px] text-black font-bold">
          Buffet Table
        </div>
        <div className="flex items-center gap-4">
          <UploadButton />
          <DownloadButton />
        </div>
      </div>
        {datas.length ? (
          <CustomeTable
            columnDefs={columnDefs}
            rowData={rowData}
            setSelectedRows={setSelectedRows}
          />
        ) : null}
      </div>

      {(props?.add && props?.add?.loading) ||
        (props?.delete && props?.delete?.loading && <BackGroundLoadSpinner />)}

      <DeleteOrNot
        formSubmit={deleteKitchen}
        open={open}
        close={() => setOpen(false)}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  kitchen: state.Kitchen,
  add: state.AddKitchen,
  delete: state.DeleteKitchen,
});

const mapDispatchToProps = (dispatch) => ({
  delKitchen: (kitchenId) => dispatch(deleteKitchenAction(kitchenId)),
  getKitchen: (id) => dispatch(getKitchenByHotelIdAction(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AllBuffet);
