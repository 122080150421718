/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createInventoryAction,
  updateItemFromInventory,
} from "../../actions/kitchen/inventoryOwner";
import LoadSpinner from "../../components/loader/LoadSpinner";
import {
  INVENTORY_PURCHASE_FREQUENCY,
  INVENTORY_TYPE, 
  STORAGE_TYPE,
} from "../../constants";

import SaveOrNot from "../../components/layouts/SaveOrNot";
import { registerEvent } from "../../logs";
import { isObjEmpty } from "../../utils";
import { useTranslation } from "react-i18next";
import Inputfield from "../../components/layouts";
import SelectFromObject from "../../components/layouts/SelectFromObject";
import { FiArrowRight } from "react-icons/fi";
import { RxCross2 } from "react-icons/rx"; 

const AddInventory = ({ close, kitchen, hotel, inventory, setInventory }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.AddInventory);
  const { loading: updateLoading, isupdated } = useSelector(
    (state) => state.UpdateInventory
  );
  // const [price, setPrice] = useState("");
  const [open, setOpen] = useState(false);
  //add new state
  const [ingredientName, setIngredientName] = useState("");
  const [inventoryType, setInventoryType] = useState(INVENTORY_TYPE.DAILRY);
  const [storageType, setStorageType] = useState(STORAGE_TYPE.DRY);
  const [currentAvailableQuantity, setCurrentAvailableQuantity] = useState("");
  const [minimumQuantityThresholdAlert, setMinimumQuantityThresholdAlert] =
    useState("");
  const [shelfLife, setShelfLife] = useState('');
  const [vendorID, setVendorID] = useState("");
  const [purchaseFrequency, setPurchaseFrequency] = useState(INVENTORY_PURCHASE_FREQUENCY.DAILY);
  const [unitMeassureAvailableQuantity, setUnitMeassureAvailableQuantity] =
    useState('');
  const [unitMeassureMinimumQuantity, setUnitMeassureMinimumQuantity] =
    useState("");
  //click open
  const handleClickOpen = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  //submiting the form input data.
  const handleSubmit = async () => {
    const formData = {
      kitchenId: kitchen._id,
      ingredientName,
      // name,
      inventoryType,
      // price,
      storageType,
      // images,
      currentAvailableQuantity,
      shelfLife,
      purchaseFrequency,
      vendorID,
      minimumQuantityThresholdAlert,
      unitMeassureAvailableQuantity,
    };
    if (isObjEmpty(inventory)) {
      dispatch(createInventoryAction(formData));
      registerEvent("add inventory", "inventory page", formData);
    } else {
      dispatch(updateItemFromInventory({ ...formData, itemId: inventory?.id }));
      registerEvent("update inventory", "inventory page", formData);
    }
  };

  //close modal
  const closeModal = () => {
    setOpen(false);
    registerEvent("close modal without confirm", "add inventory");
  };

  useEffect(() => {
    if (isupdated) setInventory(null);
  }, [isupdated]);

  useEffect(() => {
    if (!isObjEmpty(inventory)) {
      setIngredientName(inventory?.ingredientName);
      // setName(inventory?.name);
      setInventoryType(inventory?.kitchenItemType);
      // setPrice(inventory?.price);
      setStorageType(inventory?.storageType);
      setCurrentAvailableQuantity(inventory?.setCurrentAvailableQuantity);
      setShelfLife(inventory?.shelfLife);
      setPurchaseFrequency(inventory?.purchaseFrequency);
      setVendorID(inventory?.vendorID);
      setMinimumQuantityThresholdAlert(
        inventory?.minimumQuantityThresholdAlert
      );
    }
  }, [inventory]);

  return (
    <>
      <div className="w-full h-auto mt-6 bg-[#FFFFFF] border border-[#E5E9EB] rounded-lg">
        <div className="w-full flex flex-col relative">
          <div className="text-center font-bold text-[30px] leading-[37.8px] text-[#07090D] tracking-[-1.5%] my-8 capitalize">
            {t("857")}
          </div>
          <button className="absolute right-8 top-10" onClick={close}>
            <RxCross2
              size={26}
              className="hover:rotate-90 duration-500 hover:text-[#F04438]"
            />
          </button>

          {/* form two */}
          <form onSubmit={handleClickOpen} className="w-full px-16">
            <div className="grid grid-cols-2 lg:gap-32 md:gap-24">
              <div className="space-y-6">
                <Inputfield
                  label={t("Ingredient Name")}
                  placeholder={t("Enter Ingredient Name e.g Salt")}
                  value={ingredientName}
                  onChange={(e) => setIngredientName(e.target.value)}
                />
                <SelectFromObject
                  label={t("861")}
                  toolTipMessage={
                    "Select storage type like Dry and Refigerator."
                  }
                  options={STORAGE_TYPE}
                  value={storageType}
                  setValue={setStorageType}
                />

                {/* <TwoInOneInputFields
                  label={t("864")}
                  placeholder={t("865")}
                  message={
                    "Enter minimum quantity threshold alert in gm or kg."
                  }
                  value={minimumQuantityThresholdAlert}
                  onChange={(e) =>
                    setMinimumQuantityThresholdAlert(e.target.value)
                  }
                  min={1}
                  max={10}
                  required={true}
                  options={['']}
                  selectValue={unitMeassureMinimumQuantity}
                  onChangeSelect={(e) =>
                    setUnitMeassureMinimumQuantity(e.target.value)
                  }
                /> */}

                <Inputfield
                  label={t("485")}
                  placeholder={t("682")}
                  value={vendorID}
                  onChange={(e) => setVendorID(e.target.value)}
                />
              </div>
              <div className="space-y-6">
                <SelectFromObject
                  label={t("Inventory Type")}
                  toolTipMessage={
                    "Select inventory type like Grocery,Dry,and Vegitable."
                  }
                  options={INVENTORY_TYPE}
                  value={inventoryType}
                  setValue={setInventoryType}
                />

                {/* <TwoInOneInputFields
                  label={t("862")}
                  placeholder={t("863")}
                  value={currentAvailableQuantity}
                  onChange={(e) => setCurrentAvailableQuantity(e.target.value)}
                  min={1}
                  max={10}
                  required={true}
                  options={['']}
                  selectValue={unitMeassureAvailableQuantity}
                  onChangeSelect={(e) =>
                    setUnitMeassureAvailableQuantity(e.target.value)
                  }
                /> */}

                <SelectFromObject
                  label={t("866")}
                  toolTipMessage={
                    "Select daily,weekly,and monthly to store items."
                  }
                  options={{}}
                  value={shelfLife}
                  setValue={setShelfLife}
                />
                <SelectFromObject
                  label={t("868")}
                  toolTipMessage={
                    "Purchase frequency like daily,weekly,and monthly"
                  }
                  options={INVENTORY_PURCHASE_FREQUENCY}
                  value={purchaseFrequency}
                  setValue={setPurchaseFrequency}
                />
              </div>
            </div>
            <div className="flex justify-end items-center gap-4 my-12">
              <button className="w-[76px] h-10 rounded-md text-sm font-medium border border-[#D0D5DD] bg-[#FFFFFF] hover:bg-slate-100">
                Cancel
              </button>
              <button
                className="flex items-center justify-center gap-2 h-10 w-[232px] bg-base-primary hover:bg-base-primary_hover rounded-md text-[#FFFFFF]"
                disabled={loading || updateLoading}
              >
                <div className="text-[14px] leading-5 font-medium">
                  {loading || updateLoading ? (
                    <LoadSpinner />
                  ) : isObjEmpty(inventory) ? (
                    <>{t("869")}</>
                  ) : (
                    <>{t("336")}</>
                  )}
                </div>
                <FiArrowRight className="font-medium" />
              </button>
            </div>
          </form>
        </div>
      </div>
      <SaveOrNot close={closeModal} isopen={open} formSubmit={handleSubmit} />
    </>
  );
};

export default AddInventory;
