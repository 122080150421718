import React from "react";
import { useState } from "react";
import { CustomeTable } from "../../components";
import RoomCleaningStatus from "./RoomCleaningStatus";
import Assign from "./Assign";
import RoomCleaningTime from "./RoomCleaningTime";
import DownloadButton from "../../components/buttons/DownloadButton";
import UploadButton from "../../components/buttons/UploadButton";

const CheckOutTomarrow = () => {
  const [selectedRows, setSelectedRows] = useState([]);

  const tableData = [
    {
      room_no: "100",
      checkouttime: "10:00 AM",
      cleaningtime: "10:15 AM",
      cleaninstatus: "completed",
      assign_to: "assign",
    },
    {
      room_no: "101",
      checkouttime: "10:00 AM",
      cleaningtime: "10:15 AM",
      cleaninstatus: "In Progress",
      assign_to: "E023 Masud , E024 Rajot",
    },
    {
      room_no: "102",
      checkouttime: "10:00 AM",
      cleaningtime: "10:15 AM",
      cleaninstatus: "completed",
      assign_to: "assign",
    },
    {
      room_no: "103",
      checkouttime: "10:00 AM",
      cleaningtime: "10:15 AM",
      cleaninstatus: "Not Started",
      assign_to: "E023 Masud , E024 Rajot",
    },
    {
      room_no: "104",
      checkouttime: "10:00 AM",
      cleaningtime: "10:15 AM",
      cleaninstatus: "In Progress",
      assign_to: "assign",
    },
  ];

  const columnDefs = [
    {
      headerName: "Room ID",
      field: "_id",
      maxWidth: 150,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Room No",
      field: "room_no",
      minWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Checkout Time",
      field: "checkouttime",
      maxWidth: 150,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Cleaning time",
      field: "cleaningtime",
      cellRenderer: RoomCleaningTime,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Cleaning Status",
      field: "cleaninstatus",
      cellRenderer: RoomCleaningStatus,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "assigned to",
      field: "assign_to",
      cellRenderer: Assign,
      filter: true,
      floatingFilter: true,
    },
  ];
  return (
    <div className="relative">
      <div className="flex items-center justify-between mb-4">
        <div className="text-[22px] leading-[27.72px] text-black font-bold">Checkout Tomorrow table</div>
        <div className="flex items-center gap-4">
          <UploadButton />
          <DownloadButton />
        </div>
      </div>
      <div className="">
        <CustomeTable
          rowData={tableData}
          setSelectedRows={setSelectedRows}
          columnDefs={columnDefs}
        />
      </div>
    </div>
  );
};

export default CheckOutTomarrow;
