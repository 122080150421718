import React from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants";

const PropertyConfigTopButton = ({ name, params, index }) => {
  const navigate = useNavigate();

  const setOptionTabIndex = () => {
    navigate(`${ROUTES.HOTEL_CONFIG}/${index}`);
  };

  return (
      <button
        className={`our-button w-[208px] h-12 last:mr-0 capitalize rounded-lg whitespace-nowrap min-w-fit overflow-auto bg-white ${
          +params !== index
            ? "border-[#E8E8E8] text-black border"
            : "border border-base-primary text-base-primary bg-green-50"
        }`}
        onClick={setOptionTabIndex}
      >
        {name}
      </button>
  );
};

export default PropertyConfigTopButton;
