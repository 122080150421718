import React from "react";
import { BiUser } from "react-icons/bi";
import { IoIosArrowDown } from "react-icons/io";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ROUTES } from "../../constants";

const ProfileButton = (props) => {
  return (
    <>
      {props?.owner && props?.owner?.owner && props?.owner?.owner?.name ? (
        <Link
          to={ROUTES.MY_PROFILE}
          className="select-none  flex items-center justify-center"
        >
          <div className="text-2xl text-[#FFFFFF] rounded-full h-8 w-8 min-w-[32px] bg-[#152238] flex items-center justify-center">
            <BiUser />
          </div>
          <span className="hidden md:flex">
            <span className="font-semibold text-[20px] whitespace-nowrap mx-2 capitalize">
              {props?.owner && props?.owner?.owner && props?.owner?.owner?.name
                ? props?.owner?.owner?.name
                : null}
            </span>
            <span className="mt-2">
              <IoIosArrowDown />
            </span>
          </span>
        </Link>
      ) : props?.employee &&
        props?.employee?.employee &&
        props?.employee?.employee?.name ? (
        <Link
          to={ROUTES.MY_PROFILE}
          className="select-none  flex items-center justify-center"
        >
          <div className="text-2xl text-white rounded-full h-8 w-8 min-w-[32px] bg-[#152238] flex items-center justify-center">
            <BiUser />
          </div>
          <span className="font-semibold text-[20px] mx-2 whitespace-nowrap capitalize">
            {props?.employee &&
            props?.employee?.employee &&
            props?.employee?.employee?.name
              ? props?.employee?.employee?.name
              : null}
          </span>
          <span>
            <IoIosArrowDown />
          </span>
        </Link>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => ({
  owner: state.Authenticate,
  employee: state.EmployeeAuth,
});

export default connect(mapStateToProps)(ProfileButton);
