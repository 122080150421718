import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import RefreshLoader from "../components/loader/RefreshLoader";
import SideMenu from "../components/SideBar/SideMenu";
import TopBar from "../components/SideBar/TopBar";
import ApiCallsBefore from "./ApiCallsBefore";
import AuthenticateUser from "./AuthenticateUser";
import CheckIsConfigured from "./CheckIsConfigured";
import Myhotels from "./Myhotels";
import PageDetail from "./PageDetail";
import SetAvailableRooms from "./SetAvailableRooms";
import CheckOnlineOffilne from "./CheckOnlineOffilne";
import MetaData from "../utils/MetaData";
import { useTranslation } from "react-i18next";

const AdminAndEmployeeRoute = (props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [isConfigured, setIsConfigured] = useState(false);
  const [configurePercentage, setConfigurePercentage] = useState(0);
  const [title, setTitle] = useState("Kunggy");
  const { isadmin } = useSelector((state) => state.IsAdmin);
  const { hamburger } = useSelector((state) => state.Hamburger);

  return (
    <>
      <MetaData title={loading ? `${t("Loading...")}` : `${title}`} />
      {/* 1. api calls */}
      {props?.hotel && props?.hotel?.hotel && props?.hotel?.hotel?._id ? (
        <>
          <ApiCallsBefore
            setIsConfigured={setIsConfigured}
            isConfigured={isConfigured}
            setConfigurePercentage={setConfigurePercentage}
            setLoading={setLoading}
          />
          {isadmin ? (
            <CheckIsConfigured
              setIsConfigured={setIsConfigured}
              hotel={props?.hotel?.hotel}
              configurePercentage={configurePercentage}
              setConfigurePercentage={setConfigurePercentage}
            />
          ) : null}
        </>
      ) : null}
      <SetAvailableRooms />
      {isadmin ? <Myhotels /> : null}

      <AuthenticateUser setIsConfigured={setIsConfigured} />
      <PageDetail setTitle={setTitle} isadmin={isadmin} />
      {/* 2. view components */}
      {loading ? (
        <RefreshLoader />
      ) : (
        <>
          <TopBar
            isConfigured={isConfigured}
            hotel={props.hotel.hotel} 
            setTheme={props.setTheme}
            configurePercentage={configurePercentage}
            isadmin={isadmin}
          />
          <SideMenu
            isConfigured={isConfigured}
            hotel={props?.hotel?.hotel} 
            isadmin={isadmin}
          />
          <div
            className={`${
              hamburger ? "lg:left-[266px] lg:w-[calc(100%-266px)]" : ""
            } relative w-full left-0 duration-300`}
          >
            <Outlet />
            {/* <BottomBar hotel={props?.hotel?.hotel} /> */}
          </div>
        </>
      )}
      {/* check page is online or not */}
      <CheckOnlineOffilne />
    </>
  );
};

const mapStateToProps = (state) => ({
  owner: state.Authenticate,
  employee: state.EmployeeAuth,
  profile: state.OwnerProfile,
  rooms: state.AllRoom,
  hotel: state.Hotel,
  booking: state.AllBooking,
});

export default connect(mapStateToProps)(AdminAndEmployeeRoute);
