import React from "react";
import { useTranslation } from "react-i18next";
import Seperator from "../../components/layouts/Seperator";
import { ROUTES } from "../../constants";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import DetailsHeading from "../../components/layouts/DetailsHeading";

function VendorDetails() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const data = {
    heading: t("Details"),
    seperator: [
      {
        text: t("134"),
      },
      {
        link: ROUTES.VENDORS,
        text: t("233"),
      },
      { text: t("Details") },
    ],
  };
  const backToVendors = () => {
    navigate(ROUTES.VENDORS);
  };
  return (
    <>
      <div className="page-container">
        <div className="w-full max-w-7xl mx-auto">
          <div className="flex justify-between items-end">
            <Seperator data={data} />
            <div className="flex gap-2">
              <button
                className="h-10 w-[89px] flex justify-center gap-2 items-center border border-[#DDE2E4] rounded-md bg-[#FFFFFF]"
                onClick={backToVendors}
              >
                <BiArrowBack size={15} />
                <div className="text-[14px] leading-6 font-normal tracking-[-0.6%]">
                  {t("Back")}
                </div>
              </button>
            </div>
          </div>
        </div>
        {/* Company Information */}
        <div className="my-4 text-[24px] leading-[30.24px] font-bold tracking-[-0.8px]">
          {t("Company Information")}
        </div>
        <div className="border border-[#D0D5DD] rounded-[10px] bg-[#FFFFFF] grid grid-cols-4 p-6 gap-4">
          <DetailsHeading heading={"Name"} subHeading={"Amul"} />
          <DetailsHeading heading={"Email"} subHeading={"amul@gmail.com"} />
          <DetailsHeading
            heading={"Contact Number"}
            subHeading={"+91-214214535"}
          />
          <DetailsHeading
            heading={"Tax Identification Number"}
            subHeading={"NNN-NN-NNNN"}
          />
          <DetailsHeading heading={"Country"} subHeading={"India"} />
          <DetailsHeading heading={"State"} subHeading={"Maharashtra"} />
          <DetailsHeading heading={"City"} subHeading={"Mumbai"} />
          <DetailsHeading
            heading={"Address"}
            subHeading={"93, Aurbindo Apartment, Adchini"}
          />
          <DetailsHeading
            heading={"Vendor to Hotel Distance"}
            subHeading={"12 km"}
          />
        </div>
        <div className="my-4 text-[24px] leading-[30.24px] font-bold tracking-[-0.8px]">
          {t("Vendor Information")}
        </div>
        <div className="border border-[#D0D5DD] rounded-[10px] bg-[#FFFFFF] grid grid-cols-3 p-6 gap-4">
          <DetailsHeading heading={"Name"} subHeading={"Rajveer"} />
          <DetailsHeading heading={"Email"} subHeading={"raj@gmail.com"} />
          <DetailsHeading
            heading={"Contact Number"}
            subHeading={"+91-214214535"}
          />
          <DetailsHeading
            heading={"Product Type"}
            subHeading={"Grocery, Vegetable"}
          />
          <DetailsHeading
            heading={"Product Name"}
            subHeading={"Potato, Tomato"}
          />
          <DetailsHeading
            heading={"Average Delivery Time"}
            subHeading={"1 Hour"}
          />
        </div>
      </div>
    </>
  );
}

export default VendorDetails;
