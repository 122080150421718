import React from "react"; 
import { registerEvent } from "../../logs";
import { useTranslation } from "react-i18next";

export default function DeleteOrNot({ formSubmit, open, close }) {
  const { t } = useTranslation();
  //agree to delete
  const agree = () => {
    formSubmit();
    close();
    registerEvent("agree to delete", "delete or not delete modal");
  };

  //disagree to delete
  const disagree = () => {
    close();
    registerEvent("disagree to delete", "delete or not delete modal");
  };

  return (
    <>
      {/* <Dialog
        open={open}
        onClose={close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="w-full max-w-[90vw] sm:max-w-md">
          <DialogTitle id="alert-dialog-title">{t("669")}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t("670")}
            </DialogContentText>
          </DialogContent>
          <DialogActions className="p-4">
            <Button
              onClick={disagree}
              style={{ background: "#2fa05a", color: "white" }}
              className="rounded-sm py-1 hover:bg-[#257e47]"
            >
              {t("258")}
            </Button>
            <Button
              onClick={agree}
              autoFocus
              style={{ background: "#ff3853", color: "white" }}
              className="rounded-sm py-1 hover:bg-[#fa223f]"
            >
              {t("63")}
            </Button>
          </DialogActions>
        </div>
      </Dialog> */}
    </>
  );
}
