import React, { useState } from "react";
import SaveOrNot from "../../components/layouts/SaveOrNot";
import { connect, useDispatch } from "react-redux";
import { addKitchenAction } from "../../actions/kitchen/kitchen";
import { useTranslation } from "react-i18next";
import Inputfield from "../../components/layouts";
import PhoneWithCode from "../../components/layouts/PhoneWithCode"; 
import SelectFromArray from "../../components/layouts/SelectFromArray";
import { CLEAR_ERRORS } from "../../actions/types";
import { notifySuccess } from "../../components/alert";
import { ADD_KITCHEN_RESET } from "../../actions/typesKitchen/kitchen";
import { useEffect } from "react";
import AddSubmitButton from "../../components/buttons/AddSubmitButton";
import { RxCross2 } from "react-icons/rx";

const AddKitchen = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  //add new state
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [manager, setManager] = useState("");
  const [managerContact, setManagerContact] = useState("");
  const [headChef, setHeadChef] = useState("");
  const [headChefContact, setHeadChefContact] = useState("");
  const [inventoryManager, setInventoryManager] = useState("");
  const [inventoryManagerContact, setInventoryManagerContact] = useState("");
  const [buffetManager, setBuffetManager] = useState("");
  const [buffetManagerContact, setBuffetManagerContact] = useState("");
  const [menuManager, setMenuManager] = useState("");
  const [menuManagerContact, setMenuManagerContact] = useState("");
  useState("");

  //click open
  const handleClickOpen = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  const handleSubmit = () => {
    setOpen(false);
    props.addKitchen({
      hotelId: props?.hotel?._id,
      name,
      address,
      images: [
        {
          id: "64ba543306370748f2930b54",
          url: "https://ik.imagekit.io/rzb2n7n7p/hotelMainPhoto_hhKKVUXpX",
        },
        {
          id: "64ba544706370748f2936662",
          url: "https://ik.imagekit.io/rzb2n7n7p/hotelPhotos_EuV9MUASr",
        },
      ],
    });
  };

  useEffect(() => {
    if (props?.kitchen?.error) {
      dispatch({ type: CLEAR_ERRORS });
    }
    if (props?.kitchen?.added) {
      notifySuccess("1043");
      dispatch({ type: ADD_KITCHEN_RESET });
    }
  }, [props?.kitchen]);

  return (
    <>
      <div className="px-8 py-6 max-w-[928px] h-[732px] flex flex-col border border-[#d0d5dde8] rounded-lg bg-white relative mt-4 mx-auto">
        <div className="font-bold text-center text-[30px] leading-[37.8px] text-[#07090D] tracking-[-1.5%] mb-8">
          {t("1044")}
        </div>
        <button className="absolute top-9 right-12" onClick={props.close}>
          <RxCross2
            size={26}
            className="hover:rotate-90 duration-500 hover:text-[#F04438]"
          />
        </button>

        <form onSubmit={handleClickOpen} className="w-full px-16">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-7">
            <Inputfield
              label={t("1045")}
              placeholder={t("1046")}
              value={name}
              onChange={(e) => setName(e.target.value)}
              required={true}
              minLength={3}
              maxLength={30}
            />
            <SelectFromArray
              label={t("1047")}
              toolTipMessage={t("1048")}
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              options={['']}
            />
            <Inputfield
              label={t("1049")}
              placeholder={t("1050")}
              value={manager}
              onChange={(e) => setManager(e.target.value)}
              minLength={3}
              maxLength={30}
            />
            <PhoneWithCode
              phone={managerContact}
              setPhone={setManagerContact}
              label={t("527")}
              placeholder={t("769")}
            />
            <Inputfield
              label={t("1051")}
              placeholder={t("1052")}
              value={headChef}
              onChange={(e) => setHeadChef(e.target.value)}
              minLength={3}
              maxLength={30}
            />
            <PhoneWithCode
              phone={headChefContact}
              setPhone={setHeadChefContact}
              label={t("527")}
              placeholder={t("769")}
            />
            <Inputfield
              label={t("1053")}
              placeholder={t("1054")}
              value={inventoryManager}
              onChange={(e) => setInventoryManager(e.target.value)}
              minLength={3}
              maxLength={30}
            />
            <PhoneWithCode
              phone={inventoryManagerContact}
              setPhone={setInventoryManagerContact}
              label={t("527")}
              placeholder={t("769")}
            />
            <Inputfield
              label={t("1055")}
              placeholder={t("1056")}
              value={buffetManager}
              onChange={(e) => setBuffetManager(e.target.value)}
              minLength={3}
              maxLength={30}
            />
            <PhoneWithCode
              phone={buffetManagerContact}
              setPhone={setBuffetManagerContact}
              label={t("527")}
              placeholder={t("769")}
            />
            <Inputfield
              label={t("1057")}
              placeholder={t("1058")}
              value={menuManager}
              onChange={(e) => setMenuManager(e.target.value)}
              minLength={3}
              maxLength={30}
            />
            <PhoneWithCode
              phone={menuManagerContact}
              setPhone={setMenuManagerContact}
              label={t("527")}
              placeholder={t("769")}
            />
          </div>
          <div className="flex items-center justify-end pt-7">
            <AddSubmitButton
              text={t("1059")}
              loading={props?.kitchen?.loading}
            />
          </div>
        </form>
      </div>
      <SaveOrNot
        formSubmit={handleSubmit}
        isopen={open}
        close={() => setOpen(false)}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  kitchen: state.AddKitchen,
});

const mapDispatchToProps = (dispatch) => ({
  addKitchen: (data) => dispatch(addKitchenAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddKitchen);
