import React, { useState } from "react";
import Seperator from "../../components/layouts/Seperator";
import { useTranslation } from "react-i18next";
import { CustomeTable } from "../../components";
import QrPrints from "../../components/modals/QrPrints";
import qrcodePrints from "../../images/users/qrcodePrints.png";

const AllQRCode = (props) => {
  const { t } = useTranslation();
  const [selectedRows, setSelectedRows] = useState([]);
  const [isOpen, setIsOpen] = useState(false)

  const open = () => {
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
  };

  const printData = () => {
   
    return <>
      <button
        type="button"
        className="h-8 w-[90px] rounded-md shadow-[0_1px_2px_0_rgba(16,24,40,0.05)] bg-base-primary hover:bg-base-primary_hover text-sm font-medium text-white"
        onClick={open} >
        Print
      </button>
     
     
    </>
  }
  const showQRCodeImage = (params) => {
    return (
      // first save qrcode file in svg then fetch svg to display
      <div className="p-[0.5px] border-[0.5px] h-10 w-10 rounded-sm">
        <img src={qrcodePrints} alt="" className="object-cover" />
      </div>
    );
  };

  const rowData = [
    {
      _id: 1255472734423,
      qrCode: "image",
      roomNo: "101",
      roomType: "Delux",
      numberOfScanForFoodOrder: 3,
      status: "Active",
    },
    {
      _id: 12423,
      qrCode: "image",
      roomNo: "102",
      roomType: "Delux",
      numberOfScanForFoodOrder: 3,
      status: "Inactive",
    },
    {
      _id: 1255423,
      qrCode: "image",
      roomNo: "101",
      roomType: "Delux",
      numberOfScanForFoodOrder: 3,
      status: "Active",
    },
    {
      _id: 1255423,
      qrCode: "image",
      roomNo: "104",
      roomType: "Delux",
      numberOfScanForFoodOrder: 3,
      status: "Active",
    },
    {
      _id: 1255423,
      qrCode: "image",
      roomNo: "105",
      roomType: "Delux",
      numberOfScanForFoodOrder: 3,
      status: "Active",
    },
  ];

  const columnDefs = [
    {
      headerName: "Qr Code ID",
      field: "_id",
      maxWidth: 250,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Qr Code",
      field: "qrCode",
      maxWidth: 300,
      cellRenderer: showQRCodeImage
    },
    {
      headerName: "Room No",
      field: "roomNo",
      maxWidth: 300,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Room Type",
      field: "roomType",
      maxWidth: 300,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "No. of Scane For Food Order",
      field: "numberOfScanForFoodOrder",
      maxWidth: 300,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Status",
      field: "status",
      maxWidth: 300,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Actions",
      field: "_id",
      maxWidth: 350,
      cellRenderer: printData
    },
  ];

  const headingData = {
    heading: t("QR Code Generator"),
    seperator: [
      {
        text: t("75"),
      },
      {
        text: t("QR Code"),
      },
    ],
  };
  return (
      <>
      {isOpen ? <QrPrints open={open} setIsOpen = {setIsOpen} close={close} />:null}
      <div className="page-container max-w-7xl">
        <div className="w-full">
          <Seperator data={headingData} />
        </div>
        <div className="w-full mt-6">
          <CustomeTable
            rowData={rowData}
            setSelectedRows={setSelectedRows}
            columnDefs={columnDefs}
          />
        </div>
      </div>
      </>
  );
};
export default AllQRCode;
