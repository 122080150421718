/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";  
import { useTranslation } from "react-i18next";
import {
  bookingSalesReportByDate,
  bookingSalesReportByMonth,
  bookingSalesReportByYear,
} from "../../actions/reports/salesReportBy";
import { clearError } from "../../actions/clearError";
import { getBookingSalesReport } from "../../actions/reports/salesReport";
import { connect, useSelector } from "react-redux";

const RoomTopBookingSource = () => {
  const [datas, setDatas] = useState([]);

  const { sales } = useSelector((state) => state.SalesReport);
  const { t } = useTranslation();
  useEffect(() => {
    if (sales && Array.isArray(sales) && sales?.length) {
      let expense = sales?.map((d) => {
        return {
          sourceName: d?.sourceName,
          bookingNo: d?.bookingNo,
        };
      });
      setDatas(expense);
    } else {
      setDatas([
        {
          sourceName: null,
          bookingNo: null,
        },
      ]);
    }
  }, [sales]);

  const tableData = [
    {
      sourceName: "Air bnb",
      bookingNo: "987",
    },
    {
      sourceName: "Funotel",
      bookingNo: "987",
    },
    {
      sourceName: "Booking.com",
      bookingNo: "987",
    },
    {
      sourceName: "Hotka",
      bookingNo: "987",
    },
    {
      sourceName: "Gozayan",
      bookingNo: "987",
    },
    {
      sourceName: "Trip Plus",
      bookingNo: "987",
    },
    {
      sourceName: "Booking.com",
      bookingNo: "987",
    },
    {
      sourceName: "Air bnb",
      bookingNo: "987",
    },
    {
      sourceName: "Funotel",
      bookingNo: "987",
    },
    {
      sourceName: "Booking.com",
      bookingNo: "987",
    },
    {
      sourceName: "Hotka",
      bookingNo: "987",
    },
    {
      sourceName: "Gozayan",
      bookingNo: "987",
    },
    {
      sourceName: "Trip Plus",
      bookingNo: "987",
    },
    {
      sourceName: "Booking.com",
      bookingNo: "987",
    },
    {
      sourceName: "Air bnb",
      bookingNo: "987",
    },
    {
      sourceName: "Funotel",
      bookingNo: "987",
    },
    {
      sourceName: "Booking.com",
      bookingNo: "987",
    },
    {
      sourceName: "Hotka",
      bookingNo: "987",
    },
    {
      sourceName: "Gozayan",
      bookingNo: "987",
    },
    {
      sourceName: "Trip Plus",
      bookingNo: "987",
    },
    {
      sourceName: "Booking.com",
      bookingNo: "987",
    },
    {
      sourceName: "Air bnb",
      bookingNo: "987",
    },
    {
      sourceName: "Funotel",
      bookingNo: "987",
    },
    {
      sourceName: "Booking.com",
      bookingNo: "987",
    },
    {
      sourceName: "Hotka",
      bookingNo: "987",
    },
    {
      sourceName: "Gozayan",
      bookingNo: "987",
    },
    {
      sourceName: "Trip Plus",
      bookingNo: "987",
    },
    {
      sourceName: "Booking.com",
      bookingNo: "987",
    },
    {
      sourceName: "Air bnb",
      bookingNo: "987",
    },
    {
      sourceName: "Funotel",
      bookingNo: "987",
    },
    {
      sourceName: "Booking.com",
      bookingNo: "987",
    },
    {
      sourceName: "Hotka",
      bookingNo: "987",
    },
    {
      sourceName: "Gozayan",
      bookingNo: "987",
    },
    {
      sourceName: "Trip Plus",
      bookingNo: "987",
    },
    {
      sourceName: "Booking.com",
      bookingNo: "987",
    },
  ];
   
  return (
    <div>
      <div className="border-b border-[#E5E9EB] h-[80px]">
        <div className="text-[18px] leading-[22.68px] tracking-[-0.8px] text-[#000000] font-medium mx-5 mt-4">
          Top Booking Source
        </div>
        <div className="flex items-center justify-between px-5 text-[14px] leading-6 font-normal text-[#6E7C87] tracking-[-0.6%] mt-5">
          <div className="">Source Name</div>
          <div className="">Booking No</div>
        </div>
      </div>
      <div className="overflow-y-auto h-[360px] w-full scroll-bar3">
        {/* <DataTable3
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          rows={rows}
          prepareRow={prepareRow}
        /> */}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  sales: state.SalesReport,
});

const mapDispatchToProps = (dispatch) => ({
  getAllSales: (hotelId) => dispatch(getBookingSalesReport(hotelId)),
  //get booking sales by date
  byDateSales: (hotelId, fromDate, toDate) =>
    dispatch(bookingSalesReportByDate(hotelId, fromDate, toDate)),
  //get booking sales by month
  byMonthSales: (hotelId, fromMonth, toMonth) =>
    dispatch(bookingSalesReportByMonth(hotelId, fromMonth, toMonth)),
  //booking sales by year
  byYearSales: (hotelId, fromYear, toYear) =>
    dispatch(bookingSalesReportByYear(hotelId, fromYear, toYear)),
  clear: () => dispatch(clearError()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RoomTopBookingSource);
