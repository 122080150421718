import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { ID_NUMBER, ROUTES } from "../../constants";
import { useNavigate } from "react-router-dom";
import DelIcon from "../../images/icons/DelIcon.svg";
import EditIcon from "../../images/icons/EidIcon.svg";
import CustomeTable from "../../components/CustomeTable";
import PrintButton from "../../components/buttons/PrintButton";
import UploadButton from "../../components/buttons/UploadButton";
import DownloadButton from "../../components/buttons/DownloadButton";

const AllExpense = (props) => {
  const [datas, setDatas] = useState([]);
  const { expenses } = useSelector((state) => state.AllExpense);
  const [selectedRows, setSelectedRows] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (expenses && Array.isArray(expenses) && expenses?.length) {
      let expense = expenses?.map((d) => {
        return {
          expenseID: d?.expenseID,
          name: d?.name,
          type: d?.type,
          date: d?.date,
          frequency: d?.frequency,
          amount: d?.amount,
          paymentDate: d?.paymentDate,
          invoiceNumber: d?.invoiceNumber,
          _id: d?._id,
          // note:d?.note,
        };
      });
      setDatas(expense);
    }
  }, [expenses]);

  const viewExpenseActions = () => {
    const viewExpenseDetails = (id) => {
      if (id) {
        sessionStorage.setItem(ID_NUMBER, id);
        navigate(ROUTES.EXPENSE);
      }
    };
    return (
      <div className="flex items-center gap-4 mt-[5px]">
        <button
          className="text-[14px] leading-6 font-normal tracking-[-0.6%] text-base-primary hover:text-base-primary_hover"
          onClick={(id) => viewExpenseDetails(id)}
        >
          View Details
        </button>
        <button
          className="w-[30px] h-[30px] border border-[#E5E9EB] bg-white rounded-full flex items-center justify-center hover:bg-[#E5E9EB]"
          // onClick={deleteRoaster}
        >
          <img src={DelIcon} alt="" />
        </button>
        <button
          className="w-[30px] h-[30px] border border-[#E5E9EB] bg-white rounded-full flex items-center justify-center hover:bg-[#E5E9EB]"
          // onClick={updateRoaster}
        >
          <img src={EditIcon} alt="" />
        </button>
      </div>
    );
  };

  const columnDefs = [
    {
      headerName: "Expense ID",
      field: "expenseID",
      maxWidth: 150,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Name",
      field: "name",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Type",
      field: "type",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Date",
      field: "date",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Frequency",
      field: "frequency",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Amount",
      field: "amount",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Payment Date",
      field: "paymentDate",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Invoice Number",
      field: "invoiceNumber",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Actions",
      field: "_id",
      minWidth: 200,
      cellRenderer: viewExpenseActions,
    },
  ];

  return (
    <>
      <div className="flex items-center justify-between mt-6">
        <div className="text-[22px] leading-[27.72px] text-black font-bold">
          Expense Table
        </div>
        <div className="flex items-center gap-4">
          <PrintButton />
          <UploadButton />
          <DownloadButton />
        </div>
      </div>
      <div className="mt-4">
        {/* {datas?.datas?.length ? ( */}
        <CustomeTable
          columnDefs={columnDefs}
          rowData={datas}
          setSelectedRows={setSelectedRows}
        />
        {/* ) : null} */}
      </div>
      {/* <DeleteOrNot
        open={open}
        close={notDeleteExpense}
        formSubmit={deleteExpenseConfirm}
      /> */}
    </>
  );
};

export default AllExpense;