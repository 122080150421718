const RefreshLoader = () => {
  return (
    <>
      <div
        className={`fixed top-0 left-0 w-screen h-screen flex items-center justify-center z-[1100]`}
      >
        <div className="h-9 w-9 rounded-full border-4 border-base-primary border-t-[#ffffff34] animate-spin relative"></div>
      </div>
    </>
  );
};

export default RefreshLoader;
