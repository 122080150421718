import React from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { notifyError } from "../components/alert";
import Header from "../components/Header";
import { ISADMIN, ROUTES, TOKEN } from "../constants";

const OnBoardingRoutes = () => {
  const navigate = useNavigate();
  const { error, owner } = useSelector((state) => state.Authenticate);

  const redirect = async () => {
    const token = localStorage.getItem(TOKEN);
    const isadmin = localStorage.getItem(ISADMIN);
    if (typeof token !== "string" || error) {
      navigate(ROUTES.REGISTER);
    }
    if (owner === null) {
      navigate(ROUTES.LOGIN);
    }
    //if not admin than redirect
    if (!isadmin) {
      notifyError("You don't have permission for this!");
      navigate(ROUTES.DASHBOARD);
    }
  };
  // useEffect(() => {
  //   redirect();
  // }, [error]);

  return (
    <>
      <Header />
      <Outlet />
    </>
  );
};

export default OnBoardingRoutes;
