import React from "react";

function DetailsPrefences(props) {
  return (
    <>
      <div>
        <div className="text-[20px] leading-[25px] font-semibold tracking-[-0.8px] mb-2">
          {props.Name}
        </div>
        <div className="text-[14px] leading-[17.64px] font-medium text-[#6E7C87] space-y-3">
          <div>{props.serviceName1}</div>
          <div>{props.serviceName2}</div>
          <div>{props.serviceName3}</div>
          <div>{props.serviceName4}</div>
        </div>
      </div>
    </>
  );
}

export default DetailsPrefences;
