export const GET_ALL_OCCUPANCY_REQ = "GET_ALL_OCCUPANCY_REQ";
export const GET_ALL_OCCUPANCY_SUC = "GET_ALL_OCCUPANCY_SUC";
export const GET_ALL_OCCUPANCY_FAIL = "GET_ALL_OCCUPANCY_FAIL";

//get occupancy by - date, month and year
export const OCCUPANCY_BY_DATE_REQ = "OCCUPANCY_BY_DATE_REQ";
export const OCCUPANCY_BY_DATE_SUC = "OCCUPANCY_BY_DATE_SUC";
export const OCCUPANCY_BY_DATE_FAIL = "OCCUPANCY_BY_DATE_FAIL";

export const OCCUPANCY_BY_MONTH_REQ = "OCCUPANCY_BY_MONTH_REQ";
export const OCCUPANCY_BY_MONTH_SUC = "OCCUPANCY_BY_MONTH_SUC";
export const OCCUPANCY_BY_MONTH_FAIL = "OCCUPANCY_BY_MONTH_FAIL";

export const OCCUPANCY_BY_YEAR_REQ = "OCCUPANCY_BY_YEAR_REQ";
export const OCCUPANCY_BY_YEAR_SUC = "OCCUPANCY_BY_YEAR_SUC";
export const OCCUPANCY_BY_YEAR_FAIL = "OCCUPANCY_BY_YEAR_FAIL";
