import { CHANGE_HAMBURGER } from "../../actions/types";

export const hamburgerReducer = (state = { hamburger: true }, action) => {
  switch (action.type) {
    case CHANGE_HAMBURGER:
      return {
        hamburger: action.payload,
      };
    default:
      return state;
  }
};
