import React from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants";
import { useTranslation } from "react-i18next";
import com_success from "./images/com_success.png";
import { FiArrowRight } from "react-icons/fi";

const Step5 = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const gotoDashboard = () => {
    navigate(ROUTES.DASHBOARD);
  };

  return (
    <>
      <div className="flex items-start flex-col w-full px-4 sm:px-6">
        <div>
          <div className="flex justify-center items-center pt-6">
            <img src={com_success} alt="" className="w-[270px] h-[162.05px]" />
          </div>
          <div className="py-4 text-center text-[26.7px] leading-[17.8px] font-medium text-[#00A35E]">
            {t("1094")}
          </div>
          <div className="text-center text-xl sm:text-3xl md:text-[40px] capitalize font-bold leading-[48px] pt-6">
            {t("926")}
          </div>
          <div className="text-center text-[#000000] leading-[24px] text-[24px] sm:text-[20px] pt-4 pb-6">
            {t("927")}
            <span className="pl-1">{t("928")}</span>
          </div>

          <div className="relative flex justify-center w-full items-center text-sm font-medium">
            {/* <button
              className="our-button w-32 capitalize rounded-md bg-white text-black border border-[#E5E9EB] flex justify-between"
              onClick={() => props.setStep(4)}
            >
              <FiArrowLeft />
              {t("65")}
            </button> */}
            <button
              className="w-[185px] h-12 rounded-md flex justify-center items-center gap-4 capitalize bg-base-primary shadow-btn hover:bg-base-primary_hover text-[#FFFFFF]"
              onClick={() => gotoDashboard()}
            >
              <>
                {t("891")}
                <FiArrowRight size={18} />
              </>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step5;
