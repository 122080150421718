import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useDispatch } from "react-redux";
import { verifyPropertyAction } from "../../actions/pms/verifyPropety";
import { AiOutlineSearch } from "react-icons/ai";

const AddressAutoComplete = ({address, setAddress, required = false}) => {
  const dispatch = useDispatch();

  const handleChange = (address) => {
    setAddress(address);
  };

  const handleSelect = (address) => {
    setAddress(address);
    if (address) {
      geocodeByAddress(address)
        .then((results) => getLatLng(results[0]))
        .then((latLng) => {})
        .catch((error) => {});
      dispatch(verifyPropertyAction(address));
    }
  };

  return (
    <div className="relative flex flex-col w-full">
      <PlacesAutocomplete
        value={address ? address : ""}
        onChange={handleChange}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="relative">
            <input
              required={required}
              {...getInputProps({
                placeholder: "Search",
                className: `placeholder:text-[16px] placeholder:leading-6 placeholder:font-normal placeholder:text-[#667085] pl-9`,
              })}
            />
            <AiOutlineSearch className="absolute top-4 left-3 text-[#A1A1AA]" />

            <div className="w-full bg-white absolute font top-full left-0 z-40 shadow">
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion, index) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                const style = suggestion.active
                  ? { backgroundColor: "#0000000c", cursor: "pointer" }
                  : { backgroundColor: "#ffffff", cursor: "pointer" };
                return (
                  <div
                    key={index}
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                    className="p-2"
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  );
};

export default AddressAutoComplete;
