import React from "react";

function DetailsHeading({ heading, subHeading}) {
  return (
    <>
      <div>
        <div className="text-[14px] text-[#6E7C87] leading-[17.64px] font-medium tracking-[-0.8px]">
          {heading}
        </div>
        <div className="text-[18px] text-[#07090D] leading-[22.68px] font-normal tracking-[-0.8px]">
          {subHeading}
        </div>
      </div>
    </>
  );
}

export default DetailsHeading;
