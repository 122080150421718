import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteMenuItem } from "../../actions/kitchen/menu";
import BackGroundLoadSpinner from "../../components/loader/BackGroundLoadSpinner";
import DeleteOrNot from "../../components/layouts/DeleteOrNot";
import { CustomeTable } from "../../components";
import { useTranslation } from "react-i18next";
import DelIcon from "../../images/icons/DelIcon.svg";
import EidIcon from "../../images/icons/EidIcon.svg";
import UploadButton from "../../components/buttons/UploadButton";
import DownloadButton from "../../components/buttons/DownloadButton";
import PrintButton from "../../components/buttons/PrintButton";

const AllInventory = ({
  kitchen,
  setInventory,
  hotel,
  isadmin,
  updateInventory,
  isShowUpdate,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [datas, setDatas] = useState([]);
  const [itemId, setItemId] = useState("");
  const { inventories } = useSelector((state) => state.AllInventory);
  const { loading: delLoading } = useSelector((state) => state.DeleteMenuItem);
  const [open, setOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  //const set modified data
  const setModifiedData = (inventories) => {
    let newData = [];
    if (inventories && Array.isArray(inventories)) {
      // eslint-disable-next-line array-callback-return
      inventories?.map((item) => {
        newData.push({
          itemID: item?.itemID,
          // name: item?.name,
          itemName: item?.itemName,
          kitchenItemType: item?.kitchenItemType,
          price: item?.price,
          storageType: item?.item?.storageType,
          currentAvailableQuantity: item?.currentAvailableQuantity,
          shelfLife: item?.shelfLife,
          purchaseFrequency: item?.purchaseFrequency,
          vendorID: item?.vendorID,
          id: item?.id,
        });
      });
      if (newData?.length) {
        setDatas(newData);
      }
    }
  };
  useEffect(() => {
    setModifiedData(inventories);
  }, [inventories]);

  // set inventory for update
  const setInventoryForUpdate = (id) => {
    // eslint-disable-next-line array-callback-return
    inventories?.map((item) => {
      if (item.id === id) {
        setInventory(item);
      }
    });
  };

  // delete inventory confirmation modal open
  const openModal = (id) => {
    if (id) {
      setOpen(true);
      setItemId(id);
    }
  };

  // delete confirm
  const confirmDelete = () => dispatch(deleteMenuItem(kitchen._id, itemId));

  //delete not confirm
  const closeModal = () => {
    setOpen(false);
    setItemId(null);
  };
  const inventryEditBtn = (value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setInventoryForUpdate(value);
  };

  const rowData = [
    {
      itemID: "IN01",
      itemName: "Salt",
      kitchenItemType: "Grocery",
      price: "+2%",
      storageType: "Dry",
      currentAvailableQuantity: "2 Kg",
      shelfLife: "6 months",
      purchaseFrequency: "Weekly",
      vendorID: "V01,V02",
    },
    {
      itemID: "IN02",
      itemName: "Cheese",
      kitchenItemType: "Dairy",
      price: "-10%",
      storageType: "Refrigerator",
      currentAvailableQuantity: "4 Kg",
      shelfLife: "12 days",
      purchaseFrequency: "Daily",
      vendorID: "V01,V02",
    },
    {
      itemID: "IN03",
      itemName: "Butter",
      kitchenItemType: "Dairy",
      price: "+1%",
      storageType: "Refrigerator",
      currentAvailableQuantity: "5 Kg",
      shelfLife: "16 days",
      purchaseFrequency: "Daily",
      vendorID: "V012,V024,V079",
    },
    {
      itemID: "IN04",
      itemName: "Tomato",
      kitchenItemType: "Vegetable",
      price: "+10%",
      storageType: "Refrigerator",
      currentAvailableQuantity: "8 Kg",
      shelfLife: "1 week",
      purchaseFrequency: "Monthly",
      vendorID: "V031",
    },
    {
      itemID: "IN05",
      itemName: "Potato",
      kitchenItemType: "Vegetable",
      price: "-5%",
      storageType: "Dry",
      currentAvailableQuantity: "Out of stock",
      shelfLife: "2 Weeks",
      purchaseFrequency: "Monthly",
      vendorID: "V01,V02",
      _id: "",
    },
  ];

  // view all inventory action
  const viewAllInventoryAction = ({ value }) => {
    return (
        <div
          className={`flex items-center gap-4 mt-[5px] ${
            isShowUpdate ? "w-[260px]" : "w-[180px]"
          }`}
        >
          {isShowUpdate ? (
            <button
              className="w-[133px] h-[32px] flex justify-center items-center rounded-lg bg-base-primary hover:bg-base-primary_hover text-[14px] text-[#F6F8F9] leading-6 font-normal tracking-[-0.6%]"
              onClick={updateInventory}
            >
              {t("Update Inventory")}
            </button>
          ) : null}
          <button
            type="button"
            className="w-[30px] h-[30px] border border-[#E5E9EB] bg-white rounded-full flex items-center justify-center hover:bg-[#E5E9EB]"
            onClick={() => openModal(value)}
          >
            <img src={DelIcon} alt="" />
          </button>
          <button
            className="w-[30px] h-[30px] border border-[#E5E9EB] bg-white rounded-full flex items-center justify-center hover:bg-[#E5E9EB]"
            onClick={inventryEditBtn}
          >
            <img src={EidIcon} alt="" />
          </button>
        </div>
    );
  };

  const CurrentAvailableQuant = ({ value }) => {
    return (
      <>
        {value === "Out of stock" ? (
          <div className="text-[14px] leading-6 font-normal tracking-[-0.6%] text-[#FF2424] mt-2">
            {value}
          </div>
        ) : (
          <div>{value}</div>
        )}
      </>
    );
  };
  const columnDef = [
    {
      headerName: "Item ID",
      field: "itemID",
      maxWidth: 120,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Item Name",
      field: "itemName",
      minWidth: 120,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Item Type",
      field: "kitchenItemType",
      maxWidth: 150,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Price",
      field: "price",
      maxWidth: 150,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Storage Type",
      field: "storageType",
      maxWidth: 150,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Available Quantity",
      field: "currentAvailableQuantity",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
      cellRenderer: CurrentAvailableQuant,
    },
    {
      headerName: "Shelf Life",
      field: "shelfLife",
      maxWidth: 150,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Purchase Frequency",
      field: "purchaseFrequency",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Vendor ID",
      field: "vendorID",
      maxWidth: 150,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Actions",
      minWidth: 260,
      cellRenderer: viewAllInventoryAction,
    },
  ];
  return (
    <>
      <div className="mt-8">
      <div className="flex items-center justify-between mb-4">
          <div className="text-[22px] leading-[27.72px] text-black font-bold">
            Inventory Table
          </div>
          <div className="flex items-center gap-4">
            <PrintButton />
            <UploadButton />
            <DownloadButton />
          </div>
        </div>
        {datas.length ? (
          <CustomeTable
            rowData={rowData}
            columnDefs={columnDef}
            setSelectedRows={setSelectedRows}
          />
        ) : null}
      </div>

      <DeleteOrNot open={open} close={closeModal} formSubmit={confirmDelete} />
      {delLoading ? <BackGroundLoadSpinner /> : null}
    </>
  );
};

export default AllInventory;
