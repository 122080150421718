import axios from "axios";
import { TOKEN } from "../../constants";
import {
  DELETE_ROOM_FAIL,
  DELETE_ROOM_NUMBER_FAIL,
  DELETE_ROOM_NUMBER_REQ,
  DELETE_ROOM_NUMBER_SUC,
  DELETE_ROOM_REQUEST,
  DELETE_ROOM_SUCCESS,
} from "../types/room";

// delete room
export const deleteRoomAction = (roomId, hotelId) => async (dispatch) => {
  if(!roomId || !hotelId) return;
  let url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/rooms`;

  try {
    dispatch({ type: DELETE_ROOM_REQUEST });
    const { data } = await axios.delete(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
      data: { roomId, hotelId },
    });
    dispatch({
      type: DELETE_ROOM_SUCCESS,
      payload: data.rooms,
    });
  } catch (error) {
    dispatch({
      type: DELETE_ROOM_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

// delete room number by roomNumber
export const deleteRoomNumberAction =
  (hotelId, roomId, roomNumber) => async (dispatch) => {
    if(!hotelId || !roomId || !roomNumber) return;
    let url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/rooms/hotel-rooms`;

    try {
      dispatch({ type: DELETE_ROOM_NUMBER_REQ });
      const { data } = await axios.delete(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem(TOKEN),
        },
        withCredentials: true,
        data: { hotelId, roomId, roomNumber },
      });
      dispatch({
        type: DELETE_ROOM_NUMBER_SUC,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: DELETE_ROOM_NUMBER_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };
