import { useTranslation } from "react-i18next";
import { CONNECTION_STATUS } from "../constants"; 

const CheckOnlineOffilne = () => {
  const {t} = useTranslation()
  //INITIAL STATUS OF THE CONNECTION
  if (window.navigator.onLine) {
    sessionStorage.setItem(CONNECTION_STATUS, true);
  } else {
    sessionStorage.setItem(CONNECTION_STATUS, false);
  }
  //CALL WHEN DEVICE IS OFFILNE
  window.addEventListener(t("offline"), function (e) {
    sessionStorage.setItem(CONNECTION_STATUS, false);
  });
  //CALL WHEN DEVICE IS ONLINE
  window.addEventListener(t("online"), function (e) {
    sessionStorage.setItem(CONNECTION_STATUS, true);
  });

  return null;
};

export default CheckOnlineOffilne;
