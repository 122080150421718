//auth
export const GET_EMPLOYEE_REQUEST = "GET_EMPLOYEE_REQUEST";
export const GET_EMPLOYEE_SUCCESS = "GET_EMPLOYEE_SUCCESS";
export const GET_EMPLOYEE_FAIL = "GET_EMPLOYEE_FAIL";

export const EMPLOYEE_LOGIN_REQUEST = "EMPLOYEE_LOGIN_REQUEST";
export const EMPLOYEE_LOGIN_SUCCESS = "EMPLOYEE_LOGIN_SUCCESS";
export const EMPLOYEE_LOGIN_FAIL = "EMPLOYEE_LOGIN_FAIL";

//employee
export const ADD_EMPLOYEE_REQUEST = "ADD_EMPLOYEE_REQUEST";
export const ADD_EMPLOYEE_SUCCESS = "ADD_EMPLOYEE_SUCCESS";
export const ADD_EMPLOYEE_FAIL = "ADD_EMPLOYEE_FAIL";
export const ADD_EMPLOYEE_RESET = "ADD_EMPLOYEE_RESET";

export const GET_ALL_EMPLOYEE_REQUEST = "GET_ALL_EMPLOYEE_REQUEST";
export const GET_ALL_EMPLOYEE_SUCCESS = "GET_ALL_EMPLOYEE_SUCCESS";
export const GET_ALL_EMPLOYEE_FAIL = "GET_ALL_EMPLOYEE_FAIL";

export const GET_EMPLOYEE_BY_ID_REQUEST = "GET_EMPLOYEE_BY_ID_REQUEST";
export const GET_EMPLOYEE_BY_ID_SUCCESS = "GET_EMPLOYEE_BY_ID_SUCCESS";
export const GET_EMPLOYEE_BY_ID_FAIL = "GET_EMPLOYEE_BY_ID_FAIL";

//leave
export const ADD_LEAVE_FOR_EMP_REQUEST = "ADD_LEAVE_FOR_EMP_REQUEST";
export const ADD_LEAVE_FOR_EMP_SUCCESS = "ADD_LEAVE_FOR_EMP_SUCCESS";
export const ADD_LEAVE_FOR_EMP_FAIL = "ADD_LEAVE_FOR_EMP_FAIL";
export const ADD_LEAVE_FOR_EMP_RESET = "ADD_LEAVE_FOR_EMP_RESET";

export const DELETE_LEAVE_OF_EMP_REQUEST = "DELETE_LEAVE_OF_EMP_REQUEST";
export const DELETE_LEAVE_OF_EMP_SUCCESS = "DELETE_LEAVE_OF_EMP_SUCCESS";
export const DELETE_LEAVE_OF_EMP_FAIL = "DELETE_LEAVE_OF_EMP_FAIL";
export const DELETE_LEAVE_OF_EMP_RESET = "DELETE_LEAVE_OF_EMP_RESET";

//permission
export const ADD_PERMISSION_TO_EMP_REQUEST = "ADD_PERMISSION_TO_EMP_REQUEST";
export const ADD_PERMISSION_TO_EMP_SUCCESS = "ADD_PERMISSION_TO_EMP_SUCCESS";
export const ADD_PERMISSION_TO_EMP_FAIL = "ADD_PERMISSION_TO_EMP_FAIL";
export const ADD_PERMISSION_TO_EMP_RESET = "ADD_PERMISSION_TO_EMP_RESET";

export const DELETE_PERMISSION_TO_EMP_REQUEST =
  "DELETE_PERMISSION_TO_EMP_REQUEST";
export const DELETE_PERMISSION_TO_EMP_SUCCESS =
  "DELETE_PERMISSION_TO_EMP_SUCCESS";
export const DELETE_PERMISSION_TO_EMP_FAIL = "DELETE_PERMISSION_TO_EMP_FAIL";
export const DELETE_PERMISSION_TO_EMP_RESET = "DELETE_PERMISSION_TO_EMP_RESET";

//profile
export const UPDATE_EMPLOYEE_REQUEST = "UPDATE_EMPLOYEE_REQUEST";
export const UPDATE_EMPLOYEE_SUCCESS = "UPDATE_EMPLOYEE_SUCCESS";
export const UPDATE_EMPLOYEE_FAIL = "UPDATE_EMPLOYEE_FAIL";
export const UPDATE_EMPLOYEE_RESET = "UPDATE_EMPLOYEE_RESET";

export const DELETE_EMPLOYEE_REQUEST = "DELETE_EMPLOYEE_REQUEST";
export const DELETE_EMPLOYEE_SUCCESS = "DELETE_EMPLOYEE_SUCCESS";
export const DELETE_EMPLOYEE_FAIL = "DELETE_EMPLOYEE_FAIL";
export const DELETE_EMPLOYEE_RESET = "DELETE_EMPLOYEE_RESET";

//shift
export const START_SHIFT_REQUEST = "START_SHIFT_REQUEST";
export const START_SHIFT_SUCCESS = "START_SHIFT_SUCCESS";
export const START_SHIFT_FAIL = "START_SHIFT_FAIL";

export const GET_ALL_SHIFT_REQUEST = "GET_ALL_SHIFT_REQUEST";
export const GET_ALL_SHIFT_SUCCESS = "GET_ALL_SHIFT_SUCCESS";
export const GET_ALL_SHIFT_FAIL = "GET_ALL_SHIFT_FAIL";

export const END_SHIFT_REQUEST = "END_SHIFT_REQUEST";
export const END_SHIFT_SUCCESS = "END_SHIFT_SUCCESS";
export const END_SHIFT_FAIL = "END_SHIFT_FAIL";

//extra work
export const ADD_EXTRA_WORK_REQUEST = "ADD_EXTRA_WORK_REQUEST";
export const ADD_EXTRA_WORK_SUCCESS = "ADD_EXTRA_WORK_SUCCESS";
export const ADD_EXTRA_WORK_FAIL = "ADD_EXTRA_WORK_FAIL";
export const ADD_EXTRA_WORK_RESET = "ADD_EXTRA_WORK_RESET";

export const DELETE_EXTRA_WORK_REQUEST = "DELETE_EXTRA_WORK_REQUEST";
export const DELETE_EXTRA_WORK_SUCCESS = "DELETE_EXTRA_WORK_SUCCESS";
export const DELETE_EXTRA_WORK_FAIL = "DELETE_EXTRA_WORK_FAIL";
export const DELETE_EXTRA_WORK_RESET = "DELETE_EXTRA_WORK_RESET";
