import React from "react";

const SubmitButton = ({
  onClick = () => {},
  type = "submit",
  text,
  sizing,
}) => {
  return (
    <button
      onClick={onClick}
      type={type}
      className={`${sizing} rounded-md bg-base-primary hover:bg-base-primary_hover shadow-[0_1px_2px_0_rgba(16,24,40,0.05)] text-sm font-medium text-[#F6F8F9] transition-all duration-[0.3s] ease-[0s] capitalize`}
    >
      {text}
    </button>
  );
};

export default SubmitButton;
