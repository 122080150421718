import React, { useEffect } from "react";
import { useState } from "react";
import { registerEvent } from "../../logs";
import AllAssets from "./AllAssets";
import { connect, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getAllAssetAction } from "../../actions/pms/assetget";
import Seperator from "../../components/layouts/Seperator";
import AddButton from "../../components/buttons/AddButton";
import AddAssets from "./AddAssets";
import DelIcon from "../../images/icons/DelIcon.svg";

const AssetsPage = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { hotel } = useSelector((state) => state.Hotel);
  const [asset, setAsset] = useState(null);

  const openModal = () => {
    if (asset) setAsset(null);
    setOpen(true);
    registerEvent("add asset button open", "add asset", { open });
  };

  const close = () => {
    if (asset) setAsset(null);
    setOpen(false);
    registerEvent("add asset button close", "add asset", { open });
  };

  useEffect(() => {
    if (hotel?._id) {
      props.getAssets(hotel?._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hotel?._id]);

  const headingData = {
    heading: t("325"),
    seperator: [
      {
        text: t("241"),
      },
      {
        text: t("325"),
      },
    ],
  };

  return (
    <>
      <div className="page-container max-w-7xl">
        <div className="flex justify-between items-end mx-auto">
          <Seperator data={headingData} />
          <div className="flex gap-2">
            <button className="flex items-center gap-2 px-3 border border-[#E5E9EB] rounded-md h-10 bg-[#FFFFFF] hover:bg-gray-50">
              <img src={DelIcon} alt="" />
              <div className="">Delete</div>
            </button>
            <AddButton text={t("326")} onClick={openModal} />
          </div>
        </div>
        {open || asset?._id ? <AddAssets close={close} hotel={hotel} /> : null}
        <AllAssets hotel={hotel} setAsset={setAsset} />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  assets: state.AllAsset,
});

const mapDispatchToProps = (dispatch) => ({
  getAssets: (hotelId) => dispatch(getAllAssetAction(hotelId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AssetsPage);
