import React from "react"; 

const PropertyContacts = ({ contacts, setContacts, }) => {
  return (
    <>
      <div className="w-full grid gap-6 grid-cols-1">  
       
      </div>
    </>
  );
};

export default PropertyContacts;
