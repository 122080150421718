/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  addUpdateAmenityAction,
  deleteAmenityAction,
} from "../../actions/pms/hotelProfile";
import LoadSpinner from "../../components/loader/LoadSpinner";
import BackGroundLoadSpinner from "../../components/loader/BackGroundLoadSpinner"; 
import { notifyError } from "../../components/alert";
import { clearError } from "../../actions/clearError";
import {
  ADD_UPDATE_AMENITY_RESET,
  DELETE_AMENITY_RESET,
} from "../../actions/types/hotelProfile";
import { updateHotelAction } from "../../actions/pms/hotel";
import { ONBOARDING_STAGES } from "../../constants";
import { isObjEmpty } from "../../utils";
import { useTranslation } from "react-i18next";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";

const Step3 = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [amenities, setAmenities] = useState([]);
  const [prevAment, setPrevAment] = useState([]);
  const {
    loading,
    amenities: addedAmenities,
    isadded,
  } = useSelector((state) => state.AddAmenity);

  const {
    loading: delLoading,
    isdeleted,
    amenities: deletedAmenities,
    error: deleteErr,
  } = useSelector((state) => state.DeleteAmenity);

  const {
    loading: submitLoading,
    isupdated,
    hotel: updatedData,
  } = useSelector((state) => state.UpdateHotel);

  const [hotel, setHotel] = useState({});
  const [ourAmenties, setOuramenties] = useState([]);
  const [newAmenity, setNewAmenity] = useState("");
  const [open, setOpen] = useState(false);

  const close = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!isObjEmpty(props.hotel)) {
      setHotel(props.hotel);
      setPrevAment(props.hotel.amenities);
    }
  }, [props.hotel]);

  //set our amenities
  // useEffect(() => {
  //   if (HOTEL_AMENITIES && HOTEL_AMENITIES.length) {
  //     if (prevAment && prevAment.length) {
  //       ourAmenties.map((amen) => {
  //         prevAment.map((newA) => {
  //           if (newA.name === amen.name) {
  //             const result = ourAmenties.filter((d) => d !== amen);
  //             setOuramenties(result);
  //           }
  //         });
  //       });
  //     } else {
  //       setOuramenties(HOTEL_AMENITIES);
  //     }
  //   }
  // }, [HOTEL_AMENITIES]);

  //added amenities set
  useEffect(() => {
    if (addedAmenities && addedAmenities.length) {
      setPrevAment(addedAmenities);
    }
  }, [addedAmenities]);
  //after delettion amenities set
  useEffect(() => {
    if (deletedAmenities && deletedAmenities.length) {
      setPrevAment(deletedAmenities);
    }
  }, [deletedAmenities]);

  //add amenities
  const addAmenity = (data) => {
    dispatch(
      addUpdateAmenityAction({ hotelId: hotel._id, amenity: data.name })
    );
  };

  //delete amenities
  const deleteAmenities = (data) => {
    if (data.name && hotel?._id) {
      dispatch(deleteAmenityAction(hotel?._id, data?.name));
    }
  };

  //add new amenities (custome amenity)
  const handleSubmit = () => {
    if (hotel?._id) {
      dispatch(
        addUpdateAmenityAction({ hotelId: hotel?._id, amenity: newAmenity })
      );
    }
    close();
  };

  const gotToNextStep = async () => {
    dispatch(
      updateHotelAction({
        hotelId: hotel?._id,
        amenities,
        stage: ONBOARDING_STAGES.IDENTITY_SEARCH,
      })
    );
  };

  useEffect(() => {
    if (isupdated) {
      props.reset();
      props.setStep(4);
    }
  }, [isupdated, updatedData]);

  useEffect(() => {
    if (isadded) {
      dispatch({ type: ADD_UPDATE_AMENITY_RESET });
    }
  }, [isadded]);
  useEffect(() => {
    if (isdeleted) {
      dispatch({ type: DELETE_AMENITY_RESET });
    }
  }, [isdeleted]);

  useEffect(() => {
    if (deleteErr) {
      deleteErr && notifyError(deleteErr);
      dispatch(clearError());
    }
  }, [deleteErr]);

  return (
    <>
      {loading || delLoading ? <BackGroundLoadSpinner /> : <></>}
      <div className="flex items-start flex-col w-full pb-6 px-4 sm:px-6">
        <h2 className="text-xl sm:text-3xl md:text-[40px] capitalize mb-6 font-bold leading-[48px] text-center w-full">
          {t("921")} :
        </h2>
        <div className="flex w-full flex-wrap mx-auto justify-center">
          <>
            {prevAment && prevAment.length
              ? prevAment.map((ament, index) => (
                  <div
                    className="w-32 h-32 mr-3 mb-3"
                    key={index}
                    onClick={() => deleteAmenities(ament)}
                  >
                    <div
                      className={`bg-base-primary text-[#FFFFFF]
                  h-full w-full flex items-center justify-center select-none cursor-pointer rounded-full p-2 text-center capitalize shadow-md`}
                    >
                      {ament.name}
                    </div>
                  </div>
                ))
              : null}
            {ourAmenties && ourAmenties.length
              ? ourAmenties.map((ament, index) => (
                  <div
                    className="w-32 h-32 mr-3 mb-3"
                    key={index}
                    onClick={() => addAmenity(ament)}
                  >
                    <div
                      className={`text-black border-black bg-[#FFFFFF]
                  h-full w-full flex items-center justify-center select-none cursor-pointer border-2 rounded-full p-2 text-center capitalize shadow-md`}
                    >
                      {ament.name}
                    </div>
                  </div>
                ))
              : null}
          </>
          <button
            className="w-32 h-32 m-3 flex items-center justify-center select-none cursor-pointer border-2 rounded-full p-2 text-center capitalize shadow-md text-black border-black"
            onClick={() => setOpen(true)}
          >
            <AiOutlinePlus style={{ fontSize: "2rem" }} />
          </button>
        </div>
        {/* dialog box */}
        {/* <Dialog
          open={open}
          onClose={close}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" style={{ padding: "1rem" }}>
            {t("920")}
          </DialogTitle>
          <DialogContent style={{ padding: "1rem" }}>
            <div className="grid grid-cols-1 md:grid-cols-2 py-3 gap-4">
              <div className="w-full relative">
                <label
                  htmlFor="Name"
                  className="absolute -top-3 left-1 bg-[#FFFFFF] px-1 text-sm capitalize text-green-600"
                >
                  {t("922")}
                </label>
                <input
                  type="text"
                  onChange={(e) => setNewAmenity(e.target.value)}
                  value={newAmenity}
                  className="rounded outline-none border border-gray-300 p-2 focus:border-black w-96 max-w-full"
                />
              </div>
              <button
                className="w-full text-center h-10 bg-green-600 text-[#FFFFFF] select-none cursor-pointer rounded uppercase"
                onClick={() => handleSubmit()}
              >
                {loading ? <LoadSpinner /> : <span>{t("412")}</span>}
              </button>
            </div>
          </DialogContent>
        </Dialog> */}
        <div className="relative flex w-full items-center justify-end mt-8 text-sm font-medium">
          <div className="flex items-center gap-4 mr-14">
            <button
              className="w-[120px] h-12 flex items-center justify-center gap-2 rounded-md hover:bg-gray-200 bg-[#FFFFFF] border border-[#E5E9EB] text-[#252C32] capitalize"
              onClick={() => props.setStep(2)}
            >
              <FiArrowLeft size={18} />
              {t("64")}
            </button>
            <button
              className="w-[120px] h-12 flex justify-center items-center gap-2 bg-base-primary hover:bg-base-primary_hover rounded-md  text-[#FFFFFF] capitalize"
              onClick={gotToNextStep}
              disabled={submitLoading}
            >
              {submitLoading ? (
                <LoadSpinner />
              ) : (
                <>
                  {t("65")}
                  <FiArrowRight size={18} />
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step3;
