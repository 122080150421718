import React from "react";
import { useTranslation } from "react-i18next";

const TotalReviews = ({ count }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="w-full flex flex-col items-center font-pjs">
        <div className="font-semibold text-xl sm:text-2xl md:text-[30px] leading-[37px] tracking-[-0.8 px] text-[#667085] capitalize">
          {t("324")}
        </div>
        <div className="font-medium text-[30px] leading-[37px] tracking-[-0.8 px] text-[#00A35E]">
          {count}
        </div>
      </div>
    </>
  );
};

export default TotalReviews;
