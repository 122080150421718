import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { ID_NUMBER, ROUTES } from "../../constants";
import { deleteBookingAction } from "../../actions/pms/booking";
import DeleteOrNot from "../../components/layouts/DeleteOrNot";
import BackGroundLoadSpinner from "../../components/loader/BackGroundLoadSpinner";
import { CustomeTable } from "../../components";
import BookingStatus from "./BookingStatus";
import UploadButton from "../../components/buttons/UploadButton";
import DownloadButton from "../../components/buttons/DownloadButton";

const AllBooking = (props) => {
  const [datas, setDatas] = useState([]);
  const [BookingId, setBookingId] = useState("");
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const navigate = useNavigate();
  //set bookings as datas
  useEffect(() => {
    if (
      props.bookings &&
      Array.isArray(props?.bookings?.bookings) &&
      props?.bookings?.bookings?.length
    ) {
      const arr = props?.bookings?.bookings?.reverse()?.map((booking) => {
        return {
          name: booking?.bookingName,
          source: booking?.bookingSource,
          bookingDate: booking?.bookingDate,
          checkInDate: booking?.checkInDate,
          checkOutDate: booking?.checkOutDate,
          roomTypeNo: booking?.roomType,
          status: booking?.bookingStatus,
          code: booking?.bookingCode,
          _id: booking?._id,
        };
      });
      setDatas(arr);
    }
    console.log(props?.bookings?.bookings);
  }, [props?.bookings]);

  //set update data
  // const updateTheBooking = async (id) => {
  //   if (id) {
  //     const booking = await props?.bookings?.bookings?.filter(
  //       (x) => x?._id === id
  //     );
  //     console.log(booking[0]);
  //     // props.setBooking(booking[0]);
  //     window.scrollTo({
  //       top: 0,
  //       behavior: "smooth",
  //     });
  //   }
  // };

  //delete booking confirmation modal open
  // const deleteTheBooking = (id) => {
  //   if (id) {
  //     setBookingId(id);
  //     setDeleteConfirmation(true);
  //   }
  // };

  // delete booking confirm that delete this booking with id-: BookingId
  const deleteBooking = () => {
    if (BookingId) {
      props.deleteBookingDone(BookingId, props?.hotel?._id);
    }
  };

  // confirm that do not delete this booking with id : BookingId
  const notDeleteBooking = () => {
    setDeleteConfirmation(false);
    setBookingId(null);
  };

  //view Booking details
  const ViewBookingDetails = ({ v }) => {
    const viewDetails = () => {
      localStorage.setItem(ID_NUMBER, v?.data?._id);
      navigate(ROUTES.BOOKING);
    };
    return (
      <button onClick={viewDetails} className="text-[14px] leading-6 font-normal tracking-[-0.6%] text-base-primary hover:text-base-primary_hover">
        View Details
      </button>
    );
  };
  const columnDefs = [
    {
      headerName: "Booking ID",
      field: "_id",
      maxWidth: 200,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Name",
      field: "name",
      maxWidth: 150,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Source",
      field: "source",
      maxWidth: 150,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Booking Date",
      field: "bookingDate",
      maxWidth: 150,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Check-In",
      field: "checkInDate",
      maxWidth: 150,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Check-Out",
      field: "checkOutDate",
      maxWidth: 150,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Room Type-No",
      field: "roomTypeNo",
      maxWidth: 150,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Status",
      field: "status",
      cellRenderer: BookingStatus,
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Code",
      field: "code",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Details",
      field: "_id",
      maxWidth: 200,
      cellRenderer: ViewBookingDetails,
    },
  ];

  return (
    <>
      <div className="relative">
        <div className="flex items-center justify-between mb-4">
          <div className="text-[22px] leading-[27.72px] text-black font-bold">
            Booking Table
          </div>
          <div className="flex items-center gap-4">
            <UploadButton />
            <DownloadButton />
          </div>
        </div>
        {datas?.length ? (
          <CustomeTable
            columnDefs={columnDefs}
            rowData={datas}
            setSelectedRows={setSelectedRows}
          />
        ) : null}
        {props?.delete && props?.delete?.loading && <BackGroundLoadSpinner />}
        <DeleteOrNot
          formSubmit={deleteBooking}
          open={deleteConfirmation}
          close={notDeleteBooking}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  delete: state.DeleteBooking,
  bookings: state.AllBooking,
});

const mapDispatchToProps = (dispatch) => ({
  deleteBookingDone: (id, hotelId) =>
    dispatch(deleteBookingAction(id, hotelId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AllBooking);