import { useEffect } from "react";
import { connect } from "react-redux";
import { HOTEL_ROOM_DETAILS, ROOM_TYPES } from "../constants";

const SetAvailableRooms = (props) => {
  useEffect(() => {
    //initial states
    let hotelRooms = {};
    let roomTypes = [];

    if (
      props?.rooms &&
      props?.rooms?.rooms &&
      Array.isArray(props?.rooms?.rooms)
    ) {
      //getting room informations 
      props?.rooms?.rooms?.map((room) => {
        const adults = room?.adults;
        const children = room?.children;
        const roomType = room?.roomType;
        roomTypes.push(roomType);

        if (room?.hotelRooms && room?.hotelRooms?.length) {
          room?.hotelRooms?.map((rx) => {
            hotelRooms[rx?.roomNumber] = {
              adults,
              children,
              roomType,
              bookings: [],
              roomNumber: rx?.roomNumber
            };
          });
        }
      });

      //getting booking details @booked(some one is in room) today or future
      props?.bookings &&
        props?.bookings?.bookings &&
        Array.isArray(props?.bookings?.bookings) &&
        props?.bookings?.bookings?.map((data) => {
          const bookingName = data?.bookingName;
          const _id = data?._id;
          const bookingStatus = data?.bookingStatus;

          data?.roomDetails?.map((room) => {
            const bookingDetail = {
              checkInDate: room?.checkInDate,
              checkOutDate: room?.checkOutDate,
              guestNumber: room?.guestsDetails?.length,
              roomPrice: room?.tariff,
              bookingName,
              _id,
              bookingStatus,
            };

            if (hotelRooms[room?.roomNumber]) {
              const previousBooked = hotelRooms[room?.roomNumber]?.bookings;
              const previousBookedExist = hotelRooms[room?.roomNumber]?.bookings?.length > 1;
              hotelRooms[room?.roomNumber].bookings = previousBookedExist
                ? [...previousBooked, bookingDetail]
                : [bookingDetail];
            }
          });
        });
    }
    //store room types in a hotel @session storage
    sessionStorage.setItem(ROOM_TYPES, JSON.stringify(roomTypes));

    //storing hotel room numbers @session storage
    sessionStorage.setItem(HOTEL_ROOM_DETAILS, JSON.stringify(hotelRooms));
    //storing future booked hotel rooms details for checking on new booking is room available @session storage
  }, [props?.bookings?.bookings, props?.rooms?.rooms]);

  return null;
};

const mapStateToProps = (state) => ({
  bookings: state.AllBooking,
  rooms: state.AllRoom,
});

export default connect(mapStateToProps)(SetAvailableRooms);
