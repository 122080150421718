import axios from "axios";

import { ISADMIN, TOKEN } from "../../constants";
import {
  ADD_KITCHEN_FAIL,
  ADD_KITCHEN_REQUEST,
  ADD_KITCHEN_SUCCESS,
  DELETE_KITCHEN_FAIL,
  DELETE_KITCHEN_REQUEST,
  DELETE_KITCHEN_SUCCESS,
  GET_ALL_KITCHEN_FAIL,
  GET_ALL_KITCHEN_REQUEST,
  GET_ALL_KITCHEN_SUCCESS,
  GET_KITCHEN_FAIL,
  GET_KITCHEN_REQUEST,
  GET_KITCHEN_SUCCESS,
  UPDATE_KITCHEN_FAIL,
  UPDATE_KITCHEN_REQUEST,
  UPDATE_KITCHEN_SUCCESS,
} from "../typesKitchen/kitchen";

//add kitchen action
export const addKitchenAction = (formData) => async (dispatch) => {
  const isadmin = localStorage.getItem(ISADMIN);
  if (!isadmin) return;
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/kitchen/add`;
  try {
    dispatch({ type: ADD_KITCHEN_REQUEST });
    const { data } = await axios.post(url, formData, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: ADD_KITCHEN_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADD_KITCHEN_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

//get kitchens action
export const getAllKitchenAction = () => async (dispatch) => {
  const isadmin = localStorage.getItem(ISADMIN);
  if (!isadmin) return;
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/kitchen/all`;
  try {
    dispatch({ type: GET_ALL_KITCHEN_REQUEST });
    const { data } = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: GET_ALL_KITCHEN_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_KITCHEN_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

//get one kitchen action
export const getKitchenByHotelIdAction = (id) => async (dispatch) => {
  if(!id) return;
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/kitchen?hotelId=${id}`;
  try {
    dispatch({ type: GET_KITCHEN_REQUEST });
    const { data } = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: GET_KITCHEN_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_KITCHEN_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

//update kitchen action
export const updateKitchen = (formData) => async (dispatch) => {
  const isadmin = localStorage.getItem(ISADMIN);
  if (!isadmin) return;
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/kitchen/update`;
  try {
    dispatch({ type: UPDATE_KITCHEN_REQUEST });
    const { data } = await axios.post(url, formData, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: UPDATE_KITCHEN_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_KITCHEN_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

//delete kitchen action
export const deleteKitchenAction = (kitchenId) => async (dispatch) => {
  if(!kitchenId) return;
  const isadmin = localStorage.getItem(ISADMIN);
  if (!isadmin) return;
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/kitchen/delete`;
  try {
    dispatch({ type: DELETE_KITCHEN_REQUEST });
    const { data } = await axios.delete(url, {
      headers: {
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
      data: { kitchenId },
    });
    dispatch({
      type: DELETE_KITCHEN_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DELETE_KITCHEN_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};
