import React, { useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";


const CustomeTable = ({ rowData, columnDefs, setSelectedRows,rowHeight}) => {
  const rowSelection = "multiple";
  const paginationPageSize = 10;
  const paginationPageSizeSelector = [10, 25, 50];

  const getSelectedRowData = (event) => {
    const selectedData = event.api.getSelectedRows();
    setSelectedRows(selectedData);
  };

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100,
      // allow every column to be aggregated
      enableValue: true,
      // allow every column to be grouped
      enableRowGroup: true,
      // allow every column to be pivoted
      enablePivot: true,
      filter: true,
    };
  }, []);
  const autoGroupColumnDef = useMemo(() => {
    return {
      minWidth: 200,
    };
  }, []);

  return (
    <div className="ag-theme-quartz" style={{ height:450}}>
      <AgGridReact
        rowHeight={rowHeight}
        rowData={rowData}
        columnDefs={columnDefs}
        pagination={true}
        paginationPageSize={paginationPageSize}
        paginationPageSizeSelector={paginationPageSizeSelector}
        rowSelection={rowSelection}
        suppressRowClickSelection={true}
        onSelectionChanged={getSelectedRowData}
        allowDragFromColumnsToolPanel={true}
        defaultColDef={defaultColDef}
        autoGroupColumnDef={autoGroupColumnDef}
        sideBar={"columns"}
        suppressColumnsToolPanel={true}
      />
    </div>
  );
};

export default CustomeTable;
