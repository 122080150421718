import {
    ADD_VENDOR_FAIL,
    ADD_VENDOR_REQUEST,
    ADD_VENDOR_RESET,
    ADD_VENDOR_SUCCESS,
    GET_ALL_VENDOR_FAIL,
    GET_ALL_VENDOR_REQUEST,
    GET_ALL_VENDOR_SUCCESS,
    GET_VENDOR_BY_ID_FAIL,
    GET_VENDOR_BY_ID_REQUEST,
    GET_VENDOR_BY_ID_SUCCESS,
  } from "../../actions/types/vendor";
  import { CLEAR_ERRORS } from "../../actions/types";
  
  //hotel owner add vendor 
  export const addVendorReducer = (state = { vendor: {} }, action) => {
    switch (action.type) {
      case ADD_VENDOR_REQUEST:
        return {
          ...state,
          loading: true,
          isadded: false,
        };
      case ADD_VENDOR_SUCCESS:
        return {
          ...state,
          loading: false,
          vendor: action.payload,
          isadded: true,
        };
      case ADD_VENDOR_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
          isadded: false,
        };
      case ADD_VENDOR_RESET:
        return {
          ...state,
          loading: false,
          error: action.payload,
          cook: null,
          isadded: false,
        };
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };
  
  //hotel get all vendors
  export const getAllVendorReducer = (state = { vendors: [] }, action) => {
    switch (action.type) {
      case GET_ALL_VENDOR_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case GET_ALL_VENDOR_SUCCESS:
        return {
          ...state,
          loading: false,
          vendors: action.payload,
        };
      case GET_ALL_VENDOR_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
          vendors:[],
        };
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };
  
  //hotel owner get one vendor by id
  export const getVendorByIdReducer = (state = { vendor: {} }, action) => {
    switch (action.type) {
      case GET_VENDOR_BY_ID_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case GET_VENDOR_BY_ID_SUCCESS:
        return {
          ...state,
          loading: false,
          vendor: action.payload,
        };
      case GET_VENDOR_BY_ID_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
          vendor: null
        };
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null, 
        };
      default:
        return state;
    }
  };
  
  