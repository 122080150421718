import React, { useState } from "react";
import { MdOutlineSettingsApplications } from "react-icons/md";
import AllLaundry from "./AllLaundry";
import LaundryConfig from "./LaundryConfig";
import { registerEvent } from "../../logs";
import SubmitButton from "../../components/buttons/SubmitButton";
import Seperator from "../../components/layouts/Seperator";
import LaundryRequest from "./LaundryRequest";

const Laundry = () => {
  const [isOpenLaundryReq, setIsOpenLaundryReq] = useState(false);
  const [isLaundryConfig, setIsLaundryConfig] = useState(false);
  const track = {
    heading: "Laundry",
    seperator: [
      {
        text: "hotel",
      },
      {
        text: "Laundry",
      },
    ],
  };
  const laundryRequestModal = () => {
    setIsOpenLaundryReq(true);
    registerEvent("click add Laundry button", "all laundry page");
  };

  //close Laundry Request item modal
  const closeLaundryRequest = () => {
    setIsOpenLaundryReq(false);

    registerEvent("close add Laundry component", "add Laundry page");
  };

  const laundryConfigModal = () => {
    setIsLaundryConfig(true);
    registerEvent("click  Laundry congfiguration button", "all laundry page");
  };

  //close add Laundry modal
  const closeLaundryConfig = () => {
    setIsLaundryConfig(false);
    registerEvent("close add Laundry component", "add Laundry page");
  };

  return (
    <div className="page-container">
      <div className="flex items-end justify-between">
        <Seperator data={track} />
        <div className="flex items-center justify-end gap-4">
          <SubmitButton
            type="button"
            sizing={"h-10 w-[171px]"}
            text={"Configure Laundry"}
            onClick={laundryConfigModal}
          />
          <SubmitButton
            type="button"
            sizing={"h-10 w-[171px]"}
            text={"Laundry Request"}
            onClick={laundryRequestModal}
          />
        </div>
      </div>

      <div className="">
        {isOpenLaundryReq ? (
          <LaundryRequest closeLaundryReq={closeLaundryRequest} />
        ) : null}
      </div>
      <div className="">
        {isLaundryConfig ? (
          <LaundryConfig closeLaundryConfig={closeLaundryConfig} />
        ) : null}
      </div>
      <AllLaundry />
    </div>
  );
};

export default Laundry;
