import React from "react";

const CheckRadioInputs = ({
  value,
  onChange,
  label,
  readOnly = false,
  label2,
}) => {
  return (
    <div
      className={`h-10 w-fit relative flex items-center justify-between gap-4`}
    >
      <div className="text-sm font-medium text-[#07090D]">{label}</div>
      <input
        type={"checkbox"}
        checked={value}
        id={label}
        onChange={onChange}
        value={value}
        className={"checkBoxControl"}
        readOnly={readOnly}
      />
      <label
        htmlFor={label}
        className={`text-sm font-medium text-[#07090D] capitalize`}
      >
        {label2}
      </label>
    </div>
  );
};

export default CheckRadioInputs;
