import {
  ADD_VENDOR_FAIL,
  ADD_VENDOR_REQUEST,
  ADD_VENDOR_SUCCESS,
  GET_ALL_VENDOR_FAIL,
  GET_ALL_VENDOR_REQUEST,
  GET_ALL_VENDOR_SUCCESS,
  GET_VENDOR_BY_ID_FAIL,
  GET_VENDOR_BY_ID_REQUEST,
  GET_VENDOR_BY_ID_SUCCESS,
} from "../types/vendor";
import axios from "axios";
import { TOKEN } from "../../constants";

//add vendor
export const addVendorAction = (formData) => async (dispatch) => {
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/vendor`;
  try {
    dispatch({ type: ADD_VENDOR_REQUEST });
    const { data } = await axios.post(url, formData, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: ADD_VENDOR_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADD_VENDOR_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

//get all vendor
export const getAllVendorAction = (HOTEL_ID) => async (dispatch) => {
  if(!HOTEL_ID) return;
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/vendor/all?hotelId=${HOTEL_ID}`;
  try {
    dispatch({ type: GET_ALL_VENDOR_REQUEST });
    const { data } = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: GET_ALL_VENDOR_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_VENDOR_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

//get  vendor
export const getVendorByIdAction =
  (VENDOR_ID, HOTEL_ID) => async (dispatch) => {
    if(!VENDOR_ID || !HOTEL_ID) return;
    const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/vendor?vendorId=${VENDOR_ID}&hotelId=${HOTEL_ID}`;
    try {
      dispatch({ type: GET_VENDOR_BY_ID_REQUEST });
      const { data } = await axios.get(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem(TOKEN),
        },
        withCredentials: true,
      });
      dispatch({
        type: GET_VENDOR_BY_ID_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_VENDOR_BY_ID_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };
