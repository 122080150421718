import axios from "axios";

import {  ISADMIN, TOKEN } from "../../constants";
import {
  CREATE_INVENTORY_FAIL,
  CREATE_INVENTORY_REQUEST,
  CREATE_INVENTORY_SUCCESS,
  GET_KITCHEN_INVENTORY_FAIL,
  GET_KITCHEN_INVENTORY_REQUEST,
  GET_KITCHEN_INVENTORY_SUCCESS,
  UPDATE_INVENTORY_FAIL,
  UPDATE_INVENTORY_REQUEST,
  UPDATE_INVENTORY_SUCCESS,
} from "../typesKitchen/inventoryOwner";

//create inventory
export const createInventoryAction = (formData) => async (dispatch) => {
  const isadmin = localStorage.getItem(ISADMIN);
  if (!isadmin) return;

  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/kitchen/inventory/add-item`;
  try {
    dispatch({ type: CREATE_INVENTORY_REQUEST });
    const { data } = await axios.post(url, formData, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: CREATE_INVENTORY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_INVENTORY_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

//get kitchen inventory
export const getOwnerInventoryAction = (kitchenId) => async (dispatch) => {
  const isadmin = localStorage.getItem(ISADMIN);
  if (!isadmin || !kitchenId) return;
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/kitchen/inventory?kitchenId=${kitchenId}`;
  try {
    dispatch({ type: GET_KITCHEN_INVENTORY_REQUEST });
    const { data } = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: GET_KITCHEN_INVENTORY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_KITCHEN_INVENTORY_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

//update kitchen inventory
export const updateItemFromInventory = (formData) => async (dispatch) => {
  const isadmin = localStorage.getItem(ISADMIN);
  if (!isadmin) return;
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/kitchen/inventory/update-item`;
  try {
    dispatch({ type: UPDATE_INVENTORY_REQUEST });
    const { data } = await axios.put(url, formData, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: UPDATE_INVENTORY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_INVENTORY_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};
