import axios from "axios";

import { ISADMIN, TOKEN } from "../../constants";
import {
  GET_CURRENT_STOCK_FAIL,
  GET_CURRENT_STOCK_REQUEST,
  GET_CURRENT_STOCK_SUCCESS,
  GET_STOCK_FAIL,
  GET_STOCK_REQUEST,
  GET_STOCK_SUCCESS,
  UPDATE_CURRENT_STOCK_FAIL,
  UPDATE_CURRENT_STOCK_REQUEST,
  UPDATE_CURRENT_STOCK_SUCCESS,
} from "../typesKitchen/inventoryStock";

//get inventory stock all
export const getInventoryStock = (kitchenId) => async (dispatch) => {
  if(!kitchenId) return;
  let url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/kitchen/employee/stocks/all`;
  const isadmin = localStorage.getItem(ISADMIN);
  if (isadmin)
    url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/kitchen/stocks/all?kitchenId=${kitchenId}`;

  try {
    dispatch({ type: GET_CURRENT_STOCK_REQUEST });
    const { data } = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: GET_CURRENT_STOCK_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_CURRENT_STOCK_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

//get inventory stock by id
export const getInventoryStockById =
  (stockId, kitchenId) => async (dispatch) => {
    if(!stockId) return;

    let url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/kitchen/employee/stocks?id=${stockId}`;
    const isadmin = localStorage.getItem(ISADMIN);
    if (isadmin)
      url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/kitchen/stocks?id=${stockId}&kitchenId=${kitchenId}`;
    try {
      dispatch({ type: GET_STOCK_REQUEST });
      const { data } = await axios.get(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem(TOKEN),
        },
        withCredentials: true,
      });
      dispatch({
        type: GET_STOCK_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_STOCK_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

//update inventory stock
export const updateInventoryStockAction = (formData) => async (dispatch) => {
  let url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/kitchen/employee/stocks`;
  const isadmin = localStorage.getItem(ISADMIN);
  if (isadmin)
    url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/kitchen/stocks`;

  try {
    dispatch({ type: UPDATE_CURRENT_STOCK_REQUEST });
    const { data } = await axios.put(url, formData, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: UPDATE_CURRENT_STOCK_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_CURRENT_STOCK_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};
