import { CLEAR_ERRORS } from "../../actions/types";
import {
  GET_HOTEL_GUEST_FAIL,
  GET_HOTEL_GUEST_REQUEST,
  GET_HOTEL_GUEST_SUCCESS,
} from "../../actions/types/guest";

//get hotel guest reducer
export const getHotelGuestReducer = (state = { hotel: {} }, action) => {
  switch (action.type) {
    case GET_HOTEL_GUEST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_HOTEL_GUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        hotel: action.payload,
      };
    case GET_HOTEL_GUEST_FAIL:
      return {
        ...state,
        loading: false,
        hotel: null,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
