import { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { clearError } from "../actions/clearError";
import { getAllBookingAction } from "../actions/pms/bookingGet";
import { getAllRoomAction } from "../actions/pms/roomget";
import { getOwnerProfileAction } from "../actions/pms/ownerProfile";
import { getAllHotelEmployeeAction } from "../actions/pms/employee";
import { getKitchenByHotelIdAction } from "../actions/kitchen/kitchen";

const ApiCallsBefore = (props) => {
  const { hotel } = useSelector((state) => state.Hotel);

  const callApis = async (hotelId) => {
    if (props?.isadmin && props?.isadmin?.isadmin) {
      props.setConfigurePercentage(0);
      // get all kitchens
      await props?.getKitchens(hotelId);
      await props.getEmployees(hotelId);
    }
    //2. get all rooms is rooms are configured
    await props.getRooms(hotelId);
    // get hotel bookings for check available rooms
    await props.getBookings(hotelId);
    props?.setLoading(false);
  };

  useEffect(() => {
    if (hotel && hotel?._id) {
      callApis(hotel?._id); 
    }
  }, [hotel?._id]);

  useEffect(() => {
    props.getProfile();
  }, []);

  useEffect(() => {
    if (
      (props?.profile && props?.profile?.error) ||
      (props?.rooms && props?.rooms?.error)
    ) {
      props?.clear();
    }
  }, [props?.profile, props?.rooms, props?.hotelId]);

  return null;
};

const mapStateToProps = (state) => ({
  profile: state.OwnerProfile,
  rooms: state.AllRoom,
  isadmin: state.IsAdmin,
});

const mapDispatchToProps = (dispatch) => ({
  getProfile: () => dispatch(getOwnerProfileAction()),
  getRooms: (hotelId) => dispatch(getAllRoomAction(hotelId)),
  getBookings: (hotelId) => dispatch(getAllBookingAction(hotelId)),
  getEmployees: (hotelId) => dispatch(getAllHotelEmployeeAction(hotelId)),
  getKitchens: (hotelId) => dispatch(getKitchenByHotelIdAction(hotelId)),
  clear: () => dispatch(clearError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApiCallsBefore);
