import axios from "axios";
import { ISADMIN, TOKEN } from "../../constants";
import {
  ADD_UPDATE_AMENITY_FAIL,
  ADD_UPDATE_AMENITY_REQUEST,
  ADD_UPDATE_AMENITY_SUCCESS,
  ADD_UPDATE_CONTACT_FAIL,
  ADD_UPDATE_CONTACT_REQUEST,
  ADD_UPDATE_CONTACT_SUCCESS,
  DELETE_AMENITY_FAIL,
  DELETE_AMENITY_REQUEST,
  DELETE_AMENITY_SUCCESS,
} from "../types/hotelProfile";

// add and update amenity
export const addUpdateAmenityAction = (formData) => async (dispatch) => {
  const isadmin = await JSON.parse(localStorage.getItem(ISADMIN));
  if (!isadmin) return;
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/hotels/hotel/amenity`;

  try {
    dispatch({ type: ADD_UPDATE_AMENITY_REQUEST });
    const { data } = await axios.put(url, formData, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: ADD_UPDATE_AMENITY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADD_UPDATE_AMENITY_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

// delete amenity
export const deleteAmenityAction = (hotelId, amenity) => async (dispatch) => {
  const isadmin = await JSON.parse(localStorage.getItem(ISADMIN));
  if (!isadmin || !hotelId || !amenity) return;
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/hotels/hotel/amenity`;

  try {
    dispatch({ type: DELETE_AMENITY_REQUEST });
    const { data } = await axios.delete(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
      data: {
        hotelId,
        amenity,
      },
    });
    dispatch({
      type: DELETE_AMENITY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DELETE_AMENITY_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

// add / update hotel contact
export const addUpdateContact = (formData) => async (dispatch) => {
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/hotels/contact`;

  try {
    dispatch({ type: ADD_UPDATE_CONTACT_REQUEST });
    const { data } = await axios.put(url, formData, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: ADD_UPDATE_CONTACT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADD_UPDATE_CONTACT_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};
