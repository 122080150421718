import React from "react";

const AmenityCount = () => {

    return (
        <>
            <select className="amenity-count-select">
                {
                    [1, 2, 3, 4].map((it, index) => (
                        <option key={index}>
                            {it}
                        </option>
                    ))
                }
            </select>
        </>
    );
};

export default AmenityCount;
