export const ADD_EXPENSE_REQUEST = "ADD_EXPENSE_REQUEST";
export const ADD_EXPENSE_SUCCESS = "ADD_EXPENSE_SUCCESS";
export const ADD_EXPENSE_FAIL = "ADD_EXPENSE_FAIL";
export const ADD_EXPENSE_RESET = "ADD_EXPENSE_RESET";

export const GET_ALL_EXPENSE_REQUEST = "GET_ALL_EXPENSE_REQUEST";
export const GET_ALL_EXPENSE_SUCCESS = "GET_ALL_EXPENSE_SUCCESS";
export const GET_ALL_EXPENSE_FAIL = "GET_ALL_EXPENSE_FAIL";

export const GET_EXPENSE_BY_ID_REQUEST = "GET_EXPENSE_BY_ID_REQUEST";
export const GET_EXPENSE_BY_ID_SUCCESS = "GET_EXPENSE_BY_ID_SUCCESS";
export const GET_EXPENSE_BY_ID_FAIL = "GET_EXPENSE_BY_ID_FAIL";

export const UPDATE_EXPENSE_REQUEST = "UPDATE_EXPENSE_REQUEST";
export const UPDATE_EXPENSE_SUCCESS = "UPDATE_EXPENSE_SUCCESS";
export const UPDATE_EXPENSE_FAIL = "UPDATE_EXPENSE_FAIL";
export const UPDATE_EXPENSE_RESET = "UPDATE_EXPENSE_RESET";

export const DELETE_EXPENSE_REQUEST = "DELETE_EXPENSE_REQUEST";
export const DELETE_EXPENSE_SUCCESS = "DELETE_EXPENSE_SUCCESS";
export const DELETE_EXPENSE_FAIL = "DELETE_EXPENSE_FAIL";
export const DELETE_EXPENSE_RESET = "DELETE_EXPENSE_RESET";
