import {
  ADD_EXTRA_WORK_FAIL,
  ADD_EXTRA_WORK_REQUEST,
  ADD_EXTRA_WORK_RESET,
  ADD_EXTRA_WORK_SUCCESS,
  DELETE_EXTRA_WORK_FAIL,
  DELETE_EXTRA_WORK_REQUEST,
  DELETE_EXTRA_WORK_RESET,
  DELETE_EXTRA_WORK_SUCCESS,
} from "../../actions/types/employee";
import { CLEAR_ERRORS } from "../../actions/types";

//add extra work done by the emloyee
export const employeeWorkReducer = (state = { employee: {} }, action) => {
  switch (action.type) {
    case ADD_EXTRA_WORK_REQUEST:
      return {
        ...state,
        loading: true,
        isadded: false,
      };
    case ADD_EXTRA_WORK_SUCCESS:
      return {
        ...state,
        loading: false,
        employee: action.payload,
        isadded: true,
      };
    case ADD_EXTRA_WORK_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isadded: false,
      };
    case ADD_EXTRA_WORK_RESET:
      return {
        ...state,
        loading: false,
        error: null,
        isadded: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

//delete extra work done by the employee
export const employeeWorkDeleteReducer = (state = { employee: {} }, action) => {
  switch (action.type) {
    case DELETE_EXTRA_WORK_REQUEST:
      return {
        ...state,
        loading: true,
        isdeleted: false,
      };
    case DELETE_EXTRA_WORK_SUCCESS:
      return {
        ...state,
        loading: false,
        employee: action.payload,
        isdeleted: true,
      };
    case DELETE_EXTRA_WORK_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isdeleted: false,
      };
    case DELETE_EXTRA_WORK_RESET:
      return {
        ...state,
        loading: false,
        error: null,
        isdeleted: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
