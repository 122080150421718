import React, { useState } from "react";
import { FiSearch } from "react-icons/fi";
import { RiArrowDropDownLine } from "react-icons/ri";
import SubmitButton from "../../components/buttons/SubmitButton";
import { MdOutlineCancelPresentation } from "react-icons/md";
import ToolTip from "../ToolTip";

const MultipleSelector = ({
  selectedValue,
  setSelectedValue,
  label,
  options,
  message,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const handleAmenitiesValues = (e) => {
    const getValue = e.target.value;
    // Toggle selection
    setSelectedValue((prevSelected) =>
      prevSelected.includes(getValue)
        ? prevSelected.filter((item) => item !== getValue)
        : [...prevSelected, getValue]
    );
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleAddItem = () => {
    // show after multi seleted value
    // alert(`Selected Amenities: ${selectedValue.join(", ")}`);
    setIsOpen(false);
  };

  const filteredAmenities = Object.keys(options).filter((item) =>
    item.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="relative">
      <div className="flex items-center justify-between">
        <label>{label}</label>
        <ToolTip Message={message} />
      </div>
      <div
        className="w-full h-12 flex items-center justify-start rounded-[10px] border border-[#D0D5DD] shadow-[0_1px_2px_0_rgba(16,24,40,0.05)] bg-white"
        onClick={() => setIsOpen(true)}
      >
        <div
          className={`w-full flex items-center justify-between text-[16px] leading-6 font-normal text-[#667085] pl-4 ${
            isOpen ? "pr-10" : "pr-4"
          }`}
        >
          <h2>Add Room Amenities</h2>

          <RiArrowDropDownLine
            size={30}
            className={`transition-all duration-[0.6s] ease-in-out ${
              isOpen && "rotate-180"
            } `}
          />
        </div>
      </div>
      {isOpen ? (
        <MdOutlineCancelPresentation
          size={26}
          onClick={() => setIsOpen(false)}
          className="transition-all duration-[0.3s] ease-in-out text-[#667085] hover:text-[#FF2727] absolute right-4 top-8"
        />
      ) : null}
      {!isOpen ? (
        <div className="absolute top-14 mt-2 flex flex-wrap gap-2 overflow-hidden">
          {selectedValue.map((item) => {
            return (
              <span
                className="h-10 w-fit flex items-center justify-center border border-base-primary rounded-lg bg-[#00a35e1a] text-[16px] leading-6 font-normal text-base-primary px-4"
                key={item}
              >
                {item}
              </span>
            );
          })}
        </div>
      ) : null}
      {isOpen ? (
        <div className="absolute top-[72px] w-full rounded-lg border border-[#D0D5DD] shadow-[0_1px_2px_0_rgba(16,24,40,0.05)] bg-white transition-all duration-[0.5s] ease-[0.3s] z-[10000]">
          <ul className="py-6">
            <div className="relative text-[#6E7C87] px-6">
              <FiSearch size={24} className="absolute top-3 left-10" />
              <input
                type="text"
                placeholder="Search For Amenities"
                className="text-[16px] leading-3 font-normal bg-[#F6F6F6] capitalize pl-12"
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
            <div className="h-[60vh] overflow-y-auto dropdown-scroll py-2 mt-4">
              {filteredAmenities.map((item) => {
                return (
                  <li
                    className="h-12 flex items-center justify-between border-b-[0.93px] border-[#E5E9EB] text-[16px] leading-[20.16px] font-medium tracking-[-1.5%] mx-6 capitalize"
                    key={item}
                  >
                    <p className="w-3/4">{item}</p>
                    <input
                      type="checkbox"
                      value={item}
                      onChange={handleAmenitiesValues}
                      checked={selectedValue.includes(item)}
                      className="shadow-none size-[23px] border-[#B0BABF] accent-current"
                    />
                  </li>
                );
              })}
            </div>
            <div className="gap-2 pl-6 pr-10 pt-6 pb-2">
              <SubmitButton
                type="button"
                text={"Add"}
                sizing={"h-12 w-full"}
                onClick={handleAddItem}
              />
              {/* <CancelButton onClick={()=>setIsOpen(false)}/> */}
            </div>
          </ul>
        </div>
      ) : null}
    </div>
  );
};

export default MultipleSelector;
