export const ADD_ROOM_REQUEST = "ADD_ROOM_REQUEST";
export const ADD_ROOM_SUCCESS = "ADD_ROOM_SUCCESS";
export const ADD_ROOM_FAIL = "ADD_ROOM_FAIL";
export const ADD_ROOM_RESET = "ADD_ROOM_RESET";

export const GET_HOTEL_ROOMS_REQ = "GET_HOTEL_ROOMS_REQ";
export const GET_HOTEL_ROOMS_SUC = "GET_HOTEL_ROOMS_SUC";
export const GET_HOTEL_ROOMS_FAIL = "GET_HOTEL_ROOMS_FAIL";

export const GET_ROOM_BY_ID_REQ = "GET_ROOM_BY_ID_REQ";
export const GET_ROOM_BY_ID_SUC = "GET_ROOM_BY_ID_SUC";
export const GET_ROOM_BY_ID_FAIL = "GET_ROOM_BY_ID_FAIL";

export const UPDATE_ROOM_REQUEST = "UPDATE_ROOM_REQUEST";
export const UPDATE_ROOM_SUCCESS = "UPDATE_ROOM_SUCCESS";
export const UPDATE_ROOM_FAIL = "UPDATE_ROOM_FAIL";
export const UPDATE_ROOM_RESET = "UPDATE_ROOM_RESET";

//delete
export const DELETE_ROOM_REQUEST = "DELETE_ROOM_REQUEST";
export const DELETE_ROOM_SUCCESS = "DELETE_ROOM_SUCCESS";
export const DELETE_ROOM_FAIL = "DELETE_ROOM_FAIL";
export const DELETE_ROOM_RESET = "DELETE_ROOM_RESET";

export const DELETE_ROOM_NUMBER_REQ = "DELETE_ROOM_NUMBER_REQ";
export const DELETE_ROOM_NUMBER_SUC = "DELETE_ROOM_NUMBER_SUC";
export const DELETE_ROOM_NUMBER_FAIL = "DELETE_ROOM_NUMBER_FAIL";
export const DELETE_ROOM_NUMBER_RESET = "DELETE_ROOM_NUMBER_RESET";



