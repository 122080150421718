import React, { useState } from "react";
import AllAlaCarte from "../kitchen/AllAlaCarte";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { registerEvent } from "../../logs";
import { isObjEmpty } from "../../utils";
import { ROUTES } from "../../constants";
import Seperator from "../../components/layouts/Seperator";
import EditButton from "../../components/buttons/EditButton";
import DeleteButton from "../../components/buttons/DeleteButton";
import AddButton from "../../components/buttons/AddButton";
import AddItemToMenu from "./AddItemToMenu";

const AlaCarte = () => {
  const { t } = useTranslation();
  const { hotel } = useSelector((state) => state.Kitchen);
  const { kitchen } = useSelector((state) => state.Kitchen);
  const [addCreateMenuItem, setAddCreateMenuItem] = useState(false);
  const [menu, setMenu] = useState({});

  //register event
  const registerEventAndOpen = () => {
    setAddCreateMenuItem(true);
    registerEvent("add alacarte menu item button open", "add menu item", {
      addCreateMenuItem,
    });
  };

  // register evnet on close add asset component
  const close = () => {
    if (!isObjEmpty(menu)) setMenu(null);
    setAddCreateMenuItem(false);
    registerEvent("add alacarte menu item button close", "add menu item", {
      addCreateMenuItem,
    });
  };
  const data = {
    heading: t("Kladee's Cafe - A' l a carte"),
    seperator: [
      {
        text: t("134"),
      },
      {
        link: ROUTES.KITCHENS,
        text: t("36"),
      },
      { text: t("Menu") },
    ],
  };

  return (
    <div className="page-container">
      <div className="w-full max-w-7xl mx-auto">
        <div className="flex justify-between items-end">
          <Seperator data={data} />
          <div className="flex gap-2">
            <EditButton />
            <DeleteButton />
            <AddButton text={t("1065")} onClick={registerEventAndOpen} />
          </div>
        </div>
      </div>
      {!isObjEmpty(menu) || addCreateMenuItem ? (
        <AddItemToMenu
          close={close}
          hotel={hotel}
          kitchen={kitchen}
          menu={menu}
        />
      ) : null}
      {/* data table for AlaCarte menu */}
      <div className="my-4">
        <AllAlaCarte />
      </div>
    </div>
  );
};

export default AlaCarte;
