import React, { useState } from "react";
import { registerEvent } from "../../logs";
import BookingSourceAdd from "./BookingSourceAdd";
import BookingSourceAll from "./BookingSourceAll";
import { useTranslation } from "react-i18next";
// import { HiOutlinePlusSm } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants";
import DeleteButton from "../../components/buttons/DeleteButton";
import EditButton from "../../components/buttons/EditButton";
import AddsquareIcon from "../../images/icons/AddsquareIcon.svg";

const BookingSourceConfig = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  // const [bookingSources, setBookingSources] = useState([]);
  const navigate = useNavigate();

  //remove source
  // const removeSource = async (value) => {
  //   const result = bookingSources.filter((data) => data != value);
  //   setBookingSources(result);
  // };

  //GOTO NEXT
  const gotoNext = () => navigate(`${ROUTES.HOTEL_CONFIG}/6`);

  //GOTO PREVIOUS
  const skipTheTab = () => navigate(`${ROUTES.HOTEL_CONFIG}/6`);

  return (
    <>
      <div className="w-full max-w-full">
        <div className="flex justify-end gap-2">
          <EditButton />
          <DeleteButton />
          <button
            className="py-2 px-3 min-w-[123px] w-fit h-10 flex items-center bg-base-primary hover:bg-base-primary_hover rounded-md gap-2"
            onClick={() => {
              setOpen(true);
              registerEvent({
                eventName: "OPEN BOOKING SOURCE",
                section: "add booking source button",
              });
            }}
          >
            <img src={AddsquareIcon} alt="" />
            {/* <div className="border border-white rounded-md p-[2px] text-white">
              <HiOutlinePlusSm />
            </div> */}
            <div className="text-[14px] font-semibold tracking-[-0.006em] text-[#F6F8F9] whitespace-nowrap">
              {t("413")}
            </div>
          </button>
        </div>
      </div>
      {
        open ? (
          <BookingSourceAdd
            open={open}
            setOpen={() => setOpen(!open)}
            hotel={props.hotel}
          />
        ) : null
        // <button
        //   type="button"
        //   className={`our-button w-52`}
        //   onClick={() => {
        //     setOpen(true);
        //     registerEvent({
        //       eventName: "OPEN BOOKING SOURCE",
        //       section: "add booking source button",
        //     });
        //   }}
        // >
        //   {t("add Booking Source")}
        // </button>
      }
      <div className="">
        {/* show all sources */}
        <BookingSourceAll
          hotel={props.hotel}
          skipButton={skipTheTab}
          nextButton={gotoNext}
        />

        {/* <div className="w-full mx-auto max-w-7xl flex justify-end items-center gap-2 px-4 pb-3">
          <SkipButton text={t("260")} onClick={skipTheTab} />
          <AddSubmitButton text={t("65")} width={88} onClick={gotoNext} />
        </div> */}
        {/* <div className="py-2 ">
          {bookingSources.map((source, index) => (
            <div className="flex items-start justify-between py-2" key={index}>
              <div>{source.source}</div>
              <button
                type="button"
                className="hover:text-red-400 text-xl duration-300"
                onClick={() => removeSource(source)}
              >
                <MdClose />
              </button>
            </div>
          ))}
        </div> */}
        {/* <EmailForwording /> */}
      </div>
    </>
  );
};

export default BookingSourceConfig;
