import React, { useState } from "react";

const OPTIONS = {
  CHECKEDIN: "Checked-IN",
  RESERVED: "Reserved",
  CANCEL: "Cancel",
};

const BookingStatus = () => {
  const [value, setValue] = useState("CHECKEDIN");

  return (
    <>
      <select
        onChange={(e) => setValue(e?.target?.value)}
        value={value}
        className="inspection-selector"
        style={{
          backgroundColor:
            value === "CHECKEDIN"
              ? "#ECFDF3!important"
              : value === "RESERVED"
              ? "#EEF4FF!important"
              : "#FEF3F2!important",
          color:
            value === "CHECKEDIN"
              ? "#12B76A"
              : value === "RESERVED"
              ? "#444CE7"
              : "#F04438",
        }}
      >
        {Object.keys(OPTIONS)?.map((it, index) => (
          <option className="capitalize" key={index} value={OPTIONS[it]}>
            {OPTIONS[it]}
          </option>
        ))}
      </select>
    </>
  );
};

export default BookingStatus;
