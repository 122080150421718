import React, { useEffect, useState } from "react";
import { 
  ROUTES,
} from "../../constants";
import { useTranslation } from "react-i18next";
import Seperator from "../../components/layouts/Seperator";
import { isObjEmpty } from "../../utils";
import { CustomeTable } from "../../components";
import DetailsHeading from "../../components/layouts/DetailsHeading";

const CheckInventoryDetails = (props) => {
  const { t } = useTranslation();
  const [datas, setDatas] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [usedFromTomorrowStock, setUsedFromTomorrowStock] = useState("");
  const [usedQuantity, setUsedQuantity] = useState("");
  const [remainingQuantity, setRemainingQuantity] = useState("");

  useEffect(() => {
    if (props?.kitchen && !isObjEmpty(props?.kitchen?.kitchen)) {
      setDatas([
        {
          menu: props?.kitchen?.kitchen?.menu,
          // usedFromTomorrowStock: props?.kitchen?.usedFromTomorrowStock,
          totalQuantity: props?.kitchen?.totalQuantity,
          // usedQuantity: props?.kitchen?.usedQuantity,
          // remainingQuantity: props?.kitchen.remainingQuantity,
          _id: props?.kitchen?.kitchen?._id,
        },
      ]);
    }
  }, [props?.kitchen]);
  const rowData = [
    {
      menu: "Fruit Salad",
      totalQuantity: "05 kg",
    },
    {
      menu: "Biryani",
      totalQuantity: "12 kg",
    },
    {
      menu: "Appetizer",
      totalQuantity: "09 kg",
    },
    {
      menu: "Chicken Soup",
      totalQuantity: "03 kg",
    },
    {
      menu: "Chicken wings",
      totalQuantity: "05 kg",
    },
  ];

  const getUsedFromTomorrowStock = () => {
    return (
      <>
        <input
          type="text"
          className="h-8 border-none shadow-none placeholder:text-[#252C32] bg-transparent focus:bg-[#FFFFFF]"
          value={usedFromTomorrowStock}
          onChange={(e) => setUsedFromTomorrowStock(+e.target.value)}
          placeholder="Enter Value"
        />
      </>
    );
  };
  const getUsedQuantity = () => {
    return (
      <>
        <input
          type="text"
          className="h-8 border-none shadow-none placeholder:text-[#252C32] bg-transparent focus:bg-[#FFFFFF]"
          value={usedQuantity}
          onChange={(e) => setUsedQuantity(e.target.value)}
          placeholder="Enter Value"
        />
      </>
    );
  };
  const getRemainingQuantity = () => {
    return (
      <>
        <input
          type="text"
          className="h-8 border-none shadow-none placeholder:text-[#252C32] bg-transparent focus:bg-[#FFFFFF]"
          value={remainingQuantity}
          onChange={(e) => setRemainingQuantity(e.target.value)}
          placeholder="Enter Value"
        />
      </>
    );
  };
  const columnDefs = [
    {
      headerName: "Menu",
      field: "menu",
      maxWidth: 150,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Used From Tomorrow Stock",
      field: "usedFromTomorrowStock",
      maxWidth: 300,
      cellRenderer: getUsedFromTomorrowStock,
    },
    {
      headerName: "Total Quantity",
      field: "totalQuantity",
      maxWidth: 300,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Used Quantity",
      field: "usedQuantity",
      maxWidth: 300,
      cellRenderer: getUsedQuantity,
    },
    {
      headerName: "Remaining Quantity",
      field: "remainingQuantity",
      maxWidth: 300,
      cellRenderer: getRemainingQuantity,
    },
  ];

  const dataa = {
    heading: t("36"),
    seperator: [
      {
        text: t("134"),
      },
      {
        link: ROUTES.KITCHENS,
        text: t("36"),
      },
      {
        link: ROUTES.KITCHEN_BUFFETS,
        text: t("Buffet"),
      },
      {
        link: ROUTES.KITCHEN_BUFFET,
        text: t("Buffet Details"),
      },
      {
        text: t("Check Inventory Details"),
      },
    ],
  };
  return (
    <div className="page-container max-w-7xl">
      <div className="w-full max-w-full">
        <div className="flex justify-between items-end">
          <Seperator data={dataa} />
        </div>
      </div>
      <div className="text-[24px] leading-[30.24px] font-bold tracking-[-0.8px] text-[#07090D] mt-6">
        Buffet Information
      </div>
      <div className=" border border-[#E5E9EB] rounded-[10px]  bg-[#FFFFFF] p-6 my-4">
        <div className="grid grid-cols-4 items-center gap-10">
          <DetailsHeading heading={"Chef Name"} subHeading={"Amul"} />
          <DetailsHeading heading={"Order Date"} subHeading={"10-04-2024"} />
          <DetailsHeading
            heading={"Completion Date"}
            subHeading={"11-04-2024"}
          />
          <DetailsHeading heading={"Buffet Time"} subHeading={"02:00 pm"} />
          <DetailsHeading heading={"Total Dishes"} subHeading={"32"} />
          <div>
            <div className="text-[14px] text-[#6E7C87] leading-[17.64px] font-medium tracking-[-0.8px]">
              Status
            </div>
            <div className="text-[18px] text-base-primary leading-[22.68px] font-normal tracking-[-0.8px]">
              Completed
            </div>
          </div>
        </div>
      </div>

      {/* stock table */}
      <div className="text-[24px] leading-[30.24px] font-bold tracking-[-0.8px] text-[#07090D] mb-4">
        Stock Table
      </div>
      <div className="">
        <CustomeTable
          columnDefs={columnDefs}
          rowData={rowData}
          setSelectedRows={setSelectedRows}
        />
      </div>
    </div>
  );
};

export default CheckInventoryDetails;
