import React from "react"; 
import { useTranslation } from "react-i18next"; 

const CancelReservation = ({ open = true, handleClose, booking }) => {
  const { t } = useTranslation();
  const inputsStyle =
    "border border-[#D0D5DD] rounded-lg shadow-[0_1px_2px_0_rgba(16,24,40,0.05)]";
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    height: "90%",
    bgcolor: "background.paper",
    borderRadius: 2,
    p: 3,
  };
  return (
    <></>
    // <Modal
    //   open={open}
    //   onClose={handleClose}
    //   aria-labelledby="modal-modal-title"
    //   aria-describedby="modal-modal-description"
    //   sx={{ background: "#33333399" }}
    // >
    //   <Box sx={{ ...style }}>
    //     <h2 className="text-[30px] leading-[37.8px] font-bold tracking-[-1.5%] text-[#07090D] text-center">
    //       {t("626")}
    //     </h2>
    //     <button
    //       type="button"
    //       onClick={handleClose}
    //       className="absolute right-6 top-[30px]"
    //     >
    //       <RxCross2
    //         onClick={handleClose}
    //         size={26}
    //         className=" hover:rotate-90 duration-500 hover:text-[#F04438]"
    //       />
    //     </button>
    //     <h2 className="text-[20px] leading-5 font-bold my-2">{t("627")}</h2>
    //     <div className="grid grid-cols-3 items-center gap-3 my-3">
    //       <div>
    //         <label>{t("175")}</label>
    //         <div className={inputsStyle}>
    //           <input
    //             type="date"
    //             value={booking?.bookingName}
    //             readOnly={true}
    //             className="border-none shadow-none"
    //           />
    //         </div>
    //       </div>
    //       <div>
    //         <label>{t("628")}</label>
    //         <div className={inputsStyle}>
    //           <input
    //             type="date"
    //             value={"12123"}
    //             readOnly={true}
    //             className="border-none shadow-none"
    //           />
    //         </div>
    //       </div>
    //       <div>
    //         <label>{t("check-In-Date")}</label>
    //         <div className={inputsStyle}>
    //           <input
    //             type="date"
    //             value={booking?.checkInDate}
    //             readOnly={true}
    //             className="border-none shadow-none"
    //           />
    //         </div>
    //       </div>
    //       <div>
    //         <label>{t("157")}</label>
    //         <div className={inputsStyle}>
    //           <input
    //             type="date"
    //             value={booking?.checkOutDate}
    //             readOnly={true}
    //             className="border-none shadow-none"
    //           />
    //         </div>
    //       </div>
    //     </div>
    //     <h2 className="text-[20px] leading-5 font-bold">{t("629")}</h2>
    //     <div className="grid grid-cols-3 gap-2 my-2">
    //       <ReasonForCancelation text={t("630")} />
    //       <ReasonForCancelation text={t("631")} />
    //       <ReasonForCancelation text={t("632")} />
    //       <ReasonForCancelation text={t("633")} />
    //       <ReasonForCancelation text={t("634")} />
    //       <ReasonForCancelation text={t("635")} />
    //       <ReasonForCancelation text={t("636")} />
    //       <ReasonForCancelation text={t("637")} />
    //     </div>
    //     <div className="text-[14px] leading-6 font-normal text-[#344054]">
    //       {t("639")}
    //     </div>
    //     <div
    //       className={`border border-[#D0D5DD] rounded-lg shadow-[0_1px_2px_0_rgba(16,24,40,0.05)]`}
    //     >
    //       <textarea
    //         // rows={2}
    //         className="h-[70px] border-none shadow-none pt-2"
    //         placeholder={t("638")}
    //       ></textarea>
    //     </div>
    //     <div className="flex justify-end mt-4">
    //       <button
    //         className="flex justify-center gap-2 w-[188px] h-10 rounded-md items-center text-white bg-[#FF2424] hover:bg-[#aa1a1a]"
    //         type="button"
    //         onClick={handleClose}
    //       >
    //         <div className="text-sm font-medium">{t("626")}</div>
    //         <FiArrowRight size={17} />
    //       </button>
    //     </div>
    //   </Box>
    // </Modal>
  );
};

export default CancelReservation;
