import React from "react";

const PageLoader = () => {
  return (
    <div
      className={`min-h w-full relative bg-transparent flex justify-center items-center z-[1040]`}
    >
      <div className="h-8 w-8 rounded-full border-[3px] border-base-primary border-t-[#ffffff3f] animate-spin relative"></div>
    </div>
  );
};

export default PageLoader;
