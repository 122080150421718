import {
    UPDATE_VENDOR_FAIL,
    UPDATE_VENDOR_REQUEST,
    UPDATE_VENDOR_SUCCESS,
    DELETE_VENDOR_FAIL,
    DELETE_VENDOR_REQUEST,
    DELETE_VENDOR_SUCCESS,
    UPDATE_VENDOR_RESET,
    DELETE_VENDOR_RESET,
  } from "../../actions/types/vendor";
  import { CLEAR_ERRORS } from "../../actions/types";

//owner update vendor profile
export const updateVendorProfileReducer = (
    state = { vendor: {} },
    action
  ) => {
    switch (action.type) {
      case UPDATE_VENDOR_REQUEST:
        return {
          ...state,
          loading: true,
          isupdated: false,
        };
      case UPDATE_VENDOR_SUCCESS:
        return {
          ...state,
          loading: false,
          vendor: action.payload,
          isupdated: true,
        };
      case UPDATE_VENDOR_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
          isupdated: false,
        };
      case UPDATE_VENDOR_RESET:
        return {
          ...state,
          loading: false,
          error: null,
          isupdated: false,
        };
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };
  
  //delete the vendor
  export const deleteVendorProfileReducer = (
    state = { vendor: {} },
    action
  ) => {
    switch (action.type) {
      case DELETE_VENDOR_REQUEST:
        return {
          ...state,
          loading: true,
          isdeleted: false,
        };
      case DELETE_VENDOR_SUCCESS:
        return {
          ...state,
          loading: false,
          vendor: action.payload,
          isdeleted: true,
        };
      case DELETE_VENDOR_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
          isdeleted: false,
        };
      case DELETE_VENDOR_RESET:
        return {
          ...state,
          loading: false,
          error: null,
          isdeleted: false,
        };
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };
  