import {
  ADD_UPDATE_AMENITY_FAIL,
  ADD_UPDATE_AMENITY_REQUEST,
  ADD_UPDATE_AMENITY_RESET,
  ADD_UPDATE_AMENITY_SUCCESS,
  ADD_UPDATE_CONTACT_FAIL,
  ADD_UPDATE_CONTACT_REQUEST,
  ADD_UPDATE_CONTACT_RESET,
  ADD_UPDATE_CONTACT_SUCCESS,
  DELETE_AMENITY_FAIL,
  DELETE_AMENITY_REQUEST,
  DELETE_AMENITY_RESET,
  DELETE_AMENITY_SUCCESS,
} from "../../actions/types/hotelProfile";
import { CLEAR_ERRORS } from "../../actions/types";

export const addUpdateContactReducer = (state = { contacts: [] }, action) => {
  switch (action.type) {
    case ADD_UPDATE_CONTACT_REQUEST:
      return {
        ...state,
        loading: true,
        isupdated: false,
      };
    case ADD_UPDATE_CONTACT_SUCCESS:
      return {
        ...state,
        loading: false,
        contacts: action.payload,
        isupdated: true,
      };
    case ADD_UPDATE_CONTACT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isupdated: false,
      };
    case ADD_UPDATE_CONTACT_RESET:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isupdated: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const addUpdateAmenityReducer = (state = { amenities: [] }, action) => {
  switch (action.type) {
    case ADD_UPDATE_AMENITY_REQUEST:
      return {
        ...state,
        loading: true,
        isadded: false,
      };
    case ADD_UPDATE_AMENITY_SUCCESS:
      return {
        ...state,
        loading: false,
        amenities: action.payload,
        isadded: true,
      };
    case ADD_UPDATE_AMENITY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isadded: false,
      };
    case ADD_UPDATE_AMENITY_RESET:
      return {
        ...state,
        loading: false,
        isadded: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const deleteAmenityReducer = (state = { amenities: [] }, action) => {
  switch (action.type) {
    case DELETE_AMENITY_REQUEST:
      return {
        ...state,
        loading: true,
        isdeleted: false,
      };
    case DELETE_AMENITY_SUCCESS:
      return {
        ...state,
        loading: false,
        isdeleted: true,
        amenities: action.payload,
      };
    case DELETE_AMENITY_FAIL:
      return {
        ...state,
        loading: false,
        isdeleted: false,
        error: action.payload,
      };
    case DELETE_AMENITY_RESET:
      return {
        ...state,
        loading: false,
        isdeleted: false,
        error: null,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
