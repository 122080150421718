/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import BasicInformation from "./BasicInformation";
import OccupationalInformation from "./OccupationalInformation";

const EmployeeInformation = () => {
  return (
    <div>
      <div className="text-[18px] text-[#07090D] font-semibold leading-[22.68px] tracking-[-0.8px] my-4">
        Basic information
      </div>
      <div>
        <BasicInformation />
      </div>
      <div className="text-[18px] text-[#07090D] font-semibold leading-[22.68px] tracking-[-0.8px] my-4">
        Occupational Information
      </div>
      <div>
        <OccupationalInformation />
      </div>
    </div>
  );
};

export default EmployeeInformation;
