import React, { useState } from "react"; 
import { CiSquarePlus } from "react-icons/ci"; 

const Assign = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const close = () => setOpen(false);

  return (
    <div className="relative">
      <div className="flex items-center gap-2">
        <p>Assign</p>
        <button className="text-base-primary" onClick={handleOpen}>
          <CiSquarePlus />
        </button>
      </div>
      {/* <Modal
        open={open}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="h-[586px] max-h-[90vh] w-[313px] rounded-[5px] border shadow-[0_0px_24px_0px_rgba(202,202,202,0.25)] box-border bg-[#FFFFFF] overflow-hidden p-6">
            <div className="relative">
              <LuSearch className="absolute left-4 top-4 text-[#6E7C87]" />
              <input
                type="search"
                placeholder="Search For People"
                className="w-full h-[45px] rounded-[5px] bg-[#F6F6F6] placeholder:text-[16px] placeholder:leading-3 placeholder:font-normal placeholder:text-[#6E7C87] pl-10 pr-4"
              />
            </div>
            <div className="my-2 h-[380px] overflow-auto scroll-bar5 pr-3">
              {[...Array(10)].map((it, index) => (
                <AssignEmployee key={index} />
              ))}
            </div> 
            <div className="flex justify-center items-center w-full mt-10 shadow-none bg-[#F6F6F6]">
              <button
                className="w-full px-12 h-12 bg-[#00A35E] hover:bg-[#007041] text-[#FFFFFF] text-[16px] leading-3 font-normal rounded-[5px]"
                onClick={close}
              >
                Assing
              </button>
            </div>
          </div>
        </Box>
      </Modal> */}
    </div>
  );
};

export default Assign;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
};
