import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { clearError } from "../../actions/clearError";
import { placeFoodOrderAction } from "../../actions/pms/foodOrder";
import { FOOD_ORDER_RESET } from "../../actions/types/food";
import { notifyError, notifySuccess } from "../../components/alert";
import PhoneWithCode from "../../components/layouts/PhoneWithCode";
import { HOTEL_ROOM_DETAILS } from "../../constants";
import { useTranslation } from "react-i18next";
import Inputfield from "../../components/layouts"; 
import SelectFromArray from "../../components/layouts/SelectFromArray";
import FormSubmitButton from "../../components/buttons";

const PlaceOrder = (props) => {
  const { t } = useTranslation();
  const [totalPrice, setTotalPrice] = useState(0);
  const [allRooms, setAllRooms] = useState([]);
  const [orderFor, setOrderFor] = useState(1);
  const [roomNumber, setRoomNumber] = useState("");
  const [description, setDescription] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [customerName, setCustomerName] = useState("");

  //order place api call
  const handleOrder = (e) => {
    e.preventDefault();
    const data = {
      totalPrice,
      orderFor,
      deliveryDestination: "ok",
      roomNumber: orderFor === "OUTSIDE_DELIVERY" ? "NA" : roomNumber,
      mobileNo,
      customerName,
      orderDetails: props?.cartItems,
      hotelId: props?.hotel?.hotel?._id,
    };

    props.placeOrder(data);
  };

  //check for is admin or not
  const getHotelFromLocalStorage = async () => {
    const rooms = await JSON.parse(sessionStorage.getItem(HOTEL_ROOM_DETAILS));

    if (rooms) {
      const datas = Object.keys(rooms);
      setAllRooms(datas);
      setRoomNumber(datas[0]);
    }
  };

  useEffect(() => {
    getHotelFromLocalStorage();
  }, []);

  //calculate totol price
  useEffect(() => {
    let price = 0;
    if (props?.cartItems && props?.cartItems?.length) {
      // eslint-disable-next-line array-callback-return
      props?.cartItems.map((item) => {
        price += item?.quantity * item?.price;
      });
    }

    setTotalPrice(price);
  }, [props?.cartItems]);

  //show message
  useEffect(() => {
    if (props?.data && props?.data?.isordered) {
      notifySuccess(t("842"));
      props.reset();
    }
    if (props?.data && props?.data?.error) {
      notifyError(t("843"));
      props?.clear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.data]);

  return (
    <>
      <div className="text-[30px] text-[#000000] leading-[37.8px] tracking-[-0.8px] font-bold my-4">
        {t("269")}
      </div>
      <form
        className="flex flex-col p-6 max-w-2xl bg-[#FFFFFF] border border-[#D0D5DD] rounded-lg"
        onSubmit={handleOrder}
      >
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
          <Inputfield
            label={t("844")}
            value={customerName}
            onChange={(e) => setCustomerName(e.target.value)}
            placeholder={t("845")}
            required={true}
            minLength={3}
            maxLength={30}
          />
          <PhoneWithCode
            phone={mobileNo}
            setPhone={setMobileNo}
            label={t("527")}
          />
          <SelectFromArray
            label={t("270")}
            value={orderFor}
            onChange={(e) => setOrderFor(e?.target?.value)}
            options={['']}
          />
          {orderFor === "OUTSIDE_DELIVERY" ? null : (
            <SelectFromArray
              label={t("846")}
              placeholder={t("847")}
              value={roomNumber}
              onChange={(e) => setRoomNumber(e?.target?.value)}
              options={allRooms}
            />
          )}
        </div>
        {/* customization */}
        <div className="pt-6">
          <label className="text-sm font-medium">{t("848")}</label>
          <textarea
            name=""
            cols="28"
            rows="4"
            className="pt-4"
            placeholder={t("849")}
            value={description}
            onChange={(e) => setDescription(e?.target?.value)}
            minLength={3}
            maxLength={100}
          ></textarea>
        </div>
        {/* total */}
        <div className="flex justify-between items-center mr-2 mt-5">
          <div className="flex gap-1">
            <div className="text-[30px] leading-[37.8px] text-[#000000] font-semibold">
              {t("276")}
            </div>
            <kbd className="text-[14px] leading-6 font-normal flex items-end">
              (Incl.VAT)
            </kbd>
          </div>
          <div className="text-[30px] leading-[37.8px] text-[#000000] font-semibold">
            ₹{totalPrice}
          </div>
        </div>
        {/* button */}
        <div className="relative flex justify-end mr-2 mt-4">
          <FormSubmitButton loading={props?.data?.loading} text={t("277")} />
        </div>
      </form>
    </>
  );
};

const mapStateToProps = (state) => ({
  data: state.AddFoodOrder,
  hotel: state.Hotel,
});

const mapDispatchToProps = (dispatch) => ({
  placeOrder: (data) => dispatch(placeFoodOrderAction(data)),
  clear: () => dispatch(clearError()),
  reset: () => dispatch({ type: FOOD_ORDER_RESET }),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlaceOrder);
