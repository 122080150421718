import React from "react";
import { IoIosArrowUp } from "react-icons/io";

const CollapseExpend = ({ value, setValue, text, color = "#667085" }) => {
  //value === expend
  return (
    <>
      <button
        className={`h-[38px] items-center flex gap-2 text-[${color}]`}
        onClick={() => setValue(!value)}
        type="button"
      >
        {value ? (
          <IoIosArrowUp className={`${value ? "duration-500" : ""}`} />
        ) : (
          <IoIosArrowUp
            className={`${!value ? "rotate-180 duration-500" : ""}`}
          />
        )}
        <span className="text-[16px] leading-4 font-semibold capitalize">
          {text}
        </span>
      </button>
    </>
  );
};

export default CollapseExpend;
