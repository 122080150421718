import React from "react";
import Seperator from "../../components/layouts/Seperator";
import AllLogs from "./AllLogs";
import SubmitButton from "../../components/buttons/SubmitButton";

const LogsChange = () => {
  const track = {
    heading: "Logs Change History",
    seperator: [
      {
        text: "hotel",
      },
      {
        text: "Logs Change History",
      },
    ],
  };
  return (
    <div className="page-container">
      <div className="flex justify-between items-end">
        <Seperator data={track} />
      </div>
      <div className="flex items-center justify-end mb-6">
        <SubmitButton type="button" text={"Permissions"} sizing={"h-10 w-[213px]"}/>
      </div>
      <div >
        <AllLogs/>
      </div>
    </div>
  );
};

export default LogsChange;
