import React, { useEffect, useState } from "react";
import SelectFromArray from "../../components/layouts/SelectFromArray";
import { 
  FOOD_PLANS,
  NOT_APPLICABLE,
  ROOM_TYPES,
} from "../../constants";
import {
  getAdultsAndChildrenInRoom,
  getAvailableRooms,
  getPastFutureDate,
  getToday,
} from "../../utils";
import GuestDetails from "./GuestDetails";
import { useTranslation } from "react-i18next";
import Inputfield from "../../components/layouts";
import CheckBoxInput from "../../components/layouts/CheckBoxInput";
import CollapseExpend from "./CollapseExpend";
import ChildGuestDetails from "./ChildGuestDetails"; 
import { useSelector } from "react-redux";
import SelectFromObject from "../../components/layouts/SelectFromObject";

const RoomDetails = ({
  room,
  roomDetails,
  setRoomDetails,
  isRoomChanged,
  setIsRoomChanged,
  checkInDate,
  checkOutDate,
  setRoomLen,
  isBooked,
}) => {
  const { t } = useTranslation();
  //max values set in options
  const [roomNumbers, setRoomNumbers] = useState([]);
  const [roomTypes, setRoomTypes] = useState('');
  const [maxAdults, setMaxAdults] = useState([]);
  const [maxChildren, setMaxchildren] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const { hotel } = useSelector((state) => state.Hotel);
  const [expend, setExpend] = useState(true);

  //input states
  const [roomType, setRoomType] = useState(roomTypes && roomTypes[0]);
  const [rateType, setRateType] = useState(Object.keys(FOOD_PLANS)[0]);
  const [roomNumber, setRoomNumber] = useState(NOT_APPLICABLE);
  const [adults, setAdults] = useState(0);
  const [children, setChildren] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [isSmokingRoom, setIsSmokingRoom] = useState(false);
  const [roomCheckInDate, setRoomCheckInDate] = useState(getToday());
  const [roomCheckOutDate, setRoomCheckOutDate] = useState(
    getPastFutureDate(+2)
  );
  const [guestsDetails, setGuestsDetails] = useState([]);
  const [isChanged, setIsChanged] = useState(false);

  //onchange handler or state management
  useEffect(() => {
    if (adults || children) {
      setAdults(adults);
      setChildren(children);
    }
    if (adults + children < guestsDetails?.length) {
      setGuestsDetails(guestsDetails.slice(0, adults + children));
      return;
    }
    const roomDetail = {
      roomType,
      rateType,
      roomNumber,
      adults,
      children,
      tariff: "TEST",
      discount,
      isSmokingRoom,
      checkInDate: roomCheckInDate,
      checkOutDate: roomCheckOutDate,
      guestsDetails,
    };
    roomDetails.guestDetails = new Array(adults + children);

    if (!roomDetails[room]) {
      setIsRoomChanged(!isRoomChanged);
      setRoomDetails([...roomDetails, roomDetail]);
    } else {
      roomDetails[room].roomType = roomType;
      roomDetails[room].rateType = rateType;
      roomDetails[room].roomNumber = roomNumber;
      roomDetails[room].adults = adults;
      roomDetails[room].children = children;
      roomDetails[room].discount = discount;
      roomDetails[room].tariff = "TEST";
      roomDetails[room].isSmokingRoom = isSmokingRoom;
      roomDetails[room].checkInDate = roomCheckInDate;
      roomDetails[room].checkOutDate = roomCheckOutDate;
      roomDetails[room].guestsDetails = guestsDetails;
      setIsRoomChanged(!isRoomChanged);
      setRoomDetails(roomDetails);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    roomType,
    rateType,
    roomNumber,
    adults,
    children,
    roomType,
    discount,
    isSmokingRoom,
    roomCheckInDate,
    roomCheckOutDate,
    guestsDetails,
    isChanged,
  ]);

  //get available rooms
  const getRoomsAndBookings = async (checkInDate, checkOutDate, roomType) => {
    const availableRooms = await getAvailableRooms(
      checkInDate,
      checkOutDate,
      roomType
    );
    if (availableRooms && availableRooms?.length) {
      setRoomNumbers(availableRooms);
      setRoomLen(availableRooms?.length);
    } else {
      setAdults(0);
      setChildren(0);
      setRoomLen(0);
      setRoomNumbers([]);
    }
  };
  useEffect(() => {
    getRoomsAndBookings(checkInDate, checkOutDate, roomType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkInDate, checkOutDate, roomType, isBooked]);

  //get roomtypes from session Storage
  const getRoomDataFromSession = async () => {
    const types = await JSON.parse(sessionStorage.getItem(ROOM_TYPES));
    if (types && types?.length) setRoomTypes(types);
  };
  useEffect(() => {
    getRoomDataFromSession();
  }, []);

  useEffect(() => {
    if (roomTypes && roomTypes?.length) {
      setRoomType(roomTypes[0]);
    }
  }, [roomTypes]);

  const getAdultchild = async (roomNumber) => {
    if (roomNumber) {
      const data = await getAdultsAndChildrenInRoom(roomNumber);
      //data[0] == max adults capacity in this room, data[1] == max children capacity in this room
      if (data) {
        [...Array(data[0] + 1)]?.map((i, index) =>
          setMaxAdults((prev) => [...prev, +index])
        );
        [...Array(data[1] + 1)]?.map((i, index) =>
          setMaxchildren((prev) => [...prev, +index])
        );
        if (data[0] < adults) {
          setAdults(data[0]);
        }
        if (data[1] < children) {
          setChildren(data[1]);
        }
      }
    }
  };

  //by default take first room
  useEffect(() => {
    if (roomNumbers && roomNumbers?.length) {
      setRoomNumber(roomNumbers[0]);
    } else {
      setRoomNumber(NOT_APPLICABLE);
    }
  }, [roomNumbers]);

  useEffect(() => {
    if (roomNumber === NOT_APPLICABLE) {
      setMaxAdults([]);
      setMaxchildren([]);
    } else {
      setMaxAdults([]);
      setMaxchildren([]);
      getAdultchild(roomNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomNumber]);

  return (
    <div className="px-6 py-4 border-t">
      <div className="font-bold text-[20px] leading-[100%]">{t("515")}</div>
      <CollapseExpend
        value={expend}
        setValue={setExpend}
        text={`${t("107")} ${room + 1} `}
      />

      <div
        className={`${
          expend ? "relative" : "hidden"
        } w-full grid sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-4 grid-w-full`}
      >
        <SelectFromArray
          label={t("84")}
          value={roomType}
          onChange={(e) => setRoomType(e?.target?.value)}
          options={['']}
          toolTipMessage={t("192")}
        />
        <SelectFromObject
          label={t("516")}
          value={rateType}
          setValue={setRateType}
          options={FOOD_PLANS}
          toolTipMessage={t("190")}
        />
        <SelectFromArray
          label={t("191")}
          value={roomNumber}
          onChange={(e) => setRoomNumber(e?.target?.value)}
          options={[NOT_APPLICABLE, ...roomNumbers]}
          toolTipMessage={t("793")}
        />
        <SelectFromArray
          label={t("186")}
          value={adults}
          onChange={(e) => setAdults(+e?.target?.value)}
          options={maxAdults}
          toolTipMessage={t("187")}
        />
        <SelectFromArray
          label={t("188")}
          value={children}
          onChange={(e) => setChildren(+e?.target?.value)}
          options={maxChildren}
          toolTipMessage={t("189")}
        />
        <Inputfield
          label={t("517")}
          value={discount}
          onChange={(e) => setDiscount(+e.target.value)}
          max={100}
          min={0}
        />
        <CheckBoxInput
          value={isSmokingRoom}
          onChange={() => setIsSmokingRoom(!isSmokingRoom)}
          label={t("518")}
          label2={t("519")}
        />
        <Inputfield
          label={t("139")}
          message={t("520")}
          type="date"
          value={roomCheckInDate}
          onChange={(e) => setRoomCheckInDate(e?.target?.value)}
        />
        <Inputfield
          label={t("140")}
          message={t("521")}
          type="date"
          value={roomCheckOutDate}
          onChange={(e) => setRoomCheckOutDate(e?.target?.value)}
        />
        {/* render for all guests in a room */}
      </div>
      {adults
        ? [...Array(adults)].map((item, index) => (
            <GuestDetails
              key={index}
              guest={index}
              guestsDetails={guestsDetails}
              setGuestsDetails={setGuestsDetails}
              isChanged={isChanged}
              setIsChanged={setIsChanged}
            />
          ))
        : null}
      {children
        ? [...Array(children)].map((item, index) => (
            <ChildGuestDetails
              key={index}
              guest={index}
              guestsDetails={guestsDetails}
              setGuestsDetails={setGuestsDetails}
              isChanged={isChanged}
              setIsChanged={setIsChanged}
            />
          ))
        : null}
    </div>
  );
};

export default RoomDetails;
