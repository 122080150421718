import React from "react";
import { useTranslation } from "react-i18next";

const RoomAvailable = ({ datas }) => {
  const { t} = useTranslation()
  return (
    <>
      <div className="flex">
      <div className="w-[177px] min-w-[177px] h-[60px] bg-[#F6F6F6] border-r flex px-5 items-center text-[16px] leading-[20.16px] font-medium">
          {t("993")}
        </div>
        <div
          className="w-12 min-w-12 h-[60px] border-r border-b flex justify-center items-center"
        >
        </div>
        {datas?.map((i, index) => (
          <div
            className="w-[60px] min-w-[60px] h-[60px] border-r flex justify-center items-center"
            key={index}
          >
            {index + 1}
          </div>
        ))}
      </div>
    </>
  );
};

export default RoomAvailable;
