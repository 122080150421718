import React from "react";
import ProfileNavigation from "./ProfileNavigation";

const ProfileHeader = ({ index, setIndex }) => {
  return (
    <>
      <div className="relative mb-4">
        <div className="lg:flex md:grid md:grid-cols-2 items-center">
          <ProfileNavigation
            text={"Employee Information"}
            index={index}
            buttonIndex={1}
            setIndex={setIndex}
          />
          <ProfileNavigation
            text={"Leave Management"}
            setIndex={setIndex}
            index={index}
            buttonIndex={2}
          />
          <ProfileNavigation
            text={"Shift Management"}
            setIndex={setIndex}
            index={index}
            buttonIndex={3}
          />
          <ProfileNavigation
            text={"Salary Management"}
            setIndex={setIndex}
            index={index}
            buttonIndex={4}
          />
        </div>
        {/* <div className="h-[2px] border w-4/5 absolute top-[40px] z-10"></div> */}
      </div>
    </>
  );
};

export default ProfileHeader;
