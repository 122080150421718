const CLEAR_ERRORS = "CLEAR_ERRORS";
const CLEAR_REDUX = "CLEAR_REDUX";
const IS_USER_ADMIN = "IS_USER_ADMIN";
const CONFIGURATION_PERCENTAGE = "CONFIGURATION_PERCENTAGE";
const CHANGE_HAMBURGER = "CHANGE_HAMBURGER";

// if hotel owner is calling hotel employee api than -> if api call request is get than send hotelId in query String , if api call request is post than send hotel id in body
export {
  CLEAR_ERRORS,
  CLEAR_REDUX,
  IS_USER_ADMIN,
  CONFIGURATION_PERCENTAGE,
  CHANGE_HAMBURGER,
};
