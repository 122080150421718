import React from "react";
import { useState } from "react";
import {
  AiOutlineEye,
  AiOutlineEyeInvisible,
  AiOutlineLock,
} from "react-icons/ai";
import InputError from "../../components/alert/InputError";
import { validatePassword } from "../../validations";
import { useTranslation } from "react-i18next";

const AuthPasswordInput = ({
  password,
  setPassword,
  passwordError,
  setPasswordError,
}) => {
  const [passwordEye, setPasswordEye] = useState(false);
  const { t } = useTranslation();
  //handle change
  const enterValidePassword = (val) => {
    setPassword(val);
    setPasswordError(validatePassword(val));
  };
  return (
    <>
      <div className="w-full mb-5 relative">
        <span className="absolute top-4 left-6">
          <AiOutlineLock />
        </span>
        <input
          type={passwordEye ? "text" : "password"}
          className="w-full outline-none border border-[#D0D5DD] py-3 px-14 focus:border-base-primary rounded-3xl"
          placeholder={t("211")}
          onChange={(e) => enterValidePassword(e.target.value)}
          value={password}
          maxLength={15}
        />
        <span
          className="absolute top-4 px-2 select-none cursor-pointer text-black right-2"
          onClick={() => setPasswordEye(!passwordEye)}
        >
          {passwordEye ? (
            <AiOutlineEye style={{ fontSize: "1.3rem" }} />
          ) : (
            <AiOutlineEyeInvisible style={{ fontSize: "1.3rem" }} />
          )}
        </span>
        <div className="px-4 absolute -bottom-5 left-0">
          <InputError error={passwordError} />
        </div>
      </div>
    </>
  );
};

export default AuthPasswordInput;
