import React from "react";
import { AiOutlineUser } from "react-icons/ai";
import InputError from "../../components/alert/InputError";
import { validateName } from "../../validations";
import { useTranslation } from "react-i18next";

const AuthNameInput = ({ name, setName, nameError, setNameError }) => {
  const { t } = useTranslation();
  // onchange handler
  const nameOnchange = (val) => {
    setName(val);
    setNameError(validateName(val));
  };

  return (
    <>
      <div className="w-full mb-5 relative">
        <span className="absolute top-1/2 left-6 -translate-y-1/2">
          <AiOutlineUser />
        </span>
        <input
          type="text"
          onChange={(e) => nameOnchange(e.target.value)}
          value={name}
          placeholder={t("166")}
          maxLength={30}
          className="w-full outline-none border border-[#D0D5DD] p-3 pl-14 focus:border-base-primary rounded-3xl"
        />
        {nameError ? (
          <div className="px-4 absolute -bottom-5 left-0">
            <InputError error={nameError} />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default AuthNameInput;
