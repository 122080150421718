import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { registerEvent } from "../../logs";
import RoomTypeComponent from "./AddRoomType";
import { useTranslation } from "react-i18next";
import Seperator from "../../components/layouts/Seperator";
import AddButton from "../../components/buttons/AddButton";
import { getAllRoomAction } from "../../actions/pms/roomget";
import AllRoomType from "./AllRoomType";

const RoomTypes = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [updateData, setUpdateData] = useState(null);
  const { hotel } = useSelector((state) => state.Hotel);

  //register event
  const openModal = () => {
    setOpen(true);
    setUpdateData(null);
    registerEvent("add room type component open", "add room type");
  };

  //close modal
  const closeModal = () => {
    setUpdateData(null);
    setOpen(false);
    registerEvent("add room type component close", "add room type");
  };

  useEffect(() => {
    if (hotel?._id) dispatch(getAllRoomAction(hotel?._id));
  }, []);

  const headingData = {
    heading: t("83"),
    seperator: [
      {
        text: t("75"),
      },
      {
        text: t("34"),
      },
    ],
  };

  return (
    <>
      <div className="page-container max-w-7xl">
        <div className="w-full">
          <div className=" flex justify-between items-end">
            <Seperator data={headingData} />
            <div className="flex gap-2">
              <AddButton text={t("112")} onClick={openModal} />
            </div>
          </div>
        </div>
        {open || (updateData && updateData?._id) ? (
          <RoomTypeComponent
            open={open}
            close={closeModal}
            hotel={hotel}
            updateData={updateData}
          />
        ) : null}
    
        <div className="mt-4">
          <AllRoomType setUpdateData={setUpdateData} hotel={hotel} />
        </div>
      </div>
    </>
  );
};

export default RoomTypes;
