import {
  GET_CURRENT_STOCK_FAIL,
  GET_CURRENT_STOCK_REQUEST,
  GET_CURRENT_STOCK_SUCCESS,
  GET_STOCK_FAIL,
  GET_STOCK_REQUEST,
  GET_STOCK_SUCCESS,
  UPDATE_CURRENT_STOCK_FAIL,
  UPDATE_CURRENT_STOCK_REQUEST,
  UPDATE_CURRENT_STOCK_RESET,
  UPDATE_CURRENT_STOCK_SUCCESS,
} from "../../actions/typesKitchen/inventoryStock";
import { CLEAR_ERRORS } from "../../actions/types";

//get all inventory stock
export const getInventoryStockReducer = (state = { stocks: [] }, action) => {
  switch (action.type) {
    case GET_CURRENT_STOCK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_CURRENT_STOCK_SUCCESS:
      return {
        ...state,
        loading: false,
        stocks: action.payload,
      };
    case GET_CURRENT_STOCK_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload, 
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

//get inventory stock by id
export const getInventoryStockByIdReducer = (state = { stock: {} }, action) => {
  switch (action.type) {
    case GET_STOCK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_STOCK_SUCCESS:
      return {
        ...state,
        loading: false,
        stock: action.payload,
      };
    case GET_STOCK_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

//update inventory stock
export const updateInventoryStockReducer = (state = { stock: {} }, action) => {
  switch (action.type) {
    case UPDATE_CURRENT_STOCK_REQUEST:
      return {
        ...state,
        loading: true,
        isupdated: false,
      };
    case UPDATE_CURRENT_STOCK_SUCCESS:
      return {
        ...state,
        loading: false,
        stock: action.payload,
        isupdated: true,
      };
    case UPDATE_CURRENT_STOCK_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isupdated: false,
      };
    case UPDATE_CURRENT_STOCK_RESET:
      return {
        ...state,
        loading: false,
        isupdated: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
