import axios from "axios";
import { TOKEN } from "../../constants";
import {
  VERIFY_PROPERTY_REQUEST,
  VERIFY_PROPERTY_SUCCESS,
  VERIFY_PROPERTY_FAIL,
} from "../types/hotel";

//verify property details
export const verifyPropertyAction = (query) => async (dispatch) => {
  if(!query) return;
  let url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/verify/property`;
  try {
    dispatch({ type: VERIFY_PROPERTY_REQUEST });
    const { data } = await axios.post(
      url,
      { query },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem(TOKEN),
        },
        withCredentials: true,
      }
    );
    dispatch({
      type: VERIFY_PROPERTY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: VERIFY_PROPERTY_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};
