import React, { useState } from "react";
import MultipleSelect from "../../components/layouts/MultipleSelector";
import { CustomeTable } from "../../components";
import AmenityCount from "./AmenityCount";
import UploadButton from "../../components/buttons/UploadButton";
import DownloadButton from "../../components/buttons/DownloadButton";

const HousekeepingAmenities = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [roomNumber, setRoomNumber] = useState([]);
  const [date, setDate] = useState([]);
  const [time, setTime] = useState([]);

  const tableData = [
    {
      inv_id: "IN01",
      room_no: "103",
      bedsheets: "0",
      pillows: "1",
      blankets: "1",
      towels: "4",
      tea_bags: "2",
      water_bottle: "2",
      soap: "1",
      shampoo: "0",
      toilet_paper: "2",
    },
    {
      inv_id: "IN01",
      room_no: "101",
      bedsheets: "2",
      pillows: "2",
      blankets: "0",
      towels: "2",
      tea_bags: "1",
      water_bottle: "0",
      soap: "2",
      shampoo: "1",
      toilet_paper: "1",
    },
    {
      inv_id: "IN01",
      room_no: "101",
      bedsheets: "1",
      pillows: "2",
      blankets: "1",
      towels: "1",
      tea_bags: "2",
      water_bottle: "1",
      soap: "0",
      shampoo: "0",
      toilet_paper: "2",
    },
    {
      inv_id: "IN01",
      room_no: "103",
      bedsheets: "2",
      pillows: "0",
      blankets: "1",
      towels: "3",
      tea_bags: "0",
      water_bottle: "2",
      soap: "2",
      shampoo: "1",
      toilet_paper: "0",
    },
  ];

  const columnDefs = [
    {
      headerName: "Room ID",
      field: "inv_id",
      maxWidth: 150,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Room No",
      field: "room_no",
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "bed sheets",
      field: "bedsheets",
      cellRenderer: AmenityCount,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "pillows",
      field: "pillows",
      cellRenderer: AmenityCount,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "blankets",
      field: "blankets",
      cellRenderer: AmenityCount,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "towels",
      field: "towels",
      cellRenderer: AmenityCount,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "tea bags",
      field: "tea_bags",
      cellRenderer: AmenityCount,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "water bottle",
      field: "water_bottle",
      cellRenderer: AmenityCount,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "soap",
      field: "soap",
      cellRenderer: AmenityCount,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "shampoo",
      field: "shampoo",
      cellRenderer: AmenityCount,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "toilet paper",
      field: "toilet_paper",
      cellRenderer: AmenityCount,
      filter: true,
      floatingFilter: true,
    },
  ];

  return (
    <>
      <div className="w-full h-auto border border-[#E4E4E7] rounded-[10px] bg-[#FFFFFF] px-4 py-6 mb-4">
        <div className="text-[24px] text-[#000000] leading-[30.24px] font-bold tracking-[-0.8px]">
          Staff Detail
        </div>
        <div className="grid lg:grid-cols-5 md:grid-cols-3 items-center lg:gap-12 md:gap-8 my-6">
          <MultipleSelect
            label={"Room Number"}
            values={roomNumber}
            setValues={setRoomNumber}
            options={["101", "102", "103", "104"]} //room number here
          />
          <MultipleSelect
            label={"Date"}
            values={date}
            setValues={setDate}
            options={["01-01-2024", "02-01-2024", "03-01-2024", "04-01-2024"]} //Date here
          />
          <MultipleSelect
            label={"Time"}
            values={time}
            setValues={setTime}
            options={["11:09Am", "12:01Pm", "01:10Pm"]} //Time here
          />
        </div>
        {/* Add Amenities*/}
        <div className="flex items-center justify-between">
          <div className="text-[24px] text-[#000000] leading-[30.24px] font-bold tracking-[-0.8px]">
            Amenities
          </div>
          <button className="h-12 w-[132px] text-sm text-[#FFFFFF] font-medium rounded-md bg-base-primary hover:bg-base-primary_hover shadow-[0_1px_2px_0px_rgba(16, 24, 40, 0.05)]">
            Add Amenities
          </button>
        </div>
        <div className="grid lg:grid-cols-5 md:grid-cols-3 items-center lg:gap-12 md:gap-8 my-6">
          <MultipleSelect
            label={"Bed Sheets"}
            values={time}
            setValues={setTime}
            options={["0", "1", "2"]}
          />
          <MultipleSelect
            label={"Pillows"}
            values={time}
            setValues={setTime}
            options={["0", "1", "2"]}
          />
          <MultipleSelect
            label={"Blankets"}
            values={time}
            setValues={setTime}
            options={["0", "1", "2"]}
          />
          <MultipleSelect
            label={"Towels"}
            values={time}
            setValues={setTime}
            options={["0", "1", "2", "3", "4"]}
          />
          <MultipleSelect
            label={"Tea Bags"}
            values={time}
            setValues={setTime}
            options={["0", "1", "2"]}
          />
          <MultipleSelect
            label={"Water Bottles"}
            values={time}
            setValues={setTime}
            options={["0", "1", "2"]}
          />
          <MultipleSelect
            label={"Tissue Box"}
            values={time}
            setValues={setTime}
            options={["0", "1", "2"]}
          />
          <MultipleSelect
            label={"Shampoo"}
            values={time}
            setValues={setTime}
            options={["0", "1", "2"]}
          />
          <MultipleSelect
            label={"Soap"}
            values={time}
            setValues={setTime}
            options={["0", "1", "2"]}
          />
          <MultipleSelect
            label={"Toilet Paper"}
            values={time}
            setValues={setTime}
            options={["0", "1", "2"]}
          />
        </div>
        <div className="flex items-center justify-end">
          <button className="h-12 w-[132px] text-sm text-[#FFFFFF] font-medium rounded-md bg-base-primary hover:bg-base-primary_hover shadow-[0_1px_2px_0px_rgba(16, 24, 40, 0.05)]">
            Add
          </button>
        </div>
      </div>
      <div className="flex items-center justify-between mb-4">
        <div className="text-[22px] leading-[27.72px] text-black font-bold">Issue Reported Table</div>
        <div className="flex items-center gap-4">
          <UploadButton />
          <DownloadButton />
        </div>
      </div>
      {/* data table */}
      <div className="relative mt-4">
        <CustomeTable
          rowData={tableData}
          setSelectedRows={setSelectedRows}
          columnDefs={columnDefs}
        />
      </div>
    </>
  );
};

export default HousekeepingAmenities;
