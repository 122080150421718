import axios from "axios";
import { TOKEN } from "../../constants";

import {
  ADD_EXPENSE_FAIL,
  ADD_EXPENSE_REQUEST,
  ADD_EXPENSE_SUCCESS,
  GET_ALL_EXPENSE_FAIL,
  GET_ALL_EXPENSE_REQUEST,
  GET_ALL_EXPENSE_SUCCESS,
  GET_EXPENSE_BY_ID_FAIL,
  GET_EXPENSE_BY_ID_REQUEST,
  GET_EXPENSE_BY_ID_SUCCESS,
  UPDATE_EXPENSE_REQUEST,
  UPDATE_EXPENSE_SUCCESS,
  UPDATE_EXPENSE_FAIL,
  DELETE_EXPENSE_REQUEST,
  DELETE_EXPENSE_SUCCESS,
  DELETE_EXPENSE_FAIL,
} from "../types/expense";

//add expense
export const addExpenseAction = (formData) => async (dispatch) => {
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/employee/expense`;
  try {
    dispatch({ type: ADD_EXPENSE_REQUEST });
    const { data } = await axios.post(url, formData, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: ADD_EXPENSE_SUCCESS,
      payload: data?.expenses,
    });
  } catch (error) {
    dispatch({
      type: ADD_EXPENSE_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

//get hotel all expense
export const getAllExpenseAction = (hotelId) => async (dispatch) => {
  if(!hotelId) return;
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/employee/expense/all?hotelId=${hotelId}`;
  try {
    dispatch({ type: GET_ALL_EXPENSE_REQUEST });
    const { data } = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: GET_ALL_EXPENSE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_EXPENSE_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

//get hotel expense by id
export const getExpenseByIdAction =
  (employeeId, hotelId) => async (dispatch) => {
    if(!hotelId || !employeeId) return;
    const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/employee?empId=${employeeId}&hotelId=${hotelId}`;
    try {
      dispatch({ type: GET_EXPENSE_BY_ID_REQUEST });
      const { data } = await axios.get(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem(TOKEN),
        },
        withCredentials: true,
      });
      dispatch({
        type: GET_EXPENSE_BY_ID_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_EXPENSE_BY_ID_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

//update expense
export const updateExpenseAction = (formData) => async (dispatch) => {
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/employee/update`;
  try {
    dispatch({ type: UPDATE_EXPENSE_REQUEST });
    const { data } = await axios.put(url, formData, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: UPDATE_EXPENSE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_EXPENSE_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

// delete expense
export const deleteExpenseAction =
  (hotelId, employeeId) => async (dispatch) => {
    if(!hotelId || !employeeId) return;
    const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/employee`;
    try {
      dispatch({ type: DELETE_EXPENSE_REQUEST });
      const { data } = await axios.delete(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem(TOKEN),
        },
        withCredentials: true,
        data: { hotelId, employeeId },
      });
      dispatch({
        type: DELETE_EXPENSE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: DELETE_EXPENSE_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };
