/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { CustomeTable } from "../../components";
import DelIcon from "../../images/icons/DelIcon.svg";
import EditIcon from "../../images/icons/EidIcon.svg";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants";
import PrintButton from "../../components/buttons/PrintButton";
import UploadButton from "../../components/buttons/UploadButton";
import DownloadButton from "../../components/buttons/DownloadButton";
const AllCreateRoaster = () => {
  const [selectedRows, setSelectedRows] = useState([]);

  const tableData = [
    {
      _id: "EX001",
      rosterName: "Vendor Bill",
      position: "Manager",
      role: "Manage Staff",
      noOfShifts: "02",
      workArea: "Working on First Floor",
      timeFormat: "123",
      action: "",
    },
    {
      _id: "EX001",
      rosterName: "Vendor Bill",
      position: "Manager",
      role: "Manage Staff",
      noOfShifts: "02",
      workArea: "Working on First Floor",
      timeFormat: "123",
      action: "",
    },
    {
      _id: "EX001",
      rosterName: "Vendor Bill",
      position: "Manager",
      role: "Manage Staff",
      noOfShifts: "02",
      workArea: "Working on First Floor",
      timeFormat: "123",
      action: "",
    },
    {
      _id: "EX001",
      rosterName: "Vendor Bill",
      position: "Manager",
      role: "Manage Staff",
      noOfShifts: "02",
      workArea: "Working on First Floor",
      timeFormat: "123",
      action: "",
    },
    {
      _id: "EX001",
      rosterName: "Vendor Bill",
      position: "Manager",
      role: "Manage Staff",
      noOfShifts: "02",
      workArea: "Working on First Floor",
      timeFormat: "123",
      action: "",
    },
  ];
  const RoasterAction = () => {
    const navigate = useNavigate();
    const AssignShift = () => {
      console.log("Assign Shift");
      navigate(ROUTES.ASSIGN_SHIFT);
    };
    const deleteRoaster = () => {
      console.log("Delete The Employee");
    };
    const updateRoaster = () => {
      console.log("Update The Employee");
    };
    return (
      <div className="flex items-center gap-4 mt-[5px]">
        <button
          className="text-[14px] leading-6 font-normal tracking-[-0.6%] text-base-primary hover:text-base-primary_hover"
          onClick={AssignShift}
        >
          Assign Shift
        </button>
        <button
          className="w-[30px] h-[30px] border border-[#E5E9EB] bg-[#FFFFFF] rounded-full flex items-center justify-center hover:bg-[#E5E9EB]"
          onClick={deleteRoaster}
        >
          <img src={DelIcon} alt="" />
        </button>
        <button
          className="w-[30px] h-[30px] border border-[#E5E9EB] bg-[#FFFFFF] rounded-full flex items-center justify-center hover:bg-[#E5E9EB]"
          onClick={updateRoaster}
        >
          <img src={EditIcon} alt="" />
        </button>
      </div>
    );
  };
  const columnDefs = [
    {
      headerName: "Roaster ID",
      field: "_id",
      maxWidth: 200,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Roster Name",
      field: "rosterName",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Position",
      field: "position",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Role",
      field: "role",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "No. Of Shifts",
      field: "noOfShifts",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Work Area",
      field: "workArea",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Time Format",
      field: "timeFormat",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Actions",
      field: "_id",
      minWidth: 200,
      cellRenderer: RoasterAction,
    },
  ];

  return (
    <div>
      <div className="flex items-center justify-between mb-4">
        <div className="text-[22px] leading-[27.72px] text-black font-bold">
          Roaster Table
        </div>
        <div className="flex items-center gap-4">
          <PrintButton />
          <UploadButton />
          <DownloadButton />
        </div>
      </div>
      <CustomeTable
        columnDefs={columnDefs}
        rowData={tableData}
        setSelectedRows={setSelectedRows}
      />
    </div>
  );
};

export default AllCreateRoaster;
