import React from "react";
import Seperator from "../../components/layouts/Seperator";
import AddButton from "../../components/buttons/AddButton";
import { useTranslation } from "react-i18next";
import { ROUTES } from "../../constants";
import { useNavigate } from "react-router-dom";

const FAndBDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const data = {
    heading: t("1089"),
    seperator: [
      {
        text: t("134"),
      },
      {
        link: ROUTES.KITCHENS,
        text: t("36"),
      },
      {
        link: ROUTES.KITCHEN_MENU,
        text: t("F&B"),
      },
      {
        text: t("273"),
      },
    ],
  };
  const setKitchenAndRedirect = (link) => {
    if (link) {
      navigate(link);
    }
  };
  return (
    <>
      <div className="page-container">
        <div className="w-full max-w-7xl mx-auto">
          <div className="flex justify-between items-end">
            <Seperator data={data} />
            <div className="flex gap-2">
              <AddButton
                text={t("1065")}
                onClick={() => setKitchenAndRedirect(ROUTES.KITCHEN_MENU)}
              />
            </div>
          </div>
        </div>
        <div className="text-4xl flex justify-center items-center">
          details page is not created
        </div>
      </div>
    </>
  );
};

export default FAndBDetails;
