/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { clearError } from "../../actions/clearError";
import { changePasswordAction } from "../../actions/pms/ownerProfile";
import { CHANGE_PASSWORD_RESET } from "../../actions/types/ownerAuth";
import { notifySuccess } from "../../components/alert";
import LoadSpinner from "../../components/loader/LoadSpinner";
import PasswordInput from "./PasswordInput";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants";
import { useTranslation } from "react-i18next";

const ChangePassword = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  //validations
  const [oldError, setOldError] = useState("");
  const [newError, setNewError] = useState("");
  const [confirmError, setConfirmError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!oldPassword) {
      setOldError(t("944"));
      return;
    }
    if (!newPassword) {
      setNewError(t("945"));
      return;
    }
    if (!confirmPassword) {
      setConfirmError(t("946"));
      return;
    }
    if (newPassword !== confirmPassword) {
      setConfirmError(t("947"));
      return;
    } else {
      setConfirmError("");
    }

    const data = {
      oldPassword,
      newPassword,
      confirmPassword,
    };

    props.changeNow(data);
  };

  useEffect(() => {
    if (props?.password && props?.password?.ischanged) {
      notifySuccess(t("948"));
      props?.reset();
      navigate(ROUTES.MY_PROFILE);
    }
  }, [props.password, navigate]);

  useEffect(() => {
    if (props?.password && props?.password?.error) {
      setOldError(props?.password?.error);
      props?.clear();
    }
  }, [props?.password]);

  return (
    <>
      <div className="page-container">
        <div className="w-[450px] max-w-full mx-auto">
          <div className="p-4 w-full flex flex-col shadow-sm rounded-lg bg-[#FFFFFF] sm:p-8">
            <div className="w-full mb-8">
              <h2 className="font-bold text-[30px] leading-9 text-black tracking-tighter">
                {t("949")}
              </h2>
            </div>
            <form className="w-full" onSubmit={handleSubmit}>
              {/* old password */}
              <div className="w-full mb-8 relative">
                <PasswordInput
                  password={oldPassword}
                  setPassword={setOldPassword}
                  Placeholder={t("950")}
                  passwordError={oldError}
                  setPasswordError={setOldError}
                />
              </div>
              {/* new password */}
              <div className="w-full mb-8 relative">
                <PasswordInput
                  password={newPassword}
                  setPassword={setNewPassword}
                  Placeholder={t("951")}
                  passwordError={newError}
                  setPasswordError={setNewError}
                />
              </div>
              <div className="w-full mb-8 relative">
                <PasswordInput
                  password={confirmPassword}
                  setPassword={setConfirmPassword}
                  Placeholder={t("952")}
                  passwordError={confirmError}
                  setPasswordError={setConfirmError}
                />
              </div>
              <button
                className="our-button"
                disabled={props?.password && props?.password?.loading}
              >
                {props?.password && props?.password?.loading ? (
                  <LoadSpinner text={t("953")} />
                ) : (
                  <>{t("949")}</>
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  password: state.ChangePassword,
});

const mapDispatchToProps = (dispatch) => ({
  changeNow: (data) => dispatch(changePasswordAction(data)),
  clear: () => dispatch(clearError()),
  reset: () => dispatch({ type: CHANGE_PASSWORD_RESET }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
