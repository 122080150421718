import React from "react";
import { LuPrinter } from "react-icons/lu";
const PrintButton = ({ onClick }) => {
  return (
    <button
      type="button"
      className="flex items-center justify-center gap-3 h-10 px-4 border border-base-primary hover:bg-base-primary hover:text-white rounded-md bg-white text-sm font-semibold text-base-primary shadow-[0_1px_2px_0_rgba(16, 24, 40, 0.05)] transition-all duration-[0.3s] ease-[0s]"
      onClick={onClick}
    >
      Print
      <LuPrinter size={18} />
    </button>
  );
};

export default PrintButton;
