import axios from "axios";
import {
  GOOGLE_AUTH_FAIL,
  GOOGLE_AUTH_REQUEST,
  GOOGLE_AUTH_SUCCESS,
} from "../types/googleAuth";

//auth with google
export const authWithGoogleAction = (formData) => async (dispatch) => {
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/oAuth`;
  try {
    dispatch({ type: GOOGLE_AUTH_REQUEST });
    const { data } = await axios.post(url, formData, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    dispatch({
      type: GOOGLE_AUTH_SUCCESS,
      payload: data.token,
    });
  } catch (error) { 
    dispatch({
      type: GOOGLE_AUTH_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};
