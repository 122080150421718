import axios from "axios";
import { TOKEN } from "../../constants";
import {
  ANS_QUERY_FAIL,
  ANS_QUERY_REQUEST,
  ANS_QUERY_SUCCESS,
  GET_QUERY_FAIL,
  GET_QUERY_REQUEST,
  GET_QUERY_SUCCESS,
} from "../types/guestSupport";

// get guest queries
export const getAllQueryAction = (hotelId) => async (dispatch) => {
  if(!hotelId) return;
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/employee/query?hotelId=${hotelId}`;

  try {
    dispatch({ type: GET_QUERY_REQUEST });
    const { data } = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: GET_QUERY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_QUERY_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

//ans guest query
export const ansQueryAction = (answer, queryId) => async (dispatch) => {
  if(!answer, queryId) return;
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/employee/query/answer`;

  try {
    dispatch({ type: ANS_QUERY_REQUEST });
    const { data } = await axios.put(
      url,
      { answer, queryId },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem(TOKEN),
        },
        withCredentials: true,
      }
    );
    dispatch({
      type: ANS_QUERY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ANS_QUERY_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};
