import {
  combineReducers,
  applyMiddleware,
  legacy_createStore as createStore,
} from "redux";

import thunk from "redux-thunk";

// external import for development purpose
import { composeWithDevTools } from "redux-devtools-extension";

import {
  checkInCodeGenerateReducer,
  checkOutGuestReducer,
} from "./reducers/pms/checkInCode";
import {
  hotelOwnerAuthReducer,
  hotelOwnerLoginSignupReducer,
} from "./reducers/pms/ownerAuth";
import {
  addHotelReducer,
  getHotelReducer,
  getHotelsReducer,
  updateHotelReducer,
} from "./reducers/pms/hotel";
import {
  employeeAuthReducer,
  employeeLoginReducer,
} from "./reducers/pms/employeeAuth";
import {
  employeeShiftEndReducer,
  employeeShifts,
  employeeShiftStartReducer,
} from "./reducers/pms/employeeShift";
import {
  addEmployeeReducer,
  getAllEmployeeReducer,
  getEmployeeByIdReducer,
} from "./reducers/pms/employee";
import { getHotelGuestReducer } from "./reducers/pms/getHotelGuest";
import { changePasswordReducer } from "./reducers/pms/ownerProfile";
import {
  updateEmployeeProfileReducer,
  deleteEmployeeProfileReducer,
} from "./reducers/pms/employeeProfile";
import {
  employeeLeaveDeleteReducer,
  employeeLeaveReducer,
} from "./reducers/pms/employeeLeave";
import {
  addUpdateAmenityReducer,
  addUpdateContactReducer,
  deleteAmenityReducer,
} from "./reducers/pms/hotelProfile";
import {
  ownerProfileReducer,
  updateProfileReducer,
} from "./reducers/pms/ownerProfile";
import {
  getTransactionHistoryReducer,
  getWalletReducer,
  updateWalletReducer,
} from "./reducers/pms/wallet";
import {
  forgotPasswordReducer,
  matchOtpReducer,
  resetPasswordReducer,
} from "./reducers/pms/forgotPassword";
import {
  employeeWorkDeleteReducer,
  employeeWorkReducer,
} from "./reducers/pms/employeeWork";
import {
  cancelFoodOrderReducer,
  foodOrderReducer,
  getFoodOrderReducer,
  getFoodOrdersReducer,
} from "./reducers/pms/foodOrder";
import { getFoodMenuReducer } from "./reducers/pms/foodMenu";
import { googleAuthReducer } from "./reducers/pms/googleAuth";
import { getBookingReducer } from "./reducers/pms/bookingGet";
import {
  employeePermissionDeleteReducer,
  employeePermissionReducer,
} from "./reducers/pms/employeePermission";
import { addRoomReducer, updateRoomReducer } from "./reducers/pms/room";
import {
  deleteImagesReducer,
  uploadImagesReducer,
} from "./reducers/pms/ImageUpload";
import {
  addKitchenReducer,
  deleteKitchenReducer,
  getAllKitchensReducer,
  getKitchenReducer,
  updateKitchenReducer,
} from "./reducers/kitchen/kitchen";
import {
  addKitcheMenuItemReducer,
  deleteKitchenMenuItemReducer,
  getKitchenMenuReducer,
  updateKitchenMenuItemReducer,
} from "./reducers/kitchen/menu";
import {
  getInventoryStockByIdReducer,
  getInventoryStockReducer,
  updateInventoryStockReducer,
} from "./reducers/kitchen/inventoryStock";
import {
  createInventoryReducer,
  getInventoryItemReducer,
  getOwnerInventoryReducer,
  updateItemFromInventoryReducer,
} from "./reducers/kitchen/inventoryOwner";
import { getRoomReducer, getAllRoomReducer } from "./reducers/pms/roomget";
import { foodCartReducer } from "./reducers/pms/foodMenuCart";
import {
  deleteRoomNumberReducer,
  deleteRoomReducer,
} from "./reducers/pms/roomdelete";
import { getAllSalesReport } from "./reducers/reports/salesReport";
import { occupanciesReportReducer } from "./reducers/reports/occupancyReport";
import {
  getHotelAssetReducer,
  getHotelAssetsAllReducer,
} from "./reducers/pms/assetsget";
import {
  addHotelAssetReducer,
  deleteHotelAssetReducer,
  updateHotelAssetReducer,
} from "./reducers/pms/asset";
import { assetsReportReducer } from "./reducers/reports/assetsReportAllTime";
import { verifyEmailReducer } from "./reducers/pms/verifyEmail";
import { USER_LOGOUT } from "./actions/types/ownerAuth";
import {
  addBookingSourceReducer,
  getBookingSourceReducer,
} from "./reducers/pms/bookingSource";
import {
  addHotelWifiReducer,
  deleteHotelWifiReducer,
  getHotelWifiReducer,
  updateHotelWifiReducer,
} from "./reducers/pms/wifi";
import {
  addVendorReducer,
  getAllVendorReducer,
  getVendorByIdReducer,
} from "./reducers/pms/vendor";
import {
  deleteVendorProfileReducer,
  updateVendorProfileReducer,
} from "./reducers/pms/vendorProfile";
import {
  addBookingReducer,
  deleteBookingReducer,
  updateBookingReducer,
} from "./reducers/pms/booking";
import {
  propertyConfiguredPercentage,
  verifyPropertyReducer,
} from "./reducers/pms/verifyProperty";
import { CLEAR_REDUX } from "./actions/types";
import { isAdminReducer } from "./reducers/pms/isadmin";
import {
  assignHouseKeepingReducer,
  dirtyRoomsReducer,
  updateRoomStatusReducer,
} from "./reducers/pms/houseKeeping";
import {
  answereGuestQuery,
  getAllQueryReducer,
} from "./reducers/pms/guestSupport";
import {
  reSendOTPReducer,
  sendOTPReducer,
  verifyOTPReducer,
} from "./reducers/pms/verification";
import {
  approveReviewReducer,
  deleteReviewReducer,
  getAllReviewReducer,
  replyReviewReducer,
} from "./reducers/pms/reviews";
import {
  addExpenseReducer,
  getAllExpenseReducer,
} from "./reducers/pms/expense";
import { hamburgerReducer } from "./reducers/pms";

const appReducer = combineReducers({
  //owner authentication
  Authenticate: hotelOwnerAuthReducer,
  LoginSignup: hotelOwnerLoginSignupReducer,
  OwnerProfile: ownerProfileReducer,
  UpdateProfile: updateProfileReducer,
  GoogleAuth: googleAuthReducer,
  VerifyProfile: verifyEmailReducer,

  //forgot password
  ForgotPassword: forgotPasswordReducer,
  VerifyOTP: matchOtpReducer,
  ResetPassword: resetPasswordReducer,

  //owner profile
  ChangePassword: changePasswordReducer,

  //employee auth
  EmployeeAuth: employeeAuthReducer,
  EmployeeLogin: employeeLoginReducer,

  //employee profile  //  HOTEL&KITCHEN
  AddEmployee: addEmployeeReducer,
  AllEmployee: getAllEmployeeReducer,
  Employee: getEmployeeByIdReducer,
  UpdateEmployeeProfile: updateEmployeeProfileReducer,
  DeleteEmployeeProfile: deleteEmployeeProfileReducer,

  IsAdmin: isAdminReducer,

  //employee extra-work, leave, permission
  AddLeave: employeeLeaveReducer,
  DeleteLeave: employeeLeaveDeleteReducer,
  AddExtraWork: employeeWorkReducer,
  DeleteWork: employeeWorkDeleteReducer,
  AddEmployeePermission: employeePermissionReducer,
  DeleteEmployeePermission: employeePermissionDeleteReducer,

  //employee shift
  EmployeeShiftStart: employeeShiftStartReducer,
  EmployeeShiftEnd: employeeShiftEndReducer,
  EmployeeShifts: employeeShifts,

  //guest
  GuestHotel: getHotelGuestReducer,

  //checkin checkout code
  GenerateCode: checkInCodeGenerateReducer,
  CheckOutGuest: checkOutGuestReducer,

  //hotel
  Hotel: getHotelReducer,
  AddHotel: addHotelReducer,
  AllHotel: getHotelsReducer,
  UpdateHotel: updateHotelReducer,

  Configuration: propertyConfiguredPercentage,
  // hotel amenities
  AddAmenity: addUpdateAmenityReducer,
  DeleteAmenity: deleteAmenityReducer,
  Contacts: addUpdateContactReducer,

  //verify hotel
  VerifyProperty: verifyPropertyReducer,

  //ROOM
  Room: getRoomReducer,
  AddRoom: addRoomReducer,
  AllRoom: getAllRoomReducer,
  UpdateRoom: updateRoomReducer,
  DeleteRoom: deleteRoomReducer,
  DeleteRoomNumber: deleteRoomNumberReducer,

  // BOOKING
  AddBooking: addBookingReducer,
  AllBooking: getBookingReducer,
  UpdateBooking: updateBookingReducer,
  DeleteBooking: deleteBookingReducer,

  //kitchen
  Kitchen: getKitchenReducer,
  AllKitchen: getAllKitchensReducer,
  AddKitchen: addKitchenReducer,
  UpdateKitchen: updateKitchenReducer,
  DeleteKitchen: deleteKitchenReducer,

  //menu item
  AllMenuItem: getKitchenMenuReducer,
  AddMenuItem: addKitcheMenuItemReducer,
  UpdateMenuItem: updateKitchenMenuItemReducer,
  DeleteMenuItem: deleteKitchenMenuItemReducer,

  //FOOD ORDER
  FoodMenu: getFoodMenuReducer,
  AddFoodOrder: foodOrderReducer,
  FoodOrder: getFoodOrderReducer,
  AllFoodOrder: getFoodOrdersReducer,
  CancelFoodOrder: cancelFoodOrderReducer,
  FoodCart: foodCartReducer,

  //upload image
  UploadImage: uploadImagesReducer,
  DeleteImage: deleteImagesReducer,

  //track hotel reviews
  // HotelReviews: getHotelReviewsReducer,

  //inventory stock
  AllInventoryStock: getInventoryStockReducer,
  InventoryStock: getInventoryStockByIdReducer,
  UpdateInventoryStock: updateInventoryStockReducer,

  //inventory
  Inventory: getInventoryItemReducer,
  AddInventory: createInventoryReducer,
  AllInventory: getOwnerInventoryReducer,
  UpdateInventory: updateItemFromInventoryReducer,

  //REPORTS
  SalesReport: getAllSalesReport,
  OccupanciesReport: occupanciesReportReducer,
  AssetsReport: assetsReportReducer,

  //HOTEL ASSETS
  AllAsset: getHotelAssetsAllReducer,
  Asset: getHotelAssetReducer,
  AddAsset: addHotelAssetReducer,
  UpdateAsset: updateHotelAssetReducer,
  DeleteAsset: deleteHotelAssetReducer,

  //BOOKING SOURCE
  AddBookingSource: addBookingSourceReducer,
  GetBookingSource: getBookingSourceReducer,

  //HOTEL WIFI
  AllWifi: getHotelWifiReducer,
  AddWifi: addHotelWifiReducer,
  UpdateWifi: updateHotelWifiReducer,
  DeleteWifi: deleteHotelWifiReducer,

  //vendor
  AddVendor: addVendorReducer,
  AllVendor: getAllVendorReducer,
  Vendor: getVendorByIdReducer,
  UpdateVendorProfile: updateVendorProfileReducer,
  DeleteVendorProfile: deleteVendorProfileReducer,

  //wallet
  Wallet: getWalletReducer,
  Transactions: getTransactionHistoryReducer,
  UpdateWallet: updateWalletReducer,

  //HOUSE KEEPING
  DirtyRooms: dirtyRoomsReducer,
  UpdateDirtyRoom: updateRoomStatusReducer,
  AssingHouseKeeping: assignHouseKeepingReducer,

  //GUEST SUPPORT
  AllQuery: getAllQueryReducer,
  AnsQuery: answereGuestQuery,

  //phone otp verify
  SendOTP: sendOTPReducer,
  ReSendOTP: reSendOTPReducer,
  VerifyPhoneOTP: verifyOTPReducer,

  //reviews
  AllReview: getAllReviewReducer,
  ReplyReview: replyReviewReducer,
  ApproveReview: approveReviewReducer,
  DeleteReivew: deleteReviewReducer,

  //expense
  AllExpense: getAllExpenseReducer,
  AddExpense: addExpenseReducer,
  Hamburger: hamburgerReducer,
});

//reset reducer
const rootReducer = (state, action) => {
  if (action.type === USER_LOGOUT || action.type === CLEAR_REDUX) {
    state = undefined;
  }
  return appReducer(state, action);
};

const initialState = {};

const middleware = [thunk];

const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
  // applyMiddleware(...middleware)
);

export default store;
