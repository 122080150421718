/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { registerEvent } from "../../logs";
import { useSelector } from "react-redux";

const SingleMenuOption = ({ link, Icon, name, active, permission }) => {
  const { isadmin } = useSelector((state) => state.IsAdmin);
  const { employee } = useSelector((state) => state.EmployeeAuth);
  const { hamburger } = useSelector((state) => state.Hamburger);

  const [canAccess, setCanAccess] = useState(false);
  useEffect(() => {
    if (!isadmin) {
      if (employee && employee?.permissions?.length) {
        employee?.permissions?.map((per) => {
          if (per?.permission === permission) setCanAccess(true);
        });
      }
    }
  }, [isadmin, employee]);

  return (
    <Link
      to={
        (hamburger && !isadmin && canAccess) || (hamburger && isadmin)
          ? link
          : null
      }
      className={`${
        active === name ? "bg-base-primary text-white" : "text-black"
      } ${
        (!isadmin && canAccess) || isadmin ? "" : "blur-sm"
      } w-full relative flex items-center cursor-pointer px-2 py-2 hover:bg-base-primary my-[2px] min-h-fit rounded-xl hover:text-white duration-300`}
      onClick={() => registerEvent("CLICK TO LINK", "SIDEMENU", { link, name })}
    >
      <div className="w-6 h-6 min-w-[24px] mr-4">
        <Icon style={{ fontSize: "1.3rem", fontWeight: "300" }} />
      </div>
      <p
        className={`${
          !hamburger && "opacity-0"
        } font-medium leading-[21px] whitespace-wrap tracking-wide capitalize text-[13px]`}
      >
        {name}
      </p>
    </Link>
  );
};

export default SingleMenuOption;
