import React from "react";
import BookingSourceConfig from "./BookingSourceConfig";
import PropertyDetails from "./property-details/PropertyDetails";
import TaxCategories from "./taxes/TaxCategories";
import SettingsHeader from "./Tabs";
import WifiConfig from "./wifi/WifiConfig";
import { useSelector } from "react-redux";
import PageLoader from "../../components/loader/PageLoader";
import PropertyInfo from "./property-details/PropertyInfo";
import ContactInfo from "./property-details/ContactInfo";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Seperator from "../../components/layouts/Seperator";
import EmployeePermissions from "./employepermission";
import NewPropertyAmenities from "./property-details/NewPropertyAmenities";
import HotelCheckInConfig from "./hotel-check-in";
import PaymentModes from "./payment-modes";

const Settings = () => {
  const { t } = useTranslation();
  const { index } = useParams();
  const { hotel } = useSelector((state) => state.Hotel);

  return (
    <div className="page-container max-w-7xl">
      <Seperator
        data={{
          heading: t("48"),
          seperator: [
            {
              text: t("344"),
            },
            {
              text: t("48"),
            },
          ],
        }}
      />

      <SettingsHeader params={index} />
      {hotel && hotel?._id ? 
        <>
          {+index === 1 ? <PropertyDetails hotel={hotel} /> : null}
          {+index === 2 ? <PropertyInfo hotel={hotel} /> : null}
          {+index === 3 ? <ContactInfo hotel={hotel} /> : null}
          {+index === 4 ? <PaymentModes hotel={hotel} /> : null}
          {/* incorrect index value */}
          {+index === 9 ? <NewPropertyAmenities hotel={hotel} /> : null}
          {+index === 5 ? <BookingSourceConfig hotel={hotel} /> : null}
          {+index === 6 ? <TaxCategories hotel={hotel} /> : null}
          {+index === 7 ? <WifiConfig hotel={hotel} /> : null}
          {+index === 8 ? <EmployeePermissions hotel={hotel} /> : null}
          {+index === 10 ? <HotelCheckInConfig hotel={hotel} /> : null}
        </>
       : (
        <PageLoader />
      )}
    </div>
  );
};

export default Settings;
