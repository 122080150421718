import {
  GET_TRANS_HISTORY_FAIL,
  GET_TRANS_HISTORY_REQUEST,
  GET_TRANS_HISTORY_SUCCESS,
  GET_WALLET_FAIL,
  GET_WALLET_REQUEST,
  GET_WALLET_SUCCESS,
  UPDATE_WALLET_FAIL,
  UPDATE_WALLET_REQUEST,
  UPDATE_WALLET_RESET,
  UPDATE_WALLET_SUCCESS,
} from "../../actions/types/wallet";
import { CLEAR_ERRORS } from "../../actions/types";

//get wallet
export const getWalletReducer = (state = { wallet: {} }, action) => {
  switch (action.type) {
    case GET_WALLET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_WALLET_SUCCESS:
      return {
        ...state,
        loading: false,
        wallet: action.payload,
      };
    case GET_WALLET_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        wallet: null,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// update wallet
export const updateWalletReducer = (state = { wallet: {} }, action) => {
  switch (action.type) {
    case UPDATE_WALLET_REQUEST:
      return {
        ...state,
        loading: true,
        isupdated: false,
      };
    case UPDATE_WALLET_SUCCESS:
      return {
        ...state,
        loading: false,
        hotelOwnerProfile: action.payload,
        isupdated: true,
      };
    case UPDATE_WALLET_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isupdated: false,
      };
    case UPDATE_WALLET_RESET:
      return {
        ...state,
        loading: false,
        error: null,
        isupdated: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get transaction history
export const getTransactionHistoryReducer = (
  state = { history: {} },
  action
) => {
  switch (action.type) {
    case GET_TRANS_HISTORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_TRANS_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        history: action.payload,
      };
    case GET_TRANS_HISTORY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
