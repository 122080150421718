// all reviews
export const GET_All_EMPLOYEE_REVIEWS_REQUEST = "GET_All_REVIEW";
export const GET_All_EMPLOYEE_REVIEWS_SUCCESS = "GET_All_EMPLOYEE_REVIEWS_SUCCESS";
export const GET_All_EMPLOYEE_REVIEWS_FAIL = "GET_All_EMPLOYEE_REVIEWS_FAIL";
// reply review
export const EMPLOYEE_REPLY_A_REVIEW_REQUEST ="EMPLOYEE_REPLY_A__REVIEW";
export const EMPLOYEE_REPLY_A_REVIEW_SUCCESS ="EMPLOYEE_REPLY_A__REVIEW_SUCCESS";
export const EMPLOYEE_REPLY_A_REVIEW_FAIL ="EMPLOYEE_REPLY_A__REVIEW_FAIL";
// approve review
export const APPROVE_A_EMPLOYEE_REVIEW_REQUEST= "APPROVE_A_EMPLOYEE_REVIEW_REQUEST";
export const APPROVE_A_EMPLOYEE_REVIEW_SUCCESS= "APPROVE_A_EMPLOYEE_REVIEW_SUCCESS";
export const APPROVE_A_EMPLOYEE_REVIEW_FAIL= "APPROVE_A_EMPLOYEE_REVIEW_FAIL";
// delete review
export const DELETE_A_EMPLOYEE_REVIEW_REQUEST = "DELETE_A_REVIEW_REQUEST";
export const DELETE_A_EMPLOYEE_REVIEW_SUCCESS = "DELETE_A_REVIEW_SUCCESS";
export const DELETE_A_EMPLOYEE_REVIEW_FAIL= "DELETE_A_REVIEW_FAIL";