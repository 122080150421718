import React, { useEffect, useState } from "react";
// import { IoBagCheckOutline } from "react-icons/io5";
import { connect, useSelector } from "react-redux";
import { getFoodMenuAction } from "../../actions/pms/foodMenu";
import FoodMenuItemCard from "./FoodMenuItemCard";
import { ROUTES } from "../../constants";
import PageLoader from "../../components/loader/PageLoader";
import { getFoodOrdersAll } from "../../actions/pms/foodOrder";
import FoodOrders from "./FoodOrders";
import { Link } from "react-router-dom";
import { CLEAR_CART } from "../../actions/types/food";
import { useTranslation } from "react-i18next";
import Seperator from "../../components/layouts/Seperator";
import shoppingCartIcon from "../../images/icons/shoppingCartIcon.svg";
import { RiArrowRightLine } from "react-icons/ri";
import { RiArrowLeftLine } from "react-icons/ri";

const FoodMenu = (props) => {
  const { t } = useTranslation();
  const [datas, setDatas] = useState([]);
  const { hotel } = useSelector((state) => state.Hotel);
  const { hamburger } = useSelector((state) => state.Hamburger);
  const [done, setDone] = useState(false);

  //initialize two cart one is for showing data and second is sending data to backend
  useEffect(() => {
    if (!done && hotel && hotel?._id) {
      setDone(true);
      props.getMenu(hotel?._id);
      props.getAllOrders(hotel?._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hotel]);

  useEffect(() => {
    if (props.menu && Array.isArray(props?.menu?.menu)) {
      setDatas(props?.menu?.menu);
    }
  }, [props?.menu]);

  const headingData = {
    heading: t("261"),
    seperator: [
      { text: t("241") },
      {
        text: t("262"),
      },
    ],
  };

  return (
    <>
      {props?.menu && !props?.menu?.menu?.length ? (
        <PageLoader />
      ) : (
        <div className="page-container">
          <div className="max-w-7xl mx-auto">
            {props?.menu &&
              props?.menu?.menu &&
              Array.isArray(props?.menu?.menu) &&
              props?.menu?.menu?.length ? (
              <>
                <div className="flex justify-between items-center">
                  <Seperator data={headingData} />
                  <div className="flex gap-2">
                    <Link
                      to={props?.cart?.cartItems?.length ? ROUTES.FOOD_ORDER_SUMMARY : ""}
                      className="w-[86px] h-10 flex items-center justify-center bg-base-primary rounded-md gap-2 relative"
                    >
                      {/* cart icon */}
                      <img src={shoppingCartIcon} alt="" />
                      <div className="text-[14px] leading-[14px] text-[#F6F8F9] tracking-[-0.6%] font-semibold whitespace-nowrap">
                        {t("263")}
                      </div>
                      {/* counts */}
                      <span className="absolute top-[2px] left-[22px] text-white text-[10px] leading-[10px] tracking-[-0.6%] font-semibold">
                        {props?.cart &&
                          props?.cart?.cartItems &&
                          props?.cart?.cartItems?.length}
                      </span>
                    </Link>
                  </div>
                </div>
                <div className="flex justify-between items-center mt-6">
                  <div className="text-xl sm:text-2xl md:text-3xl font-bold leading-[37px] tracking-[-0.8 px] ">
                    {t("264")}
                  </div>
                  <div className="flex justify-center gap-8">
                    <button
                      type="button"
                      className="h-10 w-10 border border-[#E5E9EB] rounded-md flex items-center justify-center bg-white text-[#252C32] hover:text-[#667085]"
                    >
                      <RiArrowLeftLine />
                    </button>
                    <button
                      type="button"
                      className="h-10 w-10 border border-[#E5E9EB] rounded-md flex items-center justify-center bg-white text-[#252C32] hover:text-[#667085]"
                    >
                      <RiArrowRightLine />
                    </button>
                  </div>
                </div>
                <div
                  className={`${hamburger
                      ? "lg:grid-cols-3 xl:grid-cols-4"
                      : "lg:grid-cols-4 xl:grid-cols-5"
                    } w-full grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 my-4`}
                >
                  {datas?.map((item, index) => {
                    return <FoodMenuItemCard item={item} key={index} />;
                  })}
                </div>
              </>
            ) : (
              <Link className="our-button w-fit" to={ROUTES.KITCHENS}>
                {t("265")}
              </Link>
            )}
            <div className="flex justify-between items-end">
              <div className="font-bold text-xl sm:text-2xl md:text-3xl leading-9 text-black tracking-tighter">
                {t("266")}
              </div>
            </div>
            <FoodOrders />
          </div>

          {/* new design show */}
          {/* <button className="w-fit px-4 h-10 rounded-[10px] bg-base-primary text-white mt-4" onClick={goToAllFoodPage}>
            Show All Food Page
          </button> */}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  menu: state.FoodMenu,
  cart: state.FoodCart,
  allOrders: state.AllFoodOrder,
});

const mapDispatchToProps = (dispatch) => ({
  getMenu: (hotelId) => dispatch(getFoodMenuAction(hotelId)),
  getAllOrders: (hotelId) => dispatch(getFoodOrdersAll(hotelId)),
  clearCart: () => dispatch({ type: CLEAR_CART }),
});
export default connect(mapStateToProps, mapDispatchToProps)(FoodMenu);
