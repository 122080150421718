import React from "react";
import ToolTip from "../../components/ToolTip";

const InputItemName = ({ label, value, setValue, toolTipMessage }) => {
  return (
    <>
      <div>
        <label className="text-sm font-semibold text-[#344054]"> {label}</label>
        <div className="relative flex items-center">
          <input
            type="text"
            onChange={(e) => setValue(e.target.value)}
            value={value}
            className="border border-[#D0D5DD] w-full text-sm py-2 px-3 rounded-md focus:border-base-primary mr-2 h-10"
          />
          <ToolTip Message={toolTipMessage} />
        </div>
      </div>
    </>
  );
};

export default InputItemName;
