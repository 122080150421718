import React from "react";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import {
  addItemTofoodCartAction,
  removeFoodCartItem,
} from "../../actions/pms/foodMenuCart";
import { connect } from "react-redux";

const OrderSummaryItemCart = (props) => {
  //title name length
  const titlelen = 35;

  //increase quantity of the item
  const increaseQuantity = (item) => {
    props.addToCart({ ...item, quantity: item?.quantity + 1 });
  };

  //decrease quantity of the item
  const decreaseQuantity = (item) => {
    if (item.quantity > 1) {
      props.addToCart({ ...item, quantity: item?.quantity - 1 });
    }
  };

  //remove item
  // const removeCartItem = (id) => {
  //   props.removeItem(id);
  // };

  return (
    <>
      {props.item && (
        <div className="w-full h-[240px] max-w-full overflow-hidden bg-[#FFFFFF] p-3 rounded-xl shadow-base mx-auto">
          {/* image field */}
          <div className="h-[140px] w-full min-w-[8rem] overflow-hidden rounded">
            <img
              src={
                props?.item?.images &&
                props?.item?.images[0] &&
                props?.item?.images[props?.item?.images?.length - 1]?.url
              }
              alt=""
              className="h-full w-full object-cover rounded"
            />
          </div>
          {/* data field */}
          <div className="flex flex-col">
            <div className="my-1">
              <div className="text-[16px] text-[#000000] leading-[20.16px] font-semibold">
                {props?.item.name && props?.item?.name?.length > titlelen
                  ? props?.item?.name?.slice(0, titlelen - 3) + "..."
                  : props?.item?.name}
              </div>
              <div className="text-[12px] text-[#000000] leading-[15.12px] font-normal">
                Appetizer
              </div>
            </div>
            <div className="flex justify-between w-full">
              <div className="text-[20px] text-[#000000] leading-[25.2px] font-semibold  mr-3">
                ₹{props?.item?.price}
              </div>
              <div className="flex justify-between w-fit text-[#07090D]">
                <button
                  className="w-[30px] h-[30px] flex items-center justify-center rounded-md cursor-pointer select-none border border-[#D0D5DD] bg-[#FFFFFF]"
                  onClick={() => decreaseQuantity(props?.item)}
                >
                  <AiOutlineMinus size={18} />
                </button>
                <div className="w-12 flex items-center justify-center text-[14px] leading-[17.64px] font-semibold">
                  {props?.item?.quantity}
                </div>
                <button
                  className="w-[30px] h-[30px] flex items-center justify-center rounded-md cursor-pointer select-none border border-[#D0D5DD] bg-[#FFFFFF]"
                  onClick={() => increaseQuantity(props?.item)}
                >
                  <AiOutlinePlus size={18} />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  addToCart: (data) => dispatch(addItemTofoodCartAction(data)),
  removeItem: (id) => dispatch(removeFoodCartItem(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderSummaryItemCart);
