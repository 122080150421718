/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import {
  HiOutlineOfficeBuilding,
  HiOutlineUser,
  HiOutlineUsers,
} from "react-icons/hi";
//icons
import {
  BsChatRightQuote,
  BsBox,
  BsShieldLock,
  // BsBuildingCheck,
} from "react-icons/bs";
import {
  MdCleaningServices,
  MdOutlineSoupKitchen,
  MdOutlineMeetingRoom,
  MdOutlineFastfood,
  MdOutlineReviews,
  MdOutlineLocalLaundryService,
  MdOutlineSpa, MdHistory
} from "react-icons/md";
import { LuCalendarCheck } from "react-icons/lu";
import { AiOutlineSetting, AiOutlineQrcode } from "react-icons/ai";
import { IoLogOutOutline } from "react-icons/io5";
import { GiExpense } from "react-icons/gi";
import {
  RiHome5Line,
  RiCalendarCheckLine, 
} from "react-icons/ri";
import { RxClock } from "react-icons/rx";
import { TbAsset, TbCalendarCheck, TbReport } from "react-icons/tb";
import SingleMenuOption from "./SingleMenuOption";
import { PERMISSIONS, ROUTES } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { USER_LOGOUT } from "../../actions/types/ownerAuth";
import { registerEvent } from "../../logs";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SectionDivider from "./SectionDivider";
import { CHANGE_HAMBURGER } from "../../actions/types";

const SideMenu = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [active, setActive] = useState(t("29"));
  const { hotel } = useSelector((state) => state?.Hotel);
  const { hamburger } = useSelector((state) => state?.Hamburger);

  //close side menu if sidemnu open in small devices
  const closeINSmallDevice = () =>
    dispatch({ type: CHANGE_HAMBURGER, payload: false });

  //logout the user
  const logoutMe = () => {
    registerEvent("LOGOUT THE USER");
    dispatch({ type: USER_LOGOUT });
    localStorage.clear();
    sessionStorage.clear();
    navigate(props?.isadmin ? ROUTES.LOGIN : ROUTES.EMPLOYEE_LOGIN);
  };

  const url = window.location.pathname;

  const routes = [
    {
      name: t("240"),
      isdivider: true,
    },
    {
      link: ROUTES.DASHBOARD,
      name: t("29"),
      Icon: RiHome5Line,
    },
    {
      name: t("75"),
      isdivider: true,
    },
    {
      link: ROUTES.HOTELS,
      name: t("30"),
      Icon: HiOutlineOfficeBuilding,
    },
    {
      link: ROUTES.RESERVATION_GRID,
      name: t("31"),
      Icon: TbCalendarCheck,
      permission: PERMISSIONS.BOOKING,
    },
    {
      link: ROUTES.CUSTOMERS,
      name: t("32"),
      Icon: HiOutlineUsers,
      permission: PERMISSIONS.BOOKING,
    },
    {
      link: `${ROUTES.HOUSE_KEEPING}/1`,
      name: t("33"),
      Icon: MdCleaningServices,
      permission: PERMISSIONS.BOOKING,
    },
    {
      link: ROUTES.ROOMS,
      name: t("34"),
      Icon: MdOutlineMeetingRoom,
    },
    {
      link: ROUTES.LAUNDRY,
      name: t("Laundry"),
      Icon: MdOutlineLocalLaundryService,
    },
    {
      link: ROUTES.QR_CODES,
      name: t("QR Code"),
      Icon: AiOutlineQrcode,
    },
    {
      name: t("134"),
      isdivider: true,
    },
    {
      link: ROUTES.BOOKINGS,
      name: t("35"),
      Icon: LuCalendarCheck,
      permission: PERMISSIONS.BOOKING,
    },
    {
      link: ROUTES.KITCHENS,
      name: t("36"),
      Icon: MdOutlineSoupKitchen,
    },
    {
      link: ROUTES.KITCHEN_OPERATIONS,
      name: t("Kitchen Operations"),
      Icon: MdOutlineSoupKitchen,
    },
    {
      link: ROUTES.EMPLOYEES,
      name: t("37"),
      Icon: HiOutlineUser,
    },
    {
      link: ROUTES.EMPLOYEE_ROASTER,
      name: t("Roaster Management"),
      Icon: RxClock,
    },
    // {
    //   link: ROUTES.HOTEL_CHECKIN,
    //   name: t("Hotel Check In"),
    //   Icon: BsBuildingCheck,
    // },
    {
      name: t("241"),
      isdivider: true,
    },
    {
      link: ROUTES.EXPENSES,
      name: t("38"),
      Icon: GiExpense,
      permission: PERMISSIONS.EXPENSE,
    },
    {
      link: ROUTES.FOOD_MENU,
      name: t("39"),
      Icon: MdOutlineFastfood,
      permission: PERMISSIONS.KITCHEN,
    },
    {
      link: ROUTES.VENDORS,
      name: t("233"),
      Icon: BsBox,
    },
    {
      Icon: TbReport,
      name: t("40"),
      link: ROUTES.REPORTS,
    },
    {
      link: ROUTES.REVIEWS,
      name: t("41"),
      Icon: MdOutlineReviews,
      permission: PERMISSIONS.REVIEW,
    },
    {
      link: ROUTES.ASSETS,
      name: t("43"),
      Icon: TbAsset,
      permission: PERMISSIONS.ASSETS,
    },
    {
      link: ROUTES.LOGS_CHANGE,
      name: t("Logs Change"),
      Icon: MdHistory,
    },
    {
      name: t("AUTOMATION"),
      isdivider: true,
    },
    {
      link: ROUTES.ROOM_TARIIF_AUTOMATION,
      name: t("Room Tariff"),
      Icon: RiCalendarCheckLine,
    },
    {
      name: t("344"),
      isdivider: true,
    },
    {
      link: ROUTES.ACCESS_CONTROL,
      name: t("1005"),
      Icon: BsShieldLock,
    },
    {
      link: `${ROUTES.SPA}`,
      name: t("Spa"),
      Icon: MdOutlineSpa,
    },
    {
      link: ROUTES.SUPPORT,
      name: t("46"),
      Icon: BsChatRightQuote,
      permission: PERMISSIONS.RECEPTION,
    },
    {
      link: `${ROUTES.HOTEL_CONFIG}/1`,
      name: t("48"),
      Icon: AiOutlineSetting,
    },
  ];

  useEffect(() => {
    if (url) {
      routes.map((route) => {
        if (route?.link === url) setActive(route?.name);
      });
    }
  }, [url]);

  return (
    <>
      <div
        className={`${hamburger ? "left-0" : "-left-[266px]"
          } fixed top-16 w-[266px] h-[calc(100vh-4rem)] bg-white z-[1050] duration-300 max-w-full sidebar-scroll overflow-x-hidden`}
      >
        <div className="relative w-full flex flex-col select-none duration-200 overflow-y-auto overflow-x-hidden px-6 py-1 border border-r-[#D0D5DD] shadow-sm">
          {routes?.map((route) => (
            <>
              {route?.isdivider ? (
                <SectionDivider text={route?.name} key={route} />
              ) : (
                <SingleMenuOption
                  key={route?.name}
                  name={route?.name}
                  link={route?.link}
                  Icon={route?.Icon}
                  active={active}
                  permission={route?.permission}
                />
              )}
            </>
          ))}

          <button
            className="w-full h-[41px] relative flex items-center cursor-pointer px-2 overflow-hidden hover:bg-base-primary my-1 min-h-[2.5rem] rounded-xl hover:text-white text-black"
            onClick={logoutMe}
          >
            <span className="w-6 h-6 min-w-[24px] flex items-center justify-center mr-4">
              <IoLogOutOutline
                style={{ fontSize: "1.3rem", fontWeight: "300" }}
              />
            </span>
            <span className="font-medium leading-5 whitespace-wrap tracking-wide capitalize text-[13px]">
              {t("50")}
            </span>
          </button>
        </div>
      </div>
      {hamburger ? (
        <div
          className={`lg:hidden fixed w-full left-0 top-16 h-[calc(100vh-3.5rem)] bg-black opacity-10 5 z-[1040]`}
          onClick={closeINSmallDevice}
        ></div>
      ) : null}
    </>
  );
};

export default SideMenu;
