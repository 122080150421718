import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addBookingAction } from "../../actions/pms/booking";
import { getPastFutureDate, getToday, getTotalDayStay } from "../../utils";
import { useTranslation } from "react-i18next";
import { AiOutlinePrinter } from "react-icons/ai";
import Inputfield from "../../components/layouts";
import AddBookingCheckBox from "./BookingCheckBox";
import {
  PAYMENT_STATUS,
  PAYMENT_METHODS,
  ROUTES,
  BOOKING_STATUS,
} from "../../constants";
import SelectFromArray from "../../components/layouts/SelectFromArray";
import RoomDetails from "./RoomDetails";
import BillingInformation from "./BillingInformation";
import RoomSummary from "./RoomSummary";
import { useEffect } from "react";
import OtherInformation from "./OtherInformation";
import BillingSummary from "./BillingSummary";
import { notifyError, notifySuccess } from "../../components/alert";
import { ADD_BOOKING_RESET } from "../../actions/types/booking";
import { clearError } from "../../actions/clearError";
import { getAllBookingAction } from "../../actions/pms/bookingGet"; 
import Seperator from "../../components/layouts/Seperator";
import { useNavigate } from "react-router-dom";
import SelectFromObject from "../../components/layouts/SelectFromObject";
import FormSubmitButton from "../../components/buttons";

const AddBooking = ({ booking, close }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const today = getToday();
  const { isbooked, loading, error } = useSelector((state) => state.AddBooking);
  const { hotel } = useSelector((state) => state?.Hotel)
  const [isRoomChanged, setIsRoomChanged] = useState(false);
  const [roomLen, setRoomLen] = useState(0);
  const [roomDetails, setRoomDetails] = useState([]);

  // Add new state
  const [totalHoursStay, setTotalHoursStay] = useState(1);
  const [bookingSource, setBookingSource] = useState("walk-in");
  const [bookingType, setBookingType] = useState("");
  const [bookingSourceId, setBookingSourceId] = useState("");
  // const [bookingDate, setBookingDate] = useState(today);
  const [checkInDate, setCheckInDate] = useState(today);
  const [checkOutDate, setCheckOutDate] = useState(getPastFutureDate(2));
  const [bookingName, setBookingName] = useState("");
  const [numberOfRooms, setNumberOfRooms] = useState(1);
  const [bookingEmail, setBookingEmail] = useState("");
  const [paymentStatus, setPaymentStatus] = useState(PAYMENT_STATUS.PENDING);
  const [paymentMethod, setPaymentMethod] = useState(PAYMENT_METHODS.CASH);
  const [totalAmount, setTotalAmount] = useState("");
  const [isRefundable, setIsRefundable] = useState(false);
  const [refundableTill, setRefundableTill] = useState("");
  const [billTo, setBillTo] = useState("GUEST");
  const [taxExemption, setTaxExemption] = useState(false);
  const [complementoryYes, setComplementoryYes] = useState(false);
  const [complementoryNo, setComplementoryNo] = useState(false);
  const [internalNotes, setInternalNotes] = useState("");
  const [emailBookingVoucher, setEmailBookingVoucher] = useState("");
  const [isRoomOpen, setIsRoomOpen] = useState(false);
  //handle submit
  const handleSubmit = (e) => {
    e.preventDefault();

    // validation booking details
    const formData = {
      hotelId: hotel?._id,
      bookingType,
      bookingSource,
      sourceBookingId: bookingSourceId ? bookingSourceId : "",
      totalRooms: numberOfRooms,
      checkInDate,
      checkOutDate,
      totalHoursStay: 0,
      bookingName,
      bookingEmail,
      foodPlan: "PRIME",
      roomDetails: numberOfRooms ? roomDetails : [],
      internalNotes,
      billingInformation: {
        paymentStatus,
        paymentMethod,
        totalAmount: +totalAmount,
        isRefundable,
        refundLastDate: refundableTill,
        billTo,
        taxExemption,
      },
    };
    if (checkInDate === checkOutDate) {
      formData.totalHoursStay = totalHoursStay;
    }
    dispatch(addBookingAction(formData));
    console.log(hotel)
  };

  useEffect(() => {
    if (booking && booking?._id) {
      console.log(booking);
      setBookingType(booking?.bookingType);
      setRoomDetails(booking?.roomDetails);
      setBookingEmail(booking?.bookingEmail);
      setBookingName(booking?.bookingName);
      setBookingSource(booking?.bookingSource);
      setBookingSourceId(booking?.bookingSourceId);
      setCheckInDate(booking?.checkInDate?.slice(0, 10));
      setCheckOutDate(booking?.checkOutDate?.slice(0, 10));
      setNumberOfRooms(booking?.totalRooms);
      setTotalHoursStay(booking?.totalHoursStay);
      setInternalNotes(booking?.internalNotes);
      setPaymentMethod(booking?.billingInformation?.paymentMethod);
      setPaymentStatus(booking?.billingInformation?.paymentStatus);
      setTotalAmount(booking?.billingInformation?.totalAmount);
      setIsRefundable(booking?.billingInformation?.isRefundable);
      setRefundableTill(booking?.billingInformation?.refundLastDate);
      setBillTo(booking?.billingInformation?.billTo);
      setTaxExemption(booking?.billingInformation?.taxExemption);
    }
  }, [booking]);

  // message
  useEffect(() => {
    if (isbooked) {
      notifySuccess(t("494"));
      dispatch({ type: ADD_BOOKING_RESET });
      dispatch(getAllBookingAction(hotel?._id));
    }

    if (error) {
      notifyError(t("495"));
      dispatch(clearError());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isbooked, error]);

  useEffect(() => {
    console.log(checkInDate, checkOutDate);
  }, [checkInDate, checkOutDate]);

  const headingData = {
    heading: t("133"),
    seperator: [
      {
        text: t("134"),
      },
      {
        link: ROUTES.BOOKINGS,
        text: t("35"),
      },
      {
        text: t("Add Bookings"),
      },
    ],
  };
  const cancelAddBooking = () => {
    navigate(ROUTES.BOOKINGS);
  };
  return (
    <>
      <div className="page-container">
        <div className="flex justify-between items-end">
          <Seperator data={headingData} />
          <button
            className="w-[75px] h-10 border border-[#DDE2E4] rounded-[6px] text-[14px] text-[#252C32] leading-6 tracking-[-0.6%] bg-white hover:bg-[#f2f0f0]"
            onClick={() => cancelAddBooking()}
          >
            Cancel
          </button>
        </div>
        <form className="w-full relative mx-auto mt-6" onSubmit={handleSubmit}>
          <div className="w-full relative bg-white border rounded-lg overflow-hidden">
            <div className="flex justify-between items-center px-6 pt-4">
              <div className="font-bold text-[20px] leading-[100%]">
                {t("135")}
              </div>
            </div>
            <div>
              <div className="w-full relative grid sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-4 p-6 pb-4">
                <SelectFromObject
                  label={t("496")}
                  options={BOOKING_STATUS}
                  value={bookingType}
                  setValue={setBookingType}
                />
                <SelectFromArray
                  label={t("497")}
                  toolTipMessage={t("498")}
                  options={[
                    "Walk-in",
                    "booking.com",
                    "expedia"]}
                  value={bookingSource}
                  onChange={(e) => setBookingSource(e?.target?.value)}
                />
                <Inputfield
                  label={t("499")}
                  message={t("500")}
                  placeholder={t("501")}
                  value={bookingSourceId}
                  onChange={(e) => setBookingSourceId(e.target.value)}
                  required={bookingSource !== "Walk-in"}
                />

                {/* <Inputfield
                label={t("Booking Date")}
                message={t("This is Booking Date.")}
                type="date"
                placeholder={t("Select Date")}
                value={bookingDate}
                onChange={(e) => setBookingDate(e.target.value)}
                required={true}
              /> */}

                <Inputfield
                  label={t("502")}
                  type="date"
                  placeholder={t("503")}
                  value={checkInDate}
                  onChange={(e) => setCheckInDate(e.target.value)}
                  required={true}
                />
                <Inputfield
                  label={t("504")}
                  type="date"
                  placeholder={t("503")}
                  value={checkOutDate}
                  onChange={(e) => setCheckOutDate(e.target.value)}
                  required={true}
                  min={checkInDate}
                />

                {checkInDate === checkOutDate ? (
                  <Inputfield
                    label={t("505")}
                    placeholder={t("506")}
                    value={totalHoursStay}
                    onChange={(e) => setTotalHoursStay(e?.target?.value)}
                    required={true}
                    max={23}
                    min={1}
                  />
                ) : (
                  <Inputfield
                    label={t("507")}
                    placeholder={t("506")}
                    value={getTotalDayStay(checkInDate, checkOutDate)}
                    onChange={() => {
                      return null;
                    }}
                    readOnly={true}
                  />
                )}

                <Inputfield
                  label={t("508")}
                  placeholder={t("509")}
                  value={numberOfRooms}
                  onChange={(e) => setNumberOfRooms(+e.target.value)}
                  type="number"
                />
                <Inputfield
                  label={t("175")}
                  placeholder={t("387")}
                  value={bookingName}
                  onChange={(e) => setBookingName(e.target.value)}
                  required={true}
                  minLength={3}
                  maxLength={50}
                />
                <Inputfield
                  label={t("510")}
                  placeholder={t("511")}
                  value={bookingEmail}
                  onChange={(e) => setBookingEmail(e.target.value)}
                  required={true}
                  type="email"
                />
                <AddBookingCheckBox
                  label={t("512")}
                  message={t("513")}
                  text={t("113")}
                  text2={t("514")}
                  valueYes={complementoryYes}
                  setValueYes={setComplementoryYes}
                  valueNo={complementoryNo}
                  setValueNo={setComplementoryNo}
                />
              </div>
              {numberOfRooms ? (
                <div
                  className={`${numberOfRooms && isRoomOpen
                    ? " h-fit duration-500"
                    : "h-fit duration-500"
                    }`}
                >
                  {[...Array(numberOfRooms)].map((it, index) => (
                    <RoomDetails
                      room={index}
                      key={index}
                      roomDetails={roomDetails}
                      setRoomDetails={setRoomDetails}
                      isRoomChanged={isRoomChanged}
                      setIsRoomChanged={setIsRoomChanged}
                      checkInDate={checkInDate}
                      checkOutDate={checkOutDate}
                      setRoomLen={setRoomLen}
                      isbooked={isbooked}
                    />
                  ))}
                </div>
              ) : null}
            </div>
            <RoomSummary roomDetails={roomDetails} />
            <OtherInformation
              emailBookingVoucher={emailBookingVoucher}
              setEmailBookingVoucher={setEmailBookingVoucher}
              internalNotes={internalNotes}
              setInternalNotes={setInternalNotes}
            />
          </div>

          {/* Billing Summary incomplte*/}
          <BillingSummary />
          {/* billing information */}
          <BillingInformation
            paymentStatus={paymentStatus}
            setPaymentStatus={setPaymentStatus}
            paymentMethod={paymentMethod}
            setPaymentMethod={setPaymentMethod}
            amount={totalAmount}
            setAmount={setTotalAmount}
            refundable={isRefundable}
            setRefundable={setIsRefundable}
            refundableTill={refundableTill}
            setRefundableTill={setRefundableTill}
            billTo={billTo}
            setBillTo={setBillTo}
            taxExemption={taxExemption}
            setTaxExemption={setTaxExemption}
          />

          <div className="mt-6 flex justify-end items-center gap-4">
            <button
              className="h-[50px] w-fit px-6 rounded-md text-black border border-[#DDE2E4] hover:bg-gray-50 bg-[#FFFFFF] flex items-center justify-between gap-3"
              type="button"
            >
              <AiOutlinePrinter size={21} />
              <span className="text-[16px] leading-5 font-medium">
                {t("826")}
              </span>
            </button>
            <FormSubmitButton text={t("567")} loading={loading} />
          </div>
        </form>
      </div>
    </>
  );
};

export default AddBooking;
