export const ADD_VENDOR_REQUEST = "ADD_VENDOR_REQUEST";
export const ADD_VENDOR_SUCCESS = "ADD_VENDOR_SUCCESS";
export const ADD_VENDOR_FAIL = "ADD_VENDOR_FAIL";
export const ADD_VENDOR_RESET = "ADD_VENDOR_RESET";

export const GET_ALL_VENDOR_REQUEST = "GET_ALL_VENDOR_REQUEST";
export const GET_ALL_VENDOR_SUCCESS = "GET_ALL_VENDOR_SUCCESS";
export const GET_ALL_VENDOR_FAIL = "GET_ALL_VENDOR_FAIL";

export const GET_VENDOR_BY_ID_REQUEST = "GET_VENDOR_BY_ID_REQUEST";
export const GET_VENDOR_BY_ID_SUCCESS = "GET_VENDOR_BY_ID_SUCCESS";
export const GET_VENDOR_BY_ID_FAIL = "GET_VENDOR_BY_ID_FAIL";

export const UPDATE_VENDOR_REQUEST = "UPDATE_VENDOR_REQUEST";
export const UPDATE_VENDOR_SUCCESS = "UPDATE_VENDOR_SUCCESS";
export const UPDATE_VENDOR_FAIL = "UPDATE_VENDOR_FAIL";
export const UPDATE_VENDOR_RESET = "UPDATE_VENDOR_RESET";

export const DELETE_VENDOR_REQUEST = "DELETE_VENDOR_REQUEST";
export const DELETE_VENDOR_SUCCESS = "DELETE_VENDOR_SUCCESS";
export const DELETE_VENDOR_FAIL = "DELETE_VENDOR_FAIL";
export const DELETE_VENDOR_RESET = "DELETE_VENDOR_RESET";
