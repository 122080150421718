import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOwnerInventoryAction } from "../../actions/kitchen/inventoryOwner"; 
import PageLoader from "../../components/loader/PageLoader";
import { deleteMenuItem } from "../../actions/kitchen/menu";
import { isObjEmpty } from "../../utils";
import BackGroundLoadSpinner from "../../components/loader/BackGroundLoadSpinner";
import DeleteOrNot from "../../components/layouts/DeleteOrNot"; 
import { getInventoryStock } from "../../actions/kitchen/inventoryStock";
import { useTranslation } from "react-i18next"; 

const HotelAllInventory = ({ kitchen, setInventory, hotel, isadmin }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [datas, setDatas] = useState([]);
  const [itemId, setItemId] = useState("");
  const { inventories, loading } = useSelector((state) => state.AllInventory);
  const { loading: delLoading } = useSelector((state) => state.DeleteMenuItem);
  const [open, setOpen] = useState(false);

  //const set modified data
  const setModifiedData = (inventories) => {
    let newData = [];
    if (inventories && Array.isArray(inventories)) {
      // eslint-disable-next-line array-callback-return
      inventories?.map((item) => {
        newData.push({
          name: item?.name,
          price: item?.price,
          isMeasurable: item?.isMeasurable,
          isNonVeg: item?.isNonVeg,
          portionFor: item?.portionFor,
          portionSize: item?.portionSize,
          quantity: item?.quantity,
          quantityMeasurement: item?.quantityMeasurement,
          id: item?.id,
        });
      });
      if (newData?.length) {
        setDatas(newData);
      } else {
        setDatas([
          {
            name: null,
            price: null,
            isMeasurable: null,
            isNonVeg: null,
            portionFor: null,
            portionSize: null,
            quantity: null,
            quantityMeasurement: null,
            id: null,
          },
        ]);
      }
    }
  };
  useEffect(() => {
    setModifiedData(inventories);
  }, [inventories]);

  //get kitchen from session storage
  const getKitchenFromSession = async () => {
    if (inventories && Array.isArray(inventories)) {
      if (isObjEmpty(kitchen) && !isadmin) dispatch(getInventoryStock());
      else dispatch(getOwnerInventoryAction(kitchen._id));
    }
  };
  useEffect(() => {
    getKitchenFromSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kitchen]);

  //set inventory for update
  const setInventoryForUpdate = (id) => {
    // eslint-disable-next-line array-callback-return
    inventories?.map((item) => {
      if (item.id === id) {
        setInventory(item);
      }
    });
  };

  // delete inventory confirmation modal open
  const openModal = (id) => {
    if (id) {
      setOpen(true);
      setItemId(id);
    }
  };

  // delete confirm
  const confirmDelete = () => dispatch(deleteMenuItem(kitchen._id, itemId));

  //delete not confirm
  const closeModal = () => {
    setOpen(false);
    setItemId(null);
  };

  return (
    <>
      {loading ? (
        <PageLoader />
      ) : (
        <div className="">
          <div className="w-full max-w-7xl mx-auto px-4">
            <h2 className="font-bold text-[30px] leading-9 text-black tracking-tighter">
              {t("879")}
            </h2>
          </div>

          {/* {data.length ? (
            <DataTable
              getTableProps={getTableProps}
              getTableBodyProps={getTableBodyProps}
              headerGroups={headerGroups}
              page={page}
              prepareRow={prepareRow}
              pageOptions={pageOptions}
              previousPage={previousPage}
              gotoPage={gotoPage}
              nextPage={nextPage}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              pageCount={pageCount}
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
              // openFilter={openFilter}
              // changeColumns={changeColumns}
            />
          ) : null} */}
        </div>
      )}
      <DeleteOrNot open={open} close={closeModal} formSubmit={confirmDelete} />
      {delLoading ? <BackGroundLoadSpinner /> : null}
    </>
  );
};

export default HotelAllInventory;
