import axios from "axios";
import { TOKEN } from "../../constants";

import {
  GET_HOTEL_ASSETS_FAIL,
  GET_HOTEL_ASSETS_REQ,
  GET_HOTEL_ASSETS_SUC,
  GET_HOTEL_ASSET_FAIL,
  GET_HOTEL_ASSET_REQ,
  GET_HOTEL_ASSET_SUC,
} from "../types/asset";

//get hotel assets
export const getAllAssetAction = (hotelId) => async (dispatch) => {
  if (!hotelId) return;
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/employee/assets/all?hotelId=${hotelId}`;

  try {
    dispatch({ type: GET_HOTEL_ASSETS_REQ });
    const { data } = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: GET_HOTEL_ASSETS_SUC,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_HOTEL_ASSETS_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

//get hotel asset by asset id
export const getAssetAction = (assetId, hotelId) => async (dispatch) => {
  if (!assetId || !hotelId) return;
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/employee/assets?assetId=${assetId}&hotelId=${hotelId}`;

  try {
    dispatch({ type: GET_HOTEL_ASSET_REQ });
    const { data } = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: GET_HOTEL_ASSET_SUC,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_HOTEL_ASSET_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};
