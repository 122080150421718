import React from "react";
import GotoReport from "./GotoReport";
import { useTranslation } from "react-i18next";

const DashBoardHead = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="flex flex-col sm:flex-row justify-between mb-6 gap-4">
        <div>
          <div className="font-bold tracking-[-0.8px] text-[#18181B] leading-[38px] text-xl sm:text-2xl md:text-3xl">
            {t("2")}
          </div>
          <p className="text-black font-normal text-base md:text-[20px] leading-[25px] tracking-[-0.8px] opacity-60">
            {t("3")}
          </p>
        </div>
        <div className="flex gap-4 items-center">
          <div className="relative flex w-[308px] h-10 border rounded-[6px] box-border flex-row justify-center items-center gap-[14px] p-3 bg-[#FFFFFF] text-[#18181B]">
            <input
              value={props.date}
              onChange={(e) => props.setDate(e.target.value)}
              type={"date"}
              className="flex w-1/2 my-[10px] bg-[#FFFFFF] items-center border-none shadow-none rounded h-5 font-normal cursor-pointer select-none text-[14px] leading-[18px] tracking-[-0.8px]"
            />
            <input
              value={props.date}
              onChange={(e) => props.setDate(e.target.value)}
              type={"date"}
              className="flex w-1/2 my-[10px] bg-[#FFFFFF] items-center border-none shadow-none rounded h-5 font-normal cursor-pointer select-none text-[14px] leading-[18px] tracking-[-0.8px]"
            />
          </div>
          <GotoReport />
        </div>
      </div>
    </>
  );
};

export default DashBoardHead;
