import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
// import { useTranslation } from "react-i18next";
import {
  assetsReportAllTime,
  assetsReportAllTimeForOne,
} from "../../actions/reports/assetsReportAllTime";
import { assetsReportAllTimeByDate } from "../../actions/reports/assetsReportByDate";
import CustomeTable from "../../components/CustomeTable";
import DownloadButton from "../../components/buttons/DownloadButton";

const SecurityReports = (props) => {
  // const { t } = useTranslation();
  const [datas, setDatas] = useState([]);
  const { sales } = useSelector((state) => state.SalesReport);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    props.getAssetsFromToDateAll(
      props?.hotel?._id,
      props?.fromDate,
      props?.toDate
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.fromDate, props?.toDate]);

  useEffect(() => {
    if (sales && Array.isArray(sales) && sales?.length) {
      let expense = sales?.map((d) => {
        return {
          date: d?.date,
          location: d?.location,
          time: d?.time,
          type: d?.type,
          officerName: d?.officerName,
        };
      });
      setDatas(expense);
    }
  }, [sales]);

  const tableData = [
    {
      id: "SC001",
      date: "24-01-12",
      location: "Parking Lot",
      time: "1:00 pm",
      type: "Security clearance",
      officerName: "John Doe",
    },
    {
      id: "SC002",
      date: "24-01-12",
      location: "Parking Lot",
      time: "1:00 pm",
      type: "Security clearance",
      officerName: "John Doe",
    },
    {
      id: "SC003",
      date: "24-01-12",
      location: "Parking Lot",
      time: "1:00 pm",
      type: "Security clearance",
      officerName: "John Doe",
    },
    {
      id: "SC004",
      date: "24-01-12",
      location: "Parking Lot",
      time: "1:00 pm",
      type: "Security clearance",
      officerName: "John Doe",
    },
    {
      id: "SC005",
      date: "24-01-12",
      location: "Parking Lot",
      time: "1:00 pm",
      type: "Security clearance",
      officerName: "John Doe",
    },
  ];

  const columnDefs = [
    {
      headerName: "Security ID",
      field: "id",
      maxWidth: 200,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Date",
      field: "date",
      minWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Location",
      field: "location",
      maxWidth: 300,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Time",
      field: "time",
      maxWidth: 300,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Type",
      field: "type",
      maxWidth: 300,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Officer Name",
      field: "officerName",
      maxWidth: 300,
      filter: true,
      floatingFilter: true,
    },
  ];

  return (
    <>
      <div className="flex items-center justify-between my-4">
        <h2 className="text-[24px] leading-6 font-bold">Security Report</h2>
        <DownloadButton />
      </div>
      <div className="w-full mt-4">
        {/* {datas?.datas?.length ? ( */}
        <CustomeTable
          columnDefs={columnDefs}
          rowData={tableData}
          setSelectedRows={setSelectedRows}
        />
        {/* ) : null} */}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  data: state.AssetsReport,
});

const mapDispatchToProps = (dispatch) => ({
  getAllTimeAsset: (hotelId) => dispatch(assetsReportAllTime(hotelId)),
  //get assets report by date
  getAssetsReportAll: (hotelId, assetId) =>
    dispatch(assetsReportAllTimeForOne(hotelId, assetId)),
  getAssetsFromToDateAll: (hotelId, fromDate, toDate) =>
    dispatch(assetsReportAllTimeByDate(hotelId, fromDate, toDate)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SecurityReports);
