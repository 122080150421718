import React from "react";
import ToolTip from "../../../components/ToolTip";
import { useTranslation } from "react-i18next";

const TaxSelectionOption = ({ value, setValue, label, options }) => {
  const { t } = useTranslation();
  return (
    <>
      <div>
        <div className="flex justify-between">
          <label className="font-pjs text-sm font-semibold text-[#344054] capitalize">
            {label}
            <span className="text-red-600 text-xl leading-3 ml-1">*</span>
          </label>
          <ToolTip Message={t("432")} />
        </div>
        <div className="relative flex items-center">
          <select
            className="border border-[#D0D5DD] w-full h-12 text-sm py-2 px-3 rounded-md focus:border-base-primary"
            onChange={(e) => setValue(e.target.value)}
            value={value}
          >
            {Object.keys(options)?.map((op, index) => (
              <option value={op} key={index}>
                {options[op]}
              </option>
            ))}
          </select>
        </div>
        {/* <InputError error={valueError} /> */}
      </div>
    </>
  );
};

export default TaxSelectionOption;
