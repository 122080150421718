import React, { useState } from "react";
import Seperator from "../../components/layouts/Seperator";
import { BiArrowBack } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { ROUTES } from "../../constants";

import { useNavigate } from "react-router-dom";
import CustomeTable from "../../components/CustomeTable";
import UploadButton from "../../components/buttons/UploadButton";
import DownloadButton from "../../components/buttons/DownloadButton";

function VendorHistory() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = useState([]);

  const track = {
    heading: t("History - Amul"),
    seperator: [
      {
        text: t("134"),
      },
      {
        link: ROUTES.VENDORS,
        text: t("233"),
      },
      { text: t("History") },
    ],
  };
  const tableData = [
    {
      date: "2023-11-06",
      vendorType: "Food & Baverage",
      invoiceNumber: "INV-1234",
      items: ["Salt", "Eggs", "Chicken"],
      quantity: ["50kg", "20dz", "100lbs"],
      price: ["$600", "$800", "$600"],
      totalAmount: "$2000",
      paymentStatus: "Paid",
      receivedStatus: "Received",
    },
    {
      date: "2023-11-06",
      vendorType: "Equipments",
      invoiceNumber: "INV-1234",
      items: ["Salt", "Eggs", "Chicken"],
      quantity: ["50kg", "20dz", "100lbs"],
      price: ["$600", "$800", "$600"],
      totalAmount: "$2000",
      paymentStatus: "Pending",
      receivedStatus: "Pending",
    },
  ];

  const checkPaymentStatus = ({ value }) => {
    return (
      <>
        <div className="mt-2">
          {value === "Paid" && (
            <>
              <div className="flex justify-center  items-center w-[67px] h-[26px] bg-[#ECFDF3] rounded-[5px] text-[13px] leading-[12px] font-normal text-base-primary">
                {value}
              </div>
            </>
          )}
          {value === "Pending" && (
            <>
              <div className="flex justify-center items-center w-[67px] h-[26px] bg-[#FEF3F2] rounded-[5px] text-[13px] leading-[12px] font-normal text-[#F04438]">
                {value}
              </div>
            </>
          )}
        </div>
      </>
    );
  };

  const checkPaymentReceivedStatus = ({ value }) => {
    return (
      <>
        <div className="mt-2">
          {value === "Received" && (
            <>
              <div className="flex justify-center  items-center w-[67px] h-[26px] bg-[#ECFDF3] rounded-[5px] text-[13px] leading-[12px] font-normal text-base-primary">
                {value}
              </div>
            </>
          )}
          {value === "Pending" && (
            <>
              <div className="flex justify-center items-center w-[67px] h-[26px] bg-[#FEF3F2] rounded-[5px] text-[13px] leading-[12px] font-normal text-[#F04438]">
                {value}
              </div>
            </>
          )}
        </div>
      </>
    );
  };

  const columnDefs = [
    {
      headerName: "Date",
      field: "date",
      maxWidth: 170,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Vendor Type",
      field: "vendorType",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Invoice Number",
      field: "invoiceNumber",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Items",
      field: "items",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Quantity",
      field: "quantity",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Price",
      field: "price",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Total Amount",
      field: "totalAmount",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Payment Status",
      field: "paymentStatus",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
      cellRenderer: checkPaymentStatus,
    },
    {
      headerName: "Received Status",
      field: "receivedStatus",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
      cellRenderer: checkPaymentReceivedStatus,
    },
  ];

  const backToVendors = () => {
    navigate(ROUTES.VENDORS);
  };
  return (
    <>
      <div className="page-container">
        <div className="w-full max-w-7xl mx-auto">
          <div className="flex justify-between items-end">
            <Seperator data={track} />
            <div className="flex gap-2">
              <button
                className="h-10 w-[89px] flex justify-center gap-2 items-center border border-[#DDE2E4] rounded-md bg-white"
                onClick={backToVendors}
              >
                <BiArrowBack size={15} />
                <div className="text-[14px] leading-6 font-normal tracking-[-0.6%]">
                  {t("Back")}
                </div>
              </button>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between my-4">
          <div className="text-[22px] leading-[27.72px] text-black font-bold">
            History Table
          </div>
          <div className="flex items-center gap-4">
            <UploadButton />
            <DownloadButton />
          </div>
        </div>
        <div className="">
          <CustomeTable
            columnDefs={columnDefs}
            rowData={tableData}
            setSelectedRows={setSelectedRows}
          />
        </div>
      </div>
    </>
  );
}

export default VendorHistory;