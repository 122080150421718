import React from "react";
import { BiMenu } from "react-icons/bi";
import { registerEvent } from "../../logs";
import { useSelector, useDispatch } from "react-redux";
import { CHANGE_HAMBURGER } from "../../actions/types";

const HamBurgerBtn = () => {
  const dispatch = useDispatch();
  const { hamburger } = useSelector((state) => state.Hamburger);

  //hamburger action
  const hamburgerAction = () => {
    registerEvent(`hamburger ${hamburger ? "close" : "open"}`, "HAMBURGER");
    dispatch({ type: CHANGE_HAMBURGER, payload: !hamburger });
  };

  return (
    <button
      type="button"
      className="rounded text-4xl"
      onClick={hamburgerAction}
    >
      <BiMenu />
    </button>
  );
};

export default HamBurgerBtn;
