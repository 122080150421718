import React from "react";
import { useNavigate } from "react-router-dom";

const SearchQuery = ({ data, setQuery }) => {
  const navigate = useNavigate();
  const gotoDirect = () => {
    setQuery(data?.query);
    navigate(data?.path);
  };
  return (
    <button
      key={data?.query}
      onClick={gotoDirect}
      className="px-4 py-2 hover:bg-green-100 w-full text-left"
    >
      {data?.query}
    </button>
  );
};

export default SearchQuery;
