import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import MetaData from "../../utils/MetaData";
import {
  authenticateHotelOwner,
  hotelOwnerLoginAction,
} from "../../actions/pms/ownerAuth";
import LoadSpinner from "../../components/loader/LoadSpinner";
import { notifyError } from "../../components/alert";
import { clearError } from "../../actions/clearError";
import {
  ISADMIN,
  TOKEN,
  ROUTES,
} from "../../constants";
import { connect } from "react-redux";
import RefreshLoader from "../../components/loader/RefreshLoader";
import ContinueWithGoogle from "../../components/layouts/ContinueWithGoogle";
import AuthEmailInput from "./AuthEmailInput";
import AuthPasswordInput from "./AuthPasswordInput";
import { isAdminAction } from "../../actions/pms/isadmin";
import { useTranslation } from "react-i18next";

const LoginPage = (props) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  //validation error
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const { t } = useTranslation();
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!emailError && !passwordError && email && password) {
      const formData = {
        email,
        password,
      };
      props.login(formData);
      localStorage.clear();
      sessionStorage.clear();
    }
  };

  //login user
  useEffect(() => {
    if (
      props?.loginRes &&
      props?.loginRes?.token &&
      props?.loginRes?.token?.length
    ) {
      localStorage.setItem(ISADMIN, true);
      localStorage.setItem(TOKEN, props?.loginRes?.token);
      props.setAdmin(true);
      props.getOwner(); //get owner
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.loginRes]);

  //user profile getted
  useEffect(() => {
    if (props?.user && props?.user?.owner && props?.user?.owner?._id) {
      navigate(ROUTES.DASHBOARD);
    }
    if (props?.user && props?.user?.error) {
      props.clear();
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props?.user]
  );

  // clear errors
  useEffect(() => {
    if (props?.loginRes && props?.loginRes?.error) {
      if (
        props?.loginRes?.error === "Please Verify Your Email To Proceed Further"
      ) {
        //if user is singedup and not verified account than according to error redirect to verify email page!
        localStorage.setItem(TOKEN, email);
        navigate(ROUTES.VERIFY_EMAIL_OTP);
      } else {
        notifyError(props?.loginRes?.error);
      }
      props.clear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.loginRes]);

  return (
    <>
      <MetaData title={t("220")} />
      {props?.user && props?.user?.loading ? (
        <RefreshLoader />
      ) : (
        <div className="w-full min-h-screen p-4 relative flex flex-col justify-center">
          <div className="w-[450px] mx-auto max-w-full rounded-lg flex flex-col border bg-[#FFFFFF]">
            <div className="w-full pt-6">
              <h1 className="text-3xl font-medium text-center text-base-primary">
                {t("220")}
              </h1>
            </div>
            <form
              className="w-full p-8 grid grid-cols-1 gap-4"
              onSubmit={handleSubmit}
            >
              <AuthEmailInput
                email={email}
                setEmail={setEmail}
                setEmailError={setEmailError}
                emailError={emailError}
              />
              <AuthPasswordInput
                password={password}
                setPassword={setPassword}
                setPasswordError={setPasswordError}
                passwordError={passwordError}
              />

              <button
                className="inline-block h-12 w-full bg-base-primary text-[#FFFFFF] rounded-3xl font-medium disabled:bg-base-rgba_primary_8"
                disabled={
                  (props?.loginRes && props?.loginRes?.loading) ||
                  emailError ||
                  passwordError ||
                  !email ||
                  !password
                }
              >
                {props?.loginRes && props?.loginRes?.loading ? (
                  <LoadSpinner text={t("798")} />
                ) : (
                  <>{t("220")}</>
                )}
              </button>
            </form>
            <div className="w-full text-center">
              <Link
                to={ROUTES.PASSWORD_FORGOT}
                className="text-blue-400 font-sans hover:underline"
              >
                {t("799")}
              </Link>
            </div>
            <div className="w-full flex flex-col items-center relative p-8 py-4">
              <div className="flex w-full items-center">
                <span className="border-b flex border-[#D0D5DD] w-full"></span>
                <p className="whitespace-nowrap mx-2">{t("800")}</p>
                <span className="border-b flex border-[#D0D5DD] w-full"></span>
              </div>
              <div className="flex w-full justify-center items-center flex-col">
                <div className="m-2">
                  <ContinueWithGoogle />
                </div>
              </div>
            </div>
            <div className="w-full text-center pb-6">
              {t("801")}
              <Link
                to={ROUTES.REGISTER}
                className="text-blue-400 ml-2 hover:underline"
              >
                {t("802")}
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.Authenticate,
  loginRes: state.LoginSignup,
});

const mapDispatchToProps = (dispatch) => ({
  login: (data) => dispatch(hotelOwnerLoginAction(data)),
  clear: () => dispatch(clearError()),
  getOwner: () => dispatch(authenticateHotelOwner()),
  setAdmin: (value) => dispatch(isAdminAction(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
