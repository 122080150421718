import React from "react";

const Sidebar = ({ children }) => {
  return (
    <aside className="w-[400px] h-[100vh] border-r bg-[#00a35e1a] overflow-y-auto dropdown-scroll">
      <div className="h-[301px] flex items-center justify-center radialGradient">
        <h2 className="w-[256px] text-[36px] leading-[54px] font-semibold text-white">
          Kunggy learn tutorials like how to use
        </h2>
      </div>
      <nav className="">
        <ul className="">{children}</ul>
      </nav>
    </aside>
  );
};

export default Sidebar;
