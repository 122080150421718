import React, { useState } from "react";
import { notifyError, notifySuccess } from "../../components/alert";
import SaveOrNot from "../../components/layouts/SaveOrNot";
import { connect } from "react-redux";
import { ONBOARDING_STAGES } from "../../constants";
import { registerEvent } from "../../logs";
import { updateHotelAction } from "../../actions/pms/hotel";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Inputfield from "../../components/layouts";
import SelectFromObject from "../../components/layouts/SelectFromObject";
import { UPDATE_HOTEL_RESET } from "../../actions/types/hotel";
import CopyButton from "../../components/buttons/CopyButton";
import { RxCross2 } from "react-icons/rx";
import AddSubmitButton from "../../components/buttons/AddSubmitButton";
// import copy from "./copy.svg"

const BookingSourceAdd = (props) => {
  const { t } = useTranslation();
  const [source, setSource] = useState("");
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(false);
  const [generatedEmail, setGeneratedEmail] = useState("");
  const [accept, setAccept] = useState(false);

  // open modal for confimation of booking source add or not
  const handleClickOpen = (e) => {
    e.preventDefault();
    const data = { source, email };
    setOpen(true);
    registerEvent("BOOKING SOURCE ADD", "booking source submit button", {
      data,
    });
  };

  // sumbit form confirmation
  const handleSubmit = async () => {
    let data = { stage: ONBOARDING_STAGES.DASHBOARD };
    if (
      props?.hotel &&
      props?.hotel?.bookingSource &&
      props?.hotel?.bookingSource?.length
    ) {
      data = {
        hotelId: props?.hotel?._id,
        bookingSource: [...props?.hotel?.bookingSource, { source, email }],
      };
    } else {
      data = {
        hotelId: props?.hotel?._id,
        bookingSource: [{ source, email }],
      };
    }
    if (!source || !email) {
      notifyError(t("756"));
      setOpen(false);
      return;
    } else {
      setOpen(false);
      await props.addBookingSource(data);
    }
  };

  //close booking source add modal / not adding booking source/ confirmation cancel
  const closeModal = () => {
    props.setOpen(false);
    registerEvent("CLOSE MODAL", "booking source add component");
  };

  useEffect(() => {
    if (props?.data && props?.data?.isupdated) {
      notifySuccess(`${t("757")}, ${email}`);
      props?.reset();
    }
  }, [props?.data]);

  return (
    <>
      <SaveOrNot
        formSubmit={handleSubmit}
        isopen={open}
        close={() => setOpen(false)}
      />
      <div className="w-full mx-auto bg-white border border-[#D0D5DD] rounded-[10px] my-4 pt-6 pb-8 px-6">
        <div className="flex items-center justify-between">
          <div className="font-bold text-[30px] text-center leading-[37.8px] text-black tracking-[-1.5%]">
            {t("413")}
          </div>
          <button type="button" onClick={closeModal}>
            <RxCross2
              size={26}
              className="hover:rotate-90 duration-500 hover:text-[#F04438]"
            />
          </button>
        </div>
        <form
          className="w-full text-[14px] leading-6 font-normal mt-8"
          onSubmit={handleClickOpen}
        >
          <div className="relative grid grid-cols-2 items-center gap-6 ">
            <SelectFromObject
              label={t("160")}
              message={t("415")}
              options={{}}
              value={source}
              setValue={setSource}
            />
            <Inputfield
              label={t("416")}
              message={t("417")}
              placeholder={t("Email Address provided to booking source (OTA)")}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required={true}
            />
            {/* copyIcon */}
            <button className="absolute right-4 top-8 rounded-lg cursor-copy">
              <CopyButton />
            </button>
            <div className="">
              <Inputfield
                label={t("758")}
                message={t("759")}
                placeholder={t("760")}
                value={generatedEmail}
                readOnly={true}
              />
            </div>
            <div className="text-[#000000] underline">
              <Link to={"#"} className="hover:text-base-primary">
                {t("421")}
              </Link>
              <br />
              <Link to={"#"} className="hover:text-base-primary">
                {t("422")}
              </Link>
              <br />
              <Link to={"#"} className="hover:text-base-primary">
                {t("423")}
              </Link>
            </div>
          </div>

          <div className="relative w-full">
            <div className="flex items-start gap-2 my-4">
              <input
                type="checkbox"
                className="h-4 w-4 border border-[#F9F9F9] rounded-[4px]  accent-[#00A35E] mt-1"
                onChange={() => setAccept(!accept)}
                value={accept}
                required={true}
              />
              <span className="w-3/5">
                {t(
                  "I have enabled forwarding and all my booking emails of booking.com will be forwarded to generated mail"
                )}
              </span>
            </div>
          </div>
          <div className="flex items-center gap-6">
            <button
              className="h-10 w-[204px] text-white font-semibold text-sm bg-base-primary hover:bg-base-primary_hover rounded-md"
              type="button"
            >
              {t("425")}
            </button>
            <button
              className="text-base-primary hover:text-base-primary_hover underline"
              type="button"
            >
              {t("426")}
            </button>
          </div>
          <div className="mt-4">
            <AddSubmitButton
              h="h-10"
              width={204}
              text={t("413")}
              disabled={props?.data && props?.data?.loading}
            />
          </div>
        </form>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  data: state.UpdateHotel,
});

const mapDispatchToProps = (dispatch) => ({
  addBookingSource: (data) => dispatch(updateHotelAction(data)),
  reset: () => dispatch({ type: UPDATE_HOTEL_RESET }),
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingSourceAdd);
