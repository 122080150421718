/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import MetaData from "../../utils/MetaData";
import LoadSpinner from "../../components/loader/LoadSpinner";
import { clearError } from "../../actions/clearError";
import { verifyOnwerEmailAction } from "../../actions/pms/verifyEmail";
import { connect } from "react-redux";
import { useEffect } from "react";
import { notifyError, notifySuccess } from "../../components/alert";
import { TOKEN, ROUTES } from "../../constants";
import { authenticateHotelOwner } from "../../actions/pms/ownerAuth";
import BackGroundLoadSpinner from "../../components/loader/BackGroundLoadSpinner";
import { useTranslation } from "react-i18next";

const VerifyEmailOTP = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  //get email from localStorage
  const getEmailFromLocalStorage = async () => {
    const data = localStorage.getItem(TOKEN);
    if (data && data?.length) {
      setEmail(data);
    } else {
      navigate(ROUTES.LOGIN);
    }
  };
  useEffect(() => {
    getEmailFromLocalStorage();
  }, []);

  //submit otp
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!otp || otp?.length !== 6) {
      notifyError(t("989"));
      return;
    }
    props.verifyOTP({ otp, email });
  };

  //success
  const gosuccess = async (token) => {
    notifySuccess(t("990"));
    localStorage.setItem(TOKEN, token); //set token in local storage
    //get owner authenticated
    props.getOwner();
  };
  useEffect(() => {
    if (props?.token && props?.token?.token) {
      gosuccess(props?.token?.token);
    }
  }, [props?.token]);

  //redirect owner after authenticated
  useEffect(() => {
    if (props?.auth && props?.auth?.owner && props?.auth?.owner?._id) {
      navigate(ROUTES.ONBOARDING);
    }
  }, [props?.auth]);

  //error
  useEffect(() => {
    if (props?.token && props?.token?.error) {
      notifyError(props?.token?.error);
      props?.clear();
    }
    if (props?.auth && props?.auth?.error) {
      notifyError(props?.auth?.error);
      props.clear();
    }
  }, [props?.token, props?.auth]);

  return (
    <>
      <MetaData title={t("Verify Email")} />
      <div className="w-full bg-[#fcf5ec] min-h-screen p-4 relative">
        <div className="w-[450px] mx-auto max-w-full rounded flex flex-col shadow-md my-8 bg-[#FFFFFF]">
          <div className="w-full pt-6">
            <h1 className="text-3xl  font-medium text-center text-base-primary capitalize">
              {t("961")}
            </h1>
            <p className="text-[#616161] w-full text-center pt-2">
              {t("962")}
              <strong>{email}</strong>
            </p>
          </div>
          <form className="w-full p-8" onSubmit={handleSubmit}>
            <div className="w-full mb-6 relative sm:mb-8 flex justify-between">
              <input
                type="text"
                name="otp"
                value={otp}
                maxLength={6}
                minLength={6}
                onChange={(e) => setOtp(e.target.value)}
                className="w-full tracking-[1rem] sm:tracking-[2rem] text-center outline-none border border-gray-300 py-3 focus:border-black rounded-3xl"
              />
            </div>
            <button
              className="inline-block h-12 w-full bg-base-primary text-[#FFFFFF] rounded-3xl font-semibold"
              disabled={props?.token && props?.token?.loading}
            >
              {props?.token && props?.token?.loading ? (
                <LoadSpinner />
              ) : (
                <>{t("963")}</>
              )}
            </button>
          </form>
        </div>
      </div>
      {props?.auth && props?.auth?.loading ? <BackGroundLoadSpinner /> : <></>}
    </>
  );
};

const mapStateToProps = (state) => ({
  token: state.VerifyProfile,
  auth: state.Authenticate,
});

const mapDispatchToProps = (dispatch) => ({
  verifyOTP: (data) => dispatch(verifyOnwerEmailAction(data)),
  clear: () => dispatch(clearError()),
  getOwner: () => dispatch(authenticateHotelOwner()),
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmailOTP);
