import React, { useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

const RomTypeCostTable = ({rowData,columnDefs}) => {
    const defaultColDef = useMemo(() => {
        return {
          flex: 1,
          minWidth: 100,
          // allow every column to be aggregated
          enableValue: true,
          // allow every column to be grouped
          enableRowGroup: true,
          // allow every column to be pivoted
          enablePivot: true,
          filter: true,
        };
      }, []);
  return (
    <div className="ag-theme-quartz" style={{ height:140}}>
    <AgGridReact
      rowData={rowData}
      columnDefs={columnDefs}
      pagination={false}
      suppressRowClickSelection={true}
      allowDragFromColumnsToolPanel={true}
      defaultColDef={defaultColDef}
      sideBar={"columns"}
      suppressColumnsToolPanel={true}
    />
  </div>
  )
}

export default RomTypeCostTable