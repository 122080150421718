import axios from "axios";
import { TOKEN } from "../../constants";
import {
  ASSET_REPORT_FOR_ALL_BY_DATE_FAIL,
  ASSET_REPORT_FOR_ALL_BY_DATE_REQ,
  ASSET_REPORT_FOR_ALL_BY_DATE_SUC,
  ASSET_REPORT_FOR_ONE_BY_DATE_FAIL,
  ASSET_REPORT_FOR_ONE_BY_DATE_REQ,
  ASSET_REPORT_FOR_ONE_BY_DATE_SUC,
} from "../types/reportAssets";

//get assets report for all time by date
export const assetsReportAllTimeByDate =
  (hotelId, fromDate, toDate) => async (dispatch) => {
    if(!hotelId || !fromDate || !toDate) return;
    const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/reports/assets/by-date`;
    try {
      dispatch({ type: ASSET_REPORT_FOR_ALL_BY_DATE_REQ });
      const data = await axios.post(
        url,
        { hotelId, fromDate, toDate },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-auth-token": localStorage.getItem(TOKEN),
          },
          withCredentials: true,
        }
      );
      dispatch({
        type: ASSET_REPORT_FOR_ALL_BY_DATE_SUC,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: ASSET_REPORT_FOR_ALL_BY_DATE_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

//get assets report for all time for one asset by date
export const assetsReportAllTimeByDateForOne =
  (hotelId, fromDate, toDate, assetId) => async (dispatch) => {
    if(!hotelId || !fromDate || !toDate || !assetId) return;
    const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/reports/assets/by-date`;
    try {
      dispatch({ type: ASSET_REPORT_FOR_ONE_BY_DATE_REQ });
      const data = await axios.post(
        url,
        { hotelId, fromDate, toDate, assetId },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-auth-token": localStorage.getItem(TOKEN),
          },
          withCredentials: true,
        }
      );
      dispatch({
        type: ASSET_REPORT_FOR_ONE_BY_DATE_SUC,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: ASSET_REPORT_FOR_ONE_BY_DATE_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };
