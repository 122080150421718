import React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { clearError } from "../../actions/clearError";
import { addItemTofoodCartAction } from "../../actions/pms/foodMenuCart";
import { registerEvent } from "../../logs";
import { useTranslation } from "react-i18next";

const FoodMenuItemCard = (props) => {
  const { t } = useTranslation();
  const [count, setCount] = useState(1);

  //add to cart food product
  const addToSessionCart = () => {
    registerEvent("add item to food cart", "food menu", props.item);

    props.addToCart({ ...props.item, quantity: count });
    setCount(count + 1);
  };

  return (
    <>
      {props?.item && (
        //w-full max-w-full w-[262px]
        <div className="h-[240px] w-full max-w-full bg-white shadow-base overflow-hidden p-3 rounded-xl mx-auto">
          <div className="relative h-[140px] w-full max-w-full overflow-hidden rounded-md">
            <img
              src={props?.item?.images[props?.item?.images?.length - 1]?.url}
              alt=""
              className="h-full w-full object-cover hover:scale-110 duration-300 rounded-md"
            />
          </div>
          <div className="mt-1">
            {/* item name */}
            <div className="text-[16px] leading-[20.16px] text-[#000000] font-semibold capitalize">
              {props?.item?.name}
            </div>
            {/* item type */}
            <div className="text-[12px] leading-[15.12px] text-[#000000] font-normal">
              {/* {props?.item?.ItemType} */}
              Appetizer
            </div>
          </div>
          {/* item price */}
          <div className="flex justify-between p-1">
            <div className="text-[20px] leading-[25.2px] text-[#000000] font-semibold">
              ₹{props?.item?.price}
            </div>
            <button
              className="w-fit h-[31px] text-center text-[12.13px] leading-[15.29px] px-2 text-[#FFFFFF] font-semibold select-none cursor-pointer rounded-md bg-base-primary hover:bg-base-primary_hover"
              onClick={() => addToSessionCart()}
            >
              {t("254")}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  cart: state.FoodCart,
});

const mapDispatchToProps = (dispatch) => ({
  addToCart: (data) => dispatch(addItemTofoodCartAction(data)),
  clear: () => dispatch(clearError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FoodMenuItemCard);
