import { CLEAR_ERRORS } from "../../actions/types";
import {
  RE_SEND_OTP_FAIL,
  RE_SEND_OTP_REQUEST,
  RE_SEND_OTP_RESET,
  RE_SEND_OTP_SUCCESS,
  SEND_OTP_FAIL,
  SEND_OTP_REQUEST,
  SEND_OTP_RESET,
  SEND_OTP_SUCCESS,
  VERIFY_OTP_FAIL,
  VERIFY_OTP_REQUEST,
  VERIFY_OTP_RESET,
  VERIFY_OTP_SUCCESS,
} from "../../actions/types/verification";

export const sendOTPReducer = (state = { otp_id: "" }, action) => {
  switch (action.type) {
    case SEND_OTP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SEND_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        otp_id: action.payload,
      };
    case SEND_OTP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        otp_id: null,
      };
    case SEND_OTP_RESET:
      return {
        ...state,
        loading: false,
        otp_id: null,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const reSendOTPReducer = (state = { otp_id: "" }, action) => {
  switch (action.type) {
    case RE_SEND_OTP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RE_SEND_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        otp_id: action.payload,
      };
    case RE_SEND_OTP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        otp_id: null,
      };

    case RE_SEND_OTP_RESET:
      return {
        ...state,
        loading: false,
        otp_id: null,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const verifyOTPReducer = (state = { token: "" }, action) => {
  switch (action.type) {
    case VERIFY_OTP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case VERIFY_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        token: action.payload,
      };
    case VERIFY_OTP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        token: null,
      };
    case VERIFY_OTP_RESET:
      return {
        ...state,
        loading: false,
        token: null,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
