import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
// import { useTranslation } from "react-i18next";
import {
  assetsReportAllTime,
  assetsReportAllTimeForOne,
} from "../../actions/reports/assetsReportAllTime";
import { assetsReportAllTimeByDate } from "../../actions/reports/assetsReportByDate";
import ReportsCard from "./ReportsCard";
import totalItemIcon from "../../images/icons/totalItemIcon.svg";
import higheValueItemIcon from "../../images/icons/higheValueItemIcon.svg";
import lowValueItemIcon from "../../images/icons/lowValueItemIcon.svg";
import totalValueItem from "../../images/icons/totalValueItem.svg";
import CustomeTable from "../../components/CustomeTable";
import DownloadButton from "../../components/buttons/DownloadButton";

const InventoryReports = (props) => {
  // const { t } = useTranslation();
  const [datas, setDatas] = useState([]);
  const { sales } = useSelector((state) => state.SalesReport);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    props.getAssetsFromToDateAll(
      props?.hotel?._id,
      props?.fromDate,
      props?.toDate
    );
  }, [props?.fromDate, props?.toDate]);

  useEffect(() => {
    if (sales && Array.isArray(sales) && sales?.length) {
      let expense = sales?.map((d) => {
        return {
          item: d?.item,
          totalQuantity: d?.totalQuantity,
          availableQuantity: d?.availableQuantity,
          costPerUnit: d?.costPerUnit,
          totalValue: d?.totalValue,
        };
      });
      setDatas(expense);
    }
  }, [sales]);
  const tableData = [
    {
      id:"IN001",
      item: "Towel",
      totalQuantity: "500",
      availableQuantity: "400",
      costPerUnit: "$10",
      totalValue: "$500",
    },
    {
      id:"IN002",
      item: "Pillow",
      totalQuantity: "500",
      availableQuantity: "400",
      costPerUnit: "$10",
      totalValue: "$500",
    },
    {
      id:"IN003",
      item: "Towel",
      totalQuantity: "500",
      availableQuantity: "400",
      costPerUnit: "$10",
      totalValue: "$500",
    },
    {
      id:"IN004",
      item: "Towel",
      totalQuantity: "500",
      availableQuantity: "400",
      costPerUnit: "$10",
      totalValue: "$500",
    },
    {
      id:"IN005",
      item: "Pillow",
      totalQuantity: "500",
      availableQuantity: "400",
      costPerUnit: "$10",
      totalValue: "$500",
    },
  ];

  const columnDefs = [
    {
      headerName: "Inventory ID",
      field: "id",
      maxWidth: 200,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Item",
      field: "item",
      maxWidth: 300,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Total Quantity",
      field: "totalQuantity",
      maxWidth: 300,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Available Quantity",
      field: "availableQuantity",
      maxWidth: 300,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Cost/Unit",
      field: "costPerUnit",
      maxWidth: 300,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Total Value",
      field: "totalValue",
      maxWidth: 300,
      filter: true,
      floatingFilter: true,
    },
  ];

  return (
    <>
      <div className="w-full mt-4">
        <div className="my-4 flex items-center gap-3">
          <ReportsCard
            utility={"w-[234px]"}
            icon={totalItemIcon}
            iconBgColor={"bg-[#F1FADF]"}
            heading={"Total item"}
            value={"1258"}
          />
          <ReportsCard
            utility={"w-[325px]"}
            icon={higheValueItemIcon}
            iconBgColor={"bg-[#E8EBFF]"}
            heading={"High Value Item"}
            value={"800"}
          />
          <ReportsCard
            utility={"w-[325px]"}
            icon={lowValueItemIcon}
            iconBgColor={"bg-[#FCEEDD]"}
            heading={"Low Value Item"}
            value={"425"}
          />
          <ReportsCard
            utility={"w-[262px]"}
            icon={totalValueItem}
            iconBgColor={"bg-[#FCEAEA]"}
            heading={"Total Value"}
            value={"425"}
          />
        </div>
        <div className="flex items-center justify-between my-4">
          <h2 className="text-[24px] leading-6 font-bold">Inventory Report</h2>
          <DownloadButton />
        </div>
        {/* {datas && datas?.length ? ( */}
        <CustomeTable
          columnDefs={columnDefs}
          rowData={tableData}
          setSelectedRows={setSelectedRows}
        />
        {/* ) : null} */}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  data: state.AssetsReport,
});

const mapDispatchToProps = (dispatch) => ({
  getAllTimeAsset: (hotelId) => dispatch(assetsReportAllTime(hotelId)),
  //get assets report by date
  getAssetsReportAll: (hotelId, assetId) =>
    dispatch(assetsReportAllTimeForOne(hotelId, assetId)),
  getAssetsFromToDateAll: (hotelId, fromDate, toDate) =>
    dispatch(assetsReportAllTimeByDate(hotelId, fromDate, toDate)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InventoryReports);
