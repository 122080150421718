export const CREATE_INVENTORY_REQUEST = "CREATE_INVENTORY_REQUEST";
export const CREATE_INVENTORY_SUCCESS = "CREATE_INVENTORY_SUCCESS";
export const CREATE_INVENTORY_FAIL = "CREATE_INVENTORY_FAIL";
export const CREATE_INVENTORY_RESET = "CREATE_INVENTORY_RESET";

export const GET_KITCHEN_INVENTORY_REQUEST = "GET_KITCHEN_INVENTORY_REQUEST";
export const GET_KITCHEN_INVENTORY_SUCCESS = "GET_KITCHEN_INVENTORY_SUCCESS";
export const GET_KITCHEN_INVENTORY_FAIL = "GET_KITCHEN_INVENTORY_FAIL";

export const UPDATE_INVENTORY_REQUEST = "UPDATE_INVENTORY_REQUEST";
export const UPDATE_INVENTORY_SUCCESS = "UPDATE_INVENTORY_SUCCESS";
export const UPDATE_INVENTORY_FAIL = "UPDATE_INVENTORY_FAIL";
export const UPDATE_INVENTORY_RESET = "UPDATE_INVENTORY_RESET";
