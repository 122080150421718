import { CLEAR_ERRORS,  } from "../../actions/types";

import {
  GET_EMPLOYEE_REQUEST,
  EMPLOYEE_LOGIN_REQUEST,
  EMPLOYEE_LOGIN_SUCCESS,
  GET_EMPLOYEE_SUCCESS,
  GET_EMPLOYEE_FAIL,
  EMPLOYEE_LOGIN_FAIL,
} from "../../actions/types/employee";

//hotel employee auth
export const employeeAuthReducer = (state = { employee: {} }, action) => {
  switch (action.type) {
    case GET_EMPLOYEE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: false,
        employee: action.payload,
      };
    case GET_EMPLOYEE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        employee: {},
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

//employee login reducer
export const employeeLoginReducer = (state = { token: "" }, action) => {
  switch (action.type) {
    case EMPLOYEE_LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case EMPLOYEE_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        token: action.payload,
      };
    case EMPLOYEE_LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }; 
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
