import axios from "axios";
import { ISADMIN, TOKEN } from "../../constants";

import {
  GET_BOOKINGS_FAIL,
  GET_BOOKINGS_REQUEST,
  GET_BOOKINGS_SUCCESS,
  BOOKING_BID_FAIL,
  BOOKING_BID_REQUEST,
  BOOKING_BID_SUCCESS,
  BOOKING_CNAME_FAIL,
  BOOKING_CNAME_REQUEST,
  BOOKING_CNAME_SUCCESS,
  BOOKING_PHONE_FAIL,
  BOOKING_PHONE_REQUEST,
  BOOKING_PHONE_SUCCESS,
  BOOKING_STATUS_FAIL,
  BOOKING_STATUS_REQUEST,
  BOOKING_STATUS_SUCCESS,
} from "../types/booking";

//get all booking
export const getAllBookingAction = (hotelId) => async (dispatch) => {
  const isadmin = localStorage.getItem(ISADMIN);

  let url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/employee/booking/all`;
  if (isadmin) {
    if (!hotelId) return;
    url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/employee/booking/all?hotelId=${hotelId}`;
  }

  try {
    dispatch({ type: GET_BOOKINGS_REQUEST });
    const { data } = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: GET_BOOKINGS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_BOOKINGS_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

//get booking by BOOKING STATUS
export const getBookingByStatusAction = (status) => async (dispatch) => {
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/employee/booking/findByStatus?status=${status}`;
  try {
    dispatch({ type: BOOKING_STATUS_REQUEST });
    const { data } = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: BOOKING_STATUS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BOOKING_STATUS_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

//get booking by BOOKING ID
export const getBookingByBookingIdAction = (bookingId) => async (dispatch) => {
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/employee/booking?bookingId=${bookingId}`;
  try {
    dispatch({ type: BOOKING_BID_REQUEST });
    const { data } = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: BOOKING_BID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BOOKING_BID_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

//get booking by PHONE NO
export const getBookingByPhoneAction = (phone) => async (dispatch) => {
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/employee/booking?phone=${phone}`;
  try {
    dispatch({ type: BOOKING_PHONE_REQUEST });
    const { data } = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: BOOKING_PHONE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BOOKING_PHONE_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

//get booking by CUSTOMER NAME
export const getBookingByCustomerNameAction = (name) => async (dispatch) => {
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/employee/booking?customerName=${name}`;
  try {
    dispatch({ type: BOOKING_CNAME_REQUEST });
    const { data } = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: BOOKING_CNAME_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BOOKING_CNAME_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};
