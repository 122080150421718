import { CLEAR_ERRORS } from "../../actions/types";
import {
  ANS_QUERY_FAIL,
  ANS_QUERY_REQUEST,
  ANS_QUERY_RESET,
  ANS_QUERY_SUCCESS,
  GET_QUERY_FAIL,
  GET_QUERY_REQUEST,
  GET_QUERY_SUCCESS,
} from "../../actions/types/guestSupport";

//ANSWARE GUEST QUERY
export const answereGuestQuery = (state = { answer: {} }, action) => {
  switch (action.type) {
    case ANS_QUERY_REQUEST:
      return {
        ...state,
        loading: true,
        isadded: false,
      };
    case ANS_QUERY_SUCCESS:
      return {
        ...state,
        loading: false,
        answer: action.payload,
        isadded: true,
      };
    case ANS_QUERY_FAIL:
      return {
        ...state,
        loading: false,
        isadded: false,
        error: action.payload,
      };
    case ANS_QUERY_RESET:
      return {
        ...state,
        loading: false,
        isadded: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

//get all queries
export const getAllQueryReducer = (state = { queries: [] }, action) => {
  switch (action.type) {
    case GET_QUERY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_QUERY_SUCCESS:
      return {
        ...state,
        loading: false,
        queries: action.payload,
      };
    case GET_QUERY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
