import {
  UPLOAD_IMAGE_FAIL,
  UPLOAD_IMAGE_REQUEST,
  UPLOAD_IMAGE_RESET,
  UPLOAD_IMAGE_SUCCESS,
  DELETE_IMAGE_REQUEST,
  DELETE_IMAGE_SUCCESS,
  DELETE_IMAGE_FAIL,
  DELETE_IMAGE_RESET,
  CLEAR_IMAGE,
} from "../../actions/types/ImageUpload";
import { CLEAR_ERRORS } from "../../actions/types";

//upload image
export const uploadImagesReducer = (state = { image: {} }, action) => {
  switch (action.type) {
    case UPLOAD_IMAGE_REQUEST:
      return {
        ...state,
        loading: true,
        isuploaded: false,
      };
    case UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        isuploaded: true,
        image: action.payload,
      };
    case UPLOAD_IMAGE_FAIL:
      return {
        ...state,
        loading: false,
        isuploaded: false,
        error: action.payload,
      };
    case UPLOAD_IMAGE_RESET:
      return {
        ...state,
        isuploaded: false,
        loading: false,
      };
    case CLEAR_IMAGE:
      return {
        ...state,
        isuploaded: false,
        image: null,
        loading: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

//delete image
export const deleteImagesReducer = (state = { image: {} }, action) => {
  switch (action.type) {
    case DELETE_IMAGE_REQUEST:
      return {
        ...state,
        loading: true,
        isdeleted: false,
      };
    case DELETE_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        isdeleted: true,
        image: action.payload,
      };
    case DELETE_IMAGE_FAIL:
      return {
        ...state,
        loading: false,
        isdeleted: false,
        error: action.payload,
      };
    case DELETE_IMAGE_RESET:
      return {
        ...state,
        isdeleted: false,
        loading: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};