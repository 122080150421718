/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import PageLoader from "../../../components/loader/PageLoader";
import { updateHotelAction } from "../../../actions/pms/hotel";
import { connect } from "react-redux";
import { notifySuccess } from "../../../components/alert";
import BackGroundLoadSpinner from "../../../components/loader/BackGroundLoadSpinner";
import { 
  COUNTRY_DETAILS,
  TIME_ZONES, 
  ROUTES,
  ONBOARDING_STAGES,
} from "../../../constants";
import { registerEvent } from "../../../logs";
import PropertyCurrencySelector from "./PropertyCurrencySelector";
// import UploadHotelLogo from "../../hotel/UploadHotelLogo";
// import UploadHotelMainPhoto from "../../hotel/UploadHotelMainPhoto";
// import UploadHotelPhotos from "../../hotel/UploadHotelPhotos";
import InputPropertyTimeZone from "./InputPropertyTimeZone";
import SelectFromArray from "../../../components/layouts/SelectFromArray";
import { useTranslation } from "react-i18next";
import Inputfield from "../../../components/layouts";
import { UPDATE_HOTEL_RESET } from "../../../actions/types/hotel";
import { useNavigate } from "react-router-dom";
import SkipButton from "../../../components/buttons/SkipButton";
import AddSubmitButton from "../../../components/buttons/AddSubmitButton";

const PropertyDetails = (props) => {
  const { t } = useTranslation();
  const [propertyName, setPropertyName] = useState("");
  const [timeZoneOffset, setTimeZoneOffset] = useState(TIME_ZONES[0]?.offset);
  const [propertyType, setPropertyType] = useState('');
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [tagline, setTagline] = useState("");
  const [taxIdNumber, setTaxIdNumber] = useState("");
  const [HsnCode, setHsnCode] = useState("");
  const [coperateIdNumber, setCoperateIdNumber] = useState("");
  const [currency, setCurrency] = useState(COUNTRY_DETAILS[0]?.currency.code);
  const [checkInTime, setCheckInTime] = useState("");
  const [checkOutTime, setCheckOutTime] = useState("");
  const navigate = useNavigate();
  const [clicked, setClicked] = useState(false);
  const [isFullData, setIsFullData] = useState(false);

  //set property details from local / hotel data
  useEffect(() => {
    if (props?.hotel && props?.hotel?._id) {
      setPropertyName(props?.hotel?.propertyName);
      setPropertyType(props?.hotel?.propertyType);
      setTimeZoneOffset(props?.hotel?.timeZoneOffset);
      setEmail(props?.hotel?.propertyContact?.email);
      setWebsite(props?.hotel?.website);
      setTaxIdNumber(props?.hotel?.taxIdNumber);
      setHsnCode(props?.hotel?.HsnCode);
      setCoperateIdNumber(props?.hotel?.coperateIdNumber);
      setCurrency(props?.hotel?.currency);
      setCheckInTime(props?.hotel?.checkInTime);
      setCheckOutTime(props?.hotel?.checkOutTime);
      setTagline(props?.hotel?.tagline);
      setIsFullData(true);
    }
  }, [props?.hotel]);

  //submit the form
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      hotelId: props?.hotel?._id,
      propertyName,
      propertyType,
      email,
      website,
      taxIdNumber: taxIdNumber ? taxIdNumber : null,
      HsnCode: HsnCode ? HsnCode : null,
      coperateIdNumber: coperateIdNumber ? coperateIdNumber : null,
      currency: currency ? currency : null,
      tagline: tagline ? tagline : null,
      timeZoneOffset,
      checkInTime,
      checkOutTime,
      stage: ONBOARDING_STAGES.DASHBOARD,
    };

    setClicked(true);
    await props.updateHotel(formData);
    registerEvent("UPDATE PROPERTY DETAILS", "PROPERTY DETAILS", formData);
  };

  //show updatation alears
  useEffect(() => {
    if (
      props?.data &&
      props?.data?.isupdated &&
      props?.data?.hotel &&
      clicked
    ) {
      props?.reset();
      notifySuccess(t("688"));
      setClicked(false);
      navigate(`${ROUTES.HOTEL_CONFIG}/2`);
    }
  }, [props?.data]);

  //clear error
  useEffect(() => {
    if (props?.data && props?.data?.error && clicked) {
      setClicked(false);
    }
  }, [props.data]);

  const skipTheTab = () => {
    navigate(`${ROUTES.HOTEL_CONFIG}/2`);
  };

  return (
    <>
      {props?.data && props?.data?.loading && clicked ? (
        <BackGroundLoadSpinner />
      ) : null}

      {isFullData ? (
        <>
          <div className="w-full max-w-full border border-[#E4E4E7] bg-white rounded-lg p-6">
            <h2 className="font-bold text-[30px] leading-[37.8px] text-[#07090D] tracking-[-1.5%]">
              {t("360")}
            </h2>
            <form onSubmit={handleSubmit}>
              <div className="grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-3 my-4">
                <Inputfield
                  label={t("689")}
                  message={t("690")}
                  value={propertyName}
                  onChange={(e) => setPropertyName(e.target.value)}
                  required={true}
                  minLength={3}
                  maxLength={40}
                />
                <SelectFromArray
                  value={propertyType}
                  setValue={setPropertyType}
                  options={['']}
                  label={`${t("363")} *`}
                  toolTipMessage={t("364")}
                  required={true}
                />
                <InputPropertyTimeZone
                  value={timeZoneOffset}
                  setValue={setTimeZoneOffset}
                  options={TIME_ZONES}
                  required={true}
                />
                <Inputfield
                  label={t("691")}
                  message={t("692")}
                  value={email}
                  onChange={(e) => setEmail(e?.target?.value)}
                  type="email"
                  placeholder={"Enter Your Property Email"}
                />
                <Inputfield
                  label={t("693")}
                  message={t("694")}
                  value={website}
                  placeholder={"Enter a Value"}
                  onChange={(e) => setWebsite(e?.target.value)}
                  type="ulr"
                />
                <Inputfield
                  label={t("695")}
                  message={t("696")}
                  value={taxIdNumber}
                  placeholder={"Enter a Value"}
                  onChange={(e) => setTaxIdNumber(e?.target?.value)}
                />
                <Inputfield
                  label={t("697")}
                  message={t("698")}
                  placeholder={"Enter a Value"}
                  value={HsnCode}
                  onChange={(e) => setHsnCode(e?.target?.value)}
                />
                <Inputfield
                  label={t("699")}
                  message={t("700")}
                  placeholder={"Enter a Value"}
                  value={coperateIdNumber}
                  onChange={(e) => setCoperateIdNumber(e?.target?.value)}
                />
                <PropertyCurrencySelector
                  label={`${t("377")} *`}
                  value={currency}
                  onChange={(e) => setCurrency(e?.target?.value)}
                  options={COUNTRY_DETAILS}
                />
                <Inputfield
                  label={t("701")}
                  message={t("702")}
                  value={checkInTime}
                  onChange={(e) => setCheckInTime(e?.target?.value)}
                  type="time"
                  required={true}
                />
                <Inputfield
                  label={t("703")}
                  message={t("704")}
                  value={checkOutTime}
                  onChange={(e) => setCheckOutTime(e?.target?.value)}
                  type="time"
                  required={true}
                />
              </div>
              {/* <div>
                    <span className="font-bold text-xl sm:text-2xl md:text-3xl leading-[38px]">
                      {t("705")}
                    </span>
                    <div className="flex items-start gap-8 mt-2">
                      <div className="w-[168px] h-[168px]">
                        <UploadHotelLogo />
                      </div>
                      <div className="w-[247px] h-[168px]">
                        <UploadHotelMainPhoto />
                      </div>
                    </div>
                    <div className="w-[168px] h-[168px] mt-6">
                      <UploadHotelPhotos />
                    </div>
                    
                  </div> */}
              <div className="flex justify-end items-center gap-3 mb-6 mt-8">
                <SkipButton text={t("260")} onClick={skipTheTab} />
                <AddSubmitButton
                  text={t("65")}
                  width={88}
                  disabled={props?.data && props?.data?.loading}
                />
              </div>
            </form>
          </div>
        </>
      ) : (
        <PageLoader />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  data: state.UpdateHotel,
});

const mapDispatchToProps = (dispatch) => ({
  updateHotel: (data) => dispatch(updateHotelAction(data)),
  reset: () => dispatch({ type: UPDATE_HOTEL_RESET }),
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertyDetails);
