//check is object empty
export const isObjEmpty = (value) => {
  if (typeof value !== "object" || value === null) return true;
  return Object.keys(value).length === 0;
};

//check type of value is string and there is a string
export const isString = (value) => {
  if (typeof value !== "string") return false;
  return value.length > 0;
};
