import React from "react";
import Seperator from "../../components/layouts/Seperator";
import AddButton from "../../components/buttons/AddButton";
import { useTranslation } from "react-i18next";
// import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants";
import Ingredients from "./Ingredients";
import DeleteButton from "../../components/buttons/DeleteButton";
import EditButton from "../../components/buttons/EditButton";
import IngredientsFor from "./IngredientsFor";

const MapIngredients = (props) => {
  const { t } = useTranslation();
  // const navigate = useNavigate();

  //set kitchen data and redirect to another page
  // const setKitchenAndRedirect = (link) => {
  //   if (link) {
  //     navigate(link);
  //   }
  // };

  const data = {
    heading: t("1091"),
    seperator: [
      {
        text: t("134"),
      },
      {
        link: ROUTES.KITCHENS,
        text: t("36"),
      },
      {
        link: ROUTES.KITCHEN_MENU,
        text: t("Menu"),
      },
      {
        text: t("1085"),
      },
    ],
  };
  return (
    <>
      <div className="page-container max-w-7xl">
        <div className="w-full max-w-full">
          <div className="flex justify-between items-end">
            <Seperator data={data} />
            <div className="flex items-center gap-2">
              <EditButton />
              <DeleteButton />
              <AddButton text={t("Add To Ingredient")} />
              {/* onClick={() => setKitchenAndRedirect(PATH_KITCHEN_INVENTORY)} */}
            </div>
          </div>
        </div>
        {/* data table for map ingredients*/}
        <div className="my-6">
          <Ingredients />
        </div>
        {/* Ingredients for Chicken Wings */}
        <div className="text-[20px] leading-6 font-semibold my-4">
          {t("1093")}
        </div>
        <IngredientsFor />
      </div>
    </>
  );
};

export default MapIngredients;
