import { CLEAR_ERRORS } from "../../actions/types";
import {
  ASSET_REPORT_FOR_ALL_BY_DATE_FAIL,
  ASSET_REPORT_FOR_ALL_BY_DATE_REQ,
  ASSET_REPORT_FOR_ALL_BY_DATE_SUC,
  ASSET_REPORT_FOR_ALL_BY_MONTH_FAIL,
  ASSET_REPORT_FOR_ALL_BY_MONTH_REQ,
  ASSET_REPORT_FOR_ALL_BY_MONTH_SUC,
  ASSET_REPORT_FOR_ALL_BY_YEAR_FAIL,
  ASSET_REPORT_FOR_ALL_BY_YEAR_REQ,
  ASSET_REPORT_FOR_ALL_BY_YEAR_SUC,
  ASSET_REPORT_FOR_ALL_FAIL,
  ASSET_REPORT_FOR_ALL_REQ,
  ASSET_REPORT_FOR_ALL_SUC,
  ASSET_REPORT_FOR_ONE_BY_DATE_FAIL,
  ASSET_REPORT_FOR_ONE_BY_DATE_REQ,
  ASSET_REPORT_FOR_ONE_BY_DATE_SUC,
  ASSET_REPORT_FOR_ONE_BY_MONTH_FAIL,
  ASSET_REPORT_FOR_ONE_BY_MONTH_REQ,
  ASSET_REPORT_FOR_ONE_BY_MONTH_SUC,
  ASSET_REPORT_FOR_ONE_BY_YEAR_FAIL,
  ASSET_REPORT_FOR_ONE_BY_YEAR_REQ,
  ASSET_REPORT_FOR_ONE_BY_YEAR_SUC,
  ASSET_REPORT_FOR_ONE_FAIL,
  ASSET_REPORT_FOR_ONE_REQ,
  ASSET_REPORT_FOR_ONE_SUC,
} from "../../actions/types/reportAssets";

// get assets report for all time
export const assetsReportReducer = (state = { assetsreport: [] }, action) => {
  switch (action.type) {
    case ASSET_REPORT_FOR_ALL_REQ:
    case ASSET_REPORT_FOR_ONE_REQ:
    case ASSET_REPORT_FOR_ALL_BY_DATE_REQ:
    case ASSET_REPORT_FOR_ONE_BY_DATE_REQ:
    case ASSET_REPORT_FOR_ALL_BY_MONTH_REQ:
    case ASSET_REPORT_FOR_ONE_BY_MONTH_REQ:
    case ASSET_REPORT_FOR_ALL_BY_YEAR_REQ:
    case ASSET_REPORT_FOR_ONE_BY_YEAR_REQ:
      return {
        ...state,
        loading: true,
      };
    case ASSET_REPORT_FOR_ALL_SUC:
    case ASSET_REPORT_FOR_ONE_SUC:
    case ASSET_REPORT_FOR_ALL_BY_DATE_SUC:
    case ASSET_REPORT_FOR_ONE_BY_DATE_SUC:
    case ASSET_REPORT_FOR_ALL_BY_MONTH_SUC:
    case ASSET_REPORT_FOR_ONE_BY_MONTH_SUC:
    case ASSET_REPORT_FOR_ALL_BY_YEAR_SUC:
    case ASSET_REPORT_FOR_ONE_BY_YEAR_SUC:
      return {
        ...state,
        loading: false,
        assetsreport: action.payload,
      };
    case ASSET_REPORT_FOR_ALL_FAIL:
    case ASSET_REPORT_FOR_ONE_FAIL:
    case ASSET_REPORT_FOR_ALL_BY_DATE_FAIL:
    case ASSET_REPORT_FOR_ONE_BY_DATE_FAIL:
    case ASSET_REPORT_FOR_ALL_BY_MONTH_FAIL:
    case ASSET_REPORT_FOR_ONE_BY_MONTH_FAIL:
    case ASSET_REPORT_FOR_ALL_BY_YEAR_FAIL:
    case ASSET_REPORT_FOR_ONE_BY_YEAR_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        assetsreport: null,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
