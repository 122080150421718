import React, { useState } from "react";
import SaveOrNot from "../../components/layouts/SaveOrNot";
import { connect } from "react-redux";
import { addKitchenAction } from "../../actions/kitchen/kitchen";
import LoadSpinner from "../../components/loader/LoadSpinner";
import { useTranslation } from "react-i18next";
import Inputfield from "../../components/layouts";
import { FiArrowRight } from "react-icons/fi";
import {
  BUFFET_PURPOSE, 
  BUFFET_TYPE, 
  DAYS_NAME, 
} from "../../constants";
import { RxCross2 } from "react-icons/rx";
import SelectFromObject from "../../components/layouts/SelectFromObject";

const AddBuffet = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [buffetName, setBuffetName] = useState("");
  const [buffetType, setBuffetType] = useState(BUFFET_TYPE.HOTEL_GUEST);
  const [buffetPurpose, setBuffetPurpose] = useState(BUFFET_PURPOSE.HOTEL_GUEST);
  const [buffetPriceAndPerson, setBuffetPriceAndPerson] = useState("");
  const [buffetSaved, setBuffetSaved] = useState([]);
  const [day, setDay] = useState([]);
  const [noOfPlate, setNoOfPlate] = useState("");
  const [maximumNoOfPlates, setMaximumNoOfPlates] = useState([]);

  const handleClickOpen = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  const handleSubmit = () => {
    setOpen(false);
    props.addKitchen({
      hotelId: props?.hotel?._id,
      buffetName,
      buffetType,
      buffetPurpose,
      buffetSaved,
      day,
      maximumNoOfPlates,
      noOfPlate,
    });
  };

  return (
    <>
      <div className="px-8 py-6 w-full h-auto flex flex-col border border-[#D0D5DD] overflow-hidden rounded-lg bg-[#FFFFFF] relative mt-8 mx-auto">
        <div className="font-bold text-center text-[30px] leading-9 text-black tracking-tighter">
          {t("1036")}
        </div>
        <button className="absolute top-9 right-12" onClick={props.close}>
          <RxCross2
            size={26}
            className="hover:rotate-90 duration-500 hover:text-[#F04438]"
          />
        </button>
        <form
          className="grid grid-cols-2 md:gap-24 lg:px-16 lg:gap-32 md:px-4 mt-6 pt-4"
          onSubmit={handleClickOpen}
        >
          <div className="space-y-6">
            <Inputfield
              label={t("1037")}
              placeholder={t("1038")}
              message={"Enter your Buffet Name..."}
              value={buffetName}
              onChange={(e) => setBuffetName(e.target.value)}
              required={true}
            />
            <SelectFromObject
              label={t("1039")}
              toolTipMessage={t("1040")}
              options={BUFFET_TYPE}
              value={buffetType}
              setValue={setBuffetType}
            />
            <SelectFromObject
              label={t("Buffet Saved")}
              toolTipMessage={t("this is buffet saved")}
              options={{}}
              value={buffetSaved}
              setValue={setBuffetSaved}
            />
            <SelectFromObject
              label={t("Day")}
              toolTipMessage={t("select day")}
              options={DAYS_NAME}
              value={day}
              setValue={setDay}
            />
          </div>
          <div className="space-y-6">
            <SelectFromObject
              label={t("1041")}
              toolTipMessage={"Please select buffet purpose"}
              options={BUFFET_PURPOSE}
              value={buffetPurpose}
              setValue={setBuffetPurpose}
            />
            <Inputfield
              label={t("Buffet Price/Person")}
              placeholder={t("$12")}
              message={"enter Buffet Price and Persion..."}
              value={buffetPriceAndPerson}
              onChange={(e) => setBuffetPriceAndPerson(e.target.value)}
              min={0}
              maxLength={10}
              required={true}
            />
            <Inputfield
              label={t("No. of Plates")}
              placeholder={t("Manual Plates Entry")}
              message={"enter Manual Plates you want..."}
              value={noOfPlate}
              onChange={(e) => setNoOfPlate(e.target.value)}
              min={0}
              maxLength={10}
              required={true}
            />
            <SelectFromObject
              label={t("Maximum No. of Plates")}
              toolTipMessage={t("select Maximum No. of Plates")}
              options={{}}
              value={maximumNoOfPlates}
              setValue={setMaximumNoOfPlates}
            />
          </div>
        </form>
        <div className="flex justify-end pt-8 lg:pr-16 md:pr-4">
          <button
            className="h-10 w-[148px] bg-base-primary hover:bg-base-primary_hover rounded-md text-[#FFFFFF] flex justify-center gap-2 items-center"
            type="submit"
          >
            <div className="text-[16px] leading-5 font-medium ">
              {props.kitchen && props.kitchen.loading ? (
                <LoadSpinner
                  text={!props.asset ? <>{t("336")}</> : <>{t("1036")}</>}
                />
              ) : (
                <>{!props.asset ? <>{t("1036")}</> : <>{t("328")}</>}</>
              )}
            </div>
            <FiArrowRight className="" />
          </button>
        </div>
      </div>
      <SaveOrNot
        formSubmit={handleSubmit}
        isopen={open}
        close={() => setOpen(false)}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  kitchen: state.AddKitchen,
});
const mapDispatchToProps = (dispatch) => ({
  addKitchen: (data) => dispatch(addKitchenAction(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AddBuffet);
