import {
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  AUTH_REQUEST,
  AUTH_SUCCESS,
  AUTH_FAIL,
} from "../types/ownerAuth";
import axios from "axios";
import { ISADMIN, TOKEN } from "../../constants";

// owner register action
export const hotelOwnerRegisterAction = (formData) => async (dispatch) => {
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/register`;
  try {
    dispatch({ type: REGISTER_REQUEST });
    const { data } = await axios.post(url, formData, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    dispatch({
      type: REGISTER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: REGISTER_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

// hotel owner login action
export const hotelOwnerLoginAction = (formData) => async (dispatch) => {
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/login`;
  try {
    dispatch({ type: LOGIN_REQUEST });
    const { data } = await axios.post(url, formData, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    dispatch({
      type: LOGIN_SUCCESS,
      payload: data.token,
    });
  } catch (error) {
    dispatch({
      type: LOGIN_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

//get/authenticate hotel owner
export const authenticateHotelOwner = () => async (dispatch) => {
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner`;
  const token = localStorage.getItem(TOKEN);
  const isAdmin = await JSON.parse(localStorage.getItem(ISADMIN));

  if (token?.length && isAdmin) { 
    try {
      dispatch({ type: AUTH_REQUEST });
      const { data } = await axios.get(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
        withCredentials: true,
      });
      dispatch({
        type: AUTH_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: AUTH_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  } else {
    dispatch({ type: AUTH_FAIL });
    return;
  }
};
