const ROOM_AMENITIES = 
  {
    childrensCribs: "Children's cribs",
    clothesRack: "Clothes rack",
    dryingRack: "Drying rack for clothing",
    foldUpBed: "Fold-up bed",
    sofaBed: "Sofa bed",
    trashCans: "Trash cans",
    airConditioning: "Air conditioning",
    dryer: "Dryer",
    wardrobeCloset: "Wardrobe/Closet",
    carpeted: "Carpeted",
    walkInCloset: "Walk-in closet",
    extraLongBeds: "Extra long beds (> 6.5 ft)",
    fan: "Fan",
    heating: "Heating",
    interconnectingRooms: "Interconnecting room(s) available",
    iron: "Iron",
    hotTub: "Hot tub",
    mosquitoNet: "Mosquito net",
    privateEntrance: "Private entrance",
    safe: "Safe",
    sofa: "Sofa",
    soundproof: "Soundproof",
    tileMarbleFloor: "Tile/Marble floor",
    washingMachine: "Washing machine",
    hardwoodFloors: "Hardwood/Parquet floors",
    desk: "Desk",
    privateBathroom: "Private bathroom",
    sharedBathroom: "Shared bathroom",
    toiletPaper: "Toilet paper",
    toiletries: "Toiletries",
    bathrobe: "Bathrobe",
    shower: "Shower",
    bottleOfWater: "Bottle of Water",
    toaster: "Toaster",
    dishwasher: "Dishwasher",
    electricKettle: "Electric kettle",
    kitchen: "Kitchen",
    kitchenware: "Kitchenware",
    microwave: "Microwave",
    refrigerator: "Refrigerator",
    teaCoffeeMachine: "Tea/Coffee machine",
    alarmClock: "Alarm clock",
    wakeUpService: "Wake-up service",
    linens: "Linens",
    towels: "Towels",
    balcony: "Balcony",
    patio: "Patio",
    poolView: "Pool view",
    seaView: "Sea view",
    tv: "TV",
    ironBoard: "Iron Board",
    clothHangars: "Cloth Hangars",
    dishesSilverware: "Dishes and silverware",
    toothbrush: "Toothbrush",
    bathtub: "Bathtub",
    shampoo: "Shampoo",
    conditioner: "Conditioner",
    bodySoap: "Body soap",
    showerCap: "Shower cap",
    showerGel: "Shower gel",
    bedLinen: "Bed linen",
    extraPillowsBlankets: "Extra pillows and blankets",
    booksReadingMaterial: "Books and reading material",
    childrensBooksToys: "Children's books and toys",
    ceilingFan: "Ceiling fan",
    portableFans: "Portable fans",
    coffee: "Coffee",
    kettle: "Kettle",
    miniFridge: "Mini fridge",
    bbqGrill: "BBQ grill",
    firePit: "Fire pit",
    privatePool: "Private pool",
    terrace: "Terrace",
    spaTub: "Spa tub",
    heatedPool: "Heated pool",
    infinityPool: "Infinity Pool",
    plungePool: "Plunge Pool",
    poolCover: "Pool cover",
    poolTowels: "Pool towels",
    poolWithView: "Pool with a view",
    rooftopPool: "Rooftop pool",
    saltwaterPool: "Saltwater pool",
    shallowEnd: "Shallow end",
    fireplace: "Fireplace",
    ironingFacilities: "Ironing facilities",
    suitPress: "Suit press",
    hypoallergenic: "Hypoallergenic",
    cleaningProducts: "Cleaning products",
    electricBlankets: "Electric blankets",
    pajamas: "Pajamas",
    yukata: "Yukata",
    socketNearBed: "Socket near the bed",
    adapter: "Adapter",
    featherPillow: "Feather pillow",
    nonFeatherPillow: "Non-feather pillow",
    extensionBoard: "Extension board",
    bidet: "Bidet",
    freeToiletries: "Free toiletries",
    guestBathroom: "Guest bathroom",
    hairdryer: "Hairdryer",
    sharedToilet: "Shared toilet",
    sauna: "Sauna",
    slippers: "Slippers",
    toilet: "Toilet",
    additionalBathroom: "Additional bathroom",
    gameConsolePs4: "Game console - PS4",
    gameConsoleWiiU: "Game console - Wii U",
    gameConsoleXboxOne: "Game console - Xbox One",
    computer: "Computer",
    gameConsoleNintendoWii: "Game console - Nintendo Wii",
    gameConsolePs2: "Game console - PS2",
    gameConsolePs3: "Game console - PS3",
    gameConsoleXbox360: "Game console - Xbox 360",
    laptop: "Laptop",
    ipad: "iPad",
    cableChannels: "Cable channels",
    cdPlayer: "CD player",
    dvdPlayer: "DVD player",
    fax: "Fax",
    ipodDock: "iPod dock",
    laptopSafe: "Laptop safe",
    payPerViewChannels: "Pay-per-view channels",
    radio: "Radio",
    satelliteChannels: "Satellite channels",
    telephone: "Telephone",
    video: "Video",
    videoGames: "Video games",
    bluRayPlayer: "Blu-ray player",
    mobileHotspotDevice: "Mobile hotspot device",
    smartphone: "Smartphone",
    streamingService: "Streaming service (like Netflix)",
    diningArea: "Dining area",
    diningTable: "Dining table",
    wineGlasses: "Wine glasses",
    fruit: "Fruit",
    wineChampagne: "Wine/Champagne",
    barbecue: "Barbecue",
    oven: "Oven",
    stovetop: "Stovetop",
    outdoorFurniture: "Outdoor furniture",
    minibar: "Minibar",
    kitchenette: "Kitchenette",
    keyCardAccess: "Key card access",
    lockers: "Lockers",
    keyAccess: "Key access",
    executiveLoungeAccess: "Executive lounge access",
    cityView: "City view",
    gardenView: "Garden view",
    lakeView: "Lake view",
    landmarkView: "Landmark view",
    mountainView: "Mountain view",
    riverView: "River view",
    oceanView: "Ocean view",
    innerCourtyardView: "Inner courtyard view",
    quietStreetView: "Quiet street view",
    accessibleByElevator: "Accessible by elevator",
    groundFloorUnit: "Entire unit located on ground floor",
    wheelchairAccessibleUnit: "Entire unit wheelchair accessible",
    upperFloorsAccessibleByElevator: "Upper floors accessible by elevator",
    upperFloorsAccessibleByStairsOnly: "Upper floors accessible by stairs only",
    adaptedBath: "Adapted bath",
  }

export  {ROOM_AMENITIES} ;
