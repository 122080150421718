import React from "react";
const SectionDivider = ({ text }) => {
  return (
    <>
      <div className="text-[#A1A1AA] font-medium text-[12px] uppercase px-3 mt-5 mb-3">
        {text}
      </div>
    </>
  );
};
export default SectionDivider;
