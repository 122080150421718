/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
// import { useTranslation } from "react-i18next";
import { TbPencil } from "react-icons/tb";
import { isObjEmpty } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants";
import { FiArrowRight } from "react-icons/fi";
import CustomeTable from "../../../components/CustomeTable";
import PrintButton from "../../../components/buttons/PrintButton";
import UploadButton from "../../../components/buttons/UploadButton";
import DownloadButton from "../../../components/buttons/DownloadButton";

const AllTaxesDataTable = (props) => {
  // const { t } = useTranslation();
  const [datas, setDatas] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    if (props?.taxes && !isObjEmpty(props?.taxes?.taxes)) {
      setDatas([
        {
          name: props?.taxes?.taxes?.name,
          country: props?.taxes?.taxes?.country,
          taxCategory: props?.taxes?.taxes?.taxCategory,
          taxType: props?.taxes?.taxes?.taxType,
          minimum: props?.taxes?.taxes?.minimum,
          maximum: props?.taxes?.taxes?.maximum,
          tax: props?.taxes?.taxes?.tax,
          _id: props?.taxes?.taxes?._id,
        },
      ]);
    }
  }, [props?.taxes]);

  const gotoNextTab = () => {
    navigate(`${ROUTES.HOTEL_CONFIG}/7`);
  };
  const skipTheTab = () => {
    navigate(`${ROUTES.HOTEL_CONFIG}/7`);
  };

  const tableData = [
    {
      _id: "TX001",
      name: "Income Tax",
      country: "Usa",
      taxCategory: "Room Tarif",
      taxType: "Slab Wise",
      minimum: "10",
      maximum: "1000",
      tax: "10%",
    },
    {
      _id: "TX002",
      name: "Income Tax",
      country: "Usa",
      taxCategory: "Room Tarif",
      taxType: "Slab Wise",
      minimum: "10",
      maximum: "1000",
      tax: "10%",
    },
    {
      _id: "TX003",
      name: "Income Tax",
      country: "Usa",
      taxCategory: "Room Tarif",
      taxType: "Slab Wise",
      minimum: "10",
      maximum: "1000",
      tax: "10%",
    },
    {
      _id: "TX004",
      name: "Income Tax",
      country: "Usa",
      taxCategory: "Room Tarif",
      taxType: "Slab Wise",
      minimum: "10",
      maximum: "1000",
      tax: "10%",
    },
    {
      _id: "TX005",
      name: "Income Tax",
      country: "Usa",
      taxCategory: "Room Tarif",
      taxType: "Slab Wise",
      minimum: "10",
      maximum: "1000",
      tax: "10%",
    },
  ];
  const viewAllTaxesActions = () => {
    return (
      <>
        <button type="button" className="mt-2">
          <TbPencil
            size={14}
            className="w-6 h-6 rounded text-black hover:text-[#FFFFFF] hover:bg-base-primary"
          />
        </button>
      </>
    );
  };
  const columnDefs = [
    {
      headerName: "Tax ID",
      field: "_id",
      minWidth: 100,
      maxWidth: 200,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Name",
      field: "name",
      minWidth: 150,
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Country",
      field: "country",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Tax Category",
      field: "taxCategory",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Tax Type",
      field: "taxType",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Minimum",
      field: "minimum",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Maximum",
      field: "maximum",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Tax",
      field: "tax",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Actions",
      field: "_id",
      minWidth: 200,
      cellRenderer: viewAllTaxesActions,
    },
  ];

  return (
    <div className="mt-4">
      <div className="flex items-center justify-between mb-4">
        <div className="text-[22px] leading-[27.72px] text-black font-bold">
          All Tax Table
        </div>
        <div className="flex items-center gap-4">
          <PrintButton />
          <UploadButton />
          <DownloadButton />
        </div>
      </div>
      <div className="">
        <CustomeTable
          columnDefs={columnDefs}
          rowData={tableData}
          setSelectedRows={setSelectedRows}
        />
      </div>
      <div className="flex justify-end items-center gap-4 text-sm font-medium my-4">
        <button
          className="h-[36px] w-[58px] border border-[#E5E9EB] rounded-md bg-[#FFFFFF] hover:bg-slate-100 text-[#252C32]"
          onClick={skipTheTab}
        >
          Skip
        </button>
        <button
          className="flex items-center justify-center gap-2 h-[36px] w-[88px] rounded-md text-[#FFFFFF] bg-base-primary hover:bg-base-primary_hover drop-shadow-[0_1px_2px_0_rgba(16,24,40,0.05)]"
          onClick={gotoNextTab}
        >
          <div>Next</div>
          <FiArrowRight size={20} />
        </button>
      </div>
    </div>
  );
};

export default AllTaxesDataTable;
