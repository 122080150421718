import { CLEAR_ERRORS } from "../../actions/types";
import {
  ADD_EXPENSE_FAIL,
  ADD_EXPENSE_REQUEST,
  ADD_EXPENSE_RESET,
  ADD_EXPENSE_SUCCESS,
  GET_ALL_EXPENSE_FAIL,
  GET_ALL_EXPENSE_REQUEST,
  GET_ALL_EXPENSE_SUCCESS,
} from "../../actions/types/expense";

//ADD expense employee
export const addExpenseReducer = (state = { expense: {} }, action) => {
  switch (action.type) {
    case ADD_EXPENSE_REQUEST:
      return {
        ...state,
        loading: true,
        isadded: false,
      };
    case ADD_EXPENSE_SUCCESS:
      return {
        ...state,
        loading: false,
        expense: action.payload,
        isadded: true,
      };
    case ADD_EXPENSE_FAIL:
      return {
        ...state,
        loading: false,
        isadded: false,
        error: action.payload,
      };
    case ADD_EXPENSE_RESET:
      return {
        ...state,
        loading: false,
        isadded: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

//get expense
export const getAllExpenseReducer = (state = { expenses: [] }, action) => {
  switch (action.type) {
    case GET_ALL_EXPENSE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_EXPENSE_SUCCESS:
    case ADD_EXPENSE_SUCCESS:
      return {
        ...state,
        loading: false,
        expenses: action.payload,
      };
    case GET_ALL_EXPENSE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
