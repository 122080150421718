import axios from "axios";
import { ISADMIN, TOKEN } from "../../constants";  
import {
  CHANGE_PASSWORD_FAIL,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAIL,
  GET_PROFILE_REQUEST,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAIL,
} from "../types/ownerAuth";
// get profile
export const getOwnerProfileAction = () => async (dispatch) => {
  const isadmin = await JSON.parse(localStorage.getItem(ISADMIN))
  if (!isadmin) return;
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/profile`;
  const token = localStorage.getItem(TOKEN);

  if (token && token?.length) {
    try {
      dispatch({ type: GET_PROFILE_REQUEST });
      const { data } = await axios.get(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem(TOKEN),
        },
        withCredentials: true,
      });
      dispatch({
        type: GET_PROFILE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_PROFILE_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  }
};

//update owner profile
export const updateOwnerProfileAction = (formData) => async (dispatch) => {
  const isadmin = await JSON.parse(localStorage.getItem(ISADMIN))
  if (!isadmin) return;
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/profile`;
  try {
    dispatch({ type: UPDATE_PROFILE_REQUEST });
    const { data } = await axios.post(url, formData, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: UPDATE_PROFILE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_PROFILE_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

// change password
export const changePasswordAction = (formData) => async (dispatch) => {
  const isadmin = await JSON.parse(localStorage.getItem(ISADMIN))
  if (!isadmin) return;
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/change-password`;

  try {
    dispatch({ type: CHANGE_PASSWORD_REQUEST });
    const { data } = await axios.post(url, formData, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: CHANGE_PASSWORD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CHANGE_PASSWORD_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};
