//@HK = HOUSEKEEPING
export const HOUSE_KEEPING_ROOM_REQUEST = "HOUSE_KEEPING_ROOM_REQUEST";
export const HOUSE_KEEPING_ROOM_SUCCESS = "HOUSE_KEEPING_ROOM_SUCCESS";
export const HOUSE_KEEPING_ROOM_FAIL = "HOUSE_KEEPING_ROOM_FAIL";

export const ASSIGN_HOUSE_KEEPING_REQUEST = "ASSIGN_HOUSE_KEEPING_REQUEST";
export const ASSIGN_HOUSE_KEEPING_SUCCESS = "ASSIGN_HOUSE_KEEPING_SUCCESS";
export const ASSIGN_HOUSE_KEEPING_FAIL = "ASSIGN_HOUSE_KEEPING_FAIL";
export const ASSIGN_HOUSE_KEEPING_RESET = "ASSIGN_HOUSE_KEEPING_RESET";

export const HOUSE_KEEPING_ROOM_UPDATE_REQUEST =
  "HOUSE_KEEPING_ROOM_UPDATE_REQUEST";
export const HOUSE_KEEPING_ROOM_UPDATE_SUCCESS =
  "HOUSE_KEEPING_ROOM_UPDATE_SUCCESS";
export const HOUSE_KEEPING_ROOM_UPDATE_FAIL = "HOUSE_KEEPING_ROOM_UPDATE_FAIL";
export const HOUSE_KEEPING_ROOM_UPDATE_RESET =
  "HOUSE_KEEPING_ROOM_UPDATE_RESET";
