import React, { useState } from "react";
import Seperator from "../../components/layouts/Seperator";
import SpaConfig from "./SpaConfig";
import CheckRadioInputs from "../../components/layouts/CheckRadioInputs";

const Spa = (props) => {
  const [isSpaServices, setIsSpaServices] = useState(false);
  const track = {
    heading: "Spa",
    seperator: [
      {
        text: "Account Options",
      },
      {
        text: "spa",
      },
    ],
  };

  return (
    <div className="page-container max-w-7xl">
      <div className="w-full max-w-full">
        <div className="relative flex items-end justify-between">
          <Seperator data={track} />
          <div className="absolute -bottom-2 right-0">
            <CheckRadioInputs
              label={"Spa Services"}
              value={isSpaServices}
              onChange={() => setIsSpaServices(!isSpaServices)}
            />
          </div>
        </div>
      </div>
      <div className="">
        <SpaConfig />
      </div>
    </div>
  );
};

export default Spa;
