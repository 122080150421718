import React from "react";
import { useTranslation } from "react-i18next";
import { BsCheck2 } from "react-icons/bs";
const SupportMessageRecieved = ({ message }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="max-w-[50%] bg-[#F6F6F6] text-[#07090D] rounded-[10px] py-1 px-2 m-2 sticky left-0 ">
        <div className="text-[16px]  leading-7 font-normal tracking-[-0.6%]">
          {t(
            "Dipper mowla dolor sit amet consectetur. Diam commodo consectetur."
          )}
        </div>
        <div className="flex justify-end items-center text-[12px] leading-4 font-normal">
          <div className="">20:23</div>
          <div className="w-[14px] h-[14px] flex justify-center items-center">
            <BsCheck2 />
          </div>
        </div>
      </div>
    </>
  );
};

export default SupportMessageRecieved;
