import axios from "axios";
import {
  GET_All_EMPLOYEE_REVIEWS_REQUEST,
  GET_All_EMPLOYEE_REVIEWS_SUCCESS,
  GET_All_EMPLOYEE_REVIEWS_FAIL,
  EMPLOYEE_REPLY_A_REVIEW_REQUEST,
  EMPLOYEE_REPLY_A_REVIEW_SUCCESS,
  EMPLOYEE_REPLY_A_REVIEW_FAIL,
  APPROVE_A_EMPLOYEE_REVIEW_REQUEST,
  APPROVE_A_EMPLOYEE_REVIEW_SUCCESS,
  APPROVE_A_EMPLOYEE_REVIEW_FAIL,
  DELETE_A_EMPLOYEE_REVIEW_REQUEST,
  DELETE_A_EMPLOYEE_REVIEW_SUCCESS,
  DELETE_A_EMPLOYEE_REVIEW_FAIL,
} from "../types/reviews";
import { TOKEN } from "../../constants";

//get all employee reviews by hotel id
export const getAllReviewAction = (hotelId) => async (dispatch) => {
  if (!hotelId) return;
  let url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/employee/review?hotelId=${hotelId}`;

  try {
    dispatch({ type: GET_All_EMPLOYEE_REVIEWS_REQUEST });
    const { data } = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: GET_All_EMPLOYEE_REVIEWS_SUCCESS,
      payload: data?.reviews,
    }); 
  } catch (error) {
    dispatch({
      type: GET_All_EMPLOYEE_REVIEWS_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

//employee reply a reviews by hotel id
export const replyReviewAction = (formBody) => async (dispatch) => {
  let url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/employee/review/reply`;

  try {
    dispatch({ type: EMPLOYEE_REPLY_A_REVIEW_REQUEST });
    const { data } = await axios.put(url, formBody, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: EMPLOYEE_REPLY_A_REVIEW_SUCCESS,
      payload: data?.reviews,
    });
    console.log(data?.reviews)
  } catch (error) {
    dispatch({
      type: EMPLOYEE_REPLY_A_REVIEW_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

//approve a employee reviews by hotel id
export const approveReviewAction = (hotelId) => async (dispatch) => {
  let url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/employee/review/approve`;

  try {
    dispatch({ type: APPROVE_A_EMPLOYEE_REVIEW_REQUEST });
    const { data } = await axios.put(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: APPROVE_A_EMPLOYEE_REVIEW_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: APPROVE_A_EMPLOYEE_REVIEW_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

//delete a employee reviews by hotel id
export const deleteReviewAction = (hotelId) => async (dispatch) => {
  let url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/employee/review`;

  try {
    dispatch({ type: DELETE_A_EMPLOYEE_REVIEW_REQUEST });
    const { data } = await axios.delete(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: DELETE_A_EMPLOYEE_REVIEW_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DELETE_A_EMPLOYEE_REVIEW_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};
