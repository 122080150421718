import axios from "axios";
import { TOKEN } from "../../constants";
import {
  DELETE_IMAGE_FAIL,
  DELETE_IMAGE_REQUEST,
  DELETE_IMAGE_SUCCESS,
  UPLOAD_IMAGE_FAIL,
  UPLOAD_IMAGE_REQUEST,
  UPLOAD_IMAGE_SUCCESS,
} from "../types/ImageUpload";

//upload image
export const uploadImageAction = (formData) => async (dispatch) => {
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/media/upload`;
  try {
    dispatch({ type: UPLOAD_IMAGE_REQUEST });
    const { data } = await axios.post(url, formData, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: UPLOAD_IMAGE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPLOAD_IMAGE_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

//delete image
export const deleteImageAction = (fileId) => async (dispatch) => {
  if(!fileId) return;
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/media/delete`;
  try {
    dispatch({ type: DELETE_IMAGE_REQUEST });
    const { data } = await axios.delete(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
      data: { fileId },
    });
    dispatch({
      type: DELETE_IMAGE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DELETE_IMAGE_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};
