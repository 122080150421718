/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { clearError } from "../../actions/clearError";
import { connect } from "react-redux";
import { notifySuccess } from "../../components/alert";
import DelIcon from "../../images/icons/DelIcon.svg";
import EditIcon from "../../images/icons/EidIcon.svg";
import { deleteAssetAction } from "../../actions/pms/asset";
import DeleteOrNot from "../../components/layouts/DeleteOrNot";
import { registerEvent } from "../../logs";
import BackGroundLoadSpinner from "../../components/loader/BackGroundLoadSpinner";
import { useTranslation } from "react-i18next";
import { DELETE_HOTEL_ASSET_RESET } from "../../actions/types/asset";
import CustomeTable from "../../components/CustomeTable";
import PrintButton from "../../components/buttons/PrintButton";
import UploadButton from "../../components/buttons/UploadButton";
import DownloadButton from "../../components/buttons/DownloadButton";

const AllAssets = (props) => {
  const { t } = useTranslation();
  const [datas, setDatas] = useState([]);
  const [open, setOpen] = useState(false);
  const [assetId, setAssetId] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  // set assets state
  const setNewDatas = async (assets) => {
    let data = [];
    await assets.map((asset) => {
      return data.push({
        name: asset.name,
        brand: asset.brand,
        description: asset.description,
        pricePerItem: asset.pricePerItem,
        availableQuantity: asset.availableQuantity,
        thresholdValue: asset.thresholdValue,
        consumeIn: asset.consumeIn,
        vendorId: asset.vendorId,
        id: asset.id,
      });
    });
    if (data?.length) {
      setDatas(data);
    }
  };
  useEffect(() => {
    if (
      props.assets &&
      props.assets.assets &&
      Array.isArray(props.assets.assets)
    ) {
      setNewDatas(props.assets.assets);
    }
  }, [props?.assets]);

  //set asset for edit
  const setAssetForEdit = async (id) => {
    if (
      props?.assets &&
      props?.assets?.assets &&
      props?.assets?.assets?.length
    ) {
      const asset = props?.assets?.assets?.filter((x) => x?._id === id);
      props.setAsset(asset[0]);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  //open modal for confiramtion of delete asset or not
  const openDeleteModal = async (id) => {
    if (id) {
      setAssetId(id);
      setOpen(true);
      registerEvent("delete asset modal", "all assets", { id });
    }
  };

  //delete asset
  const deleteAssetNow = () => {
    if (assetId) {
      props.deleteAsset(assetId, props?.hotel?._id);
    }
  };

  // show deleted message
  useEffect(() => {
    if (props?.delAsset && props?.delAsset?.isdeleted) {
      notifySuccess(t("683"));
      props?.reset();
    }
  }, [props?.delAsset]);

  const viewExpenseActions = (id) => {
    return (
      <div className="flex items-center gap-4 mt-[5px]">
        <button
          className="w-[30px] h-[30px] border border-[#E5E9EB] bg-[#FFFFFF] rounded-full flex items-center justify-center hover:bg-[#E5E9EB]"
          onClick={(value) => openDeleteModal(value)}
        >
          <img src={DelIcon} alt="" />
        </button>
        <button
          className="w-[30px] h-[30px] border border-[#E5E9EB] bg-[#FFFFFF] rounded-full flex items-center justify-center hover:bg-[#E5E9EB]"
          onClick={setAssetForEdit}
        >
          <img src={EditIcon} alt="" />
        </button>
      </div>
    );
  };

  const columnDefs = [
    {
      headerName: "Assets ID",
      field: "id",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Name",
      field: "name",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Brand",
      field: "brand",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Description",
      field: "description",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Available Quantity",
      field: "availableQuantity",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Threshold Value",
      field: "thresholdValue",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Consume In",
      field: "consumeIn",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Vendor Id",
      field: "vendorId",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Actions",
      field: "_id",
      minWidth: 200,
      cellRenderer: viewExpenseActions,
    },
  ];

  return (
    <>
      <div className="flex items-center justify-between mt-6">
        <div className="text-[22px] leading-[27.72px] text-black font-bold">
          Assets Table
        </div>
        <div className="flex items-center gap-4">
          <PrintButton />
          <UploadButton />
          <DownloadButton />
        </div>
      </div>
      <div className="mt-4">
        {/* {data.length ? ( */}
        <CustomeTable
          columnDefs={columnDefs}
          rowData={datas}
          setSelectedRows={setSelectedRows}
        />
        {/* ) : null} */}
      </div>

      {open ? (
        <>
          <DeleteOrNot
            open={open}
            close={() => setOpen(false)}
            formSubmit={deleteAssetNow}
          />
        </>
      ) : null}
      {props?.delAsset && props?.delAsset?.loading ? (
        <BackGroundLoadSpinner />
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => ({
  assets: state.AllAsset,
  delAsset: state.DeleteAsset,
});

const mapDispatchToProps = (dispatch) => ({
  clear: () => dispatch(clearError()),
  deleteAsset: (assetId, hotelId) =>
    dispatch(deleteAssetAction(assetId, hotelId)),
  reset: () => dispatch({ type: DELETE_HOTEL_ASSET_RESET }),
});

export default connect(mapStateToProps, mapDispatchToProps)(AllAssets);
