import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { RxCross2 } from "react-icons/rx";
import Inputfield from "../../components/layouts";
import SelectFromArray from "../../components/layouts/SelectFromArray";
import ManagerIcon from "../../images/icons/ManagerIcon.svg";
import messageIcon2 from "../../images/icons/messageIcon2.svg";
import NewJobIcon from "../../images/icons/NewJobIcon.svg"; 
import ShiftTimming from "../../components/layouts/ShiftTimming";
import { FaPlus } from "react-icons/fa6";
import dishImg from "../../images/dishImg.png";
import AddSubmitButton from "../../components/buttons/AddSubmitButton";

const CreateRoaster = ({ close }) => {
  const { t } = useTranslation();
  const [timeZone, setTimeZone] = useState([]);
  const [startTime, setStartTime] = useState("8:00");
  const [startOptions, setStartOptions] = useState("");
  const [endTime, setEndTime] = useState("6:00");
  const [endOptions, setEndOptions] = useState("");
  const [addNewShifts, setAddNewShifts] = useState([0]);
  const [isAddNewShiftButtton, setIsAddNewShiftButton] = useState(false);

  const AddNewShift = () => {
    const addNewShift = {
      startTime,
      setStartTime,
      startOptions,
      setStartOptions,
      endTime,
      setEndTime,
      endOptions,
      setEndOptions,
    };
    setAddNewShifts([...addNewShifts, addNewShift]);
    setIsAddNewShiftButton(isAddNewShiftButtton);
  };
  return (
    <div className="relative w-full h-auto border border-[#E5E9EB] rounded-lg bg-[#FFFFFF] mt-6 p-6">
      <div className="text-center font-bold text-[30px] leading-[37.8px] text-[#07090D] tracking-[-1.5%]">
        {t("Create Roaster")}
      </div>
      <button
        className="absolute rounded-full overflow-hidden font-semibold right-7 top-8"
        onClick={close}
      >
        <RxCross2
          size={26}
          className="hover:rotate-90 duration-500 hover:text-[#F04438]"
        />
      </button>
      <form className="mt-6">
        <div className="grid lg:grid-cols-3 md:grid-cols-2 lg:gap-4 md:gap-6 lg:mr-20">
          <Inputfield
            label={"Roaster Name"}
            placeholder={"Security Guard Duty"}
            required={true}
            minLength={3}
            maxLength={50}
            utility="w-[302px]"
            icon={
              <>
                <img src={ManagerIcon} alt="" />
              </>
            }
          />
          <Inputfield
            label={"Position"}
            placeholder={"Manager"}
            required={true}
            minLength={3}
            maxLength={50}
            utility="w-[302px]"
            icon={
              <>
                <img src={messageIcon2} alt="" />
              </>
            }
          />
          <Inputfield
            label={"Role"}
            placeholder={"Manage Staff"}
            required={true}
            minLength={3}
            maxLength={50}
            utility="w-[302px]"
            icon={
              <>
                <img src={NewJobIcon} alt="" />
              </>
            }
          />
          <Inputfield
            label={"No OF Shifts"}
            type="number"
            placeholder={""}
            required={true}
            minLength={3}
            maxLength={50}
            utility="w-[302px]"
            min={0}
          />
          <Inputfield
            label={"Work Area"}
            placeholder={"working on first floor"}
            required={true}
            minLength={3}
            maxLength={50}
            utility="w-[302px]"
          />
          <SelectFromArray
            label={"Time Zone"}
            options={["1", "2"]}
            value={timeZone}
            onChange={(e) => setTimeZone(e?.target?.value)}
          />
          <SelectFromArray
            label={"Time Format"}
            options={["1", "2"]}
            value={timeZone}
            onChange={(e) => setTimeZone(e?.target?.value)}
          />
        </div>
        <div className="mt-4">
          <div className="text-[16px] leading-5 font-medium text-[#344054] underline underline-offset-4 mb-6">
            Shift Timming
          </div>
          {addNewShifts.map((i, count = 0) => {
            count += 1;
            return (
              <>
                <div className="lg:flex items-center gap-4 my-4" key={i}>
                  <div className="">
                    <div className="text-sm font-medium text-[#344054] capitalize">
                      shift {count}
                    </div>
                    <ShiftTimming
                      startTime={startTime}
                      setStartTime={setStartTime}
                      options={['']}
                      startOptionsValue={startOptions}
                      setStartOptionValue={setStartOptions}
                      endTime={endTime}
                      setEndTime={setEndTime}
                      endOptionsValue={endOptions}
                      setEndOptionValue={setEndOptions}
                    />
                  </div>
                  <div className="">
                    {!isAddNewShiftButtton && (
                      <button
                        type="button"
                        className={`flex items-center justify-center gap-3 w-[165px] h-12 bg-base-primary hover:bg-base-primary_hover rounded-md drop-shadow-[0_1px_2px_0_rgba(16, 24, 40, 0.05)] mt-[20px]`}
                        onClick={AddNewShift}
                      >
                        <div className="border h-[17px] w-[17px] bg-[#FFFFFF] rounded-full flex items-center justify-center">
                          <FaPlus size={11} className="text-base-primary" />
                        </div>
                        <div className="text-[16px] leading-5 font-medium text-[#FFFFFF]">
                          Add new shift
                        </div>
                      </button>
                    )}
                  </div>
                </div>
              </>
            );
          })}
        </div>
        <div className="mt-4">
          <div className="text-[22px] text-[#07090D] leading-[27.72px] font-semibold tracking-[-0.8px] ">
            Add workers to your Roaster
          </div>
          <div className="mt-4 w-[370px] ">
            <input type="text" placeholder="Search" className="pl-12" />
            <div className="text-[16px] text-[#6E7C87] leading-6 font-normal my-2">
              You can do it later if you want
            </div>
            <div className="grid grid-cols-3 gap-[14px]">
              <div className="w-[114px] h-[29px] border border-[#D0D5DD] rounded bg-[#F2F3F3] px-1 py-[2px]">
                <div className="flex items-center justify-between gap-1">
                  <div className="flex items-center gap-2 text-[#6E7C87]">
                    <img
                      src={dishImg}
                      alt=""
                      className="w-[19px] h-[19px] rounded-[2px] object-cover"
                    />
                    <div className="text-[10px] leading-5 font-normal">
                      Willy Williams
                    </div>
                  </div>
                  <RxCross2 size={11} />
                </div>
              </div>
              <div className="w-[114px] h-[29px] border border-[#D0D5DD] rounded bg-[#F2F3F3] px-1 py-[2px]">
                <div className="flex items-center justify-between gap-1">
                  <div className="flex items-center gap-2 text-[#6E7C87]">
                    <img
                      src={dishImg}
                      alt=""
                      className="w-[19px] h-[19px] rounded-[2px] object-cover"
                    />
                    <div className="text-[10px] leading-5 font-normal">
                      Willy Williams
                    </div>
                  </div>
                  <RxCross2 size={11} />
                </div>
              </div>
              <div className="w-[114px] h-[29px] border border-[#D0D5DD] rounded bg-[#F2F3F3] px-1 py-[2px]">
                <div className="flex items-center justify-between gap-1">
                  <div className="flex items-center gap-2 text-[#6E7C87]">
                    <img
                      src={dishImg}
                      alt=""
                      className="w-[19px] h-[19px] rounded-[2px] object-cover"
                    />
                    <div className="text-[10px] leading-5 font-normal">
                      Willy Williams
                    </div>
                  </div>
                  <RxCross2 size={11} />
                </div>
              </div>
            </div>
            {/* Add new vendor */}
            <div className="h-[320px] mt-8">
              <div className="text-[18px] text-[#344054] leading-[22.68px] tracking-[-0.8px] font-medium h-6 px-4">
                Add a New Vendor
              </div>
              <div className="h-[260px] overflow-y-auto scroll-bar4">
                {/* row-1 */}
                <div className="flex items-center justify-between text-[18px] leading-[22.68px] font-medium tracking-[-0.8px] text-[#344054] px-4 pt-4">
                  <div className="flex items-center gap-3">
                    <div className="w-12 h-12 border border-[#D0D5DD] rounded bg-[#F2F3F3]">
                      <img
                        src={dishImg}
                        alt=""
                        className="w-fit h-fit object-cover"
                      />
                    </div>
                    <div className="">William William </div>
                  </div>
                  <input
                    type="radio"
                    className="accent-current w-[14px] h-[14px] border border-[#D0D5DD]"
                  />
                </div>
                {/* row-1 */}
                <div className="flex items-center justify-between text-[18px] leading-[22.68px] font-medium tracking-[-0.8px] text-[#344054] px-4 pt-4">
                  <div className="flex items-center gap-3">
                    <div className="w-12 h-12 border border-[#D0D5DD] rounded bg-[#F2F3F3]">
                      <img
                        src={dishImg}
                        alt=""
                        className="w-fit h-fit object-cover"
                      />
                    </div>
                    <div className="">William William </div>
                  </div>
                  <input
                    type="radio"
                    className="accent-current w-[14px] h-[14px] border border-[#D0D5DD]"
                  />
                </div>
                {/* row-2 */}
                <div className="flex items-center justify-between text-[18px] leading-[22.68px] font-medium tracking-[-0.8px] text-[#344054] px-4 pt-4">
                  <div className="flex items-center gap-3">
                    <div className="w-12 h-12 border border-[#D0D5DD] rounded bg-[#F2F3F3]">
                      <img
                        src={dishImg}
                        alt=""
                        className="w-fit h-fit object-cover"
                      />
                    </div>
                    <div className="">William William </div>
                  </div>
                  <input
                    type="radio"
                    className="accent-current w-[14px] h-[14px] border border-[#D0D5DD]"
                  />
                </div>
                {/* row-3 */}
                <div className="flex items-center justify-between text-[18px] leading-[22.68px] font-medium tracking-[-0.8px] text-[#344054] px-4 pt-4">
                  <div className="flex items-center gap-3">
                    <div className="w-12 h-12 border border-[#D0D5DD] rounded bg-[#F2F3F3]">
                      <img
                        src={dishImg}
                        alt=""
                        className="w-fit h-fit object-cover"
                      />
                    </div>
                    <div className="">William William </div>
                  </div>
                  <input
                    type="radio"
                    className="accent-current w-[14px] h-[14px] border border-[#D0D5DD]"
                  />
                </div>
                {/* row-4 */}
                <div className="flex items-center justify-between text-[18px] leading-[22.68px] font-medium tracking-[-0.8px] text-[#344054] px-4 pt-4">
                  <div className="flex items-center gap-3">
                    <div className="w-12 h-12 border border-[#D0D5DD] rounded bg-[#F2F3F3]">
                      <img
                        src={dishImg}
                        alt=""
                        className="w-fit h-fit object-cover"
                      />
                    </div>
                    <div className="">William William </div>
                  </div>
                  <input
                    type="radio"
                    className="accent-current w-[14px] h-[14px] border border-[#D0D5DD]"
                  />
                </div>
                {/* row-5 */}
                <div className="flex items-center justify-between text-[18px] leading-[22.68px] font-medium tracking-[-0.8px] text-[#344054] px-4 pt-4">
                  <div className="flex items-center gap-3">
                    <div className="w-12 h-12 border border-[#D0D5DD] rounded bg-[#F2F3F3]">
                      <img
                        src={dishImg}
                        alt=""
                        className="w-fit h-fit object-cover"
                      />
                    </div>
                    <div className="">William William </div>
                  </div>
                  <input
                    type="radio"
                    className="accent-current w-[14px] h-[14px] border border-[#D0D5DD]"
                  />
                </div>
                {/* row-6 */}
                <div className="flex items-center justify-between text-[18px] leading-[22.68px] font-medium tracking-[-0.8px] text-[#344054] px-4 pt-4">
                  <div className="flex items-center gap-3">
                    <div className="w-12 h-12 border border-[#D0D5DD] rounded bg-[#F2F3F3]">
                      <img
                        src={dishImg}
                        alt=""
                        className="w-fit h-fit object-cover"
                      />
                    </div>
                    <div className="">William William </div>
                  </div>
                  <input
                    type="radio"
                    className="accent-current w-[14px] h-[14px] border border-[#D0D5DD]"
                  />
                </div>
              </div>
              <div className="flex items-center justify-between text-[16px] leading-5 font-medium pr-4 py-[2px]">
                <button className="w-fit px-5 h-6 rounded-md drop-shadow-[0_1px_2px_0_rgba(16,24,40,0.05)] text-base-primary hover:text-base-primary_hover">
                  Cancel
                </button>
                <button className="w-[113px] h-[29px] rounded-md drop-shadow-[0_1px_2px_0_rgba(16,24,40,0.05)] bg-base-primary hover:bg-base-primary_hover text-[#FFFFFF]">
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-end">
          <AddSubmitButton text={t("Add Roaster")} />
        </div>
      </form>
    </div>
  );
};

export default CreateRoaster;
