export const ADD_BOOKING_REQUEST = "ADD_BOOKING_REQUEST";
export const ADD_BOOKING_SUCCESS = "ADD_BOOKING_SUCCESS";
export const ADD_BOOKING_FAIL = "ADD_BOOKING_FAIL";
export const ADD_BOOKING_RESET = "ADD_BOOKING_RESET";

export const UPDATE_BOOKING_REQUEST = "UPDATE_BOOKING_REQUEST";
export const UPDATE_BOOKING_SUCCESS = "UPDATE_BOOKING_SUCCESS";
export const UPDATE_BOOKING_FAIL = "UPDATE_BOOKING_FAIL";
export const UPDATE_BOOKING_RESET = "UPDATE_BOOKING_RESET";

export const DELETE_BOOKING_REQUEST = "DELETE_BOOKING_REQUEST";
export const DELETE_BOOKING_SUCCESS = "DELETE_BOOKING_SUCCESS";
export const DELETE_BOOKING_FAIL = "DELETE_BOOKING_FAIL";
export const DELETE_BOOKING_RESET = "DELETE_BOOKING_RESET";

export const GET_BOOKINGS_REQUEST = "GET_BOOKINGS_REQUEST";
export const GET_BOOKINGS_SUCCESS = "GET_BOOKINGS_SUCCESS";
export const GET_BOOKINGS_FAIL = "GET_BOOKINGS_FAIL";

//get booking by BOOKING STATUS
export const BOOKING_STATUS_REQUEST = "BOOKING_STATUS_REQUEST";
export const BOOKING_STATUS_SUCCESS = "BOOKING_STATUS_SUCCESS";
export const BOOKING_STATUS_FAIL = "BOOKING_STATUS_FAIL";

// BID -> booking id , get booking by BOOKING ID
export const BOOKING_BID_REQUEST = "BOOKING_BID_REQUEST";
export const BOOKING_BID_SUCCESS = "BOOKING_BID_SUCCESS";
export const BOOKING_BID_FAIL = "BOOKING_BID_FAIL";

//get booking by PHONE NO.
export const BOOKING_PHONE_REQUEST = "BOOKING_PHONE_REQUEST";
export const BOOKING_PHONE_SUCCESS = "BOOKING_PHONE_SUCCESS";
export const BOOKING_PHONE_FAIL = "BOOKING_PHONE_FAIL";

//get booking by CUSTOMER NAME
export const BOOKING_CNAME_REQUEST = "BOOKING_CNAME_REQUEST";
export const BOOKING_CNAME_SUCCESS = "BOOKING_CNAME_SUCCESS";
export const BOOKING_CNAME_FAIL = "BOOKING_CNAME_FAIL";
