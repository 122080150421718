export const GET_QUERY_REQUEST = "GET_QUERY_REQUEST";
export const GET_QUERY_SUCCESS = "GET_QUERY_SUCCESS";
export const GET_QUERY_FAIL = "GET_QUERY_FAIL";

export const ANS_QUERY_REQUEST = "ANS_QUERY_REQUEST";
export const ANS_QUERY_SUCCESS = "ANS_QUERY_SUCCESS";
export const ANS_QUERY_FAIL = "ANS_QUERY_FAIL";
export const ANS_QUERY_RESET = "ANS_QUERY_RESET";


