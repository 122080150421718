import React from "react";
import ToolTip from "../../components/ToolTip";

function AddBookingCheckBox({
  label,
  message,
  text,
  text2,
  valueYes,
  setValueYes,
  valueNo,
  setValueNo,
}) {
  return (
    <>
      <div>
        <div className="flex justify-between items-center">
          <label className="text-[14px] leading-6 font-medium text-[#344054]">
            {label}
          </label>
          <ToolTip Message={message} />
        </div>
        <div
          className={`w-fit relative flex justify-between items-center gap-6 py-1 h-10`}
        >
          <div className="flex items-center gap-2">
            <input
              type={"checkbox"}
              checked={valueYes}
              id={label}
              onChange={() => setValueYes(!valueYes)}
              value={valueYes}
              className={
                "border border-[#D0D5DD] accent-current h-[18px] w-[18px]"
              }
            />
            <label
              htmlFor={label}
              className={`text-[16px] leading-[20.16px] font-medium text-[#000000] capitalize`}
            >
              {text}
            </label>
          </div>
          {/* second */}
          <div className="flex items-center gap-2">
            <input
              type={"checkbox"}
              checked={valueNo}
              id={label}
              onChange={() => setValueNo(!valueNo)}
              value={valueNo}
              className={
                "border border-[#D0D5DD] accent-current h-[18px] w-[18px]"
              }
            />
            <label
              htmlFor={label}
              className={`text-[16px] leading-[20.16px] font-medium text-[#000000] capitalize`}
            >
              {text2}
            </label>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddBookingCheckBox;
