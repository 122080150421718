import React from "react";
import ToolTip from "../ToolTip";

const SelectFromArray = ({
  label,
  value,
  onChange,
  options,
  toolTipMessage,
  required = false,
}) => {
  // array will be like : ["VALUE_1", "VALUE_2"...."VALUE_3"];
  return (
    <>
      <div>
        <div className="flex justify-between">
          <label>{label}</label>
          <ToolTip Message={toolTipMessage} />
        </div>
        <div className="relative flex items-center">
          <select value={value} onChange={onChange} className="capitalize" required={required}>
            {options &&
              Array.isArray(options) &&
              options?.length &&
              options?.map((item, index) => (
                <option value={item} key={index} className="capitalize">
                  {item?.replace("_", " ").toLowerCase()}
                </option>
              ))}
          </select>
        </div>
      </div>
    </>
  );
};

export default SelectFromArray;
