import React, { useState } from "react";
import { PROPERTY_AMENITIES } from "../../../constants";

const NewPropertyAmenities = (props) => {
  // const [propertyAmenities, setPropertyAmenities] =
  //   useState([]);
  const [active, setActive] = useState(null);

  const handleAmenitie = (e, id) => {
    setActive(id);
  };

  return (
    <div className="w-full border border-[#D0D5DD] rounded-[10px] bg-white p-6">
      <h2 className="text-[30px] leading-[37.8px] tracking-[-1.5%] font-bold">
        Choose Your Property Amenities
      </h2>
      <div className="h-[62vh] overflow-y-auto srcoll-bar1 my-6 pt-4">
        {Object.keys(PROPERTY_AMENITIES)?.map((amenities, id) => {
          return (
            <button
              type="button"
              className={`relative w-fit h-10 border rounded text-[20px] font-normal hover:border-base-primary hover:bg-[#00a35e1a] ${
                active === id
                  ? "border-base-primary bg-[#00a35e1a]"
                  : "border-[#D0D5DD] bg-[#F6F6F6]"
              } px-4 mx-2 mb-3`}
              key={id}
              value={amenities}
              onClick={(e) => handleAmenitie(e, id)}
            >
              {amenities}
              {active === id ? (
                <div className="absolute -top-2 -right-2 h-[18.25px] w-[18.25px] border-2 border-base-primary rounded-full flex items-center justify-center p-[2px]">
                  <div className="h-full w-full rounded-full bg-base-primary"></div>
                </div>
              ) : null}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default NewPropertyAmenities;
