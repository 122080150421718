import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteOrNot from "../../components/layouts/DeleteOrNot";
import { registerEvent } from "../../logs";
import { deleteVendorProfileAction } from "../../actions/pms/vendorProfile";
import BackGroundLoadSpinner from "../../components/loader/BackGroundLoadSpinner";
import { useTranslation } from "react-i18next";
import {
  ID_NUMBER,
  ROUTES,
} from "../../constants";
import { useNavigate } from "react-router-dom";
import { notifyError, notifySuccess } from "../../components/alert";
import { CLEAR_ERRORS } from "../../actions/types";
import { DELETE_VENDOR_RESET } from "../../actions/types/vendor";
import { getAllVendorAction } from "../../actions/pms/vendor";
import DelIcon from "../../images/icons/DelIcon.svg";
import EditIcon from "../../images/icons/EidIcon.svg";
import CustomeTable from "../../components/CustomeTable";
import PrintButton from "../../components/buttons/PrintButton";
import UploadButton from "../../components/buttons/UploadButton";
import DownloadButton from "../../components/buttons/DownloadButton";

const AllVendor = ({ setVendor }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [datas, setDatas] = useState([]);
  const { vendors } = useSelector((state) => state.AllVendor);
  const { hotel } = useSelector((state) => state.Hotel);
  const [selectedRows, setSelectedRows] = useState([]);
  const navigate = useNavigate();
  const { loading, isdeleted, error } = useSelector(
    (state) => state.DeleteVendorProfile
  );
  const [deleteId, setDeleteId] = useState("");
  const [confirmDelete, setConfirmDelete] = useState(false);

  //get employees array from redux and set employees as datas
  useEffect(() => {
    if (vendors && Array.isArray(vendors) && vendors?.length) {
      let arr = [];

      //modify employee datas (filter )
      vendors.map((employee) => {
        arr.push({
          vendorId: employee?.vendorId,
          name: employee?.name,
          vendorName: employee?.vendorName,
          productType: employee?.productType,
          email: employee?.email,
          phone: employee?.phone,
          // address: employee?.address,
          _id: employee?.vendorId,
        });
      });
      setDatas(arr);
    }
  }, [vendors]);

  useEffect(() => {
    if (error) {
      notifyError(t("777"));
      dispatch({ type: CLEAR_ERRORS });
    }

    if (isdeleted) {
      dispatch(getAllVendorAction(hotel?._id));
      notifySuccess(t("778"));
      dispatch({ type: DELETE_VENDOR_RESET });
    }
  }, [isdeleted, error]);

  //set employee in session storage and redirect to next page
  const setEmployee = (id) => {
    registerEvent("redirect using link", "all employee page", { id });
    if (id) {
      const vendor = vendors?.filter((x) => x?.vendorId === id);
      setVendor(vendor[0]);
    }
  };

  //delete employee
  const openConfirmationModal = (id) => {
    if (id) {
      setDeleteId(id);
      setConfirmDelete(true);
      registerEvent("click on delete employee button", "all employee page", {
        id,
      });
    }
  };
  const deleteEmployee = () => {
    if (deleteId) dispatch(deleteVendorProfileAction(hotel?._id, deleteId));
  };
  const vendorEditBtn = (value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setEmployee(value);
  };

  // purchase order page
  const setValueRedirectPurchaseOrder = (id) => {
    if (id) {
      sessionStorage.setItem(ID_NUMBER, id);
      navigate(`${ROUTES.VENDOR_PURCHASE_ORDER}`);
    }
  };
  // view details page
  const setValueRedirectViewDetails = (id) => {
    if (id) {
      sessionStorage.setItem(ID_NUMBER, id);
      navigate(`${ROUTES.VENDOR}`);
    }
  };

  // history page
  const setValueRedirectHistory = (id) => {
    if (id) {
      sessionStorage.setItem(ID_NUMBER, id);
      navigate(`${ROUTES.VENDOR_HISTORY}`);
    }
  };

  const ViewVendorAction = () => {
    return (
      <>
        <div className="flex items-center gap-4 mt-[5px]">
          <button
            onClick={setValueRedirectPurchaseOrder}
            className="font-normal text-[14px] leading-6 tracking-[-0.6%] text-base-primary hover:text-base-primary_hover"
          >
            {t("Purchase Order")}
          </button>
          <button
            onClick={setValueRedirectViewDetails}
            className="font-normal text-[14px] leading-6 tracking-[-0.6%] text-base-primary hover:text-base-primary_hover"
          >
            {t("Details")}
          </button>
          <button
            onClick={setValueRedirectHistory}
            className="font-normal text-[14px] leading-6 tracking-[-0.6%] text-base-primary hover:text-base-primary_hover"
          >
            {t("History")}
          </button>
          <button
            className="w-[30px] h-[30px] border border-[#E5E9EB] bg-[#FFFFFF] rounded-full flex items-center justify-center hover:bg-[#E5E9EB]"
            onClick={openConfirmationModal}
          >
            <img src={DelIcon} alt="" />
          </button>
          <button
            className="w-[30px] h-[30px] border border-[#E5E9EB] bg-[#FFFFFF] rounded-full flex items-center justify-center hover:bg-[#E5E9EB]"
            onClick={vendorEditBtn}
          >
            <img src={EditIcon} alt="" />
          </button>
        </div>
      </>
    );
  };

  const columnDefs = [
    {
      headerName: "Vendor ID",
      field: "vendorId",
      maxWidth: 170,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Name",
      field: "name",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Vendor Name",
      field: "vendorName",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Product Type",
      field: "productType",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Email",
      field: "email",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Phone",
      field: "phone",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Actions",
      field: "_id",
      minWidth: 335,
      cellRenderer: ViewVendorAction,
    },
  ];
  return (
    <>  <div className="flex items-center justify-between my-6">
      <div className="text-[22px] leading-[27.72px] text-black font-bold">
        Vendor Table
      </div>
      <div className="flex items-center gap-4">
        <PrintButton />
        <UploadButton />
        <DownloadButton />
      </div>
    </div>
      <div>
        {datas?.length ? (
          <CustomeTable
            columnDefs={columnDefs}
            rowData={datas}
            setSelectedRows={setSelectedRows}
          />
        ) : null}
      </div>
      {confirmDelete ? (
        <DeleteOrNot
          formSubmit={deleteEmployee}
          open={confirmDelete}
          close={() => setConfirmDelete(false)}
        />
      ) : null}
      {loading ? <BackGroundLoadSpinner /> : null}
    </>
  );
};

export default AllVendor;
