import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CustomeTable } from "../../components";

const AllEmployees = () => {
  const [datas, setDatas] = useState([]);
  const { employees } = useSelector((state) => state.AllEmployee);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isChecked, setIsChecked] = useState(false);

  //get employees array from redux and set employees as datas
  useEffect(() => {
    if (employees && Array.isArray(employees) && employees?.length) {
      let arr = [];

      //modify employee datas (filter )
      employees.map((employee) => {
        return arr.push({
          employeeId: employee?.employeeId,
          // designation: employee?.designation,
          allHotels: employee?.allHotels,
          reservations: employee?.reservations,
          customers: employee?.customers,
          houseKeeping: employee?.houseKeeping,
          rooms: employee?.rooms,
          booking: employee?.booking,
          kitchen: employee?.kitchen,
          employee: employee?.employee,
          expense: employee?.expense,
          foodOrders: employee?.FoodOrders,
          reports: employee?.reports,
          // _id: employee?.employeeId,
        });
      });
    }
  }, [employees]);

  const rowDatas = [
    {
      _id: "01",
      allHotels: "All Access",
      reservations: "Micro Access",
      customers: "Micro Access",
      houseKeeping: "Micro Access",
      rooms: "Micro Access",
      booking: "Micro Access",
      kitchen: "Micro Access",
      employee: "Micro Access",
      expense: "Micro Access",
      foodOrder: "Micro Access",
      reports: "Micro Access",
    },
    {
      _id: "02",
      allHotels: "All Access",
      reservations: "Micro Access",
      customers: "Micro Access",
      houseKeeping: "Micro Access",
      rooms: "Micro Access",
      booking: "Micro Access",
      kitchen: "Micro Access",
      employee: "Micro Access",
      expense: "Micro Access",
      foodOrder: "Micro Access",
      reports: "Micro Access",
    },
    {
      _id: "03",
      allHotels: "All Access",
      reservations: "Micro Access",
      customers: "Micro Access",
      houseKeeping: "Micro Access",
      rooms: "Micro Access",
      booking: "Micro Access",
      kitchen: "Micro Access",
      employee: "Micro Access",
      expense: "Micro Access",
      foodOrder: "Micro Access",
      reports: "Micro Access",
    },
    {
      _id: "04",
      allHotels: "All Access",
      reservations: "Micro Access",
      customers: "Micro Access",
      houseKeeping: "Micro Access",
      rooms: "Micro Access",
      booking: "Micro Access",
      kitchen: "Micro Access",
      employee: "Micro Access",
      expense: "Micro Access",
      foodOrder: "Micro Access",
      reports: "Micro Access",
    },
    {
      _id: "05",
      allHotels: "All Access",
      reservations: "Micro Access",
      customers: "Micro Access",
      houseKeeping: "Micro Access",
      rooms: "Micro Access",
      booking: "Micro Access",
      kitchen: "Micro Access",
      employee: "Micro Access",
      expense: "Micro Access",
      foodOrder: "Micro Access",
      reports: "Micro Access",
    },
    {
      _id: "06",
      allHotels: "All Access",
      reservations: "Micro Access",
      customers: "Micro Access",
      houseKeeping: "Micro Access",
      rooms: "Micro Access",
      booking: "Micro Access",
      kitchen: "Micro Access",
      employee: "Micro Access",
      expense: "Micro Access",
      foodOrder: "Micro Access",
      reports: "Micro Access",
    },
    {
      _id: "07",
      allHotels: "All Access",
      reservations: "Micro Access",
      customers: "Micro Access",
      houseKeeping: "Micro Access",
      rooms: "Micro Access",
      booking: "Micro Access",
      kitchen: "Micro Access",
      employee: "Micro Access",
      expense: "Micro Access",
      foodOrder: "Micro Access",
      reports: "Micro Access",
    },
  ];

  const ViewSelectChecked = ({ value }) => {
    const handleValue = (e) => {
      setIsChecked(e.target.checked?._id);
      if (isChecked && e._id) {
        return value;
      }
    };
    return (
      <>
        <div className="flex items-center gap-4">
          <input
            type="checkbox"
            checked={isChecked?._id}
            onChange={handleValue}
            className="h-[15.63px] w-4 border border-[#D0D5DD] rounded accent-current"
          />
          <h2>{value}</h2>
        </div>
      </>
    );
  };

  const columnDefs = [
    {
      headerName: "All Hotels",
      field: "allHotels",
      minWidth: 150,
      maxWidth: 200,
      cellRenderer: ViewSelectChecked,
    },
    {
      headerName: "Reservations",
      field: "reservations",
      minWidth: 150,
      maxWidth: 200,
      cellRenderer: ViewSelectChecked,
    },
    {
      headerName: "Customers",
      field: "customers",
      minWidth: 150,
      maxWidth: 200,
      cellRenderer: ViewSelectChecked,
    },
    {
      headerName: "House Keeping",
      field: "houseKeeping",
      minWidth: 150,
      maxWidth: 200,
      cellRenderer: ViewSelectChecked,
    },
    {
      headerName: "Rooms",
      field: "rooms",
      minWidth: 150,
      maxWidth: 200,
      cellRenderer: ViewSelectChecked,
    },
    {
      headerName: "Booking",
      field: "booking",
      minWidth: 150,
      maxWidth: 200,
      cellRenderer: ViewSelectChecked,
    },
    {
      headerName: "Kitchen",
      field: "kitchen",
      minWidth: 150,
      maxWidth: 200,
      cellRenderer: ViewSelectChecked,
    },
    {
      headerName: "Employee",
      field: "employee",
      minWidth: 150,
      maxWidth: 200,
      cellRenderer: ViewSelectChecked,
    },
    {
      headerName: "Expense",
      field: "expense",
      minWidth: 150,
      maxWidth: 200,
      cellRenderer: ViewSelectChecked,
    },
    {
      headerName: "Food Order",
      field: "foodOrder",
      minWidth: 150,
      maxWidth: 200,
      cellRenderer: ViewSelectChecked,
    },
    {
      headerName: "Reports",
      field: "reports",
      minWidth: 150,
      maxWidth: 200,
      cellRenderer: ViewSelectChecked,
    },
  ];
  return (
    <>
      {/* {datas.length ? ( */}
      <CustomeTable
        columnDefs={columnDefs}
        rowData={rowDatas}
        setSelectedRows={setSelectedRows}
      />
      {/* ) : null} */}
    </>
  );
};

export default AllEmployees;
