import React from "react";
import ToolTip from "../../components/ToolTip";
import { useTranslation } from "react-i18next";

const RoomTypeBeds = ({ options, value, setValue, index }) => {
  const { t } = useTranslation();
  //onchange hanlder
  const onchangehandler = (e) => {
    const val = e.target.value;
    value[index].bedNumber = index + 1;
    value[index].details = val;
    setValue(value);
  };

  return (
    <>
      <div>
        <div className="flex justify-between">
          <label className="text-sm font-semibold text-[#344054]">
            {t("103")} {index + 1}
          </label>
          <ToolTip Message={t("104")} />
        </div>
        <div className="relative flex items-center">
          <select onChange={onchangehandler} value={value[index]?.details}>
            {Object.keys(options)?.map((v) => (
              <option value={v} key={v}>
                {options[v]}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
};

export default RoomTypeBeds;
