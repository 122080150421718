export const GET_CURRENT_STOCK_REQUEST = "GET_CURRENT_STOCK_REQUEST";
export const GET_CURRENT_STOCK_SUCCESS = "GET_CURRENT_STOCK_SUCCESS";
export const GET_CURRENT_STOCK_FAIL = "GET_CURRENT_STOCK_FAIL";

export const GET_STOCK_REQUEST = "GET_STOCK_REQUEST";
export const GET_STOCK_SUCCESS = "GET_STOCK_SUCCESS";
export const GET_STOCK_FAIL = "GET_STOCK_FAIL";

export const UPDATE_CURRENT_STOCK_REQUEST = "UPDATE_CURRENT_STOCK_REQUEST";
export const UPDATE_CURRENT_STOCK_SUCCESS = "UPDATE_CURRENT_STOCK_SUCCESS";
export const UPDATE_CURRENT_STOCK_FAIL = "UPDATE_CURRENT_STOCK_FAIL";
export const UPDATE_CURRENT_STOCK_RESET = "UPDATE_CURRENT_STOCK_RESET";
