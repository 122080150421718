import { CLEAR_ERRORS } from "../../actions/types";
import {
  GET_MENU_FAIL,
  GET_MENU_REQUEST,
  GET_MENU_SUCCESS,
} from "../../actions/types/food";

//get food menu reducer
export const getFoodMenuReducer = (state = { menu: [] }, action) => {
  switch (action.type) {
    case GET_MENU_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_MENU_SUCCESS:
      return {
        ...state,
        loading: false,
        menu: action.payload,
      };
    case GET_MENU_FAIL:
      return {
        ...state,
        loading: false,
        menu: [],
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
