/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageLoader from "../../components/loader/PageLoader";
import { registerEvent } from "../../logs";
import { isObjEmpty } from "../../utils";
import AddVendor from "./AddVendor";
import AllVendor from "./AllVendor";
import { getAllVendorAction } from "../../actions/pms/vendor";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Seperator from "../../components/layouts/Seperator";
import AddButton from "../../components/buttons/AddButton";

const VendorPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [vendor, setVendor] = useState(null);
  const { hotel } = useSelector((state) => state.Hotel);
  const { loading, vendors } = useSelector((state) => state.AllVendor);
  const addEmployeeModal = () => {
    setOpen(true);
    registerEvent("click add vendor button", "all vendor page");
  };

  //close add employee modal
  const close = () => {
    setVendor(null);
    setOpen(false);
    registerEvent("close add vendor component", "add vendor page");
  };

  useEffect(() => {
    if (hotel?._id) dispatch(getAllVendorAction(hotel?._id));
  }, []);

  const headingData = {
    heading: t("233"),
    seperator: [
      {
        text: t("241"),
      },
      {
        text: t("233"),
      },
    ],
  };

  return (
    <>
      {loading && !vendors?.length ? (
        <PageLoader />
      ) : (
        <div className="page-container max-w-7xl">
          <div className="flex justify-between items-end">
            <Seperator data={headingData} />
            <div className="flex gap-2">
              <AddButton text={t("201")} onClick={addEmployeeModal} />
            </div>
          </div>
          {open || !isObjEmpty(vendor) ? (
            <div className="mt-6">
              <AddVendor close={close} hotel={hotel} vendor={vendor} />
            </div>
          ) : null}
          <AllVendor hotel={hotel} setVendor={setVendor} />
        </div>
      )}
    </>
  );
};
export default VendorPage;
