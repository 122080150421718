import React from "react";
import { useState, useEffect } from "react";
import PageLoader from "../../../components/loader/PageLoader";
import { updateHotelAction } from "../../../actions/pms/hotel";
import { connect } from "react-redux";
import { notifySuccess } from "../../../components/alert";
import BackGroundLoadSpinner from "../../../components/loader/BackGroundLoadSpinner";
import { registerEvent } from "../../../logs";
import { useTranslation } from "react-i18next";
import InputTextArea from "../../../components/layouts/InputTextArea";
import { UPDATE_HOTEL_RESET } from "../../../actions/types/hotel";
import { useNavigate } from "react-router-dom";
import { ONBOARDING_STAGES, ROUTES } from "../../../constants";
import SkipButton from "../../../components/buttons/SkipButton";
import AddSubmitButton from "../../../components/buttons/AddSubmitButton";

const PropertyInfo = (props) => {
  const { t } = useTranslation();
  const [tagline, setTagline] = useState("");
  const [description, setDescription] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [propertyDetails, setPropertyDetails] = useState("");
  const [fullDescription, setFullDescription] = useState("");
  const [termsAndConditions, setTermsAndConditions] = useState("");
  const [covidInformation, setCovidInformation] = useState("");
  const navigate = useNavigate();

  const [clicked, setClicked] = useState(false);
  const [isFullData, setIsFullData] = useState(false);

  useEffect(() => {
    if (props?.hotel?._id) {
      setTagline(props?.hotel?.tagline);
      setDescription(props?.hotel?.description);
      setPropertyDetails(props?.hotel?.propertyDetails);
      setStreetAddress(props?.hotel?.propertyAddress?.streetAddress);
      setCovidInformation(props?.hotel?.covidInformation);
      setFullDescription(props?.hotel?.fullDescription);
      setTermsAndConditions(props?.hotel?.termsAndConditions);
      setIsFullData(true);
    }
  }, [props?.hotel]);

  //submit the form
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      hotelId: props?.hotel?._id,
      tagline,
      description,
      streetAddress,
      propertyAddress: {
        streetAddress,
      },
      fullDescription,
      termsAndConditions,
      covidInformation,
      stage: ONBOARDING_STAGES.DASHBOARD,
    };

    setClicked(true);
    await props.updateHotel(formData);
    registerEvent("UPDATE PROPERTY DETAILS", "PROPERTY DETAILS", formData);
  };

  //show updatation alears
  useEffect(() => {
    if (
      props?.data &&
      props?.data?.isupdated &&
      props?.data?.hotel &&
      clicked
    ) {
      props.reset();
      notifySuccess(t("706"));
      setClicked(false);
      navigate(`${ROUTES.HOTEL_CONFIG}/3`);
    }
  }, [props?.data]);

  //clear error
  useEffect(() => {
    if (props?.data && props?.data?.error && clicked) {
      setClicked(false);
    }
  }, [props.data]);

  const skipTheTab = () => navigate(`${ROUTES.HOTEL_CONFIG}/3`);

  return (
    <>
      {props?.data && props?.data?.loading && clicked && (
        <BackGroundLoadSpinner />
      )}
      {isFullData ? (
        <>
          <div className="w-full max-w-full border border-[#E4E4E7] rounded-lg bg-white overflow-hidden p-8">
            <h2 className="text-[30px] leading-[37.8px] text-[#07090D] font-bold tracking-[-1.5%]">
              {t("707")}
            </h2>
            <form className="w-full mt-4" onSubmit={handleSubmit}>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <InputTextArea
                  value={tagline}
                  onChange={(e) => setTagline(e?.target?.value)}
                  label={t("708")}
                  message={t("709")}
                  placeholder={t("710")}
                  utility={"h-12"}
                />
                <InputTextArea
                  value={streetAddress}
                  onChange={(e) => setStreetAddress(e?.target?.value)}
                  label={`${t("711")} *`}
                  message={t("712")}
                  placeholder={t("713")}
                  utility={"h-12"}
                  required={true}
                />
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-1">
                <InputTextArea
                  value={description}
                  onChange={(e) => setDescription(e?.target?.value)}
                  label={t("714")}
                  message={t("715")}
                  placeholder={t("716")}
                  utility={"h-[105px] placeholder:text-base-primary"}
                />
                <InputTextArea
                  value={propertyDetails}
                  onChange={(e) => setPropertyDetails(e?.target?.value)}
                  label={t("717")}
                  message={t("718")}
                  placeholder={t("Enter detailse here..")}
                  utility={"h-[105px] placeholder:text-base-primary"}
                />
              </div>

              <div className="grid grid-cols-1 gap-1 mt-1">
                <InputTextArea
                  value={fullDescription}
                  onChange={(e) => setFullDescription(e?.target?.value)}
                  label={t("720")}
                  message={t("721")}
                  placeholder={t("722")}
                  utility={"h-[131px] placeholder:text-base-primary"}
                />
                <InputTextArea
                  value={termsAndConditions}
                  onChange={(e) => setTermsAndConditions(e?.target?.value)}
                  label={t("723")}
                  message={t("724")}
                  placeholder={t("725")}
                  utility={"h-[131px] placeholder:text-base-primary"}
                />
                <InputTextArea
                  value={covidInformation}
                  onChange={(e) => setCovidInformation(e?.target?.value)}
                  label={t("726")}
                  message={t("727")}
                  placeholder={t("728")}
                  utility={"h-[131px] placeholder:text-base-primary"}
                />
              </div>
              <div className="w-full flex justify-end items-center gap-3 mt-4">
                <SkipButton text={t("260")} onClick={skipTheTab} />
                <AddSubmitButton
                  text={t("65")}
                  width={88}
                  disabled={props?.data && props?.data?.loading}
                />
              </div>
            </form>
          </div>
        </>
      ) : (
        <PageLoader />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  data: state.UpdateHotel,
});

const mapDispatchToProps = (dispatch) => ({
  updateHotel: (data) => dispatch(updateHotelAction(data)),
  reset: () => dispatch({ type: UPDATE_HOTEL_RESET }),
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertyInfo);
