import { CLEAR_ERRORS } from "../../actions/types";
import {
  ADD_BOOKING_SOURCE_FAIL,
  ADD_BOOKING_SOURCE_REQ,
  ADD_BOOKING_SOURCE_RESET,
  ADD_BOOKING_SOURCE_SUC,
  GET_BOOKING_SOURCE_FAIL,
  GET_BOOKING_SOURCE_REQ,
  GET_BOOKING_SOURCE_SUC,
} from "../../actions/types/bookinSource";

//add hotel
export const addBookingSourceReducer = (state = { source: {} }, action) => {
  switch (action.type) {
    case ADD_BOOKING_SOURCE_REQ:
      return {
        ...state,
        loading: true,
        isadded: false,
      };
    case ADD_BOOKING_SOURCE_SUC:
      return {
        ...state,
        loading: false,
        source: action.payload,
        isadded: true,
      };
    case ADD_BOOKING_SOURCE_FAIL:
      return {
        ...state,
        loading: false,
        isadded: false,
        error: action.payload,
      };
    case ADD_BOOKING_SOURCE_RESET:
      return {
        ...state,
        loading: false,
        isadded: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

//get hotel
export const getBookingSourceReducer = (state = { source: {} }, action) => {
  switch (action.type) {
    case GET_BOOKING_SOURCE_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_BOOKING_SOURCE_SUC:
      return {
        ...state,
        loading: false,
        source: action.payload,
      };
    case GET_BOOKING_SOURCE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
