import axios from "axios";
import { TOKEN } from "../../constants";
import {
  GET_MENU_FAIL,
  GET_MENU_REQUEST,
  GET_MENU_SUCCESS,
} from "../types/food";

//get food menu
export const getFoodMenuAction = (hotelId) => async (dispatch) => {
  if(!hotelId) return;
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/employee/kitchen/menu?hotelId=${hotelId}`;
  try {
    dispatch({ type: GET_MENU_REQUEST });
    const data = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: GET_MENU_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_MENU_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};
