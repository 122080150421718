import {
  FORGOT_PASSWORD_FAIL,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_RESET,
  VERIFY_OTP_REQUEST,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAIL,
  VERIFY_OTP_RESET,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_RESET,
} from "../../actions/types/forgotPassword";
import { CLEAR_ERRORS } from "../../actions/types";

//forgot passowrd reducer
export const forgotPasswordReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        isforgoted: false,
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        isforgoted: true,
      };
    case FORGOT_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isforgoted: false,
      };
    case FORGOT_PASSWORD_RESET:
      return {
        ...state,
        loading: false,
        isforgoted: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// match otp reducer
export const matchOtpReducer = (state = { token: "" }, action) => {
  switch (action.type) {
    case VERIFY_OTP_REQUEST:
      return {
        ...state,
        loading: true,
        isverified: false,
      };
    case VERIFY_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        token: action.payload,
        isverified: true,
      };
    case VERIFY_OTP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isverified: false,
      };
    case VERIFY_OTP_RESET:
      return {
        ...state,
        loading: false,
        isverified: false,
        token:null
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

//reset passowrd reducer
export const resetPasswordReducer = (state = {token:{}}, action) => {
  switch (action.type) {
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        isreset: false,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        isreset: true,
        token:action.payload,
      };
    case RESET_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        isreset: false,
        error: action.payload,
      };
    case RESET_PASSWORD_RESET:
      return {
        ...state,
        loading: false,
        isreset: false,
        token:null
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
