import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { cancelFoodOrderAction } from "../../actions/pms/foodOrder";
import { clearError } from "../../actions/clearError";
import { notifySuccess } from "../../components/alert";
import { CANCEL_FOOD_ORDER_RESET } from "../../actions/types/food";
import DeleteOrNot from "../../components/layouts/DeleteOrNot";
import BackGroundLoadSpinner from "../../components/loader/BackGroundLoadSpinner";
import { useTranslation } from "react-i18next"; 

const FoodOrders = (props) => {
  const { t } = useTranslation();
  const [datas, setDatas] = useState([]);
  const { hotel } = useSelector((state) => state.Hotel);
  const [cancelId, setCancelId] = useState("");
  const [open, setOpen] = useState(false);
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const modifyTheData = async (orders) => {
    let arr = [];
    // eslint-disable-next-line array-callback-return
    await orders?.map((element) => {
      delete element?.food;
      if (element?.status === "IN_QUEUE")
        arr.push({
          foodOrderId: element?.foodOrderId,
          date: element?.date,
          customerName: element?.customerName,
          orderFor: element?.orderFor,
          contactNumber: element?.contactNumber,
          isBillSign: element?.isBillSign,
          isPaid: element?.isPaid,
          status: element?.status,
          totalPrice: element?.totalPrice,
          _id: element?._id,
        });
    });
    if (arr?.length) {
      setDatas(arr);
    } else {
      setDatas([
        {
          foodOrderId: null,
          date: null,
          customerName: null,
          orderFor: null,
          contactNumber: null,
          isBillSign: null,
          isPaid: null,
          status: null,
          totalPrice: null,
          _id: null,
        },
      ]);
    }
  };
  useEffect(() => {
    if (
      props?.allOrders &&
      props?.allOrders?.orders &&
      Array.isArray(props?.allOrders?.orders)
    ) {
      modifyTheData(props?.allOrders?.orders);
    }
  }, [props?.allOrders]);

  //cancel the food order
  const cancelTheOrder = async (id) => {
    setCancelId(id);
    setOpen(true);
  };

  const deleteConfirmed = async () => {
    props.cancelOrder(cancelId, hotel?._id);
  };

  //show cancel message if cancallation done successfully. and remove that item
  const removeItem = async () => {
    const reslut = datas?.filter((item) => item?._id !== cancelId);
    setDatas(reslut);
    notifySuccess(t("811"));
    await props.reset();
  };
  useEffect(() => {
    if (props?.cancelInfo && props?.cancelInfo?.iscanceled) {
      removeItem();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.cancelInfo]);

  const columnDatas = {
    foodOrderId: t("Food Order ID"),
    date: t("244"),
    customerName: t("Customer Name"),
    orderFor: t("Order For"),
    contactNumber: t("Contact Number"),
    isBillSign: t("812"),
    isPaid: t("813"),
    status: t("806"),
    totalPrice: t("814"),
  };
  const tableData = [
    {
      foodOrderId: "F001",
      date: "2024-01-13",
      customerName: "Alex Hales",
      orderFor: "Room Service",
      contactNumber: "+91234214535",
      isBillSign: "Yes",
      isPaid: "No",
      status: "InQueue",
      totalPrice: "1212",
    },
    {
      foodOrderId: "F002",
      date: "2024-01-13",
      customerName: "Salman",
      orderFor: "Table",
      contactNumber: "+91234214535",
      isBillSign: "Yes",
      isPaid: "No",
      status: "Delivered",
      totalPrice: "750",
    },
  ]; 

  return (
    <>
      <div className="mt-6">
       
      </div>
      {open ? (
        <DeleteOrNot
          open={open}
          close={() => setOpen(false)}
          formSubmit={deleteConfirmed}
        />
      ) : null}
      {props?.cancelInfo && props?.cancelInfo?.loading ? (
        <BackGroundLoadSpinner />
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => ({
  allOrders: state.AllFoodOrder,
  cancelInfo: state.CancelFoodOrder,
});

const mapDispatchToProps = (dispatch) => ({
  cancelOrder: (orderId, hotelId) =>
    dispatch(cancelFoodOrderAction(orderId, hotelId)),
  clear: () => dispatch(clearError()),
  reset: () => dispatch({ type: CANCEL_FOOD_ORDER_RESET }),
});

export default connect(mapStateToProps, mapDispatchToProps)(FoodOrders);
