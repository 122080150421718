import { IS_USER_ADMIN } from "../types";

//set isuser admin
export const isAdminAction = (value) => async (dispatch) => {
  try {
    dispatch({
      type: IS_USER_ADMIN,
      payload: value,
    });
  } catch (error) {
    //console.log(error)
  }
};
