import React, { useState } from "react";
import Seperator from "../../components/layouts/Seperator";
import { useTranslation } from "react-i18next";
import AddButton from "../../components/buttons/AddButton";
import Editpen from "../../images/icons/Editpen.svg";
import DelIcon from "../../images/icons/DelIcon.svg";
import { registerEvent } from "../../logs";
import { isObjEmpty } from "../../utils";
import CreateRoaster from "./CreateRoaster";
import AllCreateRoaster from "./AllCreateRoaster";

const RoasterManagement = () => {
  const { t } = useTranslation();
  const [addButton, setAddButton] = useState(false);
  const [createRoaster, setCreateRoaster] = useState({});
  //register event for Add inventory
  const registerEventAndOpen = () => {
    setAddButton(true);
    registerEvent("add roaster button open", "add item roaster", {
      addButton,
    });
  };
  // register evnet on close add inventry component
  const close = () => {
    if (!isObjEmpty(createRoaster)) setCreateRoaster(null);
    setAddButton(false);
    registerEvent("add roaster button close", "add item roaster", {
      addButton,
    });
  };
  const headingData = {
    heading: t("Create Roaster"),
    // seperator: [
    //   {
    //     text: t("134"),
    //   },
    //   {
    //     text: t("198"),
    //   },
    // ],
  };
  return (
    <>
      <div className="page-container max-w-full">
        <div className="w-full flex justify-between items-end max-w-7xl mx-auto">
          <Seperator data={headingData} />
          <div className="flex gap-2">
            {/* edit button */}
            <button className="flex items-center justify-center gap-3 h-10 w-[86px] border border-[#DDE2E4] rounded-md bg-[#FFFFFF] hover:bg-slate-100">
              <img src={Editpen} alt="" />
              <div className="text-[14px] leading-6 font-normal tracking-[-0.6%] text-[#252C32]">
                Edit
              </div>
            </button>
            {/* delete button */}
            <button className="flex items-center justify-center gap-3 h-10 w-[103px] border border-[#DDE2E4] rounded-md bg-[#FFFFFF] hover:bg-slate-100">
              <img src={DelIcon} alt="" />
              <div className="text-[14px] leading-6 font-normal tracking-[-0.6%] text-[#252C32]">
                Delete
              </div>
            </button>
            <AddButton text={t("Add Roaster")} onClick={registerEventAndOpen} />
          </div>
        </div>
        <div>
          {!isObjEmpty(createRoaster) || addButton ? (
            <CreateRoaster close={close} />
          ) : null}
        </div>
        {/* All Create Roaster */}
        <div className="mt-4">
          <AllCreateRoaster />
        </div>
      </div>
    </>
  );
};
export default RoasterManagement;
