import React, { useEffect, useState } from "react";
import { isObjEmpty } from "../../utils";
import DelIcon from "../../images/icons/DelIcon.svg";
import EditIcon from "../../images/icons/EidIcon.svg";
import { CustomeTable } from "../../components";
import UploadButton from "../../components/buttons/UploadButton";
import DownloadButton from "../../components/buttons/DownloadButton";
const AllCreateBuffet = (props) => {
  const [datas, setDatas] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  useEffect(() => {
    if (props?.kitchen && !isObjEmpty(props?.kitchen?.kitchen)) {
      setDatas([
        {
          //   Kitchen_id: "1214",
          itemId: props?.kitchen?.kitchen?.itemId,
          itemName: props?.kitchen?.kitchen?.itemName,
          itemType: props?.kitchen?.kitchen?.itemType,
          non_veg: props?.kitchen?.kitchen?.non_veg,
          ave_cost_per_kg: props?.kitchen?.kitchen?.ave_cost_per_kg,
          ave_cost_per_gm: props?.kitchen?.kitchen?.ave_cost_per_gm,
          quantity_per_10: props?.kitchen?.kitchen?.quantity_per_10,
          available: props?.kitchen?.kitchen?.available,
          _id: props?.kitchen?.kitchen?._id,
        },
      ]);
    }
  }, [props?.kitchen]);
  //set kitchen data and redirect to another page
  // const setKitchenAndRedirect = (link) => {
  //     if (link) {
  //       navigate(link);
  //     }
  //   };
  const tableData = [
    {
      itemId: "IT01",
      itemName: "Chicken Wings",
      itemType: "Appetizer",
      non_veg: "Yes",
      ave_cost_per_kg: "$ 100",
      ave_cost_per_gm: "$ 0.01",
      quantity_per_10: "4/10",
      available: "Regular",
    },
    {
      itemId: "IT02",
      itemName: "Chicken Wings",
      itemType: "Main Course",
      non_veg: "Yes",
      ave_cost_per_kg: "$ 200",
      ave_cost_per_gm: "$ 0.05",
      quantity_per_10: "10/10",
      available: "Regular",
    },
    {
      itemId: "IT03",
      itemName: "Caesar Salad",
      itemType: "Salad",
      non_veg: "No",
      ave_cost_per_kg: "$ 200",
      ave_cost_per_gm: "$ 0.05",
      quantity_per_10: "6/10",
      available: "Weekdays",
    },
    {
      itemId: "IT04",
      itemName: "Beef Steak",
      itemType: "Main Dish",
      non_veg: "Yes",
      ave_cost_per_kg: "$ 300",
      ave_cost_per_gm: "$ 0.15",
      quantity_per_10: "1/10",
      available: "Weekend",
    },
    {
      itemId: "IT05",
      itemName: "Kheer",
      itemType: "Dessert",
      non_veg: "Yes",
      ave_cost_per_kg: "$ 600",
      ave_cost_per_gm: "$ 0.18",
      quantity_per_10: "5/10",
      available: "Regular",
    },
  ];
  const ViewCreateBuffetAction = () => {
    return (
      <>
        <div className="grid grid-cols-2 gap-2 mt-[6px]">
          <button className="w-[30px] h-[30px] border border-[#E5E9EB] bg-[#FFFFFF] rounded-full flex items-center justify-center hover:bg-[#E5E9EB]">
            <img src={DelIcon} alt="" />
          </button>
          <button className="w-[30px] h-[30px] border border-[#E5E9EB] bg-[#FFFFFF] rounded-full flex items-center justify-center hover:bg-[#E5E9EB]">
            <img src={EditIcon} alt="" />
          </button>
        </div>
      </>
    );
  };
  const columnDefs = [
    {
      headerName: "Item ID",
      field: "itemId",
      maxWidth: 120,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Item Name",
      field: "itemName",
      maxWidth: 150,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Item Type",
      field: "itemType",
      maxWidth: 150,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Non-Veg",
      field: "non_veg",
      maxWidth: 150,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Ave-Cost/Kg",
      field: "ave_cost_per_kg",
      maxWidth: 150,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Ave-Cost/Gm",
      field: "ave_cost_per_gm",
      maxWidth: 150,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Quantity/10",
      field: "quantity_per_10",
      maxWidth: 150,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Available",
      field: "available",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Actions",
      field: "_id",
      maxWidth: 200,
      cellRenderer: ViewCreateBuffetAction,
    },
  ];

  return (
    <div>
      <div className="flex items-center justify-between mb-4">
        <div className="text-[22px] leading-[27.72px] text-black font-bold">
          Create Buffet Table
        </div>
        <div className="flex items-center gap-4">
          <UploadButton />
          <DownloadButton />
        </div>
      </div>
      {datas ? (
        <CustomeTable
          columnDefs={columnDefs}
          rowData={tableData}
          setSelectedRows={setSelectedRows}
        />
      ) : null}
    </div>
  );
};

export default AllCreateBuffet;
