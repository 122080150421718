import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { CiFilter } from "react-icons/ci";

const GridHeader = ({ dates, month, setMonth }) => {
  const [filter, setFilter] = useState(true)

  const { t } = useTranslation();

  return (
    <>
      <div className="flex">
        <div>
          <div className="w-[177px] h-[39px] bg-base-primary flex items-center justify-center border-r border-b border-[#D0D5DD] text-[16px] leading-[20.16px] font-medium text-[#FFFFFF] tracking-[-0.8px]">
            {t("640")}
          </div>
          <div className="w-[177px] h-[54px] flex items-center overflow-y-auto overflow-hidden pl-6">
            {/* filter */}
            <button className="gap-2 w-[117px] h-[34px] border rounded-[5px] bg-white flex items-center px-4" onClick={() => setFilter(!filter)}>
              <span className="tracking-[-0.6%] text-[14px] leading-[24px] font-normal text-[#344054] capitalize">
                {t("1014")}
              </span>
              <span className="border-2 border-base-primary rounded-lg p-[2px] text-base-primary">
                <CiFilter size={13} />
              </span>
              <span className="h-[19px] w-[19px] min-w-[19px] rounded-full flex justify-center items-center text-white text-[11px] leading-5 font-medium bg-base-primary hover:bg-base-primary_hover">3</span>
            </button>
          </div>
        </div>
        <button onClick={() => setMonth(month - 1)} className="w-12 min-w-12 h-[93px] flex items-center justify-center bg-[#F6F6F6]"><IoIosArrowBack size={34} /></button>
        {dates?.map((i, index) => (
          <div
            className="w-[60px] min-w-[60px] h-[93px] border-r last:border-r-0 border-b border-[#D0D5DD] flex justify-center items-center px-2 flex-col bg-base-primary text-white"
            key={index}
          >
            <div className="font-semibold text-xl">
              {i?.toString()?.slice(8, 10)}
            </div>
            <div className="font-normal"> {i?.toString()?.slice(4, 7)}</div>
            <div className="font-normal"> {i?.toString()?.slice(0, 3)}</div>
          </div>
        ))}
        {/* right side button */}
        <button onClick={() => setMonth(month + 1)} className="w-12 h-[93px] flex items-center justify-center bg-[#F6F6F6]"><IoIosArrowForward size={34} /></button>
      </div>
    </>
  );
};

export default GridHeader;
