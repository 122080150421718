import React, { useEffect, useState } from "react";
import PlaceOrder from "./PlaceOrder";
import { useNavigate } from "react-router-dom";
import OrderSummaryItemCart from "./OrderSummaryItemCart";
import { connect, useSelector } from "react-redux";
import { CLEAR_CART } from "../../actions/types/food";
import { useTranslation } from "react-i18next";
import Seperator from "../../components/layouts/Seperator";
import { ROUTES } from "../../constants";
import Xsquare from "../../images/icons/Xsquare.svg";
import { FiArrowLeft } from "react-icons/fi";

const OrderSummary = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [cartItems, setCartItems] = useState([]);
  const [cartPayload, setCartPayload] = useState([]);
  const { hamburger } = useSelector((state) => state.Hamburger);

  useEffect(() => {
    if (
      props?.cart &&
      props?.cart?.cartItems &&
      props?.cart?.cartItems?.length &&
      Array.isArray(props?.cart?.cartItems)
    ) {
      setCartItems(props?.cart?.cartItems);
      //setting payload for sending data in this formate to backend
      let payload = [];
      props?.cart?.cartItems.map((item) => {
        const newPaylod = {
          foodId: item?.id,
          quantity: item?.quantity,
          price: item?.price,
        };
        payload.push(newPaylod);
      });
      setCartPayload(payload);
    } else {
      navigate(ROUTES.FOOD_MENU);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.cart]);

  //back
  const backToOrder = () => {
    navigate(-1);
  };

  //cancel the order
  const cancelOrder = () => {
    props.clearCart();
    navigate(-1);
  };

  const headingData = {
    heading: t("261"),
    seperator: [
      { text: t("241") },
      {
        link: ROUTES.FOOD_MENU,
        text: t("262"),
      },
      {
        text: t("267"),
      },
    ],
  };

  return (
    <div className="page-container">
      <div className="mx-auto max-w-7xl">
        <div className="flex flex-col sm:flex-row justify-between sm:items-end mb-4">
          <Seperator data={headingData} />
          <div className="flex items-center justify-end mt-4 sm:mt-0">
            <button
              className="h-10 w-[89px] flex items-center justify-center gap-2 border border-[#DDE2E4] rounded-md bg-[#FFFFFF] hover:bg-slate-50"
              onClick={backToOrder}
            >
              <FiArrowLeft size={18} />{" "}
              <div className="text-[14px] text-[#252C32] leading-6 tracking-[-0.6%] font-normal">
                {t("236")}
              </div>
            </button>
            <button
              className="h-10 w-[146px] flex items-center justify-center gap-2 text-[#F6F8F9] rounded-md  bg-[#FF2424] ml-4"
              onClick={cancelOrder}
            >
              <img src={Xsquare} alt="" />
              <div className="text-[14px] leading-[14px] tracking-[-0.6%] font-semibold">
                Cancel Order
              </div>
            </button>
          </div>
        </div>
        {/* heading */}
        <div className="text-[30px] leading-[37.8px] text-[#000000] font-bold tracking-[-0.8px] mb-4">
          Cart -
          <span className="mx-1">
            {props?.cart &&
              props?.cart?.cartItems &&
              props?.cart?.cartItems?.length}
          </span>
        </div>
        <div
          className={`${hamburger
              ? "lg:grid-cols-3 xl:grid-cols-4"
              : "lg:grid-cols-4 xl:grid-cols-5"
            } w-full grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3`}
        >
          {cartItems?.map((item, index) => (
            <OrderSummaryItemCart item={item} key={index} cart={cartPayload} />
          ))}
        </div>
        <PlaceOrder cartItems={cartPayload} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  cart: state.FoodCart,
});

const mapDispatchToProps = (dispatch) => ({
  clearCart: () => dispatch({ type: CLEAR_CART }),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderSummary);
