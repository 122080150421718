import React from "react";
import ToolTip from "../../components/ToolTip";
import { useTranslation } from "react-i18next";

const InputPropertyName = ({ propertyName, setPropertyName, message }) => {
  const { t } = useTranslation();
  return (
    <div className="relative max-w-full w-full">
      {" "}
      {/* sm:w-[410px] */}
      <label className="text-sm font-medium text-[#344054]">{t("896")}*</label>
      <div className="relative flex items-center w-full gap-2">
        <input
          type="text"
          onChange={(e) => setPropertyName(e?.target?.value)}
          value={propertyName}
          placeholder={t("897")}
          minLength={3}
          maxLength={100}
          required={true}
          className="border border-[#D0D5DD] w-full text-sm py-2 px-3 rounded-md focus:border-base-primary h-12"
        />
        {/* <ToolTip Message={t("899")} /> */}
        <ToolTip Message={message} />
      </div>
    </div>
  );
};

export default InputPropertyName;
