/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import MetaData from "../../utils/MetaData";
import { useSelector, useDispatch } from "react-redux";
import LoadSpinner from "../../components/loader/LoadSpinner";
import { forgotPasswordAction } from "../../actions/pms/forgotPassword";
import { notifyError, notifySuccess } from "../../components/alert";
import { clearError } from "../../actions/clearError";
import { ROUTES } from "../../constants";
import AuthEmailInput from "../auth/AuthEmailInput";
import { FORGOT_PASSWORD_RESET } from "../../actions/types/forgotPassword";
import { useTranslation } from "react-i18next";

const ForgotPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const { loading, isforgoted, error } = useSelector(
    (state) => state.ForgotPassword
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!emailError && email) dispatch(forgotPasswordAction({ email }));
  };

  useEffect(() => {
    if (isforgoted) {
      dispatch({ type: FORGOT_PASSWORD_RESET });
      notifySuccess("Password changed");
      navigate(ROUTES.VERIFY_OTP);
    }
  }, [isforgoted]);

  //clear error
  useEffect(() => {
    if (error) {
      notifyError(error);
      dispatch(clearError());
    }
  }, [error]);

  return (
    <>
      <MetaData title={t("954")} />
      <div className="w-full min-h-screen p-4 relative">
        <div className="w-[450px] mx-auto max-w-full rounded flex flex-col shadow-md my-8 bg-[#FFFFFF]">
          <div className="w-full pt-6">
            <h1 className="text-3xl font-medium text-center text-base-primary capitalize">
              {t("954")}
            </h1>
            <p className="text-[#616161] w-full text-center pt-2 px-4">
              {t("955")}
            </p>
          </div>
          <form
            className="w-full p-8 grid grid-cols-1 gap-4"
            onSubmit={handleSubmit}
          >
            <AuthEmailInput
              email={email}
              setEmail={setEmail}
              emailError={emailError}
              setEmailError={setEmailError}
            />
            <button
              className="inline-block h-12 w-full bg-base-primary text-[#FFFFFF] rounded-3xl font-semibold py-0 disabled:bg-base-rgba_primary_7"
              disabled={emailError || loading || !email}
            >
              {loading ? <LoadSpinner /> : <>{t("956")}</>}
            </button>
          </form>
          <div className="w-full text-center pb-6">
            <Link to={ROUTES.LOGIN} className="text-base-primary  ml-2">
              {t("957")}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
