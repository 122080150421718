import React from "react";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";

const Seperator = ({ data, textColor }) => {
  return (
    <>
      <div>
        <h2 className="text-xl sm:text-2xl md:text-[30px] font-bold leading-[37px] tracking-[-0.8px] capitalize">
          {data?.heading}
        </h2>
        <div className="relative flex items-center mt-2">
          {data?.seperator?.map((sp, index) => (
            <div key={index} className="flex items-center w-fit">
              {index && index < data?.seperator?.length ? (
                <IoIosArrowForward size={15} className="mx-1" />
              ) : null}
              {sp?.link ? (
                <Link
                  className="leading-[20px] font-normal capitalize text-sm md:text-base"
                  to={sp?.link}
                >
                  {sp?.text}
                </Link>
              ) : (
                <span
                  className={`leading-[20px] font-normal capitalize text-sm md:text-base ${
                    index === data?.seperator?.length - 1
                      ? "text-[#00A35E]"
                      : ""
                  }`}
                >
                  {sp?.text}
                </span>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Seperator;
