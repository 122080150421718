import React, { useState } from "react";

const OPTIONS = {
    VACANT: "Vacant",
    OCCUPIED: "Occupied",
}

const RoomAvailability = () => {
    const [value, setValue] = useState("DIRTY");

    return (
        <>
            <select onChange={(e) => setValue(e?.target?.value)} value={value} className="inspection-selector">
                {
                    Object.keys(OPTIONS)?.map((it, index) => (
                        <option className="capitalize" key={index} value={OPTIONS[it]}>{OPTIONS[it]}</option>
                    ))
                }
            </select>
        </>
    );
};

export default RoomAvailability;
