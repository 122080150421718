import React from "react";

function RoomTarrifCalender() {
  const week = ["MO", "TU", "WE", "TH", "FR", "SA", "SU"];
  const days = {
    row1: ["", "", "", "", "", "1", "2"],
    row2: ["3", "4", "5", "6", "7", "8", "9"],
    row3: ["10", "11", "12", "13", "14", "15", "16"],
    row4: ["17", "18", "19", "20", "21", "22", "23"],
    row5: ["24", "25", "26", "27", "28", "29", "30"],
  };
  const price = "123e";
  return (
    <div className="h-[392px] rounded-sm">
      {/* clender week */}
      <div className="flex border-t h-[52px]">
        {week.map((items, i) => {
          return (
            <>
              <div
                key={i}
                className="flex justify-between items-center  px-[28.50px] text-[10px] font-medium leading-3 text-[#292A34]"
              >
                {items}
              </div>
            </>
          );
        })}
      </div>
      {/* calender r*c */}
      <div className="h-[282px]">
        <div className="flex grid-cols-7 h-[56.4px]">
          {days.row1.map((items, i) => {
            return (
              <>
                <div
                  key={i}
                  className="w-[73.29px] h-[56.4px] border-r border-b"
                >
                  <div className="flex justify-start text-[15px] leading-[18px] font-semibold pt-2 pl-2">
                    {items}
                  </div>
                  <div className="flex justify-end text-[11px] leading-[13.31px] font-medium text-[#292A34] pt-2 pr-2">
                    {days.row1[5] === items ? price : null}
                    {days.row1[6] === items ? price : null}
                  </div>
                </div>
              </>
            );
          })}
        </div>
        <div className="flex grid-cols-7 h-[56.4px]">
          {days.row2.map((items, i) => {
            return (
              <>
                <div
                  key={i}
                  className="w-[73.29px] h-[56.4px] border-r border-b"
                >
                  <div className="flex justify-start text-[15px] leading-[18px] font-semibold pt-2 pl-2">
                    {items}
                  </div>
                  <div className="flex justify-end text-[11px] leading-[13.31px] font-medium text-[#292A34] pt-2 pr-2">
                    {price}
                  </div>
                </div>
              </>
            );
          })}
        </div>
        <div className="flex grid-cols-7 h-[56.4px]">
          {days.row3.map((items, i) => {
            return (
              <>
                <div
                  key={i}
                  className="w-[73.29px] h-[56.4px] border-r border-b"
                >
                  <div className="flex justify-start text-[15px] leading-[18px] font-semibold pt-2 pl-2">
                    {items}
                  </div>
                  <div className="flex justify-end text-[11px] leading-[13.31px] font-medium text-[#292A34] pt-2 pr-2">
                    {price}
                  </div>
                </div>
              </>
            );
          })}
        </div>
        <div className="flex grid-cols-7 h-[56.4px]">
          {days.row4.map((items, i) => {
            return (
              <>
                <div
                  key={i}
                  className="w-[73.29px] h-[56.4px] border-r border-b"
                >
                  <div className="flex justify-start text-[15px] leading-[18px] font-semibold pt-2 pl-2">
                    {items}
                  </div>
                  <div className="flex justify-end text-[11px] leading-[13.31px] font-medium text-[#292A34] pt-2 pr-2">
                    {price}
                  </div>
                </div>
              </>
            );
          })}
        </div>
        <div className="flex grid-cols-7 h-[56.4px]">
          {days.row5.map((items, i) => {
            return (
              <>
                <div
                  key={i}
                  className="w-[73.29px] h-[56.4px] border-r border-b"
                >
                  <div className="flex justify-start text-[15px] leading-[18px] font-semibold pt-2 pl-2">
                    {items}
                  </div>
                  <div className="flex justify-end text-[11px] leading-[13.31px] font-medium text-[#292A34] pt-2 pr-2">
                    {price}
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
}
export default RoomTarrifCalender;
