/* eslint-disable array-callback-return */
// eslint-disable-next-line array-callback-return
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PageLoader from "../../components/loader/PageLoader";
import { useTranslation } from "react-i18next";
import { ID_NUMBER, ROUTES } from "../../constants";
import { useNavigate } from "react-router-dom";
import Seperator from "../../components/layouts/Seperator";
import CustomeTable from "../../components/CustomeTable";
import HideEmail from "./HideEmail";
import HidePhone from "./HidePhone";
import AddButton from "../../components/buttons/AddButton";
import { registerEvent } from "../../logs";
import AddGuest from "./AddGuest";
import UploadButton from "../../components/buttons/UploadButton";
import DownloadButton from "../../components/buttons/DownloadButton";

//view customer details
const ViewCustomerDetails = ({ v }) => {
  const navigate = useNavigate();
  const viewDetails = () => {
    localStorage.setItem(ID_NUMBER, v?.data?._id);
    navigate(ROUTES.CUSTOMER);
  };
  return (
    <button onClick={viewDetails} className="text-[14px] leading-6 font-normal tracking-[-0.6%] text-base-primary hover:text-base-primary_hover">
      View Details
    </button>
  );
};

const CustomersList = (props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [datas, setDatas] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [open, setOpen] = useState(false)
  //set all bookings as customers(datas)
  useEffect(() => {
    setLoading(true);
    let array = [];
    if (Array.isArray(props?.data?.bookings)) {
      props?.data?.bookings.map((room) => {
        if (Array.isArray(room?.roomDetails)) {
          room.roomDetails.map((guest) => {
            if (Array.isArray(guest?.guestsDetails)) {
              guest?.guestsDetails.map((guest) => {
                array.push({
                  ...guest,
                  name: guest?.name,
                  gender: guest?.gender,
                  phone: guest?.phone,
                  email: guest?.email,
                  country: "India",
                  _id: guest?._id,
                });
              });
            }
          });
        }
      });
      if (array?.length) setDatas(array);
    }
    setLoading(false);
  }, [props?.data?.bookings]);

  const columnDefs = [
    {
      headerName: "Customer ID",
      field: "_id",
      maxWidth: 200,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Guest Name",
      field: "name",
      minWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Gender",
      field: "gender",
      maxWidth: 150,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "phone",
      field: "phone",
      maxWidth: 150,
      filter: true,
      floatingFilter: true,
      cellRenderer: HidePhone,
    },
    {
      headerName: "email",
      field: "email",
      maxWidth: 150,
      filter: true,
      floatingFilter: true,
      cellRenderer: HideEmail,
    },
    {
      headerName: "country",
      field: "country",
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "address",
      field: "address",
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Actions",
      field: "_id",
      maxWidth: 150,
      cellRenderer: ViewCustomerDetails,
    },
  ];

  const headingData = {
    heading: t("68"),
    seperator: [
      {
        text: t("75"),
      },
      {
        text: t("32"),
      },
    ],
  };

  const addGuestModal = () => {
    setOpen(true);
    registerEvent("click add guest button", "all guest page");
  };

  //close add guest modal
  const close = () => {
    setOpen(false);
    // setUpdateData(null);
    registerEvent("close add guest component", "add guest page");
  };
  return (
    <>
      {props?.data?.loading || loading ? (
        <PageLoader />
      ) : (
        <div className="page-container max-w-7xl mx-auto relative">

          <div className="w-full flex items-end justify-between mx-auto mb-6">
            <Seperator data={headingData} />
            <AddButton text={t("Add Guest")} onClick={addGuestModal} />
          </div>
          {open ? <AddGuest close={close} /> : null}
          <div className="flex items-center justify-between">
            <div className="text-[22px] leading-[27.72px] text-black font-bold">Customer Table</div>
            <div className="flex items-center gap-4">
              <UploadButton />
              <DownloadButton />
            </div>
          </div>
          <div className="mt-4">
            <CustomeTable
              columnDefs={columnDefs}
              rowData={datas}
              setSelectedRows={setSelectedRows}
            />
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  data: state.AllBooking,
});

export default connect(mapStateToProps)(CustomersList);
