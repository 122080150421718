import React, { useState } from "react";
import { CustomeTable } from "../../components";

const RealTimeAlacarteTable = () => {
  const [selectedRows, setSelectedRows] = useState([]);

  const rowData = [
    {
      _id: "F001",
      tableAndRoomNo: "120",
      order: "Chicken Wings",
      orderFor: "Room Service",
      paymentType: "Cash on Delivery",
      totalAmount: "$120",
      time: "09:00 am",
      status: "inQueue",
    },
    {
      _id: "F002",
      tableAndRoomNo: "T102",
      order: "Chinese",
      orderFor: "Table",
      paymentType: "-",
      totalAmount: "$120",
      time: "09:00 am",
      status: "inQueue",
    },
    {
      _id: "F003",
      tableAndRoomNo: "150",
      order: "Rasian Salad",
      orderFor: "Room Service",
      paymentType: "Bill Sign",
      totalAmount: "$120",
      time: "09:00 am",
      status: "inQueue",
    },
    {
      _id: "F004",
      tableAndRoomNo: "150",
      order: "Chicken Pulao",
      orderFor: "Room Service",
      paymentType: "Paid",
      totalAmount: "$120",
      time: "09:00 am",
      status: "delivered",
    },
    {
      _id: "F005",
      tableAndRoomNo: "150",
      order: ["Chicken Wings", "Chicken Pulao", "Mango Juice"],
      orderFor: "Table",
      paymentType: "-",
      totalAmount: "$120",
      time: "09:00 am",
      status: "delivered",
    },
  ];
  const checkStatusActions = ({ value }) => {
    return (
      <div className="mt-2 capitalize">
        {value === "delivered" && (
          <div className="flex justify-center  items-center w-[89px] h-[24px] border border-base-primary bg-[#00a35e33] rounded text-[14px] leading-[24px] tracking-[-0.6%] font-normal text-base-primary ">
            {value}
          </div>
        )}
        {value === "inQueue" && (
          <div className="flex justify-center items-center w-[89px] h-[24px] border border-[#EFD703] bg-[#fff50033] rounded text-[14px] leading-[24px] tracking-[-0.6%] font-normal text-[#EFD703]">
            {value}
          </div>
        )}
      </div>
    );
  };

  const columnDefs = [
    {
      headerName: "ID",
      field: "_id",
      maxWidth: 120,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Table/Room No.",
      field: "tableAndRoomNo",
      minWidth: 150,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Order",
      field: "order",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Order For",
      field: "orderFor",
      maxWidth: 150,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Payment Type",
      field: "paymentType",
      maxWidth: 150,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Total Amount",
      field: "totalAmount",
      maxWidth: 150,
      filter: true,
      floatingFilter: true,
    },

    {
      headerName: "Time",
      field: "time",
      maxWidth: 150,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Status",
      field: "status",
      maxWidth: 200,
      minWidth: 100,
      cellRenderer: checkStatusActions,
    },
  ];
  return (
    <div>
      <CustomeTable
        columnDefs={columnDefs}
        rowData={rowData}
        setSelectedRows={setSelectedRows}
      />
    </div>
  );
};

export default RealTimeAlacarteTable;
