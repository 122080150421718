import axios from "axios";
import { TOKEN, ISADMIN } from "../../constants";
import {
  VERIFY_EMAIL_FAIL,
  VERIFY_EMAIL_REQ,
  VERIFY_EMAIL_SUC,
} from "../types/ownerAuth";

//verify owner profile email
export const verifyOnwerEmailAction = (formbody) => async (dispatch) => {
  const isadmin = await JSON.parse(localStorage.getItem(ISADMIN));
  if (!isadmin) return;
  let url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/verify-mail`;
  try {
    dispatch({ type: VERIFY_EMAIL_REQ });
    const { data } = await axios.post(url, formbody, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: VERIFY_EMAIL_SUC,
      payload: data.token,
    });
  } catch (error) {
    dispatch({
      type: VERIFY_EMAIL_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};
