/* eslint-disable no-unused-vars */
import React from "react";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import { useState } from "react";
import ToolTip from "../ToolTip";

const PhoneWithCode = ({
  phone,
  setPhone,
  placeholder,
  label,
  toolTipMessage,
  required = false,
  readOnly = false,
}) => {
  const [validPhone, setValidPhone] = useState(true);
  const [validLength, setValidLength] = useState(0);
  const onChange = (value) => {
    if (value === undefined || value === null) {
      setPhone("");
      setValidPhone(true);
      setValidLength(0);
    } else if (value && isValidPhoneNumber(value)) {
      setPhone(value);
      setValidPhone(isValidPhoneNumber(value));
      setValidLength(value.length); // valid phone number length by country
    } else {
      setValidPhone(isValidPhoneNumber(value));
      if (validLength && value.length >= validLength) return;
      setPhone(value);
    }
  };

  return (
    <>
      <div className="w-full">
        {label && label?.length ? (
          <div className="flex justify-between">
            <label>{label}</label>
            <ToolTip Message={toolTipMessage} />
          </div>
        ) : null}
        <div className="relative flex items-center">
          <PhoneInput
            flags={flags}
            placeholder={placeholder}
            value={phone}
            onChange={onChange}
            className={`w-full h-12 relative bg-transparent border shadow-inputFeild border-[#D0D5DD] rounded-lg phone-number-input-box overflow-hidden`}
            numberInputProps={{
              required: required,
            }}
            readOnly={readOnly}
          />
        </div>
      </div>
    </>
  );
};

export default PhoneWithCode;
