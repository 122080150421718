import axios from "axios";
import { TOKEN } from "../../constants";
import {
  OCCUPANCY_BY_DATE_FAIL,
  OCCUPANCY_BY_DATE_REQ,
  OCCUPANCY_BY_DATE_SUC,
  OCCUPANCY_BY_MONTH_FAIL,
  OCCUPANCY_BY_MONTH_REQ,
  OCCUPANCY_BY_MONTH_SUC,
  OCCUPANCY_BY_YEAR_FAIL,
  OCCUPANCY_BY_YEAR_REQ,
  OCCUPANCY_BY_YEAR_SUC,
} from "../types/occupancyReport";

//occupancy report by date - yyyy-mm-dd
export const occupancyReportByDate =
  (hotelId, fromDate, toDate) => async (dispatch) => {
    if(!hotelId || !fromDate || !toDate) return; 
    let url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/reports/bookings/occupancy/by-date`;
    try {
      dispatch({ type: OCCUPANCY_BY_DATE_REQ });
      const { data } = await axios.post(
        url,
        { hotelId, fromDate, toDate },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-auth-token": localStorage.getItem(TOKEN),
          },
          withCredentials: true,
        }
      );
      dispatch({
        type: OCCUPANCY_BY_DATE_SUC,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: OCCUPANCY_BY_DATE_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

//occupancy report by month - yyyy-mm
export const occupancyReportByMonth =
  (hotelId, fromMonth, toMonth) => async (dispatch) => {
    if(!hotelId || !fromMonth || !toMonth) return;
    let url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/reports/bookings/occupancy/by-month`;
    try {
      dispatch({ type: OCCUPANCY_BY_MONTH_REQ });
      const { data } = await axios.post(
        url,
        { hotelId, fromMonth, toMonth },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-auth-token": localStorage.getItem(TOKEN),
          },
          withCredentials: true,
        }
      );
      dispatch({
        type: OCCUPANCY_BY_MONTH_SUC,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: OCCUPANCY_BY_MONTH_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };

//occupancy report by year - yyyy
export const occupancyReportByYear =
  (hotelId, fromYear, toYear) => async (dispatch) => {
    if(!hotelId || !fromYear ||!toYear) return;
    let url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/reports/bookings/occupancy/by-year`;
    try {
      dispatch({ type: OCCUPANCY_BY_YEAR_REQ });
      const { data } = await axios.post(
        url,
        { hotelId, fromYear, toYear },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-auth-token": localStorage.getItem(TOKEN),
          },
          withCredentials: true,
        }
      );
      dispatch({
        type: OCCUPANCY_BY_YEAR_SUC,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: OCCUPANCY_BY_YEAR_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };
