import {
  CREATE_INVENTORY_FAIL,
  CREATE_INVENTORY_REQUEST,
  CREATE_INVENTORY_RESET,
  CREATE_INVENTORY_SUCCESS,
  GET_KITCHEN_INVENTORY_FAIL,
  GET_KITCHEN_INVENTORY_REQUEST,
  GET_KITCHEN_INVENTORY_SUCCESS,
  UPDATE_INVENTORY_FAIL,
  UPDATE_INVENTORY_REQUEST,
  UPDATE_INVENTORY_RESET,
  UPDATE_INVENTORY_SUCCESS,
} from "../../actions/typesKitchen/inventoryOwner";
import { CLEAR_ERRORS } from "../../actions/types";
import { DELETE_MENU_ITEM_SUCCESS } from "../../actions/typesKitchen/menu";

//create inventory
export const createInventoryReducer = (state = { inventory: {} }, action) => {
  switch (action.type) {
    case CREATE_INVENTORY_REQUEST:
      return {
        ...state,
        loading: true,
        isadded: false,
      };
    case CREATE_INVENTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        inventory: action.payload,
        isadded: true,
      };
    case CREATE_INVENTORY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isadded: false,
      };
    case CREATE_INVENTORY_RESET:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isadded: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

//get inventory
export const getOwnerInventoryReducer = (state = { inventories: [] }, action) => {
  switch (action.type) {
    case GET_KITCHEN_INVENTORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_KITCHEN_INVENTORY_SUCCESS:
    case DELETE_MENU_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        inventories: action.payload,
      };
    case GET_KITCHEN_INVENTORY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

//get orders
export const updateItemFromInventoryReducer = (
  state = { inventory: {} },
  action
) => {
  switch (action.type) {
    case UPDATE_INVENTORY_REQUEST:
      return {
        ...state,
        loading: true,
        isupdated: false,
      };
    case UPDATE_INVENTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        inventory: action.payload,
        isupdated: true,
      };
    case UPDATE_INVENTORY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isupdated: false,
      };
    case UPDATE_INVENTORY_RESET:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isupdated: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

//get orders
export const getInventoryItemReducer = (state = { inventory: {} }, action) => {
  switch (action.type) {
    case GET_KITCHEN_INVENTORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_KITCHEN_INVENTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        inventory: action.payload,
      };
    case GET_KITCHEN_INVENTORY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
