import { useState } from "react";
import { isObjEmpty } from "../../utils";
import { registerEvent } from "../../logs";
import AddEmployee from "./AddEmployee";
import AllEmployee from "./AllEmployee";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Seperator from "../../components/layouts/Seperator";
import AddButton from "../../components/buttons/AddButton";
import { useEffect } from "react";
import { getAllHotelEmployeeAction } from "../../actions/pms/employee";
import EditButton from "../../components/buttons/EditButton";
import DeleteButton from "../../components/buttons/DeleteButton";

const EmployeePage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [updateData, setUpdateData] = useState(null);
  const { hotel } = useSelector((state) => state.Hotel);

  const addEmployeeModal = () => {
    setOpen(true);
    registerEvent("click add employee button", "all employee page");
  };

  //close add employee modal
  const close = () => {
    setOpen(false);
    setUpdateData(null);
    registerEvent("close add employee component", "add employee page");
  };

  useEffect(() => {
    if (hotel?._id) dispatch(getAllHotelEmployeeAction(hotel?._id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headingData = {
    heading: t("198"),
    seperator: [
      {
        text: t("134"),
      },
      {
        text: t("198"),
      },
    ],
  };

  return (
    <>
      <div className="page-container max-w-full">
        <div className="w-full">
          <div className=" flex justify-between items-end max-w-7xl mx-auto">
            <Seperator data={headingData} />
            <div className="flex gap-2">
              <EditButton/>
              <DeleteButton/>
              <AddButton text={t("199")} onClick={addEmployeeModal} />
            </div>
          </div>
        </div>
        {(updateData && !isObjEmpty(updateData)) || open ? (
          <div className="mt-6">
            <AddEmployee close={close} updateData={updateData} />
          </div>
        ) : null}
        <div className="mt-6">
          <AllEmployee setUpdateData={setUpdateData} />
        </div>
      </div>
    </>
  );
};

export default EmployeePage;
