import React from "react";
import { useTranslation } from "react-i18next";
import { TbPencil } from "react-icons/tb";

const EditButton = ({
  onClick = () => {},
  disabled = true,
  sizing = "w-[86px] h-10",
}) => {
  const { t } = useTranslation();
  return (
    <button
      className={`${sizing} relative  border rounded-[6px] flex items-center justify-center gap-2 transition-all duration-[0.3s] ease-[0s] bg-[#F6F6F6] hover:bg-slate-300 `}
      onClick={onClick}
      disabled={disabled}
      type="button"
    >
      <TbPencil size={16} />
      <span className="tracking-[-0.006em] text-[14px] leading-[24px] font-normal">
        {t("62")}
      </span>
    </button>
  );
};

export default EditButton;
