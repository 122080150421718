import {
  ADD_PERMISSION_TO_EMP_FAIL,
  ADD_PERMISSION_TO_EMP_REQUEST,
  ADD_PERMISSION_TO_EMP_RESET,
  ADD_PERMISSION_TO_EMP_SUCCESS,
  DELETE_PERMISSION_TO_EMP_FAIL,
  DELETE_PERMISSION_TO_EMP_REQUEST,
  DELETE_PERMISSION_TO_EMP_RESET,
  DELETE_PERMISSION_TO_EMP_SUCCESS,
} from "../../actions/types/employee";
import { CLEAR_ERRORS } from "../../actions/types";

//add extra work done by the emloyee
export const employeePermissionReducer = (state = { employee: {} }, action) => {
  switch (action.type) {
    case ADD_PERMISSION_TO_EMP_REQUEST:
      return {
        ...state,
        loading: true,
        isadded: false,
      };
    case ADD_PERMISSION_TO_EMP_SUCCESS:
      return {
        ...state,
        loading: false,
        employee: action.payload,
        isadded: true,
      };
    case ADD_PERMISSION_TO_EMP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isadded: false,
      };
    case ADD_PERMISSION_TO_EMP_RESET:
      return {
        ...state,
        loading: false,
        error: null,
        isadded: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

//delete extra work done by the employee
export const employeePermissionDeleteReducer = (
  state = { employee: {} },
  action
) => {
  switch (action.type) {
    case DELETE_PERMISSION_TO_EMP_REQUEST:
      return {
        ...state,
        loading: true,
        isdeleted: false,
      };
    case DELETE_PERMISSION_TO_EMP_SUCCESS:
      return {
        ...state,
        loading: false,
        employee: action.payload,
        isdeleted: true,
      };
    case DELETE_PERMISSION_TO_EMP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isdeleted: false,
      };
    case DELETE_PERMISSION_TO_EMP_RESET:
      return {
        ...state,
        loading: false,
        error: null,
        isdeleted: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
