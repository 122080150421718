/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addKitchenMenuItemAction,
  updateMenuItemAction,
} from "../../actions/kitchen/menu";
import {
  MENU_TYPE,
  ITEM_QUANTITY_MEASURMENT,
  PORTION_FOR,
  PORTION_SIZE,    
} from "../../constants";
import MultipleImageUpload from "../../components/layouts/MultipleImageUpload";
import { registerEvent } from "../../logs";
import SaveOrNot from "../../components/layouts/SaveOrNot";
import { isObjEmpty } from "../../utils";
import img from "../../images/menuInventoryImage.jpg";
import { useTranslation } from "react-i18next";
import Inputfield from "../../components/layouts";
import SelectFromObject from "../../components/layouts/SelectFromObject";
import SelectTrueFalse from "../../components/layouts/SelectTrueFalse";
import { notifyError, notifySuccess } from "../../components/alert";
import { ADD_ITEM_TO_MENU_RESET } from "../../actions/typesKitchen/menu";
import { clearError } from "../../actions/clearError";
import CheckBoxInput from "../../components/layouts/CheckBoxInput";
import AddSubmitButton from "../../components/buttons/AddSubmitButton";
import { RxCross2 } from "react-icons/rx"; 
import CustomerMenuDataTable from "./CustomerMenuDataTable";
import BulkProductionCostDataTable from "./BulkProductionCostDataTable";
import BulkProductionAutomatedCostDataTable from "./BulkProductionAutomatedCostDataTable";

const AddItemToMenu = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isadded, loading, error } = useSelector((state) => state.AddMenuItem);

  const { loading: updateLoading } = useSelector(
    (state) => state.UpdateMenuItem
  );
  const [quantityMeasurement, setQuantityMesurment] = useState(
    ITEM_QUANTITY_MEASURMENT.PIECE
  );
  const [images, setImages] = useState([]);
  const [isMeasurable, setIsMeasurable] = useState(false);
  const [open, setOpen] = useState(false);
  const [foodAndBeveragesName, setFoodAndBeveragesName] = useState("");
  const [priceToCustomer, setPriceToCustomer] = useState("");
  const [portionFor, setPortionFor] = useState(PORTION_FOR[1]);
  const [portionSize, setPortionSize] = useState(PORTION_SIZE.full);
  const [dishItemDescription, setDishItemDescription] = useState("");
  const [availableIn, setAvailableIn] = useState([]);
  const [menuType, setMenuType] = useState(Object.keys(MENU_TYPE)[0]);
  // eslint-disable-next-line no-unused-vars
  const [linkToMenu, setLinkToMenu] = useState(false);
  // add new state
  const [foodAndBeverageType, setFoodAndBeverageType] = useState("");
  const [dietaryPreference, setDietaryPreference] = useState("");
  const [available, setAvailable] = useState("");
  // const [quantityPer10, setQuantityPer10] = useState([{ QUANTITY_PER10 }]);
  const [quantity, setQuantity] = useState("1");
  const [productionCostPerkg, setProductionCostPerkg] = useState("1");
  //new fields
  const [isRoomService, setIsRoomService] = useState(false);
  const [isRestaurantDining, setIsRestaurantDining] = useState(false);
  const [isBarAndCafe, setIsBarAndCafe] = useState(false);
  const [isBuffet, setIsBuffet] = useState(false);
  const [isAlaCarteMenu, setIsAlaCarteMenu] = useState(false);
  const [productionQuantity, setProductionQuantity] = useState("1");
  const [quantityUnitMeasurement, setQuantityUnitMeasurement] = useState([]);
  const [productionQuantityMeasurement, setProductionQuantityMeasurement] =
    useState([]);
  const [isAutomated, SetIsAutomated] = useState(true);

  //submiting the form input data.
  const handleSubmit = async () => {
    const formData = {
      kitchenId: props?.kitchen?._id,
      foodAndBeveragesName,
      images,
      priceToCustomer: 100,
      portionFor,
      quantityMeasurement,
      quantity: !isMeasurable ? "1" : quantity,
      // isNonVeg,
      dietaryPreference,
      dishItemDescription,
      isRoomService,
      isRestaurantDining,
      isBarAndCafe,
      isBuffet,
      productionQuantity,
      productionCostPerkg,
      portionSize,
      isMeasurable,
      availableIn,
      menuType,
      linkToMenu,
    };

    if (isObjEmpty(props.menu)) {
      dispatch(addKitchenMenuItemAction(formData));
      registerEvent("add menu item", "add menu item", formData);
    } else {
      dispatch(updateMenuItemAction({ ...formData, itemId: props?.menu?.id }));
      registerEvent("update menu item", "add menu item", formData);
    }
  };

  //open modal for confirmation
  const openConfirmationModal = (e) => {
    e.preventDefault();
    setOpen(true);
    registerEvent("confirmation modal open", "add menu item");
  };

  // close confimation modal without confimation
  const closeModal = () => setOpen(false);
  // message
  useEffect(() => {
    if (isadded) {
      notifySuccess("1067");
      dispatch({ type: ADD_ITEM_TO_MENU_RESET });
    }

    if (error) {
      notifyError("1068");
      dispatch(clearError());
    }
  }, [isadded, error]);

  useEffect(() => {
    if (!isObjEmpty(props?.menu)) {
      setFoodAndBeveragesName(props?.menu?.foodAndBeveragesName);
      setQuantityMesurment(props?.menu?.quantityMeasurement);
      setImages(props?.menu?.images);
      setDietaryPreference(props?.menu?.dietaryPreference);
      setDishItemDescription(props?.menu?.dishItemDescription);
      setIsRoomService(props?.menu?.isRoomService);
      setIsRestaurantDining(props?.menu?.isRestaurantDining);
      setIsBarAndCafe(props?.menu?.isBarAndCafe);
      setIsBuffet(props?.menu?.isBuffet);
      setProductionQuantity(props?.menu?.productionQuantity);
      setProductionCostPerkg(props?.menu?.productionCostPerkg);
      setPriceToCustomer(props?.priceToCustomer);
      setPortionFor(props?.menu?.portionFor);
      setPortionSize(props?.menu?.portionSize);
      setQuantity(props?.menu?.quantity);
      setMenuType(props?.menu?.menuType);
      setAvailableIn(props?.menu?.availableIn);
    }
  }, [props?.menu]);

  return (
    <>
      <form
        onSubmit={openConfirmationModal}
        className={`max-w-[1091px] mx-auto overflow-hidden duration-300 border border-[#D0D5DD] rounded-lg bg-[#FFFFFF] relative mt-6`}
      >
        {/* Add Food and Beverage Items */}
        <div className="p-4">
          <div className="flex items-center justify-center text-[30px] text-[#07090D] font-bold leading-[37.8px] tracking-[-1.5%]">
            {isObjEmpty(props?.menu) ? t("1069") : t("1070")}
          </div>
          <button
            type="button"
            onClick={props?.close}
            className="absolute right-6 top-[23px]"
          >
            <RxCross2
              size={26}
              className="hover:rotate-90 duration-500 hover:text-[#F04438]"
            />
          </button>

          <div className="grid lg:grid-cols-8 md:grid-cols-1 gap-4 items-center mt-4">
            <div className="w-full lg:col-span-6">
              <div className="grid grid-cols-3 gap-6">
                <Inputfield
                  label={t("Food & Beverages Name")}
                  placeholder={t("Enter Dish Item Name")}
                  value={foodAndBeveragesName}
                  onChange={(e) => setFoodAndBeveragesName(e.target.value)}
                  maxLength={40}
                  minLength={3}
                  required={true}
                />
                <SelectFromObject
                  label={t("F&B Type")}
                  options={{}}
                  value={foodAndBeverageType}
                  setValue={setFoodAndBeverageType}
                />
                <SelectTrueFalse
                  label={t("1072")}
                  options={['']}
                  value={dietaryPreference}
                  setValue={setDietaryPreference}
                />
              </div>
              <div className="grid grid-cols-1 mt-8">
                <label className="text-sm font-medium text-[#344054]">
                  {t("Dish Item Description")}
                </label>
                <textarea
                  type="text"
                  className="flex w-full h-[110px] border border-[#D0D5DD] rounded-lg shadow-inputFeild p-4 resize-none overflow-y-auto srcoll-barguest"
                  placeholder={t("Add a description of the item")}
                  value={dishItemDescription}
                  onChange={(e) => setDishItemDescription(e.target.value)}
                ></textarea>
              </div>
            </div>
            {/* image */}
            <div className="lg:col-span-2 w-[240px] h-[230px]">
              <label className="text-sm font-medium text-[#344054]">
                {t("1075")}
              </label>
              <MultipleImageUpload
                utility={"h-[200.76px] mt-[4px]"}
                images={images}
                setImages={setImages}
                thumbnail={img}
              />
            </div>
          </div>
        </div>

        <hr className="text-[#D0D5DD] w-full mt-12" />

        {/*  Available in- La Carte Menu */}
        <div className="p-4">
          <div className="flex items-center justify-center text-[30px] text-[#07090D] leading-[37.8px]s font-bold tracking-[-1.5%]">
            Available in- La Carte Menu
          </div>
          <div className="w-[217px]">
            <CheckBoxInput
              value={isAlaCarteMenu}
              onChange={() => setIsAlaCarteMenu(!isAlaCarteMenu)}
              utility={"pt-4"}
              label2={t("Ala Carte Menu")}
            />
          </div>
          <div className="lg:flex md:grid md:grid-cols-1 gap-6 w-full items-center">
            <SelectFromObject
              utility={"w-[217px]"}
              label={t("878")}
              options={{}}
              value={available}
              setValue={setAvailable}
            />
            <div className="flex items-center gap-4">
              <CheckBoxInput
                value={isRoomService}
                onChange={() => setIsRoomService(!isRoomService)}
                label={t("1076")}
                label2={t("407")}
              />
              <CheckBoxInput
                adjustment={"mx-8"}
                value={isRestaurantDining}
                onChange={() => setIsRestaurantDining(!isRestaurantDining)}
                label2={t("1077")}
                utility={"pt-4"}
              />
              <CheckBoxInput
                value={isBarAndCafe}
                onChange={() => setIsBarAndCafe(!isBarAndCafe)}
                utility={"pt-4"}
                label2={t("1078")}
              />
            </div>
          </div>
          <div className="grid lg:grid-cols-4 md:grid-cols-2 gap-6 items-center mt-4 w-full">
            <SelectFromObject
              utility={"w-[226px]"}
              label={t("873")}
              options={PORTION_SIZE}
              value={portionSize}
              setValue={setPortionSize}
            />
            <SelectFromObject
              utility={"w-[226px]"}
              label={t("872")}
              options={PORTION_FOR}
              value={portionFor}
              setValue={setPortionFor}
            />
            {/* <TwoInOneInputFields
              utility={"w-[255px]"}
              label={t("335")}
              placeholder={t("Enter a Value")}
              value={quantity}
              onChange={(e) => setProductionQuantity(e?.target?.value)}
              min={1}
              max={10}
              // message={t("754")}
              required={true}
              options={['']}
              selectValue={quantityUnitMeasurement}
              onChangeSelect={(e) => setQuantityUnitMeasurement(e.target.value)}
            /> */}
            <Inputfield
              utility={"w-[226px]"}
              label={t("1079")}
              placeholder={t("489")}
              value={priceToCustomer}
              onChange={(e) => setPriceToCustomer(e.target.value)}
            />
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              className="mt-5 w-[152px] h-10 border border-[#00A35E] hover:bg-base-primary_hover rounded-md shadow-inputFeild text-sm font-medium text-base-primary hover:text-white "
            >
              {/* {t("1080")} */}
              {t("Add Price")}
            </button>
          </div>
          {/* customer menu insert data table*/}
          <div className="text-[16px] leading-4 font-medium mt-2">
            {t("Customer Menu")}
          </div>
          <div className="mt-4">
            <CustomerMenuDataTable />
          </div>
        </div>

        <hr className="text-[#D0D5DD] w-full mt-4" />

        {/*  Available in- Buffet */}
        <div className="p-4">
          <div className="flex items-center justify-center text-[30px] text-[#07090D] font-bold leading-[37.8px] tracking-[-1.5%]">
            Available in- Buffet
          </div>
          <div className={`${!isAutomated ? "ml-16" : "ml-0"}`}>
            <CheckBoxInput
              value={isBuffet}
              onChange={() => setIsBuffet(!isBuffet)}
              utility={"pt-4"}
              label2={t("1001")}
            />
          </div>
          {/* new design --- add funtionality for manually or automated*/}
          <div
            className={`flex items-center w-[275px] h-12 box-border border border-[#D0D5DD] rounded-lg shadow-[0,1px,2px,0,rgba(16, 24, 40, 0.05)] mt-2 ${
              !isAutomated ? "ml-16" : "ml-0"
            }`}
          >
            {isAutomated ? (
              <button
                type="button"
                className={`w-[150px] h-[46px] rounded-lg bg-base-primary text-[16px] text-[#FFFFFF] leading-6 font-normal`}
                onClick={() => SetIsAutomated(true)}
              >
                Manually
              </button>
            ) : (
              <button
                type="button"
                className="w-[125px] h-[46px] rounded-lg bg-[#FFFFFF] text-[16px] text-[#07090D] leading-6 font-normal"
                onClick={() => SetIsAutomated(true)}
              >
                Manually
              </button>
            )}
            {!isAutomated ? (
              <button
                type="button"
                className="w-[150px] h-[46px] rounded-lg text-[16px] text-[#FFFFFF] bg-base-primary leading-6 font-normal"
                onClick={() => SetIsAutomated(false)}
              >
                Automated
              </button>
            ) : (
              <button
                type="button"
                className="w-[125px] h-[46px] rounded-lg text-[16px] text-[#07090D] leading-6 font-normal"
                onClick={() => SetIsAutomated(false)}
              >
                Automated
              </button>
            )}
          </div>

          {isAutomated ? (
            <div className="w-full flex gap-8 mt-4">
              {/* <TwoInOneInputFields
                utility={"w-[255px]"}
                label={t("1081")}
                placeholder={t("Enter a Value")}
                value={productionQuantity}
                onChange={(e) => setProductionQuantity(e?.target?.value)}
                min={1}
                max={10}
                // message={t("754")}
                required={true}
                options={{}}
                selectValue={productionQuantityMeasurement}
                onChangeSelect={(e) =>
                  setProductionQuantityMeasurement(e.target.value)
                }
              /> */}
              <Inputfield
                utility={"w-[225px]"}
                label={t("1082")}
                value={productionCostPerkg}
                onChange={(e) => setProductionCostPerkg(e.target.value)}
              />
              <button
                type="button"
                className="mt-7 w-[152px] h-10 border border-[#00A35E] hover:bg-base-primary_hover rounded-md shadow-inputFeild text-sm font-medium text-base-primary hover:text-white "
              >
                {/* {t("1080")} */}
                {t("Add Price")}
              </button>
            </div>
          ) : null}
          <div className="mt-5 mb-2 text-[16px] leading-4 font-medium">
            {t("1083")}
          </div>
          {/* manually */}
          {isAutomated ? (
            <div className="mt-4 h-fit">
              <BulkProductionCostDataTable />
            </div>
          ) : (
            <div className="mt-4 h-fit">
              <BulkProductionAutomatedCostDataTable />
            </div>
          )}

          <div className="flex justify-end gap-4 items-baseline pt-6">
            {/* submit button */}
            <AddSubmitButton
              width={188}
              text={t("Add To Menu")}
              loading={loading || updateLoading}
            />
          </div>
        </div>
      </form>
      <SaveOrNot isopen={open} close={closeModal} formSubmit={handleSubmit} />
    </>
  );
};

export default AddItemToMenu;
