import React from "react";
import DetailsHeading from "../../components/layouts/DetailsHeading";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { IoIosArrowUp } from "react-icons/io";

const RoomInfoDetails = ({ RoomNumber, guestAdult, guestChild }) => {
  const { t } = useTranslation();
  const [isRoomOpen, setIsRoomOpen] = useState(true);
  const [isGuestOpen, setIsGuestOpen] = useState(true);
  const [isGuestChildOpen, setIsGuestChildOpen] = useState(true);
  return (
    <div
      className={`border rounded-lg bg-gray-50 overflow-hidden px-4 ${
        isRoomOpen ? "h-fit duration-500" : "h-10 duration-500"
      }`}
    >
      <div className="flex items-center h-10">
        <button
          className="flex items-center gap-2 w-full bg-gray-50 cursor-pointer"
          onClick={() => setIsRoomOpen(!isRoomOpen)}
        >
          {isRoomOpen ? (
            <IoIosArrowUp className={`${isRoomOpen ? "duration-500" : ""}`} />
          ) : (
            <IoIosArrowUp
              className={`${!isRoomOpen ? "rotate-180 duration-500" : ""}`}
            />
          )}
          {/* <IoIosArrowDown /> */}
          <div className="text-[16px] leading-4 font-semibold capitalize">
            {RoomNumber}
          </div>
        </button>
      </div>
      <div className="pt-8 flex justify-between items-center gap-10">
        <DetailsHeading heading={t("84")} subHeading="282" />
        <DetailsHeading heading={t("84")} subHeading="American Meal" />
        <DetailsHeading heading={t("123")} subHeading="101" />
        <DetailsHeading heading={t("186")} subHeading="1" />
        <DetailsHeading heading={t("188")} subHeading="3" />
        <DetailsHeading heading={t("517")} subHeading="8%" />
        <DetailsHeading heading={t("1006")} subHeading="$1000" />
      </div>
      <div className="flex items-center gap-10 pt-8">
        <DetailsHeading heading={t("574")} subHeading="Yes" />
        <DetailsHeading heading={t("570")} subHeading="11-05-2023" />
        <DetailsHeading heading={t("571")} subHeading="13-05-2023" />
        <DetailsHeading heading={t("518")} subHeading="Smoking Allowed" />
      </div>
      {/* guest1(adult) */}
      <div
        className={`border bg-[#FFFFFF] rounded-lg overflow-hidden my-4 ${
          isGuestOpen ? "h-[360px] duration-500" : "h-10 duration-500"
        }`}
      >
        <div className="flex items-center px-4 h-10">
          <button
            className="flex items-center gap-2 w-full cursor-pointer"
            onClick={() => setIsGuestOpen(!isGuestOpen)}
          >
            {isGuestOpen ? (
              <IoIosArrowUp
                className={`${isGuestOpen ? "duration-500" : ""}`}
              />
            ) : (
              <IoIosArrowUp
                className={`${!isGuestOpen ? "rotate-180 duration-500" : ""}`}
              />
            )}
            <div className="text-[16px] leading-4 font-semibold capitalize">
              {guestAdult}
            </div>
          </button>
        </div>
        {/* personal information */}
        <div className="p-4">
          <div className="text-[16px] leading-4 font-semibold capitalize">
            {t("1095")}
          </div>
          <div className="flex gap-10 py-4">
            <DetailsHeading heading={t("486")} subHeading="Maria" />
            <DetailsHeading heading={t("168")} subHeading="Female" />
            <DetailsHeading heading={t("526")} subHeading="British" />
            <DetailsHeading heading={t("389")} subHeading="012-23142135" />
          </div>
          <hr></hr>

          {/* identification number */}
          <div className="text-[16px] leading-4 font-semibold capitalize pt-4">
            {t("1096")}
          </div>
          <div className="flex gap-10 items-center py-4">
            <DetailsHeading heading={t("530")} subHeading="xxxx-xxxxxx-xx" />
            <DetailsHeading heading={t("532")} subHeading="Driving License" />
            <DetailsHeading heading={t("533")} subHeading="India" />
            <DetailsHeading heading={t("534")} subHeading="01/2028" />
          </div>
          <hr></hr>
          {/* address info */}
          <div className="text-[16px] leading-4 font-semibold capitalize py-4">
            {t("1097")}
          </div>
          <div className="flex gap-10 items-center">
            <DetailsHeading
              heading={t("347")}
              subHeading="House#21, Block C, 53 Earls Avenue"
            />
            <DetailsHeading heading={t("429")} subHeading="United Kingdom" />
            <DetailsHeading heading={t("576")} subHeading="Grimmet" />
            <DetailsHeading heading={t("577")} subHeading="England" />
            <DetailsHeading heading={t("542")} subHeading="AB31 6NN" />
          </div>
        </div>
      </div>
      {/* guest child */}
      <div
        className={`border bg-[#FFFFFF] rounded-lg overflow-hidden my-4 ${
          isGuestChildOpen ? "h-[360px] duration-500" : "h-10 duration-500"
        }`}
      >
        <div className="flex items-center px-4 h-10">
          <button
            className="flex items-center gap-2 w-full cursor-pointer"
            onClick={() => setIsGuestChildOpen(!isGuestChildOpen)}
          >
            {isGuestChildOpen ? (
              <IoIosArrowUp
                className={`${isGuestChildOpen ? "duration-500" : ""}`}
              />
            ) : (
              <IoIosArrowUp
                className={`${
                  !isGuestChildOpen ? "rotate-180 duration-500" : ""
                }`}
              />
            )}
            <div className="text-[16px] leading-4 font-semibold capitalize">
              {guestChild}
            </div>
          </button>
        </div>
        {/* personal information */}
        <div className="p-4">
          <div className="text-[16px] leading-4 font-semibold capitalize">
            {t("1095")}
          </div>
          <div className="flex gap-10 py-4">
            <DetailsHeading heading={t("486")} subHeading="Maria" />
            <DetailsHeading heading={t("168")} subHeading="Female" />
            <DetailsHeading heading={t("526")} subHeading="British" />
            <DetailsHeading heading={t("389")} subHeading="012-23142135" />
          </div>
          <hr></hr>

          {/* identification number */}
          <div className="text-[16px] leading-4 font-semibold capitalize pt-4">
            {t("1096")}
          </div>
          <div className="flex gap-10 items-center py-4">
            <DetailsHeading heading={t("530")} subHeading="xxxx-xxxxxx-xx" />
            <DetailsHeading heading={t("532")} subHeading="Driving License" />
            <DetailsHeading heading={t("533")} subHeading="India" />
            <DetailsHeading heading={t("534")} subHeading="01/2028" />
          </div>
          <hr></hr>
          {/* address info */}
          <div className="text-[16px] leading-4 font-semibold capitalize py-4">
            {t("1097")}
          </div>
          <div className="flex gap-10 items-center">
            <DetailsHeading
              heading={t("347")}
              subHeading="House#21, Block C, 53 Earls Avenue"
            />
            <DetailsHeading heading={t("429")} subHeading="United Kingdom" />
            <DetailsHeading heading={t("576")} subHeading="Grimmet" />
            <DetailsHeading heading={t("577")} subHeading="England" />
            <DetailsHeading heading={t("542")} subHeading="AB31 6NN" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoomInfoDetails;
