import React, { useEffect, useState } from "react";
import Seperator from "../../components/layouts/Seperator";
import AddButton from "../../components/buttons/AddButton";
import { useTranslation } from "react-i18next";
import { ROUTES } from "../../constants";
import BuffetSummaryCard from "./BuffetSummaryCard";
import AllCreateBuffet from "./AllCreateBuffet";
import { registerEvent } from "../../logs";
import { isObjEmpty } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import AddItemToMenu from "../kitchen-menu/AddItemToMenu";
import { getKitchenMenuAction } from "../../actions/kitchen/menu";
import BuffetSummaryDataTable from "./BuffetSummaryDataTable";
import { IoIosArrowForward } from "react-icons/io";
const CreateBuffetMenu = () => {
  const { t } = useTranslation();
  const { hotel } = useSelector((state) => state.Kitchen);
  const { kitchen } = useSelector((state) => state.Kitchen);
  const dispatch = useDispatch();
  const [addFAndBButton, setAddFAndBButton] = useState(false);
  const [menu, setMenu] = useState({});

  //register event
  const registerEventAndOpen = () => {
    setAddFAndBButton(true);
    registerEvent(
      "add food and beverage item button open",
      "add food and beverage menu",
      {
        addFAndBButton,
      }
    );
  };

  //get kitchen data from session Storage
  useEffect(() => {
    if (kitchen?._id) dispatch(getKitchenMenuAction(kitchen?._id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kitchen?._id]);

  // register evnet on close add asset component
  const close = () => {
    if (!isObjEmpty(menu)) setMenu(null);
    setAddFAndBButton(false);
    registerEvent(
      "add food and beverage item button close",
      "add food and beverage menu",
      {
        addFAndBButton,
      }
    );
  };
  const data = {
    heading: t("Kladee's Cafe  - Royal Buffet"),
    seperator: [
      {
        text: t("134"),
      },
      {
        link: ROUTES.KITCHENS,
        text: t("36"),
      },
      {
        link: ROUTES.KITCHEN_BUFFETS,
        text: t("1001"),
      },
      { text: t("Create Buffet Menu") },
    ],
  };
  // const summary = ["IT 0012","Royal Buffet","Party Buffet","Friends Party","20 Person","$20/Person"]
  return (
    <div className="page-container">
      <div className="w-full max-w-7xl mx-auto">
        <div className="flex justify-between items-end">
          <Seperator data={data} />
          <div className="flex gap-2">
            <AddButton text={t("Add F&B")} onClick={registerEventAndOpen} />
          </div>
        </div>
      </div>
      {!isObjEmpty(menu) || addFAndBButton ? (
        <AddItemToMenu
          close={close}
          hotel={hotel}
          kitchen={kitchen}
          menu={menu}
        />
      ) : null}
      {/* data table for Create buffet menu */}
     
      <div className="mt-4 mb-6">
        <AllCreateBuffet />
      </div>
      <div className="text-[30px] leading-[37.8px] font-bold ">
        {t("Production Cost Table")}
      </div>
      {/* Summary */}

      <div className="flex items-center gap-[2px] text-[16px] leading-[20.16px] font-normal text-[#07090D] my-6">
        <div>IT 0012</div>
        <IoIosArrowForward />
        <div>Royal Buffet</div>
        <IoIosArrowForward />
        <div>Party Buffet</div>
        <IoIosArrowForward />
        <div>Friends Party</div>
        <IoIosArrowForward />
        <div>20 Person</div>
        <IoIosArrowForward />
        <div>$20/Person</div>
      </div>

      {/* buffet summary data table w-[856px] */}
      <div className="w-[856px]">
        <BuffetSummaryDataTable />
      </div>
      <div className="text-[30px] leading-[37.8px] font-bold tracking-[-0.8px] text-[#000000] m-4">
        Total Menu
      </div>
      <div className="grid grid-cols-4 gap-20 w-full mt-4">
        <BuffetSummaryCard Num={"8"} text={"No. of items in salads"} />
        <BuffetSummaryCard Num={"12"} text={"Total No. of buffet items"} />
        <BuffetSummaryCard Num={"2"} text={"No. of items in appitizer"} />
        <BuffetSummaryCard Num={"0"} text={"No. of items in soups"} />
        <BuffetSummaryCard Num={"12"} text={"No. of items in side dish"} />
        <BuffetSummaryCard Num={"3"} text={"No. of items in main course"} />
        <BuffetSummaryCard Num={"3"} text={"No. of items in dessert"} />
      </div>
    </div>
  );
};

export default CreateBuffetMenu;
