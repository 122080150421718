export const UPLOAD_IMAGE_REQUEST = "UPLOAD_IMAGE_REQUEST";
export const UPLOAD_IMAGE_SUCCESS = "UPLOAD_IMAGE_SUCCESS";
export const UPLOAD_IMAGE_FAIL = "UPLOAD_IMAGE_FAIL";
export const UPLOAD_IMAGE_RESET = "UPLOAD_IMAGE_RESET";
export const CLEAR_IMAGE = "CLEAR_IMAGE";

export const DELETE_IMAGE_REQUEST = "DELETE_IMAGE_REQUEST";
export const DELETE_IMAGE_SUCCESS = "DELETE_IMAGE_SUCCESS";
export const DELETE_IMAGE_FAIL = "DELETE_IMAGE_FAIL";
export const DELETE_IMAGE_RESET = "DELETE_IMAGE_RESET";
