import React from "react";
import { useDispatch } from "react-redux";
import DelIcon from "../../images/icons/DelIcon.svg";
import EditIcon from "../../images/icons/EidIcon.svg";
import { deleteRoomAction } from "../../actions/pms/roomdelete";

const RoomAction = ({ roomId, hotelId }) => {
  const dispatch = useDispatch();

  const deleteConfirm = () => {
    dispatch(deleteRoomAction(roomId, hotelId));
  };

  const cancelDelete = () => {};

  return (
    <div className="grid grid-cols-2 gap-2 mt-[6px]">
      <button className="w-[30px] h-[30px] border border-[#E5E9EB] bg-[#FFFFFF] rounded-full flex items-center justify-center hover:bg-[#E5E9EB]">
        <img src={DelIcon} alt="" />
      </button>
      <button className="w-[30px] h-[30px] border border-[#E5E9EB] bg-[#FFFFFF] rounded-full flex items-center justify-center hover:bg-[#E5E9EB]">
        <img src={EditIcon} alt="" />
      </button>
    </div>
  );
};

export default RoomAction;
