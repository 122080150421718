import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
// import { useTranslation } from "react-i18next";
import CustomeTable from "../../../components/CustomeTable";
import PrintButton from "../../../components/buttons/PrintButton";
import UploadButton from "../../../components/buttons/UploadButton";
import DownloadButton from "../../../components/buttons/DownloadButton";

const WifiAll = () => {
  // const { t } = useTranslation();
  const [datas, setDatas] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const { wifi } = useSelector((state) => state.AllWifi);

  useEffect(() => {
    if (wifi && wifi?.length) {
      let wifies = [];
      wifi?.map((w) => {
        wifies.push({
          _id: w?._id,
          ssid: w?.ssid,
          password: w?.password,
          dailyUsesQuota: w?.dailyUsesQuota,
          speedRestriction: w?.speedRestriction,
        });
      });
      setDatas(wifies);
    }
  }, [wifi]);

  const columnDefs = [
    {
      headerName: "Wifi ID",
      field: "_id",
      maxWidth: 200,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "SSID",
      field: "ssid",
      maxWidth: 300,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Password",
      field: "password",
      maxWidth: 300,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Daily Uses Quota",
      field: "dailyUsesQuota",
      maxWidth: 300,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Speed Restriction",
      field: "speedRestriction",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
  ];

  return (
    <>
      <div className="my-6">
        {/* {datas?.length ? ( */}
        <div className="flex items-center justify-between mb-4">
          <div className="text-[22px] leading-[27.72px] text-black font-bold">
            Wifi Table
          </div>
          <div className="flex items-center gap-4">
            <PrintButton />
            <UploadButton />
            <DownloadButton />
          </div>
        </div>
        <CustomeTable
          columnDefs={columnDefs}
          rowData={datas}
          setSelectedRows={setSelectedRows}
        />
        {/* ) : null} */}
      </div>
    </>
  );
};

export default WifiAll;
