import React from "react";
import { useTranslation } from "react-i18next";
import { BsCheck2 } from "react-icons/bs";

const SupportMessageSended = ({ message }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="flex justify-between items-center text-[#FFFFFF]">
        <div></div>
        <div className="max-w-[45%] bg-[#00A35E] rounded-[10px] py-1 px-2 m-2 sticky right-0">
          <div className="text-[16px]  leading-7 font-normal tracking-[-0.6%]">
            {message}
          </div>
          <div className="flex justify-end items-center text-[12px] leading-4 font-normal">
            <div className="">20:23</div>
            <div className="w-[14px] h-[14px] font-normal flex justify-center items-center">
              <BsCheck2 />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SupportMessageSended;
