export const ADD_ITEM_TO_MENU_REQUEST = "ADD_ITEM_TO_MENU_REQUEST";
export const ADD_ITEM_TO_MENU_SUCCESS = "ADD_ITEM_TO_MENU_SUCCESS";
export const ADD_ITEM_TO_MENU_FAIL = "ADD_ITEM_TO_MENU_FAIL";
export const ADD_ITEM_TO_MENU_RESET = "ADD_ITEM_TO_MENU_RESET";

export const GET_MENU_ITEM_REQUEST = "GET_MENU_ITEM_REQUEST";
export const GET_MENU_ITEM_SUCCESS = "GET_MENU_ITEM_SUCCESS";
export const GET_MENU_ITEM_FAIL = "GET_MENU_ITEM_FAIL";

export const DELETE_MENU_ITEM_REQUEST = "DELETE_MENU_ITEM_REQUEST";
export const DELETE_MENU_ITEM_SUCCESS = "DELETE_MENU_ITEM_SUCCESS";
export const DELETE_MENU_ITEM_FAIL = "DELETE_MENU_ITEM_FAIL";
export const DELETE_MENU_ITEM_RESET = "DELETE_MENU_ITEM_RESET";

export const UPDATE_MENU_ITEM_REQUEST = "UPDATE_MENU_ITEM_REQUEST";
export const UPDATE_MENU_ITEM_SUCCESS = "UPDATE_MENU_ITEM_SUCCESS";
export const UPDATE_MENU_ITEM_FAIL = "UPDATE_MENU_ITEM_FAIL";
export const UPDATE_MENU_ITEM_RESET = "UPDATE_MENU_ITEM_RESET";
