/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
// import { useTranslation } from "react-i18next";
import {
  assetsReportAllTime,
  assetsReportAllTimeForOne,
} from "../../actions/reports/assetsReportAllTime";
import { assetsReportAllTimeByDate } from "../../actions/reports/assetsReportByDate";
import ReportsCard from "./ReportsCard";
import totalServiceProviderIcon from "../../images/icons/totalServiceProviderIcon.svg";
import activeServiceProviderIcon from "../../images/icons/activeServiceProviderIcon.svg";
import inactiveServiceProviderIcon from "../../images/icons/inactiveServiceProviderIcon.svg";
import totalValueItem from "../../images/icons/totalValueItem.svg";
import CustomeTable from "../../components/CustomeTable";
import DownloadButton from "../../components/buttons/DownloadButton";

const ThirdPartyServiceReports = (props) => {
  // const { t } = useTranslation();
  const [datas, setDatas] = useState([]);
  const { sales } = useSelector((state) => state.SalesReport);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    props.getAssetsFromToDateAll(
      props?.hotel?._id,
      props?.fromDate,
      props?.toDate
    );
  }, [props?.fromDate, props?.toDate]);

  useEffect(() => {
    if (sales && Array.isArray(sales) && sales?.length) {
      let expense = sales?.map((d) => {
        return {
          serviceID: d?.serviceID,
          serviceName: d?.serviceName,
          vendorName: d?.vendorName,
          category: d?.category,
          date: d?.date,
          amount: d?.amount,
          paymentMethod: d?.paymentMethod,
          invoiceNo: d?.invoiceNo,
        };
      });
      setDatas(expense);
    }
  }, [sales]);

  const tableData = [
    {
      serviceID: "P001",
      serviceName: "Puja Stage",
      vendorName: "ABC Supplies",
      category: "Event",
      date: "2024-01-12",
      amount: "$100",
      paymentMethod: "Card",
      invoiceNo: "INV-001",
    },
    {
      serviceID: "P002",
      serviceName: "Puja Stage",
      vendorName: "ABC Supplies",
      category: "Event",
      date: "2024-01-12",
      amount: "$100",
      paymentMethod: "Card",
      invoiceNo: "INV-002",
    },
    {
      serviceID: "P003",
      serviceName: "Puja Stage",
      vendorName: "ABC Supplies",
      category: "Event",
      date: "2024-01-12",
      amount: "$100",
      paymentMethod: "Card",
      invoiceNo: "INV-003",
    },
    {
      serviceID: "P004",
      serviceName: "Puja Stage",
      vendorName: "ABC Supplies",
      category: "Event",
      date: "2024-01-12",
      amount: "$100",
      paymentMethod: "Card",
      invoiceNo: "INV-004",
    },
    {
      serviceID: "P005",
      serviceName: "Puja Stage",
      vendorName: "ABC Supplies",
      category: "Event",
      date: "2024-01-12",
      amount: "$100",
      paymentMethod: "Card",
      invoiceNo: "INV-005",
    },
  ];

  const columnDefs = [
    {
      headerName: "Service ID",
      field: "serviceID",
      maxWidth: 150,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Service Name",
      field: "serviceName",
      maxWidth: 300,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Vendor Name",
      field: "vendorName",
      maxWidth: 300,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Category",
      field: "category",
      maxWidth: 300,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Date",
      field: "date",
      minWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Amount",
      field: "amount",
      maxWidth: 300,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Payment Method",
      field: "paymentMethod",
      maxWidth: 300,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Invoice No",
      field: "invoiceNo",
      maxWidth: 300,
      filter: true,
      floatingFilter: true,
    },
  ];
  return (
    <>
      <div className="w-full mt-4">
        <div className="my-4 flex items-center gap-3">
          <ReportsCard
            utility={"w-[302px]"}
            icon={totalServiceProviderIcon}
            iconBgColor={"bg-[#F1FADF]"}
            heading={"Total Service Provider"}
            value={"1258"}
          />
          <ReportsCard
            utility={"w-[302px]"}
            icon={activeServiceProviderIcon}
            iconBgColor={"bg-[#E8EBFF]"}
            heading={"Active Service Provider"}
            value={"800"}
          />
          <ReportsCard
            utility={"w-[312px]"}
            icon={inactiveServiceProviderIcon}
            iconBgColor={"bg-[#FCEEDD]"}
            heading={"Inactive Service Provider"}
            value={"1258"}
          />
          <ReportsCard
            utility={"w-[200px]"}
            icon={totalValueItem}
            iconBgColor={"bg-[#FCEAEA]"}
            heading={"Total Cost"}
            value={"$3000"}
          />
        </div>
        <div className="flex items-center justify-between my-4">
          <h2 className="text-[24px] leading-6 font-bold">Third Party Service Report</h2>
          <DownloadButton />
        </div>
        {/* {datas && datas?.length ? ( */}
        <CustomeTable
          columnDefs={columnDefs}
          rowData={tableData}
          setSelectedRows={setSelectedRows}
        />
        {/* ) : null} */}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  data: state.AssetsReport,
});

const mapDispatchToProps = (dispatch) => ({
  getAllTimeAsset: (hotelId) => dispatch(assetsReportAllTime(hotelId)),
  //get assets report by date
  getAssetsReportAll: (hotelId, assetId) =>
    dispatch(assetsReportAllTimeForOne(hotelId, assetId)),
  getAssetsFromToDateAll: (hotelId, fromDate, toDate) =>
    dispatch(assetsReportAllTimeByDate(hotelId, fromDate, toDate)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThirdPartyServiceReports);
