import React from "react";
import { replyReviewAction } from "../../actions/pms/reviews";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import FormSubmitButton from "../../components/buttons";
import { useTranslation } from "react-i18next";

const ReplyReview = ({ reviewId }) => {
  const { t } = useTranslation();
  const [reply, setReply] = useState(false);
  const [replyText, setReplyText] = useState("");
  const { hotel } = useSelector((state) => state.Hotel);
  const { loading, isreplyed } = useSelector((state) => state?.ReplyReview);
  const [clicked, setClicked] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    setClicked(true);
    dispatch(
      replyReviewAction({
        reviewId,
        hotelId: hotel?._id,
        replyText,
      })
    );
  };

  useEffect(() => {
    if (isreplyed) {
      setClicked(false);
    }
  }, [isreplyed]);

  return (
    <>
      {reply ? (
        <>
          <form onSubmit={handleSubmit}>
            <textarea
              value={replyText}
              onChange={(e) => setReplyText(e?.target?.value)}
              minLength={5}
              maxLength={350}
              className="text-sm p-2 md:min-w-[230px]"
            />
            <div className="flex items-center gap-2 w-full justify-end">
              <button
                className="relative h-10 w-fit px-6 bg-[#fa1c1c] rounded-md flex items-center text-[#FFFFFF]"
                onClick={() => setReply(false)}
                type="button"
              >
                {t("258")}
              </button>
              <FormSubmitButton loading={loading && clicked} text={t("821")} />
            </div>
          </form>
        </>
      ) : (
        <div className="flex w-full">
          <button
            className="relative h-8 w-fit px-6 bg-base-primary rounded-md flex items-center justify-end text-[#FFFFFF]"
            onClick={() => setReply(true)}
            type="button"
          >
            {t("821")}
          </button>
        </div>
      )}
    </>
  );
};

export default ReplyReview;
