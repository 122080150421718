import i18n from "i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import tar from "./translations/ar.json";
import tch from "./translations/ch.json";
import ten from "./translations/en.json";
import tes from "./translations/es.json";
import tfr from "./translations/fr.json";
import tge from "./translations/ge.json";
import tin from "./translations/in.json";
import tja from "./translations/ja.json";
import tko from "./translations/ko.json";
import tpr from "./translations/pr.json";
import tru from "./translations/ru.json";
import tsp from "./translations/sp.json";
import tta from "./translations/ta.json";

const resources = {
  ar: {
    translation: tar,
  },
  ch: {
    translation: tch,
  },
  en: {
    translation: ten,
  },
  es: {
    translation: tes,
  },
  fr: {
    translation: tfr,
  },
  ge: {
    translation: tge,
  },
  in: {
    translation: tin,
  },
  ja: {
    translation: tja,
  },
  ko: {
    translation: tko,
  },
  pr: {
    translation: tpr,
  },
  ru: {
    translation: tru,
  },
  sp: {
    translation: tsp,
  },
  ta: {
    translation: tta,
  },
};

i18n
  .use(initReactI18next)
  .use(I18nextBrowserLanguageDetector)
  .init({
    resources,
    lng: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
