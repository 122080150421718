/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { connect } from "react-redux";
import { clearError } from "../../actions/clearError";
import { authWithGoogleAction } from "../../actions/pms/googleAuth";
import { authenticateHotelOwner } from "../../actions/pms/ownerAuth";
import { ISADMIN, TOKEN } from "../../constants";
import { notifyError } from "../alert"; 
import {GoogleOAuthProvider ,GoogleLogin } from "@react-oauth/google";
import { useEffect } from "react";
import BackGroundLoadSpinner from "../loader/BackGroundLoadSpinner";
import { useTranslation } from "react-i18next";

const ContinueWithGoogle = (props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  // login with google response
  const responseSuccessGoogle = async (res) => { 
    if (res?.credential) {
      localStorage.clear();
      sessionStorage.clear();
      console.log(res)
      await props.googleLogin({ token: res?.credential });
    }
    setLoading(true);
  };

  //login with google failure
  const responseFailureGoogle = (res) => {
    setLoading(false); 
    // notifyError("Internel server error!");
  };

  // google login success
  const authUser = async () => {
    if (
      props?.googleRes &&
      props?.googleRes?.isAuthenticated &&
      props?.googleRes?.token &&
      loading &&
      typeof props?.googleRes?.token === "string"
    ) {
      setLoading(false);
      localStorage.setItem(ISADMIN, true);
      localStorage.setItem(TOKEN, props?.googleRes?.token);
      await props.getOwner(); //get owner
    }
  };
  useEffect(() => {
    authUser();
  }, [props?.googleRes]);

  // clear errors
  useEffect(() => {
    if (props?.googleRes && props?.googleRes?.error) {
      notifyError(props?.googleRes?.error);
      props.clear();
      setLoading(false);
    } 
  }, [props?.googleRes]);

  return (
    <>
      <div className="h-10 mx-auto overflow-hidden rounded relative">
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_ID} >          
        <GoogleLogin
          onSuccess={responseSuccessGoogle}
          onError={responseFailureGoogle}
          cancel_on_tap_outside={true}
          shape="circle" 
          theme="filled_blue"  
        /> 
        </GoogleOAuthProvider>
      </div>
      {loading ? <BackGroundLoadSpinner /> : null}
    </>
  );
};

const mapStateToProps = (state) => ({
  googleRes: state.GoogleAuth,
});

const mapDispatchToProps = (dispatch) => ({
  googleLogin: (data) => dispatch(authWithGoogleAction(data)),
  clear: () => dispatch(clearError()),
  getOwner: () => dispatch(authenticateHotelOwner()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContinueWithGoogle);
