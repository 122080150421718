import { ADD_TO_CART, REMOVE_CART_ITEM } from "../types/food";

// ADD TO CART
export const addItemTofoodCartAction = (data) => async (dispatch) => {
  dispatch({
    type: ADD_TO_CART,
    payload: data,
  });
};

// REMOVE FROM CART
export const removeFoodCartItem = (id) => async (dispatch) => {
  dispatch({
    type: REMOVE_CART_ITEM,
    payload: id,
  });
};
