/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { isObjEmpty } from "../../utils";
import { useTranslation } from "react-i18next";
import { TbPencil } from "react-icons/tb";
import { CustomeTable } from "../../components";

const IngredientsFor = (props) => {
  const { t } = useTranslation();
  const [datas, setDatas] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [values, setValues] = useState("");

  useEffect(() => {
    if (props?.kitchen && !isObjEmpty(props?.kitchen?.kitchen)) {
      setDatas([
        {
          ingredients: props?.kitchen?.kitchen?.ingredients,
          portionFor1: props?.kitchen?.kitchen?.portionFor1,
          portionFor2: props?.kitchen?.kitchen?.portionFor2,
          portionFor3: props?.kitchen?.kitchen?.portionFor3,
          portionFor4: props?.kitchen?.kitchen?.portionFor4,
          //   _id: props?.kitchen?.kitchen?._id,
        },
      ]);
    }
  }, [props?.kitchen]);

  const tableData = [
    {
      ingredients: "Salt",
    },
    {
      ingredients: "Black Pepper",
    },
    {
      ingredients: "Tomato",
    },
    {
      ingredients: "Rice",
    },
    {
      ingredients: "Spice",
    },
    {
      ingredients: "Onions",
    },
    {
      ingredients: "Potato",
    },
  ];

  const getInputs = () => {
    return (
      <>
        <div className="relative">
          <input
            className="h-8"
            value={values}
            onChange={(e) => setValues(e.target.value)}
          />
          <button
            type="button"
            className="flex justify-center items-center w-[22px] h-[22px] rounded hover:bg-base-primary_hover absolute top-[10px] right-4"
            // onClick={}
          >
            <TbPencil
              size={20}
              className="text-[#667085] hover:text-[#FFFFFF]"
            />
          </button>
        </div>
      </>
    );
  };
  const columnDef = [
    // for id
    {
      headerName: "",
      field: "",
      maxWidth: 30,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Ingredients",
      field: "ingredients",
      minWidth: 150,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Portion For 1 (300gm)",
      field: "portionFor1",
      filter: true,
      floatingFilter: true,
      cellRenderer: getInputs,
    },
    {
      headerName: "Portion For 2 (600gm)",
      field: "portionFor2",
      filter: true,
      floatingFilter: true,
      cellRenderer: getInputs,
    },
    {
      headerName: "Portion For 3 (900gm)",
      field: "portionFor3",
      filter: true,
      floatingFilter: true,
      cellRenderer: getInputs,
    },
    {
      headerName: "Portion For 4 (1000gm)",
      field: "portionFor4",
      filter: true,
      floatingFilter: true,
      cellRenderer: getInputs,
    },
  ];

  return (
    <div>
      <CustomeTable
        rowData={tableData}
        columnDefs={columnDef}
        setSelectedRows={setSelectedRows}
      />
    </div>
  );
};

export default IngredientsFor;
