import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createInventoryAction,
  updateItemFromInventory,
} from "../../actions/kitchen/inventoryOwner";
import LoadSpinner from "../../components/loader/LoadSpinner";
import { GrClose } from "react-icons/gr";
import {
  ITEM_QUANTITY_MEASURMENT,
  PORTION_FOR,
  PORTION_SIZE, 
} from "../../constants";
import InventoryInputVendorId from "./InventoryInputVendorId";
import MultipleImageUpload from "../../components/layouts/MultipleImageUpload";
import SaveOrNot from "../../components/layouts/SaveOrNot";
import { registerEvent } from "../../logs";
import { isObjEmpty } from "../../utils";
import SelectFromObject from "../../components/layouts/SelectFromObject";
import SelectTrueFalse from "../../components/layouts/SelectTrueFalse";
import InputItemName from "../kitchen-menu/InputItemName";
import InputItemPrice from "../kitchen-menu/InputItemPrice";
import img from "../../images/menuInventoryImage.jpg";
import ToolTip from "../../components/ToolTip";
import { useTranslation } from "react-i18next";

const HotelAddInventory = ({
  close,
  kitchen,
  hotel,
  inventory,
  setInventory,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.AddInventory);

  const { loading: updateLoading, isupdated } = useSelector(
    (state) => state.UpdateInventory
  );

  const [images, setImages] = useState([]);
  const [isMeasurable, setIsMeasurable] = useState("");
  const [isNonVeg, setIsNonVeg] = useState("");
  const [isOnlyInventoryItem, setIsOnlyInventoryItem] = useState(
    ""
  );
  //input fields state managing
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [portionFor, setPortionFor] = useState(PORTION_FOR[1]);
  const [portionSize, setPortionSize] = useState(PORTION_SIZE.full);
  const [quantityMeasurement, setQuantityMeasurement] = useState(
    ITEM_QUANTITY_MEASURMENT.PIECE
  );
  const [vendorId, setVendorId] = useState("");
  const [quantity, setQuantity] = useState("1");
  const [open, setOpen] = useState(false);

  //click open
  const handleClickOpen = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  //submiting the form input data.
  const handleSubmit = async () => {
    const formData = {
      kitchenId: kitchen._id,
      name,
      images,
      price,
      portionFor,
      quantityMeasurement,
      quantity: !isMeasurable ? "1" : quantity,
      isNonVeg,
      portionSize,
      isMeasurable,
      isOnlyInventoryItem,
    };
    if (isObjEmpty(inventory)) {
      dispatch(createInventoryAction(formData));
      registerEvent("add inventory", "inventory page", formData);
    } else {
      dispatch(updateItemFromInventory({ ...formData, itemId: inventory?.id }));
      registerEvent("update inventory", "inventory page", formData);
    }
  };

  //close modal
  const closeModal = () => {
    setOpen(false);
    registerEvent("close modal without confirm", "add inventory");
  };

  useEffect(() => {
    if (isupdated) setInventory(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isupdated]);

  useEffect(() => {
    if (!isObjEmpty(inventory)) {
      setName(inventory?.name);
      setQuantityMeasurement(inventory?.quantityMeasurement);
      setImages(inventory?.images);
      setIsNonVeg(inventory?.isNonVeg);
      setPrice(inventory?.price);
      setPortionFor(inventory?.portionFor);
      setPortionSize(inventory?.portionSize);
      setQuantity(inventory?.quantity);
    }
  }, [inventory]);

  return (
    <>
      <div className="w-full bg-[#FFFFFF] border rounded-lg">
        <div className="w-full flex flex-col p-4 sm:p-6 pt-4 relative">
          <div className="w-full mb-4">
            <h2 className="font-bold text-[30px] leading-9 text-black tracking-tighter capitalize">
              {t("871")}
            </h2>
          </div>
          <button
            className="absolute top-0 right-0 p-2 hover:rotate-90 duration-300"
            onClick={close}
          >
            <GrClose />
          </button>
          {/* form two */}
          <form className="" onSubmit={handleClickOpen}>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-6">
              <InputItemName
                label={t("166")}
                value={name}
                setValue={setName}
                toolTipMessage={t("913")}
              />
              <InputItemPrice
                label={t("120")}
                value={price}
                setValue={setPrice}
                toolTipMessage={t("932")}
              />
              <SelectFromObject
                label={t("872")}
                value={portionFor}
                setValue={setPortionFor}
                options={PORTION_FOR}
                toolTipMessage={t("933")}
              />

              <SelectFromObject
                label={t("873")}
                value={portionSize}
                setValue={setPortionSize}
                options={PORTION_SIZE}
                toolTipMessage={t("934")}
              />

              <SelectTrueFalse
                label={t("874")}
                value={isMeasurable}
                setValue={setIsMeasurable}
                options={''}
                tooltip={t("935")}
              />

              {isMeasurable ? (
                <>
                  <SelectFromObject
                    label={t("875")}
                    value={quantityMeasurement}
                    setValue={setQuantityMeasurement}
                    options={ITEM_QUANTITY_MEASURMENT}
                    toolTipMessage={t("936")}
                  />

                  <div>
                    <label className="text-sm font-semibold text-[#344054]">
                      {t("335")}
                    </label>
                    <div className="relative flex items-center">
                      <input
                        type="number"
                        name="quantity"
                        onChange={(e) => setQuantity(+e.target.value)}
                        value={quantity}
                        min={0}
                        className="border border-[#D0D5DD] w-full text-sm py-2 px-3 rounded-md focus:border-base-primary mr-2 h-10"
                      />
                      <ToolTip Message={t("937")} />
                    </div>
                  </div>
                </>
              ) : null}

              <SelectTrueFalse
                label={t("876")}
                value={isNonVeg}
                setValue={setIsNonVeg}
                options={{}}
                tooltip={t("938")}
              />

              <SelectTrueFalse
                label={t("877")}
                value={isOnlyInventoryItem}
                setValue={setIsOnlyInventoryItem}
                options={{}}
                tooltip={t("939")}
              />
              <InventoryInputVendorId value={vendorId} setValue={setVendorId} />
            </div>
            <MultipleImageUpload
              images={images}
              setImages={setImages}
              thumbnail={img}
            />

            <button
              className="w-48 our-button mt-6"
              disabled={loading || updateLoading}
            >
              {loading || updateLoading ? (
                <LoadSpinner />
              ) : isObjEmpty(inventory) ? (
                <>{t("412")}</>
              ) : (
                <>{"74"}</>
              )}
            </button>
          </form>
        </div>
      </div>
      <SaveOrNot close={closeModal} isopen={open} formSubmit={handleSubmit} />
    </>
  );
};

export default HotelAddInventory;
