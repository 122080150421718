import React from "react";

function BookingCaptureCheckBox({ label, text, value, setValue }) {
  return (
    <>
      <div
        className={`w-fit relative flex justify-between items-center p-2 py-1 h-10`}
      >
        <label
          className="text-sm font-medium text-[#344054] mr-3 capitalize"
          htmlFor={label}
        >
          {label}
        </label>
        <input
          type={"checkbox"}
          checked={value}
          id={label}
          onChange={() => setValue(!value)}
          value={value}
          className={"checkBoxControl"}
        />
        <div className="text-sm font-normal text-[#344054] ml-3 capitalize">
          {text}
        </div>
      </div>
    </>
  );
}

export default BookingCaptureCheckBox;
