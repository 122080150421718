const PROPERTY_AMENITIES = 
  {
    internetWiFi: "Internet/Wi-Fi",
    onPremiseParking: "On Premise Parking",
    "24HourFrontDeskService": "24-hour front desk service",
    swimmingPool: "Swimming pool",
    bar: "Bar",
    restaurant: "Restaurant",
    coffeeTeaInLobby: "Coffee/tea in lobby",
    vendingMachine: "Vending Machine",
    roomService: "Room Service",
    spa: "Spa",
    sauna: "Sauna",
    hotTubJacuzzi: "Hot Tub/Jacuzzi",
    gym: "Gym",
    breakfast: "Breakfast",
    shuttleService: "Shuttle Service",
    currencyExchange: "Currency Exchange",
    dryCleaning: "Dry Cleaning",
    laundry: "Laundry",
    meetingBanquetFacilities: "Meeting/Banquet Facilities",
    atmOnSite: "ATM On Site",
    "24HoursPowerBackup": "24 Hours Power Backup",
    luggageStorage: "Luggage storage",
    safetyDepositBoxes: "Safety deposit boxes",
    elevator: "Elevator",
    concierge: "Concierge",
    airportTransfer: "Airport transfer",
    rentalCar: "Rental car",
    valetParking: "Valet parking",
    tours: "Tours",
    dedicatedWorkspace: "Dedicated workspace",
    garden: "Garden",
    outdoorDiningArea: "Outdoor dining area",
    outdoorFurniture: "Outdoor furniture",
    freeParkingOnPremises: "Free parking on premises",
    fitnessCenter: "Fitness center",
    terrace: "Terrace",
    nonSmokingRooms: "Non-smoking rooms",
    familyRooms: "Family rooms",
    kidsClub: "Kids club",
    waterPark: "Water park",
    lunch: "Lunch",
    dinner: "Dinner",
    firstAidKitsAvailable: "First aid kits available",
    accessToHealthcareProfessionals: "Access to healthcare professionals",
    thermometersForGuests: "Thermometers for guests",
    faceMasksForGuestsAvailable: "Face masks for guests available",
    physicalDistancing: "Physical distancing",
    contactlessCheckInOut: "Contactless check-in/out",
    cashlessPaymentAvailable: "Cashless payment available",
    physicalDistancingRulesFollowed: "Physical distancing rules followed",
    mobileAppForRoomService: "Mobile app for room service",
    screensOrPhysicalBarriers:
      "Screens or physical barriers between staff and guests in appropriate areas",
    linensTowelsAndLaundryWashed:
      "Linens, towels, and laundry washed in accordance with local authority guidelines",
    guestAccommodationDisinfected:
      "Guest accommodation disinfected between stays",
    guestAccommodationSealed: "Guest accommodation sealed after cleaning",
    propertyCleanedByProfessionals:
      "Property cleaned by professional cleaning companies",
    optionToCancelCleaningServices:
      "Guests have the option to cancel any cleaning services for their accommodation during their stay",
    physicalDistancingInDiningAreas: "Physical distancing in dining areas",
    deliveredFoodCoveredSecurely: "Delivered food covered securely",
    guestSelfCheckInServices: "Guest self-check-in services",
    roomLevelDigitalKeyAccess: "Room-level digital key access",
    mobilePhoneUnlockBluetooth: "Mobile phone to unlock door via bluetooth",
    mobilePhoneUnlockInternet:
      "Mobile phone to unlock door via internet connection",
    preSharedPinCodeUnlock: "Pre-shared PIN/code to unlock door",
    qrCodeScan: "QR code scan",
    downloadableCheckInApp: "Downloadable check-in app",
    propertyLevelDigitalKeyAccess: "Property-level digital key access",
    tennisEquipment: "Tennis equipment",
    badmintonEquipment: "Badminton equipment",
    beach: "Beach",
    temporaryArtGalleries: "Temporary art galleries",
    barCrawls: "Bar crawls",
    movieNights: "Movie nights",
    themedDinners: "Themed dinners",
    happyHour: "Happy hour",
    tourOrClassAboutLocalCulture: "Tour or class about local culture",
    cookingClass: "Cooking class",
    liveMusicPerformance: "Live music/Performance",
    archery: "Archery",
    aerobics: "Aerobics",
    bingo: "Bingo",
    tennisCourt: "Tennis court",
    poolTable: "Pool table",
    pingPong: "Ping-pong",
    darts: "Darts",
    racquetball: "Racquetball",
    bowling: "Bowling",
    miniGolf: "Mini golf",
    waterSportsFacilitiesOnSite: "Water sports facilities on site",
    windsurfing: "Windsurfing",
    diving: "Diving",
    snorkeling: "Snorkeling",
    canoeing: "Canoeing",
    fishing: "Fishing",
    horsebackRiding: "Horseback riding",
    cycling: "Cycling",
    hiking: "Hiking",
    skiing: "Skiing",
    kidsMeals: "Kids meals",
    wineChampagne: "Wine/Champagne",
    coffeeHouseOnSite: "Coffee house on site",
    snackBar: "Snack bar",
    groceryDeliveries: "Grocery deliveries",
    packedLunches: "Packed lunches",
    vendingMachineDrinks: "Vending machine (drinks)",
    vendingMachineSnacks: "Vending machine (snacks)",
    specialDietMealsOnRequest: "Special diet meals (on request)",
    breakfastInTheRoom: "Breakfast in the room",
    beachChairsLoungers: "Beach chairs/Loungers",
    beachUmbrellas: "Beach umbrellas",
    beautyServices: "Beauty services",
    massageChair: "Massage chair",
    yogaClasses: "Yoga classes",
    lockerRooms: "Locker rooms",
    kidsPool: "Kids pool",
    turkishSteamBath: "Turkish/Steam Bath",
    solarium: "Solarium",
    openAirBath: "Open-air bath",
    atmOnSiteMeals: "ATM on site meals",
    picnicArea: "Picnic area",
    sunDeck: "Sun deck",
    chapelShrine: "Chapel/Shrine",
    indoorPlayArea: "Indoor play area",
  }

export { PROPERTY_AMENITIES };
