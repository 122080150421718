import React from "react";
import Seperator from "../../components/layouts/Seperator";
import { useTranslation } from "react-i18next";
import { BiArrowBack } from "react-icons/bi";
import { ROUTES } from "../../constants";
import DetailsHeading from "../../components/layouts/DetailsHeading";
import { useNavigate } from "react-router-dom";

const ExpenseDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const track = {
    heading: t("Details"),
    seperator: [
      {
        text: t("241"),
      },
      {
        text: t("Expenses"),
        link: ROUTES.EXPENSES,
      },
      {
        text: t("Details"),
      },
    ],
  };
  const backExpenseDetails = () => {
    navigate(ROUTES.EXPENSES);
  };
  return (
    <div className="page-container max-w-7xl">
      <div className=" flex justify-between items-end">
        <Seperator data={track} />
        <button
          className="h-10 w-[89px] border rounded-md bg-[#FFFFFF] hover:bg-gray-50 flex items-center relative"
          onClick={() => backExpenseDetails()}
        >
          <BiArrowBack size={14} className="text-[#252C32] absolute left-4" />
          <div className="text-[14px] leading-6 font-normal pl-10">
            {t("236")}
          </div>
        </button>
      </div>
      <div className="my-4 text-[24px] leading-[30.24px] font-bold tracking-[-0.8px]">
        {t("Expense Information")}
      </div>
      <div className="border border-[#D0D5DD] rounded-[10px] bg-[#FFFFFF] grid grid-cols-4 p-6 gap-4">
        <DetailsHeading heading={"Expense ID"} subHeading={"EX001"} />
        <DetailsHeading heading={"Expense Name"} subHeading={"Expense-1"} />
        <DetailsHeading heading={"Type"} subHeading={"Maintenance"} />
        <DetailsHeading heading={"Date"} subHeading={"17-04-2024"} />
        <DetailsHeading heading={"Frequency"} subHeading={"One Time"} />
        <DetailsHeading heading={"Amount"} subHeading={"$ 10,000"} />
        <DetailsHeading heading={"Invoice Number"} subHeading={"123"} />
      </div>
      <div className="my-4 text-[24px] leading-[30.24px] font-bold tracking-[-0.8px]">
        {t("Payment Approval")}
      </div>
      <div className="border border-[#D0D5DD] rounded-[10px] bg-[#FFFFFF] grid grid-cols-4 p-6 gap-4">
        <DetailsHeading heading={"Paid Amount"} subHeading={"$ 10,000"} />
        <DetailsHeading heading={"Payment Date"} subHeading={"17-04-2024"} />
        <DetailsHeading heading={"Payment Approvel By"} subHeading={"CEO"} />
        <DetailsHeading heading={"Status"} subHeading={"Approved"} />
        <DetailsHeading
          heading={"Payment Method"}
          subHeading={"Bank Transfer"}
        />
        <DetailsHeading
          heading={"Transaction Id"}
          subHeading={"863484vd634278"}
        />
        <DetailsHeading heading={"Tax"} subHeading={"0%"} />
      </div>
    </div>
  );
};

export default ExpenseDetails;
