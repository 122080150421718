import React, { useRef } from "react";
import { RxCross2 } from "react-icons/rx";
import { LuPrinter } from "react-icons/lu";
import { useOutsideClick } from "../../utils";

const QrPrints = ({ setIsOpen, close }) => {
  const closeModalRef = useRef(null);
  useOutsideClick(closeModalRef, setIsOpen);

  return (
    <div className="fixed flex items-center justify-center top-0 right-0 left-0 bottom-0 bg-[#7a7a7a61] border h-full z-[10000]">
      <div
        className="relative w-[40vw] rounded-[10px] bg-white"
        ref={closeModalRef}
      >
        <RxCross2
          onClick={close}
          size={26}
          className="absolute right-3 top-3 hover:rotate-90 duration-500 hover:text-[#F04438]"
        />
        <div className="flex items-center justify-between text-[24px] leading-[30.24px] font-semibold tracking-[-1.5%] text-[#07090D] pt-10 px-8">
          <h2>Hotal Name</h2>
          <h2>Room#102</h2>
        </div>
        <div className="w-full px-8 mt-6">
          {/* qrcode */}
          <div className="flex items-center justify-center">
            <div className="border border-[#E5E9EB] p-3 rounded shadow-base">
              <img
                src={`https://w7.pngwing.com/pngs/144/829/png-transparent-qr-code-information-qr-code-android-qrcode-text-rectangle-monochrome-thumbnail.png`}
                alt="Qr code imgage"
                className="h-[200px] w-[200px]"
              />
              <div className="text-2xl font-bold tracking-[-1.5%] text-[#07090D] flex justify-center mt-2">
                Scane Me
              </div>
            </div>
          </div>
          {/* list */}
          <div className="flex items-start justify-between text-lg font-bold tracking-[-1.5%] text-[#07090D] gap-20 mt-6 ">
            <ul className="list-disc list-inside marker:text-base-primary">
              <li>For Food Menu</li>
              <li>To Order Food</li>
              <li>For Room Service</li>
              <li>To Connect Wifi</li>
            </ul>
            <ul className="list-disc marker:text-base-primary">
              <li>To Call Reception</li>
              <li>To Call HouseKeeping</li>
              <li>And More Services</li>
            </ul>
          </div>
        </div>
        <div className="flex items-center justify-end pr-8 pb-4">
          <button
            type="button"
            className="h-10 w-[134px] rounded-md flex items-center justify-center gap-2 shadow-[0_1px_2px_0_rgba(16,24,40,0.05)] bg-base-primary hover:bg-base-primary_hover text-sm font-medium text-white"
            // onClick={handlePrint}
          >
            <LuPrinter size={20} />
            Print
          </button>
        </div>
      </div>
    </div>
  );
};

export default QrPrints;
