import React from "react";
import { useTranslation } from "react-i18next";

function OtherInformation({
  emailBookingVoucher,
  setEmailBookingVoucher,
  internalNotes,
  setInternalNotes,
}) {
  const { t } = useTranslation();
  return (
    <div className="px-6 py-4 border-t max-w-full overflow-auto bg-[#FFFFFF] w-full">
      <div className="font-bold text-[20px] leading-[20px]">{t("827")}</div>
      <div className="flex gap-2 pt-3 pb-2">
        <input
          type="checkbox"
          className="h-4 w-4 mt-1 accent-current"
          value={emailBookingVoucher}
          onChange={(e) => setEmailBookingVoucher(!emailBookingVoucher)}
        />
        <label
          htmlFor="label"
          className="text-[14px] leading-6 font-normal text-[#07090D]"
        >
          {t("549")}
        </label>
      </div>
      <div>
        <label className="text-sm font-medium text-[#344054]">{t("550")}</label>
        <textarea
          type="text"
          placeholder={t("551")}
          className="h-32 rounded-lg p-3 border border-[#D0D5DD] w-full"
          value={internalNotes}
          onChange={(e) => setInternalNotes(e?.target?.value)}
        ></textarea>
      </div>
    </div>
  );
}

export default OtherInformation;
