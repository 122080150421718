import React, { useEffect, useState } from "react";
import { isObjEmpty } from "../../utils";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants";
import UploadButton from "../../components/buttons/UploadButton";
import DownloadButton from "../../components/buttons/DownloadButton";
import { CustomeTable } from "../../components";

const AllAlaCarte = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [datas, setDatas] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  useEffect(() => {
    if (props?.kitchen && !isObjEmpty(props?.kitchen?.kitchen)) {
      setDatas([
        {
          //   Kitchen_id: "1214",
          itemId: props?.kitchen?.kitchen?.itemId,
          itemName: props?.kitchen?.kitchen?.itemName,
          itemType: props?.kitchen?.kitchen?.itemType,
          non_veg: props?.kitchen?.kitchen?.non_veg,
          ave_cost_per_kg: props?.kitchen?.kitchen?.ave_cost_per_kg,
          ave_cost_per_gm: props?.kitchen?.kitchen?.ave_cost_per_gm,
          quantity_per_10: props?.kitchen?.kitchen?.quantity_per_10,
          available: props?.kitchen?.kitchen?.available,
          _id: props?.kitchen?.kitchen?._id,
        },
      ]);
    }
  }, [props?.kitchen]);
  //set kitchen data and redirect to another page
  const setKitchenAndRedirect = (link) => {
    if (link) {
      navigate(link);
    }
  };
  const rowData = [
    {
      itemId: "IT01",
      itemName: "Chicken Wings",
      itemType: "Appetizer",
      non_veg: "Yes",
      ave_cost_per_kg: "$ 100",
      ave_cost_per_gm: "$ 0.01",
      quantity_per_10: "4/10",
      available: "Regular",
    },
    {
      itemId: "IT02",
      itemName: "Chicken Wings",
      itemType: "Main Course",
      non_veg: "Yes",
      ave_cost_per_kg: "$ 200",
      ave_cost_per_gm: "$ 0.05",
      quantity_per_10: "10/10",
      available: "Regular",
    },
    {
      itemId: "IT03",
      itemName: "Caesar Salad",
      itemType: "Salad",
      non_veg: "No",
      ave_cost_per_kg: "$ 200",
      ave_cost_per_gm: "$ 0.05",
      quantity_per_10: "6/10",
      available: "Weekdays",
    },
    {
      itemId: "IT04",
      itemName: "Beef Steak",
      itemType: "Main Dish",
      non_veg: "Yes",
      ave_cost_per_kg: "$ 300",
      ave_cost_per_gm: "$ 0.15",
      quantity_per_10: "1/10",
      available: "Weekend",
    },
    {
      itemId: "IT05",
      itemName: "Kheer",
      itemType: "Dessert",
      non_veg: "Yes",
      ave_cost_per_kg: "$ 600",
      ave_cost_per_gm: "$ 0.18",
      quantity_per_10: "5/10",
      available: "Regular",
    },
  ];
  const ViewMapIngredientAction = () => {
    return (
      <>
        <button
          className="w-[133px] h-[32px] rounded-md text-[14px] leading-[14px] tracking-[-0.6%] text-[#F6F8F9] bg-base-primary hover:bg-base-primary_hover"
          onClick={() => setKitchenAndRedirect(ROUTES.KITCHEN_MENU_INGREDIENTS)}
        >
          {t("1085")}
        </button>
      </>
    );
  };
  const columnDefs = [
    {
      headerName: "Item ID",
      field: "itemId",
      maxWidth: 120,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Item Name",
      field: "itemName",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Item Type",
      field: "itemType",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Non-Veg",
      field: "non_veg",
      maxWidth: 150,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Avg Cost Per Kg",
      field: "ave_cost_per_kg",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Avg Cost Per Gm",
      field: "ave_cost_per_gm",
      maxWidth: 120,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Quantity Per 10",
      field: "quantity_per_10",
      maxWidth: 120,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Available",
      field: "available",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Actions",
      field: "_id",
      maxWidth: 450,
      minWidth: 160,
      cellRenderer: ViewMapIngredientAction,
    },
  ];
  return (
    <div>
      <div className="flex items-center justify-between mb-4">
        <div className="text-[22px] leading-[27.72px] text-black font-bold">
          Ala Carte Table
        </div>
        <div className="flex items-center gap-4">
          <UploadButton />
          <DownloadButton />
        </div>
      </div>
      <CustomeTable
        columnDefs={columnDefs}
        rowData={rowData}
        setSelectedRows={setSelectedRows}
      />
    </div>
  );
};

export default AllAlaCarte;
