import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomeTable } from "../../components";

function RoomSummary() {
  const { t } = useTranslation();
  const [selectedRows, setSelectedRows] = useState([]);
  const rowData = [
    {
      sn: "01",
      roomType: "Deluxe",
      rateType: "Americon Plane(AP)",
      noOfRooms: "1",
      roomNo: "101",
      discount: "12%",
      roomTarrif: "$1000",
      totaleRoomTarrif: "$1000",
    },
    {
      sn: "01",
      roomType: "Suit",
      rateType: "European Plane(EP)",
      noOfRooms: "1",
      roomNo: "101",
      discount: "12%",
      roomTarrif: "$1000",
      totaleRoomTarrif: "$1000",
    },
  ];

  const columnDefs = [
    {
      headerName: "",
      field: "_id",
      maxWidth: 30,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Room Type",
      field: "roomType",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Rate Type",
      field: "rateType",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "No. Of Rooms",
      field: "noOfRooms",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Room No",
      field: "roomNo",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Discount",
      field: "discount",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Room Tarrif",
      field: "roomTarrif",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Totale Room Tarrif",
      field: "totaleRoomTarrif",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
  ];
  return (
    <div className="px-6 pt-4 border-t max-w-full overflow-y-auto w-full">
      <div className="font-bold text-[20px] leading-[20px]">{t("586")}</div>
      <div className="mt-4 mb-6">
        <CustomeTable
          columnDefs={columnDefs}
          rowData={rowData}
          setSelectedRows={setSelectedRows}
        />
      </div>
    </div>
  );
}

export default RoomSummary;
