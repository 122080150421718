import React, { useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import BuffetTable from "./BuffetTable";
import RealTimeAlacarteTable from "./RealTimeAlacarteTable";

const AllKitchenOperations = () => {
  const [isShowBuffetTable, setIsShowBuffetTable] = useState(false);
  const [isAlacarteTable, setAlacarteTable] = useState(true);

  function handleBuffetTable() {
    setIsShowBuffetTable(!isShowBuffetTable);
  }
  function handleAlaCarteTable() {
    setAlacarteTable(!isAlacarteTable);
  }
  return (
    <div className="">
      <div
        className={`relative border border-[#E5E9EB] bg-[#FFFFFF] w-full mb-4 ${
          isShowBuffetTable ? "h-auto" : "h-[40px]"
        }`}
      >
        <div className="flex items-center justify-center">
          <button
            type="button"
            className="text-[14px] leading-[16.94px] font-medium text-[#07090D] text-center w-full mt-[10px]"
            onClick={handleBuffetTable}
          >
            Buffet Table
          </button>
          <div className="absolute right-2 top-3">
            {isShowBuffetTable ? (
              <IoIosArrowForward
                className={`${
                  isShowBuffetTable ? "rotate-90 duration-500" : ""
                }`}
              />
            ) : (
              <IoIosArrowForward
                className={`${!isShowBuffetTable ? " duration-500" : ""}`}
              />
            )}
          </div>
        </div>
        <div className="relative w-full mx-auto max-w-7xl px-4 py-3">
          {isShowBuffetTable ? <BuffetTable /> : null}
        </div>
      </div>
      {/* Real time ala carte table */}
      <div
        className={`relative border border-[#E5E9EB] bg-[#FFFFFF] w-full ${
          isAlacarteTable ? "h-auto" : "h-[40px]"
        }`}
      >
        <div className="flex items-center justify-center">
          <button
            type="button"
            className="text-[14px] leading-[16.94px] font-medium text-[#07090D] text-center w-full mt-[10px]"
            onClick={handleAlaCarteTable}
          >
            Real Time Alacarte Table
          </button>
          <div className="absolute right-2 top-3">
            {isAlacarteTable ? (
              <IoIosArrowForward
                className={`${isAlacarteTable ? "rotate-90 duration-500" : ""}`}
              />
            ) : (
              <IoIosArrowForward
                className={`${!isAlacarteTable ? " duration-500" : ""}`}
              />
            )}
          </div>
        </div>
        <div className="relative w-full mx-auto max-w-7xl px-4 py-3">
          {isAlacarteTable ? <RealTimeAlacarteTable /> : null}
        </div>
      </div>
    </div>
  );
};

export default AllKitchenOperations;
