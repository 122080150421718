import React from "react";
import ToolTip from "../../components/ToolTip";
import { useTranslation } from "react-i18next";


const InputItemPrice = ({ label, value, setValue }) => {
  const {t} = useTranslation()
  return (
    <>
      <div>
        <label className="text-sm font-semibold text-[#344054]"> {label}</label>
        <div className="relative flex items-center">
          <input
            type="number"
            onChange={(e) => setValue(+e.target.value)}
            value={value}
            className="border border-[#D0D5DD] w-full text-sm py-2 px-3 rounded-md focus:border-base-primary mr-2 h-10"
          />
          <ToolTip Message={t("this is tooltip")} />
        </div>
      </div>
    </>
  );
};

export default InputItemPrice;
