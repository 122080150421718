import React from "react";

const NewCustomer = ({ image }) => {
  return (
    <>
      <div className="flex w-full py-2 items-center ">
        <div className="w-9 h-9 min-w-[36px] rounded-full relative overflow-hidden">
          <img
            className="h-full w-full absolute top-0 left-0 object-cover"
            src={image}
            alt=""
          />
        </div>
        <div className="font-bold text-[13px] leading-[22px] pl-4">
          Jenny Wilson
          <div className="w-full h-[22px] text-[13px] leading-[22px] font-pjs font-normal text-[#71717A]">
            w.lawson@example.com
          </div>
        </div>
      </div>
    </>
  );
};

export default NewCustomer;
