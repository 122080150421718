import React, { useState } from "react";
import { connect } from "react-redux";
import { deleteRoomAction } from "../../actions/pms/roomdelete";
import { DELETE_ROOM_RESET } from "../../actions/types/room";
import { clearError } from "../../actions/clearError";
import { getAllRoomAction } from "../../actions/pms/roomget";
import { CustomeTable } from "../../components";
import RoomAction from "./RoomAction";
import PrintButton from "../../components/buttons/PrintButton";
import UploadButton from "../../components/buttons/UploadButton";
import DownloadButton from "../../components/buttons/DownloadButton";

export const YesNo = () => {
  return (
    <>
      <div className={true ? "text-red-500" : "text-green-500"}>
        {true ? "Yes" : "No"}
      </div>
    </>
  );
};

const AllRoomType = (props) => {
  const [selectedRows, setSelectedRows] = useState([]);

  const rowData = [
    {
      _id: 1255472734423,
      roomId: 2389,
      name: "Delux",
      numberOfRooms: 3,
      roomNumbers: [123, 2323, 342],
      aduls: 3,
      children: 2,
      ac: true,
      balcony: true,
      smoking: true,
      price: 343,
      id: 23432,
    },
    {
      _id: 1255347523423,
      roomId: 2389,
      name: "Delux",
      numberOfRooms: 3,
      roomNumbers: [123, 2323, 342],
      aduls: 3,
      children: 2,
      ac: true,
      balcony: true,
      smoking: true,
      price: 343,
      id: 23432,
    },
    {
      _id: 1255474234023,
      roomId: 2389,
      name: "Delux",
      numberOfRooms: 3,
      roomNumbers: [123, 2323, 342],
      aduls: 3,
      children: 2,
      ac: true,
      balcony: true,
      smoking: true,
      price: 343,
      id: 23432,
    },
    {
      _id: 1255247237423,
      roomId: 2389,
      name: "Delux",
      numberOfRooms: 3,
      roomNumbers: "123, 2323, 342",
      aduls: 3,
      children: 2,
      ac: true,
      balcony: true,
      smoking: true,
      price: 343,
      id: 23432,
    },
  ];

  const columnDefs = [
    {
      headerName: "Room ID",
      field: "_id",
      maxWidth: 150,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    // {
    //   headerName: "Room Id",
    //   field: "roomId",
    //   filter: true,
    //   floatingFilter: true,
    // },
    { headerName: "Name", field: "name", filter: true, floatingFilter: true },
    {
      headerName: "Rooms",
      field: "numberOfRooms",
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Room No",
      field: "roomNumbers",
      filter: true,
      floatingFilter: true,
    },
    { headerName: "aduls", field: "aduls", filter: true, floatingFilter: true },
    {
      headerName: "children",
      field: "children",
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "ac",
      field: "ac",
      cellRenderer: YesNo,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "balcony",
      field: "balcony",
      cellRenderer: YesNo,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "smoking",
      field: "smoking",
      cellRenderer: YesNo,
      filter: true,
      floatingFilter: true,
    },
    { headerName: "price", field: "price", filter: true, floatingFilter: true },
    {
      headerName: "Actions",
      field: "id",
      cellRenderer: RoomAction,
    },
  ];

  return (
    <>
      <div className="flex items-center justify-between my-4">
        <div className="text-[22px] leading-[27.72px] text-black font-bold">
          Room Type Table
        </div>
        <div className="flex items-center gap-4">
          <PrintButton />
          <UploadButton />
          <DownloadButton />
        </div>
      </div>
      <CustomeTable
        rowData={rowData}
        setSelectedRows={setSelectedRows}
        columnDefs={columnDefs}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  rooms: state.AllRoom,
  delete: state.DeleteRoom,
});

const mapDispatchToProps = (dispatch) => ({
  deleteRoom: (roomId, hotelId) => dispatch(deleteRoomAction(roomId, hotelId)),
  deletereset: () => dispatch({ type: DELETE_ROOM_RESET }),
  clear: () => dispatch(clearError()),
  getRooms: (hotelId) => dispatch(getAllRoomAction(hotelId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AllRoomType);
