import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomeTable } from "../../components";

function BillingSummary() {
  const { t } = useTranslation();
  const [selectedRows, setSelectedRows] = useState([]);
  const rowData = [
    {
      roomType: "Deluxe",
      totalRooms: "1",
      roomTarrif: "2",
      totalNights: "4",
      cgst: "$200",
      sgst: "$200",
      igst: "$200",
      ut: "$200",
      vat: "$200",
      totleRoomTarrif: "$600",
    },
    {
      roomType: "Suit",
      totalRooms: "1",
      roomTarrif: "2",
      totalNights: "4",
      cgst: "$100",
      sgst: "$50",
      igst: "$50",
      ut: "$200",
      vat: "$600",
      totleRoomTarrif: "$3500",
    },
  ];

  const columnDefs = [
    {
      headerName: "",
      field: "_id",
      maxWidth: 30,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Room Type",
      field: "roomType",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Total Rooms",
      field: "totalRooms",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Room Tarrif",
      field: "roomTarrif",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Total Nights",
      field: "totalNights",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "CGST",
      field: "cgst",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "SGST",
      field: "sgst",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },

    {
      headerName: "IGST",
      field: "igst",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },

    {
      headerName: "UT",
      field: "ut",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "VAT",
      field: "vat",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Totle Room Tarrif",
      field: "totleRoomTarrif",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
  ];
  return (
    <div className="my-4 px-6 max-w-full w-full overflow-auto border border-[#DDE2E4] rounded-lg bg-white">
      <div className="text-[20px] leading-5 font-bold py-4">{t("147")}</div>
      <div className="mt-4 mb-6">
        <CustomeTable
          columnDefs={columnDefs}
          rowData={rowData}
          setSelectedRows={setSelectedRows}
        />
      </div>
    </div>
  );
}

export default BillingSummary;
