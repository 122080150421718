import { CLEAR_ERRORS } from "../../actions/types";
import {
  DELETE_BOOKING_FAIL,
  DELETE_BOOKING_REQUEST,
  DELETE_BOOKING_RESET,
  DELETE_BOOKING_SUCCESS,
  ADD_BOOKING_FAIL,
  ADD_BOOKING_REQUEST,
  ADD_BOOKING_RESET,
  ADD_BOOKING_SUCCESS,
  UPDATE_BOOKING_FAIL,
  UPDATE_BOOKING_REQUEST,
  UPDATE_BOOKING_RESET,
  UPDATE_BOOKING_SUCCESS,
} from "../../actions/types/booking";

//add booking reducer
export const addBookingReducer = (state = { booking: {} }, action) => {
  switch (action.type) {
    case ADD_BOOKING_REQUEST:
      return {
        ...state,
        loading: true,
        isbooked: false,
      };
    case ADD_BOOKING_SUCCESS:
      return {
        ...state,
        loading: false,
        booking: action.payload,
        isbooked: true,
      };
    case ADD_BOOKING_FAIL:
      return {
        ...state,
        loading: false,
        isbooked: false,
        error: action.payload,
      };
    case ADD_BOOKING_RESET:
      return {
        ...state,
        loading: false,
        isbooked: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

//update booking reducer
export const updateBookingReducer = (state = { booking: {} }, action) => {
  switch (action.type) {
    case UPDATE_BOOKING_REQUEST:
      return {
        ...state,
        loading: true,
        isupdated: false,
      };
    case UPDATE_BOOKING_SUCCESS:
      return {
        ...state,
        loading: false,
        booking: action.payload,
        isupdated: true,
      };
    case UPDATE_BOOKING_FAIL:
      return {
        ...state,
        loading: false,
        isupdated: false,
        error: action.payload,
      };
    case UPDATE_BOOKING_RESET:
      return {
        ...state,
        loading: false,
        isupdated: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

//delete booking reducer
export const deleteBookingReducer = (state = { message: "" }, action) => {
  switch (action.type) {
    case DELETE_BOOKING_REQUEST:
      return {
        ...state,
        loading: true,
        isdeleted: false,
      };
    case DELETE_BOOKING_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload,
        isdeleted: true,
      };
    case DELETE_BOOKING_FAIL:
      return {
        ...state,
        loading: false,
        isdeleted: false,
        error: action.payload,
      };
    case DELETE_BOOKING_RESET:
      return {
        ...state,
        loading: false,
        isdeleted: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};