import { CLEAR_ERRORS } from "../../actions/types";
import {
  ASSIGN_HOUSE_KEEPING_FAIL,
  ASSIGN_HOUSE_KEEPING_REQUEST,
  ASSIGN_HOUSE_KEEPING_SUCCESS,
  HOUSE_KEEPING_ROOM_FAIL,
  HOUSE_KEEPING_ROOM_REQUEST,
  HOUSE_KEEPING_ROOM_SUCCESS,
  HOUSE_KEEPING_ROOM_UPDATE_FAIL,
  HOUSE_KEEPING_ROOM_UPDATE_REQUEST,
  HOUSE_KEEPING_ROOM_UPDATE_RESET,
  HOUSE_KEEPING_ROOM_UPDATE_SUCCESS,
} from "../../actions/types/houseKeeping";

//GET DIRTY ROOMS - EMPLOYEE
export const dirtyRoomsReducer = (state = { rooms: [] }, action) => {
  switch (action.type) {
    case HOUSE_KEEPING_ROOM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case HOUSE_KEEPING_ROOM_SUCCESS:
      return {
        ...state,
        loading: false,
        rooms: action.payload,
      };
    case HOUSE_KEEPING_ROOM_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

//UPDATE ROOM STATUS - EMPLOYEE
export const updateRoomStatusReducer = (state = { message: [] }, action) => {
  switch (action.type) {
    case HOUSE_KEEPING_ROOM_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        isupdated: false,
      };
    case HOUSE_KEEPING_ROOM_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isupdated: true,
        message: action.payload,
      };
    case HOUSE_KEEPING_ROOM_UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        isupdated: false,
        error: action.payload,
      };
    case HOUSE_KEEPING_ROOM_UPDATE_RESET:
      return {
        ...state,
        loading: false,
        isupdated: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};



//UPDATE ROOM STATUS 
export const assignHouseKeepingReducer = (state = { room: [] }, action) => {
  switch (action.type) {
    case ASSIGN_HOUSE_KEEPING_REQUEST:
      return {
        ...state,
        loading: true,
        isupdated: false,
      };
    case ASSIGN_HOUSE_KEEPING_SUCCESS:
      return {
        ...state,
        loading: false,
        isupdated: true,
        room: action.payload,
      };
    case ASSIGN_HOUSE_KEEPING_FAIL:
      return {
        ...state,
        loading: false,
        isupdated: false,
        error: action.payload,
      }; 
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
