import React from "react";
import { useTranslation } from "react-i18next";
import { RiDeleteBin5Line } from "react-icons/ri";

const DeleteButton = ({
  onClick = () => {},
  disabled = true,
  sizing = "w-[103px] h-10",
  text=""
}) => {
  const { t } = useTranslation();
  return (
    <button
      className={`${sizing} relative  border rounded-md bg-[#FF2727] flex items-center justify-center gap-2 text-[#F6F8F9] transition-all duration-[0.3s] ease-[0s] hover:bg-[#aa1a1a]`}
      onClick={onClick}
      disabled={disabled}
      type="button"
    >
      <RiDeleteBin5Line size={15} />
      <span className="tracking-[-0.006em] text-[14px] leading-[24px] font-normal capitalize">
        {text}
      </span>
    </button>
  );
};

export default DeleteButton;
