import axios from "axios";
import { TOKEN } from "../../constants";

import {
  DELETE_BOOKING_FAIL,
  DELETE_BOOKING_REQUEST,
  DELETE_BOOKING_SUCCESS,
  ADD_BOOKING_FAIL,
  ADD_BOOKING_REQUEST,
  ADD_BOOKING_SUCCESS,
  UPDATE_BOOKING_FAIL,
  UPDATE_BOOKING_REQUEST,
  UPDATE_BOOKING_SUCCESS,
} from "../types/booking";

//add booking action
export const addBookingAction = (formData) => async (dispatch) => {
  let url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/employee/booking`;
  try {
    dispatch({ type: ADD_BOOKING_REQUEST });
    const { data } = await axios.post(url, formData, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: ADD_BOOKING_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADD_BOOKING_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

//update booking action
export const updateBookingAction = (formData) => async (dispatch) => {
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/employee/booking`;
  try {
    dispatch({ type: UPDATE_BOOKING_REQUEST });
    const { data } = await axios.put(url, formData, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: UPDATE_BOOKING_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_BOOKING_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

// delete booking action
export const deleteBookingAction = (bookingId, hotelId) => async (dispatch) => {
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/employee/booking`;

  try {
    dispatch({ type: DELETE_BOOKING_REQUEST });
    const { data } = await axios.delete(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
      data: { bookingId, hotelId },
    });
    dispatch({
      type: DELETE_BOOKING_SUCCESS,
      payload: data.message,
    });
  } catch (error) {
    dispatch({
      type: DELETE_BOOKING_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};
