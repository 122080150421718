import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants";
import { isObjEmpty } from "../../utils";
import { registerEvent } from "../../logs";
import AddInventory from "./AddInventory";
import AllInventory from "./AllInventory";
import { useTranslation } from "react-i18next";
import { getOwnerInventoryAction } from "../../actions/kitchen/inventoryOwner";
import AddButton from "../../components/buttons/AddButton";
import Seperator from "../../components/layouts/Seperator";
import DeleteButton from "../../components/buttons/DeleteButton";
import EditButton from "../../components/buttons/EditButton";
import AddQuantity from "./AddQuantity";

const InventoryPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [addButton, setAddButton] = useState(false);
  const [addQuantity, setAddQuantity] = useState(false);
  const [updateInventory, setUpdateInventory] = useState(false);
  const [showUpdateInventory, setShowUpdateInventory] = useState(false);
  const { kitchen, loading: gettingKitchen } = useSelector(
    (state) => state.Kitchen
  );
  const { hotel } = useSelector((state) => state.Hotel);
  const { isadmin } = useSelector((state) => state.IsAdmin);
  const [inventory, setInventory] = useState({});

  //register event for Add inventory
  const registerEventAndOpen = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setAddButton(true);
    setUpdateInventory(true);
    setShowUpdateInventory(true);
    registerEvent("add inventory button open", "add item inventory", {
      addButton,
      updateInventory,
      showUpdateInventory,
    });
  };
  // register evnet on close add inventry component
  const close = () => {
    if (!isObjEmpty(inventory)) setInventory(null);
    setAddButton(false);
    setUpdateInventory(false);
    setShowUpdateInventory(false);
    registerEvent("add inventory button close", "add item inventory", {
      addButton,
      updateInventory,
      showUpdateInventory,
    });
  };
  //register event for add quantity
  const registerEventAndOpened = () => {
    setAddQuantity(true);
    registerEvent("add Quantity button open", "add item Quantity", {
      addQuantity,
    });
  };

  // register evnet on close add quantity component
  const closed = () => {
    if (!isObjEmpty(inventory)) setInventory(null);
    setAddQuantity(false);
    registerEvent("add Quantity button close", "add item Quantity", {
      addQuantity,
    });
  };

  // if there no kitchen
  useEffect(() => {
    if (isObjEmpty(kitchen) && !gettingKitchen && isadmin)
      navigate(ROUTES.KITCHENS);
    else if (kitchen) {
      dispatch(getOwnerInventoryAction(kitchen?._id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kitchen]);

  const data = {
    heading: t("Kladee's Cafe - Inventory"),
    seperator: [
      {
        text: t("134"),
      },
      {
        link: ROUTES.KITCHENS,
        text: t("36"),
      },
      { text: t("44") },
    ],
  };

  return (
    <>
      <div className="page-container max-w-7xl">
        <div className="w-full max-w-full">
          <div className="flex justify-between items-end">
            <Seperator data={data} />
            <div className="flex gap-2">
              {/* text={t("1035")} */}
              <EditButton />
              <DeleteButton />
              <AddButton text={t("1113")} onClick={registerEventAndOpen} />
            </div>
          </div>
          {!isObjEmpty(inventory) || addButton ? (
            <AddInventory
              close={close}
              kitchen={kitchen}
              hotel={hotel}
              inventory={inventory}
              setInventory={setInventory}
            />
          ) : null}
        </div>
        {/* add quantity */}
        {!isObjEmpty(inventory) || addQuantity ? (
          <AddQuantity close={closed} />
        ) : null}

        <AllInventory
          updateInventory={registerEventAndOpen}
          isShowUpdate={showUpdateInventory}
          kitchen={kitchen}
          hotel={hotel}
          setInventory={setInventory}
          isadmin={isadmin}
          handleAddQuantity={registerEventAndOpened}
        />
      </div>
    </>
  );
};

export default InventoryPage;
