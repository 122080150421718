import React from "react";
import Navigation from "./Navigation";
import { useTranslation } from "react-i18next";

const HousekeepingHeader = ({ params }) => {
  const { t } = useTranslation();

  return (
    <div className="lg:flex lg:flex-row items-center lg:gap-[25px] md:gap-4 mx-auto md:grid md:grid-cols-3">
      <Navigation text={t("76")} params={params} index={1} />
      <Navigation text={t("77")} params={params} index={2} />
      <Navigation
        utility={"w-fit md:px-4 lg:px-6"}
        text={t("78")}
        params={params}
        index={3}
      />
      <Navigation text={t("Issue Reported")} params={params} index={4} />
      <Navigation text={t("Amenities")} params={params} index={5} />
    </div>
  );
};

export default HousekeepingHeader;
