import {
  GOOGLE_AUTH_FAIL,
  GOOGLE_AUTH_REQUEST,
  GOOGLE_AUTH_SUCCESS,
} from "../../actions/types/googleAuth";
import { CLEAR_ERRORS } from "../../actions/types";

//hotel owner auth reducer
export const googleAuthReducer = (state = { token: "" }, action) => {
  switch (action.type) {
    case GOOGLE_AUTH_REQUEST:
      return {
        loading: true,
        isAuthenticated: false,
      };
    case GOOGLE_AUTH_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        token: action.payload,
      };
    case GOOGLE_AUTH_FAIL:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        token: null,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
