import {React, useState, useEffect } from "react";
import PageLoader from "../../../components/loader/PageLoader";
import { updateHotelAction } from "../../../actions/pms/hotel";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { UPDATE_HOTEL_RESET } from "../../../actions/types/hotel";
import DeleteButton from "../../../components/buttons/DeleteButton";
import AddButton from "../../../components/buttons/AddButton";
// import { useNavigate } from "react-router-dom";
// import { PATH_SETTINGS_CONFIG } from "../../../constants";
// import SkipButton from "../../../components/buttons/SkipButton";
// import AddSubmitButton from "../../../components/buttons/AddSubmitButton";
// import PropertyContacts from "../../../components/layouts/PropertyContacts";
const ContactInfo = (props) => {
  const { t } = useTranslation();
  // const [contacts, setContacts] = useState([]);
  const [isFullData, setIsFullData] = useState(false);
  // const navigate = useNavigate();

  //set property details from local / hotel data
  // useEffect(() => {
  //   if (props?.hotel?._id) {
  //     props?.hotel?.contacts && setContacts(props?.hotel?.contacts);
  //     setIsFullData(true);
  //   }
  // }, [props?.hotel, props?.data?.isupdated]);

  // const gotoNextTab = () => {
  //   navigate(`${PATH_SETTINGS_CONFIG}/4`);
  // };

  // const skipTheTab = () => {
  //   navigate(`${PATH_SETTINGS_CONFIG}/4`);
  // };

  return (
    <>
      {/* {isFullData ? ( */}
        <>
          <div className="border border-[#E4E4E7] rounded-[10px] bg-white p-8">
            <div className="flex items-center justify-between">
            <h2 className="font-bold text-xl sm:text-2xl md:text-3xl leading-9 text-black tracking-tighter">
              {t("384")}
            </h2>
            <div className="flex items-center gap-4">
                <DeleteButton/>
                <AddButton text={"Add Contact"}/>
            </div>
            </div>

            {/* <form className="">
              <PropertyContacts contacts={contacts} setContacts={setContacts} />
              <div className="mt-8 w-full flex justify-end items-center gap-2">
                <SkipButton text={t("260")} onClick={skipTheTab} />
                <AddSubmitButton
                  text={t("65")}
                  width={88}
                  disabled={props?.data && props?.data?.loading}
                />
              </div>
            </form> */}
          </div>
        </>
      {/* ) : (
        <PageLoader />
      )} */}
    </>
  );
};

const mapStateToProps = (state) => ({
  data: state.UpdateHotel,
});

const mapDispatchToProps = (dispatch) => ({
  updateHotel: (data) => dispatch(updateHotelAction(data)),
  reset: () => dispatch({ type: UPDATE_HOTEL_RESET }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactInfo);
