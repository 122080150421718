import * as React from "react"; 

const years = [
  new Date(2022, 6, 1),
  new Date(2022, 7, 1),
  new Date(2022, 8, 1),
  new Date(2022, 9, 1),
  new Date(2022, 10, 1),
  new Date(2022, 11, 1),
  new Date(2023, 0, 1),
  new Date(2023, 1, 1),
  new Date(2023, 2, 1),
  new Date(2023, 3, 1),
  new Date(2023, 4, 1),
  new Date(2023, 5, 1),
];

const salesData = [200, 300, 200, 400, 230, 230, 109, 400, 233, 122, 122, 322];

const revenueData = [
  230, 350, 290, 480, 290, 230, 209, 430, 333, 322, 222, 322,
];

const SalesRevenue = () => {
  return (
    <>
    </>
    // <LineChart
    //   xAxis={[
    //     {
    //       id: "Years",
    //       data: years,
    //       scaleType: "time",
    //     },
    //   ]}
    //   height={250}
    //   series={[
    //     {
    //       id: "sales",
    //       label: "Sales",
    //       data: salesData,
    //       stack: "total",
    //       area: true,
    //       color: "#9afcb1",
    //     },
    //     {
    //       id: "revenue",
    //       label: "Revenue",
    //       data: revenueData,
    //       stack: "total",
    //       area: true,
    //       color: "#9afcb1",
    //     },
    //   ]}
    //   sx={{
    //     ".MuiMarkElement-root": {
    //       display: "none",
    //     },
    //     ".MuiChartsAxis-directionY": {
    //       display: "none",
    //     },
    //     ".MuiChartsLegend-root": {
    //       display: "none",
    //     },
    //   }}
    // />
  );
};
export default SalesRevenue;
