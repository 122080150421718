import { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CONFIGURATION_PERCENTAGE } from "../actions/types";
import { ONBOARDING_STAGES, ROUTES } from "../constants";

const CheckIsConfigured = (props) => {
  const { hotel } = useSelector((state) => state.Hotel);
  const navigate = useNavigate();
  // 1. profile configured
  const checkProfile = (profile) => {
    if (profile && profile?._id) return true;
    else return false;
  };

  //2. hotel profile is configured or not
  const hotelProfile = (hotel) => {
    if (
      hotel &&
      hotel?.propertyName &&
      hotel?.propertyType &&
      hotel?.contacts &&
      hotel?.contacts?.length &&
      hotel?.checkInTime &&
      hotel?.checkOutTime &&
      hotel?.propertyContact?.email &&
      hotel?.propertyContact?.phone &&
      hotel?.propertyAddress?.city &&
      hotel?.propertyAddress?.streetAddress &&
      hotel?.propertyAddress?.state &&
      hotel?.propertyAddress?.country &&
      hotel?.propertyAddress?.pinCode
    ) {
      return true;
    } else return false;
  };

  //3. rooms configured
  const roomsConfig = (rooms) => {
    if (rooms && rooms?.length) return true;
    else return false;
  };

  //if only profile is configured
  const onlyProfileIsConfigured = (profile) => {
    props.setIsConfigured(false);

    if (!checkProfile(profile)) {
      navigate(ROUTES.MY_PROFILE);
    }

    if (props?.hotel?._id && checkProfile(profile)) {
      //check owner profile is configured or not
      if (checkProfile(profile) && props?.configurePercentage < 10) {
        props.setConfigurePercentage(10);
        props.setPercentage(10);
      } else if (
        hotelProfile(props?.hotel) &&
        props?.configurePercentage < 40
      ) {
        props.setConfigurePercentage(40);
        props.setPercentage(40);
      } else if (
        hotelProfile(props?.hotel) &&
        props?.hotel?.amenities &&
        props?.hotel?.amenities?.length &&
        props?.configurePercentage < 60 &&
        props?.configurePercentage >= 40
      ) {
        props?.setConfigurePercentage(60);
        props?.setPercentage(60);
      }
    }
  };

  //if profile, hotel, rooms are configured
  const fullConfigured = (profile, rooms) => {
    if (
      hotelProfile(props?.hotel) &&
      checkProfile(profile) &&
      roomsConfig(rooms)
    ) {
      props?.setConfigurePercentage(100);
      props?.setIsConfigured(true);
      props?.setPercentage(100);
    }
  };

  const checkConfiguration = () => {
    if (hotel?.stage !== ONBOARDING_STAGES.DASHBOARD) {
      navigate(ROUTES.ONBOARDING);
    }

    if (
      (props?.profile && props?.profile?.loading) ||
      (props?.rooms && props?.rooms?.loading) ||
      (props?.hotel && props?.hotel?.loading)
    ) {
      return;
    } else if (
      props?.profile &&
      props?.profile.profile &&
      props.profile.profile?._id &&
      props?.rooms &&
      props?.rooms?.rooms &&
      props?.rooms?.rooms?.length
    ) {
      fullConfigured(props?.profile?.profile, props?.rooms?.rooms);
    } else if (
      props?.profile &&
      props?.profile?.profile &&
      props?.profile?.profile?._id
    ) {
      onlyProfileIsConfigured(props?.profile?.profile);
    }
  };

  useEffect(() => {
    //check is fully loaded or not
    checkConfiguration();
  }, [
    props?.profile,
    props?.rooms,
    props?.update,
    props?.updateProfile,
    hotel,
  ]);

  return null;
};

const mapStateToProps = (state) => ({
  profile: state.OwnerProfile,
  updateProfile: state.UpdateProfile,
  rooms: state.AllRoom,
  update: state.UpdateHotel,
});

const mapDispatchToProps = (dispatch) => ({
  setPercentage: (payload) =>
    dispatch({ type: CONFIGURATION_PERCENTAGE, payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckIsConfigured);
