import React, { useEffect, useState } from "react";
import { getAvailableRooms } from "../../utils";
import { BsArrowUpShort, BsArrowDownShort } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { DesboardCard } from "./DesboardCard";
import OccupencyIcon from "../../images/icons/OccupencyIcon.svg";
import RoomAvailableIcon from "../../images/icons/RoomAvailableIcon.svg";

import TotalBookingIcon from "../../images/icons/TotalBookingIcon.svg";
import TotalCheckInIcon from "../../images/icons/TotalCheckInIcon.svg";
import TotalCheckOutIcon from "../../images/icons/TotalCheckOutIcon.svg";
import TotalCheckInPendindIcon from "../../images/icons/TotalCheckInPendindIcon.svg";
import CalendarTickIcon from "../../images/icons/CalendarTickIcon.svg";
import AllCustomerIcon from "../../images/icons/AllCustomerIcon.svg";

const PropertyStatus = ({ bookings, date }) => {
  const [totalBookings, setTotalBookings] = useState(0);
  const [totalCheckIn, setTotalCheckIn] = useState(0);
  const [totalCheckOut, setTotalCheckOut] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [totalAvailableRooms, setTotalAvailableRooms] = useState(0);
  const [totalCheckOutPending, setTotalCheckOutPending] = useState(0);
  const { t } = useTranslation();
  const { hamburger } = useSelector((state) => state.Hamburger);

  //computing data
  const computingData = async () => {
    // eslint-disable-next-line no-unused-vars
    let books = 0;
    // eslint-disable-next-line no-unused-vars
    let checkIn = 0;
    // eslint-disable-next-line no-unused-vars
    let checkOut = 0;
    // eslint-disable-next-line no-unused-vars
    let checkOutPending = 0;

    bookings &&
      bookings.length &&
      // eslint-disable-next-line array-callback-return
      bookings.map((booking) => {
        // bookings count
        if (booking.bookingDate) {
          let newDate = booking.bookingDate.slice(0, 10);
          if (newDate === date) {
            books += 1;
          }
        }

        //checkin count
        if (booking.checkInDate) {
          let newDate = booking.checkInDate.slice(0, 10);
          if (newDate === date) {
            checkIn += 1;
          }
        }

        //checkOut count
        if (booking.checkOutDate) {
          let newDate = booking.checkOutDate.slice(0, 10);
          if (newDate === date) {
            checkOut += 1;
          }
        }

        //checkout pending
        if (booking.checkOutDate && booking.checkInDate) {
          let newCheckIn = booking.checkInDate.slice(0, 10);
          let newCheckout = booking.checkOutDate.slice(0, 10);
          if (newCheckIn < date && newCheckout >= date) {
            checkOutPending += 1;
          }
        }
      });

    setTotalBookings(totalBookings);
    setTotalCheckIn(totalCheckIn);
    setTotalCheckOut(totalCheckOut);
    setTotalCheckOutPending(totalCheckOutPending);

    const rooms = await getAvailableRooms(date, date);
    setTotalAvailableRooms(rooms.length);
  };

  useEffect(() => {
    computingData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookings, date]);

  return (
    <>
      <div
        className={`${
          hamburger ? "lg:grid-cols-3" : "lg:grid-cols-4"
        } grid grid-cols-1 font-pjs sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 mb-6`}
      >
        {/* c1 */}
        <DesboardCard
          name={t("5")}
          icon={OccupencyIcon}
          percantage={"+36%"}
          percantageIcon={<BsArrowUpShort size={25} />}
          totalCounts={"12,426"}
        />
        {/* c2 */}
        <DesboardCard
          name={t("6")}
          icon={RoomAvailableIcon}
          percantage={"+16%"}
          percantageIcon={<BsArrowDownShort size={25} />}
          totalCounts={"3,585"}
          utility={"text-[#EF4444]"}
        />
        {/* c3 */}
        <DesboardCard
          name={t("7")}
          icon={TotalBookingIcon}
          percantage={"+31%"}
          percantageIcon={<BsArrowUpShort size={25} />}
          totalCounts={"14,382"}
        />
        {/* c4 */}
        <DesboardCard
          name={t("8")}
          icon={TotalCheckInIcon}
          percantage={"+31%"}
          percantageIcon={<BsArrowDownShort size={25} />}
          totalCounts={"1,426"}
          utility={"text-[#EF4444]"}
        />
        {/* c5 */}
        <DesboardCard
          name={t("9")}
          icon={TotalCheckOutIcon}
          percantage={"+31%"}
          percantageIcon={<BsArrowUpShort size={25} />}
          totalCounts={"2,485"}
        />
        {/* c6 */}
        <DesboardCard
          name={t("10")}
          icon={TotalCheckInPendindIcon}
          percantage={"+43%"}
          percantageIcon={<BsArrowDownShort size={25} />}
          totalCounts={" 4,382"}
          utility={"text-[#EF4444]"}
        />
        {/* c7 */}
        <DesboardCard
          name={t("11")}
          icon={CalendarTickIcon}
          percantage={"+31%"}
          percantageIcon={<BsArrowUpShort size={25} />}
          totalCounts={" 4,382"}
        />
        {/* c8 */}
        <DesboardCard
          name={t("835")}
          icon={AllCustomerIcon}
          percantage={"+31%"}
          percantageIcon={<BsArrowUpShort size={25} />}
          totalCounts={"12,426"}
        />
      </div>
    </>
  );
};

export default PropertyStatus;
