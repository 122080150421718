import React from "react";
import { useState } from "react";
import { CustomeTable } from "../../components";
import Inspection from "./Inspection";
import Assign from "./Assign";
import RoomCleaningStatus from "./RoomCleaningStatus";
import RoomAvailability from "./RoomAvailability";
import UploadButton from "../../components/buttons/UploadButton";
import DownloadButton from "../../components/buttons/DownloadButton";

const RoomCleaning = () => {
  const [selectedRows, setSelectedRows] = useState([]);

  const rowData = [
    {
      _id: 213,
      room_no: "100",
      status: "cleanning",
      availability: "vacant",
      inspection_status: "inspected",
      assign_to: "assign",
    },
    {
      _id: 3213,
      room_no: "101",
      status: "dirty",
      availability: "Occupied",
      inspection_status: "Pending",
      assign_to: "E023 Masud , E024 Rajot",
    },
    {
      _id: 2413,
      room_no: "102",
      status: "cleaned",
      availability: "Occupied",
      inspection_status: "inspected",
      assign_to: "assign",
    },
    {
      _id: 21343,
      room_no: "103",
      status: "cleanning",
      availability: "vacant",
      inspection_status: "inspected",
      assign_to: "E023 Masud , E024 Rajot",
    },
    {
      _id: 2138,
      room_no: "104",
      status: "dirty",
      availability: "Occupied",
      inspection_status: "Pending",
      assign_to: "assign",
    },
  ];

  const columnDef = [
    {
      headerName: "Room ID",
      field: "_id",
      maxWidth: 150,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Room No",
      field: "room_no",
      minWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Status",
      field: "status",
      cellRenderer: RoomCleaningStatus,
      maxWidth: 150,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "availability",
      field: "availability",
      cellRenderer: RoomAvailability,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "inspection status",
      field: "inspection_status",
      cellRenderer: Inspection,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "assigned to",
      field: "assign_to",
      cellRenderer: Assign,
      filter: true,
      floatingFilter: true,
    },
  ];

  return (
    <div>
      <div className="flex items-center justify-between mb-4">
        <div className="text-[22px] leading-[27.72px] text-black font-bold">
          Room Cleanning Table
        </div>
        <div className="flex items-center gap-4">
          <UploadButton />
          <DownloadButton />
        </div>
      </div>
      <div className="">
        <CustomeTable
          rowData={rowData}
          columnDefs={columnDef}
          setSelectedRows={setSelectedRows}
        />
      </div>
    </div>
  );
};
export default RoomCleaning;
