/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearError } from "../../actions/clearError";
import {
  forgotPasswordAction,
  verifyOTPAction,
} from "../../actions/pms/forgotPassword";
import { notifyError, notifySuccess } from "../../components/alert";
import LoadSpinner from "../../components/loader/LoadSpinner";
import {  
  RESET_PASS_TOKEN,
  ROUTES,
} from "../../constants";
import MetaData from "../../utils/MetaData";
import { useTranslation } from "react-i18next";

const ForgotPasswordVerifyOTP = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [enterOtp, setEnterOtp] = useState("");

  const [minutes, setMinutes] = useState(5);
  const [seconds, setSeconds] = useState(0);
  const {
    user,
    isforgoted,
    loading: loadforgot,
  } = useSelector((state) => state.ForgotPassword);
  const { loading, error, token } = useSelector((state) => state.VerifyOTP);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!enterOtp || enterOtp.length !== 4) {
      notifyError("958");
      return;
    }
    const formData = {
      email: user?.email,
      otp: enterOtp,
    };
    dispatch(verifyOTPAction(formData));
  };

  useEffect(() => {
    if (!user?.email) {
      navigate(ROUTES.PASSWORD_FORGOT);
    }
  }, [user]);

  useEffect(() => {
    if (token) {
      localStorage.setItem(RESET_PASS_TOKEN, token);
      notifySuccess(t("959"));
      navigate(ROUTES.PASSWORD_RESET);
    }
  }, [token]);

  // clearing error
  useEffect(() => {
    if (error) {
      notifyError(error);
      dispatch(clearError());
    }
  }, [error]);

  useEffect(() => {
    if (isforgoted) {
      notifySuccess(t("960"));
      setMinutes(5);
      setSeconds(0);
    }
  }, [isforgoted]);

  //resend otp
  const resendOTP = () => {
    dispatch(forgotPasswordAction({ email: user.email }));
  };

  //resend otp time count
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  return (
    <>
      <MetaData title={t("961")} />
      <div className="w-full min-h-screen">
        <div className="w-[500px] max-w-full mx-auto px-4 py-8 sm:py-12">
          <div className="p-4 w-full flex flex-col shadow-lg rounded bg-[#FFFFFF] sm:p-8">
            <div className="w-full mb-8 text-center">
              <h1 className="text-2xl font-medium mb-2">{t("961")}</h1>
              <p>
                {t("962")}
                <strong>{user.email}</strong>
              </p>
            </div>
            <form className="w-full" onSubmit={handleSubmit}>
              <div className="w-full mb-6 relative sm:mb-8 flex justify-between">
                <input
                  type="text"
                  name="otp"
                  value={enterOtp}
                  maxLength={4}
                  onChange={(e) => setEnterOtp(e.target.value)}
                  className="w-full tracking-[1rem] sm:tracking-[2rem] text-center outline-none border border-base-primary py-3 focus:border-black rounded-3xl"
                />
              </div>
              <button className="our-button rounded-3xl" disabled={loading}>
                {loading ? <LoadSpinner /> : <>{t("963")}</>}
              </button>
            </form>
            <div className="mt-6 sm:mt-8 flex items-center justify-between">
              {seconds || minutes ? (
                <p>
                  {t("966")}: {minutes < 10 ? `0${minutes}` : minutes}:
                  {seconds < 10 ? `0${seconds}` : seconds}
                </p>
              ) : (
                <p>{t("964")}</p>
              )}
              <button
                className="next-button w-36 bg-base-rgba_primary_8 hover:bg-base-primary disabled:bg-base-rgba_primary_8 disabled:cursor-not-allowed text-sm"
                onClick={resendOTP}
                disabled={loadforgot || minutes || seconds}
              >
                {loadforgot ? <LoadSpinner /> : <>{t("965")}</>}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPasswordVerifyOTP;
