export const GET_MENU_REQUEST = "GET_MENU_REQUEST";
export const GET_MENU_SUCCESS = "GET_MENU_SUCCESS";
export const GET_MENU_FAIL = "GET_MENU_FAIL";

export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_CART_ITEM = "REMOVE_CART_ITEM"
export const CLEAR_CART = "CLEAR_CART"

// ordering
export const FOOD_ORDER_REQUEST = "FOOD_ORDER_REQUEST";
export const FOOD_ORDER_SUCCESS = "FOOD_ORDER_SUCCESS";
export const FOOD_ORDER_FAIL = "FOOD_ORDER_FAIL";
export const FOOD_ORDER_RESET = "FOOD_ORDER_RESET";

export const GET_FOOD_ORDER_REQUEST = "GET_FOOD_ORDER_REQUEST";
export const GET_FOOD_ORDER_SUCCESS = "GET_FOOD_ORDER_SUCCESS";
export const GET_FOOD_ORDER_FAIL = "GET_FOOD_ORDER_FAIL";

export const GET_FOOD_ORDERS_REQUEST = "GET_FOOD_ORDERS_REQUEST";
export const GET_FOOD_ORDERS_SUCCESS = "GET_FOOD_ORDERS_SUCCESS";
export const GET_FOOD_ORDERS_FAIL = "GET_FOOD_ORDERS_FAIL";

export const CANCEL_FOOD_ORDER_REQUEST = "CANCEL_FOOD_ORDER_REQUEST";
export const CANCEL_FOOD_ORDER_SUCCESS = "CANCEL_FOOD_ORDER_SUCCESS";
export const CANCEL_FOOD_ORDER_FAIL = "CANCEL_FOOD_ORDER_FAIL";
export const CANCEL_FOOD_ORDER_RESET = "CANCEL_FOOD_ORDER_RESET";
