import {
  CANCEL_FOOD_ORDER_FAIL,
  CANCEL_FOOD_ORDER_REQUEST,
  CANCEL_FOOD_ORDER_RESET,
  CANCEL_FOOD_ORDER_SUCCESS,
  FOOD_ORDER_FAIL,
  FOOD_ORDER_REQUEST,
  FOOD_ORDER_RESET,
  FOOD_ORDER_SUCCESS,
  GET_FOOD_ORDERS_FAIL,
  GET_FOOD_ORDERS_REQUEST,
  GET_FOOD_ORDERS_SUCCESS,
  GET_FOOD_ORDER_FAIL,
  GET_FOOD_ORDER_REQUEST,
  GET_FOOD_ORDER_SUCCESS,
} from "../../actions/types/food";
import { CLEAR_ERRORS } from "../../actions/types";

//order food reducer
export const foodOrderReducer = (state = { order: {} }, action) => {
  switch (action.type) {
    case FOOD_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
        isordered: false,
      };
    case FOOD_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        isordered: true,
        order: action.payload,
      };
    case FOOD_ORDER_FAIL:
      return {
        ...state,
        loading: false,
        isordered: false,
        error: action.payload,
      };
    case FOOD_ORDER_RESET:
      return {
        ...state,
        loading: false,
        isordered: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

//get order
export const getFoodOrderReducer = (state = { order: {} }, action) => {
  switch (action.type) {
    case GET_FOOD_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_FOOD_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        order: action.payload,
      };
    case GET_FOOD_ORDER_FAIL:
      return {
        ...state,
        loading: false,
        order:null,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};


//get order
export const getFoodOrdersReducer = (state = { orders: [] }, action) => {
  switch (action.type) {
    case GET_FOOD_ORDERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_FOOD_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        orders: action.payload,
      };
    case GET_FOOD_ORDERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        orders:[],
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

//cancel order
export const cancelFoodOrderReducer = (state = { order: {} }, action) => {
  switch (action.type) {
    case CANCEL_FOOD_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
        iscanceled: false,
      };
    case CANCEL_FOOD_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        iscanceled: true,
        order: action.payload,
      };
    case CANCEL_FOOD_ORDER_FAIL:
      return {
        ...state,
        loading: false,
        iscanceled: false,
        error: action.payload,
      };
    case CANCEL_FOOD_ORDER_RESET:
      return {
        ...state,
        loading: false,
        iscanceled: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
