import {
  DELETE_EMPLOYEE_FAIL,
  DELETE_EMPLOYEE_REQUEST,
  DELETE_EMPLOYEE_RESET,
  DELETE_EMPLOYEE_SUCCESS,
  UPDATE_EMPLOYEE_FAIL,
  UPDATE_EMPLOYEE_REQUEST,
  UPDATE_EMPLOYEE_RESET,
  UPDATE_EMPLOYEE_SUCCESS,
} from "../../actions/types/employee";

import { CLEAR_ERRORS } from "../../actions/types";
import { ADD_PERMISSION_TO_EMP_SUCCESS, DELETE_PERMISSION_TO_EMP_SUCCESS } from "../../actions/types/employee";

//owner update employee profile
export const updateEmployeeProfileReducer = (
  state = { employee: {} },
  action
) => {
  switch (action.type) {
    case UPDATE_EMPLOYEE_REQUEST:
      return {
        ...state,
        loading: true,
        isupdated: false,
      };
    case UPDATE_EMPLOYEE_SUCCESS:
    case ADD_PERMISSION_TO_EMP_SUCCESS:      
    case DELETE_PERMISSION_TO_EMP_SUCCESS:

      return {
        ...state,
        loading: false,
        employee: action.payload,
        isupdated: true,
      };
    case UPDATE_EMPLOYEE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isupdated: false,
      };
    case UPDATE_EMPLOYEE_RESET:
      return {
        ...state,
        loading: false,
        error: null,
        isupdated: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

//delete the employee
export const deleteEmployeeProfileReducer = (
  state = { employee: {} },
  action
) => {
  switch (action.type) {
    case DELETE_EMPLOYEE_REQUEST:
      return {
        ...state,
        loading: true,
        isdeleted: false,
      };
    case DELETE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: false,
        employee: action.payload,
        isdeleted: true,
      };
    case DELETE_EMPLOYEE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isdeleted: false,
      };
    case DELETE_EMPLOYEE_RESET:
      return {
        ...state,
        loading: false,
        error: null,
        isdeleted: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
