import React from "react";
import { useTranslation } from "react-i18next";
import { FaArrowRight } from "react-icons/fa6";
import { FaArrowLeft } from "react-icons/fa6";
const Step0 = ({ setStep, owner }) => {
  const { t } = useTranslation();
  const completeStep = () => {
    setStep(1);
  };

  return (
    <>
      <div className="flex w-full justify-center px-16">
        <div className="flex flex-col justify-center items-start min-h ">
          <div className="capitalize text-3xl sm:text-5xl md:text-[52px] md:leading-[72px] font-bold break-words">
            <div className="">{t("900")}</div>
            <span className="text-base-primary pr-2">{t("929")}</span>
            {t("901")}
          </div>
          <div className="flex items-center gap-4 mt-6">
            <button
              className="flex items-center gap-[10px] px-4 h-10 rounded-md bg-[#FFFFFF] hover:bg-slate-100 text-[14px] text-[#252C32] leading-5 font-medium border border-[#E5E9EB]"
              // onClick={completeStep}
            >
              <FaArrowLeft />
              {t("Go Back")}
            </button>
            <button
              className="bg-base-primary hover:bg-base-primary_hover flex items-center gap-[10px] px-4 h-10 rounded-md text-[#FFFFFF] text-[14px] leading-5 font-medium"
              onClick={completeStep}
            >
              {t("902")}
              <FaArrowRight />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step0;
