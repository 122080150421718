import React, { useState } from "react";

const OPTIONS = {
  DIRTY: "Dirty",
  CLEANED: "Cleaned",
  CLEANING: "Cleaning",
};

const RoomCleaningStatus = () => {
  const [value, setValue] = useState("DIRTY");

  return (
      <select
        onChange={(e) => setValue(e?.target?.value)}
        value={value}
        className="inspection-selector"
        style={{
          backgroundColor:
            value === "DIRTY"
              ? "#FEF3F2!important"
              : value === "CLEANED"
              ? "#ECFDF3!important"
              : "#EEF4FF!important",
          color:
            value === "DIRTY"
              ? "#F04438"
              : value === "CLEANED"
              ? "#00A35E"
              : "#444CE7",
        }}
      >
        {Object.keys(OPTIONS)?.map((it, index) => (
          <option className="capitalize" key={index} value={OPTIONS[it]}>
            {OPTIONS[it]}
          </option>
        ))}
      </select>
  );
};

export default RoomCleaningStatus;
