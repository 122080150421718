import React, { useState } from "react";
import Packages from "./components/Packages";
import NewCustomers from "./NewCustomers";
import RecentActivity from "./components/RecentActivity";
import SalesRevenue from "./Charts/SalesRevenue";
import RoomBookingChart from "./Charts/RoomBookingChart";
import DashBoardHead from "./DashBoardHead";
import { connect, useSelector } from "react-redux";
import { getToday } from "../../utils";
import PageLoader from "../../components/loader/PageLoader";
import PropertyStatus from "./PropertyStatus";
import { useTranslation } from "react-i18next";
// import { CustomeTable } from "../../components";

const Dashboard = (props) => {
  const [date, setDate] = useState(getToday());
  const { hamburger } = useSelector((state) => state.Hamburger);
  // const [selectedRows, setSelectedRows] = useState([]);
  const { t } = useTranslation();

  // const rowData = [
  //   {
  //     date:"11 may",
  //     roomNights: "16",
  //     revenue: "4300.00",
  //     occupancy: "40.00%",
  //   },
  //   {
  //     date:"11 may",
  //     roomNights: "15",
  //     revenue: "4300.00",
  //     occupancy: "34.00%",
  //   },
  //   {
  //     date:"11 may",
  //     roomNights: "12",
  //     revenue: "3300.00",
  //     occupancy: "32.00%",
  //   },
  //   {
  //     date:"11 may",
  //     roomNights: "13",
  //     revenue: "3700.00",
  //     occupancy: "30.00%",
  //   },
  //   {
  //     date:"11 may",
  //     roomNights: "16",
  //     revenue: "4200.00",
  //     occupancy: "23.00%",
  //   },
  // ];
  // const columnDefs = [
  //   {
  //     headerName: "Date",
  //     field: "date",
  //     maxWidth: 120,
  //     checkboxSelection: true,
  //     headerCheckboxSelection: true,
  //   },
  //   {
  //     headerName: "Name Of Dishes",
  //     field: "roomNights",
  //     maxWidth: 400,
  //     filter: true,
  //     floatingFilter: true,
  //   },
  //   {
  //     headerName: "Name Of Dishes",
  //     field: "revenue",
  //     maxWidth: 400,
  //     filter: true,
  //     floatingFilter: true,
  //   },
  //   {
  //     headerName: "Occupancy",
  //     field: "occupancy",
  //     maxWidth: 400,
  //     filter: true,
  //     floatingFilter: true,
  //   },
  // ];
  return (
    <>
      {props?.bookings && props?.bookings?.loading ? (
        <PageLoader />
      ) : (
        // page-container
        <div className="page-container">
          {/* head */}
          <div className="mx-auto max-w-7xl w-full">
            <DashBoardHead date={date} setDate={setDate} />
            {/* some inmports*/}
            <PropertyStatus bookings={props?.bookings?.bookings} date={date} />
            {/* <Packages /> */}

            {/* new */}
            <div
              className={`${hamburger ? "lg:flex-col" : "lg:flex-row"
                } w-full flex flex-col xl:flex-row gap-6`}
            >
              <div
                className={`${hamburger ? "w-full" : "lg:w-[70%] lg:min-w-[65%]"
                  }`}
              >
                <div className="w-full h-[327px] bg-[#FFFFFF] border py-4 px-6 rounded-xl overflow-x-auto">
                  <div className="min-w-[600px]">
                    <div className="relative flex items-center justify-between">
                      <p className="font-bold text-sm">{t("1029")}</p>
                      <div className="flex gap-4 items-center">
                        <button className="text-[11px] font-bold px-4 border rounded-md h-[27px]">
                          {t("1030")}
                        </button>
                        <button className="text-[11px] font-bold px-4 rounded-md h-[27px]">
                          {t("1031")}
                        </button>
                        <button className="text-[11px] font-bold px-4 rounded-md h-[27px]">
                          {t("1032")}
                        </button>
                        <button className="text-[11px] font-bold px-4 rounded-md h-[27px]">
                          {t("1033")}
                        </button>
                      </div>
                      <button className="w-[86px] h-[27px] text-[10px] font-bold border rounded-md">
                        {t("1034")}
                      </button>
                    </div>
                    <SalesRevenue />
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row gap-6 mt-6">
                  <div className="w-full sm:w-[50%] h-[327px] rounded-xl border bg-[#FFFFFF] px-8 py-5">
                    <NewCustomers bookings={props?.bookings?.bookings} />
                  </div>
                  <div className="w-full sm:w-[50%] h-[327px] rounded-xl border bg-[#FFFFFF] px-8 py-5">
                    <RecentActivity />
                  </div>
                </div>
              </div>

              <div
                className={`${hamburger
                    ? "w-full lg:flex-col lg:w-[30%]"
                    : "lg:flex-col lg:w-[30%]"
                  } flex flex-col sm:flex-row gap-6`}
              >
                <div className="w-full sm:w-[50%] lg:w-full h-[327px] rounded-xl border">
                  <Packages />
                </div>
                <div className="w-ful sm:w-[50%] lg:w-full h-[327px] rounded-xl border bg-[#FFFFFF]">
                  <RoomBookingChart />
                </div>
              </div>
            </div>
          </div>
          {/* <InComeExpences /> */}
          {/* <div className="mt-6">
            <CustomeTable
              columnDefs={columnDefs}
              rowData={rowData}
              setSelectedRows={setSelectedRows}
            />
          </div> */}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  bookings: state.AllBooking,
});

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
