import React, { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const HidePhone = (props) => {
    const text = props?.data?.phone;
    const [hide, setHide] = useState(true)
    const toggleHide = () => setHide(!hide); 

    return (
        <div hidden={!text?.length} className="flex items-center justify-between gap-2">
            {text?.slice(0,3) + "***********"} <button onClick={toggleHide}>{hide ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}</button>
        </div>
    );
};


export default HidePhone
