import React, { useState } from "react";
import { RxCross2 } from "react-icons/rx"; 
import { useTranslation } from "react-i18next";
import Inputfield from "../../components/layouts";import FormSubmitButton from "../../components/buttons";
 ;

const AddQuantity = ({ close }) => {
  const { t } = useTranslation();
  const [currentQuantity, setCurrentQuantity] = useState("");
  const [increaseQuantity, setIncreaseQuantity] = useState("");
  const [price, setPrice] = useState("");
  const [date, setDate] = useState("");
  const [vendorID, setVendorID] = useState("");
  const [unitMeassureCurrentQuantity, setUnitMeassureCurrentQuantity] =
    useState('');
  const [unitMeassureIncreaseQuantity, setUnitMeassureIncreaseQuantity] =
    useState('');
  return (
    <div className="w-full h-[520px] mt-6 bg-[#FFFFFF] border border-[#E5E9EB] rounded-lg">
      <div className="w-full flex flex-col relative">
        <div className="text-center font-bold text-[30px] leading-[37.8px] text-[#07090D] tracking-[-1.5%] my-8 capitalize">
          Add Quantity - Salt
        </div>
        <button className="absolute right-8 top-10" onClick={close}>
          <RxCross2
            size={26}
            className="hover:rotate-90 duration-500 hover:text-[#F04438]"
          />
        </button>
      </div>
      <form className="px-16">
        <div className="grid grid-cols-2 items-center gap-40">
          {/* <TwoInOneInputFields
            label={t("Current Quantity")}
            placeholder={"Enter a value"}
            type="number"
            value={currentQuantity}
            onChange={(e) => setCurrentQuantity(e.target.value)}
            min={0}
            max={10}
            required={true}
            options={['']}
            selectValue={unitMeassureCurrentQuantity}
            onChangeSelect={(e) =>
              setUnitMeassureCurrentQuantity(e.target.value)
            }
          /> */}
          {/* <TwoInOneInputFields
            label={t("Increase Quantity")}
            placeholder={t("Enter a value")}
            message={"Enter minimum quantity threshold alert in gm or kg."}
            value={increaseQuantity}
            onChange={(e) => setIncreaseQuantity(e.target.value)}
            min={1}
            max={10}
            required={true}
            options={['']}
            selectValue={unitMeassureIncreaseQuantity}
            onChangeSelect={(e) =>
              setUnitMeassureIncreaseQuantity(e.target.value)
            }
          /> */}
        </div>
        <div className="grid grid-cols-2 items-center gap-40 my-6">
          <Inputfield
            label={t("Price")}
            placeholder={t("Enter a value")}
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
          <Inputfield
            type="date"
            label={t("Date")}
            placeholder={t("MM/DD/YYYY")}
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </div>
        <div className="grid grid-cols-2 items-center gap-40">
          <Inputfield
            label={t("485")}
            placeholder={t("682")}
            value={vendorID}
            onChange={(e) => setVendorID(e.target.value)}
          />
        </div>
      </form>
      <div className="flex justify-end items-center gap-2 mt-[100px] mr-16">
        <button className="w-[76px] h-10 rounded-md text-sm font-medium border border-[#D0D5DD] bg-[#FFFFFF] hover:bg-slate-100">
          Cancel
        </button>
        <FormSubmitButton text="Update Item" h="h-10" onClick={"#"} />
      </div>
    </div>
  );
};

export default AddQuantity;
