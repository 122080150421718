import React from "react";
import ReportDropDown from "./ReportDropDown";
import { REPORTS } from "../../constants";
import { useTranslation } from "react-i18next";

const FilterReport = ({
  reportOf,
  setReportOf,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="w-full flex flex-col gap-4 sm:flex-row items-start justify-between">
        <div className="max-w-[300px] w-full">
          <ReportDropDown
            options={REPORTS}
            value={reportOf}
            setValue={setReportOf}
          />
        </div>
        <div className="flex gap-4 items-center">
          <div className=" relative flex w-[308px] h-12 border rounded-[6px] box-border flex-row justify-center items-center gap-[14px] p-3 bg-white text-[#18181B]">
            <input
              type={"date"}
              className="flex w-1/2 my-[10px] bg-white items-center border-none shadow-none rounded h-5 font-normal cursor-pointer select-none text-[14px] leading-[18px] tracking-[-0.8px] text-pjs"
              value={fromDate}
              onChange={(e) => setFromDate(e?.target?.value)}
            />
            <input
              type={"date"}
              className="flex w-1/2 my-[10px] bg-white items-center border-none shadow-none rounded h-5 font-normal cursor-pointer select-none text-[14px] leading-[18px] tracking-[-0.8px] text-pjs"
              value={toDate}
              onChange={(e) => setToDate(e?.target?.value)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterReport;
