import { CLEAR_ERRORS } from "../../actions/types";
import {
  ADD_HOTEL_WIFI_FAIL,
  ADD_HOTEL_WIFI_REQ,
  ADD_HOTEL_WIFI_RESET,
  ADD_HOTEL_WIFI_SUC,
  DELETE_HOTEL_WIFI_FAIL,
  DELETE_HOTEL_WIFI_REQ,
  DELETE_HOTEL_WIFI_RESET,
  DELETE_HOTEL_WIFI_SUC,
  GET_HOTEL_WIFI_FAIL,
  GET_HOTEL_WIFI_REQ,
  GET_HOTEL_WIFI_SUC,
  UPDATE_HOTEL_WIFI_FAIL,
  UPDATE_HOTEL_WIFI_REQ,
  UPDATE_HOTEL_WIFI_RESET,
  UPDATE_HOTEL_WIFI_SUC,
} from "../../actions/types/hotelWifi";

//get hotel wifi
export const getHotelWifiReducer = (state = { wifi: {} }, action) => {
  switch (action.type) {
    case GET_HOTEL_WIFI_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_HOTEL_WIFI_SUC:
    case ADD_HOTEL_WIFI_SUC:
    case DELETE_HOTEL_WIFI_SUC:
    case UPDATE_HOTEL_WIFI_SUC:
      return {
        ...state,
        loading: false,
        wifi: action.payload,
      };
    case GET_HOTEL_WIFI_FAIL:
      return {
        ...state,
        loading: false,
        wifi: null,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

//add hotel wifi
export const addHotelWifiReducer = (state = { wifi: {} }, action) => {
  switch (action.type) {
    case ADD_HOTEL_WIFI_REQ:
      return {
        ...state,
        loading: true,
        isadded: false,
      };
    case ADD_HOTEL_WIFI_SUC:
      return {
        ...state,
        loading: false,
        wifi: action.payload,
        isadded: true,
      };
    case ADD_HOTEL_WIFI_FAIL:
      return {
        ...state,
        loading: false,
        isadded: false,
        error: action.payload,
      };
    case ADD_HOTEL_WIFI_RESET:
      return {
        ...state,
        loading: false,
        isadded: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

//update hotel wifi
export const updateHotelWifiReducer = (state = { wifi: {} }, action) => {
  switch (action.type) {
    case UPDATE_HOTEL_WIFI_REQ:
      return {
        ...state,
        loading: true,
        isupdated: false,
      };
    case UPDATE_HOTEL_WIFI_SUC:
      return {
        ...state,
        loading: false,
        wifi: action.payload,
        isupdated: true,
      };
    case UPDATE_HOTEL_WIFI_FAIL:
      return {
        ...state,
        loading: false,
        isupdated: false,
        error: action.payload,
      };
    case UPDATE_HOTEL_WIFI_RESET:
      return {
        ...state,
        loading: false,
        isupdated: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

//delete hotel wifi
export const deleteHotelWifiReducer = (state = { wifi: {} }, action) => {
  switch (action.type) {
    case DELETE_HOTEL_WIFI_REQ:
      return {
        ...state,
        loading: true,
        isdeleted: false,
      };
    case DELETE_HOTEL_WIFI_SUC:
      return {
        ...state,
        loading: false,
        wifi: action.payload,
        isdeleted: true,
      };
    case DELETE_HOTEL_WIFI_FAIL:
      return {
        ...state,
        loading: false,
        isdeleted: false,
        error: action.payload,
      };
    case DELETE_HOTEL_WIFI_RESET:
      return {
        ...state,
        loading: false,
        isdeleted: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};