import {
  ADD_ITEM_TO_MENU_FAIL,
  ADD_ITEM_TO_MENU_REQUEST,
  ADD_ITEM_TO_MENU_RESET,
  ADD_ITEM_TO_MENU_SUCCESS, 
  DELETE_MENU_ITEM_FAIL,
  DELETE_MENU_ITEM_REQUEST,
  DELETE_MENU_ITEM_RESET,
  DELETE_MENU_ITEM_SUCCESS,
  GET_MENU_ITEM_FAIL,
  GET_MENU_ITEM_REQUEST,
  GET_MENU_ITEM_SUCCESS,
  UPDATE_MENU_ITEM_FAIL,
  UPDATE_MENU_ITEM_REQUEST,
  UPDATE_MENU_ITEM_RESET,
  UPDATE_MENU_ITEM_SUCCESS,
} from "../../actions/typesKitchen/menu";
import { CLEAR_ERRORS } from "../../actions/types";

//add kitchen menu item reducer 
export const addKitcheMenuItemReducer = (state = { menuItem: {} }, action) => {
  switch (action.type) {
    case ADD_ITEM_TO_MENU_REQUEST:
      return {
        ...state,
        loading: true,
        isadded: false,
      };
    case ADD_ITEM_TO_MENU_SUCCESS:
      return {
        ...state,
        loading: false,
        isadded: true,
        menuItem: action.payload,
      };
    case ADD_ITEM_TO_MENU_FAIL:
      return {
        ...state,
        loading: false,
        isadded: false,
        error: action.payload,
      };
    case ADD_ITEM_TO_MENU_RESET:
      return {
        ...state,
        loading: false,
        isadded: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

//get all kitchen 
export const getKitchenMenuReducer = (state = { menuItems: [] }, action) => {
  switch (action.type) {
    case GET_MENU_ITEM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_MENU_ITEM_SUCCESS:      
    case ADD_ITEM_TO_MENU_SUCCESS:
    case DELETE_MENU_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        menuItems: action.payload,
      };
    case GET_MENU_ITEM_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

//delete kitchen menu item 
export const deleteKitchenMenuItemReducer = (state = { menuItem: {}}, action) => {
  switch (action.type) {
    case DELETE_MENU_ITEM_REQUEST:
      return {
        ...state,
        loading: true,
        isdeleted: false,
      };
    case DELETE_MENU_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        isdeleted: true,
        menuItem: action.payload,
      };
    case DELETE_MENU_ITEM_FAIL:
      return {
        ...state,
        loading: false,
        isdeleted: false,
        error: action.payload,
      };
    case DELETE_MENU_ITEM_RESET:
      return {
        ...state,
        loading: false,
        isdeleted: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

//update kitchen 
export const updateKitchenMenuItemReducer = (
  state = { menuItem: {} },
  action
) => {
  switch (action.type) {
    case UPDATE_MENU_ITEM_REQUEST:
      return {
        ...state,
        loading: true,
        isupdated: false,
      };
    case UPDATE_MENU_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        isupdated: true,
        menuItem: action.payload,
      };
    case UPDATE_MENU_ITEM_FAIL:
      return {
        ...state,
        loading: false,
        isupdated: false,
        error: action.payload,
      };
    case UPDATE_MENU_ITEM_RESET:
      return {
        ...state,
        loading: false,
        isupdated: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
