export const GET_HOTEL_ASSETS_REQ = "GET_HOTEL_ASSETS_REQ";
export const GET_HOTEL_ASSETS_SUC = "GET_HOTEL_ASSETS_SUC";
export const GET_HOTEL_ASSETS_FAIL = "GET_HOTEL_ASSETS_FAIL";

export const GET_HOTEL_ASSET_REQ = "GET_HOTEL_ASSET_REQ";
export const GET_HOTEL_ASSET_SUC = "GET_HOTEL_ASSET_SUC";
export const GET_HOTEL_ASSET_FAIL = "GET_HOTEL_ASSET_FAIL";

export const ADD_HOTEL_ASSET_REQ = "ADD_HOTEL_ASSET_REQ";
export const ADD_HOTEL_ASSET_SUC = "ADD_HOTEL_ASSET_SUC";
export const ADD_HOTEL_ASSET_FAIL = "ADD_HOTEL_ASSET_FAIL";
export const ADD_HOTEL_ASSET_RESET = "ADD_HOTEL_ASSET_RESET";

export const UPDATE_HOTEL_ASSET_REQ = "UPDATE_HOTEL_ASSET_REQ";
export const UPDATE_HOTEL_ASSET_SUC = "UPDATE_HOTEL_ASSET_SUC";
export const UPDATE_HOTEL_ASSET_FAIL = "UPDATE_HOTEL_ASSET_FAIL";
export const UPDATE_HOTEL_ASSET_RESET = "UPDATE_HOTEL_ASSET_RESET";

export const DELETE_HOTEL_ASSET_REQ = "DELETE_HOTEL_ASSET_REQ";
export const DELETE_HOTEL_ASSET_SUC = "DELETE_HOTEL_ASSET_SUC";
export const DELETE_HOTEL_ASSET_FAIL = "DELETE_HOTEL_ASSET_FAIL";
export const DELETE_HOTEL_ASSET_RESET = "DELETE_HOTEL_ASSET_RESET";
