import React from "react";

const RatingPercentage = ({ ratings }) => {
  return (
    <>
      <div className="w-full flex flex-col-reverse items-center">
        {Object.keys(ratings)?.map((rat, index) => (
          <div className="flex items-center w-[200px] justify-start" key={rat}>
            <div className="text-sm font-semibold mx-2 w-3 flex justify-center items-center">
              {rat}
            </div>
            {/* <Stack spacing={1} width={"180px"}>
              <Rating
                max={index + 1}
                name="half-rating-read"
                defaultValue={index + 1}
                precision={index + 1}
                readOnly
              />
            </Stack> */}
            <div className="text-sm font-semibold w-16 ml-2">
              {ratings[rat]}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default RatingPercentage;
