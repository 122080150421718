import React from "react";
import { useTranslation } from "react-i18next";
// import { FiArrowRight } from "react-icons/fi";
import pending_approval from "./images/pending_approval.png";
const ApprovalPending = ({ setStep, owner }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="flex items-start flex-col w-full px-14 min-h-[calc(100vh-12rem)] justify-center bg-[#FFFFFF]">
        <div className="flex justify-center items-center pt-6">
          <img
            src={pending_approval}
            alt=""
            className="w-[273px] h-[245.42px]"
          />
        </div>
        <div className="text-[40px] text-center capitalize mt-6 font-bold leading-[48px] tracking-[-1.5%]">
          {t("889")}
        </div>
        <div className="text-[#07090D] leading-[25px] text-[20px] tracking-[-1.5%] font-normal pt-2">
          {t("890")}
        </div>
      </div>
    </>
  );
};

export default ApprovalPending;
