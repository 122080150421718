import React, { useEffect, useState } from "react";
import { isObjEmpty } from "../../utils";
import DelIcon from "../../images/icons/DelIcon.svg";
import EditIcon from "../../images/icons/EidIcon.svg";
import { CustomeTable } from "../../components"; 
import { useTranslation } from "react-i18next";

const BuffetSummaryDataTable = (props) => {
  const [datas, setDatas] = useState([]);
  const [tenPlates, setTenPltes] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [unitValues, setUnitValues] = useState("");
  const { t } = useTranslation();
  useEffect(() => {
    if (props?.kitchen && !isObjEmpty(props?.kitchen?.kitchen)) {
      setDatas([
        {
          itemID: props?.kitchen?.kitchen?.itemID,
          itemName: props?.kitchen?.kitchen?.itemName,
          one_hundred_fifty_plates:
            props?.kitchen?.kitchen?.one_hundred_fifty_plates,
          _id: props?.kitchen?.kitchen?._id,
        },
      ]);
    }
  }, [props?.kitchen]);
  const tableData = [
    {
      itemID: "IT01",
      itemName: "Chicken Wings",
      one_hundred_fifty_plates: "$12",
    },
    {
      itemID: "IT02",
      itemName: "Chicken Karahi",
      one_hundred_fifty_plates: "$12",
    },
    {
      itemID: "IT03",
      itemName: "Caesar Salad",
      one_hundred_fifty_plates: "$12",
    },
    {
      itemID: "IT04",
      itemName: "Beef Steak",
      one_hundred_fifty_plates: "$12",
    },
    {
      itemID: "IT05",
      itemName: "Kheer",
      one_hundred_fifty_plates: "$12",
    },
  ];

  const ViewCreateBuffetAction = () => {
    return (
      <>
        <div className="flex gap-4 mt-[6px]">
          <button className="w-[30px] h-[30px] border border-[#E5E9EB] bg-[#FFFFFF] rounded-full flex items-center justify-center hover:bg-[#E5E9EB]">
            <img src={DelIcon} alt="" />
          </button>
          <button className="w-[30px] h-[30px] border border-[#E5E9EB] bg-[#FFFFFF] rounded-full flex items-center justify-center hover:bg-[#E5E9EB]">
            <img src={EditIcon} alt="" />
          </button>
        </div>
      </>
    );
  };
  const getUnitsInputs = () => {
    return (
      <>
        <div className="w-full flex items-center mt-1">
          {/* <UnitMeassureInputField2
            placeholder={t("Enter a Value")}
            value={unitValues}
            onChange={(e) => setUnitValues(e.target.value)}
            min={0}
            max={10}
            required={true}
            options={['']}
            selectValue={tenPlates}
            onChangeSelect={(e) => setTenPltes(e.target.value)}
          /> */}
          <div className="w-10 h-8 border border-[#E5E9EB] bg-[#FFFFFF] rounded-[5px] text-[14px] leading-[17.64px] font-normal text-[#667085] flex justify-center items-center shadow-inputFeild ml-4">
            $12
          </div>
        </div>
      </>
    );
  };
  const columnDefs = [
    {
      headerName: "Item ID",
      field: "_id",
      maxWidth: 30,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Item Name",
      field: "itemName",
      maxWidth: 250,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "150 Plates",
      field: "one_hundred_fifty_plates",
      maxWidth: 300,
      cellRenderer: getUnitsInputs,
    },
    {
      headerName: "Actions",
      field: "_id",
      maxWidth: 300,
      cellRenderer: ViewCreateBuffetAction,
    },
  ];
  return (
    <div>
      {datas ? (
        <CustomeTable
          columnDefs={columnDefs}
          rowData={tableData}
          setSelectedRows={setSelectedRows}
        />
      ) : null}
    </div>
  );
};

export default BuffetSummaryDataTable;
