import React, { useEffect, useState } from "react";
import Seperator from "../../components/layouts/Seperator";
import {
  ROUTES,
} from "../../constants";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { isObjEmpty } from "../../utils"; 
import DetailsHeading from "../../components/layouts/DetailsHeading";
import { CustomeTable } from "../../components";
import FormSubmitButton from "../../components/buttons";

const BuffetDetails = (props) => {
  const { t } = useTranslation();
  const [datas, setDatas] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  useEffect(() => {
    if (props?.kitchen && !isObjEmpty(props?.kitchen?.kitchen)) {
      setDatas([
        {
          nameOfDishes: props?.kitchen?.kitchen?.nameOfDishes,
          noOfDishes: props?.kitchen?.kitchen?.noOfDishes,
          inventoryDetails: props?.kitchen?.kitchen?.inventoryDetails,
          _id: props?.kitchen?.kitchen?._id,
        },
      ]);
    }
  }, [props?.kitchen]);
  const rowData = [
    {
      nameOfDishes: "Salad",
      noOfDishes: "08",
    },
    {
      nameOfDishes: "Buffet Items",
      noOfDishes: "12",
    },
    {
      nameOfDishes: "Appetizer",
      noOfDishes: "02",
    },
    {
      nameOfDishes: "Soups",
      noOfDishes: "05",
    },
    {
      nameOfDishes: "Side Dishes",
      noOfDishes: "12",
    },
    {
      nameOfDishes: "Main Course",
      noOfDishes: "02",
    },
    {
      nameOfDishes: "Deserts",
      noOfDishes: "03",
    },
  ];

  const ViewBuffetDetailsActions = () => {
    const navigate = useNavigate();
    const gotoCreateBuffetInventoryDeatails = (link) => {
      if (link) {
        navigate(link);
      }
    };
    return (
      <>
        <FormSubmitButton
          text={t("Check Inventory Details")} 
          onClick={() =>
            gotoCreateBuffetInventoryDeatails(
              ROUTES.KITCHEN_BUFFET_INVENTORY
            )
          }
        />
      </>
    );
  };

  const columnDefs = [
    {
      headerName: "",
      field: "Kitchen_id",
      maxWidth: 120,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Name Of Dishes",
      field: "nameOfDishes",
      maxWidth: 400,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "No Of Dishes",
      field: "noOfDishes",
      maxWidth: 400,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Actions",
      field: "_id",
      maxWidth: 400,
      minWidth: 100,
      cellRenderer: ViewBuffetDetailsActions,
    },
  ];

  const dataa = {
    heading: t("36"),
    seperator: [
      {
        text: t("134"),
      },
      {
        link: ROUTES.KITCHENS,
        text: t("36"),
      },
      {
        link: ROUTES.KITCHEN_BUFFETS,
        text: t("Buffet"),
      },
      {
        text: t("Buffet Details"),
      },
    ],
  };
  return (
    <div className="page-container max-w-7xl">
      <div className="w-full max-w-full">
        <div className="flex justify-between items-end">
          <Seperator data={dataa} />
        </div>
      </div>
      <div className="text-[24px] leading-[30.24px] font-bold tracking-[-0.8px] text-[#07090D] mt-6">
        Buffet Information
      </div>
      <div className=" border border-[#E5E9EB] rounded-[10px]  bg-[#FFFFFF] p-6 my-4">
        <div className="grid grid-cols-4 items-center gap-10">
          <DetailsHeading heading={"Chef Name"} subHeading={"Amul"} />
          <DetailsHeading heading={"Order Date"} subHeading={"10-04-2024"} />
          <DetailsHeading
            heading={"Completion Date"}
            subHeading={"11-04-2024"}
          />
          <DetailsHeading heading={"Buffet Time"} subHeading={"02:00 pm"} />
          <DetailsHeading heading={"Total Dishes"} subHeading={"32"} />
          <div>
            <div className="text-[14px] text-[#6E7C87] leading-[17.64px] font-medium tracking-[-0.8px]">
              Status
            </div>
            <div className="text-[18px] text-base-primary leading-[22.68px] font-normal tracking-[-0.8px]">
              Completed
            </div>
          </div>
        </div>
      </div>

      {/* stock table */}
      <div className="text-[24px] leading-[30.24px] font-bold tracking-[-0.8px] text-[#07090D] mb-4">
        Stock Table
      </div>
      <div className="">
        <CustomeTable
          columnDefs={columnDefs}
          rowData={rowData}
          setSelectedRows={setSelectedRows}
        />
      </div>
    </div>
  );
};

export default BuffetDetails;
