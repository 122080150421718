import React from "react";
import { RxCross2 } from "react-icons/rx";
import { FaPlus, FaMinus } from "react-icons/fa6";

const OrderCards = ({ image, heading, price, count = "2" }) => {
  return (
    <div className="w-full flex items-center gap-4 border-b border-[#E5E9EB] last:border-none py-5">
      <div className="h-[71px] w-[68px] flex items-center justify-center rounded-lg overflow-hidden">
        <img src={image} alt="" className="bg-no-repeat object-fill" />
      </div>
      <div className="flex flex-col w-full h-[71px]">
        <div className="flex items-center justify-between text-[18px] leading-[24.53px] font-medium text-[#07090D]">
          {heading}
          <button
            type="button"
            className="h-[24.56px] w-[24.56px] flex items-center justify-center bg-[#F6F6F6] rounded-[5px] hover:opacity-45"
          >
            <RxCross2 size={18} />
          </button>
        </div>
        <div className="flex items-end justify-between">
          <div className="text-[20px] leading-[24.53px] font-normal text-[#6E7C87]">
            {price}
          </div>
          <div className="flex items-center justify-between gap-4 mt-4">
            <button className="h-[30px] w-[30px] flex items-center justify-center rounded-[5px] bg-[#F6F6F6] hover:opacity-45">
              <FaMinus size={18} />
            </button>
            <p className="text-[20px] leading-[22px] font-semibold">{count}</p>
            <button className="h-[30px] w-[30px] flex items-center justify-center rounded-[5px] bg-[#F6F6F6] hover:opacity-45">
              <FaPlus size={18} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderCards;
