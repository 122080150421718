import React from "react";
import Seperator from "../../components/layouts/Seperator";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { getToday } from "../../utils";

const OperationsHeader = ({ month, setMonth }) => {
  const { t } = useTranslation();
  const [date, setDate] = useState(getToday());

  const headingData = {
    heading: t("Kitchen Operations"),
    seperator: [
      {
        text: t("134"),
      },
      {
        text: t("Kitchen Operations"),
      },
    ],
  };

  return (
    <>
      <div className="w-full flex items-center justify-between max-w-full mx-auto mb-6">
        <Seperator data={headingData} />
        <div className="flex mt-4 gap-2">
          <button
            type="button"
            className="h-10 w-[129px] text-[14px] leading-[17.64px] font-normal rounded-md text-[#FFFFFF] bg-base-primary hover:bg-base-primary_hover shadow-[#0000001a]"
            onClick={"#"}
          >
            Today
          </button>
          <button
            type="button"
            className="h-10 w-[129px] text-[14px] leading-[17.64px] font-normal rounded-md border border-[#0000001a] hover:text-[#FFFFFF] bg-[#FFFFFF] hover:bg-base-primary shadow-[#0000001a]"
            onClick={"#"}
          >
            Tomarrow
          </button>

          <input
            value={date}
            onChange={(e) => setDate(e.target.value)}
            type={"date"}
            className="flex items-center h-10 w-[140px] bg-[#FFFFFF] rounded-md font-normal cursor-pointer select-none text-[14px] leading-[18px] tracking-[-0.8px]"
          />
        </div>
      </div>
    </>
  );
};

export default OperationsHeader;
