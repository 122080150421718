import React, { useState } from "react";
import { useTranslation } from "react-i18next";
// import BillingInformation from "../booking/BillingInformation";
// import {
//   BOOKING_BILL_TO,
//   PAYMENT_METHODS,
//   PAYMENT_STATUS,
// } from "../../constants";

import Seperator from "../../../components/layouts/Seperator";
import AddButton from "../../../components/buttons/AddButton";
import AddReservation from "./AddReservation";
import { registerEvent } from "../../../logs";
import { isObjEmpty } from "../../../utils";

const HotelCheckIn = () => {
  const { t } = useTranslation();
  const [addButton, setAddButton] = useState(false);
  const [addReservation, setAddReservation] = useState({});
  //   Billing information
  // const [paymentStatus, setPaymentStatus] = useState(PAYMENT_STATUS[0]);
  // const [paymentMethod, setPaymentMethod] = useState(PAYMENT_METHODS[0]);
  // const [totalAmount, setTotalAmount] = useState("");
  // const [isRefundable, setIsRefundable] = useState(false);
  // const [refundableTill, setRefundableTill] = useState("");
  // const [billTo, setBillTo] = useState(BOOKING_BILL_TO[0]);
  // const [taxExemption, setTaxExemption] = useState(false);
  //register event for Add inventory
  const registerEventAndOpen = () => {
    setAddButton(true);
    registerEvent("add reservation button open", "add item reservation", {
      addButton,
    });
  };
  // register evnet on close add inventry component
  const close = () => {
    if (!isObjEmpty(addReservation)) setAddReservation(null);
    setAddButton(false);
    registerEvent("add roaster button close", "add item roaster", {
      addButton,
    });
  };
  const headingData = {
    heading: t("Hotel Check In"),
    seperator: [
      {
        text: t("134"),
      },
      {
        text: t("Check In"),
      },
    ],
  };
  return (
    <div className="page-container max-w-full">
      <div className="w-full flex justify-between items-end max-w-7xl mx-auto">
        <Seperator  data={headingData} />
        <AddButton text={t("Add Reservaton")} onClick={registerEventAndOpen} />
      </div>
      <div>
        {!isObjEmpty(addReservation) || addButton ? (
          <AddReservation close={close} />
        ) : null}
      </div>
      {/* <div className="mt-4">
        <BillingInformation
          paymentStatus={paymentStatus}
          setPaymentStatus={setPaymentStatus}
          paymentMethod={paymentMethod}
          setPaymentMethod={setPaymentMethod}
          amount={totalAmount}
          setAmount={setTotalAmount}
          refundable={isRefundable}
          setRefundable={setIsRefundable}
          refundableTill={refundableTill}
          setRefundableTill={setRefundableTill}
          billTo={billTo}
          setBillTo={setBillTo}
          taxExemption={taxExemption}
          setTaxExemption={setTaxExemption}
        />
      </div> */}
    
    </div>
  );
};

export default HotelCheckIn;
