/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateHotelAction } from "../../actions/pms/hotel";
import LoadSpinner from "../../components/loader/LoadSpinner";
import PropertyContacts from "../../components/layouts/PropertyContacts";
import { ONBOARDING_STAGES } from "../../constants";
import PageLoader from "../../components/loader/PageLoader";
import { useTranslation } from "react-i18next";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";

const Step2 = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [contacts, setContacts] = useState([]);
  const { loading, isupdated } = useSelector((state) => state.UpdateHotel);
  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    if (props?.hotel && props?.hotel?._id) {
      if (props?.hotel?.contacts) {
        setContacts(props?.hotel?.contacts);
      }
      setLoadingData(false);
    }
  }, [props?.hotel, isupdated]);

  const formSubmit = () => {
    if (!contacts.length) return;
    const formData = {
      hotelId: props?.hotel?._id,
      stage: ONBOARDING_STAGES.AMENITY,
    };
    dispatch(updateHotelAction(formData));
  };

  useEffect(() => {
    if (isupdated) {
      props.reset();
      props.setStep(3);
    }
  }, [isupdated]);

  // next step

  function nextStep() {
    props.setStep(3);
  }

  return (
    <>
      {loadingData ? (
        <PageLoader />
      ) : (
        <div className="flex items-start flex-col w-full pb-6">
          <div className="mx-auto px-4">
            <div className="relative flex flex-col ">
              <label className="inline-block text-center text-[40px] leading-[72px] font-bold tracking-[-1.5%]">
                {t("Important Property Contacts")}
              </label>
              <PropertyContacts contacts={contacts} setContacts={setContacts} />
            </div>
            <div className="flex justify-between items-center my-6 relative w-full mx-auto text-sm font-medium">
              <button
                className="w-[120px] h-12 flex items-center justify-center gap-2 rounded-md hover:bg-gray-200 bg-[#FFFFFF] border border-[#E5E9EB] text-[#252C32] capitalize"
                onClick={() => props.setStep(1)}
              >
                <FiArrowLeft size={18} />
                {t("64")}
              </button>
              <button
                className="w-[120px] h-12 flex justify-center items-center gap-2 bg-base-primary hover:bg-base-primary_hover rounded-md  text-[#FFFFFF] capitalize"
                // formSubmit
                onClick={nextStep}
                disabled={loading}
              >
                {loading ? (
                  <LoadSpinner />
                ) : (
                  <>
                    {t("65")} 
                    <FiArrowRight size={18} />
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Step2;
