import React from "react";
import { useTranslation } from "react-i18next";

const ReservationInfo = ({ booking, handleCancel, top, left }) => {
  const { t } = useTranslation();
  return (
    <>
      <div
        className={`absolute z-30 ${left ? "right-10" : "left-10"} ${
          top ? "-top-[160px]" : "top-3"
        }`}
      >
        <div className="w-[318.9px] h-[211px] border rounded-[10px] border-[#d0d5dd] bg-white relative">
          <div
            className={`${left ? "-right-2" : "-left-2"} ${
              top ? "bottom-3" : "top-3"
            } absolute h-4 w-4 bg-white rotate-45`}
          ></div>
          <div className="flex p-2">
            {/* left entries */}
            <div className="border-r-2 w-[180px] pl-2">
              <div className="text-[14px] leading-[17.64px] tracking-[-0.8px] text-[#344054]">
                {t("641")}
              </div>
              <div className=" text-[20px] leading-[25.2px] tracking-[-0.8px] font-semibold pt-1">
                {booking?.bookingName}
              </div>
              <div className="pt-5">
                <div className="text-[14px] leading-[17.64px] tracking-[-0.8px] text-[#344054] pt-1">
                  {t("156")}
                </div>
                <div className=" text-[20px] leading-[25.2px] tracking-[-0.8px] font-semibold pt-1">
                  {booking?.checkInDate?.substring(0, 10)}
                </div>
              </div>
            </div>
            {/* right entries */}
            <div className="pl-4">
              <div className="text-[14px] leading-[17.64px] tracking-[-0.8px] text-[#344054]">
                {t("156")}
              </div>
              <div className=" text-[20px] leading-[25.2px] tracking-[-0.8px] font-semibold pt-1">
                {booking?.guestNumber}
              </div>
              <div className="pt-5">
                <div className="text-[14px] leading-[17.64px] tracking-[-0.8px] text-[#344054]">
                  {t("642")}
                </div>
                <div className=" text-[20px] leading-[25.2px] tracking-[-0.8px] font-semibold pt-1">
                  {booking?.roomPrice}
                </div>
              </div>
            </div>
          </div>
          <div className="w-full m-[18px] ">
            <button
              className="w-[281px] h-10 border border-[#ff2424] bg-white rounded-lg text-[14px] leading-[14px] tracking-[-0.6%] font-semibold text-[#ff2424]"
              type="button"
              onClick={handleCancel}
            >
              {t("643")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReservationInfo;
