import axios from "axios";
import { ISADMIN, TOKEN } from "../../constants";
import {
  GET_HOTEL_ROOMS_FAIL,
  GET_HOTEL_ROOMS_REQ,
  GET_HOTEL_ROOMS_SUC,
  GET_ROOM_BY_ID_FAIL,
  GET_ROOM_BY_ID_REQ,
  GET_ROOM_BY_ID_SUC,
} from "../types/room";

//get hotel rooms
export const getAllRoomAction = (hotelId) => async (dispatch) => {
  let url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/rooms/all?hotelId=${hotelId}`;
  const admin = await JSON.parse(localStorage.getItem(ISADMIN))
  if (!admin)
    url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/employee/rooms/all`;

  try {
    dispatch({ type: GET_HOTEL_ROOMS_REQ });
    const { data } = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: GET_HOTEL_ROOMS_SUC,
      payload: data.rooms,
    });
  } catch (error) {
    dispatch({
      type: GET_HOTEL_ROOMS_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

//get hotel room by id
export const getRoomByIdAction = (hotelId, roomId) => async (dispatch) => {
  if(!hotelId || !roomId) return;
  const url = `api/hotel/owner/rooms/room?hotelId=${hotelId}&roomId=${roomId}`;

  try {
    dispatch({ type: GET_ROOM_BY_ID_REQ });
    const { data } = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: GET_ROOM_BY_ID_SUC,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ROOM_BY_ID_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};
