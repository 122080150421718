import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearError } from "../actions/clearError";
import { getAllHotelAction, getHotelAction } from "../actions/pms/hotel";
import { PROPERTY_ID, ROUTES } from "../constants";

const Myhotels = (props) => {
  const navigate = useNavigate();
  const [hotelIsNotInLocal, setHotelIsNotInLocal] = useState(false);
  //check is hotel is exist in localStorage or not if not than call api to get hotel
  const isHotelExist = async () => {
    const hotelId = localStorage.getItem(PROPERTY_ID);
    if (!hotelId) {
      props.getHotels();
      setHotelIsNotInLocal(true);
    } else {
      props.getHotel(hotelId);
    }
  };
  useEffect(() => {
    isHotelExist();
  }, []);

  const setHotelData = (hotel) => {
    // there is no hotel _id in local storage
    const hotelId = localStorage.getItem(PROPERTY_ID);
    if (!hotelId) {
      props.getHotel(hotel?._id);
      localStorage.setItem(PROPERTY_ID, hotel?._id);
    }
  };

  useEffect(() => {
    //if getted all the hotels
    if (
      props?.hotels &&
      props?.hotels?.hotels &&
      Array.isArray(props?.hotels?.hotels) &&
      props?.hotels?.hotels[0] &&
      props?.hotels?.hotels[0]?._id &&
      hotelIsNotInLocal
    ) {
      setHotelData(props?.hotels?.hotels[0]);
    } else if (
      props.hotels &&
      Array.isArray(props?.hotels?.hotels) &&
      props?.hotels?.loading === false &&
      !props?.hotels?.hotels?.length
    ) {
      // YOU HAVE NOT ANY HOTELS
      navigate(ROUTES.ONBOARDING);
    }
    //clear get all hotels error
    if (props?.hotels && props?.hotels?.error) {
      props.clear();
    }
  }, [props?.hotels]);

  return null;
};

const mapStateToProps = (state) => ({
  hotels: state.AllHotel,
});

const mapDispatchToProps = (dispatch) => ({
  getHotel: (hotelId) => dispatch(getHotelAction(hotelId)),
  getHotels: () => dispatch(getAllHotelAction()),
  clear: () => dispatch(clearError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Myhotels);
