import React, { useState } from "react";
import { IoMdArrowDropright } from "react-icons/io";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link } from "react-router-dom";

const SidebarMenu = () => {
  const [isOpenSubMenuItem, setIsOpenSubMenuItem] = useState(null);
  const [active, setActive] = useState(false);
  const MENU = [
    {
      id: 1,
      name: "Kunggy",
      submenu: {
        title: "Introduction to kunggy",
        titleLink: "/kunggy-intro",
        quiz: "Take a quiz",
        quizLink: "/kunggy-intro/quiz",
      },
    },
    {
      id: 2,
      name: "Deshbored",
      submenu: {
        title: "Introduction to Deshboared",
        quiz: "Take a quiz",
      },
    },
    {
      id: 3,
      name: "All Hotel",
      submenu: {
        title: "Introduction to All Hotel",
        quiz: "Take a quiz",
      },
    },
    {
      id: 4,
      name: "Reservation",
      submenu: {
        title: "Introduction to Reservation",
        quiz: "Take a quiz",
      },
    },
    {
      id: 5,
      name: "Customer",
      submenu: {
        title: "Introduction to Customers",
        quiz: "Take a quiz",
      },
    },
    {
      id: 6,
      name: "House Keeping",
      submenu: {
        title: "Introduction to House Keeping",
        quiz: "Take a quiz",
      },
    },
    {
      id: 7,
      name: "Rooms",
      submenu: {
        title: "Introduction to Rooms",
        quiz: "Take a quiz",
      },
    },
    {
      id: 8,
      name: "Laundry",
      submenu: {
        title: "Introduction to Laundry",
        quiz: "Take a quiz",
      },
    },
  ];
  return (
    <div>
      {MENU.map((item) => {
        const handleSubmenu = (id) => {
          setIsOpenSubMenuItem(isOpenSubMenuItem === id ? null : id);
        };
        return (
          <>
            <li
              className="flex items-center text-[#07090D] capitalize  p-4 hover:bg-[#00a35e26] border-b border-[#00a35e26]"
              key={item.id}
            >
              <button
                type="button"
                onClick={() => handleSubmenu(item.id)}
                className="w-full flex items-center justify-between py-2"
              >
                <div className="flex items-center gap-2 text-[20px] leading-[21px] font-semibold ">
                  <BsThreeDotsVertical size={30} />
                  Module {item.id} : {item.name}
                </div>
                <IoMdArrowDropright
                  size={30}
                  className={`transition-all duration-[0.5s] ${
                    isOpenSubMenuItem === item.id ? "rotate-90" : ""
                  }`}
                />
              </button>
            </li>
            {isOpenSubMenuItem === item.id ? (
              <ul className="transition-all delay-[0.5s]">
                <li
                  className={`py-4 px-6  hover:bg-[#00a35e26] transition-all duration-[0.3s] ease-[0s] ${
                    active
                      ? "border-l-[5px] border-base-primary bg-[#00a35e26]"
                      : null
                  } `}
                >
                  <div className="flex items-center gap-4">
                    <div className="size-[15px] p-[2px] border border-base-primary rounded-full overflow-hidden">
                      {active ? (
                        <div className="h-full w-full rounded-full bg-base-primary"></div>
                      ) : null}
                    </div>
                    <Link
                      to={item.submenu.titleLink}
                      className={`text-[16px] leading-[21px] font-medium text-[#07090D]`}
                    >
                      {item.submenu.title}
                    </Link>
                  </div>
                </li>
                <li
                  className={`py-4 px-6  hover:bg-[#00a35e26] transition-all duration-[0.3s] ease-[0s] ${
                    active
                      ? "border-l-[5px] border-base-primary bg-[#00a35e26]"
                      : null
                  } border-b border-[#00a35e26]`}
                >
                  <div className="flex items-center gap-4">
                    <div className="size-[15px] p-[2px] border border-base-primary rounded-full overflow-hidden">
                      {active ? (
                        <div className="h-full w-full rounded-full bg-base-primary"></div>
                      ) : null}
                    </div>
                    <Link
                      to={item.submenu.quizLink}
                      className="text-[16px] leading-[21px] font-medium text-[#07090D]"
                    >
                      {item.submenu.quiz}
                    </Link>
                  </div>
                </li>
              </ul>
            ) : null}
          </>
        );
      })}
    </div>
  );
};

export default SidebarMenu;
