import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { FaAngleDown } from "react-icons/fa"; 
import EditButton from '../../components/buttons/EditButton';
import DeleteButton from '../../components/buttons/DeleteButton'; 
import { BOOKING_ROOM_TYPES } from '../../constants';
import SelectFromObject from '../../components/layouts/SelectFromObject';

const AddAutomationAndRoomTarrifRules = ({
  pageOptions,
  previousPage,
  gotoPage,
  nextPage,
  canPreviousPage,
  canNextPage,
  pageSize,
  setPageSize,
  pageCount,
}) => {
  const { t } = useTranslation();
  const [roomType, setRoomType] = useState([]);
  const [selectIncAndDecValue, setSelectIncAndDecValue] = useState([]);
  const [inputPercentage, setInputPercentage] = useState("");
  const [percentage, setPercentage] = useState("%");

  const [selectIncAndDecOccupency, setSelectIncAndDecOccupency] = useState([]);
  const [inputOccupencyPercentage, setInputOccupencyPercentage] = useState("");
  const [occupencypercentage, setOccupencyPercentage] = useState("%");

  const [selectedAddRule, setSelectedRule] = useState("")
  const [isSelectAddRule, setIsSelectRule] = useState(false)

  const handleAddRulesSubmit = (e) => {
    e.preventDefault();
    const AddRulesData = {
      roomType,
      selectIncAndDecValue,
      inputPercentage,
      percentage,
      selectIncAndDecOccupency,
      inputOccupencyPercentage,
      occupencypercentage
    }
    console.log(AddRulesData);
    setIsSelectRule(true);
  }
  useEffect(() => {
    setSelectIncAndDecValue("");
    setInputPercentage("");
    setPercentage("%");
    setSelectIncAndDecOccupency("");
    setInputOccupencyPercentage("");
    setOccupencyPercentage("%");
  }, [])


  return <>
    <div className="box-border border border-[#E4E4E7] rounded-[10px] bg-white p-4">
      <div className="text-[18px] leading-[23px] font-semibold tracking-[-0.8px]">
        {t("Add Automation Rule")}
      </div>
      <form onSubmit={handleAddRulesSubmit} className="lg:flex md:grid md:grid-cols-1 w-full items-center gap-[5px] my-5 overflow-hidden">

        <div className="flex items-center gap-2 md:w-full lg:w-[232px] ">
          <div className="text-sm font-medium text-[#344054]">
            {t("Select Room Type")}
          </div>
          <SelectFromObject
            value={roomType}
            onChange={(e) => setRoomType(e.target.value)}
            options={BOOKING_ROOM_TYPES}
          />
        </div>
        <div className="text-sm font-medium text-[#344054] w-fit">
          {t("Price : ")}
        </div>
        <div className='flex items-center gap-2' >
          <select value={selectIncAndDecValue} onChange={(e) => setSelectIncAndDecValue(e.target.value)} className="w-28 px-0 text-sm font-medium underline uppercase border-none shadow-none focus:border-none">
            <option value={"increase By"}>Increase By</option>
            <option value={"decrease By"}>Dcrease By</option>
          </select>
          <input type="text" className="text-sm font-medium md:w-full lg:w-24" value={inputPercentage} onChange={(e) => setInputPercentage(e.target.value)} />
          <input type='text' value={percentage} onChange={(e) => setPercentage(e.target.value)} className='w-5 border-none shadow-none focus:border-none p-0' readOnly />
          <FaAngleDown />
        </div>

        <div className="text-sm font-medium text-[#344054] w-fit">
          {t("When : ")}
        </div>

        {/* price increas by */}
        <div className="flex items-center gap-2">
          <select value={selectIncAndDecOccupency} onChange={(e) => setSelectIncAndDecOccupency(e.target.value)} className="w-48 px-0 text-sm font-medium underline uppercase border-none shadow-none focus:border-none">
            <option value={"occupency Increase By"}> Occupency Increase By</option>
            <option value={"occupency Decrease By"}>Occupency Decrease By</option>
          </select>
          <input type="text" className="text-sm font-medium md:w-full lg:w-24" value={inputOccupencyPercentage} onChange={(e) => setInputOccupencyPercentage(e.target.value)} />
          <input type='text' value={occupencypercentage} onChange={(e) => setOccupencyPercentage(e.target.value)} className='w-5 border-none shadow-none focus:border-none p-0' readOnly />
          <FaAngleDown />
        </div>

        <button type="submit" className="h-[31px] w-[31px] rounded-full  font-medium text-[17.24px] leading-6 text-black hover:bg-gray-100 bg-white shadow-[0_5px_20px_0_rgba(0,0,0,0.05)]">
          +
        </button>

      </form>
      <div className="flex gap-4 justify-end pt-9">
        <button className="h-9 w-[76px] border rounded-md shadow-inputFeild text-sm font-medium text-[#252C32] hover:bg-gray-200">
          {t("Cancel")}
        </button>
        <button className="h-9 w-[61px] border rounded-md shadow-inputFeild text-sm font-medium bg-base-primary hover:bg-base-primary_hover text-white">
          {t("Save")}
        </button>
      </div>
    </div>
    {/* Room Tariff Automation */}
    <div className="flex justify-between items-center mt-12 mb-4">
      <div className="text-[30px] leading-[38px] font-bold tracking-[-0.8px]">
        {t("Room Tariff Automation Rules")}
      </div>
      <div className="flex gap-4">
        <EditButton />
        <DeleteButton />
      </div>
    </div>
    <div className="box-border border border-[#E4E4E7] rounded-[10px] bg-white p-4">
      {
        isSelectAddRule &&
        <div className="flex items-center gap-2">
          <input
            type="checkbox"
            value={selectedAddRule}
            className="h-4 w-4 border border-[#B0BABF] rounded accent-current bg-[#B0BABF]"
          />
          <span className="flex gap-2 text-[20px] leading-6 font-normal tracking-[-0.6%]">
            {roomType} room price
            <span className="text-[20px] uppercase font-semibold">
              {selectIncAndDecValue}
            </span>
            {` ${inputPercentage} ${percentage}`}
            <span className='font-normal'> When</span>
            <span className="text-[20px] uppercase font-semibold">
              {selectIncAndDecOccupency}
            </span>
            <span className="font-normal">{`${inputOccupencyPercentage} ${occupencypercentage}`}</span>
          </span>
        </div>
      } 
    </div>

  </>
}

export default AddAutomationAndRoomTarrifRules