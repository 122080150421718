import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { getAllHotelAction } from "../../actions/pms/hotel";
import { PROPERTY_ID, ROUTES } from "../../constants";
import { registerEvent } from "../../logs";
import { GET_HOTEL_SUCCESS } from "../../actions/types/hotel";
import { CLEAR_REDUX } from "../../actions/types";
import { useTranslation } from "react-i18next";
import Seperator from "../../components/layouts/Seperator";
import { useNavigate } from "react-router-dom";
import { getOwnerProfileAction } from "../../actions/pms/ownerProfile";
import { authenticateHotelOwner } from "../../actions/pms/ownerAuth";
import calenderMini from "../../images/icons/calenderMini.svg"
import AddButton from "../../components/buttons/AddButton";
import CustomeTable from "../../components/CustomeTable";
import Calenders from "../../components/layouts/Calenders";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
// import DownloadData from "../../components/modals/DownloadData";
import UploadButton from "../../components/buttons/UploadButton";
import DownloadButton from "../../components/buttons/DownloadButton";
// import UploadData from "../download_and_upload/UploadData";

const AllHotel = (props) => {
  const [datas, setDatas] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [open, setOpen] = useState(false);
  // const [download, setDownload] = useState(false);
  // const [upload, setUpload] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  //get all hotels
  const getHotelsNow = () => {
    if (
      (props?.data && props?.data?.hotels && !props?.data?.hotels?.length) ||
      (props?.data && !props?.data?.hotels)
    )
      props.getHotels();
  };
  useEffect(() => {
    getHotelsNow();
  }, []);

  //set datas
  useEffect(() => {
    if (
      props?.data &&
      props?.data?.hotels &&
      Array.isArray(props?.data?.hotels)
    ) {
      const hotels = props?.data?.hotels?.map((hotel) => {
        return {
          propertyName: hotel?.propertyName,
          propertyType: hotel?.propertyType,
          pl: 10,
          expense: 32,
          revenue: 65,
          monthly_ex_and_re: "$4000",
          occupancy: 54,
          _id: hotel?._id,
        };
      });
      if (hotels?.length) {
        setDatas(hotels?.reverse());
      }
    }
  }, [props?.data]);

  //select hotel
  const selectDataForTrack = (id) => {
    if (id) {
      registerEvent("select hotel", "select hotel button", { id });
      if (props?.data && props?.data?.hotels) {
        const hotel = props?.data?.hotels?.filter((x) => x?._id === id);
        if (hotel[0] && hotel[0]?._id) {
          localStorage.setItem(PROPERTY_ID, hotel[0]?._id);
          props.getHotel(hotel[0]);
        }
      }
    }
  };
  //add new hotel
  const gotoAddNewHotel = () => {
    localStorage.removeItem(PROPERTY_ID);
    props.clearRedux();
    props.getProfile();
    navigate(ROUTES.DASHBOARD);
  };
  const handleCalender = () => {
    setOpen(true)
    console.log("open calender")
  };
  // const handledownload = () => {
  //   setDownload(true);
  // }
  // const handleUpload = () => {
  //   setUpload(true)
  // }
  const customeButton = (e) => {
    return (
      <button
        className="h-[32px] w-[145px] ml-2 capitalize bg-base-primary hover:bg-base-primary_hover rounded text-[14px] text-[#FFFFFF] leading-6 tracking-[-0.6%]"
        onClick={() => selectDataForTrack(e?.value)}
      >
        {t("51")}
      </button>
    );
  };

  const columnDefs = [
    {
      headerName: "Hotel ID",
      field: "_id",
      maxWidth: 150,
      filter: true,
      // floatingFilter: true,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Property Name",
      field: "propertyName",
      minWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Property Type",
      field: "propertyType",
      maxWidth: 150,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Pl",
      field: "pl",
      maxWidth: 150,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Expense",
      field: "expense",
      maxWidth: 150,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Revenue",
      field: "revenue",
      maxWidth: 150,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Monthly_ex_and_re",
      field: "monthly_ex_and_re",
      maxWidth: 150,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Occupancy",
      field: "occupancy",
      maxWidth: 100,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Actions",
      field: "_id",
      cellRenderer: customeButton,
      minWidth: 200,
    },
  ];

  const track = {
    heading: t("My Hotel"),
    seperator: [
      {
        text: t("492"),
      },
      { text: t("491") },
    ],
  };

  return (
    <>
      <div className="page-container max-w-7xl">
        <div className="w-full flex items-end justify-between mx-auto mb-6">
          <Seperator data={track} />
          <AddButton onClick={gotoAddNewHotel} text={t("58")} />
        </div>
        <div className="flex items-center justify-end gap-4 text-[14px] leading-[17.64px] tracking-[-0.8px] font-normal text-[#18181B] mb-4">
          <button
            type="butoon"
            className="h-10 w-[86px] border border-[#E5E9EB] rounded-md bg-white hover:bg-[#f5f7f2]"
          >Today</button>

          <button
            type="butoon"
            className="h-10 w-[110px] border border-[#E5E9EB] rounded-md bg-white hover:bg-[#f5f7f2]"
          >Tomorrow</button>

          <div className="h-10 w-[308px] flex items-center justify-center border border-[#0000001a] rounded-md bg-[#FFFFFF] hover:bg-[#f5f7f2] overflow-hidden">
            <button
              type='button'
              className="flex items-center justify-center gap-4 "
              onClick={handleCalender}>
              <div className="flex items-center gap-3 w-fit">
                <img src={calenderMini} alt='' />
                <div>06-07-2024</div>
                {open ? (
                  <IoIosArrowDown
                    size={18}
                  />
                ) : (
                  <IoIosArrowUp
                    size={18}
                  />
                )}
              </div>
              {/* display next month date */}
              <div className="flex items-center gap-3 w-fit">
                <img src={calenderMini} alt='' />
                <div>06-07-2024</div>
                {open ? (
                  <IoIosArrowDown
                    size={18}
                  />
                ) : (
                  <IoIosArrowUp
                    size={18}
                  />
                )}
              </div>
            </button>
          </div>

          {open ? <Calenders open={open} close={() => setOpen(false)} /> : null}
          {/* upload data button onClick={handleUpload}*/}
          <UploadButton />
          {/* {upload ? <UploadData open={upload} close={() => setUpload(false)} /> : null} */}
          {/* download data button  onClick={handledownload}*/}
          <DownloadButton />
          {/* {download ? <DownloadData open={download} close={() => setDownload(false)} /> : null} */}
        </div>
        <CustomeTable
          columnDefs={columnDefs}
          rowData={datas}
          setSelectedRows={setSelectedRows}
        />
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  data: state.AllHotel,
  profile: state.OwnerProfile,
  owner: state.Authenticate,
});
const mapDispatchToProps = (dispatch) => ({
  getHotels: () => dispatch(getAllHotelAction()),
  getHotel: (payload) => dispatch({ type: GET_HOTEL_SUCCESS, payload }),
  getProfile: () => {
    dispatch(getOwnerProfileAction());
    dispatch(authenticateHotelOwner());
  },
  clearRedux: () => dispatch({ type: CLEAR_REDUX }),
});
export default connect(mapStateToProps, mapDispatchToProps)(AllHotel);
