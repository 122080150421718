import React from "react";

const AddRoomTypeCheckBox = ({ value, setValue, label, isRequired }) => {
  return (
    <>
      <div
        className={`w-fit relative flex justify-between items-center py-2 h-10`}
      >
        <label
          className="text-sm font-semibold text-[#344054] mr-3 capitalize"
          htmlFor={label}
        >
          {label}
        </label>
        <input
          type={"checkbox"}
          checked={value}
          id={label}
          onChange={() => setValue(!value)}
          value={value}
          className={"checkBoxControl"}
        />
      </div>
    </>
  );
};

export default AddRoomTypeCheckBox;
