import axios from "axios";
import { TOKEN } from "../../constants";
import {
  GET_ALL_SALES_FAIL,
  GET_ALL_SALES_REQ,
  GET_ALL_SALES_SUC,
} from "../types/salesReport";

//get all sales report bookings
export const getBookingSalesReport = (hotelId) => async (dispatch) => {
  if(!hotelId) return;
  let url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/reports/bookings/sales/all`;
  try {
    dispatch({ type: GET_ALL_SALES_REQ });
    const { data } = await axios.post(
      url,
      { hotelId },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem(TOKEN),
        },
        withCredentials: true,
      }
    );
    dispatch({
      type: GET_ALL_SALES_SUC,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_SALES_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};
