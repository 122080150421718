import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { isObjEmpty } from "../../utils";
import { registerEvent } from "../../logs";
import HotelAllInventory from "./HotelAllInventory";
import HotelAddInventory from "./HotelAddInventory";
import { useTranslation } from "react-i18next";
import AddButton from "../../components/buttons/AddButton";

const HotelInventory = () => {
  const { t } = useTranslation();
  const [addButton, setAddButton] = useState(false);
  const { hotel } = useSelector((state) => state.Hotel);
  const { isadmin } = useSelector((state) => state.IsAdmin);
  const [inventory, setInventory] = useState({});
  //register event
  const registerEventAndOpen = () => {
    setAddButton(true);
    registerEvent("add inventory button open", "add  item inventory", {
      addButton,
    });
  };

  // register evnet on close add asset component
  const close = () => {
    if (!isObjEmpty(inventory)) setInventory(null);
    setAddButton(false);
    registerEvent("add inventory button close", "add item inventory", {
      addButton,
    });
  };

  return (
    <>
      <div className="page-container p-0">
        <div className="w-full p-4 max-w-full">
          {!isObjEmpty(inventory) || addButton ? (
            <HotelAddInventory
              close={close}
              hotel={hotel}
              inventory={inventory}
              setInventory={setInventory}
            />
          ) : (
            <AddButton text={t("880")} onClick={registerEventAndOpen} />
          )}
        </div>
        <HotelAllInventory
          hotel={hotel}
          setInventory={setInventory}
          isadmin={isadmin}
        />
      </div>
    </>
  );
};

export default HotelInventory;
