/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux"; 
import { CustomeTable } from "../../components";

const BulkProductionAutomatedCostDataTable = () => {
  const { t } = useTranslation();
  const [datas, setDatas] = useState([]);
  const { menuItems } = useSelector((state) => state.AllMenuItem);
  const [selectedRows, setSelectedRows] = useState([]);
  const [value, setValue] = useState([]);

  useEffect(() => {
    if (menuItems && Array.isArray(menuItems)) {
      let newData = [];
      menuItems?.map((item) => {
        newData.push({
          sn: item?.sn,
          noOfPlates: item?.noOfPlates,
          quantityrequiredFor_1_type: item?.quantityrequiredFor_1_type,
          quantityrequiredFor_2_type: item?.quantityrequiredFor_2_type,
          quantityrequiredFor_3_type: item?.quantityrequiredFor_3_type,
          //   _id: item?._id,
        });
      });
      if (newData?.length) {
        setDatas(newData);
      }
    }
  }, [menuItems]);
  const tableData = [
    {
      sn: "1",
      noOfPlates: "10",
    },
    {
      sn: "2",
      noOfPlates: "20",
    },
    {
      sn: "3",
      noOfPlates: "30",
    },
    {
      sn: "4",
      noOfPlates: "40",
    },
    {
      sn: "5",
      noOfPlates: "50",
    },
  ];
  const ViewInputs = () => {
    return (
      <div className="w-44 mt-1">
        {/* <UnitMeassureInputField
          placeholder={t("Enter a Value")}
          //  value={}
          //  onChange={}
          min={1}
          max={10}
          required={true}
          options={[""]}
          selectValue={value}
          onChangeSelect={(e) => setValue(e.target.value)}
        /> */}
      </div>
    );
  };
  const columnDef = [
    {
      headerName: "S.No",
      field: "sn",
      maxWidth: 110,
      minWidth: 70,
      filter: true,
      floatingFilter: true,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "No Of Plates",
      field: "noOfPlates",
      minWidth: 100,
      maxWidth: 130,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Quantity Required For 1 Type",
      minWidth: 200,
      cellRenderer: ViewInputs,
    },
    {
      headerName: "Quantity Required For 2 Type",
      minWidth: 200,
      cellRenderer: ViewInputs,
    },
    {
      headerName: "Quantity Required For 3 Type",
      minWidth: 200,
      cellRenderer: ViewInputs,
    },
  ];
  return (
    <div>
      {datas.length ? (
        <CustomeTable
          rowData={tableData}
          columnDefs={columnDef}
          setSelectedRows={setSelectedRows}
        />
      ) : null}
    </div>
  );
};

export default BulkProductionAutomatedCostDataTable;
