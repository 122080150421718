import axios from "axios";
import { TOKEN } from "../../constants";
import {
  CANCEL_FOOD_ORDER_FAIL,
  CANCEL_FOOD_ORDER_REQUEST,
  CANCEL_FOOD_ORDER_SUCCESS,
  GET_FOOD_ORDER_FAIL,
  GET_FOOD_ORDER_REQUEST,
  GET_FOOD_ORDER_SUCCESS,
  FOOD_ORDER_FAIL,
  FOOD_ORDER_REQUEST,
  FOOD_ORDER_SUCCESS,
  GET_FOOD_ORDERS_REQUEST,
  GET_FOOD_ORDERS_SUCCESS,
  GET_FOOD_ORDERS_FAIL,
} from "../types/food";

//order food for customer
export const placeFoodOrderAction = (formData) => async (dispatch) => {
  let url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/employee/kitchen/order`;
  try {
    dispatch({ type: FOOD_ORDER_REQUEST });
    const { data } = await axios.post(url, formData, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: FOOD_ORDER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: FOOD_ORDER_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

//get order
export const getFoodOrderAction = (orderId, hotelId) => async (dispatch) => {
  if(!hotelId || !orderId) return;
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/employee/kitchen/order?orderId=${orderId}&hotelId=${hotelId}`;
  try {
    dispatch({ type: GET_FOOD_ORDER_REQUEST });
    const data = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: GET_FOOD_ORDER_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_FOOD_ORDER_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

//get ALL orderS
export const getFoodOrdersAll = (hotelId) => async (dispatch) => {
  let url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/employee/kitchen/orders/all`;
  if (hotelId) {
    url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/employee/kitchen/orders/all?hotelId=${hotelId}`;
  }
  try {
    dispatch({ type: GET_FOOD_ORDERS_REQUEST });
    const data = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: GET_FOOD_ORDERS_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: GET_FOOD_ORDERS_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

//delete order
export const cancelFoodOrderAction = (orderId, hotelId) => async (dispatch) => {
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/employee/kitchen/order/cancel?hotelId=${hotelId}`;
  try {
    dispatch({ type: CANCEL_FOOD_ORDER_REQUEST });
    const data = await axios.put(
      url,
      { orderId },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem(TOKEN),
        },
        withCredentials: true,
      }
    );
    dispatch({ type: CANCEL_FOOD_ORDER_SUCCESS, pyaload: data });
  } catch (error) {
    dispatch({
      type: CANCEL_FOOD_ORDER_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};
