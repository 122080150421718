import React, { useState } from "react";
import { TbBell } from "react-icons/tb";
const NotificationCard = () => {
  return (
    <>
      <div className="w-full h-[72px] border-b border-[#E4E4E7] last:border-b-0 p-4 tracking-[-0.6%] capitalize">
        <div className="flex items-center justify-between">
          <h2 className="text-[16px] leading-5 font-semibold">Jessica Drew</h2>
          <h3 className="text-[12px] leading-4 font-normal text-[#707991]">
            50 Minutes Ago
          </h3>
        </div>
        <div className="flex items-center justify-between mt-1">
          <h2 className="text-[14px] leading-[18px] font-normal text-[#707991]">
            Payment Approval
          </h2>
          <div className="flex items-center gap-4">
            <button
              type="button"
              className="h-[21px] w-[62px] border border-[#E5E9EB] rounded text-[12px] leading-3 font-semibold text-[#F04438] hover:text-[#F6F8F9] hover:bg-[#F04438]"
            >
              Decline
            </button>
            <button
              type="button"
              className="h-[21px] w-[62px] border border-[#E5E9EB] rounded text-[12px] leading-3 font-semibold text-[#F6F8F9] bg-base-primary hover:bg-base-primary_hover"
            >
              Accept
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
const Notification = () => {
  const [isOpen, setIsOpen] = useState(false);
  // when notification not seen show count notification number
  const dataCount = true;
  const number = 4;
  return (
    <>
      <div className="relative">
        {dataCount ? (
          <div
            className={`absolute -top-[15px]
          ${
            number <= 0
              ? "hidden"
              : number <= 99
              ? "right-[5px]  w-5 p-1"
              : number > 99 && "w-fit px-1 -right-0"
          } 
          border h-5 flex items-center justify-center rounded-t-full rounded-br-full bg-base-primary text-white text-[9px] font-bold`}
          >
            {number}
          </div>
        ) : null}
        <button
          type="button"
          className="mr-4 h-7 w-7 flex items-center justify-center text-[#3F3F46] hover:text-base-primary"
          onClick={() => setIsOpen(!isOpen)}
        >
          <TbBell size={24} className="font-extralight" />
        </button>
        {isOpen ? (
          <div className="absolute top-[35px] right-6 z-auto w-[364px] h-[293px] bg-white rounded shadow-[0_4px_4px_0_rgba(0,0,0,0.2)] shadow-inner-2xl overflow-x-auto scroll-barguest duration-300 border px-1 pt-1 pb-2 ">
            <NotificationCard />
            <NotificationCard />
            <NotificationCard />
            <NotificationCard />
            <NotificationCard />
            <NotificationCard />
            <NotificationCard />
            <NotificationCard />
            <NotificationCard />
            <NotificationCard />
            <NotificationCard />
            <NotificationCard />
            <NotificationCard />
            <NotificationCard />
            <NotificationCard />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Notification;
