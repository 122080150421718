import { CLEAR_ERRORS } from "../../actions/types";
import {
  GET_All_EMPLOYEE_REVIEWS_REQUEST,
  GET_All_EMPLOYEE_REVIEWS_SUCCESS,
  GET_All_EMPLOYEE_REVIEWS_FAIL,
  EMPLOYEE_REPLY_A_REVIEW_REQUEST,
  EMPLOYEE_REPLY_A_REVIEW_SUCCESS,
  EMPLOYEE_REPLY_A_REVIEW_FAIL,
  APPROVE_A_EMPLOYEE_REVIEW_REQUEST,
  APPROVE_A_EMPLOYEE_REVIEW_SUCCESS,
  APPROVE_A_EMPLOYEE_REVIEW_FAIL,
  DELETE_A_EMPLOYEE_REVIEW_REQUEST,
  DELETE_A_EMPLOYEE_REVIEW_SUCCESS,
  DELETE_A_EMPLOYEE_REVIEW_FAIL,
} from "../../actions/types/reviews";

//get all employee reviews
export const getAllReviewReducer = (state = { reviews: [] }, action) => {
  switch (action.type) {
    case GET_All_EMPLOYEE_REVIEWS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_All_EMPLOYEE_REVIEWS_SUCCESS:
    case EMPLOYEE_REPLY_A_REVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        reviews: action.payload,
      };
    case GET_All_EMPLOYEE_REVIEWS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
//get all employee reviews
export const approveReviewReducer = (state = { reviews: [] }, action) => {
  switch (action.type) {
    case APPROVE_A_EMPLOYEE_REVIEW_REQUEST:
      return {
        ...state,
        loading: true,
        isapproved:false
      };
    case APPROVE_A_EMPLOYEE_REVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        reviews: action.payload,
        isapproved:true
      };
    case APPROVE_A_EMPLOYEE_REVIEW_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isapproved:false
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

//approve employee reviews
export const replyReviewReducer = (state = { reviews: [] }, action) => {
  switch (action.type) {
    case EMPLOYEE_REPLY_A_REVIEW_REQUEST:
      return {
        ...state,
        loading: true,
        isreplyed:false
      };
    case EMPLOYEE_REPLY_A_REVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        reviews: action.payload,
        isreplyed:true
      };
    case EMPLOYEE_REPLY_A_REVIEW_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isreplyed:false
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

//delete employee review
export const deleteReviewReducer = (state = { reviews: [] }, action) => {
  switch (action.type) {
    case DELETE_A_EMPLOYEE_REVIEW_REQUEST:
      return {
        ...state,
        loading: true,
        isdeleted: false,
      };
    case DELETE_A_EMPLOYEE_REVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        reviews: action.payload,
        isdeleted: true,
      };
    case DELETE_A_EMPLOYEE_REVIEW_FAIL:
      return {
        ...state,
        loading: false,
        isdeleted: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
