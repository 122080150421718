import React from "react";
import ToolTip from "../ToolTip";

const SelectFromObject = ({
  label,
  value,
  setValue,
  options,
  toolTipMessage,
  utility,
  height,
  required = false
}) => {
  // typeof options === object & key for backend , value for frontend
  return (
      <div>
        <div className={`flex items-center justify-between capitalize ${utility}`}>
          <label>{label}</label>
          <ToolTip Message={toolTipMessage} />
        </div>
        <div className={` relative flex items-center`}>
          <select
            value={value}
            onChange={(e) => setValue(e.target.value)}
            className={`${height}`}
            required={required}
          >
            {Object.keys(options)?.map((item) => (
              <option value={item} key={item} >
                {options[item]}
              </option>
            ))}
          </select>
        </div>
      </div>
  );
};

export default SelectFromObject;
