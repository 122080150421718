import React from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants";

const Navigation = ({ text, params, index }) => {
  const navigate = useNavigate();
  return (
    <button
      className={`box-border w-fit px-6 h-[53px] rounded-[10px] border flex justify-center items-center font-medium text-[18px] leading-[23px] ${
        +params === +index
          ? "bg-green-100 border-green-500 text-green-500"
          : "bg-[#FFFFFF] hover:text-green-500 hover:border-green-500 hover:bg-green-100"
      }`}
      onClick={() => navigate(`${ROUTES.HOUSE_KEEPING}/${index}`)}
    >
      {text}
    </button>
  );
};

export default Navigation;
