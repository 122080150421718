import React, { useState } from "react";
import EditButton from "../../components/buttons/EditButton";
import DeleteButton from "../../components/buttons/DeleteButton";
import SubmitButton from "../../components/buttons/SubmitButton";
import { CustomeTable } from "../../components";
import CheckRadioInputs from "../../components/layouts/CheckRadioInputs";
import { registerEvent } from "../../logs";
import AddLaundryItem from "./AddLaundryItem";
import CancelButton from "../../components/buttons/CancelButton";

const LaundryConfig = ({ closeLaundryConfig }) => {
  const [isLaundryServices, setIsLaundryServices] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isOpenAddItem, setIsOpenItem] = useState(false);
  const [isSelectValue, setIsSelectValue] = useState("");

  const tableData = [
    {
      id: "1",
      image: "",
      name: "shirt",
     
    },
    {
      id: "2",
      image: "",
      name: "shirt",
     
    },
    {
      image: "",
      name: "shirt",
      
    },
    {
      image: "",
      name: "shirt",
      
    },
    {
      image: "",
      name: "shirt",
      
    },
    {
      image: "",
      name: "shirt",
      
    },
  ];

  // show image
  const showItemImage = () => {
    return (
      <div className="flex items-center justify-start">
        <img
          src={`https://w7.pngwing.com/pngs/972/557/png-transparent-blue-dress-shirt-t-shirt-dress-shirt-dress-shirt-tshirt-blue-image-file-formats-thumbnail.png`}
          alt=""
          className="h-9 w-10 rounded-lg bg-[#00000099] my-[2px]"
        />
      </div>
    );
  };
  function selectItem() {
    return (
      <input
        type="checkbox"
        value={isSelectValue}
        onChange={(e) => setIsSelectValue(e.target.value)}
        className={`size-5 border border-base-primary accent-current shadow-none mt-[10px]`}
      />
    );
  }
  function setItemPrice() {
    return (
      <input
        type="text"
        className="h-8 w-full accent-current rounded-[6px] placeholder:flex placeholder:items-center placeholder:justify-center px-2 "
        placeholder="price"
      />
    );
  }
  const handleActions = () => {
    return (
      <div className="flex items-center justify-start gap-3 h-8 mt-1">
        <EditButton sizing="h-8 w-[65px]" />
        <DeleteButton sizing="h-8 w-[84px]" />
      </div>
    );
  };

  const columnDefs = [
    {
      headerName: "Image",
      field: "image",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
      cellRenderer: showItemImage,
    },
    {
      headerName: "Name",
      field: "name",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Laundry",
      field: "laundry",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
      cellRenderer: selectItem,
    },
    {
      headerName: "Set Laundry Price",
      field: "setLaundryPrice",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
      cellRenderer: setItemPrice,
    },
    {
      headerName: "Iron",
      field: "iron",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
      cellRenderer: selectItem,
    },
    {
      headerName: "Set Iron Price",
      field: "setIronPrice",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
      cellRenderer: setItemPrice,
    },
    {
      headerName: "Dry Cleaning",
      field: "dryCleaning",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
      cellRenderer: selectItem,
    },
    {
      headerName: "Set Dry Cleaning Price",
      field: "setDryCleaningPrice",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
      cellRenderer: setItemPrice,
    },
    {
      headerName: "Actions",
      field: "",
      maxWidth: 300,
      minWidth: 180,
      cellRenderer: handleActions,
    },
  ];
  const addlaundryItemModal = () => {
    setIsOpenItem(true);
    registerEvent("click add Laundry item button", "all laundry page");
  };

  //close add Laundry modal
  const close = () => {
    setIsOpenItem(false);
    registerEvent("close add Laundry item component", "add Laundry page");
  };

  return (
    <div className="border border-[#E4E4E7] rounded-[10px] bg-white p-6 mt-6">
      <div className="flex items-center justify-between">
        <h2 className="text-[30px] leading-[37.8px] font-bold text-[#07090D] tracking-[-1.5%]">
          laundry Configuration
        </h2>
        <div className="flex items-center gap-4">
          <CancelButton onClick={closeLaundryConfig} />
          <SubmitButton
            type="button"
            text={"Add Item"}
            onClick={addlaundryItemModal}
            sizing={"h-10 w-[119px]"}
          />
        </div>
      </div>
      <div className="flex items-center justify-between my-4">
        <h2 className="text-[18px] leading-5 font-medium text-[#344054] ">
          Create Laundry Price List
        </h2>
        <CheckRadioInputs
          label={"Laundry Services"}
          value={isLaundryServices}
          onChange={() => setIsLaundryServices(!isLaundryServices)}
        />
      </div>
      {isOpenAddItem ? <AddLaundryItem closeAddLaundryItem={close} /> : null}
      <div>
        <CustomeTable
          columnDefs={columnDefs}
          rowData={tableData}
          setSelectedRows={setSelectedRows}
        />
      </div>
    </div>
  );
};

export default LaundryConfig;
