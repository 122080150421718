import React from "react";
import { SlQuestion } from "react-icons/sl";
import ReactTooltip from "react-tooltip";
//check
const ToolTip = ({ Message }) => {
  return (
    <>
      {Message && Message.length ? (
        <>
          <div className="w-fit h-fit relative rounded-lg text-[#344054] text-sm z-50 border-[#E5E9EB]">
            <SlQuestion data-tip={Message} />
          </div>
          <ReactTooltip
            type="light"
            className="py-2 px-6 max-w-full sm:max-w-sm  rounded-2xl text-[#6E7C87] shadow-[0_0_31px_0_rgba(238,238,238,1)]"
          />
        </>
      ) : null}
    </>
  );
};

export default ToolTip;
