import React, { useState } from "react";

const OPTIONS = {
  INSPECTED:"Inspected",
  PENDING: "Pending"
}

const Inspection = () => {
  const [value, setValue] = useState("INSPECTED");

  return (

    <>
      <select onChange={(e)=>setValue(e?.target?.value)} value={value} className="inspection-selector">
        {
          Object.keys(OPTIONS)?.map((it, index) => (
            <option className="capitalize" key={index} value={OPTIONS[it]}>{OPTIONS[it]}</option>
          ))
        }
      </select></>
  );
};

export default Inspection;
