import { useEffect } from "react";
import { DISPLAY_SIZE } from "../constants";
import { useDispatch } from "react-redux";
import { CHANGE_HAMBURGER } from "../actions/types";

const PageDetail = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    // close hamburger if window size is less than sm and hamburger is open
    if (window.innerWidth < DISPLAY_SIZE.lg && props.open === true) {
      dispatch({ type: CHANGE_HAMBURGER, payload: false });
    }
    // open hamburger if window size is greater than lg and hamburger is close
    if (window.innerWidth > DISPLAY_SIZE.lg && props.open === false) {
      dispatch({ type: CHANGE_HAMBURGER, payload: true });
    }
  }, []);

  return null;
};

export default PageDetail;
