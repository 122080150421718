import { CLEAR_ERRORS } from "../../actions/types";
import {
  GET_BOOKINGS_FAIL,
  GET_BOOKINGS_REQUEST,
  GET_BOOKINGS_SUCCESS,
  BOOKING_CNAME_FAIL,
  BOOKING_CNAME_REQUEST,
  BOOKING_CNAME_SUCCESS,
  BOOKING_PHONE_FAIL,
  BOOKING_PHONE_REQUEST,
  BOOKING_PHONE_SUCCESS,
  BOOKING_STATUS_FAIL,
  BOOKING_STATUS_REQUEST,
  BOOKING_STATUS_SUCCESS,
} from "../../actions/types/booking";

//get all booking reducer
export const getBookingReducer = (state = { bookings: [] }, action) => {
  switch (action.type) {
    case GET_BOOKINGS_REQUEST:
    case BOOKING_STATUS_REQUEST:
    case BOOKING_PHONE_REQUEST:
    case BOOKING_CNAME_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_BOOKINGS_SUCCESS:
    case BOOKING_STATUS_SUCCESS:
    case BOOKING_PHONE_SUCCESS:
    case BOOKING_CNAME_SUCCESS:
      return {
        ...state,
        loading: false,
        bookings: action.payload,
      };
    case GET_BOOKINGS_FAIL:
    case BOOKING_STATUS_FAIL:
    case BOOKING_PHONE_FAIL:
    case BOOKING_CNAME_FAIL:
      return {
        ...state,
        loading: false,
        bookings: null,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
