import React from "react";
import { useState } from "react";
import BookingCaptureCheckBox from "./BookingCaptureCheckBox";
import Inputfield from "../../components/layouts";
import { GENDERS } from "../../constants"; 
import SelectFromArray from "../../components/layouts/SelectFromArray";
import { useEffect } from "react";
import CollapseExpend from "./CollapseExpend";
import { useTranslation } from "react-i18next";
import SelectFromObject from "../../components/layouts/SelectFromObject";

const ChildGuestDetails = ({
  guest,
  guestsDetails,
  setGuestsDetails,
  isChanged,
  setIsChanged,
}) => {
  const { t } = useTranslation();
  const [expend, setExpend] = useState(true);
  const [expendInfo, setExpendInfo] = useState(true);
  const [captureGuestInformation, setCaptureGuestInformation] = useState(true);
  const [name, setName] = useState("");
  const [gender, setGender] = useState(GENDERS.MALE);
  const [age, setAge] = useState(5);

  useEffect(() => {
    const personalInformation = {
      name,
      gender,
      age,
    };

    const guestDetail = {
      personalInformation,
      identityInformation: null,
      addressInformation: null,
    };
    if (!guestsDetails[guest]) {
      setIsChanged(!isChanged);
      guestsDetails[guest] = guestDetail;
      setGuestsDetails(guestsDetails);
    } else if (captureGuestInformation) {
      guestsDetails[guest].personalInformation = personalInformation;
      guestsDetails[guest].identityInformation = null;
      guestsDetails[guest].addressInformation = null;
      setIsChanged(!isChanged);
      setGuestsDetails(guestsDetails);
    } else {
      guestsDetails[guest].personalInformation = guestDetail;
      setIsChanged(!isChanged);
      setGuestsDetails(guestsDetails);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, gender, age]);

  return (
    <>
      <div className="border-t pt-4 mt-4">
        <div className="font-bold text-[20px] leading-[100%]">{t("141")}</div>
        <div className="sm:flex sm:justify-between items-center mt-2">
          <CollapseExpend
            value={expend}
            setValue={setExpend}
            text={`${t("525")} ${guest + 1} ${t("544")}`}
          />
          <BookingCaptureCheckBox
            label={t("545")}
            text={captureGuestInformation ? t("113") : t("514")}
            value={captureGuestInformation}
            setValue={setCaptureGuestInformation}
          />
        </div>
      </div>
      <div className={captureGuestInformation ? "relative" : "hidden"}>
        <div className={expend ? "relative" : "hidden"}>
          <CollapseExpend
            value={expendInfo}
            setValue={setExpendInfo}
            text={t("524")}
          />
          <div
            className={`${expendInfo ? "relative" : "hidden"
              } w-full grid sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-4 grid-w-full pt-4`}
          >
            <Inputfield
              label={t("546")}
              placeholder={t("387")}
              value={name}
              onChange={(e) => setName(e.target.value)}
              required={captureGuestInformation}
            />
            <SelectFromObject
              label={t("168")}
              options={GENDERS}
              value={gender}
              onChange={(e) => setGender(e?.target?.value)}
            />
            <SelectFromArray
              label={t("547")}
              placeholder={t("548")}
              value={age}
              onChange={(e) => setAge(e.target.value)}
              options={['1', '2']}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ChildGuestDetails;
