/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Inputfield from "../../../components/layouts";

const TaxSlabMaximum = ({ taxRule, setTaxRule, index, setChanged }) => {
  const { t } = useTranslation();
  const [percentage, setPercentage] = useState("");

  //onchange when index value max changes
  useEffect(() => {
    if (taxRule && taxRule[index] && taxRule[index]?.maximum) {
      taxRule[index + 1].minimum = +taxRule[index]?.maximum + 1;
      taxRule[index + 1].maximum = 100000;
      setTaxRule(taxRule);
      setChanged(true);
    }

    if (taxRule && taxRule[index + 1]?.taxPercentage) {
      setPercentage(taxRule[index + 1]?.taxPercentage);
    }
  }, [taxRule[index]?.maximum]);

  //set tax percentage
  const taxPercentageset = (value) => {
    setPercentage(value);
    if (taxRule.length && +value <= 100) {
      taxRule[index + 1].taxPercentage = +value;
      setTaxRule(taxRule);
      setChanged(true);
    }
  };

  return (
    <>
      <div className="w-full grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 grid-w-full">
        <Inputfield
          type="number"
          label={t("441")}
          value={taxRule[index]?.maximum && +taxRule[index]?.maximum + 1}
          onChange={(e) => {}}
          readOnly={true}
        />
        <Inputfield
          type="number"
          label={t("437")}
          value={100000}
          readOnly={true}
        />
        <Inputfield
          type="number"
          label={t("437")}
          onChange={(e) => taxPercentageset(e.target.value)}
          value={percentage}
          min={0}
          max={100}
          required={true}
        />
      </div>
    </>
  );
};

export default TaxSlabMaximum;
