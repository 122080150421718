import React from "react";
import ReserveRoom from "./ReserveRoom";
import bedIcon from './resbedIcon.png'
import resIcon from './resIcons.png'

const RoomRow = ({ bookings, days, room }) => { 

  return (
    <div className="flex">
      <div
        className="w-[177px] min-w-[177px] h-[60px] bg-[#F6F6F6] border-r border-b flex px-5 items-center justify-between"
      >
        <div className="text-[18px] leading-[22.68px] font-normal">{room?.roomNumber}</div>
        <div className="flex items-center gap-2">
          <div className="text-[15px] leading-[18.9px] font-normal text-[#A1A1AA]">2</div>
          <img src={bedIcon} alt="" />
        </div>
        <img src={resIcon} alt="" className="pb-1" />
      </div>
      <div
        className={`bg-white w-12 min-w-12 h-[60px] border-r border-b`}
      ></div>
      {days?.map((date, index) => (
        <ReserveRoom
          key={index}
          bookings={room?.bookings}
          date={date}
        />
      ))}
    </div>
  );
};

export default RoomRow;
