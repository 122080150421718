import React, { useState } from "react";
import { useRef } from "react";
import { useOutsideClick } from "../../utils";
import { RiArrowDropDownLine } from "react-icons/ri";

const ReportDropDown = ({ options, value, setValue }) => {
  const [open, setOpen] = useState(false);
  const myRef = useRef(null);
  useOutsideClick(myRef, setOpen);

  return (
    <>
      <div
        className="relative w-full lg:w-[512px] z-30 "
        ref={open ? myRef : null}
      >
        <div
          className="w-full h-12 max-w-full relative flex items-center px-2 cursor-pointer select-none border rounded-md bg-white justify-between"
          onClick={() => setOpen(!open)}
        >
          <div className="whitespace-nowrap overflow-hidden">{value}</div>
          <div className="text-2xl">
            <RiArrowDropDownLine />
          </div>
        </div>
        <div
          className={`${
            open ? "h-fit" : "h-0 hidden"
          } absolute top-full right-0 bg-white duration-300 shadow-md w-full`}
        >
          {options.map((option, index) => (
            <div
              key={index}
              className="w-full text-sm hover:bg-white cursor-default p-2 first:mt-0"
              onClick={() => {
                setValue(option);
                setOpen(false);
              }}
            >
              {index + 1}. {option}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ReportDropDown;
