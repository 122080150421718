import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteKitchenAction } from "../../actions/kitchen/kitchen";
import DeleteOrNot from "../../components/layouts/DeleteOrNot";
import {
  ROUTES,
} from "../../constants";
import BackGroundLoadSpinner from "../../components/loader/BackGroundLoadSpinner";
import { isObjEmpty } from "../../utils";
import { useTranslation } from "react-i18next";
import { CustomeTable } from "../../components";
import UploadButton from "../../components/buttons/UploadButton";
import DownloadButton from "../../components/buttons/DownloadButton";
import PrintButton from "../../components/buttons/PrintButton";

const AllKitchen = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [datas, setDatas] = useState([]);
  const [open, setOpen] = useState(false);
  const [kitchenId, setKitchenId] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    if (props?.kitchen && !isObjEmpty(props?.kitchen?.kitchen)) {
      setDatas([
        {
          Kitchen_id: "1214",
          name: props?.kitchen?.kitchen?.name,
          location: props?.kitchen?.kitchen?.address,
          manager: props?.kitchen?.kitchen?.manager,
          managerContact: props?.kitchen?.kitchen?.managerContact,
          // images: props?.kitchen?.kitchen?.images,
          _id: props?.kitchen?.kitchen?._id,
        },
      ]);
    }
  }, [props?.kitchen]);

  //delete the kitchen
  const deleteKitchen = () => {
    if (kitchenId) {
      setKitchenId(kitchenId);
      dispatch(deleteKitchenAction(kitchenId));
      setOpen(false);
    }
  };

  //view Kitchen Actions buttons
  const ViewKitchenActions = () => {
    const setKitchenAndRedirect = (link) => {
      if (link) {
        navigate(link);
      }
    };
    return (
      <div className="flex items-center gap-2 mt-[5px] last:mr-2">
        <button
          className="h-[32px] px-4 rounded-md text-[14px] leading-[14px] tracking-[-0.6%] text-[#F6F8F9] bg-base-primary hover:bg-base-primary_hover"
          onClick={() => setKitchenAndRedirect(ROUTES.KITCHEN_MENU)}
        >
          {t("F&B")}
        </button>
        {/* new button */}
        <button
          className="h-[32px] px-4 rounded-md text-[14px] leading-[14px] tracking-[-0.6%] text-[#F6F8F9] bg-base-primary hover:bg-base-primary_hover"
        // onClick={}
        >
          {t("Leftovers")}
        </button>
        <button
          className="h-[32px] px-4 rounded-md text-[14px] leading-[14px] tracking-[-0.6%] text-[#F6F8F9] bg-base-primary hover:bg-base-primary_hover"
          onClick={() => setKitchenAndRedirect(ROUTES.KITCHEN_BUFFETS)}
        >
          {t("1001")}
        </button>
        <button
          className="h-[32px] px-4 rounded-md text-[14px] leading-[14px] tracking-[-0.6%] text-[#F6F8F9] bg-base-primary hover:bg-base-primary_hover"
          onClick={() => setKitchenAndRedirect(ROUTES.ALACARTE)}
        >
          {t("1064")}
        </button>
        <button
          className="h-[32px] px-4 rounded-md text-[14px] leading-[14px] tracking-[-0.6%] text-[#F6F8F9] bg-base-primary hover:bg-base-primary_hover"
          onClick={() => setKitchenAndRedirect(ROUTES.KITCHEN_INVENTORY)}
        >
          {t("Kitchen Inventory")}
        </button>
        <button
          className="h-[32px] px-4 rounded-md text-[14px] leading-[14px] tracking-[-0.6%] text-[#F6F8F9] bg-base-primary hover:bg-base-primary_hover"
        // onClick={() => setKitchenAndRedirect(PATH_KITCHEN_INVENTORY)}
        >
          {t("Setting")}
        </button>
      </div>
    );
  };

  const columnDefs = [
    {
      headerName: "Kitchen ID",
      field: "Kitchen_id",
      maxWidth: 150,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Name",
      field: "name",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Location",
      field: "location",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Manager Name",
      field: "manager",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Contact",
      field: "managerContact",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Action",
      filter: true,
      minWidth: 495,
      cellRenderer: ViewKitchenActions,
    },
  ];

  return (
    <>
      <div className="relative w-full mx-auto max-w-7xl">
        <div className="flex items-center justify-between mb-4">
          <div className="text-[22px] leading-[27.72px] text-black font-bold">
            Kitchen Table
          </div>
          <div className="flex items-center gap-4">
            <PrintButton />
            <UploadButton />
            <DownloadButton />
          </div>
        </div>
        {datas.length ? (
          <CustomeTable
            columnDefs={columnDefs}
            rowData={datas}
            setSelectedRows={setSelectedRows}
          />
        ) : null}
      </div>

      {(props?.add && props?.add?.loading) ||
        (props?.delete && props?.delete?.loading && <BackGroundLoadSpinner />)}

      <DeleteOrNot
        formSubmit={deleteKitchen}
        open={open}
        close={() => setOpen(false)}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  kitchen: state.Kitchen,
  add: state.AddKitchen,
  delete: state.DeleteKitchen,
});

const mapDispatchToProps = (dispatch) => ({
  delKitchen: (kitchenId) => dispatch(deleteKitchenAction(kitchenId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AllKitchen);
