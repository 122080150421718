import { CLEAR_ERRORS } from "../../actions/types";
import {
  ADD_ROOM_SUCCESS,
  GET_HOTEL_ROOMS_FAIL,
  GET_HOTEL_ROOMS_REQ,
  GET_HOTEL_ROOMS_SUC,
  GET_ROOM_BY_ID_FAIL,
  GET_ROOM_BY_ID_REQ,
  GET_ROOM_BY_ID_SUC,
  DELETE_ROOM_SUCCESS,
} from "../../actions/types/room";

//get hotel rooms
export const getAllRoomReducer = (state = { rooms: [] }, action) => {
  switch (action.type) {
    case GET_HOTEL_ROOMS_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_HOTEL_ROOMS_SUC:
    case ADD_ROOM_SUCCESS:
    case DELETE_ROOM_SUCCESS:
      return {
        ...state,
        loading: false,
        rooms: action.payload,
      };
    case GET_HOTEL_ROOMS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        rooms: [],
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
//get hotel room by id
export const getRoomReducer = (state = { room: {} }, action) => {
  switch (action.type) {
    case GET_ROOM_BY_ID_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_ROOM_BY_ID_SUC:
      return {
        ...state,
        loading: false,
        room: action.payload,
      };
    case GET_ROOM_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        room: null,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
