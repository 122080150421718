import React from "react";

const BackGroundLoadSpinner = () => {
  return (
    <div
      className={`fixed top-0 left-0 w-screen h-screen z-[1050] flex items-center justify-center bg-black bg-opacity-10`}
    >
      <div className="flex justify-center items-center p-8 px-9 rounded-lg bg-[#FFFFFF] shadow-sm">
        <div
          className={
            "h-7 w-7 border-[3px] rounded-full border-base-primary border-t-[#fafafa23] animate-spin relative"
          }
        ></div>
      </div>
    </div>
  );
};

export default BackGroundLoadSpinner;
