import { CLEAR_ERRORS } from "../../actions/types";
import {
  ADD_HOTEL_ASSET_SUC,
  GET_HOTEL_ASSETS_FAIL,
  GET_HOTEL_ASSETS_REQ,
  GET_HOTEL_ASSETS_SUC,
  GET_HOTEL_ASSET_FAIL,
  GET_HOTEL_ASSET_REQ,
  GET_HOTEL_ASSET_SUC,
  DELETE_HOTEL_ASSET_SUC,
  UPDATE_HOTEL_ASSET_SUC
} from "../../actions/types/asset";

//get hotel assets all
export const getHotelAssetsAllReducer = (state = { assets: [] }, action) => {
  switch (action.type) {
    case GET_HOTEL_ASSETS_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_HOTEL_ASSETS_SUC:
      return {
        ...state,
        loading: false,
        assets: action.payload,
      };
    case GET_HOTEL_ASSETS_SUC:
    case ADD_HOTEL_ASSET_SUC:
    case DELETE_HOTEL_ASSET_SUC:      
    case UPDATE_HOTEL_ASSET_SUC:      
      return {
        ...state,
        assets: action.payload,
      };
    case GET_HOTEL_ASSETS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        assets: [],
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// get hotel asset by asset id
export const getHotelAssetReducer = (state = { asset: [] }, action) => {
  switch (action.type) {
    case GET_HOTEL_ASSET_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_HOTEL_ASSET_SUC:
      return {
        ...state,
        loading: false,
        asset: action.payload,
      };
    case GET_HOTEL_ASSET_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
