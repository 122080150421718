export const GET_HOTEL_WIFI_REQ = "GET_HOTEL_WIFI_REQ";
export const GET_HOTEL_WIFI_SUC = "GET_HOTEL_WIFI_SUC";
export const GET_HOTEL_WIFI_FAIL = "GET_HOTEL_WIFI_FAIL";

export const UPDATE_HOTEL_WIFI_REQ = "UPDATE_HOTEL_WIFI_REQ";
export const UPDATE_HOTEL_WIFI_SUC = "UPDATE_HOTEL_WIFI_SUC";
export const UPDATE_HOTEL_WIFI_FAIL = "UPDATE_HOTEL_WIFI_FAIL";
export const UPDATE_HOTEL_WIFI_RESET = "UPDATE_HOTEL_WIFI_RESET";

export const ADD_HOTEL_WIFI_REQ = "ADD_HOTEL_WIFI_REQ";
export const ADD_HOTEL_WIFI_SUC = "ADD_HOTEL_WIFI_SUC";
export const ADD_HOTEL_WIFI_FAIL = "ADD_HOTEL_WIFI_FAIL";
export const ADD_HOTEL_WIFI_RESET = "ADD_HOTEL_WIFI_RESET";

export const DELETE_HOTEL_WIFI_REQ = "DELETE_HOTEL_WIFI_REQ";
export const DELETE_HOTEL_WIFI_SUC = "DELETE_HOTEL_WIFI_SUC";
export const DELETE_HOTEL_WIFI_FAIL = "DELETE_HOTEL_WIFI_SUC";
export const DELETE_HOTEL_WIFI_RESET = "DELETE_HOTEL_WIFI_RESET";
