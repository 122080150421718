import {
  CHECKOUT_GUEST_REQUEST,
  GENERATE_CODE_FAIL,
  GENERATE_CODE_REQUEST,
  GENERATE_CODE_RESET,
  GENERATE_CODE_SUCCESS,
  CHECKOUT_GUEST_FAIL,
  CHECKOUT_GUEST_RESET,
} from "../../actions/types/checkinCode";
import { CLEAR_ERRORS } from "../../actions/types";

//checkin code generate reducer
export const checkInCodeGenerateReducer = (state = { code: {} }, action) => {
  switch (action.type) {
    case GENERATE_CODE_REQUEST:
      return {
        ...state,
        loading: true,
        isgenerated: false,
      };
    case GENERATE_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        isgenerated: true,
        code: action.payload,
      };
    case GENERATE_CODE_FAIL:
      return {
        ...state,
        loading: false,
        isgenerated: false,
        error: action.payload,
      };
    case GENERATE_CODE_RESET:
      return {
        ...state,
        loading: false,
        isgenerated: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// checkout a guest
export const checkOutGuestReducer = (state = { code: {} }, action) => {
  switch (action.type) {
    case CHECKOUT_GUEST_REQUEST:
      return {
        ...state,
        loading: true,
        isout: false,
      };
    case GENERATE_CODE_SUCCESS:
      return {
        ...state,
        loading: false,
        isout: true,
        code: action.payload,
      };
    case CHECKOUT_GUEST_FAIL:
      return {
        ...state,
        loading: false,
        isout: false,
        error: action.payload,
      };
    case CHECKOUT_GUEST_RESET:
      return {
        ...state,
        loading: false,
        isout: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
