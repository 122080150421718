import React from "react";

const ReportsCard = ({ utility, icon, iconBgColor, heading, value }) => {
  return (
    <>
      <div className={`${utility} h-[84px] rounded-[5px] bg-white border border-[#E5E9EB] p-4`}>
        <div className="flex items-center gap-4">
          <div
            className={`${iconBgColor} w-[59.27px] h-[55px] rounded-[10px] flex justify-center items-center`}
          >
            <img src={icon} alt="" />
          </div>
          <div>
            <div className="text-[16px] leading-[20.16px] font-normal text-[#6E7C87]">
              {heading}
            </div>
            <div className="text-[22px] leading-[22.72px] text-[#000000] font-bold pt-2">
              {value}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportsCard;
