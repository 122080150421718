import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { getHotelWifi } from "../../../actions/pms/wifi";
import PageLoader from "../../../components/loader/PageLoader";
import { clearError } from "../../../actions/clearError";
import WifiAddUpdate from "./WifiAddUpdate";
import WifiAll from "./WifiAll";
import { useTranslation } from "react-i18next";
import AddButton from "../../../components/buttons/AddButton";
import DeleteButton from "../../../components/buttons/DeleteButton";
import EditButton from "../../../components/buttons/EditButton";

const WifiConfig = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [updateData, setUpdateData] = useState({});
  const { wifi } = useSelector((state) => state.AllWifi);

  //get hotel wifi
  useEffect(() => {
    if (props?.hotel && props?.hotel?._id) {
      props.getData(props?.hotel?._id);
    }
  }, []);

  //open component for add / update wifi
  const openComponent = () => {
    setOpen(true);
    setUpdateData(null);
  };

  //close component
  const closeComponent = () => {
    setOpen(false);
    setUpdateData(null);
  };

  return (
    <>
      {props?.wifi && props?.wifi?.loading && !wifi?.length ? (
        <PageLoader />
      ) : (
        <>
          <div className="w-full max-w-full">
            <div className="flex justify-end items-center gap-2">
              <EditButton />
              <DeleteButton />
              <AddButton text={t("454")} onClick={openComponent} />
            </div>
            <WifiAddUpdate
              hotel={props.hotel}
              open={open}
              close={closeComponent}
              clear={props.clear}
              updateData={updateData}
            />
          </div>
          <WifiAll
            wifi={props?.wifi?.wifi}
            hotel={props?.hotel}
            clear={props?.clear}
            setUpdateData={setUpdateData}
            setOpen={setOpen}
          />
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  wifi: state.AllWifi,
});

const mapDispatchToProps = (dispatch) => ({
  getData: (hotelId) => dispatch(getHotelWifi(hotelId)),
  clear: () => dispatch(clearError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(WifiConfig);
