/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";

export const useOutsideClick = (ref, setValue) => {
  useEffect(() => {
    function handleClickOutside(event) {
      //if modal is open than close modal (setValue(false))
      if (ref.current && !ref.current.contains(event.target)) {
        setValue(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
};
