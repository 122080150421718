import axios from "axios";
import { ISADMIN, TOKEN } from "../../constants";
import {
  EMPLOYEE_LOGIN_FAIL,
  EMPLOYEE_LOGIN_REQUEST,
  EMPLOYEE_LOGIN_SUCCESS,
  GET_EMPLOYEE_FAIL,
  GET_EMPLOYEE_REQUEST,
  GET_EMPLOYEE_SUCCESS,
} from "../types/employee";

// hotel employee login
export const employeeLoginAction = (formData) => async (dispatch) => {
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/employee/auth/login`;
  try {
    dispatch({ type: EMPLOYEE_LOGIN_REQUEST });
    const { data } = await axios.post(url, formData, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    dispatch({
      type: EMPLOYEE_LOGIN_SUCCESS,
      payload: data.token,
    });
  } catch (error) {
    dispatch({
      type: EMPLOYEE_LOGIN_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

// get employee
export const authenticateEmployee = () => async (dispatch) => {
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/employee/auth`;
  const token = localStorage.getItem(TOKEN);
  const isadmin = await JSON.parse(localStorage.getItem(ISADMIN))

  if (token && !isadmin) {
    try {
      dispatch({ type: GET_EMPLOYEE_REQUEST });
      const { data } = await axios.get(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-auth-token": token,
        },
        withCredentials: true,
      });
      dispatch({
        type: GET_EMPLOYEE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_EMPLOYEE_FAIL,
        payload: error.response.data.msg,
      });
    }
  }
};
