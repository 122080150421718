/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import AllLeavesCards from "../../components/layouts/AllLeavesCards";
import CustomeTable from "../../components/CustomeTable";
import DelIcon from "../../images/icons/DelIcon.svg";
import EidIcon from "../../images/icons/EidIcon.svg";

const LeaveManagement = () => {
  const [selectedRows, setSelectedRows] = useState([]);

  const tableData = [
    {
      requestdate: "11/8/2023",
      leaveType: "Sick Leave",
      leaveDuration: "27-11-2023 to 05-12-2023 ",
      noOfDays: "7",
      status: "Aproved",
    },
    {
      requestdate: "11/8/2023",
      leaveType: "Sick Leave",
      leaveDuration: "27-11-2023 to 05-12-2023 ",
      noOfDays: "7",
      status: "Cancel",
    },
    {
      requestdate: "11/8/2023",
      leaveType: "Sick Leave",
      leaveDuration: "27-11-2023 to 05-12-2023 ",
      noOfDays: "7",
      status: "Pending",
    },
    {
      requestdate: "11/8/2023",
      leaveType: "Sick Leave",
      leaveDuration: "27-11-2023 to 05-12-2023 ",
      noOfDays: "7",
      status: "Aproved",
    },
    {
      requestdate: "11/8/2023",
      leaveType: "Sick Leave",
      leaveDuration: "27-11-2023 to 05-12-2023 ",
      noOfDays: "7",
      status: "Pending",
    },
  ];

  const checkStatusAction = ({ value }) => {
    return (
      <div className="">
        {value === "Aproved" && (
          <div className="text-base-primary">{value}</div>
        )}
        {value === "Pending" && <div className="text-[#444CE7]">{value}</div>}
        {value === "Cancel" && <div className="text-[#F04438]">{value}</div>}
      </div>
    );
  };
  const viewAllLeaveManagementAction = () => {
    return (
      <div className="flex items-center gap-4 mt-[5px]">
        <button
          type="button"
          className="w-[30px] h-[30px] border border-[#E5E9EB] bg-white rounded-full flex items-center justify-center hover:bg-[#E5E9EB]"
          // onClick={}
        >
          <img src={DelIcon} alt="" />
        </button>
        <button
          className="w-[30px] h-[30px] border border-[#E5E9EB] bg-white rounded-full flex items-center justify-center hover:bg-[#E5E9EB]"
          // onClick={}
        >
          <img src={EidIcon} alt="" />
        </button>
      </div>
    );
  };

  const columnDef = [
    {
      headerName: "Request Date",
      field: "requestdate",
      maxWidth: 200,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Leave Type",
      field: "leaveType",
      minWidth: 150,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Leave Duration",
      field: "leaveDuration",
      maxWidth: 150,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "No. Of Days",
      field: "noOfDays",
      maxWidth: 150,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Status",
      field: "status",
      maxWidth: 150,
      filter: true,
      floatingFilter: true,
      cellRenderer: checkStatusAction,
    },
    {
      headerName: "Actions",
      // field: "",
      maxWidth: 200,
      cellRenderer: viewAllLeaveManagementAction,
    },
  ];
  return (
    <div className="">
      <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-4">
        <AllLeavesCards text={"Total Leaves"} value={"02"} />
        <AllLeavesCards text={"Leave Used"} value={"01"} />
        <AllLeavesCards text={"Leave Balence"} value={"01"} />
        <AllLeavesCards text={"Declined Leaves"} value={"04"} />
      </div>
      <div className="flex items-center justify-between my-4">
        <div className="text-[16px] leading-[20.16px] tracking-[-0.8px] text-[#6E7C87] font-bold ">
          My Requests
        </div>
        <button
          type="button"
          className="flex items-center justify-center h-10 w-[133px] text-[16px] leading-4 font-medium text-[#F6F8F9] tracking-[-0.6%] rounded-md bg-base-primary hover:bg-base-primary_hover"
          // onClick={handleAskForLeave}
        >
          Ask For Leave
        </button>
      </div>
      <CustomeTable
        columnDefs={columnDef}
        rowData={tableData}
        setSelectedRows={setSelectedRows}
      />
    </div>
  );
};

export default LeaveManagement;
