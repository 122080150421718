import React, { useState } from "react";
import { CustomeTable } from "../../components";

const BuffetTable = () => {
  const [selectedRows, setSelectedRows] = useState([]);

  const rowData = [
    {
      buffet_id: "IT01",
      dishName: "Chicken Wings",
      buffetType: "Breakfast Buffet",
      preParationTime: "Hotel Guest Buffet",
      deliveryTime: "08:00 am",
      status: "preparing",
    },
    {
      buffet_id: "IT02",
      dishName: "Chinese",
      buffetType: "Lunch Buffet",
      preParationTime: "Wedding Buffet",
      deliveryTime: "01:00 pm",
      status: "inQueue",
    },
    {
      buffet_id: "IT03",
      dishName: "Vegetable pulao",
      buffetType: "Lunch Buffet",
      preParationTime: "Wedding Buffet",
      deliveryTime: "01:00 pm",
      status: "preparing",
    },
    {
      buffet_id: "IT04",
      dishName: "Chicken Wings",
      buffetType: "Lunch Buffet",
      preParationTime: "Wedding Buffet",
      deliveryTime: "01:00 pm",
      status: "inQueue",
    },
    {
      buffet_id: "IT05",
      dishName: "Chicken Wings",
      buffetType: "Lunch Buffet",
      preParationTime: "Wedding Buffet",
      deliveryTime: "01:00 pm",
      status: "inQueue",
    },
  ];
  const checkStatusActions = ({ value }) => {
    return (
      <>
        <div className="mt-2 capitalize">
          {value === "preparing" && (
            <>
              <div className="flex justify-center  items-center w-[89px] h-[24px] border border-base-primary bg-[#00a35e33] rounded text-[14px] leading-[24px] tracking-[-0.6%] font-normal text-base-primary">
                {value}
              </div>
            </>
          )}
          {value === "inQueue" && (
            <>
              <div className="flex justify-center items-center w-[89px] h-[24px] border border-[#EFD703] bg-[#fff50033] rounded text-[14px] leading-[24px] tracking-[-0.6%] font-normal text-[#EFD703]">
                {value}
              </div>
            </>
          )}
        </div>
      </>
    );
  };
  const columnDefs = [
    {
      headerName: "Buffet ID",
      field: "buffet_id",
      maxWidth: 120,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Dish Name",
      field: "dishName",
      minWidth: 150,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Buffet Type",
      field: "buffetType",
      maxWidth: 150,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "PreParation Time",
      field: "preParationTime",
      maxWidth: 150,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Delivery Time",
      field: "deliveryTime",
      maxWidth: 150,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Status",
      field: "status",
      maxWidth: 200,
      minWidth: 100,
      cellRenderer: checkStatusActions,
    },
  ];
  return (
    <div className="">
      <CustomeTable
        columnDefs={columnDefs}
        rowData={rowData}
        setSelectedRows={setSelectedRows}
      />
    </div>
  );
};

export default BuffetTable;
