import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import {
  assetsReportAllTime,
  assetsReportAllTimeForOne,
} from "../../actions/reports/assetsReportAllTime";
import { assetsReportAllTimeByDate } from "../../actions/reports/assetsReportByDate";
import ReportsCard from "./ReportsCard";
import totalItemIcon from "../../images/icons/totalItemIcon.svg";
import pendingOrderIcon from "../../images/icons/pendingOrderIcon.svg";
import topVendorIcon from "../../images/icons/topVendorIcon.svg";
import totalValueItem from "../../images/icons/totalValueItem.svg";
import CustomeTable from "../../components/CustomeTable";
import DownloadButton from "../../components/buttons/DownloadButton";

const PurchasOrderReports = (props) => {
  // const { t } = useTranslation();
  const [datas, setDatas] = useState([]);
  const { sales } = useSelector((state) => state.SalesReport);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    props.getAssetsFromToDateAll(
      props?.hotel?._id,
      props?.fromDate,
      props?.toDate
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.fromDate, props?.toDate]);

  useEffect(() => {
    if (sales && Array.isArray(sales) && sales?.length) {
      let expense = sales?.map((d) => {
        return {
          purchaseOrderNo: d?.purchaseOrderNo,
          productName: d?.productName,
          vendorName: d?.vendorName,
          dateOrdered: d?.dateOrdered,
          dateExpected: d?.dateExpected,
          tdateReceived: d?.dateReceived,
          totalCost: d?.totalCost,
        };
      });
      setDatas(expense);
    }
  }, [sales]);

  const tableData = [
    {
      id:"POID01",
      purchaseOrderNo: "P001",
      productName: "Potato",
      vendorName: "ABC Supplies",
      dateOrdered: "2024-01-12",
      dateExpected: "2024-01-12",
      dateReceived: "Not Received",
      totalCost: "$500",
    },
    {
      id:"POID02",
      purchaseOrderNo: "P002",
      productName: "Salt",
      vendorName: "ABC Supplies",
      dateOrdered: "2024-01-12",
      dateExpected: "2024-01-12",
      dateReceived: "2024-01-12",
      totalCost: "$500",
    },
    {
      id:"POID03",
      purchaseOrderNo: "P003",
      productName: "Potato",
      vendorName: "ABC Supplies",
      dateOrdered: "2024-01-12",
      dateExpected: "2024-01-12",
      dateReceived: "Not Received",
      totalCost: "$500",
    },
    {
      id:"POID04",
      purchaseOrderNo: "P004",
      productName: "Potato",
      vendorName: "ABC Supplies",
      dateOrdered: "2024-01-12",
      dateExpected: "2024-01-12",
      dateReceived: "2024-01-12",
      totalCost: "$500",
    },
    {
      id:"POID05",
      purchaseOrderNo: "P005",
      productName: "Potato",
      vendorName: "ABC Supplies",
      dateOrdered: "2024-01-12",
      dateExpected: "2024-01-12",
      dateReceived: "Not Received",
      totalCost: "$500",
    },
  ];
  const checkDateReceived = ({ value }) => {
    return (
      <>
        <div className="text-[14px] leading-6 font-normal tracking-[-0.6%] mt-2">
          {value === "Not Received" ? (
            <div className="text-[#F04438]">{value}</div>
          ) : (
            <div>{value}</div>
          )}
        </div>
      </>
    );
  };
  const columnDefs = [
    {
      headerName: "Purchase Order ID",
      field: "id",
      maxWidth: 200,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Purchase Order No.",
      field: "purchaseOrderNo",
      maxWidth: 300,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Product Name",
      field: "productName",
      maxWidth: 300,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Vendor Name",
      field: "vendorName",
      maxWidth: 300,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Date Ordered",
      field: "dateOrdered",
      minWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Date Expected",
      field: "dateExpected",
      minWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Date Received",
      field: "dateReceived",
      minWidth: 200,
      filter: true,
      floatingFilter: true,
      cellRenderer: checkDateReceived,
    },
    {
      headerName: "Total Cost",
      field: "totalCost",
      maxWidth: 300,
      filter: true,
      floatingFilter: true,
    },
  ];

  return (
    <>
      <div className="w-full mt-4">
        <div className="my-4 flex items-center gap-3">
          <ReportsCard
            utility={"w-[250px]"}
            icon={totalItemIcon}
            iconBgColor={"bg-[#F1FADF]"}
            heading={"Total Purchases"}
            value={"1258"}
          />
          <ReportsCard
            utility={"w-[301px]"}
            icon={pendingOrderIcon}
            iconBgColor={"bg-[#E8EBFF]"}
            heading={"Pending Orders"}
            value={"800"}
          />
          <ReportsCard
            utility={"w-[301px]"}
            icon={topVendorIcon}
            iconBgColor={"bg-[#FCEEDD]"}
            heading={"Top Vendor"}
            value={"ABC Supplies"}
          />
          <ReportsCard
            utility={"w-[280px]"}
            icon={totalValueItem}
            iconBgColor={"bg-[#FCEAEA]"}
            heading={"Total Value"}
            value={"425"}
          />
        </div>
        <div className="flex items-center justify-between my-4">
          <h2 className="text-[24px] leading-6 font-bold">Purchase Order Report</h2>
          <DownloadButton />
        </div>
        {/* {datas && datas?.length ? ( */}
        <CustomeTable
          columnDefs={columnDefs}
          rowData={tableData}
          setSelectedRows={setSelectedRows}
        />
        {/* ) : null} */}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  data: state.AssetsReport,
});

const mapDispatchToProps = (dispatch) => ({
  getAllTimeAsset: (hotelId) => dispatch(assetsReportAllTime(hotelId)),
  //get assets report by date
  getAssetsReportAll: (hotelId, assetId) =>
    dispatch(assetsReportAllTimeForOne(hotelId, assetId)),
  getAssetsFromToDateAll: (hotelId, fromDate, toDate) =>
    dispatch(assetsReportAllTimeByDate(hotelId, fromDate, toDate)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PurchasOrderReports);
