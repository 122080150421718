/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateHotelAction } from "../../actions/pms/hotel";
import LoadSpinner from "../../components/loader/LoadSpinner";
import { isObjEmpty } from "../../utils";
import AddressAutoComplete from "../../components/layouts/AddressAutoComplete";
import { ONBOARDING_STAGES } from "../../constants";
import { useTranslation } from "react-i18next";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";

const Step4 = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [streetAddress, setStreetAddress] = useState("");
  const [confirm, setConfirm] = useState(false);
  const { loading, isupdated } = useSelector((state) => state.UpdateHotel);

  useEffect(() => {
    if (!isObjEmpty(props?.hotel)) {
      if (props?.hotel?.streetAddress)
        setStreetAddress(props?.hotel?.streetAddress);
    }
  }, [props?.hotel]);

  const formSubmit = (e) => {
    e.preventDefault();
    if (!confirm) return;

    const formData = {
      hotelId: props?.hotel?._id,
      streetAddress,
      stage: ONBOARDING_STAGES.DASHBOARD,
    };
    dispatch(updateHotelAction(formData));
  };

  useEffect(() => {
    if (isupdated) {
      props.reset();
      props.setStep(5);
    }
  }, [isupdated]);

  return (
    <>
      <div className="flex items-start flex-col w-full pb-6 px-4 sm:px-6">
        <form className="w-full sm:w-[560px]" onSubmit={formSubmit}>
          <div className="text-center text-[40px] leading-[48px] font-bold tracking-[-1.5%] text-[#07090D] mb-4">
            Search Your Property Name
          </div>
          <div>
            <label className="inline-block mb-1 text-sm font-semibold">
              {t("923")}
            </label>
            <AddressAutoComplete
              address={streetAddress}
              setAddress={setStreetAddress}
            />
          </div>
          <div className="w-full flex items-center my-3">
            <input
              type={"checkbox"}
              value={confirm}
              onChange={(e) => setConfirm(!confirm)}
              id="property-is-belongs-to-me"
              className="w-fit shadow-none accent-current bg-[#B0BABF]"
              required={true}
            />
            <label
              className="inline-block text-sm font-semibold ml-2"
              htmlFor="property-is-belongs-to-me"
            >
              {t("924")}
            </label>
          </div>
          {/* <div className="w-fit px-4 py-2 rounded-md capitalize border border-base-primary font-semibold mt-6 text-base-primary">
            {t("925")}
          </div> */}
          <div className="flex gap-4 w-full items-center justify-end text-sm font-medium ">
            <button
              className="w-[120px] h-12 flex items-center justify-center gap-2 rounded-md hover:bg-gray-200 bg-[#FFFFFF] border border-[#E5E9EB] text-[#252C32] capitalize"
              onClick={() => props.setStep(3)}
              type="button"
            >
              <FiArrowLeft size={18} />
              {t("64")}
            </button>
            <button
              className="w-[120px] h-12 flex justify-center items-center gap-2 bg-base-primary hover:bg-base-primary_hover rounded-md  text-[#FFFFFF] capitalize"
              type="submit"
              disabled={loading}
            >
              {loading ? (
                <LoadSpinner />
              ) : (
                <>
                  {t("65")}
                  <FiArrowRight size={18} />
                </>
              )}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Step4;
