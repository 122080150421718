import React from "react"; 
import { registerEvent } from "../../logs";
import { useTranslation } from "react-i18next";

export default function SaveOrNot({ formSubmit, isopen, close }) {
  const { t } = useTranslation();
  //agree to delete
  const agree = () => {
    formSubmit();
    close();
    registerEvent("agree to delete", "delete or not delete modal");
  };

  //disagree to delete
  const disagree = () => {
    close();
    registerEvent("disagree to delete", "delete or not delete modal");
  };

  return (
    <>
      {/* <Dialog
        open={isopen}
        onClose={disagree}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="max-w-[90%] w-96" id="alert-dialog-title">
          {"Please Confirm Changes"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("1117")}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="p-4">
          <Button
            onClick={disagree}
            style={{ background: "#ff3853", color: "white" }}
            className="rounded-sm py-1 hover:bg-[#fa223f]"
          >
            {t("258")}
          </Button>
          <Button
            onClick={agree}
            autoFocus
            style={{ background: "#2fa05a", color: "white" }}
            className="rounded-sm py-1 hover:bg-[#257e47]"
          >
            {t("87")}
          </Button>
        </DialogActions>
      </Dialog> */}
    </>
  );
}
