/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useState, useEffect } from "react";
import { clearError } from "../../actions/clearError";
import {
  addEmployeeAction,
  getAllHotelEmployeeAction,
} from "../../actions/pms/employee";
import { connect, useSelector } from "react-redux";
import SaveOrNot from "../../components/layouts/SaveOrNot";
import { registerEvent } from "../../logs";
import { useTranslation } from "react-i18next";
import Inputfield from "../../components/layouts";
import SelectFromObject from "../../components/layouts/SelectFromObject";
import {
  DAYS_NAME,
  DEPARTMENTS, 
  GENDERS,
  POSITIONS,   
} from "../../constants";
import SelectFromArray from "../../components/layouts/SelectFromArray";
import PhoneWithCode from "../../components/layouts/PhoneWithCode";
import { getToday, getYearAgoToday } from "../../utils/dateAndTime";
import { notifyError, notifySuccess } from "../../components/alert";
import {
  ADD_EMPLOYEE_RESET,
  UPDATE_EMPLOYEE_RESET,
} from "../../actions/types/employee";
import { updateHotelEmployee } from "../../actions/pms/employeeProfile"; 
import StateNameSelector from "../../components/layouts/StateNameSelector";
import CountryNameSelector from "../../components/layouts/CountryNameSelector";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import CirclePluseIcon from "../../images/icons/CirclePluseIcon.svg";
import CopiedIcon from "../../images/icons/CopiedIcon.svg";
import { RxCross2 } from "react-icons/rx";
import MultipleSelect from "../../components/layouts/MultipleSelector";
import FormSubmitButton from "../../components/buttons";

const AddEmployee = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { hotel } = useSelector((state) => state.Hotel);

  //add new states
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState(GENDERS.MALE);
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [countryIsoCode, setCountryIsoCode] = useState("");

  const [state, setState] = useState("");
  const [stateIsoCode, setStateIsoCode] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [designation, setDesignation] = useState(POSITIONS.ACCOUNTING_MANAGER);
  const [department, setDepartment] = useState(DEPARTMENTS.ACCOUNTING_MANAGER);
  const [reportingTo, setReportingTo] = useState("62f49e1ec9782c752d510059");
  const [joiningDate, setJoiningDate] = useState("");
  const [rotationShift, setRotationShift] = useState("");
  const [shiftType, setShiftType] = useState("");
  const [roasterManager, setRoasterManager] = useState("");
  // const [shiftStartTime, setShiftStartTime] = useState("");
  // const [shiftEndTime, setShiftEndTime] = useState("");
  // const [password, setPassword] = useState("");
  const [selectDutyDays, setSelectDutyDays] = useState([]);
  const [selectDutyTimeStart, setSelectDutyTimeStart] = useState("");
  const [selectDutyTimeEnd, setSelectDutyTimeEnd] = useState("");
  const [openRoasterManagement, setOpenRoasterManagement] = useState(true);
  const [createMoreShifts, setCreateMoreShifts] = useState([]);
  const [userName, setUserName] = useState("");
  const [userPassword, setUserPassword] = useState("");
  // new field
  const [employeeType, setEmployeeType] = useState([]);

  const handleSubmit = () => {
    const formData = {
      hotelId: hotel?._id,
      firstName,
      lastName,
      gender,
      dateOfBirth,
      phone,
      email,
      country,
      state,
      pinCode,
      streetAddress,
      designation,
      department,
      reportingTo,
      joiningDate,
      rotationShift,
      shiftType,
      roasterManager,
      selectDutyDays,
      selectDutyTimeStart,
      selectDutyTimeEnd,
      userName,
      userPassword,
      stateIsoCode,
      // new field
      employeeType,
      // password,
      // shiftEndTime,
      // shiftStartTime,
    };

    if (props?.updateData?.employeeId) {
      props?.updateEmployee({
        ...formData,
        employeeId: props?.updateData?.employeeId,
      });
    } else props.addEmployee(formData);
  };

  useEffect(() => {
    if (props?.updateData?.employeeId) {
      setFirstName(props?.updateData?.firstName);
      setLastName(props?.updateData?.lastName);
      setGender(props?.updateData?.gender);
      setDateOfBirth(props?.updateData?.dateOfBirth?.slice(0, 10));
      setPhone(props?.updateData?.phone);
      setEmail(props?.updateData?.email);
      setCountry(props?.updateData?.country);
      setState(props?.updateData?.state);
      setPinCode(props?.updateData?.pinCode);
      setStreetAddress(props?.updateData?.streetAddress);
      setDesignation(props?.updateData?.designation);
      setDepartment(props?.updateData?.department);
      setReportingTo(props?.updateData?.reportingTo);
      setJoiningDate(props?.updateData?.joiningDate?.slice(0, 10));
      setRotationShift(props?.updateData?.rotationShift);
      setShiftType(props?.updateData?.shiftType);
      setRoasterManager(props?.updateData?.roasterManager);
      setSelectDutyDays(props?.updateData?.selectDutyDays);
      setSelectDutyTimeStart(props?.updateData?.selectDutyTimeStart);
      setSelectDutyTimeEnd(props?.updateData?.selectDutyTimeEnd);
      setUserName(props?.updateData?.userName);
      setUserPassword(props?.updateData?.userPassword);
      // setShiftStartTime(props?.updateData?.shiftStartTime);
      // setShiftEndTime(props?.updateData?.shiftEndTime);
      // setPassword(props?.updateData?.password);

      // new field
      setEmployeeType(props?.updateData?.employeeType);
    }
  }, [props?.updateData]);

  const handleClickOpen = (e) => {
    e.preventDefault();
    registerEvent("add employee button click", "add employee");
    setOpen(true);
  };

  useEffect(() => {
    if (props?.employee?.isadded || props?.employee?.isupdated) {
      setFirstName("");
      setLastName("");
      setGender("");
      setDateOfBirth("");
      setPhone("");
      setEmail("");
      setCountry("");
      setState("");
      setPinCode("");
      setStreetAddress("");
      setDesignation("");
      setDepartment("");
      setReportingTo("");
      setJoiningDate("");
      setRotationShift("");
      setShiftType("");
      setRoasterManager("");
      setSelectDutyDays("");
      setSelectDutyTimeStart("");
      setSelectDutyTimeEnd("");
      setUserName("");
      setUserPassword("");
      // setShiftStartTime("");
      // setShiftEndTime("");
      // setPassword("");

      // new field
      setEmployeeType("");
      props?.close();
    }

    if (props?.employee?.isadded) {
      notifySuccess(t("648"));
      props?.getEmployees(hotel?._id);
      props?.reset();
    }
    if (props?.employee?.isupdated) {
      notifySuccess(t("649"));
      props?.getEmployees(hotel?._id);
      props?.reset();
    }

    if (props?.employee?.error || props?.update?.error) {
      notifyError(t("650"));
      props?.clear();
    }
  }, [props?.employee, props?.update]);

  const createMoreShift = () => {
    const createNewShift = {
      selectDutyDays,
      selectDutyTimeStart,
      selectDutyTimeEnd,
    };
    setCreateMoreShifts([...createMoreShifts, createNewShift]);
  };
  return (
    <>
      <SaveOrNot
        formSubmit={handleSubmit}
        isopen={open}
        close={() => setOpen(false)}
      />
      <div className="p-8 pt-4 mx-auto flex flex-col border rounded-lg bg-white relative">
        <div className="flex items-center justify-between w-full mb-4">
          <div className="text-[26px] text-[#07090D] leading-[32.76px] font-bold tracking-[-1.5%]">
            {props?.updateData?.employeeId ? t("651") : t("200")}
          </div>
          <button type="button" onClick={props?.close}>
            <RxCross2
              size={26}
              className="hover:rotate-90 duration-500 hover:text-[#F04438]"
            />
          </button>
        </div>

        <form className="w-full pt-4" onSubmit={handleClickOpen}>
          <div className="w-full grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-6 grid-w-full">
            <SelectFromArray
              label={t("Employee Type")}
              options={['']}
              value={employeeType}
              onChange={(e) => setEmployeeType(e?.target?.value)}
            />
            <Inputfield
              label={`${t("First Name")} *`}
              placeholder={t("First Name")}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required={true}
              minLength={3}
              maxLength={30}
            />
            <Inputfield
              label={`${t("Last Name")} *`}
              placeholder={t("Last Name")}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required={true}
              minLength={3}
              maxLength={30}
            />
            <Inputfield
              label={`${t("654")} *`}
              placeholder={t("655")}
              value={dateOfBirth}
              onChange={(e) => setDateOfBirth(e.target.value)}
              type="date"
              required={true}
              max={getYearAgoToday(18)}
            />
            <SelectFromObject
              label={`${t("168")} *`}
              options={GENDERS}
              value={gender}
              onChange={(e) => setGender(e?.target?.value)}
            />
            <PhoneWithCode
              label={`${t("527")} *`}
              phone={phone}
              setPhone={setPhone}
            />
            <Inputfield
              label={t("416")}
              placeholder={t("656")}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              required={true}
            />
            {/* new fields */}
            <CountryNameSelector
              country={country}
              setCountry={setCountry}
              setCountryIsoCode={setCountryIsoCode}
            />
            <StateNameSelector
              state={state}
              setState={setState}
              setStateIsoCode={setStateIsoCode}
              countryCode={countryIsoCode}
            />
            <Inputfield
              label={"Pin Code"}
              placeholder={t("Code")}
              value={pinCode}
              onChange={(e) => setPinCode(e.target.value)}
              minLength={5}
              maxLength={100}
              required={true}
            />
            <Inputfield
              label={t("Street Address")}
              placeholder={t("Street Address")}
              value={streetAddress}
              onChange={(e) => setStreetAddress(e.target.value)}
              minLength={10}
              maxLength={100}
              required={true}
            />
            <SelectFromObject
              label={t("658")}
              options={POSITIONS}
              value={designation}
              onChange={(e) => setDesignation(e?.target?.value)}
            />
            <SelectFromObject
              label={t("659")}
              options={DEPARTMENTS}
              value={department}
              onChange={(e) => setDepartment(e?.target?.value)}
            />

            <SelectFromObject
              label={t("660")}
              options={{}}
              value={reportingTo}
              setValue={setReportingTo}
              onChange={(e) => setDesignation(e?.target?.value)}
            />
            <Inputfield
              label={t("661")}
              type="date"
              value={joiningDate}
              onChange={(e) => setJoiningDate(e.target.value)}
              required={true}
              min={getToday()}
            />

            <SelectFromObject
              label={`${t("Rotation Shift")} *`}
              options={{}}
              value={rotationShift}
              onChange={(e) => setRotationShift(e?.target?.value)}
            />

            <SelectFromObject
              label={`${t("Shift Type ")} *`}
              options={{}}
              value={shiftType}
              onChange={(e) => setShiftType(e?.target?.value)}
            />

            <Inputfield
              label={`${t("Roaster Manager")} *`}
              placeholder={t("First Name")}
              value={roasterManager}
              onChange={(e) => setRoasterManager(e.target.value)}
              required={true}
            />
            {/* <Inputfield
              label={t("211")}
              placeholder={t("211")}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required={true}
              minLength={5}
              maxLength={15}
            /> */}
          </div>
          {/* Roaster Management */}
          <div className="relative">
            <div
              className="w-full h-[53px] flex items-center justify-between mt-4 cursor-pointer "
              onClick={() => setOpenRoasterManagement(!openRoasterManagement)}
            >
              <div className="text-[18px] text-[#07090D] leading-6 font-medium">
                {t("Fixed Shift Roaster")}
              </div>
              {openRoasterManagement ? (
                <IoIosArrowForward size={18} className="" />
              ) : (
                <IoIosArrowDown size={18} />
              )}
            </div>
            {!openRoasterManagement ? (
              <div className="h-auto border border-[#E5E9EB] bg-[#F6F6F6] rounded-[5px] px-4 py-6">
                <div className="flex items-center gap-8">
                  <div className="w-[347px]">
                    <MultipleSelect
                      label={t("Select Duty Days")}
                      values={selectDutyDays}
                      setValues={setSelectDutyDays}
                      options={DAYS_NAME}
                    />
                  </div>
                  <div>
                    <label>{`${t("Select Duty Time")} *`}</label>
                    <div className="w-[347px] h-12 mb-[2px] border border-[#D0D5DD] bg-white rounded-lg flex items-center justify-between">
                      <input
                        type="time"
                        className="h-fit w-[157px] border-none focus:border-none shadow-none"
                        value={selectDutyTimeStart}
                        onChange={(e) =>
                          setSelectDutyTimeStart(e?.target?.value)
                        }
                      />
                      <div className="text-[16px] text-[#6E7C87] leading-6 font-normal">
                        {t("To")}
                      </div>
                      <input
                        type="time"
                        className="h-fit w-[157px] border-none focus:border-none shadow-none"
                        value={selectDutyTimeEnd}
                        onChange={(e) => setSelectDutyTimeEnd(e?.target?.value)}
                      />
                    </div>
                  </div>
                </div>
                {createMoreShifts.map((i, createShiftData) => {
                  return (
                    <>
                      <div className="flex items-center gap-8 my-4" key={i}>
                        <div className="w-[347px]">
                          <MultipleSelect
                            label={t("Select Duty Days")}
                            values={selectDutyDays}
                            setValues={setSelectDutyDays}
                            options={DAYS_NAME}
                          />
                        </div>
                        <div className="">
                          <label>{`${t("Select Duty Time")} *`}</label>
                          <div className="w-[347px] h-12 mb-[2px] border border-[#D0D5DD] bg-white rounded-lg flex items-center justify-between">
                            <input
                              type="time"
                              className="h-fit w-[157px] border-none focus:border-none shadow-none"
                              value={selectDutyTimeStart}
                              onChange={(e) =>
                                setSelectDutyTimeStart(e?.target?.value)
                              }
                            />
                            <div className="text-[16px] text-[#6E7C87] leading-6 font-normal">
                              {t("To")}
                            </div>
                            <input
                              type="time"
                              className="h-fit w-[157px] border-none focus:border-none shadow-none"
                              value={selectDutyTimeEnd}
                              onChange={(e) =>
                                setSelectDutyTimeEnd(e?.target?.value)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}

                {/* create more shift */}
                <div className="flex items-center justify-between mt-4">
                  <button
                    type="button"
                    className="h-[45px] rounded-md border border-[#E5E9EB] bg-white hover:bg-gray-200 text-[#07090D] px-4 flex items-center justify-center gap-3"
                    onClick={createMoreShift}
                  >
                    <img src={CirclePluseIcon} alt="" />
                    <div className="text-[16px] leading-4 tracking-[-0.6%]">
                      {t("Create More Shift")}
                    </div>
                  </button>
                  {/* <NewFormSubmitButton
                    h="h-[45px]"
                    text={"Create More Shift"}
                    loading={props?.employee?.loading || props?.update?.loading}
                  /> */}
                </div>
              </div>
            ) : null}
          </div>

          {/* user name and password */}
          <div className="flex items-center gap-6 my-4">
            <div className="relative w-[349px]">
              <Inputfield
                label={t("Username")}
                placeholder={t("firstname")}
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                required={true}
              />
              <button type="button" className="absolute right-4 top-8">
                <img src={CopiedIcon} alt="" />
              </button>
            </div>
            <button
              type="button"
              className="h-10 w-[199px] border border-base-primary rounded-md bg-white text-sm text-[#07090D] font-medium hover:bg-base-primary hover:text-[#FFFFFF] mt-5"
            >
              {t("Generate Username")}
            </button>
          </div>
          <div className="flex items-center gap-6">
            <div className="relative w-[349px]">
              <Inputfield
                label={t("Password")}
                placeholder={t("userpassword")}
                value={userPassword}
                onChange={(e) => setUserPassword(e.target.value)}
                required={true}
              />
              <button type="button" className="absolute right-4 top-8">
                <img src={CopiedIcon} alt="" />
              </button>
            </div>
            <button
              type="button"
              className="h-10 w-[199px] border border-base-primary rounded-md bg-white text-sm text-[#07090D] font-medium hover:bg-base-primary hover:text-[#FFFFFF] mt-5"
            >
              {t("Generate Password")}
            </button>
          </div>

          <div className="flex mt-12">
            <FormSubmitButton 
              text={props?.updateData?.employeeId ? t("665") : t("200")}
              loading={props?.employee?.loading || props?.update?.loading}
            />
          </div>
        </form>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  employee: state.AddEmployee,
  update: state.UpdateEmployeeProfile,
});

const mapDispatchToProps = (dispatch) => ({
  addEmployee: (data) => dispatch(addEmployeeAction(data)),
  updateEmployee: (data) => dispatch(updateHotelEmployee(data)),
  clear: () => dispatch(clearError()),
  reset: () =>
    dispatch({ type: ADD_EMPLOYEE_RESET }, { type: UPDATE_EMPLOYEE_RESET }),
  getEmployees: (hotelId) => dispatch(getAllHotelEmployeeAction(hotelId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEmployee);
