import { CLEAR_ERRORS } from "../../actions/types";
import {
  GET_ALL_OCCUPANCY_FAIL,
  GET_ALL_OCCUPANCY_REQ,
  GET_ALL_OCCUPANCY_SUC,
  OCCUPANCY_BY_DATE_FAIL,
  OCCUPANCY_BY_DATE_REQ,
  OCCUPANCY_BY_DATE_SUC,
  OCCUPANCY_BY_MONTH_FAIL,
  OCCUPANCY_BY_MONTH_REQ,
  OCCUPANCY_BY_MONTH_SUC,
  OCCUPANCY_BY_YEAR_FAIL,
  OCCUPANCY_BY_YEAR_REQ,
  OCCUPANCY_BY_YEAR_SUC,
} from "../../actions/types/occupancyReport";

//occupancy report
export const occupanciesReportReducer = (state = { occupancies: [] }, action) => {
  switch (action.type) {
    case GET_ALL_OCCUPANCY_REQ:
    case OCCUPANCY_BY_DATE_REQ:
    case OCCUPANCY_BY_MONTH_REQ:
    case OCCUPANCY_BY_YEAR_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_OCCUPANCY_SUC:
    case OCCUPANCY_BY_DATE_SUC:
    case OCCUPANCY_BY_MONTH_SUC:
    case OCCUPANCY_BY_YEAR_SUC:
      return {
        ...state,
        loading: false,
        occupancies: action.payload,
      };
    case GET_ALL_OCCUPANCY_FAIL:
    case OCCUPANCY_BY_DATE_FAIL:
    case OCCUPANCY_BY_MONTH_FAIL:
    case OCCUPANCY_BY_YEAR_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        occupancies:null,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
