export const SEND_OTP_REQUEST = "SEND_OTP_REQUEST";
export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS";
export const SEND_OTP_FAIL = "SEND_OTP_FAIL"; 
export const SEND_OTP_RESET = "SEND_OTP_RESET";

export const RE_SEND_OTP_REQUEST = "RE_SEND_OTP_REQUEST";
export const RE_SEND_OTP_SUCCESS = "RE_SEND_OTP_SUCCESS";
export const RE_SEND_OTP_FAIL = "RE_SEND_OTP_FAIL"; 
export const RE_SEND_OTP_RESET = "RE_SEND_OTP_RESET";

export const VERIFY_OTP_REQUEST = "VERIFY_OTP_REQUEST";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAIL = "VERIFY_OTP_FAIL"; 
export const VERIFY_OTP_RESET = "VERIFY_OTP_RESET"; 