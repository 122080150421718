import React from "react";
import Player from "../Player";

const KunggyIntro = () => {
  return (
    <div className="container select-none">
      <section className="text-black capitalize">
        <h2 className="text-[55px] leading-[66.56px] tracking-[-0.8px] font-bold">
          Welcome To <span className="text-base-primary">Kunggy Tutorial</span>
        </h2>
        <h3 className="text-[28px] leading-[42px] font-normal tracking-[-2%]">
          Watch our quick tutorial to understand Kunggy
        </h3>
        <div className="max-w-[565px] my-6 border-black border-t"></div>
        <h2 className="text-[40px] leading-[48.21px] tracking-[-0.8px] font-medium">
          Introduction to kunggy
        </h2>
        <p className="text-[20px] leading-[30.99px] font-normal my-2">
          Kunggy is a state-of-the-art property management system designed to
          streamline real estate operations. It offers comprehensive tools for
          managing properties, tenants, maintenance requests, and financial
          transactions, ensuring efficiency and transparency. Kunggy's intuitive
          interface and robust features make property management simpler and
          more effective for landlords and property managers.
        </p>
        <ul className="text-[20px] leading-[30.99px] font-normal list-disc pl-[22px] mt-8">
          <li>
            <span className="font-medium">
              Comprehensive Property Management:
            </span>{" "}
            Kunggy offers tools to manage all aspects of property management,
            from tracking maintenance requests to handling tenant communications
            and processing payments.
          </li>
          <li className="text-base-primary my-2">
            <span className="font-medium">User-Friendly Interface:</span> The
            application features an intuitive interface that simplifies
            navigation and enhances user experience, making it accessible for
            users with varying levels of tech proficiency.
          </li>

          <li>
            <span className="font-medium">Scalability:</span> Kunggy is designed
            to cater to both small landlords and large property management
            firms, providing the flexibility to manage a single property or an
            extensive portfolio with ease.
          </li>

          <li className="text-base-primary my-2">
            <span className="font-medium">Efficiency Boost:</span> By automating
            routine tasks and providing organized, easy-to-access information,
            Kunggy significantly improves productivity and reduces the
            administrative burden on property managers.
          </li>

          <li>
            <span className="font-medium">
              Real-Time Reporting and Analytics:
            </span>{" "}
            Kunggy provides real-time reporting and analytics features, enabling
            property managers to make informed decisions based on current data,
            track performance, and identify areas for improvement.
          </li>
          <li className="text-base-primary my-2">
            <span className="font-medium">Enhanced Tenant Satisfaction:</span>{" "}
            With efficient management tools and clear communication channels,
            Kunggy helps property managers respond promptly to tenant needs,
            leading to higher tenant satisfaction and retention rates.
          </li>
        </ul>
      </section>
      <div className="my-12">
        {/*video player "https://www.youtube.com/watch?v=hn3vNgttkFM"*/}
        <Player videoURL={"https://www.youtube.com/watch?v=hn3vNgttkFM"} />
      </div>
      <p className="text-[20px] leading-[30.99px] font-normal">
        In conclusion, Kunggy revolutionizes property management with its
        comprehensive features, user-friendly interface, and scalable solutions.
        By enhancing efficiency, improving tenant satisfaction, and providing
        real-time insights, Kunggy is the ultimate tool for modern property
        management professionals.
      </p>
    </div>
  );
};

export default KunggyIntro;
