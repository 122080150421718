import React from "react";
import { useTranslation } from "react-i18next";

const RoomStatus = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="flex min-w-[700px]">
        <div className="w-full h-[50px] border-r last:border-r-0 flex gap-10 pl-6">
          <div className="flex items-center">
            <div className="w-5 h-5 bg-base-primary rounded-md mr-2"></div>
            {t("645")}
          </div>
          <div className="flex items-center ">
            <div className="w-5 h-5 bg-[#1F5DFE] rounded-md mr-2"></div>
            {t("646")}
          </div>
          <div className="flex items-center ">
            <div className="w-5 h-5 bg-[#FF5050] rounded-md mr-2"></div>
            {t("647")}
          </div>
        </div>
      </div>
    </>
  );
};
export default RoomStatus;
