import axios from "axios";
import { PAGE_DETAIL, PROPERTY_ID, TOKEN } from "../constants";

/* track event logs of user
@ DATA :
{
    hotelId,*
    eventName,*
    url,*
    page,*
    section,
    data, { Object }
    serverResponseCode,
    errorMessage
}
*/

export const registerEvent = async (eventName, section, data) => {
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/logs/register`;

  const pageDetails = await JSON.parse(sessionStorage.getItem(PAGE_DETAIL));
  const hotelId = localStorage.getItem(PROPERTY_ID);

  if (!hotelId || !pageDetails?.url || !eventName) return;

  return;

  // eslint-disable-next-line no-unreachable
  try {
    await axios.post(
      url,
      {
        hotelId,
        eventName,
        url: pageDetails.url,
        page: pageDetails.name,
        section,
        data,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-auth-token": localStorage.setItem(TOKEN),
        },
        withCredentials: true,
      }
    );
  } catch (error) {}
};
