/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import DeleteOrNot from "../../components/layouts/DeleteOrNot";
import { updateHotelAction } from "../../actions/pms/hotel";
import BackGroundLoadSpinner from "../../components/loader/BackGroundLoadSpinner";
import { registerEvent } from "../../logs";
import { notifyError } from "../../components/alert";
// import { useTranslation } from "react-i18next";
import { ONBOARDING_STAGES } from "../../constants";
import CustomeTable from "../../components/CustomeTable";
import PrintButton from "../../components/buttons/PrintButton";
import UploadButton from "../../components/buttons/UploadButton";
import DownloadButton from "../../components/buttons/DownloadButton";

const BookingSourceAll = (props) => {
  // const { t } = useTranslation();
  const [datas, setDatas] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    if (props?.hotel && props?.hotel?.bookingSource) {
      let sources = props?.hotel?.bookingSource?.map((s) => {
        return {
          source: s?.source,
          email: s?.email,
          _id: s?._id,
        };
      });
      setDatas(sources);
    }
  }, [props?.hotel]);

  // const deleteBookingSourceudate = (id) => {
  //   if (id) {
  //     setDeleteId(id);
  //     setOpen(true);
  //   }
  // };

  //delete booking source confirm
  const deleteBookingSourceConfirm = () => {
    const result = datas?.filter((data) => data._id !== deleteId);
    props.updateSource({
      hotelId: props?.hotel?._id,
      bookingSource: result,
      stage: ONBOARDING_STAGES.DASHBOARD,
    });
    registerEvent("confirm delete booking source", "booking source", {
      result,
    });
  };

  useEffect(() => {
    if (
      (props?.data && props?.data?.error) ||
      (props?.data && props?.data?.isupdated)
    ) {
      notifyError(props?.data?.error);
      setDeleteId(null);
    }
  }, [props.data]);

  //confirmation cancel of booking source delete
  const notDeletingBookingSource = () => {
    setOpen(false);
    setDeleteId("");
  };
  const tableData = [
    {
      source: "Walk-in-guest",
      notificationEmail: "ok@gmail.co",
      status: "Active",
      bookingEmailReceived: "02",
      totalEmails: "01",
    },
    {
      source: "Walk-in-guest",
      notificationEmail: "ok@gmail.co",
      status: "Not Active",
      bookingEmailReceived: "02",
      totalEmails: "01",
    },
    {
      source: "Walk-in-guest",
      notificationEmail: "ok@gmail.co",
      status: "Active",
      bookingEmailReceived: "02",
      totalEmails: "01",
    },
    {
      source: "Walk-in-guest",
      notificationEmail: "ok@gmail.co",
      status: "Active",
      bookingEmailReceived: "02",
      totalEmails: "01",
    },
    {
      source: "Walk-in-guest",
      notificationEmail: "ok@gmail.co",
      status: "Active",
      bookingEmailReceived: "02",
      totalEmails: "01",
    },
    {
      source: "Walk-in-guest",
      notificationEmail: "ok@gmail.co",
      status: "Active",
      bookingEmailReceived: "02",
      totalEmails: "01",
    },
    {
      source: "Walk-in-guest",
      notificationEmail: "ok@gmail.co",
      status: "Active",
      bookingEmailReceived: "02",
      totalEmails: "01",
    },
    {
      source: "Walk-in-guest",
      notificationEmail: "ok@gmail.co",
      status: "Active",
      bookingEmailReceived: "02",
      totalEmails: "01",
    },
    {
      source: "Walk-in-guest",
      notificationEmail: "ok@gmail.co",
      status: "Active",
      bookingEmailReceived: "02",
      totalEmails: "01",
    },
    {
      source: "Walk-in-guest",
      notificationEmail: "ok@gmail.co",
      status: "Active",
      bookingEmailReceived: "02",
      totalEmails: "01",
    },
    {
      source: "Walk-in-guest",
      notificationEmail: "ok@gmail.co",
      status: "Active",
      bookingEmailReceived: "02",
      totalEmails: "01",
    },
  ];
  const columnDefs = [
    {
      headerName: "Booking Source ID",
      field: "_id",
      maxWidth: 200,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Source",
      field: "source",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Notification Email",
      field: "notificationEmail",
      maxWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Status",
      field: "status",
      maxWidth: 150,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Booking Email Received",
      field: "bookingEmailReceived",
      maxWidth: 250,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Total Emails",
      field: "totalEmails",
      maxWidth: 150,
      filter: true,
      floatingFilter: true,
    },
  ];
  return (
    <>
      <div className="flex items-center justify-between my-4">
        <div className="text-[22px] leading-[27.72px] text-black font-bold">
          Booking Source Table
        </div>
        <div className="flex items-center gap-4">
          <PrintButton />
          <UploadButton />
          <DownloadButton />
        </div>
      </div>
      <div className="my-4">
        <CustomeTable
          columnDefs={columnDefs}
          rowData={tableData}
          setSelectedRows={setSelectedRows}
        />
      </div>
      {open ? (
        <DeleteOrNot
          open={open}
          close={notDeletingBookingSource}
          formSubmit={deleteBookingSourceConfirm}
        />
      ) : null}
      {props?.data && props?.data?.loading ? <BackGroundLoadSpinner /> : null}
    </>
  );
};

const mapStateToProps = (state) => ({
  data: state.UpdateHotel,
});

const mapDispatchToProps = (dispatch) => ({
  updateSource: (data) => dispatch(updateHotelAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingSourceAll);
