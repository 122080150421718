import {
  ADD_TO_CART,
  CLEAR_CART,
  REMOVE_CART_ITEM,
} from "../../actions/types/food";

export const foodCartReducer = (state = { cartItems: [] }, action) => {
  switch (action.type) {
    case ADD_TO_CART:
      const item = action.payload;
      let exist = false;

      const items = state?.cartItems?.map((prevItem) => {
        if (prevItem?.id === item?.id) {
          prevItem = item;
          exist = true;
        }
        return prevItem;
      });
      if (exist) {
        return {
          ...state,
          cartItems: items,
        };
      } else {
        return {
          ...state,
          cartItems: [...state.cartItems, { ...item, quantity: 1 }],
        };
      }
    case REMOVE_CART_ITEM:
      return {
        ...state,
        cartItems: state.cartItems.filter((i) => i?.id !== action.payload),
      };
    case CLEAR_CART:
      return {
        ...state,
        cartItems: [],
      };

    default:
      return state;
  }
};
