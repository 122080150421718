const data = {
  labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
  datasets: [
    {
      label: "# of Votes",
      data: [15, 13, 28, 44],
      backgroundColor: [
        "#007041",
        "#00A35E",
        "#00D179",
        "#D4F7E1",
      ],
      borderWidth: 0,
    },
  ],
};
export default data;
