import { CLEAR_ERRORS } from "../../actions/types";
import {
  GET_ALL_SALES_FAIL,
  GET_ALL_SALES_REQ,
  GET_ALL_SALES_SUC,
  SALES_BY_DATE_FAIL,
  SALES_BY_DATE_REQ,
  SALES_BY_DATE_SUC,
  SALES_BY_MONTH_FAIL,
  SALES_BY_MONTH_REQ,
  SALES_BY_MONTH_SUC,
  SALES_BY_YEAR_FAIL,
  SALES_BY_YEAR_REQ,
  SALES_BY_YEAR_SUC,
} from "../../actions/types/salesReport";

//get booking sales report
export const getAllSalesReport = (state = { sales: [] }, action) => {
  switch (action.type) {
    case GET_ALL_SALES_REQ:
    case SALES_BY_DATE_REQ:
    case SALES_BY_MONTH_REQ:
    case SALES_BY_YEAR_REQ:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_SALES_SUC:
    case SALES_BY_DATE_SUC:
    case SALES_BY_MONTH_SUC:
    case SALES_BY_YEAR_SUC:
      return {
        ...state,
        loading: false,
        sales: action.payload,
      };
    case GET_ALL_SALES_FAIL:
    case SALES_BY_DATE_FAIL:
    case SALES_BY_MONTH_FAIL:
    case SALES_BY_YEAR_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        sales: null,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
