import React from "react";
import ToolTip from "../ToolTip";

const UnitsMeassureInputfield = ({
  label = "room",
  value,
  onChange,
  type = "text",
  max,
  min,
  maxLength,
  minLength,
  message,
  placeholder,
  required = false,
  readOnly = false,
  options,
  onChangeSelect,
  selectValue,
}) => {
  return (
    <div className="w-full text-[16px] leading-6 font-normal text-[#667085]">
      <div className={`flex items-center justify-between`}>
        <label>{label}</label>
        <ToolTip Message={message} />
      </div>
      <div className="relative flex items-center">
        <input
          className={`border-r-0 rounded-r-none focus:border-r-0 w-[90%]`}
          type={type}
          value={value}
          onChange={onChange}
          max={max}
          min={min}
          maxLength={maxLength}
          minLength={minLength}
          placeholder={placeholder}
          required={required}
          readOnly={readOnly}
        />
        <select
          value={selectValue}
          onChange={onChangeSelect}
          className="w-[96px] capitalize absolute right-0 pl-3"
        >
          {Object.keys(options)?.map((item,index) => (
            <option value={item} key={index}>
              {options[item].replace("_", " ").toLowerCase()}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default UnitsMeassureInputfield;
