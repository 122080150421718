import { IS_USER_ADMIN } from "../../actions/types";

//USER ISADMIN
export const isAdminReducer = (state = { isadmin: true }, action) => {
  switch (action.type) {
    case IS_USER_ADMIN:
      return {
        isadmin: action.payload,
      };
    default:
      return state;
  }
};
