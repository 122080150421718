import React, { useState } from "react";

const RoomCleaningTime = () => {
    const [time, setTime] = useState("10:15");

    return (
        <>
            <input type="time" className="room-cleaning-checkout" value={time} onChange={(e) => setTime(e?.target?.value)} />
        </>
    );
};

export default RoomCleaningTime;
