import React from "react";
import ToolTip from "../../components/ToolTip";
import { useTranslation } from "react-i18next";

const InputNumberOfRooms = ({ totalRooms, setTotalRooms, message }) => {
  const { t } = useTranslation();
  return (
    <div className="relative max-w-full w-full">
      <label className="text-sm font-semibold text-[#344054]">
        {t("893")}*
      </label>
      <div className="relative flex items-center w-full gap-2">
        <input
          type="number"
          onChange={(e) => setTotalRooms(+e?.target?.value)}
          value={totalRooms}
          min={0}
          max={30}
          required={true}
          className="border border-[#D0D5DD] w-full text-sm py-2 px-3 rounded-md focus:border-base-primary h-12"
        />
        <ToolTip Message={message} />
      </div>
    </div>
  );
};

export default InputNumberOfRooms;
