/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import SaveOrNot from "../../components/layouts/SaveOrNot";
import { addRoomAction, updateRoomAction } from "../../actions/pms/room";
import { connect } from "react-redux";
import AddRoomTypeCheckBox from "./RoomTypeCheckBox";
import { BED_TYPES, LENGTH_UNITS, ROOM_AMENITIES } from "../../constants";
import BackGroundLoadSpinner from "../../components/loader/BackGroundLoadSpinner";
import { getAllAssetAction } from "../../actions/pms/assetget";
import { useTranslation } from "react-i18next";
import Inputfield from "../../components/layouts";
import RoomTypeRoomNumbers from "./RoomTypeRoomNumbers";
import RoomTypeRoomNumber from "./RoomTypeRoomNumber";
import RoomTypeBedTypes from "./RoomTypeBedTypes";
import RoomTypeBeds from "./RoomTypeBeds";
import SelectFromObject from "../../components/layouts/SelectFromObject";
import { notifyError, notifySuccess } from "../../components/alert";
import { ADD_ROOM_RESET, UPDATE_ROOM_RESET } from "../../actions/types/room";
import { clearError } from "../../actions/clearError";
import { getAllRoomAction } from "../../actions/pms/roomget";
import { RxCross2 } from "react-icons/rx";
import FormSubmitButton from "../../components/buttons";
import UnitsMeassureInputfield from "../../components/layouts/UnitsMeassureInputfield";
import MultipleSelector from "../../components/layouts/MultipleSelector";

const RoomTypeComponent = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [isWithBalcony, setIsWithBalcony] = useState(false);
  const [isRoomWithAC, setIsRoomWithAC] = useState(false);
  const [smokingAllow, setSmokingAllow] = useState(false);
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(1);
  const [bedDetails, setBedDetails] = useState([
    { bedNumber: 1, details: Object.keys(BED_TYPES)[0] },
  ]);

  const [hotelRooms, setHotelRooms] = useState([]);
  const [assets, setAssets] = useState([]);
  const [ourAssets, setOurAssets] = useState([]);

  //states for images
  const [images, setImages] = useState([]);

  const [isUpdating, setIsUpdating] = useState(false);
  // Add new state
  const [roomTypeName, setRoomTypeName] = useState("");
  const [numberOfRooms, setNumberOfRooms] = useState("");
  const [numberOfBeds, setNumberOfBeds] = useState(1);
  const [roomTariff, setRoomTariff] = useState("");
  const [roomLength, setRoomLength] = useState();
  const [roomBreadth, setRoomBreadth] = useState();
  // const [addRoomAmenities, setAddRoomAmenities] = useState("");
  const [selectRoomLength, setSelectRoomLength] = useState([]);
  const [selectRoomBreadth, setSelectRoomBreadth] = useState([]);
  const [selectAmenities, setSelectAmenities] = useState([]);
  const [miniBarConfigValue, setMiniBarConfigValue] = useState([]);

  //assets
  useEffect(() => {
    if (
      props?.assets &&
      props?.assets?.assets &&
      props?.assets?.assets?.length &&
      !props?.assets?.loading
    ) {
      setOurAssets(props?.assets?.assets);
    }
  }, [props?.assets]);

  useEffect(() => {
    if (props?.hotel && props?.hotel?.hotel && props?.hotel?.hotel?._id)
      props.getAsset(props?.hotel?.hotel?._id);
  }, []);

  const saveRoomDetails = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  const closeComponent = () => props.close();

  const sumbitFromDate = async () => {
    const formData = {
      hotelId: props?.hotel?.hotel?._id,
      roomType: roomTypeName,
      isRoomWithAC,
      isWithBalcony,
      isSmokingRoom: smokingAllow,
      adults,
      children,
      net: roomTariff,
      roomLength,
      roomBreadth,
      bedDetails,
      hotelRooms,
      images,
      assets,
      ourAssets,
    };
    if (isUpdating)
      props.updateRoom({ roomId: props?.updateData?._id, ...formData });
    else props.addRoom(formData);
  };

  //props isupdating
  useEffect(() => {
    if (props?.updateData && props?.updateData?._id) {
      setIsUpdating(true);
      setIsRoomWithAC(props?.updateData?.isRoomWithAC);
      setSmokingAllow(props?.updateData?.isSmokingRoom);
      setIsWithBalcony(props?.updateData?.isWithBalcony);
      setRoomTypeName(props?.updateData?.roomType);
      setAdults(props?.updateData?.adults);
      setChildren(props?.updateData?.children);
      setRoomTariff(props?.updateData?.net);
      setRoomLength(props?.updateData?.roomLength);
      setRoomBreadth(props?.updateData?.roomBreadth);
      setBedDetails(props?.updateData?.bedDetails);
      setHotelRooms(props?.updateData?.hotelRooms);
      setNumberOfRooms(props?.updateData?.hotelRooms?.length);
      setAssets(props?.updateData?.assetsConsumptionInBooking);
      setImages(props?.updateData?.images);
    }
  }, [props?.updateData]);

  useEffect(() => {
    if (props?.update?.isupdated || props?.room?.isadded) {
      setIsUpdating(false);
      setIsRoomWithAC(false);
      setSmokingAllow(false);
      setIsWithBalcony(false);
      setRoomTypeName("");
      setAdults(0);
      setChildren(0);
      setRoomTariff(0);
      setRoomLength(0);
      setRoomBreadth(0);
      setBedDetails(0);
      setHotelRooms([]);
      setNumberOfRooms(0);
      setAssets("");
      setImages("");
    }
    if (props?.room?.isadded) {
      props?.getRooms(props?.hotel?.hotel?._id);
      notifySuccess(t("586"));
      props?.addreset();
      closeComponent();
    }

    if (props.update?.isupdated) {
      props?.getRooms(props?.hotel?.hotel?._id);
      notifySuccess(t("588"));
      props?.updatereset();
      closeComponent();
    }

    if (props?.room?.error || props?.update?.error) {
      notifyError(t("589"));
      props?.clear();
    }
  }, [props?.room, props.update]);

  return (
    <div className="relative border border-[#E5E9EB] rounded-lg bg-white  overflow-auto mx-auto mt-6 px-8 pb-6 pt-3">
      <div className="text-center font-bold text-[30px] leading-[37.8px] text-[#07090D] tracking-[-1.5%]">
        {t("69")}
      </div>
      <RxCross2
        onClick={closeComponent}
        size={26}
        className="absolute right-7 top-5 hover:rotate-90 duration-500 hover:text-[#F04438]"
      />

      <div className="text-[20px] leading-5 font-bold mt-6">{t("515")}</div>
      <form onSubmit={saveRoomDetails}>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 my-4">
          <Inputfield
            label={t("88")}
            message={t("590")}
            placeholder={t("591")}
            value={roomTypeName}
            onChange={(e) => setRoomTypeName(e.target.value)}
            required={true}
            minLength={3}
            maxLength={100}
          />

          <RoomTypeRoomNumbers
            hotelRooms={hotelRooms}
            setHotelRooms={setHotelRooms}
            value={numberOfRooms}
            setValue={setNumberOfRooms}
          />
          {[...Array(+numberOfRooms)]?.map((ro, index) => (
            <RoomTypeRoomNumber
              key={index}
              hotelRooms={hotelRooms}
              setHotelRooms={setHotelRooms}
              index={index}
            />
          ))}

          <RoomTypeBedTypes
            value={numberOfBeds}
            setValue={setNumberOfBeds}
            setBedType={setBedDetails}
            options={BED_TYPES}
          />
          {[...Array(+numberOfBeds)].map((bd, index) => (
            <RoomTypeBeds
              key={index}
              options={BED_TYPES}
              value={bedDetails}
              setValue={setBedDetails}
              index={index}
            />
          ))}

          <Inputfield
            label={t("597")}
            message={t("598")}
            placeholder={t("599")}
            value={roomTariff}
            onChange={(e) => setRoomTariff(e.target.value)}
            min={0}
            max={10000}
            required={true}
          />
        </div>
        <div className="text-[20px] leading-5 font-bold">{t("518")}</div>
        <div className="flex items-center gap-4 my-4">
          <AddRoomTypeCheckBox
            label={t("85")}
            value={isRoomWithAC}
            setValue={setIsRoomWithAC}
          />
          <AddRoomTypeCheckBox
            label={t("86")}
            value={isWithBalcony}
            setValue={setIsWithBalcony}
          />
          <AddRoomTypeCheckBox
            label={t("519")}
            value={smokingAllow}
            setValue={setSmokingAllow}
          />
        </div>

        <div className="text-[20px] leading-5 font-bold">{t("124")}</div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 my-4">
          <UnitsMeassureInputfield
            label={t("97")}
            message={t("600")}
            placeholder={t("601")}
            value={roomLength}
            onChange={(e) => setRoomLength(e.target.value)}
            min={1}
            max={100}
            required={true}
            options={LENGTH_UNITS}
            selectValue={selectRoomLength}
            onChangeSelect={(e) => setSelectRoomLength(e.target.value)}
          />
          <UnitsMeassureInputfield
            label={t("99")}
            message={t("100")}
            placeholder={t("602")}
            value={roomBreadth}
            onChange={(e) => setRoomBreadth(e.target.value)}
            min={1}
            max={100}
            required={true}
            options={LENGTH_UNITS}
            selectValue={selectRoomBreadth}
            onChangeSelect={(e) => setSelectRoomBreadth(e.target.value)}
          />
        </div>
        <div className="text-[20px] leading-5 font-bold">{t("125")}</div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 my-4">
          <Inputfield
            type="number"
            label={t("603")}
            message={t("604")}
            placeholder={t("605")}
            value={adults}
            onChange={(e) => setAdults(e.target.value)}
            min={0}
            max={4}
            required={true}
          />
          <Inputfield
            type="number"
            label={t("606")}
            message={t("607")}
            placeholder={t("608")}
            value={children}
            onChange={(e) => setChildren(e.target.value)}
            min={1}
            max={6}
            required={true}
          />
        </div>
        <div className="text-[20px] leading-5 font-bold">{t("126")}</div>
        <div className="grid grid-cols-3 items-end gap-6 mt-4">
          <MultipleSelector
            label={"Amenities"}
            options={ROOM_AMENITIES}
            message={"select amenities you have to add your hotel..."}
            selectedValue={selectAmenities}
            setSelectedValue={setSelectAmenities}
          />

          <SelectFromObject
            label={t("MiniBar Configuration")}
            value={miniBarConfigValue}
            setValue={setMiniBarConfigValue}
            toolTipMessage={t("110")}
            options={{}}
          />

          {/* <MultipleSelect  
            label={t("127")}
              value={addRoomAmenities}
              setValue={setAddRoomAmenities}
              message={t("110")}
              options={{
                one:"one",
                two:"two"
              }} /> */}
        </div>
        {/* <div className="pt-6 pb-8">
            <RoomTypeImages
              images={images}
              setImages={setImages}
              thumbnail={img}
              required={props?.updateData?.images?.length === 0}
            />
          </div> */}
        <div className="flex w-full justify-end my-6">
          <FormSubmitButton
            loading={props?.room?.loading || props?.update?.loading}
            text={t("69")}
          />
        </div>
      </form>

      {open ? (
        <SaveOrNot
          close={() => setOpen(false)}
          isopen={open}
          formSubmit={sumbitFromDate}
        />
      ) : null}
      {props?.delete && props?.delete?.loading ? (
        <BackGroundLoadSpinner />
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  room: state.AddRoom,
  update: state.UpdateRoom,
  delete: state.DeleteRoom,
  hotel: state.Hotel,
  assets: state.AllAsset,
});

const mapDispatchToProps = (dispatch) => ({
  addRoom: (data) => dispatch(addRoomAction(data)),
  updateRoom: (data) => dispatch(updateRoomAction(data)),
  getAsset: (hotelId) => dispatch(getAllAssetAction(hotelId)),
  addreset: () => dispatch({ type: ADD_ROOM_RESET }),
  updatereset: () => dispatch({ type: UPDATE_ROOM_RESET }),
  clear: () => dispatch(clearError()),
  getRooms: (hotelId) => dispatch(getAllRoomAction(hotelId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RoomTypeComponent);
