/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logoutUser } from "../actions/pms/logout";
import { authenticateHotelOwner } from "../actions/pms/ownerAuth";
import { ROUTES } from "../constants";
import { registerEvent } from "../logs";
import Logo from "./layouts/Logo";
import { useTranslation } from "react-i18next";

const Header = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { owner } = useSelector((state) => state.Authenticate);
  const { isAuthenticated } = useSelector((state) => state.LoginSignup);
  const { isAuthenticated: gAuth } = useSelector((state) => state.GoogleAuth);

  //logout
  const handleLogout = async () => {
    registerEvent("click logout button onboarding", "onboarding", { owner });
    dispatch(logoutUser());
    navigate(ROUTES.LOGIN);
  };

  useEffect(() => {
    if ((isAuthenticated && !owner) || (gAuth && !owner)) {
      dispatch(authenticateHotelOwner());
    }
  }, [isAuthenticated, owner, gAuth]);

  return (
    <>
      <header className="flex sticky top-0 left-0 w-full h-16 text-gray-700 z-[1000] bg-white border-b border-b-[#D0D5DD]">
        <div className="w-full max-w-7xl mx-auto flex items-center justify-between px-6">
          <Logo url={ROUTES.LANDING_PAGE} />
          {owner && (
            <button
              onClick={() => handleLogout()}
              className="our-button w-32 rounded-sm"
            >
              {t("992")}
            </button>
          )}
        </div>
      </header>
    </>
  );
};

export default Header;
