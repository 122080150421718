export const GET_WALLET_REQUEST = "GET_WALLET_REQUEST";
export const GET_WALLET_SUCCESS = "GET_WALLET_SUCCESS";
export const GET_WALLET_FAIL = "GET_WALLET_FAIL";

export const UPDATE_WALLET_REQUEST = "UPDATE_WALLET_REQUEST";
export const UPDATE_WALLET_SUCCESS = "UPDATE_WALLET_SUCCESS";
export const UPDATE_WALLET_FAIL = "UPDATE_WALLET_FAIL";
export const UPDATE_WALLET_RESET = "UPDATE_WALLET_RESET";

export const GET_TRANS_HISTORY_REQUEST = "GET_TRANS_HISTORY_REQUEST";
export const GET_TRANS_HISTORY_SUCCESS = "GET_TRANS_HISTORY_SUCCESS";
export const GET_TRANS_HISTORY_FAIL = "GET_TRANS_HISTORY_FAIL";
