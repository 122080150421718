import React from "react";

const AllLeavesCards = (text, value) => {
  return (
    <div className="w-full h-[101px] bg-[#F8F9FA] rounded-lg p-6">
      <div className="text-[16px] text-[#6E7C87] leading-[20.16px] font-medium tracking-[-0.8px] ">
        {text}
      </div>
      <div className="text-[20px] leading-[25.2px] tracking-[-0.8px] text-base-primary pt-1">
        {value}
      </div>
    </div>
  );
};

export default AllLeavesCards;
