import { CLEAR_ERRORS, CONFIGURATION_PERCENTAGE } from "../../actions/types";
import {
  VERIFY_PROPERTY_REQUEST,
  VERIFY_PROPERTY_SUCCESS,
  VERIFY_PROPERTY_FAIL,
  VERIFY_PROPERTY_RESET,
} from "../../actions/types/hotel";

// verify property details
export const verifyPropertyReducer = (state = { details: {} }, action) => {
  switch (action.type) {
    case VERIFY_PROPERTY_REQUEST:
      return {
        ...state,
        loading: true,
        isverified: false,
      };
    case VERIFY_PROPERTY_SUCCESS:
      return {
        ...state,
        loading: false,
        details: action.payload,
        isverified: true,
      };
    case VERIFY_PROPERTY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isverified: false,
      };
    case VERIFY_PROPERTY_RESET:
      return {
        ...state,
        loading: false,
        isverified: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

// property configuration percentage
export const propertyConfiguredPercentage = (
  state = { percentage: 0 },
  action
) => {
  switch (action.type) {
    case CONFIGURATION_PERCENTAGE:
      return {
        percentage: action.payload,
      };

    default:
      return state;
  }
};
