/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Inputfield from "../../components/layouts";

const RoomTypeRoomNumber = ({ hotelRooms, setHotelRooms, index }) => {
  const { t } = useTranslation();
  const [isValid, setIsValid] = useState(true);
  const [number, setNumber] = useState("");

  //set hotel room number
  const changeThisRoomNumber = (e) => {
    const value = e?.target?.value;
    if (value?.length > 8) return;
    hotelRooms?.length &&
      hotelRooms?.map((room) => {
        if (room?.roomNumber === value) {
          setIsValid(false);
        } else if (!isValid) {
          setIsValid(true);
        }
      });

    if (value?.length < 1) setIsValid(false);
    if (hotelRooms[index]) {
      hotelRooms[index].roomNumber = value;
      setHotelRooms(hotelRooms);
      setNumber(value);
    }
  };

  useEffect(() => {
    if (hotelRooms[index]?.roomNumber) setNumber(hotelRooms[index]?.roomNumber);
  }, [hotelRooms]);

  return (
    <Inputfield
      label={`${t(34)} ${index + 1}`}
      message={t("595")}
      placeholder={t("596")}
      value={number}
      onChange={changeThisRoomNumber}
      required={true}
    />
  );
};

export default RoomTypeRoomNumber;
