import axios from "axios";

import { TOKEN } from "../../constants";
import {
  ADD_ITEM_TO_MENU_REQUEST,
  ADD_ITEM_TO_MENU_SUCCESS,
  ADD_ITEM_TO_MENU_FAIL,
  DELETE_MENU_ITEM_REQUEST,
  DELETE_MENU_ITEM_SUCCESS,
  DELETE_MENU_ITEM_FAIL,
  GET_MENU_ITEM_REQUEST,
  GET_MENU_ITEM_SUCCESS,
  GET_MENU_ITEM_FAIL,
  UPDATE_MENU_ITEM_REQUEST,
  UPDATE_MENU_ITEM_SUCCESS,
  UPDATE_MENU_ITEM_FAIL,
} from "../typesKitchen/menu";

//add item to kitchen menu action
export const addKitchenMenuItemAction = (formData) => async (dispatch) => {
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/kitchen/menu/add-item`;
  try {
    dispatch({ type: ADD_ITEM_TO_MENU_REQUEST });
    const { data } = await axios.post(url, formData, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: ADD_ITEM_TO_MENU_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADD_ITEM_TO_MENU_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

//get kitchen menu
export const getKitchenMenuAction = (kitchenId) => async (dispatch) => {
  if(!kitchenId) return;
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/kitchen/menu?id=${kitchenId}`;
  try {
    dispatch({ type: GET_MENU_ITEM_REQUEST });
    const { data } = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: GET_MENU_ITEM_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_MENU_ITEM_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

// update kitchen menu item
export const updateMenuItemAction = (formData) => async (dispatch) => {
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/kitchen/menu/update-item`;
  try {
    dispatch({ type: UPDATE_MENU_ITEM_REQUEST });
    const { data } = await axios.put(url, formData, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: UPDATE_MENU_ITEM_SUCCESS,
      payload: data,
    });
     
  } catch (error) {
    dispatch({
      type: UPDATE_MENU_ITEM_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

//delete kitchen menu item action
export const deleteMenuItem = (kitchenId, itemId) => async (dispatch) => {
  if(!kitchenId || !itemId) return;
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/kitchen/menu/delete-item`;
  try {
    dispatch({ type: DELETE_MENU_ITEM_REQUEST });
    const { data } = await axios.delete(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
      data: { kitchenId, itemId },
    });
    dispatch({
      type: DELETE_MENU_ITEM_SUCCESS,
      payload: data,
    });
    
  } catch (error) {
    dispatch({
      type: DELETE_MENU_ITEM_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};
