import axios from "axios";
import {  TOKEN } from "../../constants";


import {
  ADD_EMPLOYEE_FAIL,
  ADD_EMPLOYEE_REQUEST,
  ADD_EMPLOYEE_SUCCESS,
  GET_ALL_EMPLOYEE_FAIL,
  GET_ALL_EMPLOYEE_REQUEST,
  GET_ALL_EMPLOYEE_SUCCESS,
  GET_EMPLOYEE_BY_ID_FAIL,
  GET_EMPLOYEE_BY_ID_REQUEST,
  GET_EMPLOYEE_BY_ID_SUCCESS,
} from "../types/employee";

//add employee
export const addEmployeeAction = (formData) => async (dispatch) => {
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/employee/add`;
  try {
    dispatch({ type: ADD_EMPLOYEE_REQUEST });
    const { data } = await axios.post(url, formData, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: ADD_EMPLOYEE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADD_EMPLOYEE_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

//get hotel all employee
export const getAllHotelEmployeeAction = (hotelId) => async (dispatch) => {
  if(!hotelId) return;
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/employee/all?hotelId=${hotelId}`;
  try {
    dispatch({ type: GET_ALL_EMPLOYEE_REQUEST });
    const { data } = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: GET_ALL_EMPLOYEE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_ALL_EMPLOYEE_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

//get hotel employee by id
export const getEmployeeById = (employeeId, hotelId) => async (dispatch) => {
  if(!hotelId || !employeeId) return;
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/employee?empId=${employeeId}&hotelId=${hotelId}`;
  try {
    dispatch({ type: GET_EMPLOYEE_BY_ID_REQUEST });
    const { data } = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: GET_EMPLOYEE_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_EMPLOYEE_BY_ID_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};
