import React from "react";
import { useState } from "react";
import { addHotelWifi, updateHotelWifi } from "../../../actions/pms/wifi";
import { connect } from "react-redux";
import {
  ADD_HOTEL_WIFI_RESET,
  UPDATE_HOTEL_WIFI_RESET,
} from "../../../actions/types/hotelWifi";
import { useEffect } from "react";
import { registerEvent } from "../../../logs";
import { isObjEmpty } from "../../../utils";
import { useTranslation } from "react-i18next";
import Inputfield from "../../../components/layouts";
import AddSubmitButton from "../../../components/buttons/AddSubmitButton";
import { RxCross2 } from "react-icons/rx"; 
import SelectFromObject from "../../../components/layouts/SelectFromObject";

const WifiAddUpdate = (props) => {
  const { t } = useTranslation();
  const [ssid, setSsid] = useState("");
  const [password, setPassword] = useState("");
  const [dailyUsesQuota, setDailyUsesQuota] = useState("");
  const [speedRestriction, setSpeedRestriction] = useState("");
  const [speedIn, setSpeedIn] = useState([]);

  //add wifi for a hotel
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      hotelId: props?.hotel?._id,
      wifiDetails: {
        ssid,
        password,
        dailyUsesQuota,
        speedRestriction,
        speedIn,
      },
    };
    if (isObjEmpty(props?.updateData)) {
      registerEvent("add wifi details", "wifi details", { data });
      await props?.addWifi(data);
    } else {
      registerEvent("update wifi details", "wifi details", { data });
      await props?.update(data);
    }
  };

  //success / error
  useEffect(() => {
    if (
      (props?.wifi && props?.wifi?.isadded) ||
      (props?.wifi && props?.wifi?.error)
    ) {
      props?.reset();
    }
  }, [props.wifi]);

  //updateData
  useEffect(() => {
    if (!isObjEmpty(props?.updateData)) {
      setSsid(props?.updateData?.ssid);
      setPassword(props?.updateData?.password);
      setDailyUsesQuota(props?.updateData?.dailyUsesQuota);
      setSpeedRestriction(props?.updateData?.speedRestriction);
      setSpeedIn(props?.updateData?.speedIn);
    }
  }, [props?.updateData]);

  return (
    <>
      <form
        className={` bg-white rounded-lg mx-auto border relative p-6 my-6 ${
          props?.open ? "" : "hidden"
        }`}
        onSubmit={handleSubmit}
      >
        <div className="flex items-center justify-between">
          <div className="text-center text-[30px] leading-[37.8px] tracking-[-1.5%] font-bold">
            {t("454")}
          </div>
          <button
            className={`${
              !props?.open ? "hidden" : "hover:rotate-90 duration-200"
            } `}
            onClick={() => props?.close()}
          >
            <RxCross2
              size={26}
              className="hover:rotate-90 duration-500 hover:text-[#F04438]"
            />
          </button>
        </div>

        <div className="w-full grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-6 grid-w-full mt-4">
          <Inputfield
            placeholder={t("387")}
            value={ssid}
            onChange={(e) => setSsid(e?.target?.value)}
            label={t("455")}
            maxLength={40}
            message={t("751")}
            required={true}
          />
          <Inputfield
            placeholder={t("1114")}
            value={password}
            onChange={(e) => setPassword(e?.target?.value)}
            label={t("211")}
            maxLength={40}
            minLength={8}
            required={true}
            message={t("752")}
          />
          {/* <Inputfield
            placeholder={t("1115")}
            value={dailyUsesQuota}
            onChange={(e) => setDailyUsesQuota(e?.target?.value)}
            label={t("457")}
            max={1000}
            min={10}
            message={t("753")}
            required={true}
            type="number"
          /> */}
          <SelectFromObject
            label={t("1115")}
            toolTipMessage={t("737")}
            options={{}}
            value={dailyUsesQuota}
            setValue={setDailyUsesQuota}
            required={true}
          />
          {/* <Inputfield
            placeholder={t("1116")}
            value={speedRestriction}
            onChange={(e) => setSpeedRestriction(e?.target?.value)}
            label={t("458")}
            min={1}
            max={10}
            message={t("754")}
            type="number"
            required={true}
          /> */}
          {/* <TwoInOneInputFields
            label={t("458")}
            placeholder={t("Enter a Value")}
            value={speedRestriction}
            onChange={(e) => setSpeedRestriction(e?.target?.value)}
            min={1}
            max={10}
            message={t("754")}
            required={true}
            options={{}}
            selectValue={speedIn}
            onChangeSelect={(e) => setSpeedIn(e.target.value)}
          /> */}
        </div>
        <div className="flex justify-end relative text-white mt-6">
          <AddSubmitButton
            text={isObjEmpty(props?.updateData) ? "Add Wifi" : "Update Wifi"}
            loading={props?.wifi?.loading || props?.update?.loading}
          />
        </div>
      </form>
    </>
  );
};

const mapStateToProps = (state) => ({
  wifi: state.AddWifi,
  updated: state.UpdateWifi,
});

const mapDispatchToProps = (dispatch) => ({
  addWifi: (data) => dispatch(addHotelWifi(data)),
  reset: () =>
    dispatch({ type: ADD_HOTEL_WIFI_RESET }, { type: UPDATE_HOTEL_WIFI_RESET }),
  update: (data) => dispatch(updateHotelWifi(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WifiAddUpdate);
