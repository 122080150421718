import React, { useState } from "react";
import { CustomeTable } from "../../components";
import Assign from "./Assign";
import RoomCleaningStatus from "./RoomCleaningStatus";
import UploadButton from "../../components/buttons/UploadButton";
import DownloadButton from "../../components/buttons/DownloadButton";

const IssueReported = () => {
  const [selectedRows, setSelectedRows] = useState([]);
  const rowData = [
    {
      _id: "100",
      room_no: "103",
      report_type: "House Keeping",
      issue: "Dirty Towel",
      reporting_time: "10:15 AM",
      date: "2023-10-10",
      assign_to: "assign",
      status: "Resolved",
    },
    {
      _id: "101",
      room_no: "103",
      report_type: "Maintenance",
      issue: "Broken Lamp",
      reporting_time: "10:15 AM",
      date: "2023-10-10",
      assign_to: "E023 Masud , E024 Rajot",
      status: "In Progress",
    },
    {
      _id: "102",
      room_no: "103",
      report_type: "Security",
      issue: "Lost Key Card",
      reporting_time: "10:15 AM",
      date: "2023-10-10",
      assign_to: "assign",
      status: "Pending",
    },
    {
      _id: "103",
      room_no: "103",
      report_type: "House Keeping",
      issue: "Room Service",
      reporting_time: "10:15 AM",
      date: "2023-10-10",
      assign_to: "E023 Masud , E024 Rajot",
      status: "Resolved",
    },
    {
      _id: "104",
      room_no: "103",
      report_type: "Maintenance",
      issue: "Room Service",
      reporting_time: "10:15 AM",
      date: "2023-10-10",
      assign_to: "assign",
      status: "In Progress",
    },
  ];

  const columnDef = [
    {
      headerName: "Room ID",
      field: "_id",
      maxWidth: 150,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Room No",
      field: "room_no",
      minWidth: 150,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "report type",
      field: "report_type",
      maxWidth: 150,
      filter: true,
      floatingFilter: true,
    },
    { headerName: "issue", field: "issue", filter: true, floatingFilter: true },
    {
      headerName: "reporting_time",
      field: "reporting_time",
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "date",
      field: "date",
      minWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "assign to",
      field: "assign_to",
      cellRenderer: Assign,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "status",
      field: "status",
      cellRenderer: RoomCleaningStatus,
      filter: true,
      floatingFilter: true,
    },
  ];

  return (
    <div className="">
      <div className="flex items-center justify-between mb-4">
        <div className="text-[22px] leading-[27.72px] text-black font-bold">Issue Reported Table</div>
        <div className="flex items-center gap-4">
          <UploadButton />
          <DownloadButton />
        </div>
      </div>
      <CustomeTable
        rowData={rowData}
        columnDefs={columnDef}
        setSelectedRows={setSelectedRows}
      />
    </div>
  );
};

export default IssueReported;
