import axios from "axios";
import { TOKEN } from "../../constants";
import {
  ASSET_REPORT_FOR_ALL_FAIL,
  ASSET_REPORT_FOR_ALL_REQ,
  ASSET_REPORT_FOR_ALL_SUC,
  ASSET_REPORT_FOR_ONE_REQ,
} from "../types/reportAssets";

//get assets report for all time
export const assetsReportAllTime = (hotelId) => async (dispatch) => {
  if(!hotelId) return;
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/reports/assets/all`;
  try {
    dispatch({ type: ASSET_REPORT_FOR_ALL_REQ });
    const data = await axios.post(
      url,
      { hotelId },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem(TOKEN),
        },
        withCredentials: true,
      }
    );
    dispatch({
      type: ASSET_REPORT_FOR_ALL_SUC,
      payload: data.data,
    });
  } catch (error) {
    dispatch({
      type: ASSET_REPORT_FOR_ALL_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

//get assets report for all time for one asset
export const assetsReportAllTimeForOne =
  (hotelId, assetId) => async (dispatch) => {
    if(!hotelId || !assetId) return;
    const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/reports/assets/all`;
    try {
      dispatch({ type: ASSET_REPORT_FOR_ONE_REQ });
      const data = await axios.post(
        url,
        { hotelId, assetId },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-auth-token": localStorage.getItem(TOKEN),
          },
          withCredentials: true,
        }
      );
      dispatch({
        type: ASSET_REPORT_FOR_ALL_SUC,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: ASSET_REPORT_FOR_ALL_FAIL,
        payload: error?.response?.data?.message,
      });
    }
  };
