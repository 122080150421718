/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { isObjEmpty } from "../../utils";
import { CustomeTable } from "../../components";

const Ingredients = (props) => {
  const [datas, setDatas] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    if (props?.kitchen && !isObjEmpty(props?.kitchen?.kitchen)) {
      setDatas([
        {
          //   Kitchen_id: "1214",
          itemId: props?.kitchen?.kitchen?.itemId,
          ingredientName: props?.kitchen?.kitchen?.ingredientName,
          ingredientType: props?.kitchen?.kitchen?.ingredientType,
          changeInPrice: props?.kitchen?.kitchen?.changeInPrice,
          storageType: props?.kitchen?.kitchen?.storageType,
          availableQuantity: props?.kitchen?.kitchen?.availableQuantity,
          shelfLife: props?.kitchen?.kitchen?.shelfLife,
          purchaseFrequency: props?.kitchen?.kitchen?.purchaseFrequency,
          vendorId: props?.kitchen?.kitchen?.vendorId,
          //   _id: props?.kitchen?.kitchen?._id,
        },
      ]);
    }
  }, [props?.kitchen]);
  const CurrentAvailableQuant = ({ value }) => {
    return (
      <>
        {value === "Out of stock" ? (
          <div className="text-[14px] leading-6 font-normal tracking-[-0.6%] text-[#FF2424] mt-2">
            {value}
          </div>
        ) : (
          <div>{value}</div>
        )}
      </>
    );
  };
  const tableData = [
    {
      itemId: "IN01",
      ingredientName: "Salt",
      ingredientType: "Grocery",
      changeInPrice: "+2%",
      storageType: "Dry",
      availableQuantity: "2 Kg",
      shelfLife: "6 months",
      purchaseFrequency: "Weekly",
      vendorId: "V01,V02",
    },
    {
      itemId: "IN02",
      ingredientName: "Cheese",
      ingredientType: "Dairy",
      changeInPrice: "-10%",
      storageType: "Refrigerator",
      availableQuantity: "4 Kg",
      shelfLife: "12 days",
      purchaseFrequency: "Daily",
      vendorId: "V01,V02",
    },
    {
      itemId: "IN03",
      ingredientName: "Butter",
      ingredientType: "Dairy",
      changeInPrice: "+1%",
      storageType: "Refrigerator",
      availableQuantity: "5 Kg",
      shelfLife: "16 days",
      purchaseFrequency: "Daily",
      vendorId: "V012,V024,V079",
    },
    {
      itemId: "IN04",
      ingredientName: "Tomato",
      ingredientType: "Vegetable",
      changeInPrice: "+10%",
      storageType: "Refrigerator",
      availableQuantity: "8 Kg",
      shelfLife: "1 week",
      purchaseFrequency: "Monthly",
      vendorId: "V031",
    },
    {
      itemId: "IN05",
      ingredientName: "Potato",
      ingredientType: "Vegetable",
      changeInPrice: "-5%",
      storageType: "Dry",
      availableQuantity: "Out of stock",
      shelfLife: "2 Weeks",
      purchaseFrequency: "Monthly",
      vendorId: "V01,V02",
    },
  ];

  const columnDef = [
    {
      headerName: "Item Id",
      field: "itemId",
      maxWidth: 30,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Ingredient Name",
      field: "ingredientName",
      minWidth: 150,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Ingredient Type",
      field: "ingredientType",
      maxWidth: 150,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Change In Price",
      field: "changeInPrice",
      maxWidth: 150,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Storage Type",
      field: "storageType",
      maxWidth: 150,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Available Quantity",
      field: "availableQuantity",
      maxWidth: 150,
      filter: true,
      floatingFilter: true,
      cellRenderer: CurrentAvailableQuant,
    },
    {
      headerName: "Shelf Life",
      field: "shelfLife",
      maxWidth: 150,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Purchase Frequency",
      field: "purchaseFrequency",
      maxWidth: 150,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Vendor Id",
      field: "vendorId",
      maxWidth: 150,
      filter: true,
      floatingFilter: true,
    },
  ];
  return (
    <div>
      {/* {datas.length ? ( */}
      <CustomeTable
        rowData={tableData}
        columnDefs={columnDef}
        setSelectedRows={setSelectedRows}
      />
      {/* ) : null} */}
    </div>
  );
};

export default Ingredients;
