import React, { useState } from "react";
import PropertyConfigTopButton from "./TabButton";
import { useTranslation } from "react-i18next";
// import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";

const SettingsHeader = ({ params }) => {
  const { t } = useTranslation();
  const [current, setCurrent] = useState(0);

  // const previouse = (index) => {
  //   if (current === 0) {
  //     setCurrent(index.length - 1);
  //   } else {
  //     setCurrent(current - 1);
  //   }
  //   console.log("check index of previous button :", index);
  // };
  // const forward = (index) => {
  //   if (current === index.length - 1) {
  //     setCurrent(0);
  //   } else {
  //     setCurrent(current + 1);
  //   }
  //   console.log("check index of forward button :", index);
  // };

  return (
    <div className="relative w-full grid grid-cols-6 gap-5 my-4">
      {/* previouse */}
      {/* <button
        type="button"
        onClick={previouse}
        className="absolute left-0 top-2 flex items-center justify-center size-9 border-[0.9px] border-[#D0D5DD] rounded-[3.6px] bg-white shadow-[0_3.6px_18px_0_rgba(0,0,0,0.06)] text-[#8A8A8A] transition-all duration-[0.3s] ease-[0s] hover:opacity-50 z-20"
      >
        <IoIosArrowBack size={26} />
      </button> */}
      {/* <div className="flex gap-4 items-center" style={{
      transform: `translateX(-${current * 100}px)`,
      transition: `all 0.4s ease-in`,
    }}> */}

      <PropertyConfigTopButton 
      name={t("459")} 
      index={1} 
      params={params} 
      />
      <PropertyConfigTopButton
        name={t("Property Info")}
        index={2}
        params={params}
      />
      <PropertyConfigTopButton
        name={t("Property Contact")}
        index={3}
        params={params}
      />
      <PropertyConfigTopButton
        name={t("Payment Modes")}
        index={4}
        params={params}
      />
      <PropertyConfigTopButton
        name={t("Add Bank Account")}
        index={5}
        params={params}
      />
      <PropertyConfigTopButton
        name={t("Auto Sync Booking")}
        index={6}
        params={params}
      />
      <PropertyConfigTopButton name={t("463")} index={7} params={params} />
      <PropertyConfigTopButton name={t("464")} index={8} params={params} />
      <PropertyConfigTopButton name={t("Wifi Config")} index={9} params={params} />
      <PropertyConfigTopButton
        name={t("Employee Permission")}
        index={10}
        params={params}
      />
      <PropertyConfigTopButton
        name={t("Login Credentials")}
        index={11}
        params={params}
      />

      {/* </div> */}
      {/* <button
        type="button"
        onClick={forward}
        className="absolute right-0 top-2 flex items-center justify-center size-9 border-[0.9px] border-[#D0D5DD] rounded-[3.6px] bg-white shadow-[0_3.6px_18px_0_rgba(0,0,0,0.06)] text-[#8A8A8A] transition-all duration-[0.3s] ease-[0s] hover:opacity-50 z-20"
      >
        <IoIosArrowForward size={26} />
      </button> */}
    </div>
  );
};

export default SettingsHeader;
