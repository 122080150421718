// assets report for all assets for all time
export const ASSET_REPORT_FOR_ALL_REQ = "ASSET_REPORT_FOR_ALL_REQ";
export const ASSET_REPORT_FOR_ALL_SUC = "ASSET_REPORT_FOR_ALL_SUC";
export const ASSET_REPORT_FOR_ALL_FAIL = "ASSET_REPORT_FOR_ALL_FAIL";

// assets report for one asset for all time
export const ASSET_REPORT_FOR_ONE_REQ = "ASSET_REPORT_FOR_ONE_REQ";
export const ASSET_REPORT_FOR_ONE_SUC = "ASSET_REPORT_FOR_ONE_SUC";
export const ASSET_REPORT_FOR_ONE_FAIL = "ASSET_REPORT_FOR_ONE_FAIL";

// assets report for all assets by date
export const ASSET_REPORT_FOR_ALL_BY_DATE_REQ =
  "ASSET_REPORT_FOR_ALL_BY_DATE_REQ";
export const ASSET_REPORT_FOR_ALL_BY_DATE_SUC =
  "ASSET_REPORT_FOR_ALL_BY_DATE_SUC";
export const ASSET_REPORT_FOR_ALL_BY_DATE_FAIL =
  "ASSET_REPORT_FOR_ALL_BY_DATE_FAIL";

// assets report for one asset by date
export const ASSET_REPORT_FOR_ONE_BY_DATE_REQ =
  "ASSET_REPORT_FOR_ONE_BY_DATE_REQ";
export const ASSET_REPORT_FOR_ONE_BY_DATE_SUC =
  "ASSET_REPORT_FOR_ONE_BY_DATE_SUC";
export const ASSET_REPORT_FOR_ONE_BY_DATE_FAIL =
  "ASSET_REPORT_FOR_ONE_BY_DATE_FAIL";

// assets report for all assets by month
export const ASSET_REPORT_FOR_ALL_BY_MONTH_REQ =
  "ASSET_REPORT_FOR_ALL_BY_MONTH_REQ";
export const ASSET_REPORT_FOR_ALL_BY_MONTH_SUC =
  "ASSET_REPORT_FOR_ALL_BY_MONTH_SUC";
export const ASSET_REPORT_FOR_ALL_BY_MONTH_FAIL =
  "ASSET_REPORT_FOR_ALL_BY_MONTH_FAIL";

// assets report for one asset by month
export const ASSET_REPORT_FOR_ONE_BY_MONTH_REQ =
  "ASSET_REPORT_FOR_ONE_BY_MONTH_REQ";
export const ASSET_REPORT_FOR_ONE_BY_MONTH_SUC =
  "ASSET_REPORT_FOR_ONE_BY_MONTH_SUC";
export const ASSET_REPORT_FOR_ONE_BY_MONTH_FAIL =
  "ASSET_REPORT_FOR_ONE_BY_MONTH_FAIL";

// assets report for all assets by year
export const ASSET_REPORT_FOR_ALL_BY_YEAR_REQ =
  "ASSET_REPORT_FOR_ALL_BY_YEAR_REQ";
export const ASSET_REPORT_FOR_ALL_BY_YEAR_SUC =
  "ASSET_REPORT_FOR_ALL_BY_YEAR_SUC";
export const ASSET_REPORT_FOR_ALL_BY_YEAR_FAIL =
  "ASSET_REPORT_FOR_ALL_BY_YEAR_FAIL";

// assets report for one asset by year
export const ASSET_REPORT_FOR_ONE_BY_YEAR_REQ =
  "ASSET_REPORT_FOR_ONE_BY_YEAR_REQ";
export const ASSET_REPORT_FOR_ONE_BY_YEAR_SUC =
  "ASSET_REPORT_FOR_ONE_BY_YEAR_SUC";
export const ASSET_REPORT_FOR_ONE_BY_YEAR_FAIL =
  "ASSET_REPORT_FOR_ONE_BY_YEAR_FAIL";
