/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { connect, useSelector } from "react-redux";
import { isObjEmpty } from "../../utils";
import { registerEvent } from "../../logs";
import AddKitchen from "./AddKitchen";
import AllKitchen from "./AllKitchen";
import { useTranslation } from "react-i18next";
import AddButton from "../../components/buttons/AddButton";
import Seperator from "../../components/layouts/Seperator";
import { getKitchenByHotelIdAction } from "../../actions/kitchen/kitchen";
import { useEffect } from "react";
import DeleteButton from "../../components/buttons/DeleteButton";
import EditButton from "../../components/buttons/EditButton";

const Kitchen = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [kitchen, setKitchen] = useState(null);
  const { hotel } = useSelector((state) => state.Hotel);
  //open modal
  const openModal = () => {
    setOpen(true);
    registerEvent(t("open modal"), t("add kitchen"));
  };

  //close modal
  const closeModal = () => setOpen(false);

  useEffect(() => {
    if (hotel?._id) props?.getKitchen(hotel?._id);
  }, [hotel?._id]);

  const data = {
    heading: t("36"),
    seperator: [
      {
        text: t("134"),
      },
      {
        text: t("36"),
      },
    ],
  };

  return (
    <>
      <div className="page-container max-w-7xl">
        <div className="w-full max-w-full">
          <div className="flex justify-between items-end">
            <Seperator data={data} />
            <div className="flex gap-2">
              <EditButton />
              <DeleteButton />
              <AddButton text={t("265")} onClick={openModal} />
            </div>
          </div>
          {isObjEmpty(kitchen) && open ? (
            <div className="mt-6">
              <AddKitchen close={closeModal} hotel={props?.hotel?.hotel} />
            </div>
          ) : null}
        </div>
        <div className="mt-6">
          <AllKitchen setKitchen={setKitchen} hotel={props?.hotel?.hotel} />
        </div>
      </div>
      {/* <AddMenuItem /> */}
    </>
  );
};

const mapStateToProps = (state) => ({
  hotel: state.Hotel,
  kitchen: state.Kitchen,
});

const mapDispatchToProps = (dispatch) => ({
  getKitchen: (id) => dispatch(getKitchenByHotelIdAction(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Kitchen);
