import React from 'react'

const CancelButton = ({onClick=()=>{}}) => {
  return (
    <button
    onClick={onClick}
    type="button"
    className="w-[93px] h-[37px] bg-[#F6F6F6] hover:bg-slate-300 rounded-md shadow-[0_1px_2px_0_rgba(16,24,40,0.05)] text-[14px] leading-[22px] font-normal text-[#07090D] transition-all duration-[0.3s] ease-[0s]"
  >
    Cancel
  </button>
  )
}

export default CancelButton