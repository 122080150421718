import { CLEAR_ERRORS } from "../../actions/types";
import {
  VERIFY_EMAIL_FAIL,
  VERIFY_EMAIL_REQ,
  VERIFY_EMAIL_SUC,
} from "../../actions/types/ownerAuth";

// verify owner email
export const verifyEmailReducer = (state = { token: "" }, action) => {
  switch (action.type) {
    case VERIFY_EMAIL_REQ:
      return {
        ...state,
        loading: true,
      };
    case VERIFY_EMAIL_SUC:
      return {
        ...state,
        loading: false,
        token: action.payload,
      };
    case VERIFY_EMAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        token: null,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
