import React, { useState } from "react";
import { CustomeTable } from "../../components";
import Assign from "./Assign";
import RoomCleaningTime from "./RoomCleaningTime";
import RoomCleaningStatus from "./RoomCleaningStatus";
import UploadButton from "../../components/buttons/UploadButton";
import DownloadButton from "../../components/buttons/DownloadButton";

const RoomServiceRequest = () => {
  const [selectedRows, setSelectedRows] = useState([]);

  const rowData = [
    {
      _id: "100",
      room_no: "103",
      request_time: "10:00 AM",
      request_for: "Room Service",
      assign_to: "assign",
      assign_time: "10:15 AM",
      status: "completed",
      completion_time: "10:30 AM",
    },
    {
      _id: "101",
      room_no: "103",
      request_time: "10:00 AM",
      request_for: "Room Service",
      assign_to: "E023 Masud , E024 Rajot",
      assign_time: "10:15 AM",
      status: "Pending",
      completion_time: "10:30 AM",
    },
    {
      _id: "102",
      room_no: "103",
      request_time: "10:00 AM",
      request_for: "Room Service",
      assign_to: "assign",
      assign_time: "10:15 AM",
      status: "completed",
      completion_time: "10:30 AM",
    },
    {
      _id: "103",
      room_no: "103",
      request_time: "10:00 AM",
      request_for: "Room Service",
      assign_to: "E023 Masud , E024 Rajot",
      assign_time: "10:15 AM",
      status: "In Progress",
      completion_time: "10:30 AM",
    },
    {
      _id: "104",
      room_no: "103",
      request_time: "10:00 AM",
      request_for: "Room Service",
      assign_to: "assign",
      assign_time: "10:15 AM",
      status: "Pending",
      completion_time: "10:30 AM",
    },
  ];
  const columnDef = [
    {
      headerName: "Room ID",
      field: "_id",
      maxWidth: 150,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Room No",
      field: "room_no",
      minWidth: 200,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "request time",
      field: "request_time",
      maxWidth: 150,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "request for",
      field: "request_for",
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "assign to",
      field: "assign_to",
      cellRenderer: Assign,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "assign time",
      field: "assign_time",
      cellRenderer: RoomCleaningTime,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "status",
      field: "status",
      cellRenderer: RoomCleaningStatus,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "completion time",
      field: "completion_time",
      filter: true,
      floatingFilter: true,
    },
  ];

  return (
    <div>
      <div className="">
        <div className="flex items-center justify-between mb-4">
          <div className="text-[22px] leading-[27.72px] text-black font-bold">Room Service Request Table</div>
          <div className="flex items-center gap-4">
            <UploadButton />
            <DownloadButton />
          </div>
        </div>
      </div>
      <div className="">
        <CustomeTable
          rowData={rowData}
          columnDefs={columnDef}
          setSelectedRows={setSelectedRows}
        />
      </div>
    </div>
  );
};

export default RoomServiceRequest;
