//@validate email
export const validateEmail = (email) => {
  if (!email) {
    return "Email is required!";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
    return "Please enter a valid email!";
  }
};

//@validate auth name
export const validateName = (name) => {
  if (!name) {
    return "Name is required!";
  } else if (name.length < 3) {
    return `Name must be 3 letters!`;
  } else if (name.length > 30) {
    return `Name must be less than 30 letters!`;
  }
};

//@password validation
export const validatePassword = (password) => {
  if (!password) {
    return "Password is required!";
  } else if (password.length < 6) {
    return `Password must be 6 letters!`;
  } else if (password.length > 15) {
    return `Password must be less than 15 letters!`;
  }
};

//@confirm password validation
export const validateConfirmPassword = (password, confirmPassword) => {
  if (!confirmPassword) {
    return "Confirm Password is required!";
  } else if (password !== confirmPassword) {
    return "Password does not match!";
  }
};

//@check email formate is valid or not
export const validateEmailFormate = (email) => {
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
    return false;
  } else return true;
};
