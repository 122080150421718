import React, { Suspense } from "react";
import App from "./App";
import { createRoot } from "react-dom/client";
import "./index.css";
import { Provider } from "react-redux";
import store from "./store";
import "./i18n";

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  // update redux to redux toolkit 
  <Provider store={store}>
    <Suspense fallback="">
      <App />
    </Suspense>
  </Provider>
);
