import React from "react";

const SkipButton = ({ text, onClick }) => {
  return (
    <button
      type="button"
      className="w-fit h-10 px-6 capitalize rounded-md bg-[#FFFFFF] hover:bg-slate-300 text-black text-[16px] leading-5 font-medium border border-[#E5E9EB] flex justify-center items-center transition-all duration-[0.3s] ease-[0s]"
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default SkipButton;
