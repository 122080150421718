import axios from "axios";
import { TOKEN } from "../../constants";
import {
  ADD_ROOM_FAIL,
  ADD_ROOM_REQUEST,
  ADD_ROOM_SUCCESS,
  UPDATE_ROOM_FAIL,
  UPDATE_ROOM_REQUEST,
  UPDATE_ROOM_SUCCESS,
} from "../types/room";

// add a room
export const addRoomAction = (formData) => async (dispatch) => {
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/rooms/add`;
  try {
    dispatch({ type: ADD_ROOM_REQUEST });
    const { data } = await axios.post(url, formData, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: ADD_ROOM_SUCCESS,
      payload: data.rooms,
    });
  } catch (error) {
    dispatch({
      type: ADD_ROOM_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

//update rooms
export const updateRoomAction = (formData) => async (dispatch) => {
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/owner/rooms/update`;
  try {
    dispatch({ type: UPDATE_ROOM_REQUEST });
    const { data } = await axios.put(url, formData, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: UPDATE_ROOM_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_ROOM_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};
