import React, { useState } from "react";
import { IoMdPlay } from "react-icons/io";
const Player = ({ videoURL }) => {
  const [isVideo, setIsVideo] = useState(false);
  return (
    <div className="">
      <div className="text-[20px] leading-[30.99px] font-normal text-center my-2">
        To start the lesson's video, choose the play button.
      </div>
      <div className="h-[377px] rounded-[10px] bg-[#9A9A9A] flex items-center justify-center">
        <IoMdPlay
          size={150}
          className="text-white h-fit w-fit hover:text-slate-500 cursor-pointer z-20"
          onClick={() => setIsVideo(!isVideo)}
        />
      </div>
    </div>
  );
};

export default Player;
