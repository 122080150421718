import React from "react";
import ToolTip from "../ToolTip";

const InputTextArea = ({
  label,
  value,
  onChange,
  maxLength,
  minLength,
  message,
  placeholder,
  utility,
  required = false,
  readOnly = false,
}) => {
  return (
    <div className="w-full">
      <div className="flex items-center justify-between">
        <label className="text-sm font-semibold text-[#344054]">{label}</label>
        <ToolTip Message={message} />
      </div>
      <textarea
        className={`border border-[#D0D5DD] w-full text-sm p-3 rounded-md focus:border-base-primary resize-none ${utility}`}
        value={value}
        onChange={onChange}
        maxLength={maxLength}
        minLength={minLength}
        placeholder={placeholder}
        required={required}
        readOnly={readOnly}
      />
    </div>
  );
};

export default InputTextArea;
