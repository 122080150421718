/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
// import { useTranslation } from "react-i18next";
import {
  assetsReportAllTime,
  assetsReportAllTimeForOne,
} from "../../actions/reports/assetsReportAllTime";
import { assetsReportAllTimeByDate } from "../../actions/reports/assetsReportByDate";
import ReportsCard from "./ReportsCard";
import salaryIcon from "../../images/icons/salaryIcon.svg";
import higestPaidIcon from "../../images/icons/higestPaidIcon.svg";
import lowestPaidIcon from "../../images/icons/lowestPaidIcon.svg";
import avgSalaryIcon from "../../images/icons/avgSalaryIcon.svg";
import CustomeTable from "../../components/CustomeTable";
import DownloadButton from "../../components/buttons/DownloadButton";

const EmployeeSalaryReports = (props) => {
  // const { t } = useTranslation();
  const [datas, setDatas] = useState([]);
  const { sales } = useSelector((state) => state.SalesReport);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    props.getAssetsFromToDateAll(
      props?.hotel?._id,
      props?.fromDate,
      props?.toDate
    );
  }, [props?.fromDate, props?.toDate]);

  useEffect(() => {
    if (sales && Array.isArray(sales) && sales?.length) {
      let expense = sales?.map((d) => {
        return {
          employeeID: d?.employeeID,
          employeeName: d?.employeeName,
          designation: d?.designation,
          department: d?.department,
          salary: d?.salary,
          // type: d?.type,
          // _id: d?._id,
        };
      });
      setDatas(expense);
    }
  }, [sales]);

  const tableData = [
    {
      employeeID: "EX001",
      employeeName: "John Doe",
      designation: "Room Inspector",
      department: "House Keeping",
      salary: "$500",
    },
    {
      employeeID: "EX001",
      employeeName: "John Doe",
      designation: "Room Inspector",
      department: "House Keeping",
      salary: "$500",
    },
    {
      employeeID: "EX001",
      employeeName: "John Doe",
      designation: "Room Inspector",
      department: "House Keeping",
      salary: "$500",
    },
    {
      employeeID: "EX001",
      employeeName: "John Doe",
      designation: "Room Inspector",
      department: "House Keeping",
      salary: "$500",
    },
    {
      employeeID: "EX001",
      employeeName: "John Doe",
      designation: "Room Inspector",
      department: "House Keeping",
      salary: "$500",
    },
  ];
  const columnDefs = [
    {
      headerName: "Employee ID",
      field: "employeeID",
      maxWidth: 150,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Employee Name",
      field: "employeeName",
      maxWidth: 300,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Designation",
      field: "designation",
      maxWidth: 300,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Department",
      field: "department",
      maxWidth: 300,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Salary",
      field: "salary",
      maxWidth: 300,
      filter: true,
      floatingFilter: true,
    },
  ];
  return (
    <>
      <div className="w-full mt-4">
        <div className="my-4 flex items-center gap-3">
          <ReportsCard
            utility={"w-[234px]"}
            icon={salaryIcon}
            iconBgColor={"bg-[#F9F5FF]"}
            heading={"Total Salary"}
            value={"1258"}
          />
          <ReportsCard
            utility={"w-[325px]"}
            icon={higestPaidIcon}
            iconBgColor={"bg-[#F1FADF]"}
            heading={"Highest Paid Employee"}
            value={"800"}
          />
          <ReportsCard
            utility={"w-[325px]"}
            icon={lowestPaidIcon}
            iconBgColor={"bg-[#ECFDF3]"}
            heading={"Lowest Paid Employee"}
            value={"425"}
          />
          <ReportsCard
            utility={"w-[262px]"}
            icon={avgSalaryIcon}
            iconBgColor={"bg-[#E8EBFF]"}
            heading={"Average Salary"}
            value={"425"}
          />
        </div>
        <div className="flex items-center justify-between my-4">
          <h2 className="text-[24px] leading-6 font-bold">Employee Salary Report</h2>
          <DownloadButton />
        </div>
        {/* {datas && datas?.length ? ( */}
        <CustomeTable
          columnDefs={columnDefs}
          rowData={tableData}
          setSelectedRows={setSelectedRows}
        />
        {/* ) : null} */}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  data: state.AssetsReport,
});

const mapDispatchToProps = (dispatch) => ({
  getAllTimeAsset: (hotelId) => dispatch(assetsReportAllTime(hotelId)),
  //get assets report by date
  getAssetsReportAll: (hotelId, assetId) =>
    dispatch(assetsReportAllTimeForOne(hotelId, assetId)),
  getAssetsFromToDateAll: (hotelId, fromDate, toDate) =>
    dispatch(assetsReportAllTimeByDate(hotelId, fromDate, toDate)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeSalaryReports);
