/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"; 
import Step0 from "./Step0";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";

import { connect, useSelector } from "react-redux";
import PageLoader from "../../components/loader/PageLoader";
import MetaData from "../../utils/MetaData";
import { ONBOARDING_STAGES, PROPERTY_ID } from "../../constants";

import {
  GET_HOTEL_SUCCESS,
  UPDATE_HOTEL_RESET,
} from "../../actions/types/hotel";
import { getHotelAction } from "../../actions/pms/hotel"; 
import receptionhall from "../../images/AppImages/receptionhall.png";
import ApprovalPending from "./ApprovalPending";
import { useTranslation } from "react-i18next";

const GetStarted = (props) => {
  const { t } = useTranslation();
  const [completeStep, setCompleteStep] = useState(0);
  const { loading, owner } = useSelector((state) => state.Authenticate);
  const { hotel } = useSelector((state) => state.Hotel);

  //get local step done
  const getLocalStepsDone = async () => {
    const hotelId = localStorage.getItem(PROPERTY_ID);
    if (hotelId && !hotel?._id) props.getHotel(hotelId);
  };
  useEffect(() => {
    getLocalStepsDone();
  }, []);

  useEffect(() => {
    console.log(props?.hotel);
    if (hotel?.stage === ONBOARDING_STAGES.ONBOARDING) setCompleteStep(0);
    if (hotel?.stage === ONBOARDING_STAGES.CONTACT) setCompleteStep(2);
    if (hotel?.stage === ONBOARDING_STAGES.AMENITY) setCompleteStep(3);
    if (hotel?.stage === ONBOARDING_STAGES.IDENTITY_SEARCH) setCompleteStep(4);
    if (hotel?.stage === ONBOARDING_STAGES.DASHBOARD) setCompleteStep(5);
  }, [hotel]);

  return (
    <>
      <MetaData title={t("892")} />
      <div className="relative min-h">
        {loading || (props?.hotel && props?.hotel?.loading) ? (
          <PageLoader />
        ) : (
          <>
            <div
              className={`${completeStep === 0
                ? "flex flex-col-reverse md:grid md:grid-cols-2"
                : "flex"
                } relative mx-auto bg-[#FFFFFF] w-full min-h-[calc(100vh-5rem)]`}
            >
              <div className="mx-auto">
                {/* {completeStep > 0 && (
                  <ReactStepper
                    activeStep={completeStep}
                    steps={[{}, {}, {}, {}, {}]}
                    className="w-80 sm:w-96 mx-auto h-20"
                  />
                )} */}
                {completeStep === 0 && (
                  <Step0 setStep={setCompleteStep} owner={owner} />
                )}

                {completeStep === 1 && (
                  <Step1
                    setStep={setCompleteStep}
                    owner={owner}
                    reset={props?.updateReset}
                    hotel={props?.hotel?.hotel}
                  />
                )}
                {completeStep === 2 && (
                  <Step2
                    setStep={setCompleteStep}
                    owner={owner}
                    reset={props?.updateReset}
                    hotel={props?.hotel?.hotel}
                  />
                )}
                {completeStep === 3 && (
                  <Step3
                    setStep={setCompleteStep}
                    owner={owner}
                    reset={props?.updateReset}
                    hotel={props?.hotel?.hotel}
                  />
                )}
                {completeStep === 4 && (
                  <Step4
                    setStep={setCompleteStep}
                    owner={owner}
                    reset={props?.updateReset}
                    hotel={props?.hotel?.hotel}
                  />
                )}
                {completeStep === 5 && (
                  <Step5
                    setStep={setCompleteStep}
                    owner={owner}
                    hotel={props?.hotel?.hotel}
                  />
                )}
                {completeStep === -1 && (
                  <ApprovalPending setStep={setCompleteStep} owner={owner} />
                )}
              </div>
              <div
                className={`${completeStep !== 0 ? "hidden" : ""
                  } relative h-[calc(100vh-5rem)]`}
              >
                <img
                  src={receptionhall}
                  alt="ok"
                  className="absolute top-0 left-0 h-full w-full"
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  hotel: state.Hotel,
  update: state.UpdateHotel,
});

const mapDispatchToProps = (dispatch) => ({
  updateReset: () => dispatch({ type: UPDATE_HOTEL_RESET }),
  updateHotel: (payload) => dispatch({ type: GET_HOTEL_SUCCESS, payload }),
  getHotel: (hotelId) => dispatch(getHotelAction(hotelId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GetStarted);
