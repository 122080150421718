import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Seperator from "../../components/layouts/Seperator";
// import { IoChevronBackOutline } from "react-icons/io5";
import SelectFromArray from "../../components/layouts/SelectFromArray"; 
// import Calendar from "react-calendar";
import RomTypeCostTable from "./RomTypeCostTable";
import AddAutomationAndRoomTarrifRules from "./AddAutomationAndRoomTarrifRules";
import RoomTarrifCalender from "./RoomTariffCalender";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

const RoomTariff = () => {
  const { t } = useTranslation();
  const [isAutomate, setIsAutomate] = useState(false);
  const [selectRoomType, setSelectRoomType] = useState([]);
  const [selectRateType, setSelectRateType] = useState([]);
  const [selectNoOfPerson, setSelectNoOFPerson] = useState([]);
  // const [currentValue, setCurrentValue] = useState(new Date());
  // const [nextValue, setNextValue] = useState(new Date());
  const [selectetRows, setSelectedRows] = useState("")
  // console.log(priceIncreaseBy);

  // get inputs to better way
  // useEffect(() => {
  //   const timeDelay = setTimeout(() => {
  //     if (priceIncreaseBy || occupencyIncreaseBy !== "") {
  //       console.log(priceIncreaseBy, occupencyIncreaseBy);
  //     }
  //   }, 1000)
  //   return () => {
  //     clearTimeout(timeDelay)
  //   }
  // }, [priceIncreaseBy, occupencyIncreaseBy]);

  const headingData = {
    heading: t("Room Tariff Automation"),
    seperator: [
      {
        text: t("Automation"),
      },
      {
        // optionnk: PATH_BOOKING_ALL,
        text: t("Room Tariff"),
      },
    ],
  };

  // const handleGetCurrentMonthDate = (e) => {
  //   setCurrentValue(e);
  //   // console.log(e);
  // }

  // const handleGetNextMonthDate = (e) => {
  //   setNextValue(e);
  //   // console.log(e);
  // }
  const rowData = [
    {
      roomType: "Min. Base room tariff",
      single: "2000",
      double: "3500",
      triple: "5000",
      quad: "6000",
      king: "7500",
    },
    {
      roomType: "Max. Base room tariff",
      single: "4000",
      double: "7000",
      triple: "10,000",
      quad: "12,000",
      king: "15,500",
    }
  ]
  const columnDefs = [
    {
      headerName: "Room Type",
      field: "roomType",
      minWidth: 200,
      // filter: true,
      // floatingFilter: true,
    },
    {
      headerName: "Single",
      field: "single",
      maxWidth: 150,
    },
    {
      headerName: "Double",
      field: "double",
      maxWidth: 150,
    },
    {
      headerName: "Triple",
      field: "triple",
      maxWidth: 150,

    },
    {
      headerName: "Quad",
      field: "quad",

    },
    {
      headerName: "King",
      field: "king",
    },
  ];
  return (
    <>
      <div className="page-container">
        <div className="flex justify-between items-end">
          <Seperator data={headingData} />
        </div>
        <div className="lg:flex lg:w-full lg:grid-cols-3 grid md:grid-cols-1 items-center md:justify-center lg:justify-end gap-4 my-4">
          <div className="">
            <SelectFromArray
              value={selectRateType}
              onChange={(e) => setSelectRateType(e?.target?.value)}
              options={[
                "Seasonal Rate",
                "Rack Rate",
                "Honeymoon Packages",
                "Bed & Breakfast",
                "Half Board (B,Dinner)",
                "Full Board (B,L,D)",
              ]}
            />
          </div>
          <SelectFromArray
            value={selectRoomType}
            onChange={(e) => setSelectRoomType(e?.target?.value)}
            options={["Deluxe Room", "Superior Room", "Standard Room"]}
          />
          <SelectFromArray
            value={selectNoOfPerson}
            onChange={(e) => setSelectNoOFPerson(e?.target?.value)}
            options={[
              "N0.Of Person",
              "01",
              "02",
              "03",
              "04",
              "05",
              "06",
            ]}
          />
        </div>
        {/* Automation on/Of */}
        <div className="font-medium text-base text-[#344054] pt-1">
          {t("Automation On / Off")}
          <input
            type={"checkbox"}
            className="ml-4 checkBoxControl"
            value={isAutomate}
            onChange={() => setIsAutomate(!isAutomate)}
          />
        </div>
        <div className="my-4">
          <RomTypeCostTable
            columnDefs={columnDefs}
            rowData={rowData}
            setSelectedRows={setSelectedRows}
          />
        </div>
        {/* Add Automation Rule */}
        <AddAutomationAndRoomTarrifRules />
        <div className="border rounded-[10px] bg-[#FFFFFF] mt-14 p-4">
          <div className="flex justify-between items-center mt-2">
            <div className="flex gap-48 p-4">
              <button className="">
                <IoIosArrowBack size={20} />
              </button>
              <span className="text-[16px] leading-[130%] tracking-[-0.02em] font-bold">
                May 2023
              </span>
            </div>
            <div className="flex gap-48 p-4">
              <span className="text-[16px] leading-[130%] tracking-[-0.02em] font-bold">
                Jun 2023
              </span>
              <button className="">
                <IoIosArrowForward size={20} />
              </button>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-6">
            <RoomTarrifCalender />
            <RoomTarrifCalender />
            {/* <Calendar
                onChange={handleGetCurrentMonthDate}
                value={currentValue}
                className={"h-[320px] w-full border-none  hover:accent-current rounded-none border"}
                prev2Label
                next2Label
                nextLabel
              /> */}
            {/* <Calendar
              onChange={handleGetNextMonthDate}
              value={nextValue}
              className={"h-[320px] w-full border-none  hover:accent-current rounded-none"}
              prevLabel
              prev2Label
              next2Label
            /> */}

          </div>
          <div
            className="flex items-center gap-1 text-[14px] leading-[16.94px] tracking-[-0.6%]"
          >
            <span className="font-bold text-[#344054]">8May</span>| Room Price :
            <span className="text-[#00A35E]">123e</span>| Available Room :
            <span className="text-[#0059DF]">19/40</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default RoomTariff;
