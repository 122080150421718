import React from "react";

const InputError = ({ error }) => {
  return (
    <>
      {error && error?.length ? (
        <div className="w-full text-sm text-red-500">{error}</div>
      ) : null}
    </>
  );
};

export default InputError;
