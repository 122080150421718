/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import TaxMiddleComponent from "./TaxSlabMiddleComponent";
import TaxSlabMaximum from "./TaxSlabMaximum";
import TaxSlabMinimum from "./TaxSlabMinimum";
import { useTranslation } from "react-i18next";

const TaxRoomRules = ({ taxRule, setTaxRule, isupdate }) => {
  const { t } = useTranslation();
  const [compCtn, setCompCtn] = useState(taxRule?.length - 2);
  const [changed, setChanged] = useState(false);

  const addMoreSlab = () => {
    if (taxRule[compCtn] && !taxRule[compCtn]?.maximum) {
      return;
    } else {
      taxRule[compCtn + 1].maximum = 0;
      taxRule[compCtn + 1].taxPercentage = 0;
      const taxPercentage = taxRule[compCtn]?.taxPercentage;
      setTaxRule([...taxRule, { minimum: 0, maximum: 10000, taxPercentage }]);
      console.log(taxRule[compCtn + 1]);
      setCompCtn(compCtn + 1);
      setChanged(true);
    }
  };

  //reset ischanged
  useEffect(() => {
    if (changed) setChanged(false);
    console.log(taxRule);
  }, [changed]);

  useEffect(() => {
    if (taxRule?.length - 2 !== compCtn && isupdate)
      setCompCtn(taxRule?.length - 2);
  }, [isupdate]);

  return (
    <>
      <div className="grid gap-6 mt-6">
        {/* first row minimums*/}
        <TaxSlabMinimum
          taxRule={taxRule}
          setTaxRule={setTaxRule}
          setChanged={setChanged}
        />

        {[...Array(compCtn)].map((ctn, index) => (
          <TaxMiddleComponent
            taxRule={taxRule}
            setTaxRule={setTaxRule}
            index={index}
            setChanged={setChanged}
            key={index}
            compCtn={compCtn}
            setCompCtn={setCompCtn}
            isupdate={isupdate}
          />
        ))}

        <TaxSlabMaximum
          taxRule={taxRule}
          setTaxRule={setTaxRule}
          setChanged={setChanged}
          index={compCtn}
        />
        <button
          className="our-button hover:bg-base-primary_hover uppercase w-32"
          onClick={addMoreSlab}
          type="button"
        >
          {t("436")}
        </button>
      </div>
    </>
  );
};

export default TaxRoomRules;
