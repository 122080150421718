import React from "react";
import laundryCart from "../../images/icons/laundryCart.svg";

const Cards = ({ image, heading, paragraph, price, onClick }) => {
  return (
    <div className="h-fit border-[0.69px] border-[#E4E4E7] hover:border-base-primary rounded-[8.31px] bg-white transition-all cursor-pointer ease-[0s] delay-[0.3s] hover:scale-105 duration-[0.3s] shadow-[0_1px_2px_0_rgba(16,24,40,0.05)] pb-4">
      <div className="flex items-center justify-center h-28 w-full">
        <div className="h-20 w-20 rounded-[5px] overflow-hidden">
          {/* <img src={URL.createObjectURL(image)} alt="" /> */}
          <img src={image} alt="" className="bg-no-repeat object-fill" />
        </div>
      </div>
      <div className="px-2">
        <h2 className="text-base font-medium text-black">{heading}</h2>
        <p className="text-[9px] leading-[11.52px] text-[#979797] font-medium">
          {paragraph}
        </p>
        <div className="flex items-center justify-between mt-2">
          <h2 className="text-[14px] leading-[17.64px] font-normal text-base-primary">
            {price}
          </h2>
          <button
            type="button"
            className="flex items-center justify-center h-[30.78px] w-[30.78px] rounded-[4.4px] bg-base-primary hover:bg-base-primary_hover"
            onClick={onClick}
          >
            <img src={laundryCart} alt="" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Cards;
