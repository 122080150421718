/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteMenuItem } from "../../actions/kitchen/menu";
import { registerEvent } from "../../logs/";
import DeleteOrNot from "../../components/layouts/DeleteOrNot";
import BackGroundLoadSpinner from "../../components/loader/BackGroundLoadSpinner";
import { ROUTES } from "../../constants";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CustomeTable } from "../../components";
import UploadButton from "../../components/buttons/UploadButton";
import DownloadButton from "../../components/buttons/DownloadButton";

const AllMenuItem = ({ setMenu, kitchen }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [datas, setDatas] = useState([]);
  const { menuItems } = useSelector((state) => state.AllMenuItem);
  const [selectedRows, setSelectedRows] = useState([]);
  const { loading: delLoading } = useSelector((state) => state.DeleteMenuItem);
  const [deleteId, setDeleteId] = useState("");
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  //set kitchen menu as datas
  useEffect(() => {
    if (menuItems && Array.isArray(menuItems)) {
      let newData = [];
      menuItems?.map((item) => {
        newData.push({
          itemId: item?.itemId,
          foodAndBeveragesName: item?.foodAndBeveragesName,
          foodAndBeverageType: item?.foodAndBeverageType,
          price: item?.price,
          dietaryPreference: item?.dietaryPreference,
          portionSize: item?.portionSize,
          available: item?.available,
          availableIn: item?.availableIn,
          avgOrders: item?.avgOrders,
          invAvailable: item?.invAvailable,
          _id: item?._id,
        });
      });
      if (newData?.length) {
        setDatas(newData);
      }
    }
  }, [menuItems]);

  //set menu item for delete
  // const setMenuItem = (id) => {
  //   menuItems?.map((item) => {
  //     if (item.id === id) {
  //       setMenu(item);
  //     }
  //   });
  // };

  //delete menu items modal open for confirmation
  // const delMenuItem = (id) => {
  //   if (id) {
  //     setOpen(true);
  //     setDeleteId(id);
  //   }
  // };

  // confirm to delete menu item
  const deleteConfirmed = () => {
    registerEvent("delete confirm", "menu item", deleteId);
    dispatch(deleteMenuItem(kitchen._id, deleteId));
  };

  // delete not confirmed to delete menu item
  const closeModal = () => {
    setOpen(false);
    setDeleteId("");
  };
  //view AllMenuItem Actions buttons
  const ViewAllMenuItemActions = () => {
    const setAllMenuItemRedirect = (link) => {
      if (link) {
        navigate(link);
      }
    };
    return (
      <button
        className="flex items-center justify-center w-[133px] h-[32px] rounded text-[14px] leading-[14px] font-semibold tracking-[-0.6%] text-[#F6F8F9] mt-[5px] bg-base-primary hover:bg-base-primary_hover"
        onClick={() => setAllMenuItemRedirect(ROUTES.KITCHEN_MENU_INGREDIENTS)}
      >
        {t("1085")}
      </button>
    );
  };

  const rowData = [
    {
      itemId: "IT01",
      foodAndBeveragesName: "Chicken Wings",
      foodAndBeverageType: "Appetizer",
      price: "200",
      dietaryPreference: "Yes",
      portionSize: "Individual",
      available: "Regular",
      availableIn: "Room,Restaurant",
      avgOrders: "10",
      invAvailable: "12",
    },
    {
      itemId: "IT02",
      foodAndBeveragesName: "Chicken Wings",
      foodAndBeverageType: "Appetizer",
      price: "500",
      dietaryPreference: "Yes",
      portionSize: "Sharing",
      available: "Regular",
      availableIn: "Bar & Cafe,Buffet",
      avgOrders: "12",
      invAvailable: "4",
    },
    {
      itemId: "IT03",
      foodAndBeveragesName: "Caesar Salad",
      foodAndBeverageType: "Appetizer",
      price: "200",
      dietaryPreference: "No",
      portionSize: "Sharing",
      available: "Weekdays",
      availableIn: "Restaurant",
      avgOrders: "20",
      invAvailable: "5",
    },
    {
      itemId: "IT04",
      foodAndBeveragesName: "Steak",
      foodAndBeverageType: "Main Dish",
      price: "600",
      dietaryPreference: "Yes",
      portionSize: "Sharing",
      available: "Weekend",
      availableIn: "Room,Restaurant",
      avgOrders: "10",
      invAvailable: "6",
    },
    {
      itemId: "IT05",
      foodAndBeveragesName: "Beef Platter",
      foodAndBeverageType: "Main Dish",
      price: "100",
      dietaryPreference: "No",
      portionSize: "Platter",
      available: "Regular",
      availableIn: "Restaurant",
      avgOrders: "4",
      invAvailable: "3",
    },
  ];

  const columnDef = [
    {
      headerName: "Item ID",
      field: "itemId",
      maxWidth: 120,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      headerName: "Item Name",
      field: "foodAndBeveragesName",
      maxWidth: 220,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Item Type",
      field: "foodAndBeverageType",
      maxWidth: 220,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Price",
      field: "price",
      maxWidth: 220,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Dietary Preference",
      field: "dietaryPreference",
      maxWidth: 220,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Portion Size",
      field: "portionSize",
      maxWidth: 220,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Available",
      field: "available",
      maxWidth: 220,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Available In",
      field: "availableIn",
      maxWidth: 220,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Avg Orders",
      field: "avgOrders",
      maxWidth: 220,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Avg Orders",
      field: "avgOrders",
      maxWidth: 220,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Inv Available",
      field: "invAvailable",
      maxWidth: 220,
      filter: true,
      floatingFilter: true,
    },
    {
      headerName: "Actions",
      minWidth: 180,
      cellRenderer: ViewAllMenuItemActions,
    },
  ];
  return (
    <>
      <div className="mt-6">
      <div className="flex items-center justify-between mb-4">
          <div className="text-[22px] leading-[27.72px] text-black font-bold">
            All Menu Item Table
          </div>
          <div className="flex items-center gap-4">
            <UploadButton />
            <DownloadButton />
          </div>
        </div>
        {datas.length ? (
          <CustomeTable
            rowData={rowData}
            columnDefs={columnDef}
            setSelectedRows={setSelectedRows}
          />
        ) : null}
      </div>
      <DeleteOrNot
        open={open}
        close={closeModal}
        formSubmit={deleteConfirmed}
      />
      {delLoading ? <BackGroundLoadSpinner /> : null}
    </>
  );
};

export default AllMenuItem;
