import axios from "axios";
import { TOKEN } from "../../constants";

import {
  ADD_HOTEL_ASSET_FAIL,
  ADD_HOTEL_ASSET_REQ,
  ADD_HOTEL_ASSET_SUC,
  DELETE_HOTEL_ASSET_FAIL,
  DELETE_HOTEL_ASSET_REQ,
  DELETE_HOTEL_ASSET_SUC,
  UPDATE_HOTEL_ASSET_FAIL,
  UPDATE_HOTEL_ASSET_REQ,
  UPDATE_HOTEL_ASSET_SUC,
} from "../types/asset";

//add hotel assets
export const addAssetAction = (formData) => async (dispatch) => {
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/employee/assets`;
  try {
    dispatch({ type: ADD_HOTEL_ASSET_REQ });
    const { data } = await axios.post(url, formData, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: ADD_HOTEL_ASSET_SUC,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADD_HOTEL_ASSET_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

//update hotel asset
export const updateAssetAction = (formData) => async (dispatch) => {
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/employee/assets`;
  try {
    dispatch({ type: UPDATE_HOTEL_ASSET_REQ });
    const { data } = await axios.put(url, formData, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
    });
    dispatch({
      type: UPDATE_HOTEL_ASSET_SUC,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_HOTEL_ASSET_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};

//delete hotel assets
export const deleteAssetAction = (assetId, hotelId) => async (dispatch) => {
  const url = `${process.env.REACT_APP_BASE_API_URL}/api/hotel/employee/assets`;

  try {
    dispatch({ type: DELETE_HOTEL_ASSET_REQ });
    const { data } = await axios.delete(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": localStorage.getItem(TOKEN),
      },
      withCredentials: true,
      data: { assetId, hotelId },
    });
    dispatch({
      type: DELETE_HOTEL_ASSET_SUC,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DELETE_HOTEL_ASSET_FAIL,
      payload: error?.response?.data?.message,
    });
  }
};
